import * as React from 'react';
import AdminTools from '../index';
import { PitemStateProvider } from './reducers/PitemState';
import MainView from './MainView';

interface ItemRegistryProps {}

export default function ItemRegistry(props: ItemRegistryProps) {
  return (
    <AdminTools>
      <PitemStateProvider>
        <MainView />
      </PitemStateProvider>
    </AdminTools>
  );
}
