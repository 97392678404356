import ButtonClear from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Toc from '@material-ui/icons/Toc';
import axios from 'axios';
import * as React from 'react';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Redirect } from 'react-router-dom';
import ReactSelect from 'react-select';
import { ValueType } from 'react-select/lib/types';
import {
  SearchColumn,
  SearchRequest,
  UIDocument,
} from '../../../../shared/src/model/index';
import { APIService } from '../../services/api/src/frontend/api';
import { DocumentService } from '../../services/document/src/frontend/api/index';
import * as appContainer from '../../state/reducers/ApplicationState';
import * as container from '../../state/reducers/DocumentState';
import { debounce } from '../../utils';
import { getIdForCategoryName } from '../../utils/metadata';
import { Item } from '../Common/AutosuggestChipSelection';
import Button from '../Common/Button';
import { errorMessage, Notifier } from '../Common/Notifier';
import TextField from '../Common/TextField';
// import Checkbox from '../Common/Checkbox';
import * as _ from 'lodash';

const API = APIService(axios);
const DOC = DocumentService(axios);

interface ISearchProps extends container.IProps, appContainer.IProps {
  onCloseActionPanel: any;
}

interface IDocumentType {
  id: number;
  name: string;
}

interface ISearchState {
  showColumnSelector: boolean;
  searchConditionSelectorOpen: boolean;
  navigateToResults: boolean;
  navigateToFrontpage: boolean;
  disableSearchSave: boolean;

  searchRequest: SearchRequest;

  selectedSystems: Item[];
  selectedAreas: Item[];
  selectedContracts: Item[];
  selectedBuildingElements: Item[];
  selectedContractoDocumentTypes: Item[];
  selectedInspectionDocumentAttacmentCategories: Item[];
  selectedDocCategories: Item[];
  designDisciplines: IDocumentType[];
  selectedDesignDisciplines: Item[];
  selectedDocumentStates: Item[];
  selectedFileTypes: Item[];
  selectedContractorWorkTypes: Item[];
  selectedInspectionDocuments: Item[];
  selectedTargetTypes: Item[];
  rooms: UIDocument[];
  selectedRooms: Item[];
  startPole: string | undefined;
  endPole: string | undefined;
  mainCategoryIndex?: number;
  mainCategoryName: string;

  selectedPitemType?: Item;
  inspectionDocuments?: Item[];
}

type SearchProps = ISearchProps & InjectedIntlProps;

class AbstractSearch extends React.Component<SearchProps, ISearchState> {
  // If you need to make the search to work realtime, add this
  // to event handlers:
  /*
  this.searchDounce(() => {
    this.onSearch();
  });
 */
  searchDebounce = debounce(500);

  searchResetData = (): SearchRequest => ({
    name: '',
    cursor: {
      limit: 1000,
      offset: 0,
    },
    searchFromFileContents: false,
    freeText: '',
    areaIds: [],
    aitemFilter: [],
    contractFilter: [],
    itemIds: [],
    documentCategories: [],
    documentTypes: [],
    systems: [],
    startPole: undefined,
    endPole: undefined,
    documentStates: [],
    fileTypes: [],
    pitemTypes: [],
    cols: [
      'description',
      'fileName',
      'aItems',
      'pItems',
      'revision',
      'startPole',
      'endPole',
    ],
    searchMainCategory: 0,
    isInspectionDocument: false,
  });

  constructor(props: SearchProps) {
    super(props);

    this.state = {
      selectedPitemType: undefined,
      showColumnSelector: false,
      navigateToFrontpage: false,
      searchConditionSelectorOpen: true,
      navigateToResults: false,
      disableSearchSave: true,
      searchRequest: {
        ...this.searchResetData(),
      },
      selectedAreas: [],
      selectedContracts: [],
      selectedBuildingElements: [],
      selectedContractoDocumentTypes: [],
      selectedInspectionDocumentAttacmentCategories: [],
      selectedDocCategories: [],
      designDisciplines: this.props.getContractorWorkTypes,
      rooms: [],
      selectedDesignDisciplines: [],
      selectedDocumentStates: [],
      selectedFileTypes: [],
      selectedContractorWorkTypes: [],
      selectedRooms: [],
      selectedInspectionDocuments: [],
      selectedSystems: [],
      startPole: '',
      endPole: '',
      mainCategoryName: '',
      inspectionDocuments: [],
      selectedTargetTypes: [],
    };

    this.props.getAreas();
    this.props.getUserContracts();
    this.props.getDocumentCategories();

    this.props.getBuildingElementTree([]);

    this.props.getFileTypes();

    this.onChangeContract = this.onChangeContract.bind(this);
  }

  componentDidMount() {
    const { activeSearch } = this.props;

    // Get building elements and rooms if there's contracts selected
    if (activeSearch && activeSearch.contractFilter) {
      this.props.getBuildingElementTree(activeSearch.contractFilter);

      this.props.getRooms({
        contractIds: activeSearch.contractFilter,
        pitemIds: this.props.uiBuildingElements.map((c) => c.value),
      });
    }
    // load current data from the active search
    this.updateStateFromRequest(activeSearch);
    this.props.getSystems();
  }

  resetSearch() {
    this.updateStateFromRequest(this.searchResetData());
  }

  ColumnSelector = () => {
    const t = this.props.intl;
    return (
      <ReactSelect
        className="dropdown"
        isMulti={true}
        placeholder={t.formatMessage({
          id: 'search.columns',
        })}
        options={
          Object.keys(this.props.searchRequestKeys).map((key, index) => {
            return {
              label: t.formatMessage({
                id: 'documentMeta.' + key,
              }),
              value: key,
            };
          }) as Array<{
            label: string;
            value: string;
          }>
        }
        value={(this.state.searchRequest.cols || []).map((key, index) => {
          return {
            label: t.formatMessage({
              id: 'documentMeta.' + key,
            }),
            value: key,
          };
        })}
        onChange={(
          listOfValues: ValueType<{
            label: string;
            value: string;
          }>
        ) => {
          const keyList = (
            (listOfValues || []) as Array<{
              label: string;
              value: string;
            }>
          ).map((item) => item.value);

          this.setState({
            searchRequest: {
              ...this.state.searchRequest,
              cols: keyList as SearchColumn[],
            },
          });
        }}
      />
    );
  };

  async updateStateFromRequest(req: SearchRequest) {
    const mainCategoryName =
      req.targetType === 'pitem' ? 'PITEMS' : req.searchMainCategoryName || '';
    const isPItems = mainCategoryName === 'PITEMS';
    const contracts = isPItems
      ? req.aitemFilter || []
      : req.contractFilter || [];
    this.setState(
      {
        mainCategoryName: mainCategoryName,
        mainCategoryIndex: req.searchMainCategory || 0,
        startPole: req.startPole ? String(req.startPole) : undefined,
        endPole: req.endPole ? String(req.endPole) : undefined,
        searchRequest: { ...this.state.searchRequest, ...req },
        selectedTargetTypes: req.pitemTypes
          ? req.pitemTypes.map((id: number) => {
              const item = this.props.targetPitemTypes.filter(
                (item) => item.id === id
              );
              const pitemtype = item[0];
              if (pitemtype) {
                return { value: pitemtype.id, label: pitemtype.name || '' };
              }
              return { value: 0, label: '' };
            })
          : [],
        selectedBuildingElements: req.itemIds
          ? req.itemIds.map((id) => {
              const c = this.props.uiBuildingElements
                .filter((c) => c.value === id)
                .pop();
              return !c ? { value: 0, label: '' } : c;
            })
          : [],
        selectedSystems: req.systems
          ? req.systems.map((id) => {
              const system = this.props.systems
                .filter((a) => a.id === id)
                .pop();

              if (!system) {
                return { value: 0, label: '' };
              }

              const label = system.system_name!;
              return {
                value: system.id,
                label,
              };
            })
          : [],
        selectedRooms: req.roomIds
          ? req.roomIds.map((id) => {
              const selectedRoom = this.props.rooms
                .filter((room) => room.id === id)
                .pop();

              if (!selectedRoom) {
                return { value: 0, label: '' };
              }
              return {
                value: id,
                label: selectedRoom.name || '',
              };
            })
          : [],
        selectedDocCategories: req.documentCategories
          ? req.documentCategories
              .filter((id) => {
                return (
                  this.props.docCategories.filter((c) => c.id === id).length > 0
                );
              })
              .map((id) => {
                const c = this.props.docCategories
                  .filter((c) => c.id === id)
                  .pop();
                if (!c) {
                  return { value: 0, label: '' };
                }
                return {
                  value: id,
                  label: c.name || '',
                };
              })
          : [],
        selectedContractoDocumentTypes: req.contractorDocumentTypes
          ? req.contractorDocumentTypes
              .filter((id) => {
                return (
                  this.props.getContractorDocumentypes.filter(
                    (c) => c.id === id
                  ).length > 0
                );
              })
              .map((id) => {
                const c = this.props.getContractorDocumentypes
                  .filter((c) => c.id === id)
                  .pop();
                if (!c) {
                  return { value: 0, label: '' };
                }
                return {
                  value: id,
                  label: c.name || '',
                };
              })
          : [],
        selectedInspectionDocumentAttacmentCategories:
          req.inspectionDocumentTypes
            ? req.inspectionDocumentTypes
                .filter((id) => {
                  return (
                    this.props.inspectionDocCategories.filter(
                      (c) => c.id === id
                    ).length > 0
                  );
                })
                .map((id) => {
                  const c = this.props.inspectionDocCategories
                    .filter((c) => c.id === id)
                    .pop();
                  if (!c) {
                    return { value: 0, label: '' };
                  }
                  return {
                    value: id,
                    label: c.name
                      ? this.props.intl.formatMessage({ id: c.name })
                      : '',
                  };
                })
            : [],
        selectedContractorWorkTypes: req.contractorWorkTypes
          ? req.contractorWorkTypes
              .filter((id) => {
                return (
                  this.props.getContractorWorkTypes.filter((c) => c.id === id)
                    .length > 0
                );
              })
              .map((id) => {
                const c = this.props.getContractorWorkTypes
                  .filter((c) => c.id === id)
                  .pop();
                if (!c) {
                  return { value: 0, label: '' };
                }
                return {
                  value: id,
                  label: c.name || '',
                };
              })
          : [],
        selectedDesignDisciplines: req.designDisciplines
          ? req.designDisciplines
              .filter((id) => {
                return (
                  this.props.designDisciplines.filter((c) => c.id === id)
                    .length > 0
                );
              })
              .map((id) => {
                const c = this.props.designDisciplines
                  .filter((c) => c.id === id)
                  .pop();
                if (!c) {
                  return { value: 0, label: '' };
                }
                return {
                  value: id,
                  label: c.name || '',
                };
              })
          : [],
        selectedFileTypes: this.props.fileTypes
          .map((type, i) => ({
            label: type.toLocaleUpperCase(),
            value: i,
          }))
          .filter((type) => {
            return req.fileTypes && req.fileTypes.indexOf(type.label) > -1;
          }),
        selectedAreas: req.areaIds
          ? req.areaIds.map((id) => {
              const area = this.props.areas.filter((a) => a.id === id).pop();

              if (!area) {
                return { value: 0, label: '' };
              }

              const label = area.description
                ? `${area.area_name!} - ${area.description!}`
                : area.area_name!;
              return {
                value: id,
                label: label || '',
              };
            })
          : [],
        selectedContracts: contracts.map((id) => {
          const c = this.props.userContracts
            .filter((c) => c.aitem_id === id)
            .pop();
          if (!c) {
            return { value: 0, label: '' };
          }
          return {
            value: id,
            label: c.aitem_name + ' / ' + c.longName,
          };
        }),
        selectedDocumentStates: req.documentStates
          ? req.documentStates.map((id) => {
              const state = this.props.appDocumentStates
                .filter((s) => s.id === id)
                .pop();
              if (!state) {
                return { value: 0, label: '' };
              }
              return {
                value: id,
                label: state.description || '',
              };
            })
          : [],
      },
      () => {
        if (isPItems) {
          this.setRoomsForPItemsSearch(
            this.state.selectedBuildingElements.map((item) => item.value)
          );
        }
      }
    );
    this.handleInspectionDocuments(
      req.contractFilter || [],
      req.parentDocumentIds || []
    );
  }

  handleInspectionDocuments = async (
    contractFilter: number[],
    defaultSelected?: number[]
  ) => {
    const inspectionDocuments =
      contractFilter.length > 0
        ? (
            await DOC.documentSearchRequest({
              documentCategories: [
                await getIdForCategoryName('TARKASTUSASIAKIRJA'),
              ],
              contractFilter: contractFilter,
            })
          ).rows.map((doc): Item => ({ value: doc.id, label: doc.name }))
        : [];

    let newStates = {
      inspectionDocuments: inspectionDocuments,
    };
    if (defaultSelected && defaultSelected.length > 0) {
      newStates['selectedInspectionDocuments'] = inspectionDocuments.filter(
        (doc) => defaultSelected.includes(doc.value)
      );
    }
    this.setState(newStates);
  };

  setInspectionDocuments = (selection: Item[]) => {
    this.handleInspectionDocuments(selection.map((item) => item.value));
  };

  getRoomsForPItemsSearch = async (itemIds?: number[]) => {
    let search = {
      pitemTypes: this.props.pitemTypes
        .filter((n) => n.name === 'Huone')
        .map((t) => t.id),
    };
    if (itemIds && itemIds.length > 0) {
      search['itemIds'] = itemIds;
    }

    return (await API.searchPItems(search)).rows;
  };

  getSelectedRooms = (selectedRooms: Item[], rooms: UIDocument[]): Item[] => {
    return selectedRooms.filter((sr) =>
      rooms.reduce((pre, cur) => pre || cur.id === sr.value, false)
    );
  };

  async setRoomsForPItemsSearch(itemIds?: number[], selectedRooms?: Item[]) {
    const rooms = await this.getRoomsForPItemsSearch();
    let newStates = { rooms };
    if (selectedRooms && selectedRooms.length > 0) {
      newStates['selectedRooms'] = this.getSelectedRooms(selectedRooms, rooms);
    }
    this.setState(newStates);
  }

  onFreeTextSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchRequest = { ...this.state.searchRequest };
    searchRequest.freeText = e.currentTarget.value.trim();

    this.setState({ searchRequest });
  };

  onSearchFromContentChange = (e: any) => {
    const searchRequest = { ...this.state.searchRequest };
    searchRequest.searchFromFileContents =
      !this.state.searchRequest.searchFromFileContents;
    this.setState({ searchRequest });
  };

  onSearchNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchRequest = { ...this.state.searchRequest };
    searchRequest.name = e.currentTarget.value;
    this.setState({ searchRequest });

    // Disable save button when the name field is empry
    this.setState({ disableSearchSave: !e.currentTarget.value });
  };

  onSearch = (e?: React.SyntheticEvent) => {
    this.props.resetSearchState();

    const { searchRequest } = this.state;
    const { areaIds } = searchRequest;

    if (!searchRequest.additionalInfo) {
      searchRequest.additionalInfo = {};
    }

    if (!searchRequest.cols) {
      searchRequest.cols = [];
    }

    /*
      There's a 'bug' in following implementation. When user ads area as
      search parameter, the fallowing code adds 'area' to columns as it
      should to. If user then removes the area parameter(s) and does
      another search, the area column is still present until removed
      manually from the column options.

      We decided this to be so minor inconvenience that we don't
      handle this special case here.
    */

    // If there's areas set as search filters but 'area' is not
    // set as one of the results columns, then set it
    if (areaIds && areaIds.length > 0) {
      searchRequest.cols.splice(1, 1, 'area');
    }

    // If 'area' is set as selected column, make sure that we add needed
    // parameters to search request
    searchRequest.additionalInfo.areas =
      searchRequest.cols.indexOf('area') > -1 ? true : false;

    // Change contractFilter ids to aitemFilter ids when searching Pitems (items). #2431
    if (this.state.mainCategoryName === 'PITEMS') {
      if (
        searchRequest.contractFilter &&
        searchRequest.contractFilter.length > 0
      ) {
        searchRequest.aitemFilter = searchRequest.contractFilter;
        searchRequest.contractFilter = undefined;
      }
    }

    this.setState({
      searchRequest: {
        ...this.state.searchRequest,
        additionalInfo: searchRequest.additionalInfo,
        cols: searchRequest.cols,
      },
    });

    this.updateSearchQuery(() => {
      // NOTE: You can't use `searchRequest` from the above as it is
      // staled state after updateSearchQuery has been run
      this.props.setActiveSearch(this.state.searchRequest);
      this.props.documentSearch(this.state.searchRequest);
      if (window.location.pathname.indexOf('results') === -1) {
        this.setState({ navigateToResults: true });
        this.props.onCloseActionPanel();
      }
    });
  };

  handleSearchSave = (e: any) => {
    this.props.addSearchRequest(this.state.searchRequest);
    this.props.addAndSaveUserSearches(this.state.searchRequest);
    // this.props.onCloseActionPanel();

    setTimeout(() => {
      this.setState({ navigateToFrontpage: true });
    }, 100);
  };

  updateSearchQuery = (whenReady?: () => void) => {
    const searchRequest = { ...this.state.searchRequest };

    const {
      selectedDesignDisciplines,
      selectedContractorWorkTypes,
      selectedContractoDocumentTypes,
      selectedInspectionDocumentAttacmentCategories,
      selectedDocCategories,
      selectedFileTypes,
      selectedRooms,
    } = this.state;

    searchRequest.documentCategories = Array.prototype.concat
      .apply([], [selectedDocCategories])
      .map((item: Item) => item.value);
    searchRequest.contractorWorkTypes = selectedContractorWorkTypes.map(
      (t) => t.value
    );
    searchRequest.designDisciplines = selectedDesignDisciplines.map(
      (t) => t.value
    );
    searchRequest.contractorDocumentTypes = selectedContractoDocumentTypes.map(
      (t) => t.value
    );
    searchRequest.inspectionDocumentTypes =
      selectedInspectionDocumentAttacmentCategories.map((t) => t.value);

    searchRequest.documentStates = this.state.selectedDocumentStates.map(
      (workflowState) => workflowState.value
    );

    searchRequest.pitemTypes = this.state.selectedTargetTypes.map(
      (v) => v.value
    );

    searchRequest.searchMainCategoryName = this.state.mainCategoryName;

    searchRequest.roomIds = selectedRooms.map((t) => t.value);

    searchRequest.fileTypes = selectedFileTypes.map((t) => t.label);

    if (this.state.selectedInspectionDocuments) {
      searchRequest.parentDocumentIds =
        this.state.selectedInspectionDocuments.map((e: Item) => e.value);
    }

    this.setState({ searchRequest }, whenReady);
  };

  onAddNewSearchCondition = () => {
    this.setState({ searchConditionSelectorOpen: true });
  };

  onCancelAddNewSearchCondition = () => {
    this.setState({ searchConditionSelectorOpen: false });
  };

  async onChangeArea(selection: Item[]) {
    const searchRequest = { ...this.state.searchRequest };
    searchRequest.areaIds = selection.map((item) => item.value);
    searchRequest.additionalInfo = Object.assign(
      {},
      searchRequest.additionalInfo,
      { areas: selection.length > 0 }
    );
    this.setState({ selectedAreas: selection, searchRequest });

    // When we select the area we should also filter the buildings based on the
    // selected area in the buildings list
    this.props.setSearchNaviSelectedAreas(searchRequest.areaIds);
    this.props.getBuildingElementTree(
      this.state.selectedContracts.map((item: Item) => item.value)
    );
  }

  async onChangeSystem(selection: Item[]) {
    const searchRequest = { ...this.state.searchRequest };
    searchRequest.systems = selection.map((item) => item.value);
    searchRequest.additionalInfo = Object.assign(
      {},
      searchRequest.additionalInfo,
      { areas: selection.length > 0 }
    );
    this.setState({ selectedSystems: selection, searchRequest });
  }

  async onChangeContract(selection: Item[]) {
    const searchRequest = { ...this.state.searchRequest };

    this.props.getBuildingElementTree(
      selection.map((item: Item) => item.value)
    );

    if (this.state.selectedContracts !== selection) {
      this.setState(
        {
          selectedContracts: selection,
          selectedBuildingElements: [],
        },
        () => {
          searchRequest.contractFilter = this.state.selectedContracts.map(
            (contract) => contract.value
          );
          // reset also the search request buildings
          searchRequest.itemIds = [];
          this.setState({ searchRequest });
          if (this.state.mainCategoryName === 'PITEMS') {
            this.setRoomsForPItemsSearch();
          }
          this.props.getRooms({
            contractIds: this.state.selectedContracts.map((c) => c.value),
            pitemIds: this.state.selectedBuildingElements.map((c) => c.value),
          });
        }
      );
      this.setInspectionDocuments(selection);
    }

    if (!selection) {
      this.setState(
        {
          selectedBuildingElements: [],
        },
        () => {
          if (this.state.mainCategoryName === 'PITEMS') {
            this.setRoomsForPItemsSearch();
          }
        }
      );
    }
  }

  onChangeInspectionDocument = (inspectionDocs: Item[]) => {
    this.setState(
      {
        selectedInspectionDocuments: inspectionDocs,
      },
      () => this.updateSearchQuery()
    );
  };

  onChangeBuildingElement = async (buildingElements: Item[]) => {
    const itemIds = buildingElements.map(
      (buildingElement) => buildingElement.value
    );
    const isPItems = this.state.mainCategoryName === 'PITEMS';
    const rooms = isPItems
      ? await this.getRoomsForPItemsSearch(itemIds)
      : this.state.rooms;
    const selectedRooms = isPItems
      ? this.getSelectedRooms(this.state.selectedRooms, rooms)
      : [];

    this.setState(
      {
        selectedBuildingElements: buildingElements,
        selectedRooms,
        rooms,
      },
      () => {
        const searchRequest = { ...this.state.searchRequest };
        searchRequest.itemIds = itemIds;
        this.setState({ searchRequest });
        this.props.getRooms({
          contractIds: this.state.selectedContracts.map((c) => c.value),
          pitemIds: this.state.selectedBuildingElements.map((c) => c.value),
        });
      }
    );
  };

  onChangeDesignDisciplines = (disciplines: Item[]) => {
    this.setState(
      {
        selectedDesignDisciplines: disciplines,
      },
      () => this.updateSearchQuery()
    );
  };

  onChangeContractorDocumentTypes = (documentTypes: Item[]) => {
    this.setState(
      {
        selectedContractoDocumentTypes: documentTypes,
      },
      () => this.updateSearchQuery()
    );
  };

  onChangeTargetTypes = (documentTypes: Item[]) => {
    this.setState(
      {
        selectedTargetTypes: documentTypes,
      },
      () => this.updateSearchQuery()
    );
  };

  onChangeInspectionDocumentAttacmentCategory = (
    attachmentCategories: Item[]
  ) => {
    this.setState(
      {
        selectedInspectionDocumentAttacmentCategories: attachmentCategories,
      },
      () => this.updateSearchQuery()
    );
  };

  onChangeDocCategories = (docCategories: Item[]) => {
    this.setState(
      {
        selectedDocCategories: docCategories,
      },
      () => this.updateSearchQuery()
    );
  };

  onChangeWorkflowState = (workflowStates: Item[]) => {
    const searchRequest = { ...this.state.searchRequest };

    this.setState(
      {
        selectedDocumentStates: workflowStates,
      },
      () => {
        searchRequest.documentStates = this.state.selectedDocumentStates.map(
          (workflowState) => workflowState.value
        );
        this.setState({ searchRequest });
      }
    );
  };

  onChangeFileType = (fileTypes: Item[]) => {
    this.setState(
      {
        selectedFileTypes: fileTypes,
      },
      () => {
        this.updateSearchQuery();
      }
    );
  };

  onChangeContractorWorkTypes = (contractorWorkTypes: Item[]) => {
    this.setState(
      {
        selectedContractorWorkTypes: contractorWorkTypes,
      },
      () => this.updateSearchQuery()
    );
  };

  onChangeRooms = (rooms: Item[]) => {
    this.setState(
      {
        selectedRooms: rooms,
      },
      () => this.updateSearchQuery()
    );
  };

  onChangeStartPole = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchRequest = { ...this.state.searchRequest };

    this.setState({ startPole: e.target.value }, () => {
      searchRequest.startPole = Number(this.state.startPole);
      this.setState({ searchRequest });
    });
  };

  onChangeEndPole = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchRequest = { ...this.state.searchRequest };

    this.setState({ endPole: e.target.value }, () => {
      searchRequest.endPole = Number(this.state.endPole);
      this.setState({ searchRequest });
    });
  };

  PoleRange = () => (
    <div>
      <div className="columnLabel">
        <FormattedMessage id="documentMeta.poleInterval" />
      </div>
      <div className="poleRange">
        <input
          type="number"
          className="basic"
          value={this.state.startPole || ''}
          onChange={this.onChangeStartPole}
        />
        <div className="divider">-</div>
        <input
          type="number"
          className="basic"
          value={this.state.endPole || ''}
          onChange={this.onChangeEndPole}
        />
      </div>
    </div>
  );

  getDefaultCols = (catName: string): SearchColumn[] => {
    const URAK_DOC = 'URAK_DOC';
    const TIL_DOC = 'TIL_DOC';
    const URAK_DOC_PAKETTI = 'URAK_DP';
    // const TARK_AK = 'TARKASTUSASIAKIRJA_DOC';
    const PITEMS = 'PITEMS';

    switch (catName) {
      case TIL_DOC:
        return [
          'fileName', // Dokumentin nimi
          'area', // alue
          'aItems', // Urakka
          'pItems', // Rakennusosa
          'description', // Aihe
          'workflowStateName', // Dokumentin tila
          'designerDocumentType', // Dokumentin tyyppi
          'revision', // Revisio
          'modified', // Muokkauspvm
          'versionNumber', // Versionumero
        ];
      case URAK_DOC:
        return [
          'fileName', // Dokumentin nimi
          'area', // alue
          'location', // Tilan nimi
          'locationLongname',
          'pitemTypeName', // Kohdetyyppi
          'targetcode', // Kohdetunnus
          'contractorWorktype', // Työvaihe
          'workflowStateName', // Dokumentin tila
          'modified', // Muokkauspvm
          'versionNumber', // Versionumero
          'modifiedByUser', // Muokkaajan käyttäjätunnus
          'aItems', // Urakka
          'building', // Rakennusosa
        ];
      case URAK_DOC_PAKETTI:
        return [
          'location', // Tilan nimi
          'area', // alue
          'locationLongname',
          'targetcode', // Tunnus
          'pitemTypeName', // Kohdetyyppi
          'contractorWorktype', // Työvaihe
          'workflowStateName', // Dokumenttikokoelman tila (ref. hyväksyntäkierto)
          'aItems', // Urakka// Urakka
          'modified', // Muokkauspvm
          'modifiedByUser', // Muokkaajan käyttäjätunnus
        ];
      case PITEMS:
        return [
          // 'isTracked',
          'targetcode',
          'pitemTypeName',
          'area', // alue
          'workAitems',
          'roomId',
          'room',
          'floor',
          'building',
          'startPole',
          'endPole',
        ];

      default:
        return ['fileName', 'description', 'revision', 'inspectionDocTarget'];
    }
  };

  getAllRooms = async () => {
    try {
      const rooms = await API.searchPItems({
        pitemTypes: this.props.pitemTypes
          .filter((n) => n.name === 'Huone')
          .map((t) => t.id),
      });
      this.setState({ rooms: rooms.rows });
    } catch (e) {
      errorMessage(e);
    }
  };

  public render() {
    const t = this.props.intl;
    const translate = (id: string) => t.formatMessage({ id });
    const { mainCategoryName } = this.state;

    if (this.state.navigateToResults) {
      return <Redirect to={'/results'} push={true} />;
    }

    const URAK_DOC = 'URAK_DOC';
    const TIL_DOC = 'TIL_DOC';
    const URAK_DOC_PAKETTI = 'URAK_DP';
    const TARK_AK = 'TARKASTUSASIAKIRJA_DOC';
    const PITEMS = 'PITEMS';

    // State used in search parameters within each main category.
    // Used for determining, which ones to retain between changing categories
    const mainCategoryState: { [key: string]: string[] } = {
      TARKASTUSASIAKIRJA_DOC: [
        'selectedAreas',
        'selectedContracts',
        'selectedSystems',
        'inspectionDocuments',
        'selectedInspectionDocuments',
        'selectedInspectionDocumentAttacmentCategories',
        'selectedDocumentStates',
      ],
      TIL_DOC: [
        'selectedAreas',
        'selectedSystems',
        'selectedContracts',
        'selectedBuildingElements',
        'selectedDocCategories',
        'designDisciplines',
        'selectedFileTypes',
        'selectedDocumentStates',
        'startPole',
        'endPole',
      ],
      URAK_DOC: [
        'selectedAreas',
        'selectedSystems',
        'selectedContracts',
        'selectedBuildingElements',
        'rooms',
        'selectedRooms',
        'selectedContractorWorkTypes',
        'selectedContractoDocumentTypes',
        'selectedTargetTypes',
        'selectedFileTypes',
        'selectedDocumentStates',
        'startPole',
        'endPole',
      ],
      URAK_DP: [
        'selectedAreas',
        'selectedSystems',
        'selectedContracts',
        'selectedBuildingElements',
        'rooms',
        'selectedRooms',
        'selectedContractorWorkTypes',
        'selectedContractoDocumentTypes',
        'selectedTargetTypes',
        'selectedDocumentStates',
        'startPole',
        'endPole',
      ],
      PITEMS: [
        'selectedAreas',
        'selectedSystems',
        'selectedContracts',
        'selectedPitemType',
        'selectedBuildingElements',
        'rooms',
        'selectedRooms',
        'startPole',
        'endPole',
      ],
    };

    const mainCategories: Array<{
      label: string;
      value: number;
      tag: string;
    }> = this.props.appDocumentCategories
      .filter((cat) => {
        if (this.props.contractPermissions) {
          let hasPermission = false;
          for (const rp of this.props.contractPermissions.readPermissions) {
            if (rp.document_category_name.localeCompare(cat.name) === 0) {
              hasPermission = true;
            }
          }
          if (!hasPermission) {
            return false;
          }
        }

        return (
          cat.name === URAK_DOC ||
          cat.name === TIL_DOC ||
          cat.name === URAK_DOC_PAKETTI ||
          cat.name === TARK_AK
        );
      })
      .map((item) => {
        return {
          label: translate(item.name),
          value: item.id,
          tag: item.name,
        };
      });
    mainCategories.push({ label: translate(PITEMS), value: -1, tag: PITEMS });
    const activeCategory = this.state.mainCategoryIndex
      ? mainCategories
          .filter((c) => c.value === this.state.mainCategoryIndex)
          .pop()
      : null;
    const isCustomerDocument = activeCategory
      ? activeCategory.tag === TIL_DOC
      : false;

    // Take an intersection between maincategory search parameter states
    const getRetainedStateInTransition = (oldCat: string, newCat: string) => {
      return !mainCategoryState.hasOwnProperty(oldCat) ||
        !mainCategoryState.hasOwnProperty(newCat)
        ? {}
        : _.pick(
            this.state,
            _.intersection(mainCategoryState[oldCat], mainCategoryState[newCat])
          );
    };

    return (
      <>
        {this.state.navigateToFrontpage ? (
          <>
            <Redirect to={'/'} push={true} />
            <Notifier
              snack={{
                variant: 'success',
                message: t.formatMessage({ id: 'search.saveSuccess' }),
              }}
            />
          </>
        ) : null}

        {this.state.mainCategoryIndex ? (
          <div className="clearParameters">
            <div className="filler" />
            <ButtonClear
              onClick={() => {
                this.resetSearch();
              }}
            >
              <FormattedMessage id="search.clear" />
            </ButtonClear>
          </div>
        ) : (
          ''
        )}

        <div className="columnLabel">
          <FormattedMessage id="search.header" />
        </div>

        <ReactSelect
          className="dropdown"
          isMulti={false}
          options={mainCategories}
          placeholder={t.formatMessage({
            id: 'search.selectTarget',
          })}
          value={activeCategory}
          onChange={(param: ValueType<Item>) => {
            if (param && !Array.isArray(param)) {
              const item = param as Item & { tag: string };
              const baseline: ISearchState = {
                ...this.state,
                mainCategoryName: item.tag,
                mainCategoryIndex: item.value,
                selectedAreas: [],
                selectedContracts: [],
                selectedDocCategories: [],
                selectedDesignDisciplines: [],
                selectedInspectionDocuments: [],
                rooms: [],
                selectedSystems: [],
                selectedBuildingElements: [],
                startPole: undefined,
                endPole: undefined,
                selectedDocumentStates: [],
                selectedFileTypes: [],
                selectedRooms: [],
                selectedTargetTypes: [],
                selectedContractorWorkTypes: [],
                selectedContractoDocumentTypes: [],
                selectedInspectionDocumentAttacmentCategories: [],
                searchRequest: {
                  ...this.state.searchRequest,
                  targetType: item.tag === 'PITEMS' ? 'pitem' : 'document',

                  searchMainCategory: item.value,
                  isInspectionDocument: item.tag === TARK_AK,
                  documentCategories: [],
                  cols: [...this.getDefaultCols(item.tag)],
                  itemIds: [],
                },
              };
              const retainedState = getRetainedStateInTransition(
                this.state.mainCategoryName,
                item.tag
              );

              this.setState({ ...baseline, ...retainedState }, () => {
                this.updateSearchQuery();
                this.onChangeBuildingElement(
                  this.state.selectedBuildingElements
                );
                if (item.tag === 'PITEMS') {
                  this.setRoomsForPItemsSearch();
                }
              });
            }
          }}
        />

        {this.state.mainCategoryName === 'PITEMS' && (
          <>
            <ValidatorForm onSubmit={this.onSearch}>
              <div>
                <TextField
                  label={t.formatMessage({
                    id: 'search.freeTextSearch',
                  })}
                  defaultValue={this.state.searchRequest.freeText}
                  onChange={this.onFreeTextSearchChange}
                />
              </div>
              <ReactSelect
                className="dropdown"
                isMulti={true}
                placeholder={t.formatMessage({
                  id: 'documentMeta.area',
                })}
                options={this.props.areas.map((area) => {
                  const label = area.description
                    ? `${area.area_name} - ${area.description}`
                    : area.area_name;

                  return {
                    label,
                    value: area.id,
                  };
                })}
                value={this.state.selectedAreas}
                onChange={(listOfValues: ValueType<Item>) => {
                  this.onChangeArea(listOfValues as Item[]);
                }}
              />
              <ReactSelect
                className="dropdown"
                isMulti={true}
                placeholder={t.formatMessage({
                  id: 'documentMeta.system',
                })}
                options={this.props.systems.map((system) => {
                  const label = system.system_name;
                  return {
                    label,
                    value: system.id,
                  };
                })}
                value={this.state.selectedSystems}
                onChange={(listOfValues: ValueType<Item>) => {
                  this.onChangeSystem(listOfValues as Item[]);
                }}
              />
              <ReactSelect
                className="dropdown"
                isMulti={true}
                placeholder={t.formatMessage({
                  id: 'documentMeta.contract',
                })}
                options={this.props.userContracts.map((v) => ({
                  label: v.aitem_name + ' / ' + v.longName,
                  value: v.aitem_id,
                }))}
                value={this.state.selectedContracts}
                onChange={(listOfValues: ValueType<Item>) => {
                  this.onChangeContract(listOfValues as Item[]);
                }}
              />
              <ReactSelect
                className="dropdown"
                isMulti={true}
                placeholder={t.formatMessage({
                  id: 'documentMeta.pitemType',
                })}
                options={this.props.targetPitemTypes.map((v) => ({
                  label: v.name,
                  value: v.id,
                }))}
                value={this.state.selectedTargetTypes}
                onChange={(listOfValues: ValueType<Item>) => {
                  this.onChangeTargetTypes(listOfValues as Item[]);
                }}
              />
              <ReactSelect
                className="dropdown"
                isMulti={true}
                placeholder={t.formatMessage({
                  id: 'documentMeta.buildingElement',
                })}
                value={this.state.selectedBuildingElements}
                options={this.props.uiBuildingElements}
                onChange={(listOfValues: ValueType<Item>) => {
                  this.onChangeBuildingElement(listOfValues as Item[]);
                }}
                disabled={this.props.uiBuildingElements.length === 0}
              />

              {this.state.rooms && this.state.rooms.length > 0 ? (
                <ReactSelect
                  className="dropdown"
                  isMulti={true}
                  placeholder={t.formatMessage({
                    id: 'documentMeta.room',
                  })}
                  options={this.state.rooms.map((v) => ({
                    label: v.name,
                    value: v.id,
                  }))}
                  value={this.state.selectedRooms}
                  onChange={(listOfValues: ValueType<Item>) => {
                    this.onChangeRooms(listOfValues as Item[]);
                  }}
                />
              ) : null}

              <this.PoleRange />

              <div className="submitSearch">
                <div className="filler" />
                <Button color="primary" type="submit">
                  <FormattedMessage id="buttons.search" />
                </Button>
              </div>
              <Button
                color="primary"
                variant="text"
                onClick={() => {
                  this.setState({
                    showColumnSelector: !this.state.showColumnSelector,
                  });
                }}
              >
                <Toc />
                {t.formatMessage({
                  id: 'search.columns',
                })}
              </Button>
              {this.state.showColumnSelector ? <this.ColumnSelector /> : null}
            </ValidatorForm>

            <Divider />
          </>
        )}
        {this.state.mainCategoryIndex &&
        !(this.state.mainCategoryName === 'PITEMS') ? (
          <>
            <ValidatorForm onSubmit={this.onSearch}>
              <div>
                <TextField
                  label={t.formatMessage({
                    id: 'search.freeTextSearch',
                  })}
                  defaultValue={this.state.searchRequest.freeText}
                  onChange={this.onFreeTextSearchChange}
                />
              </div>

              {/* <div>
                <Checkbox
                  checked={this.state.searchRequest.searchFromFileContents}
                  onChange={this.onSearchFromContentChange}
                  label={t.formatMessage({
                    id: 'search.searchFromContent',
                  })}
                  control={<p />}
                />
              </div> */}

              {this.state.searchConditionSelectorOpen ? (
                <>
                  <ReactSelect
                    className="dropdown"
                    isMulti={true}
                    placeholder={t.formatMessage({
                      id: 'documentMeta.area',
                    })}
                    options={this.props.areas.map((area) => {
                      const label = area.description
                        ? `${area.area_name} - ${area.description}`
                        : area.area_name;

                      return {
                        label,
                        value: area.id,
                      };
                    })}
                    value={this.state.selectedAreas}
                    onChange={(listOfValues: ValueType<Item>) => {
                      this.onChangeArea(listOfValues as Item[]);
                    }}
                  />
                  <ReactSelect
                    className="dropdown"
                    isMulti={true}
                    placeholder={t.formatMessage({
                      id: 'documentMeta.system',
                    })}
                    options={this.props.systems.map((system) => {
                      const label = system.system_name;
                      return {
                        label,
                        value: system.id,
                      };
                    })}
                    value={this.state.selectedSystems}
                    onChange={(listOfValues: ValueType<Item>) => {
                      this.onChangeSystem(listOfValues as Item[]);
                    }}
                  />
                  <ReactSelect
                    className="dropdown"
                    isMulti={true}
                    placeholder={t.formatMessage({
                      id: 'documentMeta.contract',
                    })}
                    options={this.props.userContracts.map((v) => ({
                      label: v.aitem_name + ' / ' + v.longName,
                      value: v.aitem_id,
                    }))}
                    value={this.state.selectedContracts}
                    onChange={(listOfValues: ValueType<Item>) => {
                      this.onChangeContract(listOfValues as Item[]);
                    }}
                  />
                  {this.state.mainCategoryName === TARK_AK && (
                    <ReactSelect
                      className="dropdown"
                      isMulti={true}
                      placeholder={t.formatMessage({
                        id: 'documentMeta.inspectionDocument',
                      })}
                      options={this.state.inspectionDocuments}
                      value={this.state.selectedInspectionDocuments}
                      onChange={(listOfValues: ValueType<Item>) => {
                        this.onChangeInspectionDocument(listOfValues as Item[]);
                      }}
                    />
                  )}
                  {this.state.mainCategoryName !== TARK_AK && (
                    <ReactSelect
                      className="dropdown"
                      isMulti={true}
                      placeholder={t.formatMessage({
                        id: 'documentMeta.buildingElement',
                      })}
                      value={this.state.selectedBuildingElements}
                      options={this.props.uiBuildingElements}
                      onChange={(listOfValues: ValueType<Item>) => {
                        this.onChangeBuildingElement(listOfValues as Item[]);
                      }}
                      isDisabled={this.props.uiBuildingElements.length === 0}
                    />
                  )}
                  {!isCustomerDocument &&
                    this.state.mainCategoryName !== TARK_AK && (
                      <ReactSelect
                        className="dropdown"
                        isMulti={true}
                        placeholder={t.formatMessage({
                          id: 'documentMeta.room',
                        })}
                        options={this.props.rooms.map((v) => ({
                          label: v.name,
                          value: v.id,
                        }))}
                        value={this.state.selectedRooms}
                        onChange={(listOfValues: ValueType<Item>) => {
                          this.onChangeRooms(listOfValues as Item[]);
                        }}
                        isDisabled={this.props.rooms.length === 0}
                      />
                    )}
                  {isCustomerDocument ? (
                    <ReactSelect
                      className="dropdown"
                      isMulti={true}
                      placeholder={t.formatMessage({
                        id: 'documentMeta.documentCategory',
                      })}
                      options={this.props.docCategories.map((v) => ({
                        label: v.name,
                        value: v.id,
                      }))}
                      value={this.state.selectedDocCategories}
                      onChange={(listOfValues: ValueType<Item>) => {
                        this.onChangeDocCategories(listOfValues as Item[]);
                      }}
                    />
                  ) : null}
                  {isCustomerDocument ? (
                    <ReactSelect
                      className="dropdown"
                      isMulti={true}
                      placeholder={t.formatMessage({
                        id: 'documentMeta.designDiscipline',
                      })}
                      options={this.props.designDisciplines.map((v) => ({
                        label: v.name,
                        value: v.id,
                      }))}
                      value={this.state.selectedDesignDisciplines}
                      onChange={(listOfValues: ValueType<Item>) => {
                        this.onChangeDesignDisciplines(listOfValues as Item[]);
                      }}
                    />
                  ) : null}
                  {!isCustomerDocument &&
                  this.state.mainCategoryName !== TARK_AK ? (
                    <ReactSelect
                      className="dropdown"
                      isMulti={true}
                      placeholder={t.formatMessage({
                        id: 'documentMeta.contractorWorkType',
                      })}
                      options={this.props.getContractorWorkTypes.map((v) => ({
                        label: v.name,
                        value: v.id,
                      }))}
                      value={this.state.selectedContractorWorkTypes}
                      onChange={(listOfValues: ValueType<Item>) => {
                        this.onChangeContractorWorkTypes(
                          listOfValues as Item[]
                        );
                      }}
                    />
                  ) : null}
                  {!isCustomerDocument &&
                  this.state.mainCategoryName !== TARK_AK ? (
                    <ReactSelect
                      className="dropdown"
                      isMulti={true}
                      placeholder={t.formatMessage({
                        id: 'documentMeta.contractorDocumentType',
                      })}
                      options={this.props.getContractorDocumentypes.map(
                        (v) => ({
                          label: v.name,
                          value: v.id,
                        })
                      )}
                      value={this.state.selectedContractoDocumentTypes}
                      onChange={(listOfValues: ValueType<Item>) => {
                        this.onChangeContractorDocumentTypes(
                          listOfValues as Item[]
                        );
                      }}
                    />
                  ) : null}
                  {!isCustomerDocument &&
                  this.state.mainCategoryName !== TARK_AK ? (
                    <ReactSelect
                      className="dropdown"
                      isMulti={true}
                      placeholder={t.formatMessage({
                        id: 'documentMeta.targetTypes',
                      })}
                      options={this.props.targetPitemTypes.map((v) => ({
                        label: v.name,
                        value: v.id,
                      }))}
                      value={this.state.selectedTargetTypes}
                      onChange={(listOfValues: ValueType<Item>) => {
                        this.onChangeTargetTypes(listOfValues as Item[]);
                      }}
                    />
                  ) : null}
                  {this.state.mainCategoryName === TARK_AK && (
                    <ReactSelect
                      isMulti={true}
                      className="dropdown"
                      placeholder={t.formatMessage({
                        id: 'documentMeta.inspectionDocumentAttachmentDocumentType',
                      })}
                      options={this.props.inspectionDocCategories.map(
                        (type) => ({
                          label: t.formatMessage({ id: type.name }),
                          value: type.id,
                        })
                      )}
                      value={
                        this.state.selectedInspectionDocumentAttacmentCategories
                      }
                      onChange={(listOfValues: ValueType<Item>) => {
                        this.onChangeInspectionDocumentAttacmentCategory(
                          listOfValues as Item[]
                        );
                      }}
                      menuPortalTarget={document.body}
                    />
                  )}
                  {this.state.mainCategoryName !== TARK_AK &&
                  this.state.mainCategoryName !== URAK_DOC_PAKETTI ? (
                    <ReactSelect
                      className="dropdown"
                      isMulti={true}
                      placeholder={t.formatMessage({
                        id: 'documentMeta.fileExtension',
                      })}
                      options={this.props.fileTypes
                        .filter((value) => value)
                        .sort()
                        .map((type, i) => ({
                          label: type.toLocaleUpperCase(),
                          value: i,
                        }))}
                      value={this.state.selectedFileTypes}
                      onChange={(listOfValues: ValueType<Item>) => {
                        this.onChangeFileType(listOfValues as Item[]);
                      }}
                    />
                  ) : null}
                  <ReactSelect
                    className="dropdown"
                    isMulti={true}
                    placeholder={t.formatMessage({
                      id: 'documentMeta.workflowState',
                    })}
                    options={this.props.appDocumentStates.map((v) => ({
                      label: t.formatMessage({
                        id: 'documentMeta.' + v.name,
                      }),
                      value: v.id,
                    }))}
                    value={this.state.selectedDocumentStates}
                    onChange={(listOfValues: ValueType<Item>) => {
                      this.onChangeWorkflowState(listOfValues as Item[]);
                    }}
                  />
                  {mainCategoryName !== TARK_AK ? <this.PoleRange /> : null}
                </>
              ) : (
                <Button
                  color="primary"
                  variant="text"
                  onClick={this.onAddNewSearchCondition}
                >
                  <FormattedMessage id="search.addSearchCondition" />
                </Button>
              )}

              <div className="submitSearch">
                <div className="filler" />
                <Button color="primary" type="submit">
                  <FormattedMessage id="buttons.search" />
                </Button>
              </div>
              <Button
                color="primary"
                variant="text"
                onClick={() => {
                  this.setState({
                    showColumnSelector: !this.state.showColumnSelector,
                  });
                }}
              >
                <Toc />
                {t.formatMessage({
                  id: 'search.columns',
                })}
              </Button>
              {this.state.showColumnSelector ? <this.ColumnSelector /> : null}
            </ValidatorForm>

            <Divider />
          </>
        ) : null}

        {this.state.mainCategoryName || this.state.mainCategoryIndex ? (
          <ValidatorForm onSubmit={this.handleSearchSave}>
            <h3>
              <FormattedMessage id="search.savedSearchHeader" />
            </h3>

            <TextField
              label={t.formatMessage({
                id: 'search.savedSearchName',
              })}
              defaultValue={this.state.searchRequest.name}
              onChange={this.onSearchNameChange}
            />

            <div className="saveSearch">
              <div className="filler" />
              <Button
                color="primary"
                type="submit"
                disabled={
                  !this.state.searchRequest.name ||
                  this.state.searchRequest.name.length === 0
                }
              >
                <FormattedMessage id="buttons.save" />
              </Button>
            </div>
          </ValidatorForm>
        ) : null}
      </>
    );
  }
}

const Search = appContainer.StateConnector(
  container.StateConnector(AbstractSearch)
);

export default injectIntl(Search);
