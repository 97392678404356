import * as React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import * as applicationState from '../../state/reducers/ApplicationState';
import { setBackgroundImage } from '../../utils';
import './adminTools.scss';
import './workflow.scss';
import * as _ from 'lodash';
import * as schema from '../../../../shared/src/model/projectSchema';
// import { errorMessage, successMessage } from '../Common/Notifier';

// const API = APIService(axios);
// const DOC = DocumentService(axios);

interface IWorkFlowsProps extends applicationState.IProps {
  activeAction?: schema.action;
  workflow?: schema.workflow;
  actions: schema.action[];
  onSelectAction?: (action: schema.action) => void;
  onSelectUnusedAction?: (
    from: schema.documentstate,
    to: schema.documentstate
  ) => void;
}

interface IWorkFlowsState {
  actions: schema.action[];
}

type WorkFlowsProps = IWorkFlowsProps & InjectedIntlProps;

const Node = (props: {
  x: number;
  y: number;
  text: string;
  fill?: string;
  hidden?: boolean;
}) => (
  <>
    <circle
      cx={props.x}
      cy={props.y}
      r="7"
      fillOpacity={props.hidden ? 0.1 : 0.9}
      fill={props.fill}
    />
    <text
      x={props.x + 10}
      y={props.y}
      fontSize="5"
      fillOpacity={props.hidden ? 0.1 : 0.9}
      opacity={props.hidden ? 0.1 : 0.9}
    >
      {props.text}
    </text>
  </>
);
const Edge = (props: {
  sx: number;
  sy: number;
  ex: number;
  ey: number;
  curvature: number;
  radius?: number;
  hidden?: boolean;
  onClick?: () => void;
  isActive?: boolean;
}) => {
  const halfx = (props.ex - props.sx) / 2;
  const halfy = (props.ey - props.sy) / 2;

  const radius = props.radius || 10;

  const alpha = Math.PI / 2;
  const cs = Math.cos(alpha);
  const sn = Math.sin(alpha);

  const len = Math.sqrt(halfx * halfx + halfy * halfy);

  const dx = (cs * halfx - sn * halfy) / len;
  const dy = (sn * halfx + cs * halfy) / len;

  const qx = props.sx + halfx + props.curvature * dx;
  const qy = props.sy + halfy + props.curvature * dy;

  // adjust start and end of the vector
  const q_s_dx = qx - props.sx;
  const q_s_dy = qy - props.sy;
  const q_e_dx = qx - props.ex;
  const q_e_dy = qy - props.ey;

  const q_sa_len = Math.sqrt(q_s_dx * q_s_dx + q_s_dy * q_s_dy);
  const q_ea_len = Math.sqrt(q_e_dx * q_e_dx + q_e_dy * q_e_dy);

  const q_sa_i = q_s_dx / q_sa_len;
  const q_sa_j = q_s_dy / q_sa_len;

  const q_ea_i = q_e_dx / q_ea_len;
  const q_ea_j = q_e_dy / q_ea_len;

  const end_thresold = 4;

  return (
    <>
      <path
        d={`M ${props.sx + q_sa_i * radius} ${props.sy + q_sa_j * radius} Q 
        ${qx} 
        ${qy} 
        ${props.ex + q_ea_i * (radius + end_thresold)} 
        ${props.ey + q_ea_j * (radius + end_thresold)}`}
        fill="none"
        opacity={props.hidden ? 0.1 : 0.7}
        stroke={props.isActive ? 'blue' : 'black'}
        markerEnd="url(#arrow)"
        className="edge"
        onClick={props.onClick}
      />
    </>
  );
};

class AbstractWorkFlows extends React.Component<
  WorkFlowsProps,
  IWorkFlowsState
> {
  constructor(props: WorkFlowsProps) {
    super(props);
    this.state = {
      actions: props.actions,
    };
  }

  componentDidMount = async () => {
    setBackgroundImage();
  };

  componentDidUpdate(prevProps: WorkFlowsProps) {
    if (prevProps.actions !== this.props.actions) {
      this.setState({ actions: this.props.actions });
    }
  }

  fromName = (state: string) => {
    return this.props.appDocumentStates
      .filter(s => s.name === state)
      .map(value => value.id);
  };

  actionFromName = (state: string) => {
    return this.props.appDocumentStates.filter(s => s.name === state);
  };

  onSelectAction = (state1: string, state2: string) => {
    const fromName = this.fromName;
    const s1 = fromName(state1);
    const s2 = fromName(state2);
    const matchAction = (action: schema.action) => {
      return (
        _.intersection([action.from_state_id], s1).length > 0 &&
        _.intersection([action.to_state_id], s2).length > 0
      );
    };
    const hasAction = this.state.actions.filter(matchAction);
    if (hasAction.length > 0) {
      if (this.props.onSelectAction) {
        this.props.onSelectAction(hasAction[0]);
      }
    } else {
      if (this.props.onSelectUnusedAction) {
        const a1 = this.actionFromName(state1);
        const a2 = this.actionFromName(state2);
        this.props.onSelectUnusedAction(a1[0], a2[0]);
      }
    }
  };

  toggleAction = (state1: string, state2: string) => {
    const fromName = this.fromName;
    const s1 = fromName(state1);
    const s2 = fromName(state2);
    const matchAction = (action: schema.action) => {
      return (
        _.intersection([action.from_state_id], s1).length > 0 &&
        _.intersection([action.to_state_id], s2).length > 0
      );
    };
    const hasAction = this.state.actions.filter(matchAction);
    if (hasAction.length > 0) {
      this.setState({
        actions: this.state.actions.filter(a => !matchAction(a)),
      });
    } else {
      this.setState({
        actions: [
          ...this.state.actions,
          {
            __tag: 'action',
            from_state_id: s1[0],
            to_state_id: s2[0],
            id: 0,
            action_name: '',
            workflow_id: this.props.workflow ? this.props.workflow.id : 0,
          },
        ],
      });
    }
  };

  render() {
    // const t = (id: string) => this.props.intl.formatMessage({ id });

    if (!this.props.workflow) {
      return <div />;
    }
    const fromName = this.fromName;
    const hasAction = (state1: string, state2: string) => {
      const s1 = fromName(state1);
      const s2 = fromName(state2);
      return (
        this.state.actions.filter(action => {
          return (
            _.intersection([action.from_state_id], s1).length > 0 &&
            _.intersection([action.to_state_id], s2).length > 0
          );
        }).length > 0
      );
    };
    const getActionName = (
      state1: string,
      state2: string
    ): string | undefined => {
      const s1 = fromName(state1);
      const s2 = fromName(state2);
      const act = this.state.actions
        .filter(action => {
          return (
            _.intersection([action.from_state_id], s1).length > 0 &&
            _.intersection([action.to_state_id], s2).length > 0
          );
        })
        .pop();
      if (!act) return undefined;
      return act.action_name!;
    };
    const hasState = (state: string) => {
      const s = fromName(state);
      return (
        this.state.actions.filter(action => {
          return (
            _.intersection([action.from_state_id], s).length > 0 ||
            _.intersection([action.to_state_id], s).length > 0
          );
        }).length > 0
      );
    };

    // onClick={() => this.toggleAction('SUBMITTED', 'INITIAL_ACCEPT')}
    return (
      <div>
        <svg
          className="workflowdiagram"
          viewBox="0 0 100 150"
          width="300"
          height="300"
        >
          <marker
            id="arrow"
            viewBox="0 0 10 10"
            refX="5"
            refY="5"
            markerWidth="6"
            markerHeight="6"
            orient="auto"
          >
            <path d="M 0 0 L 10 5 L 0 10 z" />
          </marker>
          <Node
            x={50}
            y={10}
            text="Odottaa tarkastusta!!"
            hidden={!hasState('SUBMITTED')}
          />
          <Edge
            sx={50}
            sy={10}
            ex={50}
            ey={50}
            curvature={0}
            hidden={!hasAction('SUBMITTED', 'INITIAL_ACCEPT')}
            onClick={() => this.onSelectAction('SUBMITTED', 'INITIAL_ACCEPT')}
            isActive={
              this.props.activeAction &&
              this.props.activeAction.action_name ===
                getActionName('SUBMITTED', 'INITIAL_ACCEPT')
            }
          />
          <Node
            x={50}
            y={50}
            text="Odottaa hyväksyntää"
            hidden={!hasState('INITIAL_ACCEPT')}
          />
          <Edge
            sx={50}
            sy={10}
            ex={50}
            ey={90}
            curvature={-30}
            hidden={!hasAction('SUBMITTED', 'ACCEPTED')}
            onClick={() => this.onSelectAction('SUBMITTED', 'ACCEPTED')}
            isActive={
              this.props.activeAction &&
              this.props.activeAction.action_name ===
                getActionName('SUBMITTED', 'ACCEPTED')
            }
          />
          <Edge
            sx={50}
            sy={50}
            ex={50}
            ey={90}
            curvature={0}
            hidden={!hasAction('INITIAL_ACCEPT', 'ACCEPTED')}
            onClick={() => this.onSelectAction('INITIAL_ACCEPT', 'ACCEPTED')}
            isActive={
              this.props.activeAction &&
              this.props.activeAction.action_name ===
                getActionName('INITIAL_ACCEPT', 'ACCEPTED')
            }
          />
          <Node
            x={50}
            y={90}
            text="Hyväksytty"
            fill="green"
            hidden={!hasState('ACCEPTED')}
          />
          <Edge
            sx={50}
            sy={10}
            ex={50}
            ey={130}
            curvature={60}
            hidden={!hasAction('SUBMITTED', 'REJECTED')}
            onClick={() => this.onSelectAction('SUBMITTED', 'REJECTED')}
            isActive={
              this.props.activeAction &&
              this.props.activeAction.action_name ===
                getActionName('SUBMITTED', 'REJECTED')
            }
          />
          <Edge
            sx={50}
            sy={50}
            ex={50}
            ey={130}
            curvature={30}
            hidden={!hasAction('INITIAL_ACCEPT', 'REJECTED')}
            onClick={() => this.onSelectAction('INITIAL_ACCEPT', 'REJECTED')}
            isActive={
              this.props.activeAction &&
              this.props.activeAction.action_name ===
                getActionName('INITIAL_ACCEPT', 'REJECTED')
            }
          />
          <Node
            x={50}
            y={130}
            text="Hylätty"
            fill="red"
            hidden={!hasState('REJECTED')}
          />
        </svg>
      </div>
    );
  }
}

const Workflows = applicationState.StateConnector(AbstractWorkFlows);

export default injectIntl(Workflows);
