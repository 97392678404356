import * as React from 'react';
import * as container from '../../state/reducers/AdminState';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import Button from '../Common/Button';
import './admin.scss';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { GenericTable } from './genericTable';
import { Fragment } from 'react';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import HighlightOff from '@material-ui/icons/HighlightOff';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '../Common/TextField';
import Refresh from '@material-ui/icons/Refresh';
import Divider from '@material-ui/core/Divider';
import { FixtureKeys } from '../../state/reducers/AdminState';
import * as secmodel from '../../../../shared/src/model/security';
import { AdminSettingsView } from './views/AdminSettingsView';
import FaceIcon from '@material-ui/icons/Face';
import { ListItemIcon } from '@material-ui/core';
import * as schema from '../../../../shared/src/model/projectSchema';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Switch from '@material-ui/core/Switch';
import Badge from '@material-ui/core/Badge';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
// import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Card from '@material-ui/core/Card';
// import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import FileInput from '../Common/FileInput';
import { DataTable } from '../Common/DataTable';
import { AdminSearch } from './AdminSearch';
import { AdminSearchResults } from './AdminSearchResults';

import NewUser from './newuser';

export type SettingsProps = InjectedIntlProps & container.IProps;

const safeJSONParse = (txt: string): any | undefined => {
  try {
    return JSON.parse(txt);
  } catch (e) {
    return undefined;
  }
};

const getStateAsTest = (
  systemModel?: secmodel.SystemSettings,
  id?: number
): string => {
  if (!systemModel || !id) return '';
  const state = systemModel.workflowStates.filter(item => item.id === id).pop();
  if (state) {
    return state.description || state.name || 'State' + state.id;
  }
  return '';
};

type BtnType = 'default' | 'primary' | 'secondary';
const AdminBtn = (props: { text: string; fn: () => void; type?: BtnType }) => {
  return (
    <Button
      color={props.type || 'primary'}
      className="adminBtn"
      onClick={props.fn}
    >
      {props.text}
    </Button>
  );
};

export class FixtureView extends React.PureComponent<
  SettingsProps,
  { value: string }
> {
  state = {
    value: '',
  };
  constructor(props: SettingsProps) {
    super(props);
    this.state = { value: JSON.stringify(this.props.dbFixture, null, 2) };
  }
  handleChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    this.setState({ value: event.target.value });
  }
  componentDidUpdate(prevProps: SubFixtureProps) {
    if (this.props.dbFixture !== prevProps.dbFixture) {
      this.setState({
        value: JSON.stringify(this.props.dbFixture, null, 2),
      });
    }
  }
  render() {
    return (
      <div>
        <AdminBtn
          fn={() =>
            this.props.updateLocalFixture(safeJSONParse(this.state.value))
          }
          text="Update"
        />
        <TextField
          value={this.state.value}
          fullWidth={true}
          multiline={true}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
            this.setState({ value: e.target.value });
          }}
          rows={50}
        />
      </div>
    );
  }
}

interface SubFixtureProps extends SettingsProps {
  fixtureName: FixtureKeys;
}

export class SubFixtureView extends React.Component<
  SubFixtureProps,
  { value: string }
> {
  state = {
    value: '',
  };
  constructor(props: SubFixtureProps) {
    super(props);
    console.log(props.fixtureName);
    this.state = {
      value: JSON.stringify(props.dbFixture[props.fixtureName], null, 2),
    };
  }

  componentDidUpdate(prevProps: SubFixtureProps) {
    if (
      this.props.dbFixture !== prevProps.dbFixture ||
      prevProps.fixtureName !== this.props.fixtureName
    ) {
      this.setState({
        value: JSON.stringify(
          this.props.dbFixture[this.props.fixtureName],
          null,
          2
        ),
      });
    }
  }

  handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ value: event.target.value });
  };
  render() {
    return (
      <div>
        <h2>{this.props.fixtureName}</h2>
        <AdminBtn
          fn={() => {
            const value = safeJSONParse(this.state.value);
            if (value) {
              this.props.updateLocalFixture({
                ...this.props.dbFixture,
                [this.props.fixtureName]: value,
              });
            }
          }}
          text="Update"
        />
        <AdminBtn
          fn={() => this.props.initDBWithFixture()}
          text="Save to Database"
          type="secondary"
        />
        <TextField
          value={this.state.value}
          fullWidth={true}
          multiline={true}
          onChange={this.handleChange}
          rowsMax={50}
        />
      </div>
    );
  }
}

const AdminDetailView = <T, K extends keyof T>(props: SettingsProps) => {
  switch (props.view) {
    case 'tests':
      if (props.activeTestSet && props.testResults) {
        const testSet = props.testResults
          .filter(tset => tset.service_name === props.activeTestSet)
          .pop();
        if (!testSet) {
          return <div />;
        }
        return (
          <Fragment>
            <AdminBtn
              fn={() => props.runOnlyTestset(props.activeTestSet || '')}
              text={'run only tests for ' + props.activeTestSet}
              type="default"
            />
            {testSet.testdata.testResults.map((suite, index) => {
              return (
                <List component="nav" key={index}>
                  {suite.testResults.map(test => {
                    return (
                      <Fragment key={test.fullName}>
                        <Divider light={true} />
                        <ListItem>
                          <ListItemText
                            primary={`[${test.status}] ` + test.fullName}
                            secondary={
                              <pre>{test.failureMessages.join('')}</pre>
                            }
                          />
                        </ListItem>
                      </Fragment>
                    );
                  })}
                </List>
              );
            })}
          </Fragment>
        );
      }
      return <div />;
    case 'search':
      return <AdminSearchResults />;
    case 'excels':
      if (props.latestUpload && props.latestUpload.documentListing) {
        return (
          <Fragment>
            <DataTable
              rows={props.latestUpload.documentListing.rows}
              keys={[
                'docLohko',
                'docNumero',
                'docSuunnitteluala',
                'docTunnus',
                'fileName',
                'dwgFileName',
              ]}
            />
          </Fragment>
        );
      }
      if (props.latestUpload && props.latestUpload.buildingListing) {
        return (
          <Fragment>
            <DataTable
              rows={props.latestUpload.buildingListing.rows}
              keys={['Id', 'Name', 'Order']}
            />
          </Fragment>
        );
      }
      if (props.latestUpload && props.latestUpload.roomListing) {
        return (
          <Fragment>
            <DataTable
              rows={props.latestUpload.roomListing.rows}
              keys={['Id', 'Floor', 'RoomId', 'Segment', 'RoomName']}
            />
          </Fragment>
        );
      }
      if (props.latestUpload && props.latestUpload.itemListing) {
        return (
          <Fragment>
            <DataTable
              rows={props.latestUpload.itemListing.rows}
              keys={[
                'Id',
                'Floor',
                'RoomId',
                'Segment',
                'RoomName',
                'Specifier',
                'Type',
              ]}
            />
          </Fragment>
        );
      }
      return <div />;

    case 'users':
      if (!props.systemModel) {
        props.loadSettings();
      }
      if (!props.systemView.activeUser || !props.systemView.userPrivileges) {
        return <NewUser />;
      }
      // Fill cache of aitem and pitems if it is not filled...
      const load_aitems: number[] = [];
      const load_pitems: number[] = [];
      /*
      props.systemView.userPrivileges.map(priv => {
        if (priv.aitem_id && !props.aitemCache[priv.aitem_id]) {
          load_aitems.push(priv.aitem_id);
        }
        if (priv.pitem_id && !props.aitemCache[priv.pitem_id]) {
          load_pitems.push(priv.pitem_id);
        }
      });
      */
      if (load_aitems.length > 0) {
        props.getAItems(load_aitems);
      }
      if (load_pitems.length > 0) {
        props.getPItems(load_pitems);
      }
      if (props.loading_aitems || props.loading_pitems) {
        return <div>Loading...</div>;
      }
      const grouped: { [key: string]: schema.role_privilege[] } = {};
      const groupKey = (rolePriv: schema.role_privilege): string => {
        const key = '' + rolePriv.id;
        // if (rolePriv.aitem_id) key += 'aitem:' + rolePriv.aitem_id;
        // if (rolePriv.pitem_id) key += 'aitem:' + rolePriv.pitem_id;
        return key;
      };
      props.systemView.userPrivileges.forEach(priv => {
        const key = groupKey(priv);
        if (!grouped[key]) {
          grouped[key] = [];
        }
        grouped[key].push(priv);
      });
      const user = props.systemView.activeUser;
      /*
      const getAItemName = (id: number | null | undefined): string => {
        if (!id) return '';
        const aitem = props.aitemCache[id];
        if (!aitem) return '';
        return 'Oikeudet urakkaan ' + aitem.name || '';
      };
      const getPItemName = (id?: number | null | undefined): string => {
        if (!id) return '';
        const pItem = props.pitemCache[id];
        if (!pItem) return '';
        return 'Oikeudet kohteeseen ' + pItem.name || '';
      };
      */
      const findPrivilege = (id: number): schema.privilege | undefined => {
        if (!props.systemModel) return undefined;
        return props.systemModel.privileges
          .filter(item => item.id === id)
          .pop();
      };
      /*
      const findRole = (id: number): schema.role | undefined => {
        if (!props.systemModel) return undefined;
        return props.systemModel.roles.filter(item => item.id === id).pop();
      };
      */
      const getPrivilegeDesc = (id: number): string => {
        const p = findPrivilege(id);
        if (p && p.description) {
          return p.description;
        }
        if (p && p.privilege_name) {
          return p.privilege_name;
        }
        return '';
      };
      return (
        <Fragment>
          <Card>
            <CardContent>
              <Typography color="textSecondary" gutterBottom={true}>
                {user.firstname + ' ' + user.lastname}
              </Typography>
            </CardContent>
          </Card>

          {Object.keys(grouped).map(gname => {
            if (!props.systemModel) return <p />;
            const list = grouped[gname];
            return (
              <Fragment key={gname}>
                <ExpansionPanel>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    Privilege
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <List component="nav">
                      {props.systemModel.privileges.map(priv => {
                        /*
                  <div>
                    <div>aitem: {priv.aitem_id}</div>
                    <div>pitem: {priv.pitem_id}</div>
                    <div>privilege: {priv.privilege_id}</div>
                    <div>role: {priv.role_id}</div>
                  </div>
                  */
                        const activePriv = list
                          .filter(p => {
                            return p.privilege_id === priv.id;
                          })
                          .pop();
                        const is_used = typeof activePriv !== 'undefined';
                        return (
                          <Fragment key={priv.id}>
                            <Divider light={true} />
                            <ListItem
                              button={true}
                              onClick={() => {
                                //
                              }}
                            >
                              <ListItemText
                                primary={getPrivilegeDesc(priv.id)}
                              />
                              <ListItemSecondaryAction>
                                <Switch
                                  onChange={() => {
                                    if (
                                      activePriv &&
                                      props.systemView.user_role_privileges
                                    ) {
                                      if (is_used) {
                                        const up = props.systemView.user_role_privileges
                                          .filter(
                                            p =>
                                              p.role_privilege_id ===
                                              activePriv.id
                                          )
                                          .pop();
                                        if (up) {
                                          console.log('Could remove', up);
                                          props.removeUserPrivilege(up);
                                        }
                                      }
                                    } else {
                                      console.log(
                                        'Should call add privilege to user'
                                      );
                                      props.addPrivilegeToUser({
                                        privilege_id: priv.id,
                                        user_id: user.id || 0,
                                        not_rule: false,
                                      });
                                    }
                                  }}
                                  checked={is_used}
                                />
                              </ListItemSecondaryAction>
                            </ListItem>
                          </Fragment>
                        );
                      })}
                    </List>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </Fragment>
            );
          })}
        </Fragment>
      );

    case 'settings':
      return (
        <div>
          <AdminSettingsView {...props} />
        </div>
      );
    case 'contracts':
      if (props.activeContract) {
        return (
          <div className="adminDetailsView">
            <Tooltip title="Add">
              <Button variant="fab" color="primary" aria-label="Add">
                <AddIcon />
              </Button>
            </Tooltip>
            <h2>
              {props.activeContract.name} ({props.activeContract.id})
            </h2>
            TODO: continue the details view here:
            <ul>
              <li>Add sub contracts</li>
              <li>List permissions</li>
              <li>List documents</li>
              <li>etc.</li>
            </ul>
          </div>
        );
      } else {
        return <div>No contract selected</div>;
      }
    case 'db':
      const key = props.activeFixtureTable;
      const fixture = props.dbFixture;

      if (
        key &&
        typeof fixture !== 'undefined' &&
        typeof fixture[key] !== 'undefined'
      ) {
        const list = fixture[key];
        if (list) {
          return (
            <div>
              <h2>Full Fixture</h2>
              <FixtureView {...props} />
            </div>
          );
        }
      }
      return (
        <div>
          {props.dbFixture ? <FixtureView {...props} /> : 'Fixture not loaded'}
        </div>
      );
  }
  return <div>View is not defined yet</div>;
};

const AdminSubview = <T, K extends keyof T>(props: SettingsProps) => {
  switch (props.view) {
    case 'tests':
      if (!props.testResults) {
        props.getTestResults();
        return <div />;
      }
      return (
        <Fragment>
          <Button
            variant="contained"
            color="primary"
            className={'button'}
            onClick={props.runTestResults}
          >
            <Refresh />
            Run Tests Again
          </Button>
          <List component="nav">
            {props.testResults.map(test => {
              return (
                <Fragment key={test.id}>
                  <Divider light={true} />

                  <ListItem
                    button={true}
                    onClick={() => {
                      props.setSelectedTestResult(test.service_name);
                    }}
                  >
                    <ListItemIcon>
                      {test.success ? <CheckCircleOutline /> : <HighlightOff />}
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        `(${test.failed_cnt + test.success_cnt}) ` +
                        test.service_name +
                        (!test.success
                          ? ` ${test.failed_cnt} failed`
                          : ` all good!`)
                      }
                    />
                  </ListItem>
                </Fragment>
              );
            })}
          </List>
        </Fragment>
      );
    case 'search':
      return (
        <Fragment>
          <AdminSearch />
        </Fragment>
      );
    case 'excels':
      return (
        <Fragment>
          <FileInput
            id="admin_excel_file"
            label="Valitse ylläpitäjän tiedosto"
            onChange={files => {
              props.uploadNewDocuments(files);
            }}
          />
          <FileInput
            id="document_collection"
            label="Valitse Asiakirjaluettelo"
            onChange={files => {
              props.uploadAdminDocumentCollection(files);
            }}
          />
        </Fragment>
      );
    case 'settings':
      if (typeof props.systemModel === 'undefined') {
        return <div />;
      }
      return (
        <div>
          <h2>Roles</h2>
          <List component="nav">
            {props.systemModel.roles.map(role => {
              return (
                <Fragment key={role.id}>
                  <Divider light={true} />

                  <ListItem
                    button={true}
                    onClick={() => {
                      props.setSystemView('roles');
                      props.setActiveRole(role);
                      props.loadRolePrivileges(role.id);
                    }}
                  >
                    <ListItemIcon>
                      <FaceIcon />
                    </ListItemIcon>
                    <ListItemText primary={role.role_name} />
                  </ListItem>
                </Fragment>
              );
            })}
          </List>
          <h2>Privileges</h2>
          {props.systemModel.privileges.map(privilege => {
            return (
              <Fragment key={privilege.id}>
                <Divider light={true} />
                <ListItem
                  button={true}
                  onClick={() => {
                    props.setSystemView('privileges');
                    props.setActivePrivilege(privilege);
                  }}
                >
                  <ListItemText
                    primary={privilege.description || privilege.privilege_name}
                  />
                </ListItem>
              </Fragment>
            );
          })}
          <h2>Workflow states</h2>
          {props.systemModel.workflowStates.map(state => {
            return (
              <Fragment key={state.id}>
                <Divider light={true} />
                <ListItem
                  button={true}
                  onClick={() => {
                    props.setSystemView('state');
                    // props.setActivePrivilege(privilege);
                  }}
                >
                  <ListItemText primary={state.description} />
                </ListItem>
              </Fragment>
            );
          })}
          <h2>Actions</h2>
          {props.systemModel.actions.map(state => {
            if (!state.from_state_id || !state.to_state_id) {
              return (
                <Fragment key={state.id}>
                  <Divider light={true} />
                  <ListItem button={true}>
                    <ListItemText
                      primary={'ERROR: Invalid state ID ' + state.id}
                    />
                  </ListItem>
                </Fragment>
              );
            }
            return (
              <Fragment key={state.id}>
                <Divider light={true} />
                <ListItem
                  button={true}
                  onClick={() => {
                    props.setSystemView('state');
                    // props.setActivePrivilege(privilege);
                  }}
                >
                  <ListItemText
                    primary={
                      getStateAsTest(props.systemModel, state.from_state_id) +
                      ' => ' +
                      getStateAsTest(props.systemModel, state.to_state_id)
                    }
                  />
                </ListItem>
              </Fragment>
            );
          })}
        </div>
      );
    case 'users':
      return (
        <div>
          <div style={{ height: '2em' }}>
            <h2 style={{ float: 'left' }}>Users</h2>
            <Button
              style={{ position: 'relative', float: 'right' }}
              color="primary"
              onClick={() => {
                props.newUser();
              }}
            >
              New user
            </Button>
          </div>
          <List component="nav">
            {props.users.map(user => {
              return (
                <Fragment key={user.id}>
                  <Divider light={true} />
                  <ListItem
                    button={true}
                    onClick={() => {
                      props.selectUser(user);
                    }}
                  >
                    <ListItemIcon>
                      <FaceIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={user.firstname + ' ' + user.lastname}
                    />
                  </ListItem>
                </Fragment>
              );
            })}
          </List>
        </div>
      );

    case 'contracts':
      return (
        <div>
          <h2>Contracts</h2>

          <Button
            variant="contained"
            color="primary"
            className={'button'}
            onClick={props.refreshContracts}
          >
            <Refresh />
            Refresh
          </Button>
          <GenericTable
            list={props.contractNodes}
            title="name"
            keys={['id', 'name']}
            loadChildren={(id: number) => {
              props.getChildContracts(id);
            }}
            activate={(id: number) => {
              props.setActiveContract(id);
            }}
            actions={[
              {
                name: '+ Sub',
                fn: id => {
                  console.log('Add Sub for', id);
                },
              },
            ]}
          />
        </div>
      );
    case 'items':
      return (
        <div>
          <h2>Items</h2>
          <GenericTable
            title="name"
            list={props.items}
            keys={['name', 'name_long', 'mfilesid']}
          />
        </div>
      );
    case 'db':
      const fixtureKeys: FixtureKeys[] = Object.keys(
        props.dbFixture || {}
      ) as FixtureKeys[];
      return (
        <div>
          <h2>Database Status</h2>
          <AdminBtn
            fn={() => props.initializeDB()}
            text="Full DB Reset"
            type="default"
          />
          <AdminBtn
            fn={() => props.loadFixture()}
            text="Load Fixture"
            type="default"
          />
          <AdminBtn
            fn={() => props.loadDocumentCategories()}
            text="Test"
            type="default"
          />

          {props.isResettingDatabase ? (
            <div>Resetting database, please wait about 42 seconds.</div>
          ) : props.didResetDatabase ? (
            <div>Database reset ready!</div>
          ) : (
            <div />
          )}

          {props.dbFixture ? (
            <List component="nav">
              {fixtureKeys.map(key => {
                return (
                  <Fragment key={key}>
                    <Divider light={true} />
                    <ListItem
                      button={true}
                      onClick={() => props.selectFictureTable(key)}
                    >
                      <ListItemText primary={key} />
                    </ListItem>
                  </Fragment>
                );
              })}
            </List>
          ) : (
            ''
          )}
        </div>
      );
  }
  return <div />;
};

export const AdminUI = injectIntl(
  container.StateConnector(
    class InnerClass extends React.Component<SettingsProps> {
      state = {
        value: 0,
      };
      handleChange = (event: React.ChangeEvent<{}>, value: any) => {
        this.setState({ value });
        switch (value) {
          case 0:
            this.props.loadSettings();
            this.props.setView('settings');
            break;
          case 1:
            this.props.getUsers();
            break;
          case 2:
            this.props.setView('db');
            break;
          case 3:
            this.props.getRootContractNodes();
            break;
          case 4:
            this.props.getItems();
            break;
          case 5:
            this.props.setView('excels');
            break;
          case 6:
            this.props.setView('search');
            break;
          case 7:
            this.props.setView('tests');
            break;
        }
      };
      render() {
        let failedTestCnt = 0;
        if (this.props.testResults) {
          failedTestCnt = this.props.testResults.filter(tr => !tr.success)
            .length;
        }
        return (
          <>
            <div className="component adminTabs">
              <Tabs
                value={this.state.value}
                onChange={this.handleChange}
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab label="Settings" />
                <Tab label="Users" />
                <Tab label="Database" />
                <Tab label="Contracts" />
                <Tab label="Items" />
                <Tab label="Excels" />
                <Tab label="Search" />
                <Tab
                  label={
                    <Badge
                      badgeContent={
                        this.props.isRunningTests
                          ? '🚀'
                          : this.props.testResults
                          ? this.props.testResults.filter(tr => !tr.success)
                              .length
                          : 0
                      }
                      invisible={
                        this.props.isRunningTests ? true : failedTestCnt === 0
                      }
                      color={failedTestCnt > 0 ? 'primary' : 'secondary'}
                    >
                      {this.props.isRunningTests
                        ? '🚀 Tests'
                        : failedTestCnt > 0
                        ? '⚠️ Tests'
                        : '😎 Tests'}
                    </Badge>
                  }
                />
              </Tabs>
            </div>
            <div className="component adminListLeft">
              <AdminSubview {...this.props} />
            </div>
            <div className="component adminListRight">
              <AdminDetailView {...this.props} />
            </div>
          </>
        );
      }
    }
  )
);
