import * as React from 'react';
import { ItemDefinitionRow } from '../../../../../shared/src/model/index';
import { useFormatMessage } from 'utils/translateHook';
import { DataTable } from '../../Common/DataTable';

interface ItemDataProps {
  items: ItemDefinitionRow[];
}

export function ShowItemRows(props: ItemDataProps) {
  const t = useFormatMessage();
  return (
    <div className="pitemList">
      <h2>{t('adminTools.newPitems')}</h2>
      <DataTable
        rows={props.items}
        keys={[
          'Id',
          'Floor',
          'EndPole',
          'StartPole',
          'RoomId',
          'Type',
          'Contracts',
          'Tracking',
        ]}
        align={{ Tracking: 'center' }}
        render={{
          Contracts: row => (
            <span>{row.Contracts && row.Contracts.join(', ')}</span>
          ),
          Tracking: row => (
            <div>
              <span className={`${row.Tracking ? 'dot red' : ''}`} />
            </div>
          ),
        }}
      />
    </div>
  );
}
