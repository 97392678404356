import * as model from '../../../../../shared/src/model';
import * as schema from '../../../../../shared/src/model/projectSchema';
import { Fixture } from '../../../../../shared/src/api/testing/types';
import * as testing from '../../../../../shared/src/api/testing/types';

import * as secmodel from '../../../../../shared/src/model/security';
/**
 * @client api
 */
export function APIService(axios: any) {
  return new class AssetPointAPI {
    // client for endpoint saveUserSettings
    async saveUserSettings(data:model.UserSettings)  {
      return (await axios.post(`/api/v1/user/saveUserSettings/`,data)).data;
    }
    // client for endpoint getUserSettings
    async getUserSettings()  {
      return (await axios.get(`/api/v1/user/userSettings/`,{params:{}})).data;
    }
    // client for endpoint saveUserFavorites
    async saveUserFavorites(data:model.UserFavorites)  {
      return (await axios.post(`/api/v1/user/saveUserFavorites/`,data)).data;
    }
    // client for endpoint getUserFavorites
    async getUserFavorites()  {
      return (await axios.get(`/api/v1/user/userFavorites/`,{params:{}})).data;
    }
    // client for endpoint saveUserSearches
    async saveUserSearches(data:model.UserSearches) : Promise<boolean> {
      return (await axios.post(`/api/v1/user/saveUserSearches/`,data)).data;
    }
    // client for endpoint getUserSearches
    async getUserSearches() : Promise<model.UserSearches> {
      return (await axios.get(`/api/v1/user/userSearches/`,{params:{}})).data;
    }
    // client for endpoint getUserInfo
    async getUserInfo() : Promise<schema.user> {
      return (await axios.get(`/api/v1/user/`,{params:{}})).data;
    }
    // client for endpoint signout
    async signout() : Promise<void> {
      return (await axios.get(`/api/v1/signout/`,{params:{}})).data;
    }
    // client for endpoint getDocumentMetadata
    async getDocumentMetadata() : Promise<model.UIDocumentMetadata> {
      return (await axios.get(`/api/v1/getDocumentMetadata/`,{params:{}})).data;
    }
    // client for endpoint getContractPermissions
    async getContractPermissions() : Promise<model.ContractPermissions> {
      return (await axios.get(`/api/v1/getContractPermissions/`,{params:{}})).data;
    }
    // client for endpoint getUserContracts
    async getUserContracts() : Promise<model.ContractInfo[]> {
      return (await axios.get(`/api/v1/contracts/`,{params:{}})).data;
    }
    // client for endpoint getBuildingsForContractName
    async getBuildingsForContractName(name:string) : Promise<schema.pitem[]> {
      return (await axios.get(`/api/v1/getBuildingsForContractName/${name}/`,{params:{}})).data;
    }
    // client for endpoint getBuildings
    async getBuildings() : Promise<schema.pitem[]> {
      return (await axios.get(`/api/v1/getBuildings/`,{params:{}})).data;
    }
    // client for endpoint getBuildingsForContracts
    async getBuildingsForContracts(contractIds:number[]) : Promise<schema.pitem[]> {
      return (await axios.post(`/api/v1/getBuildingsForContracts/`,contractIds)).data;
    }
    // client for endpoint getBuildingElementTree
    async getBuildingElementTree(contractId:number) : Promise<model.DBPItemTree[]> {
      return (await axios.get(`/api/v1/getBuildingElementTree/${contractId}/`,{params:{}})).data;
    }
    // client for endpoint getAvailableRoles
    async getAvailableRoles() : Promise<model.APRole[]> {
      return (await axios.get(`/api/v1/system/roles/`,{params:{}})).data;
    }
    // client for endpoint getAllContracts
    async getAllContracts() : Promise<schema.aitem[]> {
      return (await axios.get(`/api/v1/contracts/all/`,{params:{}})).data;
    }
    // client for endpoint removeAitem
    async removeAitem(params:{
    id: number;
    }) : Promise<boolean> {
      return (await axios.post(`/api/v1/removeAitem/`,params)).data;
    }
    // client for endpoint getAllItems
    async getAllItems() : Promise<schema.pitem[]> {
      return (await axios.get(`/api/v1/items/all/`,{params:{}})).data;
    }
    // client for endpoint ping
    async ping(msg:string) : Promise<string> {
      return (await axios.get(`/api/v1/ping/${msg}/`,{params:{}})).data;
    }
    // client for endpoint getPItems
    async getPItems(list:number[]) : Promise<schema.pitem[]> {
      return (await axios.post(`/api/v1/getPItems/`,list)).data;
    }
    // client for endpoint getAItems
    async getAItems(list:number[]) : Promise<schema.aitem[]> {
      return (await axios.post(`/api/v1/getAItems/`,list)).data;
    }
    // client for endpoint getUserPrivileges
    async getUserPrivileges(userId:number) : Promise<schema.role_privilege[]> {
      return (await axios.get(`/api/v1/getUserPrivileges/${userId}/`,{params:{}})).data;
    }
    // client for endpoint getAllowedPItems
    async getAllowedPItems(searchRequest:model.SearchRequest) : Promise<model.UIDocument[]> {
      return (await axios.post(`/api/v1/getAllowedPItems/`,searchRequest)).data;
    }
    // client for endpoint searchPItems
    async searchPItems(searchRequest:model.SearchRequest) : Promise<{
    rows: model.UIDocument[];
    estimatedCnt: number;
    }> {
      return (await axios.post(`/api/v1/searchPItems/`,searchRequest)).data;
    }
    // client for endpoint updateUser
    async updateUser(userId:number, updateReq:model.UpdateUserRequest)  {
      return (await axios.post(`/api/v1/updateUser/${userId}/`,updateReq)).data;
    }
    // client for endpoint setSuperuserStatus
    async setSuperuserStatus(req:model.SetSuperuserStatusRequest)  {
      return (await axios.post(`/api/v1/setSuperuserStatus/`,req)).data;
    }
    // client for endpoint addRoleToUser
    async addRoleToUser(req:model.AddRoleRequest)  {
      return (await axios.post(`/api/v1/addRoleToUser/`,req)).data;
    }
    // client for endpoint removeRoleFromUser
    async removeRoleFromUser(req:model.AddRoleRequest)  {
      return (await axios.post(`/api/v1/removeRoleFromUser/`,req)).data;
    }
    // client for endpoint addRole
    async addRole(req:model.AddRoleRequest) : Promise<any> {
      return (await axios.post(`/api/v1/addRole/`,req)).data;
    }
    // client for endpoint getUserRolePrivileges
    async getUserRolePrivileges(userId:number) : Promise<schema.user_role_privilege[]> {
      return (await axios.get(`/api/v1/getUserRolePrivileges/${userId}/`,{params:{}})).data;
    }
    // client for endpoint getSystemAdminSettings
    async getSystemAdminSettings() : Promise<secmodel.SystemSettings> {
      return (await axios.get(`/api/v1/getSystemAdminSettings/`,{params:{}})).data;
    }
    // client for endpoint createRole
    async createRole(role:model.UIRole)  {
      return (await axios.post(`/api/v1/createRole/`,role)).data;
    }
    // client for endpoint addPrivilegesToRole
    async addPrivilegesToRole(request:{
    roleId: number;
    privileges: model.UIRolePrivilege[];
    })  {
      return (await axios.post(`/api/v1/addPrivilegesToRole/`,request)).data;
    }
    // client for endpoint removeRolePrivileges
    async removeRolePrivileges(privilegeIds:number[])  {
      return (await axios.post(`/api/v1/removeRolePrivileges/`,privilegeIds)).data;
    }
    // client for endpoint removeRole
    async removeRole(roleId:number)  {
      return (await axios.delete(`/api/v1/removeRole/${roleId}/`,{params:{}})).data;
    }
    // client for endpoint getPrivilegesForRole
    async getPrivilegesForRole(roleId:number) : Promise<model.UIRolePrivilege[]> {
      return (await axios.get(`/api/v1/getPrivilegesForRole/${roleId}/`,{params:{}})).data;
    }
    // client for endpoint getUserEmailSettings
    async getUserEmailSettings() : Promise<schema.user_email_settings[]> {
      return (await axios.get(`/api/v1/getUserEmailSettings/`,{params:{}})).data;
    }
    // client for endpoint updateUserEmailSettings
    async updateUserEmailSettings(data:{
    list: Array<Partial<schema.user_email_settings>>;
    }) : Promise<schema.user_email_settings[]> {
      return (await axios.post(`/api/v1/updateUserEmailSettings/`,data)).data;
    }
    // client for endpoint getRolePrivilegesForUser
    async getRolePrivilegesForUser(userId:number) : Promise<model.UIUserRolePrivilege[]> {
      return (await axios.get(`/api/v1/getRolePrivilegesForUser/${userId}/`,{params:{}})).data;
    }
    // client for endpoint getRootContractNodes
    async getRootContractNodes() : Promise<schema.aitem[]> {
      return (await axios.get(`/api/v1/getRootContractNodes/`,{params:{}})).data;
    }
    // client for endpoint getChildContractNodes
    async getChildContractNodes(parentContractId:number) : Promise<schema.aitem[]> {
      return (await axios.get(`/api/v1/getChildContractNodes/${parentContractId}/`,{params:{}})).data;
    }
    // client for endpoint getRootItems
    async getRootItems() : Promise<schema.pitem[]> {
      return (await axios.get(`/api/v1/getRootItems/`,{params:{}})).data;
    }
    // client for endpoint getDocumentTypes
    async getDocumentTypes() : Promise<testing.DocumentCategory[]> {
      return (await axios.get(`/api/v1/getDocumentTypes/`,{params:{}})).data;
    }
    // client for endpoint getChildrenOfItem
    async getChildrenOfItem(id:number) : Promise<schema.pitem[]> {
      return (await axios.get(`/api/v1/getChildrenOfItem/${id}/`,{params:{}})).data;
    }
    // client for endpoint systemDownMessage
    async systemDownMessage(str:string)  {
      return (await axios.get(`/api/v1/systemDownMessage/${str}/`,{params:{}})).data;
    }
    // client for endpoint createPItem
    async createPItem(options:model.NewPitem) : Promise<schema.pitem> {
      return (await axios.post(`/api/v1/createPItem/`,options)).data;
    }
    // client for endpoint removePItem
    async removePItem(pitemid:number) : Promise<boolean> {
      return (await axios.delete(`/api/v1/removePItem/${pitemid}/`,{params:{}})).data;
    }
    // client for endpoint getItems
    async getItems() : Promise<schema.pitem[]> {
      return (await axios.get(`/api/v1/getItems/`,{params:{}})).data;
    }
    // client for endpoint getContracts
    async getContracts() : Promise<schema.aitem[]> {
      return (await axios.get(`/api/v1/getContracts/`,{params:{}})).data;
    }
    // client for endpoint getBaseFixture
    async getBaseFixture() : Promise<Fixture> {
      return (await axios.get(`/api/v1/getBaseFixture/`,{params:{}})).data;
    }
    // client for endpoint initWithFixture
    async initWithFixture(data:Fixture) : Promise<boolean> {
      return (await axios.post(`/api/v1/initWithFixture/`,data)).data;
    }
    // client for endpoint updateCategories
    async updateCategories() : Promise<boolean> {
      return (await axios.get(`/api/v1/updateCategories/`,{params:{}})).data;
    }
    // client for endpoint getEnvironmentName
    async getEnvironmentName() : Promise<string> {
      return (await axios.get(`/api/v1/getEnvironmentName/`,{params:{}})).data;
    }
    // client for endpoint initializeDB
    async initializeDB() : Promise<string> {
      return (await axios.get(`/api/v1/initializeDB/`,{params:{}})).data;
    }
    // client for endpoint getLatestTestRuns
    async getLatestTestRuns() : Promise<model.TestResultRow[]> {
      return (await axios.get(`/api/v1/getLatestTestRuns/`,{params:{}})).data;
    }
    // client for endpoint runTests
    async runTests() : Promise<boolean> {
      return (await axios.get(`/api/v1/runTests/`,{params:{}})).data;
    }
    // client for endpoint getDBUsers
    async getDBUsers() : Promise<schema.user[]> {
      return (await axios.get(`/api/v1/getDBUsers/`,{params:{}})).data;
    }
    // client for endpoint getUser
    async getUser(userId:number) : Promise<schema.user> {
      return (await axios.get(`/api/v1/getUser/${userId}/`,{params:{}})).data;
    }
    // client for endpoint getWorkflows
    async getWorkflows() : Promise<schema.workflow[]> {
      return (await axios.get(`/api/v1/getWorkflows/`,{params:{}})).data;
    }
    // client for endpoint getActionRoles
    async getActionRoles(actionId:number) : Promise<schema.role[]> {
      return (await axios.get(`/api/v1/getActionRoles/${actionId}/`,{params:{}})).data;
    }
    // client for endpoint updateDocsToSystems
    async updateDocsToSystems(options:Array<model.DocSystemRow>) : Promise<Array<model.DocSystemRow>> {
      return (await axios.post(`/api/v1/updateDocsToSystems/`,options)).data;
    }
    // client for endpoint createBuilding
    async createBuilding(options:model.NewBuilding) : Promise<schema.pitem> {
      return (await axios.post(`/api/v1/createBuilding/`,options)).data;
    }
    // client for endpoint getActions
    async getActions(workflowId:number) : Promise<schema.action[]> {
      return (await axios.get(`/api/v1/getActions/${workflowId}/`,{params:{}})).data;
    }
    // client for endpoint getAllActions
    async getAllActions() : Promise<schema.action[]> {
      return (await axios.get(`/api/v1/getAllActions/`,{params:{}})).data;
    }
    // client for endpoint getWorkflowDocCategories
    async getWorkflowDocCategories(workflowId:number) : Promise<schema.document_category[]> {
      return (await axios.get(`/api/v1/getWorkflowDocCategories/${workflowId}/`,{params:{}})).data;
    }
    // client for endpoint getPrintOrder
    async getPrintOrder(id:number) : Promise<schema.print_order_row_document[]> {
      return (await axios.get(`/api/v1/getPrintOrder/${id}/`,{params:{}})).data;
    }
    // client for endpoint getLatestPrintOrders
    async getLatestPrintOrders() : Promise<schema.print_order_row[]> {
      return (await axios.get(`/api/v1/getLatestPrintOrders/`,{params:{}})).data;
    }
    // client for endpoint getLatestEmails
    async getLatestEmails() : Promise<schema.mail_task[]> {
      return (await axios.get(`/api/v1/getLatestEmails/`,{params:{}})).data;
    }
    // client for endpoint getEmailInfo
    async getEmailInfo(id:number) : Promise<schema.mail_task[]> {
      return (await axios.get(`/api/v1/getEmailInfo/${id}/`,{params:{}})).data;
    }
    // client for endpoint deletePitem
    async deletePitem(id:number) : Promise<boolean> {
      return (await axios.delete(`/api/v1/deletePitem/${id}/`,{params:{}})).data;
    }
    // client for endpoint findPitems
    async findPitems(params:{
    ids: number[];
    }) : Promise<schema.pitem[]> {
      return (await axios.post(`/api/v1/findPitems/`,params)).data;
    }
    // client for endpoint findPitemType
    async findPitemType(name:string) : Promise<schema.pitem_type> {
      return (await axios.get(`/api/v1/findPitemType/${name}/`,{params:{}})).data;
    }
    // client for endpoint createPitemType
    async createPitemType(name:string) : Promise<schema.pitem_type> {
      return (await axios.get(`/api/v1/createPitemType/${name}/`,{params:{}})).data;
    }
    // client for endpoint updatePitemCode
    async updatePitemCode(id:number, name:string) : Promise<schema.pitem> {
      return (await axios.get(`/api/v1/updatePitemCode/${id}/`,{params:{name}})).data;
    }
    // client for endpoint setTrackedStatus
    async setTrackedStatus(params:{
    id: number;
    isTracked: boolean;
    }) : Promise<schema.pitem> {
      return (await axios.post(`/api/v1/setTrackedStatus/`,params)).data;
    }
    // client for endpoint updatePitemParents
    async updatePitemParents(changeList:{
    id: number;
    parentName: string;
    }[]) : Promise<any> {
      return (await axios.post(`/api/v1/updatePitemParents/`,changeList)).data;
    }
    // client for endpoint updatePitemArea
    async updatePitemArea(pitemName:string, areaName:string) : Promise<schema.pitem> {
      return (await axios.get(`/api/v1/updatePitemArea/${pitemName}/`,{params:{areaName}})).data;
    }
    // client for endpoint updatePitemName
    async updatePitemName(id:number, name:string) : Promise<schema.pitem> {
      return (await axios.get(`/api/v1/updatePitemName/${id}/`,{params:{name}})).data;
    }
    // client for endpoint findRooms
    async findRooms(positions:model.RoomPosition[]) : Promise<schema.pitem[]> {
      return (await axios.post(`/api/v1/findRooms/`,positions)).data;
    }
    // client for endpoint getAreaDocumentStates
    async getAreaDocumentStates(area_id:number) : Promise<model.AreaDocumentState[]> {
      return (await axios.post(`/api/v1/getAreaDocumentStates/${area_id}/`,)).data;
    }
    // client for endpoint findRoom
    async findRoom(name:string) : Promise<schema.pitem> {
      return (await axios.get(`/api/v1/findRoom/${name}/`,{params:{}})).data;
    }
    // client for endpoint getAllBuildings
    async getAllBuildings() : Promise<schema.pitem[]> {
      return (await axios.post(`/api/v1/getAllBuildings/`,)).data;
    }
    // client for endpoint getPitemsOfType
    async getPitemsOfType(params:{
    buildings: string[];
    typename: string;
    }) : Promise<schema.pitem[]> {
      return (await axios.post(`/api/v1/getPitemsOfType/`,params)).data;
    }
    // client for endpoint getSystemRoles
    async getSystemRoles() : Promise<schema.role[]> {
      return (await axios.get(`/api/v1/getSystemRoles/`,{params:{}})).data;
    }
    // client for endpoint searchUsers
    async searchUsers(searchRequest:model.UserSearchRequest) : Promise<schema.user[]> {
      return (await axios.post(`/api/v1/searchUsers/`,searchRequest)).data;
    }
    // client for endpoint checkPItemDuplicate
    async checkPItemDuplicate(value:{
    name: string;
    }) : Promise<boolean> {
      return (await axios.post(`/api/v1/checkPItemDuplicate/`,value)).data;
    }
    // client for endpoint getPitemByName
    async getPitemByName(pitemName:string) : Promise<schema.pitem> {
      return (await axios.get(`/api/v1/getPitemByName/${pitemName}/`,{params:{}})).data;
    }
    // client for endpoint getUserRoles
    async getUserRoles(contractIds:number[]) : Promise<model.ContractRole[]> {
      return (await axios.post(`/api/v1/getUserRoles/`,contractIds)).data;
    }
    // client for endpoint getFileExtensions
    async getFileExtensions()  {
      return (await axios.get(`/api/v1/getFileExtensions/`,{params:{}})).data;
    }
    // client for endpoint getPhases
    async getPhases() : Promise<schema.aitem[]> {
      return (await axios.get(`/api/v1/getPhases/`,{params:{}})).data;
    }
    // client for endpoint createNewContract
    async createNewContract(params:{
    phaseId: number;
    contractName: string;
    longName: string;
    }) : Promise<schema.aitem> {
      return (await axios.post(`/api/v1/createNewContract/`,params)).data;
    }
    // client for endpoint createNewPhase
    async createNewPhase(params:{
    phaseName: string;
    longName: string;
    }) : Promise<schema.aitem> {
      return (await axios.post(`/api/v1/createNewPhase/`,params)).data;
    }
    // client for endpoint getAreas
    async getAreas() : Promise<model.Area[]> {
      return (await axios.get(`/api/v1/getAreas/`,{params:{}})).data;
    }
    // client for endpoint getAreaSections
    async getAreaSections(options:model.AreaSectionOptions) : Promise<schema.area_section[]> {
      return (await axios.post(`/api/v1/getAreaSections/`,options)).data;
    }
    // client for endpoint addAreas
    async addAreas(areas:model.AreaListing) : Promise<boolean> {
      return (await axios.post(`/api/v1/addAreas/`,areas)).data;
    }
    // client for endpoint updateArea
    async updateArea(area:schema.area) : Promise<boolean> {
      return (await axios.post(`/api/v1/updateArea/`,area)).data;
    }
    // client for endpoint updateAreaSection
    async updateAreaSection(section:schema.area_section) : Promise<boolean> {
      return (await axios.post(`/api/v1/updateAreaSection/`,section)).data;
    }
    // client for endpoint runMigration
    async runMigration(options:model.ImportSettings) : Promise<string> {
      return (await axios.post(`/api/v1/runMigration/`,options)).data;
    }
    // client for endpoint linkDocsToMFiles
    async linkDocsToMFiles(contracts:string[]) : Promise<any> {
      return (await axios.post(`/api/v1/linkDocsToMFiles/`,contracts)).data;
    }
    // client for endpoint getBackendInformation
    async getBackendInformation() : Promise<model.BackendInformation> {
      return (await axios.get(`/api/v1/getBackendInformation/`,{params:{}})).data;
    }
    // client for endpoint addWorkAitemsToPitem
    async addWorkAitemsToPitem(options:{
    pitemId: number;
    aitemNames: string[];
    }) : Promise<schema.pitem> {
      return (await axios.post(`/api/v1/addWorkAitemsToPitem/`,options)).data;
    }
    // client for endpoint updateDevices
    async updateDevices(deviceList:model.DeviceListRow[]) : Promise<boolean> {
      return (await axios.post(`/api/v1/updateDevices/`,deviceList)).data;
    }
  }
}
