import * as React from 'react';
import * as applicationState from '../state/reducers/ApplicationState';

import Login from '../components/Login';
import MainNavigation from '../components/MainNavigation';
import Sidebar from '../components/Sidebar';
import { FormattedMessage } from 'react-intl';
import { withSnackbar, InjectedNotistackProps } from 'notistack';
import { GlobalNotifier } from '../components/Common/Notifier';
import { Redirect } from 'react-router-dom';
import UserAgreement from '../components/Common/UserAgreements/UserAgreement';
import CircularProgress from '@material-ui/core/CircularProgress';
import Health from '../components/Common/Health';
import { ErrorBar } from 'components/Common/ErrorBar';
import { ErrorStateConsumer } from 'state/reducers/ErrorState';

interface IMainLayoutStateProps
  extends applicationState.IProps,
    InjectedNotistackProps {
  view?: string;
}

type MainLayoutProps = IMainLayoutStateProps;

interface IMainLayoutState {
  something: '';
}

class AbstractMainLayout extends React.Component<
  MainLayoutProps,
  IMainLayoutState
> {
  componentDidMount() {
    this.props.checkLoginStatus();

    // Only load user settings once
    if (!this.props.userSettings.version) {
      this.props.getUserSettings();
    }
  }

  render() {
    const { view } = this.props;

    switch (this.props.loginStatus) {
      case applicationState.LoginStatus.Logged:
        return (
          <div>
            <MainNavigation />

            <div className={`mainContentWrapper ${view ? view : ''}`}>
              <Sidebar />
              <div className="contentArea">
                <Health />

                {this.props.children}
              </div>
              <UserAgreement />
            </div>

            <ErrorStateConsumer>
              {ctx => <GlobalNotifier ctx={ctx} />}
            </ErrorStateConsumer>

            <div className="globalNotifications">
              <ErrorBar />
            </div>
          </div>
        );
      case applicationState.LoginStatus.NotLogged:
        return <Redirect to={'/login'} />;
      case applicationState.LoginStatus.Pending:
      case applicationState.LoginStatus.Failed:
        return <Login />;
      default:
        return (
          <div className="loadingMessage">
            <div className="box">
              <FormattedMessage id="messages.loading" />
              <CircularProgress className="spinner" size={32} color="primary" />
            </div>
          </div>
        );
    }
  }
}

const MainLayout = applicationState.StateConnector(
  withSnackbar(AbstractMainLayout)
);

export default MainLayout;
