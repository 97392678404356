import { FormattedMessage } from 'react-intl';
import Moment from 'react-moment';
import * as React from 'react';

interface IDateRow {
  id: string;
  value: string;
}
const DateRow = (props: IDateRow) => (
  <>
    <dt>
      <FormattedMessage id={props.id} />
    </dt>
    <dd>
      <Moment date={props.value} format="DD.MM.YYYY HH:mm" />
    </dd>
  </>
);

export default DateRow;
