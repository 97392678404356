import * as React from 'react';
import { injectIntl, InjectedIntlProps, FormattedMessage } from 'react-intl';
import ToggleArea from '../Common/ToggleArea';
import * as Model from '../../../../shared/src/model';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';

interface IRouterProps {
  rows: Model.UIDocument[];
  linkPrefix?: string;
}

interface IRouterState {
  email: string;
  password: string;
}

export class StatusHistory extends React.Component<
  IRouterProps & InjectedIntlProps,
  IRouterState
> {
  render() {
    const t = this.props.intl;
    const linkUrl = this.props.linkPrefix || '/documents/';
    return (
      <ToggleArea
        title={t.formatMessage({
          id: 'documentMeta.versionHistory',
        })}
      >
        <ol className="versionHistory">
          {this.props.rows.map((historyItem, index) => (
            <li key={index}>
              <div className="metadata">
                <span>
                  <FormattedMessage id="documentMeta.version" />:{' '}
                  {historyItem.versionNumber}
                </span>
                <span className="date">
                  <Moment
                    date={historyItem.created}
                    format="DD.MM.YYYY HH:mm"
                  />
                </span>

                <span title={historyItem.fileName}>
                  <Link to={`${linkUrl}${historyItem.id}`}>
                    {historyItem.fileName}
                  </Link>
                </span>

                <span title={historyItem.createdByUser}>
                  <FormattedMessage id="documentMeta.createdByUser" />:{' '}
                  {historyItem.createdByUser}
                </span>
              </div>
            </li>
          ))}
        </ol>
      </ToggleArea>
    );
  }
}

export default injectIntl(StatusHistory);
