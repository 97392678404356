import * as React from 'react';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import * as doc from '../../state/reducers/DocumentState';
import * as appState from '../../state/reducers/ApplicationState';
// import { errorMessage, successMessage } from './Notifier';

// import * as schema from '../../../../shared/src/model/projectSchema';

import { ListItem, List } from '@material-ui/core';
import { DocumentCategory } from '../../../../shared/src/model/index';
import Checkbox from '@material-ui/core/Checkbox';
// import { CheckBox } from '@material-ui/icons';

/*
import axios from 'axios';
import { DocumentService } from '../../services/document/src/frontend/api';
const DOCAPI = DocumentService(axios);
*/

interface IDocumentCategorySelectState {
  selectedCategories: number[];
}
interface IDocumentCategorySelectProps extends doc.IProps, appState.IProps {
  selectedCategories: number[];
  /*activeAction?: schema.action;
  documentIds: number[];
  onUpdate?: (didCancel: boolean) => void;*/
}
type ToolbarProps = IDocumentCategorySelectProps & InjectedIntlProps;

class DocumentCategorySelect extends React.Component<
  ToolbarProps,
  IDocumentCategorySelectState
> {
  constructor(props: ToolbarProps) {
    super(props);
    this.state = { selectedCategories: props.selectedCategories };
  }
  componentDidUpdate(prevProps: ToolbarProps) {
    if (prevProps.selectedCategories !== this.props.selectedCategories) {
      this.setState({
        selectedCategories: this.props.selectedCategories,
      });
    }
  }
  componentDidMount() {
    this.setState({
      selectedCategories: this.props.selectedCategories,
    });
  }

  categoryTreeLevel(list: DocumentCategory[], level = 0): JSX.Element {
    return (
      <>
        {list.map(item => {
          return (
            <React.Fragment key={item.id}>
              <ListItem style={{ paddingLeft: level * 20 }}>
                <Checkbox
                  checked={this.state.selectedCategories.indexOf(item.id) >= 0}
                />
                {item.name}
              </ListItem>
              {this.categoryTreeLevel(item.children || [], level + 1)}
            </React.Fragment>
          );
        })}
      </>
    );
  }

  public render() {
    // const t = this.props.intl;
    return <List>{this.categoryTreeLevel(this.props.categoryTree, 0)}</List>;
  }
}

export default appState.StateConnector(
  doc.StateConnector(injectIntl(DocumentCategorySelect))
);
