import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import { TextFieldProps as MuiTextFieldProps } from '@material-ui/core/TextField';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import Button from './Button';
import { FormattedMessage } from 'react-intl';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      fontSize: 16,
    },
  });

export interface ITextFieldProps {
  classes?: any;
}
export interface ITextFieldWithAcceptRejectProps {
  label: string;
  classes?: any;
  onAccept: (value: string) => void;
  onReject: () => void;
}

type TextFieldProps = MuiTextFieldProps & ITextFieldProps;

class UiTextField extends React.Component<TextFieldProps> {
  render() {
    return (
      <TextField
        InputLabelProps={{
          className: 'inputLabel',
        }}
        variant="standard"
        {...this.props}
        className={`${this.props.classes.root} ${this.props.className}`}
        fullWidth={true}
      />
    );
  }
}

class UITextFieldWithAcceptReject extends React.Component<
  ITextFieldWithAcceptRejectProps,
  { value: string }
> {
  constructor(props: ITextFieldWithAcceptRejectProps) {
    super(props);
    this.state = { value: '' };
  }
  onValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ value: e.target.value });
  };
  render() {
    const { onAccept, onReject, ...rest } = this.props;
    return (
      <div className="documentMeta component">
        <TextField
          {...rest}
          className={`${this.props.classes.root}`}
          onChange={this.onValue}
          value={this.state.value}
          margin="normal"
          variant="outlined"
          fullWidth={true}
        />
        <div className="editActions">
          <Button
            onClick={() => {
              this.props.onReject();
            }}
          >
            <FormattedMessage id="buttons.cancel" />
          </Button>
          <Button
            type="submit"
            color="primary"
            onClick={e => {
              this.props.onAccept(this.state.value);
            }}
          >
            <FormattedMessage id="buttons.save" />
          </Button>
        </div>
      </div>
    );
  }
}
export const TextFieldWithAcceptReject = withStyles(styles)(
  UITextFieldWithAcceptReject
);

export default withStyles(styles)(UiTextField);
