import './DocumentPackage.scss';

import * as Model from '../../../../shared/src/model';
import * as React from 'react';
import * as _ from 'lodash';
import * as container from '../../state/reducers/ApplicationState';
import * as documentState from '../../state/reducers/DocumentState';

import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import {
  getIdForCategoryName,
  getNameForCategoryId,
  getTypesUnderRootType,
} from '../../utils/metadata';

import { Async } from '../Common/Async';
import Button from '../Common/Button';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import ContractorDocTable from './ContractorDocTable';
import DocumentPackageStatusHistory from './DocumentPackageStatusHistory';
import { DocumentService } from '../../services/document/src/frontend/api';
import DpAcceptReject from './DocumentPackageAcceptReject';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FileInput from '../Common/FileInput';
import Loading from '../Common/Loading';
import { Theme } from '@material-ui/core';
import ToggleArea from '../Common/ToggleArea';
import { ValidatorForm } from 'react-material-ui-form-validator';
import axios from 'axios';
import { errorMessage } from '../Common/Notifier';
import { withStyles } from '@material-ui/core/styles';

// import AddWorkPhase from './AddWorkPhase';
// import { push } from 'connected-react-router';

const styles = (theme: Theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    flexBasis: '66.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    textAlign: 'right',
    flexBasis: '33.33%',
  } as CSSProperties,
});

// const API = APIService(axios);
const DOC = DocumentService(axios);

interface IDpProps {
  itemIds: number[];
  document?: Model.UIDocument;
  contractId?: number;
  workTypeId?: number;
  itemName: string;
  openByDefault?: boolean;
  onDocuments?: (rows: Model.UIDocument[]) => void;
  onWorflowChange?: () => void;

  classes: {
    heading: string;
    secondaryHeading: string;
  };
}

interface IDpState {
  selectedDocuments: Model.UIDocument[];
  openPanels: { [key: string]: boolean };
  addNewDocument?: string;
  newFileTypeId: number;
  uploading: boolean;
  uploadDone: boolean;
  uploadFile?: File;
  isMissingDocs: string;
  isCommentingOnMissingDocuments: boolean;
  whatIsMissing: string;
}

export class DPComponent extends React.Component<
  IDpProps & InjectedIntlProps & container.IProps & documentState.IProps,
  IDpState
> {
  state: IDpState = {
    selectedDocuments: [],
    openPanels: {},
    addNewDocument: '',
    newFileTypeId: 0,
    uploading: false,
    uploadDone: false,
    isMissingDocs: '',
    isCommentingOnMissingDocuments: false,
    whatIsMissing: '',
  };

  async uploadFile(firstDoc: Model.UIDocument, reload: () => void) {
    const file = this.state.uploadFile;
    if (!file) {
      return;
    }
    const doc = _.cloneDeep(firstDoc);
    const workCats = await getTypesUnderRootType(
      'Työlaji',
      firstDoc,
      this.props.getContractorWorkTypes
    );
    doc.categories = workCats.map((value) => value.id);
    doc.categories.push(this.state.newFileTypeId);
    doc.name = await getNameForCategoryId(this.state.newFileTypeId);

    const upload = async () => {
      this.setState({
        addNewDocument: '',
        uploading: true,
        uploadDone: false,
      });
      const formData = new FormData();
      formData.append('info', JSON.stringify(doc));
      formData.append('file', file);
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      try {
        await axios.post('/docs/v1/upload/contractordoc', formData, config);
        this.setState({
          uploading: false,
          uploadDone: true,
        });
        reload();
      } catch (e) {
        this.setState({
          uploading: false,
          uploadDone: true,
        });
        errorMessage(e);
      }
    };
    upload();
  }

  onMissingDocsCommentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ whatIsMissing: e.currentTarget.value });
  };

  saveCommentOnMissingDocuments = async (id: number, reload: () => void) => {
    try {
      await DOC.markMissingDocs({
        id,
        whatIsMissing: this.state.whatIsMissing,
        isMissingDocs: true,
      });

      this.setState({
        whatIsMissing: this.state.whatIsMissing,
        isCommentingOnMissingDocuments: false,
      });

      reload();
    } catch (e) {
      errorMessage(e);
    }
  };

  render() {
    const t = (id: string) => this.props.intl.formatMessage({ id });
    const allPitems = this.props.itemIds;

    const { isCommentingOnMissingDocuments } = this.state;
    const key = `ID: ${allPitems[0]}/${
      this.props.document
        ? `${this.props.document.id}/${this.props.document.modified}`
        : 0
    }/${this.props.workTypeId || 0}`;

    return (
      <Async
        uri={key}
        loader={() => getIdForCategoryName('URAK_DOC')}
        render={(urakDocsId) => (
          <ToggleArea
            open={this.props.openByDefault}
            classNames="documentPackageMetadata"
            title={t('documentMeta.workPhases')}
          >
            <Async
              uri={key}
              loader={async () => {
                const docCategories: number[] = [];
                if (this.props.workTypeId) {
                  docCategories.push(this.props.workTypeId);
                } else {
                  docCategories.push((await urakDocsId) || 0);
                }
                return {
                  rows: (
                    /*
                     This is not change to use new style of document package documents query (see searchDocumentPackageDocuments) because this component is used with other than document package content too
                     If need to change then first have to check that current document is URAK_DOC or URAK_DP
                     After that we need to
                     1. pass pitem_name to component and change param exactPitems => pitem_name
                     2. change param documentCategories => contractorWorkTypes
                     3. ensure that pitem_name, contractorWorkTypes and contractFilter values are not empty and array (All three are needed when finding URAK_DP)
                    */
                    await DOC.documentSearchRequest({
                      exactPitems: allPitems,
                      documentCategories: docCategories,
                      contractFilter: [
                        ...(this.props.contractId
                          ? [this.props.contractId]
                          : []),
                      ],
                    })
                  ).rows,
                  urakDocPackageId: await getIdForCategoryName('URAK_DP'),
                };
              }}
              render={(input, reload) => {
                if (!input || input.rows.length === 0) {
                  return (
                    <div className="inlineNotification">
                      {t('documentMeta.noDocumentPackages')}
                    </div>
                  );
                }
                const data = input.rows;
                if (data && this.props.onDocuments) {
                  this.props.onDocuments(data);
                }
                const groups = _.groupBy(data, (value) => {
                  const contractGrouper = value.aItems
                    .map((item) => item.name)
                    .join(' ');
                  if (typeof value === 'object' && value) {
                    const typeList = this.props.getContractorWorkTypes.filter(
                      (t) => {
                        if (!value.categories) {
                          return false;
                        }
                        return value.categories.indexOf(t.id) >= 0;
                      }
                    );
                    if (typeList.length === 1) {
                      return contractGrouper + ' ' + typeList[0].name;
                    }
                  }
                  return contractGrouper;
                });

                const keys = Object.keys(groups);
                const docPackages = _.mapValues(groups, (docs, _key) =>
                  docs
                    .filter((item) =>
                      item.categories
                        ? item.categories.indexOf(input.urakDocPackageId) >= 0
                        : false
                    )
                    .pop()
                );
                const canUpdate = false;

                const isMissingDocs = Object.keys(docPackages).some((key) => {
                  const pkg = docPackages[key];
                  return pkg ? pkg.isMissingDocs : false;
                });

                return (
                  // This feature is disbled until we find out about bugs concerning it
                  <>
                    {/*
                    <AddWorkPhase
                    // `allPitems[0]` here is propably not the value we want here
                      itemId={allPitems[0]}
                      contractId={this.props.contractId}
                      onAdd={docs => {
                        if (!docs || docs.length === 0) {
                          return;
                        }
                        if (this.props.workTypeId) {
                          this.props.DocumentStateDispatcher(
                            push('/documents/' + docs[0].id)
                          );
                        } else {
                          reload();
                        }
                      }}
                    /> */}

                    {isMissingDocs && (
                      <div className="isMissingDocuments">
                        <h2>
                          <FormattedMessage id="contractorDocumentPackage.isMissingDocuments" />
                        </h2>
                        <pre>
                          {Object.keys(docPackages)
                            .map((key) => {
                              const pkg = docPackages[key];
                              return pkg ? `${key}: ${pkg.whatIsMissing}` : '';
                            })
                            .join(', ')}
                        </pre>
                      </div>
                    )}

                    {keys.map((key) => {
                      const docs = groups[key];
                      const firstDoc = docs[0];
                      // Get proper docPackage or default to the first document
                      const docPackage = docPackages[key]
                        ? docPackages[key]
                        : firstDoc;

                      return (
                        <React.Fragment key={key}>
                          <ExpansionPanel key={key} expanded={true}>
                            <ExpansionPanelSummary
                              expandIcon={<ExpandMoreIcon />}
                              onClick={() => {
                                this.setState({
                                  openPanels: {
                                    ...this.state.openPanels,
                                    [key]: !this.state.openPanels[key],
                                  },
                                });
                              }}
                            >
                              <Typography
                                className={this.props.classes.heading}
                              >
                                {key}
                              </Typography>
                              <Typography
                                className={
                                  `workflow_${docPackage &&
                                    docPackage.workflowStateId} ` +
                                  this.props.classes.secondaryHeading
                                }
                              >
                                {docPackage
                                  ? t(
                                      `documentMeta.${docPackage.workflowStateName}`
                                    )
                                  : ''}
                              </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                              <div>
                                {docPackage && (
                                  <>
                                    <DocumentPackageStatusHistory
                                      doc={docPackage}
                                    />
                                    <DpAcceptReject
                                      docPackage={docPackage}
                                      onUpdated={() => {
                                        reload();
                                        if (this.props.onWorflowChange) {
                                          this.props.onWorflowChange();
                                        }
                                      }}
                                    />
                                  </>
                                )}
                                <div>
                                  <ContractorDocTable
                                    data={docs || []}
                                    reload={reload}
                                  />{' '}
                                </div>
                                <div className="addNewDocument">
                                  {canUpdate ? (
                                    <Button
                                      color="primary"
                                      type="submit"
                                      onClick={() => {
                                        this.setState({
                                          addNewDocument: this.state
                                            .addNewDocument
                                            ? ''
                                            : key,
                                        });
                                      }}
                                    >
                                      <FormattedMessage id="upload.document.add" />
                                    </Button>
                                  ) : (
                                    ''
                                  )}
                                  <Loading loading={this.state.uploading} />
                                </div>
                                {this.state.addNewDocument === key ? (
                                  <div className="urakDocInput">
                                    <form className="chooseDocumentType">
                                      <FormControl>
                                        <InputLabel htmlFor="document-type">
                                          <FormattedMessage id="documentMeta.selectType" />
                                        </InputLabel>
                                        <Select
                                          value={this.state.newFileTypeId}
                                          inputProps={{
                                            name: 'doctype',
                                            id: 'document-type',
                                          }}
                                          onChange={(e) => {
                                            this.setState({
                                              newFileTypeId: parseInt(
                                                e.target.value,
                                                10
                                              ),
                                            });
                                          }}
                                        >
                                          {this.props.getContractorDocumentypes.map(
                                            (item) => {
                                              return (
                                                <MenuItem
                                                  key={item.id}
                                                  value={item.id}
                                                >
                                                  {item.name}
                                                </MenuItem>
                                              );
                                            }
                                          )}
                                        </Select>
                                      </FormControl>
                                    </form>
                                    {this.state.newFileTypeId ? (
                                      <FileInput
                                        id="new-contractor-doc"
                                        single={true}
                                        label={t('upload.dropzoneInfo')}
                                        onChange={(files) => {
                                          this.setState({
                                            uploadFile: files[0],
                                          });
                                        }}
                                      />
                                    ) : null}
                                    <div className="editActions">
                                      <Button
                                        color="primary"
                                        type="submit"
                                        onClick={async () => {
                                          this.uploadFile(firstDoc, reload);
                                        }}
                                      >
                                        <FormattedMessage id="buttons.save" />
                                      </Button>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </ExpansionPanelDetails>
                          </ExpansionPanel>
                          <div className="commentOnMissingDocuments">
                            <div className="addCommentButton">
                              <div className="filler" />
                              <Button
                                className="addCommentButton"
                                disabled={isCommentingOnMissingDocuments}
                                onClick={() =>
                                  this.setState({
                                    isCommentingOnMissingDocuments: true,
                                  })
                                }
                              >
                                <FormattedMessage id="buttons.markAsIncomplete" />
                              </Button>
                            </div>
                            {isCommentingOnMissingDocuments && (
                              <ValidatorForm
                                onSubmit={() =>
                                  docPackage &&
                                  this.saveCommentOnMissingDocuments(
                                    docPackage.id,
                                    reload
                                  )
                                }
                              >
                                <TextField
                                  className="textField"
                                  multiline={true}
                                  value={this.state.whatIsMissing}
                                  onChange={this.onMissingDocsCommentChange}
                                  placeholder={t('inspectionDocuments.reason')}
                                  rows="3"
                                />

                                <div className="actions">
                                  <Button
                                    className="cancelButton"
                                    onClick={() =>
                                      this.setState({
                                        isCommentingOnMissingDocuments: false,
                                      })
                                    }
                                  >
                                    <FormattedMessage id="inspectionDocuments.cancel" />
                                  </Button>
                                  <Button type="submit" className="saveButton">
                                    <FormattedMessage id="inspectionDocuments.save" />
                                  </Button>
                                </div>
                              </ValidatorForm>
                            )}
                          </div>
                        </React.Fragment>
                      );
                    })}
                  </>
                );
              }}
            />
          </ToggleArea>
        )}
      />
    );
  }
}

export default withStyles(styles)(
  documentState.StateConnector(
    container.StateConnector(injectIntl(DPComponent))
  )
);
