import * as React from 'react';
import * as container from '../../state/reducers/ApplicationState';
import * as document from '../../state/reducers/DocumentState';
import { FormattedMessage, injectIntl, InjectedIntlProps } from 'react-intl';
import { DocumentRevision } from '../Common/DocumentMeta';
import Button from '../Common/Button';
import FileInput from '../Common/FileInput';
import IconButton from '@material-ui/core/IconButton';

import DeleteIcon from '@material-ui/icons/Delete';

import { Item } from '../Common/AutosuggestChipSelection';
import * as _ from 'lodash';
import * as Model from '../../../../shared/src/model';
import TextField from '@material-ui/core/TextField';
import { push } from 'connected-react-router';
import ReactSelect from 'react-select';
import { ValueType } from 'react-select/lib/types';

interface IUploadProps extends container.IProps, document.IProps {
  onCloseActionPanel: any;
  isOpen: boolean;
}

interface IUploadState extends Model.IUploadState {
  mainMode?: { value: number; label: string } | null;
  designDisciplines: Item[];
}

type UploadProps = IUploadProps & InjectedIntlProps;

const initialState = {
  files: [],
  contracts: [],
  subcontracts: [],
  items: [],
  documentTypes: [],
  documentCollectionFile: undefined,
  startPole: undefined,
  endPole: undefined,
  revision: undefined,
  description: undefined,
  dwgName: undefined,
  designDisciplines: [],
};

class AbstractUpload extends React.Component<UploadProps, IUploadState> {
  documentCollectionInputField: React.RefObject<
    HTMLInputElement
  > = React.createRef();

  constructor(props: UploadProps) {
    super(props);

    this.state = {
      ...initialState,
    };

    this.onDrop = this.onDrop.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleDocumentCollectionChange = this.handleDocumentCollectionChange.bind(
      this
    );
    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.onChangeContract = this.onChangeContract.bind(this);
    this.onChangeBuildingElements = this.onChangeBuildingElements.bind(this);
    this.onChangeDocumentCategory = this.onChangeDocumentCategory.bind(this);

    this.props.getUserContracts();
    this.props.getDocumentCategories();
  }

  onDrop(acceptedFiles: File[], rejectedFiles: File[]) {
    this.setState({ files: acceptedFiles });
  }

  handleFileChange(files: File[]) {
    if (files && files.length) {
      this.setState({ files });
    }
  }

  handleDocumentCollectionChange(files: FileList | null) {
    if (files && files.length) {
      this.setState({ documentCollectionFile: files[0] });
    } else {
      if (this.documentCollectionInputField.current) {
        this.documentCollectionInputField.current.value = '';
      }
      this.setState({ documentCollectionFile: undefined });
    }
  }

  onChangeDesignDisciplines = (designDisciplines: Item[]) => {
    this.setState({ designDisciplines });
  };

  async handleFileUpload() {
    const {
      contracts,
      documentCollectionFile,
      documentTypes,
      files,
      mainMode,
    } = this.state;

    if (!mainMode) {
      return;
    }
    if (mainMode.value === 2) {
      if (files) {
        const { files, mainMode, ...restProps } = this.state;

        this.props.uploadDesignerDocuments({
          ...restProps,
          files,
          documentCollectionFile: undefined,
        });
        this.props.onCloseActionPanel();
      }
      return;
    }
    if (mainMode.value === 1) {
      this.props.setDocCollection({
        contractId: contracts[0].value,
        disciplineId: documentTypes[0].value,
        files,
        onlyMetadata: false,
        documentCollection: documentCollectionFile,
      });
    }
    if (mainMode.value === 3) {
      this.props.setDocCollection({
        contractId: contracts[0].value,
        disciplineId: documentTypes[0].value,
        files: [],
        onlyMetadata: true,
        documentCollection: documentCollectionFile,
      });
    }

    this.props.DocumentStateDispatcher(push('/documentcollection/'));
    this.props.setSidebarVisibility({ isOpen: false });
  }

  onChangeContract(contracts: Item[]) {
    if (contracts !== this.state.contracts) {
      this.setState({
        contracts,
        items: [],
        documentTypes: [],
      });
      this.props.getBuildingElementTree(
        contracts.map((item: Item) => item.value)
      );
    }

    if (!contracts) {
      this.setState({
        items: [],
        documentTypes: [],
      });
    }
  }

  onChangeBuildingElements(sel: Item[]) {
    this.setState({
      items: sel,
    });
  }

  onChangeDocumentCategory(sel: Item[]) {
    this.setState({
      documentTypes: sel,
    });
  }

  onChangeStartPole = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ startPole: Number(e.target.value) });
  };
  onChangeEndPole = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ endPole: Number(e.target.value) });
  };
  onChangeDescription = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ description: e.target.value });
  };
  onChangeRevision = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ revision: e.target.value });
  };
  onChangeDocumentLink = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ dwgName: e.target.value });
  };

  addContractorDocuments = () => {
    this.props.setSidebarVisibility({ isOpen: false });
    this.props.DocumentStateDispatcher(push('/contracts/'));
  };

  public render() {
    const t = (id: string) => this.props.intl.formatMessage({ id });
    const { contractPermissions } = this.props;
    const {
      mainMode,
      contracts,
      designDisciplines,
      documentTypes,
      files,
      documentCollectionFile,
    } = this.state;

    const uploading = this.props.docstatus.uploadDesignerDocuments.loading;

    const createPermissions = contractPermissions
      ? contractPermissions.createPermissions
      : [];
    const canUpdateContarctorDocs =
      createPermissions.filter(
        permission => permission.document_category_name === 'URAK_DOC'
      ).length > 0;

    const topLevelOptions = [
      {
        label: t('documentMeta.multipleDocuments'),
        value: 1,
      },
      { label: t('documentMeta.assortedDocuments'), value: 2 },
      {
        label: t('documentMeta.updateMetadata'),
        value: 3,
      },
    ];

    if (canUpdateContarctorDocs) {
      topLevelOptions.push({
        label: t('upload.uploadContractorDocuments'),
        value: 4,
      });
    }

    const ContractSelector = () => (
      <ReactSelect
        className="dropdown"
        isMulti={false}
        placeholder={t('documentMeta.contract')}
        options={this.props.userContracts
          .filter(contract => {
            // Check if user has permission to upload designer documents for
            // this contract
            return (
              contractPermissions &&
              contractPermissions.createPermissions.some(
                permission =>
                  permission.aitem_id === contract.aitem_id &&
                  permission.document_category_name === 'TIL_DOC'
              )
            );
          })
          .map(v => ({
            label: v.aitem_name,
            value: v.aitem_id,
          }))}
        value={this.state.contracts}
        onChange={(listOfValues: ValueType<Item>) => {
          this.onChangeContract([listOfValues] as Item[]);
        }}
      />
    );

    const DocumentTypeSelector = () => (
      <ReactSelect
        className="dropdown"
        isMulti={false}
        placeholder={t('documentMeta.documentCategory')}
        options={this.props.docCategories.map(v => ({
          label: v.name,
          value: v.id,
        }))}
        value={documentTypes}
        onChange={(listOfValues: ValueType<Item>) => {
          this.onChangeDocumentCategory([listOfValues] as Item[]);
        }}
      />
    );

    const MetadataUpdater = () => (
      <div className="selectDocumentCollectionView">
        <h3>
          <FormattedMessage id="upload.documentCollection" />
        </h3>
        {documentCollectionFile && (
          <div className="selectedFile">
            <p title={documentCollectionFile.name}>
              {documentCollectionFile.name}
            </p>
            <IconButton
              onClick={() => this.handleDocumentCollectionChange(null)}
            >
              <DeleteIcon className="deleteIcon" />
            </IconButton>
          </div>
        )}

        <input
          ref={this.documentCollectionInputField}
          accept="*"
          style={{ display: 'none' }}
          id="raised-button-file"
          multiple={false}
          type="file"
          onChange={e => {
            this.handleDocumentCollectionChange(e.target.files);
          }}
        />
        <label htmlFor="raised-button-file">
          <Button color={'primary'} component="span" fullWidth={true}>
            {documentCollectionFile ? (
              <FormattedMessage id="upload.changeDocumentCollection" />
            ) : (
              <FormattedMessage id="upload.selectDocumentCollection" />
            )}
          </Button>
        </label>
      </div>
    );

    const SubmitButton = (props: any) => (
      <Button
        className="uploadSubmitButton"
        disabled={props.shouldBeDisabled || uploading}
        color={'primary'}
        fullWidth={true}
        onClick={this.handleFileUpload}
      >
        <FormattedMessage id="upload.save" />
      </Button>
    );

    const uploadFormSwitcher = (option: number) => {
      switch (option) {
        case 1:
          return multipleDocuments;
        case 2:
          return assortedDocuments;
        case 3:
          return updateMetadata;
        default:
          return <div />;
      }
    };

    const multipleDocuments = (
      <>
        <ContractSelector />
        {contracts.length > 0 && <DocumentTypeSelector />}
        {contracts.length > 0 && documentTypes.length > 0 && (
          <>
            <MetadataUpdater />
            <FileInput
              id="newDocumentUpload"
              label={t('upload.dropzoneInfo')}
              onChange={this.handleFileChange}
            />
            <SubmitButton
              shouldBeDisabled={
                contracts.length === 0 ||
                documentTypes.length === 0 ||
                files.length === 0 ||
                !documentCollectionFile
              }
            />
          </>
        )}
      </>
    );
    const assortedDocuments = (
      <>
        <ContractSelector />
        <ReactSelect
          className="dropdown"
          isMulti={false}
          placeholder={t('documentMeta.buildingElement')}
          value={this.state.items}
          options={this.props.uiBuildingElements}
          onChange={(listOfValues: ValueType<Item>) => {
            this.onChangeBuildingElements([listOfValues] as Item[]);
          }}
        />
        <DocumentTypeSelector />
        <ReactSelect
          className="dropdown"
          isMulti={true}
          placeholder={t('documentMeta.designDiscipline')}
          options={this.props.designDisciplines.map(v => ({
            label: v.name,
            value: v.id,
          }))}
          value={this.state.designDisciplines}
          onChange={(listOfValues: ValueType<Item>) => {
            this.onChangeDesignDisciplines(listOfValues as Item[]);
          }}
        />

        {contracts.length > 0 &&
          documentTypes.length > 0 &&
          designDisciplines.length > 0 && (
            <>
              <div>
                <TextField
                  fullWidth={true}
                  type="Number"
                  value={this.state.startPole || ''}
                  onChange={this.onChangeStartPole}
                  label={t('documentMeta.startPole')}
                />
                <TextField
                  fullWidth={true}
                  type="Number"
                  value={this.state.endPole || ''}
                  onChange={this.onChangeEndPole}
                  label={t('documentMeta.endPole')}
                />
                <TextField
                  fullWidth={true}
                  value={this.state.description || ''}
                  onChange={this.onChangeDescription}
                  label={t('documentMeta.description')}
                />
                <DocumentRevision
                  value={this.state.revision || ''}
                  onChange={this.onChangeRevision}
                />
                <TextField
                  fullWidth={true}
                  value={this.state.dwgName || ''}
                  onChange={this.onChangeDocumentLink}
                  label={t('documentMeta.documentLink')}
                />
              </div>

              <FileInput
                id="newDocumentUpload"
                label={t('upload.dropzoneInfo')}
                onChange={this.handleFileChange}
              />

              <SubmitButton
                shouldBeDisabled={
                  contracts.length === 0 ||
                  documentTypes.length === 0 ||
                  designDisciplines.length === 0 ||
                  files.length === 0
                }
              />
            </>
          )}
      </>
    );
    const updateMetadata = (
      <>
        <ContractSelector />
        <DocumentTypeSelector />
        <MetadataUpdater />
        <SubmitButton
          shouldBeDisabled={
            contracts.length === 0 ||
            documentTypes.length === 0 ||
            !documentCollectionFile
          }
        />
      </>
    );

    return (
      <>
        <h2>{t('upload.addDocuments')}</h2>

        <form>
          <ReactSelect
            className="dropdown"
            isMulti={false}
            options={topLevelOptions}
            placeholder={t('documentMeta.chooseUploadMethod')}
            value={mainMode}
            onChange={(item: Item) => {
              if (item && !Array.isArray(item) && item) {
                if (item.value !== 4) {
                  this.setState({ mainMode: item as Item });
                } else {
                  // Redirect to contractor document upload page
                  this.addContractorDocuments();
                }
              }
            }}
          />

          {mainMode && <h2>{t('upload.provideMetadata')}</h2>}

          {mainMode && uploadFormSwitcher(mainMode.value)}
        </form>
      </>
    );
  }
}

export default document.StateConnector(
  container.StateConnector(injectIntl(AbstractUpload))
);
