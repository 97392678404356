import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import themeColors from './styles/_theme.scss';

declare module '@material-ui/core/styles/createMuiTheme' {
  interface ThemeOptions {
    themeName?: string;
  }
}

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: themeColors.mainAccentColor,
    },
    secondary: {
      main: themeColors.secondaryAccentColor,
    },
  },
  shape: {
    borderRadius: 5,
  },

  themeName: 'Main theme',
  typography: {
    useNextVariants: true,
  },
  props: {
    MuiCheckbox: {
      color: 'primary',
    },
    MuiButton: {
      color: 'primary',
    },
    MuiChip: {
      color: 'primary',
    },
  },
});
