import * as React from 'react';
import * as _ from 'lodash';
import FileInput from 'components/Common/FileInput';
import { useFormatMessage } from 'utils/translateHook';
import {
  readFileToWorksheet,
  parseAreaInformation,
} from '../../../utils/excel';

interface AreaImportProps {
  onData?: (data: any) => void;
}

export function AreaExcelImport(props: AreaImportProps) {
  const [files, setFiles] = React.useState<File[]>([]);
  const t = useFormatMessage();
  return (
    <FileInput
      id="area_excel_import"
      files={files}
      label={t('upload.dropzoneInfo')}
      fileListRenderer={() => <div />}
      onChange={async files => {
        if (props.onData) {
          props.onData(
            parseAreaInformation(await readFileToWorksheet(files[0]))
          );
          setFiles([]);
        }
      }}
    />
  );
}
