import * as React from 'react';
import { createContext, useContext, Component } from 'react';
import * as container from 'state/reducers/ApplicationState';
import * as docContainer from 'state/reducers/DocumentState';
import * as adminContainer from 'state/reducers/AdminState';

export const AppContext = createContext<container.IProps>(
  {} as container.IProps
);
export const DocContext = createContext<docContainer.IProps>(
  {} as docContainer.IProps
);
export const AdminContext = createContext<adminContainer.IProps>(
  {} as adminContainer.IProps
);

class MainAppContextProvider extends Component<container.IProps> {
  constructor(props: container.IProps) {
    super(props);
  }
  public render() {
    return (
      <AppContext.Provider value={this.props}>
        {this.props.children}
      </AppContext.Provider>
    );
  }
}
class MainDocContextProvider extends Component<docContainer.IProps> {
  constructor(props: docContainer.IProps) {
    super(props);
  }
  public render() {
    return (
      <DocContext.Provider value={this.props}>
        {this.props.children}
      </DocContext.Provider>
    );
  }
}
class MainAdminContextProvider extends Component<adminContainer.IProps> {
  constructor(props: adminContainer.IProps) {
    super(props);
  }
  public render() {
    return (
      <AdminContext.Provider value={this.props}>
        {this.props.children}
      </AdminContext.Provider>
    );
  }
}
export const AppContextProvider = container.StateConnector(
  MainAppContextProvider
);
export const DocContextProvider = docContainer.StateConnector(
  MainDocContextProvider
);
export const AdminContextProvider = adminContainer.StateConnector(
  MainAdminContextProvider
);

export function useAppContext() {
  return useContext(AppContext);
}
export function useDocContext() {
  return useContext(DocContext);
}
export function useAdminContext() {
  return useContext(AdminContext);
}
