/**********************************************************************************
 *                                                                                 *
 *   Redux Reducers and React Context API Provider/Consumer for state PitemState   *
 *   Generated by ts2redux from Source file ../PitemState.ts                       *
 *                                                                                 *
 **********************************************************************************/

import * as immer from 'immer';
import { connect } from 'react-redux';
import { IState } from './index';
import * as React from 'react';
import * as schema from '../../../../../../shared/src/model/projectSchema';
import {
  getAllBuildings,
  findPitemsForType,
  findRooms,
  findPitemType,
  createNewPItem,
  checkPItemDuplicate,
  getPitemByName,
  updatePitemNAme,
} from '../../../../state/api';
import * as _ from 'lodash';
import {
  ItemListing,
  RoomPosition,
  DeviceListRow,
  ItemDefinitionRow,
  RoomListing,
  RoomDefinitionRow,
} from '../../../../../../shared/src/model/index';
import { Diff } from '../controller';
import { errorMessage } from 'components/Common/Notifier';
import axios from 'axios';
import { APIService } from '../../../../services/api/src/frontend/api';
const API = APIService(axios);

export interface RoomNameChange {
  roomId: string;
  oldName: string;
  newName: string;
  row: RoomDefinitionRow;
  databaseId: number;
}

export interface RoomBuildingChange {
  roomId: string;
  newFloorName: string;
  newBuildingName: string;
  newBuildingId: number;
  row: RoomDefinitionRow;
  databaseId: number;
}

export interface ItemDataCheckResults {
  success: boolean;
  data: ItemListing;
  missingPitemType?: string;
}

export interface SelectedSegment {
  segment: schema.pitem;
  difference: Diff;
}

/**
 * @redux true
 */
export class PitemState {
  missingAreas: RoomDefinitionRow[] = [];
  missingRooms: RoomDefinitionRow[] = [];
  alreadyAddedRooms: RoomDefinitionRow[] = [];
  nameChangedForRooms: RoomNameChange[] = [];
  buildingChangedForRooms: RoomBuildingChange[] = [];

  // models for pitem import
  updatedData?: ItemListing;
  buildings: schema.pitem[] = [];
  remoteItems: schema.pitem[] = [];
  rooms: schema.pitem[] = [];
  selectedSegment?: SelectedSegment;
  positions: RoomPosition[] = [];
  isUploading = false;
  areasMissingFromDatabase: string[] = [];

  clearState() {
    this.buildings = [];
    this.remoteItems = [];
    this.rooms = [];
    this.positions = [];
  }

  setUploading(value: boolean) {
    this.isUploading = value;
  }

  setSelectedSegment(seg: SelectedSegment) {
    this.selectedSegment = seg;
  }

  async updateRoomData(inputData: RoomListing) {
    await this.findRooms(
      inputData.rows.map((room) => {
        return {
          segmentName: room.Segment,
          roomId: room.Id.trim(),
        };
      })
    );

    const duplicates: { [key: string]: boolean } = {};

    inputData.rows.forEach((r) => {
      if (duplicates[r.Id.trim()]) {
        errorMessage(`Aineistossa on duplikaatti ${r.Id}`);
      }
      duplicates[r.Id.trim()] = true;
    });

    this.buildings = await getAllBuildings();

    const existingBuildings = this.buildings.reduce((prev, curr) => {
      return {
        ...prev,
        [curr.name]: curr,
      };
    }, {} as { [key: string]: schema.pitem });

    for (const item of inputData.rows) {
      if (!existingBuildings[item.Segment]) {
        errorMessage(`Rakennusosaa ei ole olemassa ${item.Segment}`);
      }
    }

    // const remoteRooms = _.uniq(this.rooms.map((r) => r.name.trim()));
    // const newRooms = _.uniq(inputData.rows.map((r) => r.Id.trim()));

    const existingRooms: { [key: string]: schema.pitem } = this.rooms.reduce(
      (prev, curr) => {
        return { ...prev, [curr.name]: curr };
      },
      {}
    );
    const areasInDB = await API.getAreas();

    this.areasMissingFromDatabase = [];

    const missingList: string[] = [];

    this.missingAreas = inputData.rows.filter((r) => {
      if (!r.AreaName) {
        return false;
      }
      if (!existingRooms[r.Id.trim()]) {
        return false;
      }
      const old = existingRooms[r.Id.trim()];
      if (!old.area_id) {
        return true;
      }
      const correspondingArea = areasInDB
        .filter((a) => a.area_name === r.AreaName)
        .pop();
      if (!correspondingArea) {
        missingList.push(r.AreaName);
        return false;
      }
      if (correspondingArea.area_name !== r.AreaName) {
        return true;
      }
      return false;
    });

    // These areas are missing from the database at the moment
    this.areasMissingFromDatabase = _.uniq(missingList);

    // TODO: load add floors of the selected rooms

    let allParents: number[] = [];
    this.rooms.forEach((p) => {
      allParents = [...allParents, ...(p.parents || [])];
    });

    const all = await API.findPitems({ ids: _.uniq(allParents) });
    const findFloor = (buildingName: string, floorName: string) => {
      return all
        .filter((item) => {
          if (item.name === `${buildingName}-${floorName}`) {
            return true;
          }
          return false;
        })
        .pop();
    };

    this.buildingChangedForRooms = inputData.rows
      .filter((r) => {
        const theFloor = findFloor(r.Segment.trim(), r.Floor.trim());
        // if floor can not be found from the existing parents list
        // it must have been changed.
        if (!theFloor) {
          return true;
        }
        const theRoom = existingRooms[r.Id.trim()];
        // if the room does not currently belong to the new floor it has
        // changed location
        if (theRoom) {
          if (theRoom.parents && !theRoom.parents!.includes(theFloor.id)) {
            return true;
          }
        }
        return false;
      })
      .map((row) => {
        const old = existingRooms[row.Id.trim()];
        return {
          row,
          newBuildingName: row.Segment,
          newFloorName: row.Floor,
          newBuildingId: existingBuildings[row.Segment].id,
          roomId: row.Id,
          databaseId: old.id,
        };
      });

    this.nameChangedForRooms = inputData.rows
      .filter((r) => {
        const old = existingRooms[r.Id.trim()];
        if (old) {
          if (
            (r.RoomName || '')
              .trim()
              .localeCompare((old.name_long || '').trim()) !== 0
          ) {
            return true;
          }
        }
        return false;
      })
      .map((row) => {
        const old = existingRooms[row.Id.trim()];
        return {
          row,
          oldName: old.name_long!,
          newName: row.RoomName,
          roomId: row.Id,
          databaseId: old.id,
        };
      });
    this.missingRooms = inputData.rows.filter((r) => {
      return !existingRooms[r.Id.trim()];
    });
    this.alreadyAddedRooms = inputData.rows.filter((r) => {
      return existingRooms[r.Id.trim()];
    });
  }

  async updateChangedRooms() {
    try {
      const list = this.buildingChangedForRooms.map((ch) => {
        return {
          id: ch.databaseId,
          parentName: `${ch.newBuildingName}-${ch.newFloorName}`,
        };
      });
      if (list.length > 0) {
        await API.updatePitemParents(list);
      }
    } catch (e) {
      errorMessage(e);
    }
  }

  async updateRoomAreas() {
    try {
      const list = this.missingAreas;
      for (const item of list) {
        // where do we place the item.
        // the code of the room
        try {
          if (item.AreaName) {
            await API.updatePitemArea(item.Id, item.AreaName);
          }
        } catch (e) {
          errorMessage(e);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  async updateRoomNames() {
    try {
      const list = this.nameChangedForRooms;
      for (const item of list) {
        // where do we place the item.
        // the code of the room
        try {
          await updatePitemNAme(item.databaseId, item.newName);
        } catch (e) {
          errorMessage(e);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  async createNewRooms() {
    try {
      const list = this.missingRooms;
      const pitemType = await findPitemType('Huone');
      const kerrosType = await findPitemType('Kerros');
      const areas = await API.getAreas();
      const segmentByName: {
        [key: string]: schema.pitem;
      } = this.buildings.reduce((prev, curr) => {
        return { ...prev, [curr.name]: curr };
      }, {} as { [key: string]: schema.pitem });

      for (const item of list) {
        // where do we place the item.
        // the code of the room
        try {
          if (await checkPItemDuplicate(item.Id)) {
            errorMessage(`Kannassa on duplikaatti kohteelle ${item.Id}`);
            continue;
          }
          const [area] = areas.filter((a) => a.area_name === item.AreaName);
          const seg = segmentByName[item.Segment];
          const floorName = `${seg.name}-${item.Floor}`;
          const hasFloor = await checkPItemDuplicate(floorName);
          const floorOfSegment = hasFloor
            ? await getPitemByName(`${seg.name}-${item.Floor}`)
            : await createNewPItem({
                name: floorName,
                name_long: floorName,
                parentItemId: seg.id,
                pitemTypeId: kerrosType.id,
              });
          // Floor of the room should be found too...
          await createNewPItem({
            name: item.Id.trim(),
            name_long: item.RoomName,
            parentItemId: floorOfSegment.id,
            pitemTypeId: pitemType.id,
            areaName: area ? area.area_name! : undefined,
          });
        } catch (e) {
          errorMessage(e);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  async updateDeviceMetadata(options: { devices: DeviceListRow[] }) {
    try {
      const result = await API.updateDevices(options.devices);
      return result;
    } catch (error) {
      return error;
    }
  }

  async createNewPitems(options: {
    typeName: string;
    list: ItemDefinitionRow[];
  }) {
    const { list, typeName } = options;
    try {
      const pitemType = await findPitemType(typeName);
      const segmentByName: {
        [key: string]: schema.pitem;
      } = this.buildings.reduce((prev, curr) => {
        return { ...prev, [curr.name]: curr };
      }, {} as { [key: string]: schema.pitem });

      for (const item of list) {
        // where do we place the item.
        // the code of the room
        try {
          // NOTE: If you want to disallow updating items remove comment below
          // if (await checkPItemDuplicate(item.Id)) {
          //   errorMessage(`Kannassa on duplikaatti kohteelle ${item.Id}`);
          //   continue;
          // }

          const seg = segmentByName[item.Segment];
          const rooms = this.rooms.filter((r) => {
            return r.name === item.RoomId && (r.parents || []).includes(seg.id);
          });

          let createRoom = rooms[0];

          if (rooms.length === 0) {
            if (item.StartPole && item.EndPole) {
              const sp = Number(item.StartPole);
              const ep = Number(item.EndPole);
              const poleRangeType = await findPitemType('Paaluväli');
              if (!isNaN(sp)) {
                createRoom = await createNewPItem({
                  name: `PLV-${item.Segment}-${item.StartPole}`,
                  name_long: `Paaluväli ${item.StartPole}`,
                  parentItemId: seg.id,
                  pitemTypeId: poleRangeType.id,
                  poleRange: [sp, ep],
                });
              }
            } else {
              if (item.StartPole) {
                const sp = Number(item.StartPole);
                const singlePoleType = await findPitemType('Paalu');
                if (!isNaN(sp)) {
                  createRoom = await createNewPItem({
                    name: `PL-${item.Segment}-${item.StartPole}`,
                    name_long: `Paaluluku ${item.StartPole}`,
                    parentItemId: seg.id,
                    pitemTypeId: singlePoleType.id,
                    singlePole: sp,
                    // poleRange ??
                  });
                }
              }
            }
          }

          if (createRoom) {
            const room = createRoom;
            await createNewPItem({
              name: item.Id,
              name_long: item.Type,
              aItem_names: item.Contracts,
              parentItemId: room.id,
              pitemTypeId: pitemType.id,
              is_tracked: item.Tracking,
            });
          }
        } catch (e) {
          errorMessage(e);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  async checkItemData(inputData: ItemListing) {
    // 1. Check if the pitem type does exist
    const data = {
      ...inputData,
      rows: inputData.rows.map((r) => {
        return {
          ...r,
          RoomId:
            r.RoomId === '99-99999' || r.RoomId === '99'
              ? `${r.Segment}-${r.RoomId}`
              : r.RoomId,
        };
      }),
    };
    this.updatedData = data;

    try {
      await findPitemType(data.pitemType || '');
    } catch (e) {
      return {
        success: false,
        missingPitemType: data.pitemType,
      };
    }
    await this.findPitems({
      segments: _.uniq(data.rows.map((row) => row.Segment)),
      typeName: data.pitemType || '',
    });
    await this.getBuildings();

    const existingBuildings = this.buildings.reduce((prev, curr) => {
      return {
        ...prev,
        [curr.name]: true,
      };
    }, {} as { [key: string]: boolean });

    for (const row of data.rows) {
      if (!existingBuildings[row.Segment]) {
        errorMessage(`Rakennusosaa ei ole olemassa ${row.Segment}`);
      }
    }

    const roomList = data.rows.map((row) => {
      const Obj: RoomPosition = {
        segmentName: row.Segment,
        roomId: row.RoomId,
        original: row,
      };
      const sp = Number(row.StartPole);
      const ep = Number(row.EndPole);
      if (row.StartPole && row.EndPole) {
        if (isNaN(sp) || isNaN(ep)) {
          errorMessage(
            `Virheellinen paaluväli: ${row.StartPole} - ${row.EndPole}`
          );
        }
        Obj.roomId = `PLV-${row.Segment}-${sp}-${ep}`;
        Obj.poleRange = [sp, ep];
      }
      if (row.StartPole && !row.EndPole) {
        if (isNaN(sp)) {
          errorMessage(`Virheellinen paalu: ${row.StartPole}`);
        }
        Obj.roomId = `PL-${row.Segment}-${sp}`;
        Obj.singlePole = [sp];
      }
      return Obj;
    });
    this.positions = roomList;
    await this.findRooms(roomList);
    return { success: true, data };
  }

  async getBuildings() {
    this.buildings = await getAllBuildings();
  }

  async findRooms(rooms: RoomPosition[]) {
    this.rooms = await findRooms(
      rooms.map((pl) => {
        const data = { ...pl };
        delete data.original;
        return data;
      })
    );
  }

  async findPitems(params: { segments: string[]; typeName: string }) {
    this.remoteItems = await findPitemsForType(
      params.segments,
      params.typeName
    );
  }
}

export interface IContainerPropsMethods {
  clearState: () => any;
  setUploading: (value: boolean) => any;
  setSelectedSegment: (seg: SelectedSegment) => any;
  updateRoomData: (inputData: RoomListing) => any;
  updateChangedRooms: () => any;
  updateRoomAreas: () => any;
  updateRoomNames: () => any;
  createNewRooms: () => any;
  updateDeviceMetadata: (options: { devices: DeviceListRow[] }) => any;
  createNewPitems: (options: {
    typeName: string;
    list: ItemDefinitionRow[];
  }) => any;
  checkItemData: (inputData: ItemListing) => any;
  getBuildings: () => any;
  findRooms: (rooms: RoomPosition[]) => any;
  findPitems: (params: { segments: string[]; typeName: string }) => any;
}
export interface IPitemState {
  missingAreas: RoomDefinitionRow[];
  missingRooms: RoomDefinitionRow[];
  alreadyAddedRooms: RoomDefinitionRow[];
  nameChangedForRooms: RoomNameChange[];
  buildingChangedForRooms: RoomBuildingChange[];
  // models for pitem import
  updatedData?: ItemListing;
  buildings: schema.pitem[];
  remoteItems: schema.pitem[];
  rooms: schema.pitem[];
  selectedSegment?: SelectedSegment;
  positions: RoomPosition[];
  isUploading: boolean;
  areasMissingFromDatabase: string[];
}

export type IContainerPropsState = IPitemState;
export interface IProps extends IContainerPropsState, IContainerPropsMethods {}

function pick<T, K extends keyof T>(o: T, ...props: K[]) {
  return props.reduce((a, e) => ({ ...a, [e]: o[e] }), {}) as Pick<T, K>;
}
export function mapStateToPropsWithKeys<K extends keyof IContainerPropsState>(
  state: IState,
  keys: K[]
): Pick<IContainerPropsState, K> {
  return pick(state.PitemState as IContainerPropsState, ...keys);
}

export const mapStateToProps = (state: IState): IContainerPropsState => {
  return {
    missingAreas: state.PitemState.missingAreas,
    missingRooms: state.PitemState.missingRooms,
    alreadyAddedRooms: state.PitemState.alreadyAddedRooms,
    nameChangedForRooms: state.PitemState.nameChangedForRooms,
    buildingChangedForRooms: state.PitemState.buildingChangedForRooms,
    updatedData: state.PitemState.updatedData,
    buildings: state.PitemState.buildings,
    remoteItems: state.PitemState.remoteItems,
    rooms: state.PitemState.rooms,
    selectedSegment: state.PitemState.selectedSegment,
    positions: state.PitemState.positions,
    isUploading: state.PitemState.isUploading,
    areasMissingFromDatabase: state.PitemState.areasMissingFromDatabase,
  };
};

function mapDispatchToPropsWithKeys<K extends keyof IContainerPropsMethods>(
  dispatch: any,
  keys: K[]
): Pick<IContainerPropsMethods, K> {
  return pick(mapDispatchToProps(dispatch), ...keys);
}

export const mapDispatchToProps = (dispatch: any): IContainerPropsMethods => {
  return {
    clearState: () => {
      return dispatch(RPitemState.clearState());
    },
    setUploading: (value: boolean) => {
      return dispatch(RPitemState.setUploading(value));
    },
    setSelectedSegment: (seg: SelectedSegment) => {
      return dispatch(RPitemState.setSelectedSegment(seg));
    },
    updateRoomData: (inputData: RoomListing) => {
      return dispatch(RPitemState.updateRoomData(inputData));
    },
    updateChangedRooms: () => {
      return dispatch(RPitemState.updateChangedRooms());
    },
    updateRoomAreas: () => {
      return dispatch(RPitemState.updateRoomAreas());
    },
    updateRoomNames: () => {
      return dispatch(RPitemState.updateRoomNames());
    },
    createNewRooms: () => {
      return dispatch(RPitemState.createNewRooms());
    },
    updateDeviceMetadata: (options: { devices: DeviceListRow[] }) => {
      return dispatch(RPitemState.updateDeviceMetadata(options));
    },
    createNewPitems: (options: {
      typeName: string;
      list: ItemDefinitionRow[];
    }) => {
      return dispatch(RPitemState.createNewPitems(options));
    },
    checkItemData: (inputData: ItemListing) => {
      return dispatch(RPitemState.checkItemData(inputData));
    },
    getBuildings: () => {
      return dispatch(RPitemState.getBuildings());
    },
    findRooms: (rooms: RoomPosition[]) => {
      return dispatch(RPitemState.findRooms(rooms));
    },
    findPitems: (params: { segments: string[]; typeName: string }) => {
      return dispatch(RPitemState.findPitems(params));
    },
  };
};

export function ConnectKeys<
  K extends keyof IPitemState,
  J extends keyof IContainerPropsMethods
>(keys: K[], methods: J[]) {
  return connect(
    (state: IState) => mapStateToPropsWithKeys(state, keys),
    (dispatch: any) => mapDispatchToPropsWithKeys(dispatch, methods)
  );
}

export const StateConnector = connect(mapStateToProps, mapDispatchToProps);

const initPitemState = () => {
  const o = new PitemState();
  return {
    missingAreas: o.missingAreas,
    missingRooms: o.missingRooms,
    alreadyAddedRooms: o.alreadyAddedRooms,
    nameChangedForRooms: o.nameChangedForRooms,
    buildingChangedForRooms: o.buildingChangedForRooms,
    updatedData: o.updatedData,
    buildings: o.buildings,
    remoteItems: o.remoteItems,
    rooms: o.rooms,
    selectedSegment: o.selectedSegment,
    positions: o.positions,
    isUploading: o.isUploading,
    areasMissingFromDatabase: o.areasMissingFromDatabase,
  };
};
const initWithMethodsPitemState = () => {
  const o = new PitemState();
  return {
    missingAreas: o.missingAreas,
    missingRooms: o.missingRooms,
    alreadyAddedRooms: o.alreadyAddedRooms,
    nameChangedForRooms: o.nameChangedForRooms,
    buildingChangedForRooms: o.buildingChangedForRooms,
    updatedData: o.updatedData,
    buildings: o.buildings,
    remoteItems: o.remoteItems,
    rooms: o.rooms,
    selectedSegment: o.selectedSegment,
    positions: o.positions,
    isUploading: o.isUploading,
    areasMissingFromDatabase: o.areasMissingFromDatabase,
    clearState: o.clearState,
    setUploading: o.setUploading,
    setSelectedSegment: o.setSelectedSegment,
    updateRoomData: o.updateRoomData,
    updateChangedRooms: o.updateChangedRooms,
    updateRoomAreas: o.updateRoomAreas,
    updateRoomNames: o.updateRoomNames,
    createNewRooms: o.createNewRooms,
    updateDeviceMetadata: o.updateDeviceMetadata,
    createNewPitems: o.createNewPitems,
    checkItemData: o.checkItemData,
    getBuildings: o.getBuildings,
    findRooms: o.findRooms,
    findPitems: o.findPitems,
  };
};

/**
 * @generated true
 */
export class RPitemState {
  private _state?: IPitemState;
  private _dispatch?: <A extends {}, T extends {}>(action: A) => T;
  private _getState?: () => any;
  constructor(
    state?: IPitemState,
    dispatch?: (action: any) => any,
    getState?: () => any
  ) {
    this._state = state;
    this._dispatch = dispatch;
    this._getState = getState;
  }
  get missingAreas(): RoomDefinitionRow[] {
    if (this._getState) {
      return this._getState().PitemState.missingAreas;
    } else {
      if (this._state) {
        return this._state.missingAreas;
      }
    }
    throw new Error('Invalid State in PitemState_missingAreas');
  }
  set missingAreas(value: RoomDefinitionRow[]) {
    if (this._state && typeof value !== 'undefined') {
      this._state.missingAreas = value;
    } else {
      // dispatch change for item missingAreas
      if (this._dispatch) {
        this._dispatch({
          type: PitemStateEnums.PitemState_missingAreas,
          payload: value,
        });
      }
    }
  }
  get missingRooms(): RoomDefinitionRow[] {
    if (this._getState) {
      return this._getState().PitemState.missingRooms;
    } else {
      if (this._state) {
        return this._state.missingRooms;
      }
    }
    throw new Error('Invalid State in PitemState_missingRooms');
  }
  set missingRooms(value: RoomDefinitionRow[]) {
    if (this._state && typeof value !== 'undefined') {
      this._state.missingRooms = value;
    } else {
      // dispatch change for item missingRooms
      if (this._dispatch) {
        this._dispatch({
          type: PitemStateEnums.PitemState_missingRooms,
          payload: value,
        });
      }
    }
  }
  get alreadyAddedRooms(): RoomDefinitionRow[] {
    if (this._getState) {
      return this._getState().PitemState.alreadyAddedRooms;
    } else {
      if (this._state) {
        return this._state.alreadyAddedRooms;
      }
    }
    throw new Error('Invalid State in PitemState_alreadyAddedRooms');
  }
  set alreadyAddedRooms(value: RoomDefinitionRow[]) {
    if (this._state && typeof value !== 'undefined') {
      this._state.alreadyAddedRooms = value;
    } else {
      // dispatch change for item alreadyAddedRooms
      if (this._dispatch) {
        this._dispatch({
          type: PitemStateEnums.PitemState_alreadyAddedRooms,
          payload: value,
        });
      }
    }
  }
  get nameChangedForRooms(): RoomNameChange[] {
    if (this._getState) {
      return this._getState().PitemState.nameChangedForRooms;
    } else {
      if (this._state) {
        return this._state.nameChangedForRooms;
      }
    }
    throw new Error('Invalid State in PitemState_nameChangedForRooms');
  }
  set nameChangedForRooms(value: RoomNameChange[]) {
    if (this._state && typeof value !== 'undefined') {
      this._state.nameChangedForRooms = value;
    } else {
      // dispatch change for item nameChangedForRooms
      if (this._dispatch) {
        this._dispatch({
          type: PitemStateEnums.PitemState_nameChangedForRooms,
          payload: value,
        });
      }
    }
  }
  get buildingChangedForRooms(): RoomBuildingChange[] {
    if (this._getState) {
      return this._getState().PitemState.buildingChangedForRooms;
    } else {
      if (this._state) {
        return this._state.buildingChangedForRooms;
      }
    }
    throw new Error('Invalid State in PitemState_buildingChangedForRooms');
  }
  set buildingChangedForRooms(value: RoomBuildingChange[]) {
    if (this._state && typeof value !== 'undefined') {
      this._state.buildingChangedForRooms = value;
    } else {
      // dispatch change for item buildingChangedForRooms
      if (this._dispatch) {
        this._dispatch({
          type: PitemStateEnums.PitemState_buildingChangedForRooms,
          payload: value,
        });
      }
    }
  }
  get updatedData(): ItemListing | undefined {
    if (this._getState) {
      return this._getState().PitemState.updatedData;
    } else {
      if (this._state) {
        return this._state.updatedData;
      }
    }
    return undefined;
  }
  set updatedData(value: ItemListing | undefined) {
    if (this._state && typeof value !== 'undefined') {
      this._state.updatedData = value;
    } else {
      // dispatch change for item updatedData
      if (this._dispatch) {
        this._dispatch({
          type: PitemStateEnums.PitemState_updatedData,
          payload: value,
        });
      }
    }
  }
  get buildings(): schema.pitem[] {
    if (this._getState) {
      return this._getState().PitemState.buildings;
    } else {
      if (this._state) {
        return this._state.buildings;
      }
    }
    throw new Error('Invalid State in PitemState_buildings');
  }
  set buildings(value: schema.pitem[]) {
    if (this._state && typeof value !== 'undefined') {
      this._state.buildings = value;
    } else {
      // dispatch change for item buildings
      if (this._dispatch) {
        this._dispatch({
          type: PitemStateEnums.PitemState_buildings,
          payload: value,
        });
      }
    }
  }
  get remoteItems(): schema.pitem[] {
    if (this._getState) {
      return this._getState().PitemState.remoteItems;
    } else {
      if (this._state) {
        return this._state.remoteItems;
      }
    }
    throw new Error('Invalid State in PitemState_remoteItems');
  }
  set remoteItems(value: schema.pitem[]) {
    if (this._state && typeof value !== 'undefined') {
      this._state.remoteItems = value;
    } else {
      // dispatch change for item remoteItems
      if (this._dispatch) {
        this._dispatch({
          type: PitemStateEnums.PitemState_remoteItems,
          payload: value,
        });
      }
    }
  }
  get rooms(): schema.pitem[] {
    if (this._getState) {
      return this._getState().PitemState.rooms;
    } else {
      if (this._state) {
        return this._state.rooms;
      }
    }
    throw new Error('Invalid State in PitemState_rooms');
  }
  set rooms(value: schema.pitem[]) {
    if (this._state && typeof value !== 'undefined') {
      this._state.rooms = value;
    } else {
      // dispatch change for item rooms
      if (this._dispatch) {
        this._dispatch({
          type: PitemStateEnums.PitemState_rooms,
          payload: value,
        });
      }
    }
  }
  get selectedSegment(): SelectedSegment | undefined {
    if (this._getState) {
      return this._getState().PitemState.selectedSegment;
    } else {
      if (this._state) {
        return this._state.selectedSegment;
      }
    }
    return undefined;
  }
  set selectedSegment(value: SelectedSegment | undefined) {
    if (this._state && typeof value !== 'undefined') {
      this._state.selectedSegment = value;
    } else {
      // dispatch change for item selectedSegment
      if (this._dispatch) {
        this._dispatch({
          type: PitemStateEnums.PitemState_selectedSegment,
          payload: value,
        });
      }
    }
  }
  get positions(): RoomPosition[] {
    if (this._getState) {
      return this._getState().PitemState.positions;
    } else {
      if (this._state) {
        return this._state.positions;
      }
    }
    throw new Error('Invalid State in PitemState_positions');
  }
  set positions(value: RoomPosition[]) {
    if (this._state && typeof value !== 'undefined') {
      this._state.positions = value;
    } else {
      // dispatch change for item positions
      if (this._dispatch) {
        this._dispatch({
          type: PitemStateEnums.PitemState_positions,
          payload: value,
        });
      }
    }
  }
  get isUploading(): boolean {
    if (this._getState) {
      return this._getState().PitemState.isUploading;
    } else {
      if (this._state) {
        return this._state.isUploading;
      }
    }
    throw new Error('Invalid State in PitemState_isUploading');
  }
  set isUploading(value: boolean) {
    if (this._state && typeof value !== 'undefined') {
      this._state.isUploading = value;
    } else {
      // dispatch change for item isUploading
      if (this._dispatch) {
        this._dispatch({
          type: PitemStateEnums.PitemState_isUploading,
          payload: value,
        });
      }
    }
  }
  get areasMissingFromDatabase(): string[] {
    if (this._getState) {
      return this._getState().PitemState.areasMissingFromDatabase;
    } else {
      if (this._state) {
        return this._state.areasMissingFromDatabase;
      }
    }
    throw new Error('Invalid State in PitemState_areasMissingFromDatabase');
  }
  set areasMissingFromDatabase(value: string[]) {
    if (this._state && typeof value !== 'undefined') {
      this._state.areasMissingFromDatabase = value;
    } else {
      // dispatch change for item areasMissingFromDatabase
      if (this._dispatch) {
        this._dispatch({
          type: PitemStateEnums.PitemState_areasMissingFromDatabase,
          payload: value,
        });
      }
    }
  }

  clearState() {
    if (this._state) {
      this.buildings = [];
      this.remoteItems = [];
      this.rooms = [];
      this.positions = [];
    } else {
      if (this._dispatch) {
        this._dispatch({ type: PitemStateEnums.PitemState_clearState });
      }
    }
  }

  public static clearState() {
    return (dispatcher: any, getState: any) => {
      return new RPitemState(undefined, dispatcher, getState).clearState();
    };
  }
  setUploading(value: boolean) {
    if (this._state) {
      this.isUploading = value;
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: PitemStateEnums.PitemState_setUploading,
          payload: value,
        });
      }
    }
  }

  public static setUploading(value: boolean) {
    return (dispatcher: any, getState: any) => {
      return new RPitemState(undefined, dispatcher, getState).setUploading(
        value
      );
    };
  }
  setSelectedSegment(seg: SelectedSegment) {
    if (this._state) {
      this.selectedSegment = seg;
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: PitemStateEnums.PitemState_setSelectedSegment,
          payload: seg,
        });
      }
    }
  }

  public static setSelectedSegment(seg: SelectedSegment) {
    return (dispatcher: any, getState: any) => {
      return new RPitemState(
        undefined,
        dispatcher,
        getState
      ).setSelectedSegment(seg);
    };
  }
  async updateRoomData(inputData: RoomListing) {
    await this.findRooms(
      inputData.rows.map((room) => {
        return {
          segmentName: room.Segment,
          roomId: room.Id.trim(),
        };
      })
    );
    const duplicates: {
      [key: string]: boolean;
    } = {};
    inputData.rows.forEach((r) => {
      if (duplicates[r.Id.trim()]) {
        errorMessage(`Aineistossa on duplikaatti ${r.Id}`);
      }
      duplicates[r.Id.trim()] = true;
    });
    this.buildings = await getAllBuildings();
    const existingBuildings = this.buildings.reduce(
      (prev, curr) => {
        return {
          ...prev,
          [curr.name]: curr,
        };
      },
      {} as {
        [key: string]: schema.pitem;
      }
    );
    for (const item of inputData.rows) {
      if (!existingBuildings[item.Segment]) {
        errorMessage(`Rakennusosaa ei ole olemassa ${item.Segment}`);
      }
    }
    // const remoteRooms = _.uniq(this.rooms.map((r) => r.name.trim()));
    // const newRooms = _.uniq(inputData.rows.map((r) => r.Id.trim()));
    const existingRooms: {
      [key: string]: schema.pitem;
    } = this.rooms.reduce((prev, curr) => {
      return { ...prev, [curr.name]: curr };
    }, {});
    const areasInDB = await API.getAreas();
    this.areasMissingFromDatabase = [];
    const missingList: string[] = [];
    this.missingAreas = inputData.rows.filter((r) => {
      if (!r.AreaName) {
        return false;
      }
      if (!existingRooms[r.Id.trim()]) {
        return false;
      }
      const old = existingRooms[r.Id.trim()];
      if (!old.area_id) {
        return true;
      }
      const correspondingArea = areasInDB
        .filter((a) => a.area_name === r.AreaName)
        .pop();
      if (!correspondingArea) {
        missingList.push(r.AreaName);
        return false;
      }
      if (correspondingArea.area_name !== r.AreaName) {
        return true;
      }
      return false;
    });
    // These areas are missing from the database at the moment
    this.areasMissingFromDatabase = _.uniq(missingList);
    // TODO: load add floors of the selected rooms
    let allParents: number[] = [];
    this.rooms.forEach((p) => {
      allParents = [...allParents, ...(p.parents || [])];
    });
    const all = await API.findPitems({ ids: _.uniq(allParents) });
    const findFloor = (buildingName: string, floorName: string) => {
      return all
        .filter((item) => {
          if (item.name === `${buildingName}-${floorName}`) {
            return true;
          }
          return false;
        })
        .pop();
    };
    this.buildingChangedForRooms = inputData.rows
      .filter((r) => {

        const theRoom = existingRooms[r.Id.trim()];
        // if the room does not exists, building can not be changed
        if(!theRoom) {
          return false;
        }

        const theFloor = findFloor(r.Segment.trim(), r.Floor.trim());
        // if floor can not be found from the existing parents list
        // it must have been changed.
        if (!theFloor) {
          return true;
        }
        
        // if the room does not currently belong to the new floor it has
        // changed location
        if (theRoom) {
          if (theRoom.parents && !theRoom.parents!.includes(theFloor.id)) {
            return true;
          }
        }
        return false;
      })
      .map((row) => {
        const old = existingRooms[row.Id.trim()];
        return {
          row,
          newBuildingName: row.Segment,
          newFloorName: row.Floor,
          newBuildingId: existingBuildings[row.Segment].id,
          roomId: row.Id,
          databaseId: old.id,
        };
      });
    this.nameChangedForRooms = inputData.rows
      .filter((r) => {
        const old = existingRooms[r.Id.trim()];
        if (old) {
          if (
            (r.RoomName || '')
              .trim()
              .localeCompare((old.name_long || '').trim()) !== 0
          ) {
            return true;
          }
        }
        return false;
      })
      .map((row) => {
        const old = existingRooms[row.Id.trim()];
        return {
          row,
          oldName: old.name_long!,
          newName: row.RoomName,
          roomId: row.Id,
          databaseId: old.id,
        };
      });
    this.missingRooms = inputData.rows.filter((r) => {
      return !existingRooms[r.Id.trim()];
    });
    this.alreadyAddedRooms = inputData.rows.filter((r) => {
      return existingRooms[r.Id.trim()];
    });
  }

  public static updateRoomData(inputData: RoomListing) {
    return (dispatcher: any, getState: any) => {
      return new RPitemState(undefined, dispatcher, getState).updateRoomData(
        inputData
      );
    };
  }
  async updateChangedRooms() {
    try {
      const list = this.buildingChangedForRooms.map((ch) => {
        return {
          id: ch.databaseId,
          parentName: `${ch.newBuildingName}-${ch.newFloorName}`,
        };
      });
      if (list.length > 0) {
        await API.updatePitemParents(list);
      }
    } catch (e) {
      errorMessage(e);
    }
  }

  public static updateChangedRooms() {
    return (dispatcher: any, getState: any) => {
      return new RPitemState(
        undefined,
        dispatcher,
        getState
      ).updateChangedRooms();
    };
  }
  async updateRoomAreas() {
    try {
      const list = this.missingAreas;
      for (const item of list) {
        // where do we place the item.
        // the code of the room
        try {
          if (item.AreaName) {
            await API.updatePitemArea(item.Id, item.AreaName);
          }
        } catch (e) {
          errorMessage(e);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  public static updateRoomAreas() {
    return (dispatcher: any, getState: any) => {
      return new RPitemState(undefined, dispatcher, getState).updateRoomAreas();
    };
  }
  async updateRoomNames() {
    try {
      const list = this.nameChangedForRooms;
      for (const item of list) {
        // where do we place the item.
        // the code of the room
        try {
          await updatePitemNAme(item.databaseId, item.newName);
        } catch (e) {
          errorMessage(e);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  public static updateRoomNames() {
    return (dispatcher: any, getState: any) => {
      return new RPitemState(undefined, dispatcher, getState).updateRoomNames();
    };
  }
  async createNewRooms() {
    try {
      const list = this.missingRooms;
      const pitemType = await findPitemType('Huone');
      const kerrosType = await findPitemType('Kerros');
      const areas = await API.getAreas();
      const segmentByName: {
        [key: string]: schema.pitem;
      } = this.buildings.reduce(
        (prev, curr) => {
          return { ...prev, [curr.name]: curr };
        },
        {} as {
          [key: string]: schema.pitem;
        }
      );
      for (const item of list) {
        // where do we place the item.
        // the code of the room
        try {
          if (await checkPItemDuplicate(item.Id)) {
            errorMessage(`Kannassa on duplikaatti kohteelle ${item.Id}`);
            continue;
          }
          const [area] = areas.filter((a) => a.area_name === item.AreaName);
          const seg = segmentByName[item.Segment];
          const floorName = `${seg.name}-${item.Floor}`;
          const hasFloor = await checkPItemDuplicate(floorName);
          const floorOfSegment = hasFloor
            ? await getPitemByName(`${seg.name}-${item.Floor}`)
            : await createNewPItem({
                name: floorName,
                name_long: floorName,
                parentItemId: seg.id,
                pitemTypeId: kerrosType.id,
              });
          // Floor of the room should be found too...
          await createNewPItem({
            name: item.Id.trim(),
            name_long: item.RoomName,
            parentItemId: floorOfSegment.id,
            pitemTypeId: pitemType.id,
            areaName: area ? area.area_name! : undefined,
          });
        } catch (e) {
          errorMessage(e);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  public static createNewRooms() {
    return (dispatcher: any, getState: any) => {
      return new RPitemState(undefined, dispatcher, getState).createNewRooms();
    };
  }
  async updateDeviceMetadata(options: { devices: DeviceListRow[] }) {
    try {
      const result = await API.updateDevices(options.devices);
      return result;
    } catch (error) {
      return error;
    }
  }

  public static updateDeviceMetadata(options: { devices: DeviceListRow[] }) {
    return (dispatcher: any, getState: any) => {
      return new RPitemState(
        undefined,
        dispatcher,
        getState
      ).updateDeviceMetadata(options);
    };
  }
  async createNewPitems(options: {
    typeName: string;
    list: ItemDefinitionRow[];
  }) {
    const { list, typeName } = options;
    try {
      const pitemType = await findPitemType(typeName);
      const segmentByName: {
        [key: string]: schema.pitem;
      } = this.buildings.reduce(
        (prev, curr) => {
          return { ...prev, [curr.name]: curr };
        },
        {} as {
          [key: string]: schema.pitem;
        }
      );
      for (const item of list) {
        // where do we place the item.
        // the code of the room
        try {
          // NOTE: If you want to disallow updating items remove comment below
          // if (await checkPItemDuplicate(item.Id)) {
          //   errorMessage(`Kannassa on duplikaatti kohteelle ${item.Id}`);
          //   continue;
          // }
          const seg = segmentByName[item.Segment];
          const rooms = this.rooms.filter((r) => {
            return r.name === item.RoomId && (r.parents || []).includes(seg.id);
          });
          let createRoom = rooms[0];
          if (rooms.length === 0) {
            if (item.StartPole && item.EndPole) {
              const sp = Number(item.StartPole);
              const ep = Number(item.EndPole);
              const poleRangeType = await findPitemType('Paaluväli');
              if (!isNaN(sp)) {
                createRoom = await createNewPItem({
                  name: `PLV-${item.Segment}-${item.StartPole}`,
                  name_long: `Paaluväli ${item.StartPole}`,
                  parentItemId: seg.id,
                  pitemTypeId: poleRangeType.id,
                  poleRange: [sp, ep],
                });
              }
            } else {
              if (item.StartPole) {
                const sp = Number(item.StartPole);
                const singlePoleType = await findPitemType('Paalu');
                if (!isNaN(sp)) {
                  createRoom = await createNewPItem({
                    name: `PL-${item.Segment}-${item.StartPole}`,
                    name_long: `Paaluluku ${item.StartPole}`,
                    parentItemId: seg.id,
                    pitemTypeId: singlePoleType.id,
                    singlePole: sp,
                  });
                }
              }
            }
          }
          if (createRoom) {
            const room = createRoom;
            await createNewPItem({
              name: item.Id,
              name_long: item.Type,
              aItem_names: item.Contracts,
              parentItemId: room.id,
              pitemTypeId: pitemType.id,
              is_tracked: item.Tracking,
            });
          }
        } catch (e) {
          errorMessage(e);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  public static createNewPitems(options: {
    typeName: string;
    list: ItemDefinitionRow[];
  }) {
    return (dispatcher: any, getState: any) => {
      return new RPitemState(undefined, dispatcher, getState).createNewPitems(
        options
      );
    };
  }
  async checkItemData(inputData: ItemListing) {
    // 1. Check if the pitem type does exist
    const data = {
      ...inputData,
      rows: inputData.rows.map((r) => {
        return {
          ...r,
          RoomId:
            r.RoomId === '99-99999' || r.RoomId === '99'
              ? `${r.Segment}-${r.RoomId}`
              : r.RoomId,
        };
      }),
    };
    this.updatedData = data;
    try {
      await findPitemType(data.pitemType || '');
    } catch (e) {
      return {
        success: false,
        missingPitemType: data.pitemType,
      };
    }
    await this.findPitems({
      segments: _.uniq(data.rows.map((row) => row.Segment)),
      typeName: data.pitemType || '',
    });
    await this.getBuildings();
    const existingBuildings = this.buildings.reduce(
      (prev, curr) => {
        return {
          ...prev,
          [curr.name]: true,
        };
      },
      {} as {
        [key: string]: boolean;
      }
    );
    for (const row of data.rows) {
      if (!existingBuildings[row.Segment]) {
        errorMessage(`Rakennusosaa ei ole olemassa ${row.Segment}`);
      }
    }
    const roomList = data.rows.map((row) => {
      const Obj: RoomPosition = {
        segmentName: row.Segment,
        roomId: row.RoomId,
        original: row,
      };
      const sp = Number(row.StartPole);
      const ep = Number(row.EndPole);
      if (row.StartPole && row.EndPole) {
        if (isNaN(sp) || isNaN(ep)) {
          errorMessage(
            `Virheellinen paaluväli: ${row.StartPole} - ${row.EndPole}`
          );
        }
        Obj.roomId = `PLV-${row.Segment}-${sp}-${ep}`;
        Obj.poleRange = [sp, ep];
      }
      if (row.StartPole && !row.EndPole) {
        if (isNaN(sp)) {
          errorMessage(`Virheellinen paalu: ${row.StartPole}`);
        }
        Obj.roomId = `PL-${row.Segment}-${sp}`;
        Obj.singlePole = [sp];
      }
      return Obj;
    });
    this.positions = roomList;
    await this.findRooms(roomList);
    return { success: true, data };
  }

  public static checkItemData(inputData: ItemListing) {
    return (dispatcher: any, getState: any) => {
      return new RPitemState(undefined, dispatcher, getState).checkItemData(
        inputData
      );
    };
  }
  async getBuildings() {
    this.buildings = await getAllBuildings();
  }

  public static getBuildings() {
    return (dispatcher: any, getState: any) => {
      return new RPitemState(undefined, dispatcher, getState).getBuildings();
    };
  }
  async findRooms(rooms: RoomPosition[]) {
    this.rooms = await findRooms(
      rooms.map((pl) => {
        const data = { ...pl };
        delete data.original;
        return data;
      })
    );
  }

  public static findRooms(rooms: RoomPosition[]) {
    return (dispatcher: any, getState: any) => {
      return new RPitemState(undefined, dispatcher, getState).findRooms(rooms);
    };
  }
  async findPitems(params: { segments: string[]; typeName: string }) {
    this.remoteItems = await findPitemsForType(
      params.segments,
      params.typeName
    );
  }

  public static findPitems(params: { segments: string[]; typeName: string }) {
    return (dispatcher: any, getState: any) => {
      return new RPitemState(undefined, dispatcher, getState).findPitems(
        params
      );
    };
  }
}

export const PitemStateEnums = {
  PitemState_missingAreas: 'PitemState_missingAreas',
  PitemState_missingRooms: 'PitemState_missingRooms',
  PitemState_alreadyAddedRooms: 'PitemState_alreadyAddedRooms',
  PitemState_nameChangedForRooms: 'PitemState_nameChangedForRooms',
  PitemState_buildingChangedForRooms: 'PitemState_buildingChangedForRooms',
  PitemState_updatedData: 'PitemState_updatedData',
  PitemState_buildings: 'PitemState_buildings',
  PitemState_remoteItems: 'PitemState_remoteItems',
  PitemState_rooms: 'PitemState_rooms',
  PitemState_selectedSegment: 'PitemState_selectedSegment',
  PitemState_positions: 'PitemState_positions',
  PitemState_isUploading: 'PitemState_isUploading',
  PitemState_areasMissingFromDatabase: 'PitemState_areasMissingFromDatabase',
  PitemState_clearState: 'PitemState_clearState',
  PitemState_setUploading: 'PitemState_setUploading',
  PitemState_setSelectedSegment: 'PitemState_setSelectedSegment',
};

export const PitemStateReducer = (
  state: IPitemState = initPitemState(),
  action: any
) => {
  return immer.produce(state, (draft: IPitemState) => {
    switch (action.type) {
      case PitemStateEnums.PitemState_missingAreas:
        new RPitemState(draft).missingAreas = action.payload;
        break;
      case PitemStateEnums.PitemState_missingRooms:
        new RPitemState(draft).missingRooms = action.payload;
        break;
      case PitemStateEnums.PitemState_alreadyAddedRooms:
        new RPitemState(draft).alreadyAddedRooms = action.payload;
        break;
      case PitemStateEnums.PitemState_nameChangedForRooms:
        new RPitemState(draft).nameChangedForRooms = action.payload;
        break;
      case PitemStateEnums.PitemState_buildingChangedForRooms:
        new RPitemState(draft).buildingChangedForRooms = action.payload;
        break;
      case PitemStateEnums.PitemState_updatedData:
        new RPitemState(draft).updatedData = action.payload;
        break;
      case PitemStateEnums.PitemState_buildings:
        new RPitemState(draft).buildings = action.payload;
        break;
      case PitemStateEnums.PitemState_remoteItems:
        new RPitemState(draft).remoteItems = action.payload;
        break;
      case PitemStateEnums.PitemState_rooms:
        new RPitemState(draft).rooms = action.payload;
        break;
      case PitemStateEnums.PitemState_selectedSegment:
        new RPitemState(draft).selectedSegment = action.payload;
        break;
      case PitemStateEnums.PitemState_positions:
        new RPitemState(draft).positions = action.payload;
        break;
      case PitemStateEnums.PitemState_isUploading:
        new RPitemState(draft).isUploading = action.payload;
        break;
      case PitemStateEnums.PitemState_areasMissingFromDatabase:
        new RPitemState(draft).areasMissingFromDatabase = action.payload;
        break;
      case PitemStateEnums.PitemState_clearState:
        new RPitemState(draft).clearState();
        break;
      case PitemStateEnums.PitemState_setUploading:
        new RPitemState(draft).setUploading(action.payload);
        break;
      case PitemStateEnums.PitemState_setSelectedSegment:
        new RPitemState(draft).setSelectedSegment(action.payload);
        break;
    }
  });
};
/********************************
 * React Context API component   *
 ********************************/
export const PitemStateContext = React.createContext<IProps>(
  initWithMethodsPitemState()
);
export const PitemStateConsumer = PitemStateContext.Consumer;
let instanceCnt = 1;
export class PitemStateProvider extends React.Component {
  public state: IPitemState = initPitemState();
  public lastSetState: IPitemState;
  private __devTools: any = null;
  constructor(props: any) {
    super(props);
    this.lastSetState = this.state;
    this.clearState = this.clearState.bind(this);
    this.setUploading = this.setUploading.bind(this);
    this.setSelectedSegment = this.setSelectedSegment.bind(this);
    this.updateRoomData = this.updateRoomData.bind(this);
    this.updateChangedRooms = this.updateChangedRooms.bind(this);
    this.updateRoomAreas = this.updateRoomAreas.bind(this);
    this.updateRoomNames = this.updateRoomNames.bind(this);
    this.createNewRooms = this.createNewRooms.bind(this);
    this.updateDeviceMetadata = this.updateDeviceMetadata.bind(this);
    this.createNewPitems = this.createNewPitems.bind(this);
    this.checkItemData = this.checkItemData.bind(this);
    this.getBuildings = this.getBuildings.bind(this);
    this.findRooms = this.findRooms.bind(this);
    this.findPitems = this.findPitems.bind(this);
    const devs = window['__REDUX_DEVTOOLS_EXTENSION__']
      ? window['__REDUX_DEVTOOLS_EXTENSION__']
      : null;
    if (devs) {
      this.__devTools = devs.connect({ name: 'PitemState' + instanceCnt++ });
      this.__devTools.init(this.state);
      this.__devTools.subscribe((msg: any) => {
        if (msg.type === 'DISPATCH' && msg.state) {
          this.setState(JSON.parse(msg.state));
        }
      });
    }
  }
  public componentWillUnmount() {
    if (this.__devTools) {
      this.__devTools.unsubscribe();
    }
  }
  public setStateSync(state: IPitemState) {
    this.lastSetState = state;
    this.setState(state);
  }
  clearState() {
    const nextState = immer.produce(this.state, (draft: IPitemState) =>
      new RPitemState(draft).clearState()
    );
    if (this.__devTools) {
      this.__devTools.send('clearState', nextState);
    }
    this.setStateSync(nextState);
  }
  setUploading(value: boolean) {
    const nextState = immer.produce(this.state, (draft: IPitemState) =>
      new RPitemState(draft).setUploading(value)
    );
    if (this.__devTools) {
      this.__devTools.send('setUploading', nextState);
    }
    this.setStateSync(nextState);
  }
  setSelectedSegment(seg: SelectedSegment) {
    const nextState = immer.produce(this.state, (draft: IPitemState) =>
      new RPitemState(draft).setSelectedSegment(seg)
    );
    if (this.__devTools) {
      this.__devTools.send('setSelectedSegment', nextState);
    }
    this.setStateSync(nextState);
  }
  async updateRoomData(inputData: RoomListing) {
    return new RPitemState(
      undefined,
      (action: any) => {
        const nextState = PitemStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ PitemState: this.lastSetState })
    ).updateRoomData(inputData);
  }
  async updateChangedRooms() {
    return new RPitemState(
      undefined,
      (action: any) => {
        const nextState = PitemStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ PitemState: this.lastSetState })
    ).updateChangedRooms();
  }
  async updateRoomAreas() {
    return new RPitemState(
      undefined,
      (action: any) => {
        const nextState = PitemStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ PitemState: this.lastSetState })
    ).updateRoomAreas();
  }
  async updateRoomNames() {
    return new RPitemState(
      undefined,
      (action: any) => {
        const nextState = PitemStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ PitemState: this.lastSetState })
    ).updateRoomNames();
  }
  async createNewRooms() {
    return new RPitemState(
      undefined,
      (action: any) => {
        const nextState = PitemStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ PitemState: this.lastSetState })
    ).createNewRooms();
  }
  async updateDeviceMetadata(options: { devices: DeviceListRow[] }) {
    return new RPitemState(
      undefined,
      (action: any) => {
        const nextState = PitemStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ PitemState: this.lastSetState })
    ).updateDeviceMetadata(options);
  }
  async createNewPitems(options: {
    typeName: string;
    list: ItemDefinitionRow[];
  }) {
    return new RPitemState(
      undefined,
      (action: any) => {
        const nextState = PitemStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ PitemState: this.lastSetState })
    ).createNewPitems(options);
  }
  async checkItemData(inputData: ItemListing) {
    return new RPitemState(
      undefined,
      (action: any) => {
        const nextState = PitemStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ PitemState: this.lastSetState })
    ).checkItemData(inputData);
  }
  async getBuildings() {
    return new RPitemState(
      undefined,
      (action: any) => {
        const nextState = PitemStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ PitemState: this.lastSetState })
    ).getBuildings();
  }
  async findRooms(rooms: RoomPosition[]) {
    return new RPitemState(
      undefined,
      (action: any) => {
        const nextState = PitemStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ PitemState: this.lastSetState })
    ).findRooms(rooms);
  }
  async findPitems(params: { segments: string[]; typeName: string }) {
    return new RPitemState(
      undefined,
      (action: any) => {
        const nextState = PitemStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ PitemState: this.lastSetState })
    ).findPitems(params);
  }
  public render() {
    return (
      <PitemStateContext.Provider
        value={{
          ...this.state,
          clearState: this.clearState,
          setUploading: this.setUploading,
          setSelectedSegment: this.setSelectedSegment,
          updateRoomData: this.updateRoomData,
          updateChangedRooms: this.updateChangedRooms,
          updateRoomAreas: this.updateRoomAreas,
          updateRoomNames: this.updateRoomNames,
          createNewRooms: this.createNewRooms,
          updateDeviceMetadata: this.updateDeviceMetadata,
          createNewPitems: this.createNewPitems,
          checkItemData: this.checkItemData,
          getBuildings: this.getBuildings,
          findRooms: this.findRooms,
          findPitems: this.findPitems,
        }}
      >
        {' '}
        {this.props.children}
      </PitemStateContext.Provider>
    );
  }
}
