import * as React from 'react';
import * as container from '../../state/reducers/AdminState';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import './admin.scss';

import { DataTable } from '../Common/DataTable';

export type SettingsProps = InjectedIntlProps & container.IProps;

interface ISearchState {
  value: number;
  debounce: number;
}

export const AdminSearchResults = injectIntl(
  container.StateConnector(
    class InnerClass extends React.Component<SettingsProps, ISearchState> {
      state = {
        value: 0,
        debounce: 0,
      };
      render() {
        if (!this.props.adminSearch.searchResults) {
          return <div />;
        }
        // const t = this.props.intl;
        return (
          <>
            <DataTable
              rows={this.props.adminSearch.searchResults}
              keys={['id', 'name', 'buildingElement', 'created', 'revision']}
            />
          </>
        );
      }
    }
  )
);
