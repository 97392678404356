import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Folding, UIDocument } from '../../../../shared/src/model/index';
import './DocumentSelector.scss';

export interface DocumentRow {
  selected: boolean;
  id: number;
  name: string;
  amount: number;
  folding: Folding;
  isColor: boolean;
}

interface DocumentSelectorProps {
  documents: UIDocument[];
  orderAmount: number;
  folding: Folding;
  onChange: (selectedDocuments: DocumentRow[]) => void;
}

interface DocumentSelectorState {
  page: number;
  rowsPerPage: number;
  documentRows: DocumentRow[];
}

const styles = {
  checkBoxColumn: {
    width: '50px',
  },
  nameColumn: {
    wordBreak: 'break-all',
    width: '100%', // Column grows to take up any unused space.
  },
} as { [key: string]: React.CSSProperties };

export default class DocumentSelector extends React.PureComponent<
  DocumentSelectorProps,
  DocumentSelectorState
> {
  state: DocumentSelectorState = {
    page: 0,
    rowsPerPage: 10,
    documentRows: this.props.documents
      .map(
        doc =>
          ({
            selected: true,
            id: doc.id,
            name: doc.name,
            amount: this.props.orderAmount,
            folding: this.props.folding,
            isColor: false,
          } as DocumentRow)
      )
      .sort((a, b) => a.name.localeCompare(b.name)),
  };

  componentDidMount() {
    // Send initialState to parent
    this.fireOnChange();
  }

  componentDidUpdate(
    prevProps: DocumentSelectorProps,
    prevState: DocumentSelectorState
  ) {
    if (this.state !== prevState) {
      this.fireOnChange();
    }

    if (
      this.props.orderAmount !== prevProps.orderAmount ||
      this.props.folding !== prevProps.folding
    ) {
      this.setState({
        documentRows: this.state.documentRows
          .map(doc => ({
            ...doc,
            amount: this.props.orderAmount,
            folding: this.props.folding,
          }))
          .sort((a, b) => a.name.localeCompare(b.name)),
      });
    }
  }

  fireOnChange() {
    this.props.onChange(this.state.documentRows.slice());
  }

  onPageChange = (_: any, page: number) => {
    this.setState({ page });
  };

  onRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ rowsPerPage: parseInt(event.currentTarget.value, 10) });
  };

  onDocumentSelectedChange(changedDocument: DocumentRow, selected: boolean) {
    this.setState(state => ({
      documentRows: state.documentRows.map(doc =>
        doc === changedDocument ? { ...doc, selected } : doc
      ),
    }));
  }

  onDocumentIsColorChange(changedDocument: DocumentRow, checked: boolean) {
    this.setState(state => ({
      documentRows: state.documentRows.map(doc =>
        doc === changedDocument ? { ...doc, isColor: checked } : doc
      ),
    }));
  }

  onAllDocumentSelectedChange(selected: boolean) {
    this.setState(state => ({
      documentRows: state.documentRows.map(doc => ({ ...doc, selected })),
    }));
  }

  public render() {
    const { documentRows, page, rowsPerPage } = this.state;
    const selectedRows = documentRows.filter(row => row.selected);

    const firstIndex = page * rowsPerPage;

    const allSelected = selectedRows.length === documentRows.length;
    const noneSelected = selectedRows.length === 0;
    return (
      <div>
        <Table padding="dense" className="printOrderDocumentTable">
          <TableHead>
            <TableRow>
              <TableCell style={styles.checkBoxColumn} padding="none" />
              <TableCell>
                <FormattedMessage id="printOrder.colorPrint" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="printOrder.document" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className={noneSelected ? 'disabled' : ''}>
              <TableCell style={styles.checkBoxColumn} padding="none">
                <Checkbox
                  checked={allSelected}
                  indeterminate={!allSelected && !noneSelected}
                  onChange={(_, checked) =>
                    this.onAllDocumentSelectedChange(checked)
                  }
                />
              </TableCell>
              <TableCell />
              <TableCell>
                <FormattedMessage id="printOrder.allDocuments" />
              </TableCell>
            </TableRow>

            {documentRows
              .slice(firstIndex, firstIndex + rowsPerPage)
              .map(doc => (
                <TableRow
                  key={doc.id}
                  className={!doc.selected ? 'disabled' : ''}
                >
                  <TableCell style={styles.checkBoxColumn} padding="none">
                    <Checkbox
                      checked={doc.selected}
                      onChange={(e, checked) =>
                        this.onDocumentSelectedChange(doc, checked)
                      }
                    />
                  </TableCell>
                  <TableCell
                    style={styles.checkBoxColumn}
                    align="center"
                    padding="none"
                  >
                    <Checkbox
                      style={{ width: 36, height: 36 }}
                      checked={doc.isColor}
                      onChange={(e, checked) =>
                        this.onDocumentIsColorChange(doc, checked)
                      }
                    />
                  </TableCell>
                  <TableCell style={styles.nameColumn}>{doc.name}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={documentRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            native: true,
          }}
          rowsPerPageOptions={[10, 20, 30]}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={this.onPageChange}
          onChangeRowsPerPage={this.onRowsPerPageChange}
        />
      </div>
    );
  }
}
