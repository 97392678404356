import * as React from 'react';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import * as applicationState from '../../state/reducers/ApplicationState';
import * as adminState from '../../state/reducers/AdminState';
import './adminTools.scss';
import Loading from '../Common/Loading';
import { Async } from '../Common/Async';
import * as Model from '../../../../shared/src/model/index';
import * as schema from '../../../../shared/src/model/projectSchema';
import { APIService } from '../../services/api/src/frontend/api';
import { Notifier } from '../Common/Notifier';

// import { errorMessage, successMessage } from '../Common/Notifier';
import axios from 'axios';
import PermissionPanel from './PermissionPanel';
import { Checkbox, Divider, Select, MenuItem } from '@material-ui/core';
import Button from '../Common/Button';
import { Item } from '../Common/AutosuggestChipSelection';
import ReactSelect from 'react-select';
import { ValueType } from 'react-select/lib/types';

const API = APIService(axios);
// const DOC = DocumentService(axios);

interface IUsersProps extends applicationState.IProps, adminState.IProps {
  userId: number;
}

interface IUsersState {
  expandedPrivilegeIndex: number | undefined;
  loadingUserInfo: boolean;
  loadingPermissions: boolean;
  selectedContract: Item | null;
  selectedRole: Item | null;
  error?: string;
  passwordLinkValidForHours: number;
  isActiveUser: boolean;
  updatingSuperuserStatus: boolean;
}

type UsersProps = IUsersProps & InjectedIntlProps;

class AbstractUsers extends React.Component<UsersProps, IUsersState> {
  constructor(props: UsersProps) {
    super(props);

    this.state = {
      expandedPrivilegeIndex: undefined,
      loadingUserInfo: false,
      loadingPermissions: false,
      selectedContract: null,
      selectedRole: null,
      passwordLinkValidForHours: 2,
      isActiveUser: true,
      updatingSuperuserStatus: false,
    };
  }

  onPrivilegeExpand = (index: number) => {
    this.setState({
      expandedPrivilegeIndex:
        this.state.expandedPrivilegeIndex !== index ? index : undefined,
    });
  };

  componentDidUpdate(prev: UsersProps) {
    if (prev.userId !== this.props.userId) {
      this.setState({
        expandedPrivilegeIndex: undefined,
      });
    }
  }

  onContractChange = (newItem: { label: string; value: number }) => {
    this.setState({ selectedContract: newItem });
  };

  onRoleChange = (newItem: { label: string; value: number }) => {
    this.setState({ selectedRole: newItem });
  };

  handleAddRoleForUser = async () => {
    const { selectedContract, selectedRole } = this.state;
    if (selectedRole && selectedContract) {
      try {
        await API.addRoleToUser({
          aitemId: selectedContract ? selectedContract.value : undefined,
          roleId: selectedRole.value,
          userId: Number(this.props.userId),
        });
        this.setState({
          selectedRole: null,
        });
      } catch (e) {
        this.setState({ error: e.response.data });
      }
    }
  };

  handleRemoveRoleFromUser = async (
    privilege: Model.UIUserRolePrivilege,
    roleId: number
  ) => {
    await API.removeRoleFromUser({
      userId: privilege.userId,
      roleId,
      aitemId: privilege.aitem ? privilege.aitem.id : undefined,
      pitemId: privilege.pitem ? privilege.pitem.id : undefined,
    });
  };

  handlePasswordLinkValiditySelect = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    this.setState({
      passwordLinkValidForHours: Number(event.target.value),
    });
  };

  addUserToLocalDB = async (id: number) => {
    // Assuming id is negative since it's ldap user
    // from allUsers array (plain ldap users have negative id value based on index in ldapusers array)
    const user = this.props.ldapUsers && this.props.ldapUsers[-(id - 1)];
    if (user) {
      this.props.createNewUserFromLdap(user);
    }
  };

  handlePasswordLinkSend = (email: string, validForHours: number) => {
    this.props.sendPasswordResetLinkAdmin({ email, validForHours });
  };

  handleSuperuserStatusChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({ updatingSuperuserStatus: true });
    try {
      await API.setSuperuserStatus({
        userId: this.props.userId,
        is_superuser: event.target.checked,
      });
    } catch (e) {
      this.setState({ error: e.response.data });
    }
    this.setState({ updatingSuperuserStatus: false });
  };

  render() {
    const t = (id: string) => this.props.intl.formatMessage({ id });
    const { userId } = this.props;
    const { selectedContract, selectedRole } = this.state;

    if (userId < 0) {
      const reduxUser = this.props.ldapUsers[userId];
      return (
        <div className="detail">
          <div className="component">
            <h2>{t('adminTools.unattachedUser')}</h2>
            {reduxUser && reduxUser.disabled && (
              <FormattedMessage id="adminTools.disabledUser" />
            )}
            <div className="content">
              <Button
                color="primary"
                onClick={async () => await this.addUserToLocalDB(userId)}
                disabled={!this.state.isActiveUser}
              >
                {t('adminTools.addUserToLocalDB')}
              </Button>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="detail">
        <div className="component">
          <h2>{t('adminTools.userInformation')}</h2>

          <div className="content">
            <Async
              placeholder={<Loading loading={true} />}
              uri={this.props.userId}
              loader={async (): Promise<schema.user> => {
                this.setState({ loadingUserInfo: true });
                const user = await API.getUser(userId);
                this.setState({ loadingUserInfo: false });
                const reduxUser = this.props.users
                  .filter(u => u.id === user.id)
                  .pop();
                this.setState({
                  isActiveUser: (reduxUser && reduxUser.disabled) || true,
                });
                return user;
              }}
              render={(user, reload) => {
                if (this.state.loadingUserInfo) {
                  return <Loading loading={true} />;
                } else if (!user) {
                  return (
                    <Notifier
                      snack={{
                        message: t('error.title'),
                        variant: 'error',
                      }}
                    />
                  );
                } else {
                  return (
                    <div className="adminTools userInfo">
                      <dl>
                        <dt>
                          <FormattedMessage id="adminTools.adminUser" />
                        </dt>
                        <dd>
                          <Checkbox
                            disabled={
                              this.state.updatingSuperuserStatus ||
                              (user &&
                                this.props.user &&
                                user.id === this.props.user.id)
                            }
                            checked={!!user.is_superuser}
                            onChange={async e => {
                              await this.handleSuperuserStatusChange(e);
                              reload();
                            }}
                          />
                        </dd>
                        <dt>ID</dt>
                        <dd>{user.id}</dd>
                        <dt>
                          <FormattedMessage id="adminTools.firstname" />
                        </dt>
                        <dd>{user.firstname}</dd>
                        <dt>
                          <FormattedMessage id="adminTools.lastname" />
                        </dt>
                        <dd>{user.lastname}</dd>
                        <dt>
                          <FormattedMessage id="adminTools.email" />
                        </dt>
                        <dd>{user.email}</dd>
                      </dl>
                      <div className="actions">
                        <div className="passwordReset">
                          <Button
                            disabled={!this.state.isActiveUser}
                            onClick={() =>
                              user &&
                              user.email &&
                              this.handlePasswordLinkSend(
                                user.email,
                                this.state.passwordLinkValidForHours
                              )
                            }
                          >
                            {t('adminTools.sendPasswordResetLink')}
                          </Button>
                          &nbsp;
                          <FormattedMessage id="adminTools.passwordLinkValidity" />
                          &nbsp;
                          <Select
                            value={this.state.passwordLinkValidForHours}
                            onChange={this.handlePasswordLinkValiditySelect}
                          >
                            {[2, 12, 24, 36].map(h => (
                              <MenuItem key={h} value={h}>
                                {h} {t('adminTools.hours')}
                              </MenuItem>
                            ))}
                          </Select>
                        </div>
                      </div>
                    </div>
                  );
                }
              }}
            />
          </div>
        </div>
        <div style={{ marginTop: '20px' }} />
        <div className="component">
          <h2>{t('adminTools.userPermissions')}</h2>
          <div className="content">
            <Async
              placeholder={<Loading loading={true} />}
              uri={this.props.userId}
              loader={async (): Promise<{
                privileges: Model.UIUserRolePrivilege[];
              }> => {
                this.setState({ loadingPermissions: true });
                const privileges = await API.getRolePrivilegesForUser(userId);
                this.setState({ loadingPermissions: false });
                this.setState({
                  isActiveUser: privileges.length > 0,
                });
                return { privileges };
              }}
              render={(value, reload) => {
                if (this.state.loadingPermissions) {
                  return <Loading loading={true} />;
                } else if (!value) {
                  return (
                    <Notifier
                      snack={{
                        message: t('error.title'),
                        variant: 'error',
                      }}
                    />
                  );
                } else {
                  return (
                    <div className="adminTools userPermissions">
                      <div className="newPermission">
                        <ReactSelect
                          className="dropdown"
                          isMulti={false}
                          placeholder={t('documentMeta.contract')}
                          options={
                            (this.props.contracts
                              ? this.props.contracts.map(v => ({
                                  label: v.name,
                                  value: v.id,
                                }))
                              : []) as Model.Item[]
                          }
                          value={this.state.selectedContract}
                          onChange={(value: ValueType<Model.Item>) => {
                            if (value && !Array.isArray(value)) {
                              this.onContractChange(value as Model.Item);
                            }
                          }}
                        />
                        <ReactSelect
                          className="dropdown"
                          isMulti={false}
                          placeholder={t('adminTools.role')}
                          options={
                            this.props.roles &&
                            this.props.roles.map((v: Model.APRole) => ({
                              label: v.role_name,
                              value: v.id,
                            }))
                          }
                          value={this.state.selectedRole}
                          onChange={(value: ValueType<Model.Item>) => {
                            if (value) {
                              if (!Array.isArray(value)) {
                                this.onRoleChange(value as Model.Item);
                              }
                            }
                          }}
                        />

                        <Button
                          className="addPermissionButton"
                          disabled={!selectedContract || !selectedRole}
                          onClick={async () => {
                            await this.handleAddRoleForUser();
                            reload();
                          }}
                        >
                          <FormattedMessage id="adminTools.addPermission" />
                        </Button>
                      </div>
                      <Divider />
                      <div className="userPermissionList">
                        {value.privileges.map((p, idx) => (
                          <PermissionPanel
                            key={idx}
                            index={idx}
                            expanded={this.state.expandedPrivilegeIndex === idx}
                            onClick={this.onPrivilegeExpand}
                            onRemoveRole={async roleId => {
                              await this.handleRemoveRoleFromUser(p, roleId);
                              reload();
                            }}
                            privilege={p}
                          />
                        ))}
                      </div>
                    </div>
                  );
                }
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

const Users = applicationState.StateConnector(
  adminState.StateConnector(AbstractUsers)
);

export default injectIntl(Users);
