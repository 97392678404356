import * as React from 'react';
import * as container from '../../state/reducers/AdminState';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import './admin.scss';

import TextField from '../Common/TextField';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
export type SettingsProps = InjectedIntlProps & container.IProps;

interface ISearchState {
  value: number;
  debounce: number;
  debounceContract?: number;
  freeDebounce?: debounceFn;
  contractDebounce?: debounceFn;
}

type anyFn = () => void;
type debounceFn = (fn: anyFn) => void;

const debounce = (ms: number): debounceFn => {
  let interval: number = 0;
  return (fn: anyFn) => {
    if (interval) {
      clearTimeout(interval);
    }
    interval = window.setTimeout(fn, ms);
  };
};

export const AdminSearch = injectIntl(
  container.StateConnector(
    class InnerClass extends React.Component<SettingsProps, ISearchState> {
      state = {
        value: 0,
        debounce: 0,
        freeDebounce: debounce(500),
        contractDebounce: debounce(500),
      };
      handleChange = (event: React.ChangeEvent<{}>, value: any) => {
        this.setState({ value });
        switch (value) {
          case 0:
            // this.props.loadSettings();
            // this.props.setView('settings');
            break;
          case 1:
            // this.props.getUsers();
            break;
          case 2:
            // this.props.setView('db');
            break;
        }
      };

      doSearch = () => {
        if (this.props.adminSearch.activeSearch) {
          this.props.documentSearch(this.props.adminSearch.activeSearch);
        }
      };
      onFreeItemSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (this.props.adminSearch.activeSearch) {
          this.props.setItemText(e.target.value);
        }
        this.state.freeDebounce(this.doSearch);
      };
      onFreeFloorSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (this.props.adminSearch.activeSearch) {
          this.props.setFloorText(e.target.value);
        }
        this.state.contractDebounce(this.doSearch);
      };
      onFreeRoomSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (this.props.adminSearch.activeSearch) {
          this.props.setRoomText(e.target.value);
        }
        this.state.contractDebounce(this.doSearch);
      };

      onFreeBuildingSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (this.props.adminSearch.activeSearch) {
          this.props.setBuildingText(e.target.value);
        }
        this.state.contractDebounce(this.doSearch);
      };

      onFreeContractSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (this.props.adminSearch.activeSearch) {
          this.props.setContractFilter(e.target.value);
        }
        this.state.contractDebounce(this.doSearch);
      };

      onFreeTextSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (this.props.adminSearch.activeSearch) {
          this.props.setFreeText(e.target.value);
        }
        this.state.contractDebounce(this.doSearch);
      };

      render() {
        const t = this.props.intl;
        return (
          <>
            <div className="component adminTabs">
              <Tabs
                value={this.state.value}
                onChange={this.handleChange}
                indicatorColor="primary"
                textColor="primary"
                centered={true}
              >
                <Tab label="Vapaa" />
                <Tab label="Urakoittain" />
                <Tab label="Kohteittain" />
              </Tabs>

              <div>
                <TextField
                  label={t.formatMessage({
                    id: 'search.freeTextSearch',
                  })}
                  defaultValue={''}
                  onChange={this.onFreeTextSearchChange}
                />
              </div>

              <div>
                <TextField
                  label={'Urakka'}
                  defaultValue={''}
                  onChange={this.onFreeContractSearchChange}
                />
              </div>
              <div>
                <TextField
                  label={'Rakennusosa'}
                  defaultValue={''}
                  onChange={this.onFreeBuildingSearchChange}
                />
              </div>
              <div>
                <TextField
                  label={'Kerros'}
                  defaultValue={''}
                  onChange={this.onFreeFloorSearchChange}
                />
              </div>
              <div>
                <TextField
                  label={'Huone'}
                  defaultValue={''}
                  onChange={this.onFreeRoomSearchChange}
                />
              </div>
              <div>
                <TextField
                  label={'Kohde'}
                  defaultValue={''}
                  onChange={this.onFreeItemSearchChange}
                />
              </div>
            </div>
          </>
        );
      }
    }
  )
);
