import * as React from 'react';
import { Paper } from '@material-ui/core';
import { DataTable } from 'components/Common/DataTable';
import Button from 'components/Common/Button';
import { useFormatMessage } from 'utils/translateHook';
import { useAppContext } from 'utils/AppContextProvider';

export interface MissingProps {
  name?: string;
  askNewType?: (name: string) => void;
}

export default function MissingPitemType(props: MissingProps) {
  const t = useFormatMessage();
  const appState = useAppContext();

  if (!props.name) return null;
  return (
    <Paper square={true}>
      <div style={{ padding: '10px' }}>Puuttuva tyyppi {props.name}</div>
      <Button
        onClick={() => {
          if (props.askNewType && props.name) props.askNewType(props.name);
        }}
      >
        {t('adminTools.createType')} {props.name}
      </Button>

      <div style={{ padding: '10px' }}>{t('adminTools.replaceType')}</div>

      <DataTable rows={appState.pitemTypes} keys={['name']} />
    </Paper>
  );
}
