import * as React from 'react';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import {
  UIDocument,
  AreaDocumentState,
} from '../../../../shared/src/model/index';
import * as schema from '../../../../shared/src/model/projectSchema';
import * as appStateContainer from '../../state/reducers/ApplicationState';
import { Item } from '../Common/AutosuggestChipSelection';
import Button from '../Common/Button';
import TargetArea from './targetArea';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import axios from 'axios';
import { APIService } from '../../services/api/src/frontend/api';
import Loading from 'components/Common/Loading';
const API = APIService(axios);
interface ITargetAreasProps extends appStateContainer.IProps {
  isContractor: boolean;
  targetAreas: UIDocument[];
  isFiltering: boolean;
  activeFilters: string[];
  filterTerm: string;
  favourites: number[];
  showFavourites: boolean;
  workflows: schema.workflow[];
  onTargetAreaNameChange: (
    targetAreaName: string,
    targetArea: UIDocument
  ) => void;
  onAddNewTargetArea: (index: number) => void;
  onDeleteTargetArea: (target: UIDocument) => void;
  onTargetNameChange: (targetName: string, target: UIDocument) => void;
  onOrdinalChange: (docIds: number[]) => void;
  onAddNewTarget: (index: number, parentId: number) => void;
  onDeleteTarget: (target: UIDocument) => void;
  onDownloadAsSpreadsheet: () => void;
  onSetFavourites: (id: number) => void;
  filteredTargets: (targets: UIDocument[]) => UIDocument[];
}

interface ITargetAreasState {
  targetAreas: UIDocument[];
  selectedContract?: Item[];
  editingTargetAreaId?: number;
  isLoadingTargets: boolean;
  targetAreaStates: AreaDocumentState[];
}

type TargetAreasProps = ITargetAreasProps & InjectedIntlProps;

class AbstractTargetAreas extends React.Component<
  TargetAreasProps,
  ITargetAreasState
> {
  constructor(props: TargetAreasProps) {
    super(props);

    this.state = {
      isLoadingTargets: true,
      targetAreas: props.targetAreas,
      targetAreaStates: [],
    };
  }

  componentWillMount() {
    this.setState({ isLoadingTargets: true }, async () => {
      // Fetch the States of each target
      this.setState({
        targetAreaStates: await API.getAreaDocumentStates(
          this.props.targetAreas[0].parentId || 0
        ),
        isLoadingTargets: false,
      });
    });
  }

  componentDidUpdate(prevProps: TargetAreasProps) {
    if (prevProps.targetAreas !== this.props.targetAreas) {
      this.setState({ isLoadingTargets: true }, async () => {
        // Fetch the States of each target
        this.setState({
          targetAreas: this.props.targetAreas,
          targetAreaStates: await API.getAreaDocumentStates(
            this.props.targetAreas.length === 0
              ? 0
              : this.props.targetAreas[0].parentId || 0
          ),
          isLoadingTargets: false,
        });
      });
    }
  }

  onChangeContract(selection: Item[]) {
    this.setState({ selectedContract: selection });
  }

  onEditTargetArea = (targetAreaId: number | undefined) => {
    this.setState({
      editingTargetAreaId: targetAreaId,
    });
  };

  onTargetAreaNameChange = (targetAreaName: string, targetArea: UIDocument) => {
    if (targetArea.name !== targetAreaName) {
      this.props.onTargetAreaNameChange(targetAreaName, targetArea);
    }

    const stateOrder = this.state.targetAreas.map((t) => t.id);
    // const originalOrder = this.props.targetAreas.map(t => t.id);

    this.props.onOrdinalChange(stateOrder);
  };

  array_move = (arr: UIDocument[], old_index: number, new_index: number) => {
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
  };

  onSortEnd = (params: { oldIndex: number; newIndex: number }) => {
    const { oldIndex, newIndex } = params;
    this.setState({
      targetAreas: this.array_move(this.state.targetAreas, oldIndex, newIndex),
    });
  };

  public render() {
    const { isFiltering } = this.props;
    const { targetAreas } = this.state;

    if (this.state.isLoadingTargets) {
      return <Loading loading={true} />;
    }

    const SortableItem = SortableElement(
      (props: { value: UIDocument; index: number }): any => {
        const editingTargetArea =
          this.state.editingTargetAreaId === props.value.id;
        return (
          <TargetArea
            targetDocs={this.state.targetAreaStates.filter(
              (d) => d.area_id === props.value.id
            )}
            isContractor={this.props.isContractor}
            area={props.value}
            key={props.value.id + ''}
            index={props.index}
            isEditing={editingTargetArea}
            isFiltering={isFiltering}
            filterTerm={this.props.filterTerm}
            activeFilters={this.props.activeFilters}
            favourites={this.props.favourites}
            showFavourites={this.props.showFavourites}
            onEditTargetArea={this.onEditTargetArea}
            onTargetAreaNameChange={this.onTargetAreaNameChange}
            onAddNewTargetArea={this.props.onAddNewTargetArea}
            onDeleteTargetArea={this.props.onDeleteTargetArea}
            onTargetNameChange={this.props.onTargetNameChange}
            onOrdinalChange={this.props.onOrdinalChange}
            onAddNewTarget={this.props.onAddNewTarget}
            onDeleteTarget={this.props.onDeleteTarget}
            onSetFavourites={this.props.onSetFavourites}
            filteredTargets={this.props.filteredTargets}
            workflows={this.props.workflows}
          />
        );
      }
    );

    const SortableList = SortableContainer((props: { items: UIDocument[] }) => {
      return (
        <ol className="targetAreas">
          {props.items.map((value: UIDocument, index: number) => (
            <SortableItem key={`item-${index}`} index={index} value={value} />
          ))}
        </ol>
      );
    });

    return (
      <>
        <div className="inspectionDocumentActions">
          {isFiltering ? (
            <Button onClick={this.props.onDownloadAsSpreadsheet}>
              <FormattedMessage id="inspectionDocuments.downloadVisibleTargetsAsSpreadsheet" />
            </Button>
          ) : (
            <Button onClick={this.props.onDownloadAsSpreadsheet}>
              <FormattedMessage id="inspectionDocuments.downloadInspectionDocumentAsSpreadsheet" />
            </Button>
          )}
        </div>

        <div className="targetAreas">
          {targetAreas.length > 0 && (
            <SortableList
              onSortEnd={this.onSortEnd}
              lockAxis={'y'}
              useDragHandle={true}
              items={targetAreas}
            />
          )}
        </div>
      </>
    );
  }
}

const TargetAreas = appStateContainer.StateConnector(AbstractTargetAreas);

export default injectIntl(TargetAreas);
