import * as React from 'react';
import { Paper, Input, TableCell } from '@material-ui/core';
import { DataTable } from 'components/Common/DataTable';
import Button from 'components/Common/Button';
import { findPitems, deletePitem } from 'state/api';
import { useFormatMessage } from 'utils/translateHook';
import { errorMessage } from 'components/Common/Notifier';
import { Link } from 'react-router-dom';

interface ItemData {
  id: number;
  name: string;
  buildingName: string;
}
export interface MissingProps {}

export default function DeleteOldItems(props: MissingProps) {
  const t = useFormatMessage();
  const [search, setSearch] = React.useState('');
  const [results, setResults] = React.useState<ItemData[]>([]);
  const runSearch = async () => {
    const results: ItemData[] = (await findPitems(search)).rows.map((r) => {
      return {
        id: r.id,
        name: r.name,
        buildingName: r.building ? r.building.name : '',
      };
    });
    setResults(results);
  };
  // setResults
  return (
    <Paper square={true}>
      <div style={{ padding: '10px' }}>Etsi kohteita</div>
      <Input
        onChange={(e) => {
          setSearch(e.target.value);
        }}
      />
      <Button onClick={runSearch}>Hae</Button>
      <DataTable
        render={{
          name: (row) => {
            return <Link to={`/items/${row.id}`}>{row.name}</Link>;
          },
        }}
        rowPrefix={(row) => {
          return (
            <TableCell>
              <Button
                onClick={async () => {
                  try {
                    await deletePitem(row.id);
                    runSearch();
                  } catch (e) {
                    errorMessage(e);
                  }
                }}
              >
                {t('buttons.delete')}
              </Button>
            </TableCell>
          );
        }}
        rows={results}
        keys={['id', 'name', 'buildingName']}
      />
    </Paper>
  );
}
