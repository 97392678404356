import brandVariables from './brandVariables';

const bv = brandVariables['en'];

const translations = {
  'generics.header.pitem': 'Items',
  'generics.aitem.is_hidden': 'Hidden',
  'generics.aitem.name': 'Name',
  'generics.aitem.name_long': 'Contract Description',
  'generics.pitem.name': 'Code',
  'generics.pitem.name_long': 'Target description',
  'generics.pitem.route_start_pos': 'Start pole',
  'generics.pitem.route_end_pos': 'End pole',
  'generics.pitem.specifier': 'Target code',
  'generics.pitem.warranty_expires': 'Warranty expires',
  'generics.pitem.additional_information': 'Additional information 1',
  'generics.pitem.additional_information2': 'Additional information 2',
  'generics.pitem.is_tracked': 'Tracked',
  'generics.pitem.is_tracked.setTrue': 'Set status "Tracked"',
  'generics.pitem.is_tracked.setFalse': 'Remove status "Tracked"',
  'generics.pitem.upper_equipment_id': 'Upper target',
  'generics.pitem.secondary_hierarchy_id': 'Secondary hierarchy',
  'generics.pitem.criticality_class_id': 'Priority',
  'generics.pitem.area_id': 'Maintenance area',
  'generics.pitem.systems_path': 'System',
  'generics.pitem.floor': 'Floor',
  'generics.pitem.room': 'Room',
  'generics.pitem.building': 'Building',
  'generics.pitem.parents': 'Parents',
  'generics.pitem.work_aitems': 'Contracts',
  'generics.pitem.pitemtypes': 'Item Types',
  'generics.pitem.target': 'Item',
  'generics.pitem.polerange': 'Pole range',
  'generics.pitem.created_by_user': 'Created By',
  'headers.emails': 'Email settings',
  'summary/status': 'Yhteenveto',
  'summary/review': 'Tarkastusta odottavat',
  'summary/accept': 'Hyväksyntää odottavat',
  'summary/rejected': 'Hylätyt urakoitsijan dokumentit',
  'summary/newContractorDocs': 'Uudet hyväksytyt tilaajan dokumentit',
  'categories.documentCategories': 'Document categories',
  'categories.crerateNewCategory': 'Create new category under',
  'categories.categorySuccesfullyCrerated':
    'New category was successfully created',
  'categories.changeWorkflow': 'Select workflow',
  'categories.changeWorkflowNotice':
    'Change of the workflow only affects new documents',
  'categories.workflowChanged':
    'Workflow of the category was successfully changed.',
  'categories.designDisciplines': 'Design Disciplines',
  'categories.workPhases': 'Work phases',
  'categories.contractorDocumentTypes': 'Contractor Documents',
  'adminTools.removeContract': 'Remove Contract',
  'adminTools.removeContractSuccess': 'Contract Removed Succesfully',
  'adminTools.removeContractFail':
    'Removing the Contract failed, you can not remove contract with documents linked to it',
  'adminTools.docToSystem': 'Systems',
  'adminTools.docs': 'Documents',
  'adminTools.building': 'Buildings',
  'adminTools.sync': 'Syncronizing',
  'adminTools.categories': 'Categories',
  sync: 'Synkronointi',
  'terms.customer': 'Customer',
  'messages.loading': 'Loading...',
  'buttons.edit': 'Edit',
  'buttons.save': 'Save',
  'buttons.search': 'Search',
  'buttons.cancel': 'Cancel',
  'buttons.open': 'Open',
  'buttons.delete': 'Delete',
  'buttons.close': 'Close',
  'buttons.downloadFiles': 'Download Files',
  'buttons.filter': 'Filter',
  'buttons.addComment': 'Comment',
  'buttons.add': 'Add',
  'buttons.markAsIncomplete': 'Set status "Missing documents"',
  'buttons.setAsTracked': 'Set status "Tracked"',
  'buttons.unsetAsTracked': 'Remove status "Tracked"',
  'buttons.update': 'Update',
  'buttons.setReplacement': 'Set replacement document',
  'buttons.clearReplacement': 'Clear replacement document',
  'buttons.defineReplacement': 'Define replacement',
  'buttons.changeReplacement': 'Change replacement',
  'buttons.findSimilarDocs': 'Find Items with same doc',
  'headers.home': 'Home',
  'headers.homeLong': `${bv.applicationLongName}`,
  'headers.inspectionDocument': 'Inspection Record',
  'headers.managementTools': 'Management Tools',
  'headers.waitingForReview': 'Waiting For Review',
  'headers.documents': 'Documents',
  'headers.document': 'Document',
  'headers.documentPackage': 'Document Collection',
  'headers.item': 'Construction Product and Material',
  'headers.addContractorDocuments': 'Add Contractor Documents',
  'headers.users': 'Users',
  'headers.user': 'User',
  'headers.PersonalInfo': 'Personal info',
  'headers.roles': 'Roles',
  'headers.contracts': 'Contracts',
  'headers.approvalCycles': 'Workflows',
  'headers.areas': 'Areas',
  'headers.misc': 'Misc',
  'headers.sentEmails': 'Sent Emails',
  'login.forgotPassword': 'Forgot password',
  'login.header': 'Log in',
  'login.login': 'Log in',
  'login.password': 'Password',
  'login.username': 'Username',
  'login.loginError': 'Wrong username or password',
  'login.GDPRLink': 'GDPR Statement',
  'login.systemDown': 'System is being updated. Log in has been disabled.',
  'mainnavigation.logout': 'Log out',
  'passwordReset.backToLogin': 'Back',
  'passwordReset.email': 'E-mail',
  'passwordReset.header': 'Change Password',
  'passwordReset.instructions':
    'Write the E-mail you have used to log in to the system. An e-mail will be sent to you with password changing instructions.',
  'passwordReset.messageSent': 'E-mail sent.',
  'passwordReset.passwordOld': 'Current Password',
  'passwordReset.passwordNew': 'New Password',
  'passwordReset.passwordNew2': 'Repeat Password',
  'passwordReset.requirements':
    'Password has to be min 8 characters long and it has to include upper case and lower case characters (a-z) and numbers (0-9). Password cannot contain spaces.',
  'passwordReset.requirements.eightCharacters': 'Min 8 characters',
  'passwordReset.requirements.hasCapitalLetters':
    'Password must contain upper and lower case characters.',
  'passwordReset.requirements.hasLowercaseLetters':
    'Password must contain lower case characters.',
  'passwordReset.requirements.hasNumbers': 'Password must contain numbers.',
  'passwordReset.requirements.isPasswordMatch': 'Passwords do not match.',
  'passwordReset.requirements.noSpaces': 'Password cannot contain spaces.',
  'passwordReset.requirements.required': 'Compulsory field.',
  'passwordReset.returnToLoginPage': 'Back',
  'passwordReset.submit': 'Submit',
  'passwordReset.submitEmail': 'Send E-mail',
  'passwordReset.success': 'Password changed.',
  'passwordReset.invalidCredentials': 'Wrong Password',
  'passwordReset.tokenExpired':
    'Password changing link has expired. Please order a new link.',
  'passwordReset.invalidToken': 'Wrong code.',
  'passwordReset.userHasNoPermissions':
    'You do not have rights to AssetPoint Online. You have received an invalid E-mail.',
  'search.selectTarget': 'Select...',

  // Pitem metadata
  'pitem.code': 'Code',
  'pitem.description': 'Description',
  'pitem.workAitems': 'Contracts',
  'pitem.target': 'Item',
  'pitem.targets': 'Items',
  'pitem.isTracked': 'Tracked',

  // Search main categories
  PITEMS: 'Items',
  TARKASTUSASIAKIRJA: 'Inspection Record',
  TIL_DOC: 'Design Document',
  URAK_DOC: 'Contractor Document',
  URAK_DP: 'Constructor Document Collection',

  'search.header': 'Search',
  'search.clear': 'Clear',
  'search.resultsHeader': 'Search Results',
  'search.categoryId': 'Document Type',
  'search.freeTextSearch': 'Search',

  'search.searchFromContent': 'Search Content',
  'search.addSearchCondition': '+ Add Search Criteria',
  'search.hideSearchConditions': '- Hide Search Criteria',
  'search.SelectSearchCondition': 'Select Search Criteria',
  'search.savedSearchHeader': 'Save Search Criteria',
  'search.savedSearchName': 'Title',
  'search.deleteSavedSearchHeader': 'Delete Saved Search',
  'search.deleteSavedSearchMessage':
    'You are deleting the save search "{name}". Do you want to continue?',
  'search.noSearchResults': 'No search results.',
  'search.saveSuccess': 'Search saved!',
  'search.columns': 'Select Columns',
  'search.downloadAsZip': 'Download as zip-file',
  'search.downloadAsSpreadsheet': 'Download as Excel-file',
  'warning.documentNotFound': 'Document not found.',
  'warning.invalidAdminPassword': 'Incorrect password.',
  'warning.invalidNumber': 'Virheellinen numero',
  'warning.numberMustBePositive': 'Number must be greater or equal to zero',
  'warning.update.denied': 'Update denied',
  'documentMeta.inspectionDocTarget': 'Target',
  'documentMeta.saved': 'Saved',
  'documentMeta.workPhases': 'Work Types',
  'documentMeta.workPhase': 'Work Type',
  'documentMeta.addWorkphase': 'Add Work type',
  'documentMeta.chooseUploadMethod': 'Select',
  'documentMeta.rejectReason': 'Rejection Comment',
  'documentMeta.name': 'Subject',
  'documentMeta.category': 'Category',
  'documentMeta.designDiscipline': 'Design Discipline',
  'documentMeta.contractorDocumentType': 'Document Type',
  'documentMeta.isTracked': 'Tracked',
  'documentMeta.inspectionDocumentAttachmentDocumentType': 'Document Type',
  'documentMeta.fileType': 'File Type',
  'documentMeta.fileName': 'Document',
  'documentMeta.documentCategory': 'Document Type',
  'documentMeta.pitemTypes': 'Item Types',
  'documentMeta.documentType': 'Document Type',
  'documentMeta.createdByUser': 'Created By',
  'documentMeta.pitemTypeName': 'Type',
  'documentMeta.modifiedByUser': 'Edited By',
  'documentMeta.created': 'Created',
  'documentMeta.modified': 'Edited',
  'documentMeta.location': 'Location',
  'documentMeta.pitemTree': 'Location',
  'documentMeta.floor': 'Floor',
  'documentMeta.target': 'Construction Product',
  'documentMeta.targetName': 'Code',
  'documentMeta.targetcode': 'Code',
  'documentMeta.projectPhase': 'Area Part',
  'documentMeta.contract': 'Contract',
  'documentMeta.poleInterval': 'Pole Range',
  'documentMeta.startPole': 'Start Pole',
  'documentMeta.endPole': 'End Pole',
  'documentMeta.pole': 'Pole',
  'documentMeta.polerange': 'Pole Range',
  'documentMeta.building': 'Location',
  'documentMeta.buildingElement': 'Location',
  'documentMeta.inspectionDocument': 'Inspection Record',
  'documentMeta.room': 'Room',
  'documentMeta.roomName': 'Room name',
  'documentMeta.roomId': 'Room Code',
  'documentMeta.buildingElementPlaceholder': 'Select location',
  'documentMeta.area': 'Area',
  'documentMeta.areas': 'Areas',
  'documentMeta.system': 'System',
  'documentMeta.revision': 'Revision',
  'documentMeta.aItems': 'Contract',
  'documentMeta.pItems': 'Location',
  'documentMeta.dwgName': 'DWG',
  'documentMeta.fileSize': 'Size',
  'documentMeta.fileExtension': 'File Extension',
  'documentMeta.workflowStateName': 'Document Status',
  'documentMeta.categories': 'Type',
  'documentMeta.pitemType': 'Type',
  'documentMeta.targetTypes': 'Type',
  'documentMeta.dwg': 'DWG',
  'documentMeta.version': 'Version',
  'documentMeta.workAitems': 'Contracts',
  'documentMeta.versionNumber': 'Version',
  'documentMeta.workflowState': 'Document State',
  'documentMeta.description': 'Description',
  'documentMeta.downloadLink': 'Download',
  'documentMeta.statusHistory': 'Status History',
  'documentMeta.versionHistory': 'Version History',
  'documentMeta.reasonForRejection': 'Rejection Comment',
  'documentMeta.documentLink': 'Linked File',
  'documentMeta.updateRevision': 'New Revision',
  'documentMeta.SUBMITTED': 'Waiting for Review',
  'documentMeta.INITIAL_ACCEPT': 'Waiting for Acceptance',
  'documentMeta.ACCEPTED': 'Accepted',
  'documentMeta.REJECTED': 'Rejected',
  'documentMeta.INIT': 'Initialized',
  'documentMeta.HAS_DOC': 'New documents added',
  'documentMeta.ALL': 'All',
  'documentMeta.reasonForReject': 'Reason for Rejection:',
  'documentMeta.removeSuccess': 'Documents Deleted',
  'documentMeta.acceptSuccess': 'Documents Accepted',
  'documentMeta.selectType': 'Select Document Type',
  'documentMeta.multipleDocuments': 'Read Metadata from Excel',
  'documentMeta.assortedDocuments': 'Set Metadata Manually',
  'documentMeta.updateMetadata': 'Update Metadata Only',
  'documentMeta.designerDocumentType': 'Document Type',
  // Contractor work type was called "Työlaji" previously
  'documentMeta.contractorWorktype': 'Work Type', // Both spellings are needed
  'documentMeta.contractorWorkType': 'Work Type',
  'documentMeta.locationLongname': 'Name',
  'documentMeta.documentHasNewVersion':
    'Document has a newer version. Please check version history.',
  INITIAL_ACCEPT: 'Accept',
  ACCEPT: 'Accept',
  ACCEPT_SUBMIT: 'Accept',
  REJECT: 'Reject',
  REJECT2: 'Reject',
  'documentMeta.confirmInitialAccept':
    'Do you want to accept all the selected documents?',
  'documentMeta.confirmAccept':
    'Do you want to reject all the selected documents?',
  'documentMeta.documentPackageError': 'Invalid Document Collection',
  'documentMeta.noDocumentPackages': 'No Documents',
  'documentMeta.documentPackageAutomaticRejection':
    'Document Collection is rejected as it contains rejected documents.',
  'documentMeta.replacementDocument': 'Replacement document',
  'documentMeta.name_long': 'Target description',
  'documentMeta.specifier': 'Target code',
  'documentMeta.criticality_class': 'Priority',
  'documentMeta.warranty_expires': 'Warranty expires',
  'documentMeta.upper_equipment': 'Upper target',
  'documentMeta.secondary_hierarchy': 'Secondary hierarchy',
  'documentMeta.area_name': 'Maintenance area',
  'documentMeta.additional_information': 'Additional information 1',
  'documentMeta.addtional_information2': 'Additional information 2',
  'documentMeta.similarDocumentsNotFound': 'Similar documents not found',
  'itemMeta.itemType': 'Type',
  'settings.header': 'Settings',
  'settings.savedSearchesHeader': 'Saved Searches',
  'settings.agreementsHeader': 'Terms of Use',
  'settings.sendMails': 'Notifications',
  'settings.settings': 'Settings',
  'settings.userLanguage': 'Language',
  'settings.selectUserLanguage': 'Select language...',
  'settings.save': 'Save',
  'settings.noPrivilegesGranted': 'You do now have rights to any content.',
  'settings.support': 'Help',
  'settings.userManual': 'Download User Manual',
  'settings.supportMessage': `
AssetPoint Online HelpDesk:

E-mail: [support@assetpoint.fi](mailto:tuki@lansimetro.fi)

Phone number: 044 716 5455 (weekdays 9-15)
  `,
  'upload.save': 'Save',
  'upload.waiting': 'Files being uploaded...',
  'upload.ready': 'Files saved',
  'upload.selectDocumentCollection': 'Select Excel-file',
  'upload.changeDocumentCollection': 'Change Excel-file',
  'upload.provideMetadata': 'Add metadata',
  'upload.addDocuments': 'Add documents',
  'upload.document.type': 'Type',
  'upload.document.type.placeholder': 'Select Document Type',
  'upload.document.contract': 'Contract',
  'upload.document.contract.placeholder': 'Select Contract',
  'upload.document.subcontract': 'Contract',
  'upload.document.subcontract.placeholder': 'Select contracts',
  'upload.document.item': 'Item',
  'upload.document.item.placeholder': 'Select Items',
  'upload.document.addItem': 'Add Item',
  'upload.addMetadata': 'Add metadata',
  'upload.dropzoneInfo': 'Drop documents here',
  'upload.dropzoneInfoSingle': 'Drop document here',
  'upload.loadingCollection': 'Loading',
  'upload.documentCollection': 'Data to be updated:',
  'upload.uploadDocumentCollection': 'Saved Documents',
  'upload.updateDocumentCollection': 'Update Metadata Only',
  'upload.savedDocumentsHeader': 'Added Documents',
  'upload.saving': 'Saving Files',
  'upload.updatingDocumentsFromCollection':
    'Updating document metadata from Excel file',
  'upload.documentsSuccessful': 'Documents saved',
  'upload.collectionSuccessful': 'Save Successful',
  'upload.matchedRows': 'Updated documents ({amount} kpl)',
  'upload.notMatchedRows': 'Missing documents ({amount} kpl)',
  'upload.selectedDocumentAmount': 'Selected Documents: {amount} kpl',
  'upload.uploadContractorDocuments': 'Contractor Documents',
  'upload.uploadDesignerDocumentsSuccess':
    '{amount} documents were saved successfully',
  'upload.uploadDesignerDocumentsExists':
    'The same document already exists: {filenames}',
  'documentTable.selected': 'Selected',
  'documentTable.delete': 'Deleted',
  'documentTable.selectDWG': 'Select DWG',
  'documentTable.labelRowsPerPage': 'Number of Rows',
  'downloadBasket.addToBasket': 'Add to Selection',
  'downloadBasket.backToBasket': 'Back',
  'downloadBasket.title': 'Selection',
  'downloadBasket.file': 'File',
  'downloadBasket.fileType': 'File Type',
  'downloadBasket.empty': 'No Added Documents',
  'downloadBasket.clear': 'Clear',
  'downloadBasket.downloadAll': 'Selection',
  'downloadBasket.toPrintOrder': 'Order Prints',
  'downloadBasket.someNonPrintable':
    'Only PDF-files will be added to print order.',
  'userDetails.firstName': 'Firstname',
  'userDetails.lastName': 'Lastname',
  'userDetails.email': 'E-mail',
  'userDetails.organization': 'Organization',
  'userDetails.password': 'Password',
  'userDetails.phoneNumber': 'Phone Number',
  'printOrder.title': 'Order Prints',
  'printOrder.basketEmpty': 'No Files added to Selection',
  'printOrder.step1': 'Files',
  'printOrder.step2': 'Documents',
  'printOrder.step3': 'Summary',
  'printOrder.step4': 'Complete',
  'printOrder.placeHolder': 'placeholder',
  'printOrder.ordererInfo': 'Info',
  'printOrder.orderer': 'Orderer name',
  'printOrder.project': 'Work Name / Project',
  'printOrder.payerCompany': 'Billing',
  'printOrder.streetAddress': 'Address',
  'printOrder.orderAdditionalInfo': 'Description',
  'printOrder.payerInfo': 'Billing Info',
  'printOrder.ordererContact': 'Contact Person',
  'printOrder.generalInfo': 'Order Info',
  'printOrder.postalCode': 'Postal Code',
  'printOrder.city': 'City',
  'printOrder.country': 'Country',
  'printOrder.orderRows': 'Documents',
  'printOrder.amountsAndFoldings': 'Amounts and Foldings',
  'printOrder.allDocuments': 'All Documents',
  'printOrder.deliveryDestination': 'Delivery Address',
  'printOrder.additionalInfo': 'Additional Info',
  'printOrder.deliverToCompany': 'Company',
  'printOrder.deliveryMethod': 'Method of Delivery',
  'printOrder.next': 'Next',
  'printOrder.previous': 'Back',
  'printOrder.amount': 'Amount',
  'printOrder.totalAmount': 'Total Amount',
  'printOrder.document': 'Document',
  'printOrder.handling': 'Handling',
  'printOrder.addToOrder': 'Add to Order',
  'printOrder.documentsInOrder': 'Print Order Info',
  'printOrder.noDocumentsInOrder':
    'No documents in order. Add at least one to continue.',
  'printOrder.sendOrder': 'Send order',
  'printOrder.done': 'Order saved.',
  'printOrder.delivery.DeliveryGuy': 'Courier',
  'printOrder.delivery.Post': 'Post Office',
  'printOrder.folding.Folded210mm': 'Folded 210 mm',
  'printOrder.folding.Folded190mm': 'Folded 190 mm',
  'printOrder.folding.Folded190mmSpine': 'Folded 190 mm + Spine',
  'printOrder.folding.Folded210mmSpine': 'Folded 210 mm + Spine',
  'printOrder.folding.Folded19_21': 'Folded 19/21',
  'printOrder.folding.StraightCopy': 'Straight',
  'printOrder.folding.OriginalsReturn': 'Originals Return',
  'printOrder.folding.PtintHotlaminate': 'Print + Hot Laminate',
  'printOrder.folding.Weatherproof': 'Weather Proof',
  'printOrder.folding.PrintPlastic': 'Print Plastic',
  'printOrder.folding.Scanning': 'Scanning',
  'printOrder.folding.OtherHandling': 'Other Handling Method',
  'printOrder.errors.email': 'E-mail address not correct',
  'printOrder.colorPrint': 'Color print',
  'error.unauthorized': 'Log in expired',
  'error.documentNotFound': 'Document not found.',
  'error.someDocumentsFailedToUpload':
    'All documents were not saved successfully.',
  'error.documentUploadFailed': 'Document {name} was not saved',
  'error.collectionUploadFailed': 'List of Documents {name} was not saved',
  'error.fileSizeLimitExceeded': 'File Size Limit Exceeded. Limit is 50Mt',
  'error.tooManyResults':
    'The search returned too many results. Please, limit the search.',
  'agreement.continue': 'Continue',
  'agreement.accept': 'Accept',
  'agreement.close': 'Close',
  'agreement.agreed': 'Accepted',
  'documentPreview.preview': 'Preview',
  'documentPreview.previewNotAvailable': 'Preview not available',
  'documentPreview.error': 'Loading preview failed',
  'documentPreview.noData': 'No File Selected',
  'documentPreview.page': 'Page',
  'documentPreview.page.error': 'Loading error.',
  'documentPreview.page.noData': 'No data',
  'documentPreview.page.prev': 'Back',
  'documentPreview.page.next': 'Next',
  'newUser.userAdded': 'User Added',
  'newUser.addUser': 'Add User',
  // LDAP translations
  'Invalid Credentials': 'Wrong Password',
  'Entry Already Exists': 'Username already Exists',
  'New user created': 'New User Created',
  'inspectionDocuments.withoutDocuments': 'Asiakirja on luotu ilman liitteitä',
  'inspectionDocuments.documentsAdded': 'Liitteet on lisätty',
  'inspectionDocuments.documentsAccepted': 'Liitteet on hyväksytty',
  'inspectionDocuments.documentsRejected': 'Liitteitä on hylätty',
  'inspectionDocuments.viewBuildingPermits': 'List Building Permits',
  'inspectionDocuments.buildingPermitsDialogHeader': 'Building Permits',
  'inspectionDocuments.adminView': 'Settings',
  'inspectionDocuments.adminViewHeader': 'Settings',
  'inspectionDocuments.newInspectionDocument': 'New',
  'inspectionDocuments.excelImportLabel': 'Import Data using Excel',
  'inspectionDocuments.documentName': 'Name',
  'inspectionDocuments.inspectionDocuments': 'Inspection Records',
  'inspectionDocuments.target': 'Inspection name',
  'inspectionDocuments.targets': 'Inspections',
  'inspectionDocuments.comment': 'Comment',
  'inspectionDocuments.comments': 'Comments',
  'inspectionDocuments.reason': 'Reason',
  'inspectionDocuments.inspectors': 'Inspectors',
  'inspectionDocuments.workflow': 'Work Flow',
  'inspectionDocuments.addDocuments': 'Add Documents',
  'inspectionDocuments.document': 'Document',
  'inspectionDocuments.contract': 'Contract',
  'inspectionDocuments.designer': 'Designer',
  'inspectionDocuments.supervisor': 'Clerk of Works',
  'inspectionDocuments.inspectionType': 'Inspection Group',
  'inspectionDocuments.workflowStateName': 'Status',
  'inspectionDocuments.content': 'Content',
  'inspectionDocuments.inspectionContent': 'Inspection Content',
  'inspectionDocuments.cancel': 'Cancel',
  'inspectionDocuments.save': 'Save',
  'inspectionDocuments.created': 'Created',
  'inspectionDocuments.modified': 'Edited',
  'inspectionDocuments.noComments': 'No Comments',
  'inspectionDocuments.subject': 'Title',
  'inspectionDocuments.targetArea': 'Incpection Group',
  'inspectionDocuments.confirmTargetAreaDeletion':
    'You are deleting Inspection Group. Do you want to delete the Group and its contents?',
  'inspectionDocuments.targetAreaDeleted': 'Inspection Group deleted',
  'inspectionDocuments.confirmTargetDeletion':
    'You are deleting Inspection. Do you want to delete the Inspection and its contents?',
  'inspectionDocuments.targetDeleted': 'Inspection deleted',
  'inspectionDocuments.downloadInspectionDocumentAsSpreadsheet':
    'Download Excel',
  'inspectionDocuments.downloadVisibleTargetsAsSpreadsheet': 'Download Excel',
  'inspectionDocuments.noIspectionDocuments': 'No Inspection Records',
  'inspectionDocuments.newInspectionDocumentAddedSuccessfully':
    'New Inspection Record Added',
  'inspectionDocuments.inspectionDocumentDeletedSuccessfully':
    'Inspection Record deleted',
  'inspectionDocuments.addNewTargetArea': 'New Inspection Group',
  'inspectionDocuments.addNewTarget': 'New Inspection',
  'inspectionDocuments.selectFile': 'Select File',
  'inspectionDocuments.attachmentName': 'Name',
  'inspectionDocuments.attachmentFileName': 'File Name',
  'inspectionDocuments.attachmentCategories': 'Categories',
  'inspectionDocuments.attachmentState': 'Status',
  'inspectionDocuments.addNewAttachment': 'Add new document',
  'inspectionDocuments.addNewAttachmentSuccess': 'Document saved',
  'inspectionDocuments.updateDocumentSuccess': 'Document updated',
  'inspectionDocuments.documentUploaded': 'Document {name} added',
  'inspectionDocuments.hasMissingAttachments':
    'Inspection is missing documents',
  'inspectionDocuments.documentNotUploaded': 'File {name} was not saved.',
  'inspectionDocuments.tabulaRasa': `
**Inspection Record is requires by authorities to prove constraction work has been conducted according to current laws and requirements.**

Finnish Land Use and Building Act, section 150:

*â€Inspection by authorities
The setting out of the location and level of a building, checking them after completion of the foundation
work, and the inspections to be made during the course of the work are specified in the permit concerning
construction.

If an inspection or check warrants admonition, any action that is required to remove and remedy the defect
or error and the period within which it has to be taken must be specified in written instructions. The party
concerned is entitled to place such instructions before the local building supervision authority for
consideration by means of a claim for rectification.

In order to guarantee that construction work has been carried out properly and to verify inspections an
inspection record is kept at the building site. Checks, inspections by the authorities and inspections of work
specified as being carried out by private parties are entered in the record.

Provisions on checks and inspections needed to supervise construction work will be issued by decree. â€*

`,
  'inspectionDocuments.deleteInspectionDocumentHeader':
    'Tarkastusasiakirjan poisto',
  'inspectionDocuments.deleteInspectionDocumentMessage':
    'Olet poistamassa kohdetta **{name}**. Et voi perua tätä toimintoa. Anna salasanasi varmistaaksesi poiston',

  'inspectionDocuments.filterLabels.contractor.green': 'All documents accepted',
  'inspectionDocuments.filterLabels.contractor.yellow': 'Missing documents',
  'inspectionDocuments.filterLabels.contractor.blue':
    'All documents are not accepted',
  'inspectionDocuments.filterLabels.contractor.red': 'Notes',

  'inspectionDocuments.filterLabels.inspector.green': 'All documents accepted.',
  'inspectionDocuments.filterLabels.inspector.yellow':
    'Documents waiting for Acceptance',
  'inspectionDocuments.filterLabels.inspector.blue':
    'All documents are not added but all added documents are accepted',

  'inspectionDocuments.filterLabels.default.green': 'All documents accepted',
  'inspectionDocuments.filterLabels.default.yellow':
    'Documents waiting for acceptance.',
  'inspectionDocuments.filterLabels.default.blue':
    'All documents are not added byt all added documents are accepted.',

  TARKASTUSASIAKIRJA_DOC: 'Inspection Record Document',
  TA_OTHER: 'Other Document',
  TA_REVIEW_DOC: 'Inspection Document',
  TA_RECEPTION_DOC: 'Reception Document',
  TA_INSPECTION_DOC: 'Review Document',
  Z_dummy: 'Dummy',
  // Workflow states
  DEFAULT: 'Tilaajan dokumentit: Suunnittelija tarkastaa, tilaaja hyväksyy',
  DESIGNER_APPROVES:
    'Urakoitsijan dokumentit: Suunnittelija tarkastaa ja hyväksyy',
  DESIGNER_AND_INSPECTOR:
    'Urakoitsijan dokumentit: Suunnittelija tarkastaa, tilaaja hyväksyy',
  INSPECTOR_APPROVES: 'Urakoitsijan dokumentit: Tilaaja tarkastaa ja hyväksyy',
  NO_WORKFLOW: 'Hyväksytty lisäyshetkellä',
  TA_INSPECTOR_APPROVES: 'Tarkastusasiakirja: Valvoja tarkastaa ja hyväksyy',
  TA_DESIGNER_APPROVES:
    'Tarkastusasiakirja: Suunnittelija tarkastaa ja hyväksyy',
  TA_DESIGNER_INSPECTOR_APPROVES:
    'Tarkastusasiakirja: Suunnittelija tarkastaa, valvoja hyväksyy',

  'error.title': 'Error',
  'error.instructions': `
#
[Return to Front Page](/)
`,
  'workphase.uploadProgress': 'Uploading Files',
  'workphase.selectContract': 'Select Contract:',
  'workphase.selectWorkType': 'Select Work Type:',
  'workphase.selectFiles': 'Select Files:',
  'workphase.selectFileType': 'Select Document Type: ',
  'contractorDocumentPackage.selectRoom': 'Select Room',
  'contractorDocumentPackage.selectSystem': 'Select System',
  'contractorDocumentPackage.selectOr': '...or',
  'contractorDocumentPackage.continue': 'Continue',
  'contractorDocumentPackage.selectMainLevel':
    'Select Contract and Document Type',
  'contractorDocumentPackage.selectTargets':
    'Select Construction Products or Materials:',
  'contractorDocumentPackage.searchByName': 'Search by Name',
  'contractorDocumentPackage.checkAndSelectTargets': 'Select Items',
  'contractorDocumentPackage.longerName': 'Name',
  'contractorDocumentPackage.pleaseSelectTargets':
    'Please select at least one item.',
  'contractorDocumentPackage.noTargetsSelected':
    'You have not selected any items.',
  // Work type was called "Työlaji" previously
  'contractorDocumentPackage.selectWorkType': 'Select Work Type',
  'contractorDocumentPackage.selectedTargets': 'Selected Items',
  'contractorDocumentPackage.selectDocumentFromFilesystem': 'Select Documents',
  'contractorDocumentPackage.selectDocuments': 'Select Documents',
  'contractorDocumentPackage.file': 'File',
  'contractorDocumentPackage.type': 'Type',
  'contractorDocumentPackage.acceptChoices': 'Submit',
  'contractorDocumentPackage.updatedFiles': 'Updated Files',
  'contractorDocumentPackage.isMissingDocuments':
    'Document Collection is Missing Documents.',
  'contractorDocumentPackage.allDocuments': 'All Documents',
  parentName: 'Upper category',
  name: 'Name',
  workflowId: 'Workflow name',
  'wf.DEFAULT':
    'Tilaajan dokumentit: Suunnittelija tarkastaa, tilaaja hyväksyy',
  'wf.URAK_DP': 'Urakoitsijan dokumenttipaketin työnkierto',
  'wf.DESIGNER_APPROVES':
    'Urakoitsijan dokumentit: Suunnittelija tarkastaa ja hyväksyy',
  'wf.DESIGNER_AND_INSPECTOR':
    'Urakoitsijan dokumentit: Suunnittelija tarkastaa, tilaaja hyväksyy',
  'wf.INSPECTOR_APPROVES':
    'Urakoitsijan dokumentit: Tilaaja tarkastaa ja hyväksyy',
  'wf.NO_WORKFLOW': 'Hyväksytty lisäyshetkellä',
  'wf.TA_INSPECTOR_APPROVES':
    'Tarkastusasiakirja: Valvoja tarkastaa ja hyväksyy',
  'wf.TA_DESIGNER_APPROVES':
    'Tarkastusasiakirja: Suunnittelija tarkastaa ja hyväksyy',
  'wf.TA_DESIGNER_INSPECTOR_APPROVES':
    'Tarkastusasiakirja: Suunnittelija tarkastaa, valvoja hyväksyy',
  'adminTools.buildingChanged': 'Building or room changed',
  'adminTools.changeBuildings': 'Change building and/or room',
  'adminTools.setSystemBreakMessage': 'Set Message to be seen by all users',
  'adminTools.stopMainetananceBreak': 'Stop Maintenance Break',
  'adminTools.startMainetananceBreak': 'Start Maintenance Break',
  'adminTools.systemHasMainetananceBreak': 'System is being updated.',
  'adminTools.mainteanance': 'Set Maintenance Break',
  'adminTools.firstname': 'First Name',
  'adminTools.lastname': 'Last Name',
  'adminTools.name': 'Name',
  'adminTools.moveToEam': 'Move to VincitEAM',
  'adminTools.printOrders': 'Prints',
  'adminTools.email': 'E-mail',
  'adminTools.userInformation': 'User Indo',
  'adminTools.adminUser': 'Admin user',
  'adminTools.unattachedUser': 'AD user',
  'adminTools.disabledUser': 'Locked User',
  'adminTools.addUserToLocalDB': 'Add AD user to APOL',
  'adminTools.userPermissions': 'User Rights',
  'adminTools.users': 'Users',
  'adminTools.roles': 'Roles',
  'adminTools.role': 'Role',
  'adminTools.deleteItems': 'Delete Items',
  'adminTools.deviceLists': 'Device lists',
  'adminTools.allDevices': 'All Devices',
  'adminTools.deviceAmount': 'Devices',
  'adminTools.deviceList': 'Devices',
  'adminTools.unknownSystems': 'Following systems were not found',
  'adminTools.targetsWithoutMetaInfo': 'Meta info is not read to these devices',
  'adminTools.targetsWithoutBuildingElement':
    'Building element is not set to these devices',
  'adminTools.targetsWithMultipleMetaInfo':
    'Meta info is tried to be red from multiple cells for these devices',
  'adminTools.devicesToBeDeleted': 'Following devices wil be removed',
  'adminTools.isMissingTargetcodes':
    'The document contains devices that do not have "Uusi kohdetunnus" information set',
  'adminTools.metadataUpdateSuccess': 'Device metadata update was successful',
  'adminTools.targetpage': 'Item Page',
  'adminTools.phases': 'Phases',
  'adminTools.contracts': 'Contracts',
  'adminTools.approvalCycles': 'Work Flows',
  'adminTools.itemRegistry': 'Item Registry',
  'adminTools.areas': 'Areas',
  'adminTools.areaName': 'Area Name',
  'adminTools.areaDescription': 'Area Description',
  'adminTools.importArea': 'Import Areas',
  'adminTools.areaMFiles': 'M-Files sync',
  'adminTools.section_name': 'Area Seaction Name',
  'adminTools.roomRegistry': 'Rooms',
  'adminTools.addMissingRooms': 'Add Missing Rooms',
  'adminTools.noMissingRooms': 'No Missing Rooms',
  'adminTools.selectRoomDocument': 'Select Rooms from Excel',
  'adminTools.selectDocsToSystemsExcel':
    'Select Documents to Systems from Excel',
  'adminTools.misc': 'Misc',
  'adminTools.addedTargets': 'Already added items',
  'adminTools.alreadyAddedRooms': 'Rooms which have been added earlier',
  'adminTools.roomsWithoutArea': 'Rooms without Area',
  'adminTools.addAreasToRooms': 'Add areas to rooms',
  'adminTools.nameChanged': 'Rooms with updated Name',
  'adminTools.changeRoomNames': 'Change Room name',
  'adminTools.addPermission': 'Add Permission',
  'adminTools.removePermissionVerifyTitle': 'Remove Permission',
  'adminTools.removePermissionVerify':
    'Do you want to remove permission from selected user?',
  'adminTools.removePermissionAccept': 'Remove permission',
  'adminTools.removePermissionCancel': 'Cancel',
  'adminTools.privilegeType': 'Privilege',
  'adminTools.serviceMessage': 'Maintenance Break',
  'adminTools.sentEmails': 'E-mails',
  'adminTools.sendPasswordResetLink': 'Send Password Link by E-mail',
  'adminTools.passwordLinkValidity': 'Valid',
  'adminTools.passwordResetLinkSent': 'Password Link Sent!',
  'adminTools.hours': 'hours',
  'adminTools.phaseContractBuilding': 'Link contract and locations',
  'adminTools.missingRooms': 'Following rooms do not exist',
  'adminTools.createType': 'Add new type',
  'adminTools.replaceType':
    'or replace type with existing one in import Excel-file',

  'adminTools.systems': 'Systems',
  'adminTools.newSystem': 'New system',
  'adminTools.saveNewParentSystem': 'Save supersystem',
  'adminTools.saveNewSystem': 'Save system',
  'adminTools.parentSystem': 'Supersystem',
  'adminTools.systemId': 'System Id',
  'adminTools.systemDescription': 'Name',
  'adminTools.newSystemSuccess': 'New system saved',

  'adminTools.newPhase': 'New phase',
  'adminTools.newContract': 'New contract',
  'adminTools.importWithSpreadsheet': 'Import with Excel',
  'adminTools.modify': 'Edit',
  'adminTools.phase': 'Phase',
  'adminTools.setPhaseId': 'Phase Code',
  'adminTools.setPhaseName': 'Phase name',
  'adminTools.newPhaseSuccess': 'New phase saved',
  'adminTools.contractName': 'Contract Code',
  'adminTools.setContractName': 'Contract Name',
  'adminTools.contractLongName': 'Contract Long Name',
  'adminTools.setContractLongName': 'Contract Long Name',
  'adminTools.newContractSuccess': 'New contract saved',
  'adminTools.workflows': 'Work Flows',
  'adminTools.importPitems': 'Import Items',
  'adminTools.updateDevices': 'Update Devices',
  'adminTools.newPitemsLegend': 'New Items',
  'adminTools.preservedItemsLegend': 'Old Items',
  'adminTools.newPitems': 'New Items',
  'adminTools.preservedItems': 'Old Items',
  'permissions.permission': 'Permission',
  'permissions.permissions': 'Permissions',
  'permissions.create': 'Create',
  'permissions.read': 'Read',
  'permissions.update': 'Update',
  'permissions.delete': 'Delete',
  'permissions.notRule': 'Restriction',
  'permissions.customRule': 'Specific permission',
  'permissions.categories': 'Categories',
  'permissions.documentStates': 'Document states',
  'permissions.pItemTypes': 'Items',
  'permissions.action': 'Functionality',
  'permissions.addPrivilege': 'Add Privilege',
  'usersTable.searchUser': 'Search User',
  'usersTable.labelRowsPerPage': 'Rows Per Page',
  'usersTable.showOnlyDbUsers': 'Only in the database',
  'contractor.workSecDocUploadInfo': 'Add documents here',
  'info.notMandatorySelect':
    'This selection is not compulsory for this document type. Click here to skip.',
  'please.give.code': 'Please fill in the field "Code"',
  'please.give.description': 'Please fill in the field "Description"',
  'please.give.pole': 'Please set pole',
  'please.give.startpole': 'Please set start pole',
  'please.give.endpole': 'Please set end pole',
  'add.target': 'Add item',
  'added.items': 'Added items',
  'pitem.already.exists': 'Code already exists!',
  'please.give.pitem.type': 'Set type',
  'confirm.delete': 'Are you sure you want to delete selected items?',
  'confirm.accept': 'Are you sure you want to accepts selected items?',
  'contractorDocumentPackage.replacesFile': 'New version for document',
  'general.gdpr': `${bv.gdprClause}`,
  'notifications.clearMessages': 'Clear messages',
  'replacementDocument.setById': 'Set replacement by id',
  'replacementDocument.setBySearch': 'Set replacement by search',
  'errorMessage.canNotAccessDocument': 'Can not access that document',
  'errorMessage.canNotReplaceByItself': 'Can not replace a document by itself',
  'errorMessage.canNotReplaceByRejectedDocument':
    'Can not replace with a rejected document',
  'errorMessage.invalidValue': 'Invalid value',
  'errorMessage.documentAlreadyReplaced':
    'Document has already been replaced by another document.',
  'errorMessage.searchTooLarge':
    'There are too many results. Limit your search.',
  'errorMessage.deleteInspectionAreaWithChildren':
    'Can not delete an inspection area if there are targets related to it',
  'errorMessage.deleteInspectionTargetWithChildren':
    'Can not delete an inspection target if there are documents related to it',
};

export type EN_KEY = typeof translations;
export type EN_KEYWORD = keyof EN_KEY;

export default translations;

export const languages = [
  {
    label: 'English',
    value: 'en',
  },
  {
    label: 'Finnish',
    value: 'fi',
  },
];
