import * as React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import MainLayout from '../../layout/MainLayout';
import * as documentState from '../../state/reducers/DocumentState';
import * as applicationState from '../../state/reducers/ApplicationState';
import * as Model from '../../../../shared/src/model';
import DocumentTable from '../Common/DocumentTable';
import './inspectionDocuments.scss';
import Loading from '../Common/Loading';
import FileInput from '../Common/FileInput';
import Button from '../Common/Button';
import TextField from '../Common/TextField';
import { MenuItem, LinearProgress } from '@material-ui/core';
import ReactSelect from 'react-select';
import Moment from 'react-moment';
import { push } from 'connected-react-router';
import Breadcrumbs from '../Common/Breadcrumbs';
import { Link } from 'react-router-dom';
import { errorMessage /* successMessage */ } from '../Common/Notifier';
import {
  Edit,
  Comment,
  Message,
  ReportProblem,
  PlayArrow,
  Person,
} from '@material-ui/icons';
import DocumentToolbar from '../Common/DocumentToolbar';
import { Item } from '../Common/AutosuggestChipSelection';
import * as _ from 'lodash';
import { APIService } from '../../services/api/src/frontend/api';
import axios from 'axios';
import { ValueType } from 'react-select/lib/types';

const API = APIService(axios);

interface ITargetProps extends documentState.IProps, applicationState.IProps {
  match: {
    params: {
      id: number;
    };
  };
}

type TargetProps = ITargetProps & InjectedIntlProps;

interface ITargetState {
  isFavorite: boolean;
  documents: Model.UIDocument[];
  newDocumentFiles: File[];
  selectedDocumentType: Item | null;
  isAddingComment: boolean;
  isChangingDocumentState: boolean;
  newComment: string;
  commentsExpanded: boolean;
  isEditing: boolean;
  selectedDocuments: Model.UIDocument[];
  selectedWorkflow: Item | undefined;
  documentName: string;
  documentDescription: string | undefined;
  selectedNewDocumentTypeId: number | undefined;
  newDocumentSubject: string;
  contractRoles?: Model.ContractRole;
}

class AbstractTarget extends React.Component<TargetProps, ITargetState> {
  constructor(props: TargetProps) {
    super(props);

    this.state = {
      isFavorite: false,
      documents: [],
      newDocumentFiles: [],
      selectedDocumentType: null,
      isAddingComment: false,
      isChangingDocumentState: false,
      newComment: '',
      commentsExpanded: false,
      isEditing: false,
      selectedDocuments: [],
      selectedWorkflow: undefined,
      documentName: '',
      documentDescription: undefined,
      selectedNewDocumentTypeId: undefined,
      newDocumentSubject: '',
    };
  }

  loadDocumentData() {
    const targetId = this.props.match.params.id;
    const searchParams: Model.SearchRequest = {
      parentDocumentIds: [targetId],
      cursor: {
        offset: 0,
        limit: 1000,
      },
    };
    this.props.documentSearch(searchParams);
    this.props.fetchDocumentHistory(targetId);
    this.props.fetchDocument(targetId);
    this.props.getMetadata();
    this.props.getDocumentComments(targetId);
  }

  componentDidMount() {
    this.loadDocumentData();
  }

  componentDidUpdate = async (prevProps: ITargetProps) => {
    if (
      this.props.activeDocument &&
      this.props.activeDocument !== prevProps.activeDocument
    ) {
      const contractRoles = await API.getUserRoles([
        this.props.activeDocument.aItems[0].id,
      ]);

      this.setState({
        documentName: this.props.activeDocument.name,
        contractRoles: contractRoles[0],
      });
    }

    const newCommentLoaded = this.props.docstatus.addDocumentComment.loaded;
    const stateChangeLoaded = this.props.docstatus.setDocumentState.loaded;

    // Uploading new document done
    if (
      prevProps.docstatus.uploadTargetAttachment.loading &&
      this.props.docstatus.uploadTargetAttachment.loaded
    ) {
      this.setState({ newDocumentSubject: '' });
      this.loadDocumentData();
    }

    if (this.state.isAddingComment || this.state.isChangingDocumentState) {
      if (newCommentLoaded || stateChangeLoaded) {
        this.setState({
          isAddingComment: false,
          isChangingDocumentState: false,
          newComment: '',
        });
        this.loadDocumentData();
      }
    }
  };

  checkForRole = (roleToBeChecked: string) => {
    const { contractRoles } = this.state;

    return (
      contractRoles &&
      contractRoles.roles.some((role) => role.role_name === roleToBeChecked)
    );
  };

  onToggleAddComment = () => {
    if (this.state.isAddingComment) {
      this.setState({ newComment: '', isChangingDocumentState: false });
    }
    this.setState({
      isAddingComment: !this.state.isAddingComment,
      isChangingDocumentState: false,
    });
  };

  onToggleChangeDocumentState = () => {
    if (this.state.isChangingDocumentState) {
      this.setState({ newComment: '' });
    }
    this.setState({
      isChangingDocumentState: !this.state.isChangingDocumentState,
      isAddingComment: false,
    });
  };

  onNewCommentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ newComment: e.currentTarget.value });
  };

  onWorkflowChange = (newFlow: Item) => {
    this.setState({ selectedWorkflow: newFlow });
  };

  onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ documentName: e.currentTarget.value });
  };

  onChangeDescription = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ documentDescription: e.currentTarget.value });
  };

  onChangeNewDocumentSubject = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ newDocumentSubject: e.currentTarget.value });
  };

  handleSave = () => {
    if (this.props.activeDocument) {
      if (this.state.isAddingComment) {
        this.props.addDocumentComment({
          document_id: this.props.activeDocument.id,
          comment: this.state.newComment,
          document_comment_category_id: 1,
        });
      }

      if (this.state.isChangingDocumentState) {
        this.props.addDocumentComment({
          document_id: this.props.activeDocument.id,
          comment: this.state.newComment,
          document_comment_category_id: 4,
        });
      }

      if (this.state.isEditing) {
        const workflowId = this.state.selectedWorkflow
          ? this.state.selectedWorkflow.value
          : this.props.activeDocument.workflowId;
        const document = _.merge({}, this.props.activeDocument, {
          workflowId,
          name: this.state.documentName || this.props.activeDocument.name,
          description:
            this.state.documentDescription ||
            this.props.activeDocument.description,
        });

        this.props.updateActiveDocument(document);
        this.props.saveActiveDocument();

        this.setState({
          isEditing: false,
        });
      }
    }
  };

  handleAddNewDocument = async () => {
    if (this.props.activeDocument && this.state.newDocumentFiles) {
      const documentType = this.state.selectedDocumentType
        ? this.state.selectedDocumentType.value
        : this.props.inspectionDocCategories[0].id;

      try {
        this.props.uploadTargetAttachment({
          files: this.state.newDocumentFiles,
          categoryId: documentType,
          subject: this.state.newDocumentSubject,
        });

        this.setState({
          selectedDocumentType: null,
          newDocumentFiles: [],
          newDocumentSubject: '',
        });
      } catch (e) {
        errorMessage(e);
      }
      // this.loadDocumentData();
    }
  };

  handleCancel = () => {
    this.setState({
      isAddingComment: false,
      isEditing: false,
      isChangingDocumentState: false,
      newComment: '',
    });
    // this.loadDocumentData();
  };

  onToggleFavorState = () => {
    this.setState({ isFavorite: !this.state.isFavorite });
  };

  onToggleEditing = () => {
    this.setState({ isEditing: !this.state.isEditing });
  };

  onChangeFile = (files: File[]) => {
    this.setState({
      newDocumentFiles: files,
    });
  };

  onChangeDocumentType = (value: any) => {
    this.setState({
      selectedDocumentType: value,
    });
  };

  renderDocumentTypes() {
    return this.props.pitemTypes.map((type) => (
      <MenuItem key={type.id} value={type.id}>
        {type.name}
      </MenuItem>
    ));
  }

  renderDocumentComments() {
    const t = (id: string) => this.props.intl.formatMessage({ id });
    const { loading, loaded } = this.props.docstatus.getDocumentComments;
    if (loading) return <Loading loading={true} />;
    if (
      loaded &&
      this.props.activeDocument &&
      this.props.activeDocument.comments
    ) {
      return this.props.activeDocument.comments.map((comment) => {
        const missingAttachments = comment.document_comment_category_id === 4;

        return (
          <div className="commentRow" key={comment.id}>
            <div
              className={`commentContent  ${
                missingAttachments ? 'missing' : ''
              }`}
            >
              <div className="icon">
                {missingAttachments ? <ReportProblem /> : <Message />}
              </div>
              <div className="comment">
                {missingAttachments &&
                  `${t('inspectionDocuments.hasMissingAttachments')}: `}
                {comment.comment}
              </div>
            </div>
            <div className="from">
              <Person />
              <div className="user">
                {comment.firstname} {comment.lastname}
              </div>

              <Moment
                className="date"
                date={comment.created_at}
                format="DD.MM.YYYY HH:mm"
              />
            </div>
          </div>
        );
      });
    }
    return <FormattedMessage id="inspectionDocuments.noComments" />;
  }

  renderActions() {
    return (
      <div className="actions">
        <Button className="cancelButton" onClick={this.handleCancel}>
          <FormattedMessage id="inspectionDocuments.cancel" />
        </Button>
        <Button className="saveButton" onClick={this.handleSave}>
          <FormattedMessage id="inspectionDocuments.save" />
        </Button>
      </div>
    );
  }

  renderNewDocumentComment() {
    const t = (id: string) => this.props.intl.formatMessage({ id });
    return (
      <div className="newComment">
        <TextField
          className="textField"
          multiline={true}
          value={this.state.newComment}
          onChange={this.onNewCommentChange}
          placeholder={
            this.state.isChangingDocumentState
              ? t('inspectionDocuments.reason')
              : t('inspectionDocuments.comment')
          }
          rows="3"
        />
        {this.renderActions()}
      </div>
    );
  }

  render() {
    const t = (id: string) => this.props.intl.formatMessage({ id });
    const { activeDocument } = this.props;
    const { selectedWorkflow } = this.state;
    const uploadingNewDoc = this.props.docstatus.uploadTargetAttachment.loading;

    const workflows = this.props.metadata
      ? this.props.metadata.workflows.filter(
          (wf) => wf.workflow_name!.substr(0, 2) === 'TA'
        )
      : [];

    const currentWorkflow = workflows
      .filter((workflow) =>
        activeDocument ? workflow.id === activeDocument.workflowId : false
      )
      .pop();

    const workflowNames = workflows.map((workflow) =>
      t(workflow.workflow_name!)
    );

    const categories = this.props.inspectionDocCategories;

    const isContractor = this.checkForRole('TA_URAKOITSIJA');
    const isDesigner = this.checkForRole('TA_SUUNNITTELIJA');
    const isInspector = this.checkForRole('TA_TARKASTAJA');
    const isAdmin = this.checkForRole('TA_ADMIN');

    const contractId =
      activeDocument &&
      activeDocument.aItems.length > 0 &&
      activeDocument.aItems[0].id;

    // At the time this code was written, 'parentIds' included two values: [targetAreaId, inspectionDocumentId].
    // When this code eventually breaks, check what the parentIds key contains these days
    const inspectionDocumentId =
      activeDocument &&
      activeDocument.parentIds &&
      activeDocument.parentIds.length > 0 &&
      activeDocument.parentIds
        .filter(
          (id) => id !== activeDocument.parentId && id !== activeDocument.id
        )
        .pop();

    return (
      <MainLayout>
        <Breadcrumbs>
          <Link
            to={
              contractId && inspectionDocumentId
                ? `/inspection-documents/contracts/${contractId}/${inspectionDocumentId}`
                : '/inspection-documents/'
            }
          >
            <FormattedMessage id="inspectionDocuments.inspectionDocuments" />
          </Link>
          <FormattedMessage id="inspectionDocuments.targets" />
          <Link
            to={`/inspection-documents/target/${activeDocument &&
              activeDocument.id}`}
          >
            {activeDocument && activeDocument.name}
          </Link>
        </Breadcrumbs>
        {activeDocument && (
          <div className="grid gridCols2">
            <div className="inspectionDocumentTarget component notice">
              <h2>{activeDocument.name}</h2>
              <div className="actions">
                {isAdmin && (
                  <>
                    <Button
                      variant="text"
                      disabled={
                        this.state.isAddingComment ||
                        this.state.isChangingDocumentState
                      }
                      color={this.state.isEditing ? 'secondary' : 'primary'}
                      onClick={this.onToggleEditing}
                    >
                      <Edit style={{ marginTop: '-3px', marginRight: '8px' }} />
                      <FormattedMessage id="buttons.edit" />
                    </Button>
                  </>
                )}
                {(isDesigner || isInspector || isAdmin) && (
                  <>
                    <Button
                      variant="text"
                      disabled={
                        this.state.isEditing ||
                        this.state.isChangingDocumentState
                      }
                      color={
                        this.state.isAddingComment ? 'secondary' : 'primary'
                      }
                      onClick={this.onToggleAddComment}
                    >
                      <Comment
                        style={{ marginTop: '-3px', marginRight: '8px' }}
                      />
                      <FormattedMessage id="buttons.addComment" />
                    </Button>
                    <Button
                      variant="text"
                      disabled={
                        this.state.isAddingComment || this.state.isEditing
                      }
                      color={
                        this.state.isChangingDocumentState
                          ? 'secondary'
                          : 'primary'
                      }
                      onClick={this.onToggleChangeDocumentState}
                    >
                      <ReportProblem
                        style={{ marginTop: '-3px', marginRight: '8px' }}
                      />
                      <FormattedMessage id="buttons.markAsIncomplete" />
                    </Button>
                  </>
                )}
              </div>

              <div className="content">
                {(this.state.isAddingComment ||
                  this.state.isChangingDocumentState) &&
                  this.renderNewDocumentComment()}
                <dl>
                  <dt>
                    <FormattedMessage id="inspectionDocuments.target" />
                  </dt>
                  <dd>
                    {this.state.isEditing ? (
                      <TextField
                        value={this.state.documentName}
                        onChange={this.onChangeName}
                      />
                    ) : (
                      activeDocument.name
                    )}
                  </dd>
                  <dt>
                    <FormattedMessage id="inspectionDocuments.contract" />
                  </dt>
                  <dd>{activeDocument.aItems.map((c) => c.name).join(', ')}</dd>
                  {/* <dt>
                    <FormattedMessage id="inspectionDocuments.designer" />
                  </dt>
                  <dd>{activeDocument.createdByUser}</dd>
                  <dt>
                    <FormattedMessage id="inspectionDocuments.supervisor" />
                  </dt>
                  <dd>-</dd> */}
                  <dt>
                    <FormattedMessage id="inspectionDocuments.inspectionType" />
                  </dt>
                  <dd>-</dd>
                  <dt>
                    <FormattedMessage id="inspectionDocuments.workflowStateName" />
                  </dt>
                  <dd>
                    {t(`documentMeta.${activeDocument.workflowStateName}`)}
                  </dd>
                  <dt>
                    <FormattedMessage id="inspectionDocuments.content" />
                  </dt>
                  <dd>
                    {this.state.isEditing ? (
                      <TextField
                        value={
                          this.state.documentDescription ||
                          activeDocument.description
                        }
                        onChange={this.onChangeDescription}
                      />
                    ) : (
                      activeDocument.description || '-'
                    )}
                  </dd>
                  <dt>
                    <FormattedMessage id="inspectionDocuments.created" />
                  </dt>
                  <dd>
                    <Moment
                      className="date"
                      date={activeDocument.created}
                      format="DD.MM.YYYY HH:mm"
                    />
                  </dd>
                  <dt>
                    <FormattedMessage id="inspectionDocuments.modified" />
                  </dt>
                  <dd>
                    <Moment
                      className="date"
                      date={activeDocument.modified}
                      format="DD.MM.YYYY HH:mm"
                    />
                  </dd>
                  <dt>
                    <FormattedMessage id="inspectionDocuments.workflow" />
                  </dt>
                  <dd>
                    {this.state.isEditing ? (
                      <ReactSelect
                        isMulti={false}
                        className="selectWorkflow"
                        placeholder={t('inspectionDocuments.workflow')}
                        options={workflows.map((workflow, index) => ({
                          value: workflow.id,
                          label: workflowNames[index],
                        }))}
                        value={
                          selectedWorkflow
                            ? selectedWorkflow
                            : {
                                label: currentWorkflow
                                  ? t(currentWorkflow.workflow_name!)
                                  : '',
                                value: currentWorkflow ? currentWorkflow.id : 0,
                              }
                        }
                        onChange={(item: ValueType<Item>) =>
                          this.onWorkflowChange(item as Item)
                        }
                        menuPortalTarget={document.body}
                      />
                    ) : (
                      currentWorkflow && t(currentWorkflow.workflow_name!)
                    )}
                  </dd>
                </dl>
                {this.state.isEditing && this.renderActions()}
              </div>
            </div>
            {/*
            <div className="inspectionDocumentTarget component inspectors">
              <h2>
                <FormattedMessage id="inspectionDocuments.inspectors" />
              </h2>
              <div className="content">Tarkastajat tähän</div>
            </div>
            */}
            <div className="inspectionDocumentTarget component comments">
              <h2
                onClick={() => {
                  this.setState({
                    commentsExpanded: !this.state.commentsExpanded,
                  });
                }}
                className={`headerToggleable ${
                  this.state.commentsExpanded ? 'open' : ''
                }`}
              >
                <div className="header">
                  <FormattedMessage id="inspectionDocuments.comments" />
                </div>

                <div className="filler" />

                <div className="headerBarActions">
                  <PlayArrow />
                </div>
              </h2>

              <div
                className={`content comments ${
                  this.state.commentsExpanded ? 'expand' : ''
                }`}
              >
                {this.renderDocumentComments()}
              </div>
            </div>
            <div className="inspectionDocumentTarget component documents">
              <h2>
                <FormattedMessage id="headers.documents" />
              </h2>
              <div className="content">
                {this.props.searchResults && (
                  <DocumentTable
                    hasSelect={true}
                    keys={{
                      name: t('inspectionDocuments.document'),
                      type: t('documentMeta.pitemType'),
                      description: t('documentMeta.description'),
                      workflowStateName: t(
                        'inspectionDocuments.workflowStateName'
                      ),
                      modified: t('inspectionDocuments.modified'),
                    }}
                    loading={this.props.docstatus.searchRequest.loading}
                    rows={this.props.searchResults.map((row) => {
                      const { modified, categoryNames, ...rest } = row;
                      const types = workflows.filter(
                        (wf) =>
                          categoryNames &&
                          categoryNames.indexOf(wf.workflow_name!) > -1
                      );
                      const type =
                        types.length > 0
                          ? types[0].description!
                          : categoryNames && categoryNames.length > 0
                          ? t(categoryNames[categoryNames.length - 1])
                          : '';
                      return {
                        ...rest,
                        type,
                        modified: (
                          <Moment
                            className="date"
                            date={modified}
                            format="DD.MM.YYYY HH:mm"
                          />
                        ),
                      };
                    })}
                    actionComponents={[
                      <DocumentToolbar
                        key={'inspection-toolbar'}
                        selectedDocuments={this.state.selectedDocuments}
                        didUpdateDocuments={() => {
                          this.loadDocumentData();
                        }}
                      />,
                    ]}
                    setSelected={(ids) => {
                      this.setState({
                        selectedDocuments: this.props.searchResults
                          ? this.props.searchResults.filter((doc) => {
                              return ids.indexOf(doc.id) >= 0;
                            })
                          : [],
                      });
                    }}
                    rowClick={(doc) => {
                      const selectedDoc = (
                        this.props.searchResults || []
                      ).filter((row) => row.id === doc.id);
                      if (selectedDoc.length === 0) {
                        return;
                      }
                      this.props.DocumentStateDispatcher(
                        push(
                          `/inspection-documents/target/${
                            this.props.activeDocument
                              ? this.props.activeDocument.id
                              : 0
                          }/attachment/${selectedDoc[0].id}`
                        )
                      );
                    }}
                  />
                )}
              </div>
            </div>
            {(isContractor || isAdmin || isInspector) && (
              <div className="inspectionDocumentTarget component addDocuments">
                <h2>
                  <FormattedMessage id="inspectionDocuments.addDocuments" />
                </h2>
                {uploadingNewDoc && <LinearProgress />}

                <div className="content">
                  <div className="fileInput">
                    <FileInput
                      style={{ height: 200 }}
                      id="target_document_upload"
                      label={t('inspectionDocuments.addNewAttachment')}
                      files={this.state.newDocumentFiles}
                      onChange={this.onChangeFile}
                    />
                  </div>
                  <div className="selections">
                    <ReactSelect
                      isMulti={false}
                      className="selectDocumentType"
                      placeholder={t(
                        'documentMeta.inspectionDocumentAttachmentDocumentType'
                      )}
                      options={
                        categories &&
                        categories.map((type) => {
                          return { label: t(type.name), value: type.id };
                        })
                      }
                      value={this.state.selectedDocumentType}
                      onChange={(item: ValueType<Item>) => {
                        if (item && !Array.isArray(item) && item) {
                          this.onChangeDocumentType(item);
                        }
                      }}
                      menuPortalTarget={document.body}
                    />

                    <TextField
                      className="selectDocumentName"
                      label={t('documentMeta.description')}
                      value={this.state.newDocumentSubject}
                      onChange={this.onChangeNewDocumentSubject}
                    />
                  </div>
                  <div className="actions">
                    {!uploadingNewDoc && (
                      <Button
                        disabled={
                          !this.state.selectedDocumentType ||
                          this.state.newDocumentFiles.length === 0
                        }
                        onClick={this.handleAddNewDocument}
                      >
                        <FormattedMessage id={'inspectionDocuments.save'} />
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </MainLayout>
    );
  }
}

const Target = documentState.StateConnector(
  applicationState.StateConnector(AbstractTarget)
);

export default injectIntl(Target);
