import React, { useState } from 'react';
import { AreaExcelImport } from './AreaExcelImport';
import {
  AreaListing,
  AreaSectionListing,
} from '../../../../../shared/src/model/index';
import Button from 'components/Common/Button';
import { APIService } from '../../../services/api/src/frontend/api';
import axios from 'axios';
const API = APIService(axios);
interface AreaImportProps {
  //
}

export function AreaImport(props: AreaImportProps) {
  const [itemData, setItemData] = useState<AreaListing | undefined>(undefined);

  const refreshData = async inputData => {
    setItemData(inputData);
  };

  const saveAreas = async () => {
    if (itemData) {
      await API.addAreas(itemData);
      setItemData(undefined);
    }
  };

  const renderPosition = (item: AreaSectionListing) => {
    if (item.pole_start_pos && item.pole_end_pos) {
      return (
        <div
          style={{
            border: '1px solid black',
            marginLeft: '20px',
            display: 'inline-block',
          }}
        >
          {item.pole_start_pos} - {item.pole_end_pos}
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <div>
      <AreaExcelImport onData={refreshData} />
      <div className="grid gridCols1">
        <div className="content">
          {itemData && itemData.rows && (
            <Button onClick={() => saveAreas()}>Tallenna</Button>
          )}

          {itemData &&
            itemData.rows &&
            itemData.rows.map((row, idx: number) => (
              <ul key={idx}>
                <li>
                  Asema: {row.name}
                  <ul>
                    {row.sections &&
                      row.sections.map((section, idx) => (
                        <div style={{ marginLeft: '10px' }} key={idx}>
                          Osa: {section.name}
                          {renderPosition(section)}
                        </div>
                      ))}
                  </ul>
                </li>
              </ul>
            ))}
        </div>
      </div>
    </div>
  );
}
