import BackIcon from '@material-ui/icons/ArrowBackIosRounded';
import ForwardIcon from '@material-ui/icons/ArrowForwardIosRounded';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Document, Page, pdfjs } from 'react-pdf';
import Button from '../Button';
import Loading from '../Loading';
import './Preview.scss';
import * as Model from '../../../../../shared/src/model';
import CloudDownload from '@material-ui/icons/CloudDownload';
// This file needs to be copied from node_modules/pdfjs-dist/build
// https://github.com/wojtekmaj/react-pdf#create-react-app
pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';

interface PdfProps {
  doc: Model.UIDocument;
}

interface PdfState {
  page: number;
  pages: number;
  loaded: boolean;
}

/**
 *
 * react-pdf and pdfjs don't have any proper up to date typings available.
 *
 * Currently importing pdfjs causes these warnings:
 * Critical dependency: require function is used in a way in which dependencies cannot be statically extracted
 *
 * Also navigating pages causes warning:
 * Deprecated API usage: RenderTask.then method, use the `promise` getter instead.
 *
 * Nothing we can do about them except wait for these libraries to update and not cause those warnings anymore.
 */

export default class Pdf extends React.PureComponent<PdfProps, PdfState> {
  state = {
    page: 1,
    pages: 1,
    loaded: false,
  };

  constructor(props: any) {
    super(props);
  }

  // Reset values when user changes the previewed document
  componentDidUpdate(prevProps: PdfProps) {
    if (this.props.doc.downloadUrl !== prevProps.doc.downloadUrl) {
      this.setState({
        page: 1,
        pages: 1,
      });
    }
  }

  onDocumentLoadSuccess = (info: any) => {
    this.setState({ pages: info.numPages, loaded: true });
  };

  prevPage() {
    this.setState(state => {
      return { page: state.page - 1 };
    });
  }

  nextPage() {
    this.setState(state => {
      return { page: state.page + 1 };
    });
  }
  downloadDocument = (activeDocument: Model.UIDocument) => {
    if (document && document.location) {
      document.location.href = `/docs/v1${activeDocument.downloadUrl}`;
    }
  };
  renderToolbar() {
    const { page, pages } = this.state;
    return (
      <div className="pdfToolbar">
        <Button
          disabled={page === 1}
          color="secondary"
          variant="text"
          onClick={() => this.prevPage()}
        >
          <BackIcon style={{ marginTop: '-3px', marginRight: '8px' }} />
          <FormattedMessage id="documentPreview.page.prev" />
        </Button>
        <p
          style={{
            paddingBottom: '4px',
            marginLeft: '20px',
            marginRight: '20px',
          }}
        >
          <FormattedMessage id="documentPreview.page" /> {page}/{pages}
        </p>
        <Button
          disabled={page === pages}
          color="secondary"
          variant="text"
          onClick={() => this.nextPage()}
        >
          <FormattedMessage id="documentPreview.page.next" />
          <ForwardIcon style={{ marginTop: '-3px', marginRight: '8px' }} />
        </Button>
      </div>
    );
  }

  public render() {
    const { loaded } = this.state;

    const doc = this.props.doc;
    if (!doc) {
      return (
        <div
          className="documentPreview component"
          style={{ position: 'relative' }}
        />
      );
    }

    const isOfTypePDf = doc.fileExtension.toLocaleLowerCase().indexOf('pdf') >= 0;

    return (
      <div
        className="documentPreview component"
        style={{ position: 'relative' }}
      >
        <h2>
          <FormattedMessage id="documentPreview.preview" />
        </h2>
        {!isOfTypePDf ? (
          <div className="previewNotAvailable">
            <p>
              <FormattedMessage id="documentPreview.previewNotAvailable" />
            </p>
            <Button
              variant="text"
              color="primary"
              onClick={() => {
                this.downloadDocument(doc);
              }}
            >
              <CloudDownload
                style={{ marginTop: '-3px', marginRight: '8px' }}
              />

              <FormattedMessage id="documentMeta.downloadLink" />
              <i>
                {doc.fileSize
                  ? ' (' + Math.floor(doc.fileSize / 1024) + 'kb)'
                  : ''}
              </i>
            </Button>
          </div>
        ) : null}
        {doc.downloadUrl && isOfTypePDf ? (
          <div className="pdfViewer">
            {loaded && this.renderToolbar()}
            <div className="documentPreviewContainer">
              <Document
                file={`/docs/v1${doc.downloadUrl}`}
                onLoadSuccess={this.onDocumentLoadSuccess}
                loading={<Loading loading={true} />}
                error={<FormattedMessage id="documentPreview.error" />}
                noData={<FormattedMessage id="documentPreview.noData" />}
              >
                <Page
                  loading={<Loading loading={true} />}
                  pageNumber={this.state.page}
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                  error={<FormattedMessage id="documentPreview.page.error" />}
                  noData={<FormattedMessage id="documentPreview.page.noData" />}
                />
              </Document>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
