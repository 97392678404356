// import { TextField } from '@material-ui/core/';
import * as _ from 'lodash';
import * as React from 'react';
// import { useEffect, useState } from 'react';
// import { ValidatorForm } from 'react-material-ui-form-validator';
// import ReactSelect from 'react-select';
import {
  useAdminContext,
  useAppContext,
  useDocContext,
} from 'utils/AppContextProvider';
// import { Item } from '../../../../shared/src/model';
// import * as schema from '../../../../shared/src/model/projectSchema';
import { useUnsafeFormatMessage } from '../../utils/translateHook';
import AdminTools from '../AdminTools';
// import Button from '../Common/Button';
import {
  Paper,
  Button,
  FormControl,
  TableCell,
  Typography,
} from '@material-ui/core';

import { DataTable } from 'components/Common/DataTable';

import { errorMessage, successMessage } from 'components/Common/Notifier';
import * as Model from '../../../../shared/src/model';
import { TextField } from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import AddBoxIcon from '@material-ui/icons/AddBox';
// Button, MenuItem, Paper, Select,
interface Props {}
const CategoryEditor = (props: { title: string }) => {
  // TODO: filtteröi parent category ID:n mukaisesti

  const appCtx = useAppContext();
  const adminCtx = useAdminContext();
  const docCtx = useDocContext();
  const [bIsAdding, setIsAdding] = React.useState(false);
  const [newCatData, setNewCat] = React.useState<
    Partial<Model.DocumentCategory>
  >({});
  const t = useUnsafeFormatMessage();
  React.useEffect(() => {
    adminCtx.getMetadata();
  }, []);

  // Note: "Rakennustuotteet" main category is the default uppper category
  const [upperCat] = React.useMemo(
    () => appCtx.pitemTypes.filter((cat) => cat.name === 'Rakennustuotteet'),
    [appCtx.pitemTypes]
  );

  const categoryList = React.useMemo(
    () => appCtx.pitemTypes.filter((cat) => cat.upper_type_id === upperCat.id),
    [appCtx.pitemTypes]
  );

  const [list, setList] = React.useState(categoryList);
  React.useEffect(() => {
    setList(categoryList);
  }, [categoryList]);

  if (!adminCtx.adminSearch.metadata) {
    return <div />;
  }
  return (
    <Paper style={{ margin: '1m', padding: '1em' }}>
      <Button
        onClick={() => {
          setNewCat({ parentId: upperCat.id });
          setIsAdding(!bIsAdding);
        }}
      >
        <AddBoxIcon /> {t('buttons.add')}
      </Button>

      {bIsAdding && (
        <Paper style={{ padding: '1em', margin: '1em' }}>
          <form>
            <div>
              <Typography variant="h6" component="h6">
                {t('categories.crerateNewCategory')} {upperCat.name}
              </Typography>
            </div>
            <div>
              <FormControl>
                <TextField
                  label={t('adminTools.name')}
                  value={newCatData.name || ''}
                  onChange={(e) => {
                    setNewCat({ ...newCatData, name: e.target.value });
                  }}
                />
              </FormControl>
              <div>
                <div>
                  {newCatData.name &&
                    appCtx.appDocumentCategories.filter(
                      (c) => c.name === newCatData.name
                    ).length === 0 && (
                      <Button
                        onClick={async () => {
                          if (!bIsAdding) {
                            return;
                          }
                          setIsAdding(false);
                          try {
                            await docCtx.addOrUpdatePItemType(newCatData);
                            await appCtx.getDocumentCategories();
                          } catch (e) {
                            errorMessage(`Adding category failed`);
                          }
                        }}
                      >
                        {t('buttons.save')}
                      </Button>
                    )}
                </div>
              </div>
            </div>
          </form>
        </Paper>
      )}

      <DataTable
        rows={list}
        keys={['name']}
        rowPrefix={(row) => {
          return (
            <TableCell>
              <Button
                onClick={async () => {
                  try {
                    const cnt = await docCtx.getPitemCntForPitemType(row.id);
                    if (cnt > 0) {
                      errorMessage(
                        'Kohdetyyppiin kuuluu kohteita. Poista ensin kohteet.'
                      );
                      return;
                    }
                    await docCtx.removePitemType(row.id);
                    await appCtx.getDocumentCategories();
                    successMessage(`Removed ${row.name}`);
                  } catch (e) {
                    errorMessage('Could not remove the pitem type');
                  }
                }}
              >
                <DeleteIcon /> {t('buttons.delete')}
              </Button>
            </TableCell>
          );
        }}
        titles={{
          name: t('name'),
        }}
        render={{
          name: (row) => {
            return <span>{row.name}</span>;
          },
        }}
      />
    </Paper>
  );
};

export default function PitemTypes(props: Props) {
  return (
    <AdminTools>
      <Paper square={true}>
        <div>
          <CategoryEditor title="Kohdetyypit" />
        </div>
      </Paper>
    </AdminTools>
  );
}
