import * as React from 'react';
// import { AreaExcelImport } from './AreaExcelImport';
// import { ItemListing } from '../../../../../shared/src/model/index';
// import Button from 'components/Common/Button';
// import { APIService } from '../../../services/api/src/frontend/api';
// import axios from 'axios';
// import { DocumentService } from '../../../services/document/src/frontend/api';
// import { Async } from 'components/Common/Async';

// const DOC = DocumentService(axios);

// import * as _ from 'lodash';
// import { useAppContext } from 'utils/AppContextProvider';
// const API = APIService(axios);
interface Props {
  //
}

export function AreaMFiles(props: Props) {
  // const ctx = React.useContext();
  // // const appCtx = useAppContext();
  // const [excelData, setExcelData] = React.useState<ItemListing | undefined>(
  //   undefined
  // );

  // const [missingPitemType, setMissingPitemType] = React.useState<
  //   string | undefined
  // >('');

  return (
    <div>
      <div className="grid gridCols1">
        {/*
        <Async
         placeholder={'Ladatan'}
         loader={() => await DOC.updateMFilesSyncDataForLocalDB}
        //  loader={() => await DOC.updateMFilesSyncDataForLocalDB}
         /> */}
      </div>
    </div>
  );
}
