import '../index.scss';

import * as React from 'react';
import * as _ from 'lodash';

import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import Breadcrumbs from '../../Common/Breadcrumbs';
import PropertyTable, {
  PropertyTableRenderers,
} from 'properties/PropertyTable';
import MainLayout from '../../../layout/MainLayout';
import { useUnsafeFormatMessage } from 'utils/translateHook';
import { PropertyProps, SubPropertyProps, Property } from 'properties';
import {
  defaultValue,
  getIdFromProps,
  getClassFromProps,
  getCollectionParamsKeys,
} from 'properties/utils';
import { Button, MenuItem, Paper, Select, TableCell } from '@material-ui/core';
import { PropertyCollection } from '../../../../../shared/src/model';
import RefProperty from 'properties/ref/RefProperty';
import { ClassViewParams } from 'properties/ClassViewParams';
import { ClassNames, CollectionFetchParams } from 'properties/batch';
import Checkbox from '@material-ui/core/Checkbox';
import { DocumentService } from '../../../services/document/src/frontend/api';
import axios from 'axios';
import { useImmer } from 'use-immer';
import { DraftArray } from 'immer';
// import { useHistory } from 'react-router-dom';
import DescriptionIcon from '@material-ui/icons/Description';
import ImmutableDataTable from 'properties/ImmutableDataTable';

const DOC = DocumentService(axios);

export const CustomNumberProperty = (props: SubPropertyProps<'number'>) => {
  const { data } = props;
  return (
    <b style={{ textShadow: '1px 1px 2px #58a8f8' }}>
      {data.value ? data.value : defaultValue(data)}
    </b>
  );
};

const CustomProperty: React.FC<PropertyProps<any>> = (props) => {
  if (!props.data) return null;
  const subProps: SubPropertyProps<any> = { ...props, data: props.data! };

  switch (props.data.typedef) {
    case 'number':
      return <CustomNumberProperty {...subProps} />;
    case 'refs':
      return <RefProperty {...subProps} />;
    default:
      return <Property {...props} />;
  }
};

const ResultTableContext = React.createContext<{
  modifyData: (
    f: (draft: {
      selectedRows: DraftArray<number[]>;
    }) => void | {
      selectedRows: number[];
    }
  ) => void;
  data: { selectedRows: number[] };
  ids: number[];
}>({
  modifyData: () => undefined,
  data: { selectedRows: [] },
  ids: [],
});

const CheckBoxTitle: React.FC<{
  titleData: any;
}> = () => {
  const ctx = React.useContext(ResultTableContext);
  return (
    <TableCell key="selectionBox">
      <div style={{ padding: '0px 20px' }}>
        <Checkbox
          onClick={() => {
            ctx.modifyData((draft) => {
              if (draft.selectedRows.length === ctx.ids.length)
                draft.selectedRows = [];
              else draft.selectedRows = ctx.ids;
            });
          }}
          style={{ cursor: 'pointer' }}
          checked={ctx.data.selectedRows.length === ctx.ids.length}
        />
      </div>
    </TableCell>
  );
};

const LinkTitle: React.FC<{
  titleData: any;
}> = () => {
  return <TableCell key="title"></TableCell>;
};

const CheckBoxCell: React.FC<{
  row: PropertyCollection<any>;
  customData: any;
}> = ({ row }) => {
  const ctx = React.useContext(ResultTableContext);
  const id = getIdFromProps(row);
  if (!id) return null;
  return (
    <div style={{ padding: '0px 20px' }}>
      <Checkbox
        key={`selectionBox.${id}`}
        onClick={(e) => {
          e.stopPropagation();
          ctx.modifyData((draft) => {
            const sel = draft.selectedRows;
            if (sel.includes(id)) sel.splice(sel.indexOf(id), 1);
            else sel.push(id);
          });
        }}
        checked={ctx.data.selectedRows.includes(id)}
      />
    </div>
  );
};

const RowLink: React.FC<{
  row: PropertyCollection<any>;
  customData: any;
}> = ({ row }) => {
  // const history = useHistory();
  const id = getIdFromProps(row);
  if (!id) return null;
  return (
    <Link to={`/demo/${getClassFromProps(row)}/${id}`}>
      <DescriptionIcon />
    </Link>
  );
};

interface IPitemProps {
  match: {
    params: {
      classType?: ClassNames;
    };
  };
  history: any;
}

const SearchResultsDemo: React.FC<IPitemProps> = ({
  match: {
    params: { classType },
  },
  history,
}) => {
  const className = classType as ClassNames;
  const t = useUnsafeFormatMessage();
  const [ids, setIds] = React.useState<number[]>([]);
  const [data, modifyData] = useImmer<{
    selectedRows: number[];
  }>({ selectedRows: [] });

  React.useEffect(() => {
    (async () => {
      const rows = await DOC.getRows({
        tableName: className,
        afterId: 0,
        limit: 2000,
      });
      setIds(rows);
    })();
  }, [className]);

  const fields = (ClassViewParams[className] || [
    'display.name',
  ]) as CollectionFetchParams<ClassNames>;

  const rawRenderers: PropertyTableRenderers = getCollectionParamsKeys(
    fields
  ).map((field) => ({
    id: `${className}.${field}`,
    title: () => (
      <TableCell key={`${className}.${field}`}>
        {t(`generics.${className}.${field}`)}
      </TableCell>
    ),
    cell: ({ row }) => (
      <CustomProperty
        key={field + getIdFromProps(row)}
        data={row[field]}
        editMode={false}
        viewMode="default"
      />
    ),
  }));

  const renderers: PropertyTableRenderers = React.useMemo(
    () => [
      {
        id: 'link',
        title: LinkTitle,
        cell: RowLink,
      },
      {
        id: 'selectionBox',
        title: CheckBoxTitle,
        cell: CheckBoxCell,
      },
      ...rawRenderers,
    ],
    [className, fields]
  );

  return (
    <MainLayout>
      <Breadcrumbs>
        <Link to="/">
          <FormattedMessage id="headers.home" />
        </Link>
        <FormattedMessage id="search.resultsHeader" />
      </Breadcrumbs>

      <h1>
        <FormattedMessage id="search.resultsHeader" />
      </h1>
      <div className="grid gridCol1">
        <Paper>
          Immutable Table:
          <ImmutableDataTable
            onData={(newData, oldData) => {
              if (newData !== oldData) {
                console.log('... data is changed');
                newData.rows.forEach((r, i) => {
                  if (r !== oldData.rows[i])
                    console.log('Changed row', newData);
                });
              }
            }}
            rows={[
              { isSelected: false, id: 1, name: 'one', cnt: 0 },
              { isSelected: false, name: 'two', id: 2, cnt: 0 },
              { isSelected: false, name: 'three', id: 3, cnt: 0 },
            ]}
            renderers={[
              {
                id: 'selected',
                title: '',
                cell: (params) => (
                  <TableCell>
                    <Checkbox
                      onClick={(e) => {
                        params.modifyData((draft) => {
                          draft.rows[params.rowIndex].isSelected = !draft.rows[
                            params.rowIndex
                          ].isSelected;
                        });
                      }}
                      checked={params.data.rows[params.rowIndex].isSelected}
                    />
                  </TableCell>
                ),
              },
              {
                id: 'row',
                title: 'row',
                cell: (params) => <TableCell>{params.rowIndex}</TableCell>,
              },
              {
                id: 'name',
                title: 'Name',
                cell: (params) => (
                  <TableCell>
                    {params.data.rows[params.rowIndex].name}
                  </TableCell>
                ),
              },
              {
                id: 'clicks',
                title: 'Click Cnt',
                cell: (params) => (
                  <TableCell>{params.data.rows[params.rowIndex].cnt}</TableCell>
                ),
              },
              {
                id: 'addCnt',
                title: () => <span />,
                cell: (params) => (
                  <TableCell>
                    <Button
                      onClick={() => {
                        params.modifyData((draft) => {
                          draft.rows[params.rowIndex].cnt++;
                        });
                      }}
                    >
                      +
                    </Button>
                  </TableCell>
                ),
              },
            ]}
            settings={{}}
          />
        </Paper>
        <Paper style={{ padding: '1em' }}>
          <Select
            value={classType}
            onChange={(event) => {
              history.push(`/resultsdemo/${event.target.value}`);
            }}
          >
            {Object.keys(ClassViewParams)
              .sort()
              .map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
          </Select>
        </Paper>
        <ResultTableContext.Provider
          value={{
            data,
            modifyData,
            ids,
          }}
        >
          <PropertyTable
            className={className as ClassNames}
            ids={ids}
            fields={
              (ClassViewParams[className] || [
                'display.name',
              ]) as CollectionFetchParams<ClassNames>
            }
            renderers={renderers}
            empty={<FormattedMessage id="search.noSearchResults" />}
            // onClick={(row) => {
            //   history.push(`/demo/${classType}/${getIdFromProps(row)}`);
            // }}
            defaultRowsPerPage={40}
            rowsPerPageOptions={[40, 60, 80, 100]}
            rowStyles={(row: PropertyCollection<any>) => {
              const id = getIdFromProps(row);
              return id && data.selectedRows.includes(id)
                ? { background: '#d0d0d0' }
                : {};
            }}
          />
        </ResultTableContext.Provider>
      </div>
    </MainLayout>
  );
};

export default SearchResultsDemo;
