import { useContext } from 'react';
import { FormattedMessage, MessageValue } from 'react-intl';
import { IntlContext } from './intlContext';
import { FI_KEYWORD } from '../translations/fi';

export type FormatMessage = (
  id: FormattedMessage.MessageDescriptor['id'],
  values?: { [key: string]: MessageValue }
) => string;

export type FormatMessageFunction = (
  id: FI_KEYWORD,
  values?: { [key: string]: MessageValue }
) => string;

export type UnsafeFormatMessageFunction = (
  id: string,
  values?: { [key: string]: MessageValue }
) => string;

export const useFormatMessage = (): FormatMessageFunction => {
  const { formatMessage } = useContext(IntlContext);

  const formatMessageFn: FormatMessageFunction = (id: FI_KEYWORD, values) => {
    let txt = id as string;
    if (txt.substring(0, 3) === '###') {
      txt = txt.substring(3);
    }
    return formatMessage({ id: txt }, values);
  };
  return formatMessageFn;
};

export const useUnsafeFormatMessage = (): UnsafeFormatMessageFunction => {
  const { formatMessage } = useContext(IntlContext);

  const formatMessageFn: FormatMessageFunction = (id: string, values) => {
    let txt = id as string;
    if (txt.substring(0, 3) === '###') {
      txt = txt.substring(3);
    }
    return formatMessage({ id: txt }, values);
  };

  return formatMessageFn;
};
