import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import Login from '../../components/Login';
import GDPR from '../../components/GDPR';
import PasswordReset from '../../components/PasswordReset';
import Contracts from '../../components/Contracts';
import InspectionDocuments from '../../components/InspectionDocuments';
import InspectionDocumentTarget from '../../components/InspectionDocuments/target';
import InspectionDocumentsAdmin from '../../components/InspectionDocuments/admin';
import Document from '../../components/Document';
import Home from '../../components/Home';
import ManagementTools from '../../components/ManagementTools';
import SearchResults from '../../components/SearchResults';
import SearchResultsDemo from '../../components/SearchResults/demo';
import ClassDemo from '../Document/ClassDemo';
import Settings from '../../components/Settings';
import Upload from '../../components/Upload';
import DownloadBasket from '../DownloadBasket/DownloadBasket';
import PrintOrderCheckout from '../PrintOrder/PrintOrderCheckout';
import UploadDocuments from '../Upload/documents';
import DocumentCollection from '../../components/Upload/documentcollection';
import Doc from '../Document/Doc';
import Attachment from '../../components/InspectionDocuments/Attachment';
import PItem from '../Document/PItem';
import { ErrorBoundary } from '../Common/ErrorBoundary';
import AdminTools from '../../components/AdminTools';
import AdminUsers from '../../components/AdminTools/users';
import Systems from '../../components/AdminTools/systems';

import Categories from '../../components/AdminTools/categories';
import PItemTypes from '../../components/AdminTools/pitemtypes';
import Roles from '../../components/AdminTools/roles';
import { default as ManageContracts } from '../../components/AdminTools/contracts';
import ApprovalCycles from '../../components/AdminTools/approvalCycles';
import ServiceMessage from '../../components/AdminTools/serviceMessage';
import ItemRegistry from '../AdminTools/ItemImport/';
import Docs from '../AdminTools/docs/';
import Areas from '../AdminTools/areas/';
import Phases from '../AdminTools/phases';
import Misc from '../../components/AdminTools/misc';
import MailTasks from '../../components/AdminTools/mailTasks';
import MailDetails from '../../components/AdminTools/MailDetails';
import PrintOrders from '../../components/AdminTools/PrintOrders';
import Sync from '../../components/AdminTools/Sync';
import PrintDetails from '../../components/AdminTools/PrintDetails';
import { SearchPlayground } from 'components/Search/SearchPlayground';
import PItemDemo from '../Document/PItemDemo';
import ImmutableDemo from '../../components/SearchResults/demo/immutable';

interface IRouterProps {
  something?: string;
}

interface IRouterState {
  email: string;
  password: string;
}
class AbstractRouter extends React.Component<IRouterProps, IRouterState> {
  constructor(props: IRouterProps) {
    super(props);
  }

  public render() {
    return (
      <>
        <ErrorBoundary>
          <Switch>
            <Route path="/" exact={true} component={Home} />
            <Route path="/contracts" component={Contracts} />
            <Route
              path="/inspection-documents"
              exact={true}
              component={InspectionDocuments}
            />
            <Route
              path="/inspection-documents/contracts/:contract"
              exact={true}
              component={InspectionDocuments}
            />
            <Route
              path="/inspection-documents/contracts/:contract/:inspectionDocument"
              exact={true}
              component={InspectionDocuments}
            />
            <Route
              path="/inspection-documents/target/:id"
              exact={true}
              component={InspectionDocumentTarget}
            />
            <Route
              path="/inspection-documents/target/:targetId/attachment/:id"
              exact={true}
              component={Attachment}
            />
            <Route
              path="/inspection-documents/admin"
              exact={true}
              component={InspectionDocumentsAdmin}
            />

            <Route path="/settings" component={Settings} />
            <Route path="/login" component={Login} />
            <Route path="/search-playground" component={SearchPlayground} />
            <Route path="/gdpr" exact={true} component={GDPR} />
            <Route
              path="/password-reset"
              exact={true}
              component={PasswordReset}
            />
            <Route path="/password-reset/:token" component={PasswordReset} />
            <Route path="/results" component={SearchResults} />
            <Route
              path="/resultsdemo/:classType"
              component={SearchResultsDemo}
            />
            <Route path="/immutabledemo/" component={ImmutableDemo} />
            <Route
              path="/demo/:className/:id"
              exact={true}
              component={ClassDemo}
            />
            <Route
              path="/documents/:documentId/"
              exact={true}
              component={Doc}
            />
            <Route path="/doc/:documentId/" exact={true} component={Doc} />
            <Route path="/items/:itemId/" exact={true} component={PItem} />
            <Route
              path="/itemsgeneric/:itemId"
              exact={true}
              component={PItemDemo}
            />
            <Route path="/upload" component={Upload} exact={true} />
            <Route
              path="/upload/documents"
              exact={true}
              component={UploadDocuments}
            />
            <Route path="/downloads" component={DownloadBasket} />
            <Route path="/documentcollection" component={DocumentCollection} />
            <Route path="/printorder" component={PrintOrderCheckout} />
            <Route path="/documents/:documentId/:mode" component={Document} />

            <Route path="/admin-tools" exact={true} component={AdminTools} />
            <Route path="/admin-tools/systems" component={Systems} />
            <Route path="/admin-tools/categories/" component={Categories} />
            <Route path="/admin-tools/pitemtypes" component={PItemTypes} />

            <Route path="/admin-tools/users/:id" component={AdminUsers} />
            <Route path="/admin-tools/users" component={AdminUsers} />

            <Route path="/admin-tools/roles/:id" component={Roles} />
            <Route path="/admin-tools/roles" component={Roles} />

            <Route path="/admin-tools/contracts" component={ManageContracts} />
            <Route path="/admin-tools/phases" component={Phases} />
            <Route
              path="/admin-tools/approval-cycles/:id"
              component={ApprovalCycles}
            />
            <Route
              path="/admin-tools/service-message"
              component={ServiceMessage}
            />
            <Route
              path="/admin-tools/approval-cycles/"
              component={ApprovalCycles}
            />
            <Route path="/admin-tools/item-registry" component={ItemRegistry} />
            <Route path="/admin-tools/docs" component={Docs} />
            <Route path="/admin-tools/areas" component={Areas} />

            <Route path="/admin-tools/mail-tasks/:id" component={MailDetails} />
            <Route path="/admin-tools/mail-tasks" component={MailTasks} />

            <Route
              path="/admin-tools/print-orders/:id"
              component={PrintDetails}
            />
            <Route path="/admin-tools/print-orders" component={PrintOrders} />
            <Route path="/admin-tools/sync" component={Sync} />

            <Route path="/admin-tools/misc" component={Misc} />

            {/* Old admin view used while the application was on early stages of development */}
            <Route path="/managementTools" component={ManagementTools} />
          </Switch>
        </ErrorBoundary>
      </>
    );
  }
}

export default AbstractRouter;
