import * as React from 'react';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import ReactSelect from 'react-select';
import { Grid, Switch, FormControlLabel } from '@material-ui/core';
import * as schema from '../../../../shared/src/model/projectSchema';
import * as Model from '../../../../shared/src/model/index';
import Checkbox from '../Common/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '../Common/Button';
import { ValueType } from 'react-select/lib/types';

interface IPrivilegeSelectorProps extends InjectedIntlProps {
  actions?: schema.action[];
  metadata?: Model.UIDocMetadata;
  privileges?: schema.privilege[];
  onAddPrivilege: (privileges: Model.UIRolePrivilege[]) => void;
}

interface IPrivilegeSelectorState {
  isCreatingCustomRule: boolean;
  c: boolean;
  r: boolean;
  u: boolean;
  d: boolean;
  notRule: boolean;
  selectedCategories?: Model.Item;
  selectedWorkflowStates?: Model.Item;
  selectedActions?: Model.Item;
  selectedPrivilege?: Model.Item;
}

type PrivilegeSelectorProps = IPrivilegeSelectorProps;

const basicStyles = (theme: Theme) =>
  createStyles({
    root: {},
  });
class PrivilegeSelector extends React.Component<
  PrivilegeSelectorProps,
  IPrivilegeSelectorState
> {
  constructor(props: PrivilegeSelectorProps) {
    super(props);

    this.state = {
      isCreatingCustomRule: false,
      c: false,
      r: false,
      u: false,
      d: false,
      notRule: false,
    };
  }

  onSelectDocumentCategory = (value: Model.Item) => {
    this.setState({
      selectedCategories: value,
    });
  };

  onSelectWorkflowState = (value: Model.Item) => {
    this.setState({
      selectedWorkflowStates: value,
    });
  };

  onSelectAction = (value: Model.Item) => {
    this.setState({
      selectedActions: value,
    });
  };

  onSelectPrivilege = (value: Model.Item) => {
    this.setState({
      selectedPrivilege: value,
    });
  };

  getSpecifiers = () => {
    const specifiers: Array<{
      documentCategoryId?: number;
      documentStateId?: number;
    }> = [];
    if (this.state.selectedCategories) {
      specifiers.push({
        documentCategoryId: this.state.selectedCategories.value,
      });
    }

    if (this.state.selectedWorkflowStates) {
      specifiers.push({
        documentStateId: this.state.selectedWorkflowStates.value,
      });
    }

    return specifiers;
  };

  onSaveRule = () => {
    if (this.state.isCreatingCustomRule && this.state.selectedPrivilege) {
      this.props.onAddPrivilege([
        {
          c: !!this.state.c,
          r: !!this.state.r,
          u: !!this.state.u,
          d: !!this.state.d,
          notRule: this.state.notRule,
          actionId:
            this.state.selectedActions && this.state.selectedActions.value,
          specifiers: this.getSpecifiers(),
          privilegeId: this.state.selectedPrivilege.value,
        },
      ]);
    }

    // FIXME: Maybe something like this?
    // if (this.state.isCreatingCustomRule) {
    //   this.props.onAddPrivilege([
    //     {
    //       c: !!this.state.c,
    //       r: !!this.state.r,
    //       u: !!this.state.u,
    //       d: !!this.state.d,
    //       notRule: this.state.notRule,
    //       actionId:
    //         this.state.selectedActions && this.state.selectedActions.value,
    //       specifiers: this.getSpecifiers(),
    //     },
    //   ]);
    // } else {
    //   if (this.state.selectedPrivilege) {
    //     this.props.onAddPrivilege([
    //       {
    //         privilegeId: this.state.selectedPrivilege.value,
    //         specifiers: this.getSpecifiers(),
    //       },
    //     ]);
    //   }
    // }
  };

  render() {
    const t = (id: string) => this.props.intl.formatMessage({ id });
    const { metadata, actions, privileges } = this.props;

    return (
      <ExpansionPanel style={{ width: '100%', padding: '4px' }}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <FormattedMessage id={'permissions.addPrivilege'} />
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container={true} spacing={8}>
            <Grid item={true} xs={12}>
              {metadata && metadata.documentCategories && (
                <ReactSelect
                  className="dropdown"
                  menuPortalTarget={document.body}
                  isMulti={false}
                  placeholder={t('documentMeta.documentCategory')}
                  options={
                    metadata.documentCategories.map(category => ({
                      label: category.name,
                      value: category.id,
                    })) as Model.Item[]
                  }
                  value={this.state.selectedCategories}
                  onChange={(values: ValueType<Model.Item>) => {
                    if (!Array.isArray(values)) {
                      this.onSelectDocumentCategory(values as Model.Item);
                    }
                  }}
                />
              )}
            </Grid>
            <Grid item={true} xs={12}>
              {privileges && (
                <ReactSelect
                  // isDisabled={this.state.isCreatingCustomRule}
                  className="dropdown"
                  menuPortalTarget={document.body}
                  isMulti={false}
                  placeholder={t('permissions.action')}
                  options={
                    privileges.map(action => ({
                      label: action.description,
                      value: action.id,
                    })) as Model.Item[]
                  }
                  value={this.state.selectedPrivilege}
                  onChange={(values: ValueType<Model.Item>) => {
                    this.onSelectPrivilege(values as Model.Item);
                  }}
                />
              )}
            </Grid>
            {this.state.isCreatingCustomRule && (
              <>
                <Grid item={true} xs={12}>
                  {metadata && metadata.workflowStates && (
                    <ReactSelect
                      className="dropdown"
                      menuPortalTarget={document.body}
                      isMulti={false}
                      placeholder={t('documentMeta.workflowStateName')}
                      options={
                        metadata.workflowStates.map(state => ({
                          label: state.name,
                          value: state.id,
                        })) as Model.Item[]
                      }
                      value={this.state.selectedWorkflowStates}
                      onChange={(values: ValueType<Model.Item>) => {
                        this.onSelectWorkflowState(values as Model.Item);
                      }}
                    />
                  )}
                </Grid>
                <Grid item={true} xs={12}>
                  {actions && (
                    <ReactSelect
                      className="dropdown"
                      menuPortalTarget={document.body}
                      isMulti={false}
                      placeholder={t('permissions.action')}
                      options={actions.map(action => ({
                        label: action.action_name,
                        value: action.id,
                      }))}
                      value={this.state.selectedActions}
                      onChange={(values: ValueType<Model.Item>) => {
                        this.onSelectAction(values as Model.Item);
                      }}
                    />
                  )}
                </Grid>
                <Grid item={true} xs={12}>
                  <FormattedMessage id={'permissions.permissions'} />
                  <FormGroup row={true}>
                    <Checkbox
                      checked={this.state.c}
                      label={t('permissions.create')}
                      onChange={() => {
                        this.setState({ c: !this.state.c });
                      }}
                      control={<p />}
                    />
                    <Checkbox
                      checked={this.state.r}
                      label={t('permissions.read')}
                      onChange={() => {
                        this.setState({ r: !this.state.r });
                      }}
                      control={<p />}
                    />
                    <Checkbox
                      checked={this.state.u}
                      label={t('permissions.update')}
                      onChange={() => {
                        this.setState({ u: !this.state.u });
                      }}
                      control={<p />}
                    />
                    <Checkbox
                      checked={this.state.d}
                      label={t('permissions.delete')}
                      onChange={() => {
                        this.setState({ d: !this.state.d });
                      }}
                      control={<p />}
                    />
                    <Checkbox
                      checked={this.state.notRule}
                      label={t('permissions.notRule')}
                      onChange={() => {
                        this.setState({ notRule: !this.state.notRule });
                      }}
                      control={<p />}
                    />
                  </FormGroup>
                </Grid>
              </>
            )}

            <Grid item={true} xs={12}>
              <FormGroup row={true}>
                <FormControlLabel
                  label={<FormattedMessage id="permissions.customRule" />}
                  control={
                    <Switch
                      value={this.state.isCreatingCustomRule}
                      onChange={() => {
                        this.setState({
                          isCreatingCustomRule: !this.state
                            .isCreatingCustomRule,
                        });
                      }}
                    />
                  }
                />
              </FormGroup>
            </Grid>

            <Grid item={true} xs={12}>
              <Button onClick={this.onSaveRule}>
                <FormattedMessage id="buttons.add" />
              </Button>
            </Grid>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

export default withStyles(basicStyles)(injectIntl(PrivilegeSelector));
