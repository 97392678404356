// import { TextField } from '@material-ui/core/';
import * as _ from 'lodash';
import * as React from 'react';
// import { useEffect, useState } from 'react';
// import { ValidatorForm } from 'react-material-ui-form-validator';
// import ReactSelect from 'react-select';
import {
  useAdminContext,
  useAppContext,
  useDocContext,
} from 'utils/AppContextProvider';
// import { Item } from '../../../../shared/src/model';
// import * as schema from '../../../../shared/src/model/projectSchema';
import {
  useFormatMessage,
  useUnsafeFormatMessage,
} from '../../utils/translateHook';
import AdminTools from '../AdminTools';
// import Button from '../Common/Button';
import {
  Paper,
  Tab,
  Tabs,
  Button,
  FormControl,
  InputLabel,
  CircularProgress,
  TableCell,
  Switch,
  Typography,
} from '@material-ui/core';
import useRouter from 'utils/routerHook';
import { Route } from 'react-router';
import { DataTable } from 'components/Common/DataTable';
import { Select, MenuItem } from '@material-ui/core';
import { errorMessage, successMessage } from 'components/Common/Notifier';
import * as Model from '../../../../shared/src/model';
import { TextField } from '@material-ui/core';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import DeleteIcon from '@material-ui/icons/Delete';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { Link } from 'react-router-dom';
// Button, MenuItem, Paper, Select,
interface Props {}

const SavingButton = (props: {
  categoryList: Model.DocumentCategory[];
  row: Model.DocumentCategory;
  fn: () => Promise<any>;
}) => {
  const [saving, setSaving] = React.useState(false);
  const t = useFormatMessage();
  const row = props.row;
  return (
    <>
      <Button
        disabled={
          !props.categoryList
            .filter((c) => c.id === row.id && c.workflowId !== row.workflowId)
            .pop()
        }
        onClick={async () => {
          try {
            setSaving(true);
            await props.fn();
            setSaving(false);
          } catch (e) {
            errorMessage(e);
            setSaving(false);
          }
        }}
      >
        {saving ? <HourglassEmptyIcon /> : t('buttons.save')}
      </Button>{' '}
      {saving ? <CircularProgress size={20} color="secondary" /> : null}
    </>
  );
};

const CategoryEditor = (props: {
  title: string;
  upperCategoryName: string;
  childUrl?: string;
}) => {
  // TODO: filtteröi parent category ID:n mukaisesti

  const appCtx = useAppContext();
  const adminCtx = useAdminContext();
  const docCtx = useDocContext();
  const [bIsAdding, setIsAdding] = React.useState(false);
  const [newCatData, setNewCat] = React.useState<
    Partial<Model.DocumentCategory>
  >({});
  const t = useUnsafeFormatMessage();
  React.useEffect(() => {
    adminCtx.getMetadata();
  }, []);

  const [upperCat] = React.useMemo(
    () =>
      appCtx.appDocumentCategories.filter(
        (cat) => cat.name === props.upperCategoryName
      ),
    [appCtx.appDocumentCategories]
  );

  const categoryList = React.useMemo(
    () =>
      appCtx.appDocumentCategories.filter(
        (cat) => cat.parentName === props.upperCategoryName
      ),
    [appCtx.appDocumentCategories]
  );

  const [list, setList] = React.useState(categoryList);
  React.useEffect(() => {
    setList(categoryList);
  }, [categoryList]);

  if (!adminCtx.adminSearch.metadata) {
    return <div />;
  }
  return (
    <Paper style={{ margin: '1m', padding: '1em' }}>
      <Button
        onClick={() => {
          setNewCat({ parentId: upperCat.id });
          setIsAdding(!bIsAdding);
        }}
      >
        <AddBoxIcon /> {t('buttons.add')}
      </Button>

      {bIsAdding && (
        <Paper style={{ padding: '1em', margin: '1em' }}>
          <form>
            <div>
              <Typography variant="h6" component="h6">
                {t('categories.crerateNewCategory')} {upperCat.name}
              </Typography>
            </div>
            <div>
              <FormControl>
                <TextField
                  label={t('adminTools.name')}
                  value={newCatData.name || ''}
                  onChange={(e) => {
                    setNewCat({ ...newCatData, name: e.target.value });
                  }}
                />
              </FormControl>
              <div>
                <FormControl>
                  <InputLabel id="new-cat-label">
                    {t('categories.changeWorkflow')}
                  </InputLabel>
                  <Select
                    title={t('categories.changeWorkflow')}
                    id="new-cat-label"
                    value={newCatData.workflowId || 0}
                    onChange={(e) => {
                      // change the value Number(e.target.value)
                      setNewCat({
                        ...newCatData,
                        workflowId: Number(e.target.value),
                      });
                    }}
                  >
                    {adminCtx.adminSearch.metadata!.workflows.map((wf) => (
                      <MenuItem key={wf.id} value={wf.id}>
                        {t('wf.' + (wf.workflow_name || ''))}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div>
                  {newCatData.workflowId &&
                    newCatData.name &&
                    appCtx.appDocumentCategories.filter(
                      (c) => c.name === newCatData.name
                    ).length === 0 && (
                      <Button
                        onClick={async () => {
                          if (!bIsAdding) {
                            return;
                          }
                          setIsAdding(false);
                          try {
                            await docCtx.addOrUpdateCategory(newCatData);
                            await appCtx.getDocumentCategories();
                          } catch (e) {
                            errorMessage(`Adding category failed`);
                          }
                        }}
                      >
                        {t('buttons.save')}
                      </Button>
                    )}
                </div>
              </div>
            </div>
          </form>
        </Paper>
      )}

      <DataTable
        rows={list}
        keys={['name', 'parentName', 'workflowId', 'moveToEam']}
        rowPrefix={(row) => {
          return (
            <TableCell>
              <Button
                onClick={async () => {
                  try {
                    const cnt = await docCtx.getDocCnt(row.id);
                    if (cnt > 0) {
                      errorMessage(
                        'Can not remove category, because it has documents'
                      );
                      return;
                    }
                    await docCtx.removeDocumentCategory(row.id);
                    await appCtx.getDocumentCategories();
                    successMessage(`Removed ${row.name}`);
                  } catch (e) {
                    errorMessage('Could not remove the category');
                  }
                }}
              >
                <DeleteIcon /> {t('buttons.delete')}
              </Button>
            </TableCell>
          );
        }}
        titles={{
          moveToEam: t('adminTools.moveToEam'),
          parentName: t('parentName'),
          name: t('name'),
          workflowId: t('workflowId'),
        }}
        render={{
          parentName: (row) => <span>{t(row.parentName)}</span>,
          name: (row) => {
            if (props.childUrl) {
              return (
                <Link to={props.childUrl + '/' + row.name}>{row.name}</Link>
              );
            }
            return <span>{row.name}</span>;
          },
          workflowId: (row) => {
            return (
              <>
                <Select
                  value={row.workflowId}
                  onChange={(e) => {
                    // change the value Number(e.target.value)
                    setList(
                      list.map((item) => {
                        if (item.id === row.id) {
                          return {
                            ...row,
                            workflowId: Number(e.target.value),
                          };
                        }
                        return item;
                      })
                    );
                  }}
                >
                  {adminCtx.adminSearch.metadata!.workflows.map((wf) => (
                    <MenuItem key={wf.id} value={wf.id}>
                      {t('wf.' + (wf.workflow_name || ''))}
                    </MenuItem>
                  ))}
                </Select>
                <SavingButton
                  categoryList={categoryList}
                  row={row}
                  fn={async () => {
                    await docCtx.addOrUpdateCategory({
                      id: row.id,
                      workflowId: row.workflowId,
                    });
                    await appCtx.getDocumentCategories();
                  }}
                />
              </>
            );
          },
          moveToEam: (row) => {
            return (
              <Switch
                checked={row.moveToEam}
                onChange={async (e, checked) => {
                  await docCtx.addOrUpdateCategory({
                    id: row.id,
                    moveToEam: checked,
                  });
                  await appCtx.getDocumentCategories();
                }}
              />
            );
          },
        }}
      />
    </Paper>
  );
};

const WorkPhases = () => (
  <div>
    <CategoryEditor upperCategoryName="Työlaji" title="Työlajit" />
  </div>
);
const DocumentTypes = () => (
  <div>
    <CategoryEditor
      upperCategoryName="Dokumenttityyppi"
      title="Dokumenttityyppi"
    />
  </div>
);
const ContractorDocumentTypes = () => (
  <div>
    <CategoryEditor
      upperCategoryName="Dokumentin tyyppi"
      title="Urakoitsijan Dokumentin tyyppi"
      childUrl="/admin-tools/categories/contractordocumenttypes"
    />
  </div>
);

interface SubTypeProps {
  match: {
    params: {
      typeid: string;
    };
  };
}
const ContractorDocumentSubTypes = (props: SubTypeProps) => (
  <div>
    <CategoryEditor
      upperCategoryName={props.match.params.typeid}
      title={props.match.params.typeid}
    />
  </div>
);
const DesignDisciplines = () => (
  <div>
    <CategoryEditor upperCategoryName="Suunnitteluala" title="Suunnitteluala" />
  </div>
);

export default function Categories(props: Props) {
  // const appCtx = useAppContext();
  // const adminCtx = useAdminContext();
  const t = useFormatMessage();
  const router = useRouter();

  const currentTab =
    router.location.pathname === '/admin-tools/item-registry'
      ? false
      : router.location.pathname;
  const [activeTab, setActiveTab] = React.useState(currentTab);
  return (
    <AdminTools>
      <Paper square={true}>
        <Tabs
          indicatorColor="primary"
          value={activeTab}
          textColor="primary"
          onChange={(e, newValue) => {
            setActiveTab(newValue);
            router.history.push(newValue);
          }}
        >
          <Tab
            label={t('categories.documentCategories')}
            value="/admin-tools/categories/documentcategories"
          />
          <Tab
            label={t('categories.designDisciplines')}
            value="/admin-tools/categories/designdisciplines"
          />
          <Tab
            label={t('categories.workPhases')}
            value="/admin-tools/categories/workphases"
          />
          <Tab
            label={t('categories.contractorDocumentTypes')}
            value="/admin-tools/categories/contractordocumenttypes"
          />
        </Tabs>

        <Route
          path="/admin-tools/categories/documentcategories"
          component={DocumentTypes}
        />
        <Route
          path="/admin-tools/categories/designdisciplines"
          component={DesignDisciplines}
        />
        <Route
          path="/admin-tools/categories/workphases"
          component={WorkPhases}
        />
        <Route
          path="/admin-tools/categories/contractordocumenttypes"
          exact={true}
          component={ContractorDocumentTypes}
        />

        <Route
          path="/admin-tools/categories/contractordocumenttypes/:typeid"
          exact={true}
          component={ContractorDocumentSubTypes}
        />
      </Paper>
    </AdminTools>
  );
}
