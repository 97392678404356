import * as React from 'react';
import * as appState from '../../state/reducers/ApplicationState';
import * as documentState from '../../state/reducers/DocumentState';
import { FormattedMessage, injectIntl, InjectedIntlProps } from 'react-intl';

import * as Model from '../../../../shared/src/model/index';

import ReactTable, { CellInfo } from 'react-table';
import 'react-table/react-table.css';
import './DocumentListing.scss';
import { pitem } from '../../../../shared/src/model/projectSchema';
import Button from '../Common/Button';
import Warning from '@material-ui/icons/Warning';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Card, CardContent, CardHeader } from '@material-ui/core';

// import Loading from '../Common/Loading';
// import DocumentTable from '../Common/DocumentTable';

// import { Link } from 'react-router-dom';
// import Moment from 'react-moment';

export interface DocumentListingResponse {
  discipline?: Model.DocumentCategory;
  rows: Model.DocumentListingRow[];
  aItemName: string;
  buildings: pitem[];
}

interface IDocumentListingProps extends appState.IProps, documentState.IProps {
  docListing: Model.DocumentListing;
  matchedRows: Model.DocumentListingRow[];
  databaseFiles: Model.UIDocument[];
  buildings: pitem[];
  discipline?: Model.DocumentCategory;
  onSave?: (response: DocumentListingResponse) => void;
  onUpdateCategory?: (newCategoryId: number) => void;
  updateId: string;
  uploadReady?: boolean;
}

interface IDocumentListingState {
  discipline?: Model.DocumentCategory;
  matchedRows: Model.DocumentListingRow[];
  filesByName: { [key: string]: Model.UIDocument };
  isSaving: boolean;
}

type DocumentListingProps = IDocumentListingProps & InjectedIntlProps;

class AbstractDocumentListing extends React.Component<
  DocumentListingProps,
  IDocumentListingState
> {
  updateId: string = '';
  constructor(props: DocumentListingProps) {
    super(props);
    this.updateId = props.updateId;
    this.state = {
      isSaving: false,
      discipline: props.discipline,
      matchedRows: props.matchedRows,
      filesByName: props.databaseFiles.reduce((prev, curr) => {
        return {
          ...prev,
          [curr.fileName]: curr,
        };
      }, {}),
    };
    this.renderAccept = this.renderAccept.bind(this);
    this.renderEditableText = this.renderEditableText.bind(this);
    this.renderBuildingSelect = this.renderBuildingSelect.bind(this);
    this.renderDesingDisciplineSelect = this.renderDesingDisciplineSelect.bind(
      this
    );
  }

  componentDidUpdate() {
    if (this.updateId !== this.props.updateId) {
      this.updateId = this.props.updateId;
      this.setState({
        discipline: this.props.discipline,
        matchedRows: this.props.matchedRows,
        filesByName: this.props.databaseFiles.reduce((prev, curr) => {
          return {
            ...prev,
            [curr.fileName]: curr,
          };
        }, {}),
      });
    }
  }

  getUIDocKey(
    rowKey: keyof Model.DocumentListingRow,
    uiDoc: Model.UIDocument
  ): Model.UIDocument[keyof Model.UIDocument] | undefined {
    switch (rowKey) {
      case 'targetId':
        return uiDoc.pItems.map(item => item.name).join('');
      case 'content':
        return uiDoc.description;
      case 'dwgFileName':
        return uiDoc.dwgName;
      case 'endPole':
        return uiDoc.endPole;
      case 'startPole':
        return uiDoc.startPole;
      case 'fileName':
        return uiDoc.fileName;
      case 'revision':
        return uiDoc.revision;
    }
    return undefined;
  }

  // https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/react-table/index.d.ts

  renderAccept(cellInfo: CellInfo) {
    return (
      <div>
        <Button>Hyväksy</Button>
      </div>
    );
  }

  renderDesingDisciplineSelect(cellInfo: CellInfo) {
    // export type StringKeys<T> = Extract<keyof T, string>;
    const cellId = cellInfo.column.id as Extract<
      keyof Model.DocumentListingRow,
      string
    >;
    //  const cellId = cellInfo.column.id as keyof Model.DocumentListingRow;
    const rowData = this.state.matchedRows[cellInfo.index];
    if (!rowData) {
      console.log('ERROR', this.state);
      console.log('Cell', cellInfo);
      return null;
    }
    // this.props.typeConversions
    // typeConversions
    return (
      <div>
        <select
          value={rowData.docSuunnitteluala}
          onChange={(event: React.FormEvent<HTMLSelectElement>) => {
            // I really hate "as any[]" as much as anyone, but I just did not get the
            // error message "string is not assignable to type undefined" away from the latest TypeScript
            const rows = this.state.matchedRows.slice() as any[];
            const str = event.currentTarget.value || '';
            rows[cellInfo.index][cellId] = str;
            this.setState({
              matchedRows: rows,
            });
          }}
        >
          {this.props.designDisciplines.map(item => {
            return (
              <option
                key={item.id}
                value={this.props.typeConversions[item.name]}
              >
                {this.props.typeConversions[item.name]}
              </option>
            );
          })}
        </select>
      </div>
    );
  }

  renderBuildingSelect(cellInfo: CellInfo) {
    const cellId = cellInfo.column.id as keyof Model.DocumentListingRow;
    const rowData = this.state.matchedRows[cellInfo.index];
    const uiDoc = rowData.fileName
      ? this.state.filesByName[rowData.fileName]
      : null;

    let difference = <div />;
    if (uiDoc) {
      const value = this.getUIDocKey(cellId, uiDoc);
      if (String(value) !== rowData[cellId]) {
        difference = (
          <div style={{ textDecoration: 'line-through', color: 'red' }}>
            {value}
          </div>
        );
      }
    }
    return (
      <div>
        {difference}
        <select
          value={rowData.targetId}
          onChange={(event: React.FormEvent<HTMLSelectElement>) => {
            const rows = this.state.matchedRows.slice() as any[];
            rows[cellInfo.index][cellId] = event.currentTarget.value;
            this.setState({
              matchedRows: rows,
            });
          }}
        >
          {' '}
          {this.props.buildings.map(item => {
            return (
              <option key={item.id} value={item.name}>
                {item.name}
              </option>
            );
          })}
        </select>
      </div>
    );
  }

  renderEditableText(cellInfo: CellInfo) {
    const cellId = cellInfo.column.id as keyof Model.DocumentListingRow;
    const rowData = this.state.matchedRows[cellInfo.index];
    const uiDoc = rowData.fileName
      ? this.state.filesByName[rowData.fileName]
      : null;
    let difference = <div />;
    if (uiDoc) {
      const value = this.getUIDocKey(cellId, uiDoc);
      if (String(value) !== rowData[cellId]) {
        difference = (
          <div
            style={{
              textDecoration: 'line-through',
              color: 'red',
              fontSize: '0.8em',
            }}
          >
            {value}
          </div>
        );
      }
    }
    const value = this.state.matchedRows[cellInfo.index][cellId];
    const valueStr = typeof value === 'undefined' ? '' : String(value);
    let type = 'text';
    let errorText = '';
    let isChecked;
    if (cellId === 'colorPrinting') {
      type = 'checkbox';
      isChecked = value ? true : false;
    }
    if (cellId === 'startPole' || cellId === 'endPole') {
      const n = parseInt(String(value), 10);
      if (isNaN(n) || !Number.isInteger(n)) {
        errorText = 'Ei numeroarvo';
      }
    }
    let head: any = null;
    if (cellId === 'content') {
      head = (
        <div>
          <b>{this.state.matchedRows[cellInfo.index]['fileName']}</b>
        </div>
      );
    }
    return (
      <div>
        {head}
        {difference}

        <input
          className="editableInput"
          style={{
            backgroundColor: '#fafafa',
            wordWrap: 'break-word',
            whiteSpace: 'pre-wrap',
            width: '100%',
          }}
          type={type}
          checked={isChecked}
          onChange={e => {
            const rows = this.state.matchedRows.slice() as any[];
            if (type === 'checkbox') {
              rows[cellInfo.index][cellId] = e.target.checked ? 'V' : '';
            }
            if (type === 'text') {
              rows[cellInfo.index][cellId] = e.target.value;
            }
            this.setState({
              matchedRows: rows,
            });
          }}
          value={valueStr}
        />
        {errorText ? (
          <div style={{ color: 'blue', fontSize: 10 }}>
            <Warning style={{ width: 10, height: 10 }} /> {errorText}
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }

  public render() {
    const discipline = this.state.discipline || this.props.discipline;
    if (!discipline) {
      return <div>Suunnitteluala puuttuu</div>;
    }
    return (
      <Card>
        <CardHeader title={this.props.docListing.targetContractTag} />
        <CardContent>
          <div style={{ marginBottom: '20px' }}>
            Ole hyvä ja tarkasta tiedot!
          </div>

          <Select
            value={discipline.id}
            onChange={e => {
              //
              const id = parseInt(e.target.value, 10);
              this.setState({
                discipline: this.props.docCategories
                  .filter(item => item.id === id)
                  .pop(),
              });
              // updateDocuments

              if (this.props.onUpdateCategory) {
                this.props.onUpdateCategory(id);
              }
            }}
            inputProps={{
              name: 'age',
              id: 'age-simple',
            }}
          >
            {this.props.docCategories.map(item => {
              return (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              );
            })}
          </Select>
          <div style={{ marginBottom: '10px', marginTop: '10px' }}>
            <Button
              color="primary"
              disabled={this.state.isSaving}
              onClick={() => {
                //
                this.setState({ isSaving: true });
                if (this.props.onSave) {
                  this.props.onSave({
                    rows: this.state.matchedRows,
                    aItemName: this.props.docListing.targetContractTag,
                    buildings: this.props.buildings,
                    discipline,
                  });
                }
              }}
            >
              {this.state.isSaving ? (
                this.props.uploadReady ? (
                  <FormattedMessage id="upload.ready" />
                ) : (
                  <FormattedMessage id="upload.waiting" />
                )
              ) : (
                <FormattedMessage id="upload.save" />
              )}
            </Button>
          </div>
          <div
            style={{
              overflow: 'scroll',
              minWidth: '700px',
              height: '100%',
            }}
          >
            <ReactTable
              defaultPageSize={Math.min(10, this.props.matchedRows.length)}
              data={this.props.matchedRows}
              columns={[
                {
                  accessor: 'docSuunnitteluala',
                  Header: 'Suunn.',
                  width: 100,
                  Cell: this.renderDesingDisciplineSelect,
                },
                {
                  accessor: 'targetId',
                  Header: 'Kohde',
                  Cell: this.renderBuildingSelect,
                  width: 100,
                },
                {
                  accessor: 'content',
                  Header: 'Tiedosto ja sisältö',
                  minWidth: 200,
                  Cell: this.renderEditableText,
                },
                {
                  accessor: 'startPole',
                  Header: '1. Paalu',
                  Cell: this.renderEditableText,
                  width: 100,
                },
                {
                  accessor: 'endPole',
                  Header: '2. Paalu',
                  Cell: this.renderEditableText,
                  width: 100,
                },
                {
                  accessor: 'revision',
                  Header: 'R.',
                  Cell: this.renderEditableText,
                  width: 40,
                },
                {
                  accessor: 'dwgFileName',
                  Header: 'DWG-tiedosto',
                  Cell: this.renderEditableText,
                },
                {
                  accessor: 'colorPrinting',
                  Header: 'Väri',
                  Cell: this.renderEditableText,
                  width: 70,
                },
              ]}
            />
          </div>
        </CardContent>
      </Card>
    );
  }
}

const DocumentListing = documentState.StateConnector(
  appState.StateConnector(AbstractDocumentListing)
);

export default injectIntl(DocumentListing);
