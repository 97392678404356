import * as React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import * as applicationState from '../../../state/reducers/ApplicationState';
import { setBackgroundImage } from '../../../utils/index';
import FileInput from '../../Common/FileInput';
import Loading from '../../Common/Loading';
import * as model from '../../../../../shared/src/model/index';
import axios from 'axios';
import { Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import { errorMessage } from '../../Common/Notifier';
// import { errorMessage, successMessage } from '../Common/Notifier';

// const API = APIService(axios);
// const DOC = DocumentService(axios);

interface IItemRegistryProps extends applicationState.IProps {}

interface IItemRegistryState {
  isUploading: boolean;
  latestUpload?: model.AdminDocumentUpload;
}

type aAprovalCyclesProps = IItemRegistryProps & InjectedIntlProps;

class AbstractItemRegistry extends React.Component<
  aAprovalCyclesProps,
  IItemRegistryState
> {
  constructor(props: aAprovalCyclesProps) {
    super(props);

    this.state = {
      isUploading: false,
    };
  }

  componentDidMount = async () => {
    setBackgroundImage();
  };

  async uploadNewDocuments(files: File[]) {
    if (!files) {
      return;
    }
    try {
      this.setState({ isUploading: true, latestUpload: { success: true } });
      const formData = new FormData();
      formData.append('file', files[0]);
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      this.setState({
        latestUpload: (await axios.post(
          '/docs/v1/upload/adminexcel',
          formData,
          config
        )).data,
        isUploading: false,
      });
    } catch (e) {
      errorMessage(e);
      this.setState({ isUploading: false });
    }

    // this.setState({ isUploading: true });
  }

  renderLatest() {
    const up = this.state.latestUpload;
    if (up && !this.state.isUploading) {
      if (up.success === false) {
        return (
          <div className="detail">
            <div className="component">
              <h2>Virhe</h2>
              <div className="content">
                Viimeisin lisäys epäonnistui, Excel mahdollisesti väärässä
                muodossa
              </div>
            </div>
          </div>
        );
      }
      if (up.phaseContractBuilding) {
        // ...
        return (
          <div className="detail">
            <div className="component">
              <h2>Vaihe, Urakka, Rakennusosa</h2>

              <div className="content">
                {Object.keys(up.phaseContractBuilding.phases).map(key => {
                  if (!up.phaseContractBuilding) {
                    return <div />;
                  }
                  const phase = up.phaseContractBuilding.phases[key];
                  return (
                    <div key={key}>
                      <h1>Vaihe {key}</h1>
                      <Table>
                        <TableBody>
                          {Object.keys(phase.contracts).map(c => {
                            const list = phase.contracts[c];
                            return (
                              <TableRow key={c}>
                                <TableCell>
                                  <b>{c}</b>
                                </TableCell>
                                {list.map(value => {
                                  return (
                                    <TableCell key={value}>{value}</TableCell>
                                  );
                                })}
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      }
    }
    return <div />;
  }

  render() {
    // const t = (id: string) => this.props.intl.formatMessage({ id });

    return (
      <div className="grid gridCols3">
        <div className="content">
          {this.state.isUploading ? (
            <Loading loading={true} />
          ) : (
            <FileInput
              id="admin_excel_file"
              label="Vaihe, Urakka, Rakennusosa"
              fileListRenderer={() => <div />}
              onChange={files => {
                this.uploadNewDocuments(files);
                console.log(files);
                // props.uploadNewDocuments(files);
              }}
            />
          )}
        </div>
        {this.renderLatest()}
      </div>
    );
  }
}

const aAprovalCycles = applicationState.StateConnector(AbstractItemRegistry);

export default injectIntl(aAprovalCycles);
