import * as React from 'react';
import { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core/';
import { useFormatMessage } from '../../utils/translateHook';
import * as schema from '../../../../shared/src/model/projectSchema';
import AdminTools from '../AdminTools';
import ReactSelect from 'react-select';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Item } from '../../../../shared/src/model';
import { useAdminContext, useAppContext } from 'utils/AppContextProvider';
import Button from '../Common/Button';
import Loader from '../Common/Loading';
import { errorMessage, successMessage } from 'components/Common/Notifier';

interface ContractsProps {}

export default function Contracts(props: ContractsProps) {
  const appCtx = useAppContext();
  const adminCtx = useAdminContext();
  const t = useFormatMessage();
  const [selectedContract, setSelectedContract] = useState<schema.aitem>();
  const [selectedPhase, setSelectedPhase] = useState<Item | null>(null);
  const [contractName, setContractName] = useState<string>('');
  const [contractLongName, setContractLongName] = useState<string>('');
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [isRemovingContract, setRemovingContract] = useState<boolean>(false);

  const [reloadAll, setReloadAll] = useState<number>(1);

  useEffect(() => {
    if (isDisabled) {
      setTimeout(() => {
        setIsDisabled(false);
      }, 2000);
    }
  }, [isDisabled]);

  useEffect(() => {
    appCtx.getPhases();
    adminCtx.getAllContracts();
  }, [reloadAll]);

  const saveNewContract = async () => {
    setIsDisabled(true);

    if (selectedPhase) {
      await adminCtx.createNewContract({
        phaseId: selectedPhase.value,
        contractName: contractName.trim(),
        longName: contractLongName.trim(),
      });

      setContractName('');
      setContractLongName('');
      adminCtx.getAllContracts();
    }
  };

  const nameString = (phase: schema.aitem) => {
    return phase.name_long ? `${phase.name} – ${phase.name_long}` : phase.name;
  };

  const newContract = (
    <ValidatorForm className="newItem" onSubmit={saveNewContract}>
      <h3>{t('adminTools.newContract')}</h3>
      <div>
        <ReactSelect
          placeholder={t('adminTools.phase')}
          value={selectedPhase}
          options={
            appCtx.phases &&
            appCtx.phases.map((phase) => ({
              label: nameString(phase),
              value: phase.id,
            }))
          }
          onChange={(phase: Item) => setSelectedPhase(phase)}
        />
      </div>
      <div>
        <TextField
          style={{ width: '100%' }}
          label={t('adminTools.setContractName')}
          value={contractName}
          onChange={(e) => setContractName(e.target.value)}
        />
      </div>
      <div>
        <TextField
          style={{ width: '100%' }}
          label={t('adminTools.setContractLongName')}
          value={contractLongName}
          onChange={(e) => setContractLongName(e.target.value)}
        />
      </div>
      <Button
        type="submit"
        style={{ margin: '10px 0 0' }}
        disabled={
          selectedPhase === null ||
          !contractName.trim() ||
          !contractLongName.trim() ||
          isDisabled
        }
      >
        {t('buttons.save')}
      </Button>
    </ValidatorForm>
  );

  return (
    <AdminTools>
      <div className="grid gridCols3">
        <div className="component master">
          <h2>{t('headers.contracts')}</h2>
          <div className="content">
            {newContract}
            <ul>
              {adminCtx.contracts &&
                adminCtx.contracts.map((contract) => (
                  <li
                    key={contract.id}
                    onClick={() => setSelectedContract(contract)}
                  >
                    {nameString(contract)}
                  </li>
                ))}
            </ul>
          </div>
        </div>

        {selectedContract && (
          <div className="detail">
            <div className="component">
              <h2>{selectedContract.name}</h2>
              <div className="content">
                <dl>
                  <dt>{t('adminTools.contractName')}</dt>
                  <dd>{selectedContract.name}</dd>
                  <dt>{t('adminTools.contractLongName')}</dt>
                  <dd>{selectedContract.name_long}</dd>
                  <dt></dt>
                  <dd>
                    <Button
                      onClick={async () => {
                        setRemovingContract(true);
                        const value = await adminCtx.removeContract(
                          selectedContract.id
                        );
                        if (value) {
                          successMessage(t('adminTools.removeContractSuccess'));
                          setReloadAll(Date.now());
                          setSelectedContract(undefined);
                        } else {
                          errorMessage(t('adminTools.removeContractFail'));
                        }
                        setRemovingContract(false);
                      }}
                    >
                      {t('adminTools.removeContract')}
                    </Button>

                    {isRemovingContract ? <Loader loading={true} /> : null}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        )}
      </div>
    </AdminTools>
  );
}
