import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  Typography,
} from '@material-ui/core';
import OpenIcon from '@material-ui/icons/OpenInNew';
import axios from 'axios';
import * as _ from 'lodash';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import Moment from 'react-moment';
import { user_agreements } from '../../../../../shared/src/model/projectSchema';
import { AuthService } from '../../../services/auth/src/frontend/api';
import Button from '../Button';
// tslint:disable-next-line:no-var-requires
const ReactMarkdown = require('react-markdown');

interface AgreementRow extends user_agreements {
  agreed_at: Date;
}

interface UserAgreementState {
  agreements: AgreementRow[];
  openedAgreement: AgreementRow | null;
  agreementOpen: boolean;
}

const Auth = AuthService(axios);

const styles = {
  agreementRow: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  openIcon: {
    cursor: 'pointer',
    marginRight: '5px',
  },
} as { [key: string]: React.CSSProperties };

export default class UserAgreementList extends React.PureComponent<
  {},
  UserAgreementState
> {
  state: UserAgreementState = {
    agreements: [],
    openedAgreement: null,
    agreementOpen: false,
  };

  getTitle(agreementRow: AgreementRow): string {
    return _.get(agreementRow, 'agreement.fi.title', '');
  }

  getText(agreementRow: AgreementRow): string {
    return _.get(agreementRow, 'agreement.fi.text', '');
  }

  async componentDidMount() {
    const agreements = await Auth.getAgreedAgreements();
    for (const agreement of agreements) {
      agreement.agreed_at = new Date(agreement.agreed_at);
    }

    agreements.sort((a, b) => this.getTitle(a).localeCompare(this.getTitle(b)));
    this.setState({ agreements });
  }

  onAgreementOpenClick = (openedAgreement: AgreementRow) => () => {
    this.setState({ agreementOpen: true, openedAgreement });
  };

  handleClose = () => {
    this.setState({ agreementOpen: false });
  };

  render() {
    return (
      <Fade in={this.state.agreements.length > 0}>
        <div>
          <ul>
            {this.state.agreements.map((row, index) => (
              <li
                key={row.id}
                style={styles.agreementRow}
                onClick={this.onAgreementOpenClick(row)}
              >
                <OpenIcon color="primary" style={styles.openIcon} />
                <div> {this.getTitle(row)} </div>
              </li>
            ))}
          </ul>
          {this.renderDialog()}
        </div>
      </Fade>
    );
  }

  renderDialog() {
    const agreement = this.state.openedAgreement || ({} as any);

    return (
      <Dialog
        open={this.state.agreementOpen}
        onClose={this.handleClose}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
      >
        <DialogTitle>{this.getTitle(agreement)}</DialogTitle>
        <DialogContent>
          <DialogContentText
            className="agreementsDialogContent"
            component={'div'}
          >
            <ReactMarkdown
              source={this.getText(agreement)}
              escapeHtml={false}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Typography>
            <FormattedMessage id="agreement.agreed" />{' '}
            {<Moment date={agreement.agreed_at} format="DD.MM.YYYY HH:mm" />}
          </Typography>
          <div>
            <Button color="primary" onClick={this.handleClose}>
              <FormattedMessage id="agreement.close" />
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    );
  }
}
