import * as React from 'react';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import * as Model from '../../../../shared/src/model';
import { Async } from '../Common/Async';
import Moment from 'react-moment';

import axios from 'axios';
import { DocumentService } from '../../services/document/src/frontend/api';
import { Table, TableBody, TableRow, TableCell } from '@material-ui/core';
const DOC = DocumentService(axios);

interface IPackageStateProps {
  doc: Model.UIDocument;
}

interface IPackageStateState {
  email: string;
  password: string;
}

export class StatusHistory extends React.Component<
  IPackageStateProps & InjectedIntlProps,
  IPackageStateState
> {
  render() {
    return (
      <Async
        // URI determines if this data should be reloaded when re-rendering the data
        uri={`${this.props.doc.id}:${this.props.doc.modified}`}
        // Fetch data for this component
        loader={async () => await DOC.fetchDocumentHistory(this.props.doc.id)}
        // And then render the data
        render={rows => {
          if (!rows) {
            return <div />;
          }
          return (
            <div className="documentTable">
              <div>
                <FormattedMessage id="documentMeta.statusHistory" />
              </div>
              <hr />
              <Table>
                <TableBody>
                  {rows.map(row => {
                    return (
                      <TableRow key={row.id}>
                        <TableCell className={'workflow_' + row.newStateId}>
                          {this.props.intl.formatMessage({id: `documentMeta.${row.newStateName}`})}
                        </TableCell>
                        <TableCell>
                          <Moment
                            date={row.dateStr}
                            format="DD.MM.YYYY HH:mm"
                          />
                        </TableCell>
                        <TableCell>{row.userName}</TableCell>
                        <TableCell>{row.reasonForRejection}</TableCell>
                      </TableRow>
                    );
                  })}
                  {/*
                  // This dummy row is not removed
                  <TableRow key="initiated">
                    <TableCell>
                      <FormattedMessage id="documentMeta.SUBMITTED" />
                    </TableCell>
                    <TableCell>
                      <Moment
                        date={this.props.doc.created}
                        format="DD.MM.YYYY HH:mm"
                      />
                    </TableCell>
                    <TableCell>{this.props.doc.createdByUser || ''}</TableCell>
                  </TableRow>
                  */}
                </TableBody>
              </Table>
            </div>
          );
        }}
      />
    );
  }
}

export default injectIntl(StatusHistory);
