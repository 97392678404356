import * as React from 'react';
import * as container from '../../state/reducers/ApplicationState';
import { injectIntl, InjectedIntlProps } from 'react-intl';

import SearchIcon from '@material-ui/icons/Search';
import NoteAdd from '@material-ui/icons/NoteAdd';

import './sidebar.scss';

import Search from './search';
import Upload from './upload';

import { push } from 'connected-react-router';
// import { EditMetadataIcon } from '../Common/Icons';

interface ISidebarProps extends container.IProps {}

type Panes = 'search' | 'upload';

interface ISidebarState {
  open: boolean;
  selectedPane?: Panes;
}

type SidebarProps = ISidebarProps & InjectedIntlProps;

class AbstractSidebar extends React.Component<SidebarProps, ISidebarState> {
  constructor(props: SidebarProps) {
    super(props);

    this.state = {
      open: this.props.isSidebarOpen,
    };

  }

  onToggleVisibility = (e: React.MouseEvent<HTMLElement>, target: Panes) => {
    if (this.props.selectedPanel === target) {
      this.props.setSidebarVisibility({ isOpen: !this.props.isSidebarOpen });
    } else {
      this.props.setSidebarVisibility({ isOpen: true, selectedPanel: target });
    }
  };

  onCloseActionPanel = () => {
    this.props.setSidebarVisibility({ isOpen: false });
  };

  public render() {
    const open = this.props.isSidebarOpen;
    const selectedPane = this.props.selectedPanel;
    const { contractPermissions } = this.props;
    const createPermissions = contractPermissions
      ? contractPermissions.createPermissions
      : [];
    const canUpdate =
      createPermissions.filter(
        permission =>
          permission.document_category_name === 'TIL_DOC' ||
          permission.document_category_name === 'URAK_DOC'
      ).length > 0;

    const canUpdateDesignerDocs =
      createPermissions.filter(
        permission => permission.document_category_name === 'TIL_DOC'
      ).length > 0;

    const canUpdateContarctorDocs =
      createPermissions.filter(
        permission => permission.document_category_name === 'URAK_DOC'
      ).length > 0;

    return (
      <div className={`sidebar ${open ? 'open' : 'closed'}`}>
        <div className="navigation">
          <ul>
            <li
              className={selectedPane === 'search' && open ? 'active' : ''}
              onClick={e => this.onToggleVisibility(e, 'search')}
            >
              <SearchIcon />
            </li>
            {canUpdate && (
              <li
                className={selectedPane === 'upload' && open ? 'active' : ''}
                onClick={e => {
                  if (canUpdateContarctorDocs && !canUpdateDesignerDocs) {
                    this.props.ApplicationStateDispatcher(push('/contracts/'));
                    this.props.setSidebarVisibility({ isOpen: false });
                  } else {
                    this.onToggleVisibility(e, 'upload');
                  }
                }}
              >
                <NoteAdd />
              </li>
            )}
            {/*
              <li>
                <EditMetadataIcon />
              </li>
            */}
          </ul>
        </div>
        <div className="content">
          {selectedPane === 'search' ? (
            <Search onCloseActionPanel={this.onCloseActionPanel} />
          ) : (
            <Upload
              onCloseActionPanel={this.onCloseActionPanel}
              isOpen={this.props.isSidebarOpen}
            />
          )}
        </div>
      </div>
    );
  }
}

const Sidebar = container.StateConnector(AbstractSidebar);

export default injectIntl(Sidebar);
