import axios, { CancelToken } from 'axios';

// Changes the view background to selected one by changing
// the body class
type BgClasses = 'managementTools' | 'settings';

// usage: array.filter(notEmpty)
export function notEmpty<TValue>(
  value: TValue | null | undefined
): value is TValue {
  return value !== null && value !== undefined;
}

export function setBackgroundImage(bg?: BgClasses): void {
  // #1395: Use the same background image on every page
  return;

  /* const backgroundClasses: BgClasses[] = ['managementTools', 'settings'];
  document.body.classList.remove(...backgroundClasses);

  if (bg) {
    document.body.classList.add(bg);
  } */
}

export type anyFn = () => void;
export type debounceFn = (fn: anyFn) => void;
export const debounce = (ms: number): debounceFn => {
  let interval: number = 0;
  return (fn: anyFn) => {
    if (interval) {
      clearTimeout(interval);
    }
    interval = window.setTimeout(fn, ms);
  };
};

export function arraymove<T>(arr: T[], fromIndex: number, toIndex: number) {
  const element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
}

// Downloads/saves a file from an URL with a specific name
export const downloadFromURL = async (
  url: string,
  filename: string,
  cancelToken?: CancelToken
) => {
  const rawData = (
    await axios.get<Blob>(url, { responseType: 'blob', cancelToken })
  ).data;

  // Browser compatibility
  if (window.navigator.msSaveBlob) {
    window.navigator.msSaveBlob(rawData, filename);
  } else if (
    (window.URL && window.URL.createObjectURL) ||
    ((window as any).webkitURL && (window as any).webkitURL!.createObjectURL)
  ) {
    // Create a hidden link for downloading the blob
    const url =
      window.URL && window.URL.createObjectURL
        ? window.URL.createObjectURL(rawData)
        : (window as any).webkitURL.createObjectURL(rawData);
    const link = document.createElement('a');
    link.style.display = 'none';
    link.href = url;
    link.setAttribute('download', filename);

    if (!link.download) {
      link.setAttribute('target', '_blank');
    }
    document.body.appendChild(link);
    link.click();

    // Remove the hidden link
    setTimeout(() => {
      link.remove();
      window.URL.revokeObjectURL(url);
    }, 200);
  } else {
    // Fallback on
    window.open(url, '_tab');
  }
};
