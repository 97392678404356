import * as React from 'react';
import { useEffect, useState } from 'react';
import { useAdminContext, useAppContext } from 'utils/AppContextProvider';
import { Async } from 'components/Common/Async';
import {
  List,
  ListItem,
  ListItemIcon,
  Collapse,
  ListItemText,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Brightness1, ExpandLess, Edit } from '@material-ui/icons';
import Loading from 'components/Common/Loading';
import * as _ from 'lodash';
import { AreaSectionEditor } from './AreaSectionEditor';
import TextField from 'components/Common/TextField';
import * as schema from '../../../../../shared/src/model/projectSchema';
import Button from 'components/Common/Button';
import { useFormatMessage } from 'utils/translateHook';

interface AreaFormProps {
  id?: number;
  area_name?: string | null;
  description?: string | null;
}

interface AreasProps {}
export function Areas(props: AreasProps) {
  const ctx = useAppContext();
  const adminCtx = useAdminContext();
  const t = useFormatMessage();

  const [expandedAreaId, setExpandedAreaId] = useState(0);
  const [editingAreaSectionId, setEditAreaSectionId] = useState<
    number | undefined
  >();
  const [editingAreaId, setEditAreaId] = useState<number | undefined>();

  const [areaForm, setAreaForm] = useState<AreaFormProps | undefined>(
    undefined
  );

  useEffect(() => {
    adminCtx.getAllBuildings();
  }, []);

  const toggleEditArea = (area: schema.area | undefined) => {
    if (area) {
      setAreaForm(area);
      editingAreaId === area.id
        ? setEditAreaId(undefined)
        : setEditAreaId(area.id);
    }
  };

  const toggleEditSection = (id: number | undefined) => {
    editingAreaSectionId === id
      ? setEditAreaSectionId(undefined)
      : setEditAreaSectionId(id);
  };

  const updateField = e => {
    if (areaForm) {
      setAreaForm({
        ...areaForm,
        [e.target.name]: e.target.value,
      });
    }
  };

  const saveArea = async () => {
    if (areaForm && areaForm.id) {
      await ctx.updateArea(areaForm as schema.area);
      await ctx.getAreas();
      toggleEditArea(areaForm as schema.area);
    }
  };

  const saveSection = async form => {
    // Save area section and reload
    await ctx.updateAreaSection(form);
    toggleEditSection(editingAreaSectionId);
    await ctx.getAreas();
  };

  return (
    <Async
      placeholder={
        <div>
          <Loading loading={true} />
        </div>
      }
      loader={async () => {
        await ctx.getAreas();
      }}
      render={() => {
        return (
          <div>
            <List>
              {ctx.areas.map((area, index) => {
                const isSelectedArea = editingAreaId === area.id;
                return [
                  <ListItem
                    key={'area_litem_' + index}
                    button={true}
                    onClick={() =>
                      !isSelectedArea &&
                      setExpandedAreaId(
                        expandedAreaId !== area.id ? area.id : 0
                      )
                    }
                  >
                    <ListItemIcon>
                      {area.id === expandedAreaId ? (
                        <ExpandMoreIcon />
                      ) : (
                        <ExpandLess />
                      )}
                    </ListItemIcon>
                    <ListItemText>
                      <span>
                        <b>{area.area_name}</b> {area.description}
                      </span>
                      <span style={{ marginLeft: '10px' }}>
                        <Edit
                          color="primary"
                          onClick={e => {
                            e.stopPropagation();
                            toggleEditArea(area);
                          }}
                        />
                      </span>
                      {isSelectedArea && (
                        <span>
                          <TextField
                            label={t('adminTools.areaName')}
                            value={(areaForm && areaForm.area_name) || ''}
                            name="area_name"
                            onChange={updateField}
                          />
                          <TextField
                            label={t('adminTools.areaDescription')}
                            value={(areaForm && areaForm.description) || ''}
                            name="description"
                            onChange={updateField}
                          />
                          <Button
                            color={'secondary'}
                            onClick={e => {
                              toggleEditArea(area);
                            }}
                          >
                            {t('buttons.cancel')}
                          </Button>
                          &nbsp;
                          <Button
                            onClick={e => {
                              saveArea();
                            }}
                          >
                            {t('buttons.save')}
                          </Button>
                        </span>
                      )}
                    </ListItemText>
                  </ListItem>,
                  <Collapse
                    style={{ backgroundColor: '#ECECEC' }}
                    key={'collapse_' + index}
                    in={area.id === expandedAreaId}
                    timeout="auto"
                    unmountOnExit={true}
                  >
                    <List disablePadding={true}>
                      {area.sections.map((section, index) => {
                        const isSelectedSection =
                          editingAreaSectionId === section.id;
                        return (
                          <ListItem
                            key={'area_section_litem_' + index}
                            button={true}
                          >
                            <ListItemIcon>
                              <Brightness1
                                style={{ marginLeft: '12px', fontSize: 12 }}
                              />
                            </ListItemIcon>
                            <ListItemText>
                              <span>
                                <b>{section.name} </b>
                                {(section.pole_start_pos ||
                                  section.pole_end_pos) &&
                                  `
                                  ${section.pole_start_pos} - ${section.pole_end_pos}
                                `}
                              </span>
                              <Edit
                                style={{ marginLeft: '10px' }}
                                color="secondary"
                                onClick={() => toggleEditSection(section.id)}
                              />

                              {isSelectedSection && (
                                <AreaSectionEditor
                                  form={{ ...section }}
                                  onSave={saveSection}
                                  onCancel={() =>
                                    setEditAreaSectionId(undefined)
                                  }
                                />
                              )}
                            </ListItemText>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Collapse>,
                ];
              })}
            </List>
          </div>
        );
      }}
    />
  );
}
