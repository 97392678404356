import * as React from 'react';
import { Helmet } from 'react-helmet';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { ErrorStateProvider } from 'state/reducers/ErrorState';
import {
  AdminContextProvider,
  AppContextProvider,
  DocContextProvider,
} from 'utils/AppContextProvider';
import { InjectIntlContext } from 'utils/translateContext';
import './App.scss';
import Router from './components/Router';
import brandVariables from './translations/brandVariables';
import withRoot from './withRoot';

class App extends React.Component<InjectedIntlProps> {
  render() {
    const { locale } = this.props.intl;

    return (
      <ErrorStateProvider>
        <InjectIntlContext>
          <AppContextProvider>
            <DocContextProvider>
              <AdminContextProvider>
                <Helmet>
                  <title> {brandVariables[locale].applicationTitle}</title>
                  <meta
                    name="msapplication-TileColor"
                    content={brandVariables['msapplication-TileColor']}
                  />
                  <meta
                    name="theme-color"
                    content={brandVariables['meta-theme-color']}
                  />
                </Helmet>
                <Router />
              </AdminContextProvider>
            </DocContextProvider>
          </AppContextProvider>
        </InjectIntlContext>
      </ErrorStateProvider>
    );
  }
}

export default withRoot(injectIntl(App));
