import * as React from 'react';
import { Async } from '../../Common/Async';
import axios from 'axios';
import { APIService } from '../../../services/api/src/frontend/api';
import { DataTable } from '../../Common/DataTable';
import { useFormatMessage } from 'utils/translateHook';
import { Area } from '../../../../../shared/src/model';
// import { schema.pitem } from '../../../../../shared/src/model/projectSchema';
import * as schema from '../../../../../shared/src/model/projectSchema';
import Button from '../../Common/Button';
import { TextField, ListItem, List } from '@material-ui/core/';
import ReactSelect from 'react-select';
import { ValueType } from 'react-select/lib/types';
import { successMessage } from 'components/Common/Notifier';

interface Item {
  label: string;
  value: number;
}
// import Moment from 'react-moment';
const API = APIService(axios);

function CreateBuilding(props: {
  buildings: schema.pitem[];
  areas: Area[];
  didCreate?: (pitem: schema.pitem) => void;
}) {
  // const t = useFormatMessage();
  // const sortedBuildings = React.useMemo(() => {
  //   return props.buildings.sort((a, b) => a.id - b.id);
  // }, [props.buildings]);

  const [isAdding, setAdding] = React.useState(false);
  const [newBuildingName, setNewBuildingName] = React.useState('');
  const [newBuildingLongName, setNewBuildingLongName] = React.useState('');
  const [newArea, setArea] = React.useState<ValueType<Item> | undefined>(
    undefined
  );
  return (
    <div style={{ margin: '1em' }}>
      <Button onClick={() => setAdding(!isAdding)}>
        Lisää uusi rakennusosa
      </Button>
      {isAdding ? (
        <form>
          <List>
            <ListItem>
              <TextField
                style={{ width: '40%' }}
                label={'Nimi'}
                value={newBuildingName}
                onChange={(e) => setNewBuildingName(e.target.value)}
              />
            </ListItem>
            <ListItem>
              <TextField
                style={{ width: '40%' }}
                label={'Pitkä nimi'}
                value={newBuildingLongName}
                onChange={(e) => setNewBuildingLongName(e.target.value)}
              />
            </ListItem>
            <ListItem style={{ width: '10em' }}>
              <div style={{ width: '100px' }}>
                <ReactSelect
                  style={{ width: '200px' }}
                  className="dropdown"
                  menuPortalTarget={document.body}
                  isMulti={false}
                  placeholder={'Alue'}
                  options={
                    props.areas.map((category) => ({
                      label: category.area_name,
                      value: category.id,
                    })) as Item[]
                  }
                  value={newArea}
                  onChange={(values: ValueType<Item>) => {
                    if (!Array.isArray(values) && values) {
                      setArea(values);
                    }
                  }}
                />
              </div>
            </ListItem>
            <ListItem>
              <Button
                disabled={!newBuildingLongName || !newBuildingName || !newArea}
                onClick={async () => {
                  if (
                    newBuildingLongName.length > 0 &&
                    newBuildingName.length > 0 &&
                    newArea
                  ) {
                    const pitem = await API.createBuilding({
                      name: newBuildingName,
                      name_long: newBuildingLongName,
                      areaName: (newArea as Item).label,
                    });
                    setAdding(false);
                    if (props.didCreate) props.didCreate(pitem);
                  }
                }}
              >
                Tallenna
              </Button>
            </ListItem>
          </List>
        </form>
      ) : null}
    </div>
  );
}

function ShowBuildings(props: { buildings: schema.pitem[]; areas: Area[] }) {
  const t = useFormatMessage();
  const sortedBuildings = React.useMemo(() => {
    return props.buildings.sort((a, b) => b.id - a.id);
  }, [props.buildings]);
  return (
    <DataTable
      rows={sortedBuildings}
      keys={['id', 'area_id', 'name', 'name_long']}
      titles={{ area_id: t('documentMeta.area') }}
      render={{
        area_id: (row) => (
          <span>
            {props.areas
              .filter((a) => a.id === row.area_id)
              .map((a) => a.area_name)
              .join('')}
          </span>
        ),
      }}
    />
  );
}

export default function Buildings() {
  // 'documentMeta.area'
  return (
    <Async
      loader={async () => ({
        buildings: await API.getAllBuildings(),
        areas: await API.getAreas(),
      })}
      render={(data, reload) => {
        if (!data) {
          return <div />;
        }
        return (
          <div>
            <CreateBuilding
              buildings={data.buildings}
              areas={data.areas}
              didCreate={(item) => {
                successMessage(`Uusi rakennusosa ${item.name} lisätty`);
                reload();
              }}
            />
            <ShowBuildings buildings={data.buildings} areas={data.areas} />
          </div>
        );
      }}
    />
  );
}
