import * as React from 'react';
import * as _ from 'lodash';

import './propertyTable.scss';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import { useImmer } from 'use-immer';
import Loading from 'components/Common/Loading';
import { TableRow, TableHead, TableCell } from '@material-ui/core';

export type ImmutableDataTableRow = object & { id: string | number };
export type ImmutableDataTableRenderers<
  T extends ImmutableDataTableRow,
  S extends object
> = Array<{
  id: string;
  title: React.FC<any> | string;
  cell: React.FC<{
    rowIndex: number;
    row: T;
    data: { rows: T[]; settings: S };
    modifyData: (callBack: (draft: { rows: T[]; settings: S }) => void) => void;
  }>;
}>;

interface ImmutableDataTableProps<
  T extends ImmutableDataTableRow,
  S extends object
> {
  settings: S;
  rows: T[];
  renderers: ImmutableDataTableRenderers<T, S>;
  onClick?: (item: TexImageSource) => void;
  onData?: (
    newData: { settings: S; rows: T[] },
    originalData: { settings: S; rows: T[] }
  ) => void;
  rowStyles?: (row: T) => { [key: string]: string | undefined };
}

const ImmutableDataTable = <T extends ImmutableDataTableRow, S extends object>(
  props: ImmutableDataTableProps<T, S>
) => {
  const [data, modifyData] = useImmer({
    rows: props.rows,
    settings: props.settings,
  });
  const originalData = React.useMemo(() => data, []);
  React.useEffect(() => {
    if (props.onData) {
      props.onData(data, originalData);
    }
  }, [data, props.onData]);
  return data.rows.length === 0 ? (
    <p>
      <Loading loading={true} />
    </p>
  ) : (
    <Paper style={{ overflow: 'scroll' }}>
      <Table className="dataTable">
        <TableHead>
          <TableRow>
            {props.renderers.map(({ id, title: Title }) => (
              <TableCell key={`${id}-title`}>
                {typeof Title === 'string' ? Title : <Title />}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.rows.map((row, index) => {
            return (
              <TableRow key={row.id}>
                {props.renderers.map((r) => {
                  return (
                    <r.cell
                      key={r.id}
                      rowIndex={index}
                      row={row}
                      data={data}
                      modifyData={(modifyData as unknown) as any}
                    />
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default ImmutableDataTable;
