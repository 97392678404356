// getPrivilegesForRole
import * as React from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import * as Model from '../../../../shared/src/model/index';
import * as schema from '../../../../shared/src/model/projectSchema';
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@material-ui/core';
// import Label from '@material-ui/icons/Label';
import Group from '@material-ui/icons/Group';
import {
  AddCircle,
  RemoveCircle,
  Create,
  Visibility,
} from '@material-ui/icons/';
import Chip from '@material-ui/core/Chip';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import PrivilegeSelector from './PrivilegeSelector';
const rootStyles = {
  lineHeight: '20px',
  fontSize: 15,
  border: 0,
  boxShadow: '3',
  margin: 20,
};

const basicStyles = (theme: Theme) =>
  createStyles({
    root: {
      ...rootStyles,
      textTransform: 'none',
    },
    privilege: {
      ...rootStyles,
      margin: '5px',
      padding: '5px',
    },
    notRule: {
      background: '#e57777',
    },
    chip: {
      margin: theme.spacing.unit / 2,
    },
    green: {
      background: '#CCFFE0',
    },
    blue: {
      background: '#CDFFFF',
    },
    yellow: {
      background: '#FFFFCD',
    },
    red: {
      background: '#FFD6DD',
    },
  });

export interface CardProps {
  classes?: any;
  role: Model.UIRole;
  metadata?: Model.UIDocMetadata;
  actions?: schema.action[];
  privileges?: schema.privilege[];
  onRemoveRoleFromUser?: (roleId: number) => void;
  onRemoveRole?: () => void;
  onRemovePrivilege?: () => void;
  onAddPrivilege?: (privileges: Model.UIRolePrivilege[]) => void;
}

interface CardState {
  dialogOpen: boolean;
  removeRoleId?: number;
}

type PrivilegeCardProps = CardProps & InjectedIntlProps;

class PrivilegeCard extends React.Component<PrivilegeCardProps, CardState> {
  constructor(props: PrivilegeCardProps) {
    super(props);
    this.state = {
      dialogOpen: false,
    };
  }

  getCategory(categoryId?: number): Model.UIDocumentCategory | undefined | 0 {
    const { metadata } = this.props;
    return (
      metadata &&
      categoryId &&
      metadata.documentCategories.filter(v => v.id === categoryId).pop()
    );
  }

  getState(documentStateId?: number): schema.documentstate | undefined | 0 {
    const { metadata } = this.props;
    const s =
      metadata &&
      documentStateId &&
      metadata.workflowStates.filter(v => v.id === documentStateId).pop();
    return s;
  }

  getType(pitemTypeId?: number): schema.pitem_type | undefined | 0 {
    const { metadata } = this.props;
    return (
      metadata &&
      pitemTypeId &&
      metadata.pitemTypes.filter(v => v.id === pitemTypeId).pop()
    );
  }

  getAction(actionId: number) {
    const { actions } = this.props;
    return actions && actions.filter(a => a.id === actionId).pop();
  }

  renderDialog = () => (
    <Dialog
      open={this.state.dialogOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <FormattedMessage id="adminTools.removePermissionVerifyTitle" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <FormattedMessage id="adminTools.removePermissionVerify" />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={() => {
            this.setState({ dialogOpen: false });
          }}
        >
          <FormattedMessage id="adminTools.removePermissionCancel" />
        </Button>
        <Button
          color="primary"
          autoFocus={true}
          onClick={() => this.handleRemoveRoleFromUser(this.state.removeRoleId)}
        >
          <FormattedMessage id="adminTools.removePermissionAccept" />
        </Button>
      </DialogActions>
    </Dialog>
  );

  onRemovingRole = (roleId?: number) => {
    if (roleId) {
      this.setState({
        dialogOpen: true,
        removeRoleId: roleId,
      });
    }
  };

  handleRemoveRoleFromUser = (roleId?: number) => {
    if (roleId && this.props.onRemoveRoleFromUser) {
      this.props.onRemoveRoleFromUser(roleId);
      this.setState({
        dialogOpen: false,
        removeRoleId: undefined,
      });
    }
  };

  renderSpecifiersAndAction(privilege: Model.UIRolePrivilege) {
    const t = (id: string) => this.props.intl.formatMessage({ id });
    const categories =
      privilege.specifiers &&
      privilege.specifiers.filter(v => !!v.documentCategoryId);
    const states =
      privilege.specifiers &&
      privilege.specifiers.filter(v => !!v.documentStateId);
    const types =
      privilege.specifiers && privilege.specifiers.filter(v => !!v.pitemTypeId);
    const action = privilege.actionId && this.getAction(privilege.actionId);
    return (
      <div className="specifiers">
        <dl>
          {categories && categories.length > 0 && (
            <>
              <dt>
                <FormattedMessage id="documentMeta.documentCategory" />
              </dt>
              <dd>
                {categories
                  .map(s => this.getCategory(s.documentCategoryId))
                  .map(c => (c && c.name) || 'N/A')
                  .join(', ')}
              </dd>
            </>
          )}
          {states && states.length > 0 && (
            <>
              <dt>
                <FormattedMessage id="documentMeta.workflowStateName" />
              </dt>
              <dd>
                {states
                  .map(s => this.getState(s.documentStateId))
                  .map(c => c && c.name ? t(`documentMeta.${c.name}`) : 'N/A')
                  .join(', ')}
              </dd>
            </>
          )}
          {types && types.length > 0 && (
            <>
              <dt>
                <FormattedMessage id="permissions.pItemTypes" />
              </dt>
              <dd>
                {types
                  .map(s => this.getType(s.pitemTypeId))
                  .map(c => (c && c.name) || 'N/A')
                  .join(', ')}
              </dd>
            </>
          )}
          {action && (
            <>
              <dt>
                <FormattedMessage id="permissions.action" />
              </dt>
              <dd>{action.action_name} </dd>
            </>
          )}
        </dl>
      </div>
    );
  }

  getPrivilegeDescription = (privilegeId: number) => {
    const privilege =
      this.props.privileges &&
      this.props.privileges.filter(p => p.id === privilegeId).pop();
    return privilege ? privilege.description : '';
  };

  render() {
    const t = (id: string) => this.props.intl.formatMessage({ id });
    const { role } = this.props;
    return (
      <Card className={`${this.props.classes.root} privilegeCard`}>
        {this.renderDialog()}
        <CardContent>
          <Typography component="h5" variant="h5">
            <Group /> {role.name}
          </Typography>
          <FormattedMessage id="permissions.permissions" />
          {role.privileges.map(privilege => (
            <Card
              className={`${this.props.classes.privilege} ${privilege.notRule &&
                this.props.classes.notRule}`}
              key={privilege.id}
            >
              <Typography variant="subtitle1" align={'left'}>
                {privilege.notRule ? (
                  <FormattedMessage id="permissions.notRule" />
                ) : (
                  <FormattedMessage id="permissions.permission" />
                )}
              </Typography>

              {privilege.privilegeId && (
                <Typography variant="body1">
                  {this.getPrivilegeDescription(privilege.privilegeId)}
                </Typography>
              )}
              {this.renderSpecifiersAndAction(privilege)}
              {privilege.c && (
                <Chip
                  key={'privilege_c'}
                  icon={<AddCircle />}
                  label={t('permissions.create')}
                  clickable={false}
                  className={`${this.props.classes.chip} ${
                    this.props.classes.green
                  }`}
                  variant="outlined"
                  color="primary"
                />
              )}
              {privilege.r && (
                <Chip
                  key={'privilege_r'}
                  icon={<Visibility />}
                  label={t('permissions.read')}
                  clickable={false}
                  className={`${this.props.classes.chip} ${
                    this.props.classes.blue
                  }`}
                  variant="outlined"
                  color="primary"
                />
              )}
              {privilege.u && (
                <Chip
                  key={'privilege_u'}
                  icon={<Create />}
                  label={t('permissions.update')}
                  clickable={false}
                  className={`${this.props.classes.chip} ${
                    this.props.classes.yellow
                  }`}
                  variant="outlined"
                  color="primary"
                />
              )}
              {privilege.d && (
                <Chip
                  key={'privilege_d'}
                  icon={<RemoveCircle />}
                  label={t('permissions.delete')}
                  clickable={false}
                  className={`${this.props.classes.chip} ${
                    this.props.classes.red
                  }`}
                  variant="outlined"
                  color="primary"
                />
              )}
            </Card>
          ))}
        </CardContent>
        <CardActions>
          {this.props.onRemoveRoleFromUser && (
            <Button
              size="small"
              type="reset"
              onClick={() => this.onRemovingRole(role.id)}
            >
              <FormattedMessage id="buttons.delete" />
            </Button>
          )}

          {this.props.onAddPrivilege && (
            <PrivilegeSelector
              metadata={this.props.metadata}
              onAddPrivilege={this.props.onAddPrivilege}
              actions={this.props.actions}
              privileges={this.props.privileges}
            />
          )}
        </CardActions>
      </Card>
    );
  }
}

// export const ToolbarButton = withStyles(styleWithMargin)(ActionButton);

export default withStyles(basicStyles)(injectIntl(PrivilegeCard));
