import {
  Checkbox,
  LinearProgress,
  FormGroup,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import axios from 'axios';
import * as React from 'react';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Link } from 'react-router-dom';
import {
  DocumentCategory,
  UIUserRolePrivilege,
} from '../../../../shared/src/model/index';
import MainLayout from '../../layout/MainLayout';
import { APIService } from '../../services/api/src/frontend/api';
import * as container from '../../state/reducers/ApplicationState';
import { PasswordResetStatus } from '../../state/reducers/ApplicationState';
import Breadcrumbs from '../Common/Breadcrumbs';
import Button from '../Common/Button';
import { errorMessage, Notifier } from '../Common/Notifier';
import PasswordField from '../Common/PasswordField';
import UserAgreementList from '../Common/UserAgreements/AgreementList';
import ReactSelect from 'react-select';
// port { ReactSelect } from '../Common/ReactSelectAll';
import { languages as languagesEN } from '../../translations/en';
import { languages as languagesFI } from '../../translations/fi';
import './settings.scss';
import { Item } from 'components/Common/AutosuggestChipSelection';
import { user_email_settings } from '../../../../shared/src/model/projectSchema';
import { useAppState } from 'utils/metadata';
import { useUnsafeFormatMessage } from 'utils/translateHook';
// tslint:disable-next-line:no-var-requires
const ReactMarkdown = require('react-markdown');

const API = APIService(axios);

interface LanguageOption {
  label: string;
  value: string;
}

interface ISettingsProps extends container.IProps {
  something?: string;
  passwordResetStatus: PasswordResetStatus;
}

interface ISettingsState {
  fullName?: string;
  email?: string;
  passwordOld: string;
  passwordNew: string;
  passwordNew2: string;
  privileges?: UIUserRolePrivilege[];
  email_settings?: user_email_settings[];
  isLoadingPrivileges?: boolean;
  isReceivingEmailReports: boolean;
  languageOptions: LanguageOption[];
}

type SettingsProps = ISettingsProps & InjectedIntlProps;

const initialState: ISettingsState = {
  passwordOld: '',
  passwordNew: '',
  passwordNew2: '',
  isReceivingEmailReports: false,
  languageOptions: [],
};

export const EmailSetttings = (props: {
  designDisciplines: DocumentCategory[];
  getContractorWorkTypes: DocumentCategory[];
  privileges: UIUserRolePrivilege[];
}) => {
  const [settings, setSettings] = React.useState<user_email_settings[]>([]);
  const appCtx = useAppState();
  const t = useUnsafeFormatMessage();
  const SaveEmailSettings = async (values: user_email_settings[]) => {
    await API.updateUserEmailSettings({
      list: values,
    });
  };
  const UpdateSettings = (newSetting: user_email_settings) => {
    const value = settings.map((oldSetting) => {
      if (oldSetting.id === newSetting.id) {
        return { ...newSetting };
      }
      return oldSetting;
    });
    setSettings(value);
    SaveEmailSettings(value);
  };

  const FetchEmailSettings = async () => {
    setSettings(await API.getUserEmailSettings());
  };
  React.useEffect(() => {
    FetchEmailSettings();
  }, []);

  const rolesAndAitems: {
    contractor: Array<number>;
    reviewer: Array<number>;
    inspector: Array<number>;
  } = React.useMemo(() => {
    return {
      contractor: props.privileges
        .map((p) => {
          if (
            p.roles.filter(
              (r) => r.name.toLocaleLowerCase().indexOf('urak') === 0
            ).length > 0
          ) {
            return p.aitem!.id;
          }
          return -1;
        })
        .filter((n) => n > 0),
      reviewer: props.privileges
        .map((p) => {
          if (
            p.roles.filter(
              (r) =>
                r.name.toLocaleLowerCase().indexOf('suunnittelija tark') === 0
            ).length > 0
          ) {
            return p.aitem!.id;
          }
          return -1;
        })
        .filter((n) => n > 0),
      inspector: props.privileges
        .map((p) => {
          if (
            p.roles.filter(
              (r) => r.name.toLocaleLowerCase().indexOf('tilaajan tark') === 0
            ).length > 0
          ) {
            return p.aitem!.id;
          }
          return -1;
        })
        .filter((n) => n > 0),
    };
  }, [props.privileges]);

  function filterIn<T extends { id: number }>(
    list: T[],
    oldIds: number[],
    newIds: number[]
  ) {
    return [
      // accept all values not in the specific list
      ...oldIds.filter(
        (id) => list.filter((item) => item.id === id).length === 0
      ),
      ...newIds.filter(
        (id) => list.filter((item) => item.id === id).length > 0
      ),
    ];
  }

  function filterInAitems<T extends { aitem_id: number }>(
    list: T[],
    oldIds: number[],
    newIds: number[]
  ) {
    return [
      // accept all values not in the specific list
      ...oldIds.filter(
        (id) => list.filter((item) => item.aitem_id === id).length === 0
      ),
      ...newIds.filter(
        (id) => list.filter((item) => item.aitem_id === id).length > 0
      ),
    ];
  }

  if (props.privileges.length === 0) {
    return null;
  }

  return (
    <>
      {settings.map((s) => {
        return (
          <FormGroup key={s.id}>
            <h2>{t(s.email_name)}</h2>
            <FormControlLabel
              control={
                <Switch
                  checked={s.is_enabled || false}
                  onChange={(e) => {
                    UpdateSettings({
                      ...s,
                      is_enabled: e.target.checked,
                    });
                  }}
                />
              }
              label="Sähköpostimuistutukset"
            />

            {(rolesAndAitems.reviewer.length > 0 ||
              rolesAndAitems.inspector.length > 0) &&
              s.email_name.toLocaleLowerCase().indexOf('rejected') < 0 ? (
              <>
                <div>Suunnitteluala</div>

                <ReactSelect
                  isMulti={true}
                  required={false}
                  value={(s.categories_filter || [])
                    .filter((id) => {
                      return (
                        props.designDisciplines.filter((dp) => dp.id === id)
                          .length > 0
                      );
                    })
                    .map((id) => {
                      const [p] = props.designDisciplines.filter(
                        (dp) => dp.id === id
                      );
                      return {
                        label: p.name,
                        value: p.id,
                      } as Item;
                    })}
                  options={props.designDisciplines.map((item) => {
                    return {
                      label: item.name,
                      value: item.id,
                    };
                  })}
                  onChange={(selected: Item[]) => {
                    //
                    UpdateSettings({
                      ...s,
                      categories_filter: filterIn(
                        props.designDisciplines,
                        s.categories_filter || [],
                        selected.map((item) => item.value)
                      ),
                    });
                  }}
                />
              </>
            ) : null}

            {s.email_name.toLocaleLowerCase().indexOf('contractor') < 0 && (<>
            <div>Työlaji</div>

            <ReactSelect
              isMulti={true}
              required={false}
              value={(s.categories_filter || [])
                .filter((id) => {
                  return (
                    props.getContractorWorkTypes.filter((dp) => dp.id === id)
                      .length > 0
                  );
                })
                .map((id) => {
                  const [p] = props.getContractorWorkTypes.filter(
                    (dp) => dp.id === id
                  );
                  return {
                    label: p.name,
                    value: p.id,
                  } as Item;
                })}
              options={props.getContractorWorkTypes.map((item) => {
                return {
                  label: item.name,
                  value: item.id,
                };
              })}
              onChange={(selected: Item[]) => {
                UpdateSettings({
                  ...s,
                  categories_filter: filterIn(
                    props.getContractorWorkTypes,
                    s.categories_filter || [],
                    selected.map((item) => item.value)
                  ),
                });
              }}
            />
            </>)}

            <div>Urakka</div>

            <ReactSelect
              isMulti={true}
              required={false}
              value={(s.contracts_filter || [])
                .filter((id) => {
                  return (
                    appCtx.userContractsOrig.filter((dp) => dp.aitem_id === id)
                      .length > 0
                  );
                })
                .map((id) => {
                  const [p] = appCtx.userContractsOrig.filter(
                    (dp) => dp.aitem_id === id
                  );
                  return {
                    label: p.aitem_name,
                    value: p.aitem_id,
                  } as Item;
                })}
              options={appCtx.userContractsOrig.map((item) => {
                return {
                  label: item.aitem_name,
                  value: item.aitem_id,
                };
              })}
              onChange={(selected: Item[]) => {
                UpdateSettings({
                  ...s,
                  contracts_filter: filterInAitems(
                    appCtx.userContractsOrig,
                    s.contracts_filter || [],
                    selected.map((item) => item.value)
                  ),
                });
              }}
            />
          </FormGroup>
        );
      })}
    </>
  );
};

class AbstractSettings extends React.Component<SettingsProps, ISettingsState> {
  constructor(props: SettingsProps) {
    super(props);

    this.state = {
      ...initialState,
    };
  }

  resetFields = () => {
    this.setState({ ...initialState });
  };

  componentDidUpdate = async (prevProps: ISettingsProps) => {
    if (prevProps !== this.props) {
      if (prevProps.appstatus.resetPassword.loaded) {
        this.resetFields();
      }

      if (this.props.user) {
        const { firstname, lastname, email } = this.props.user;

        this.setState({
          fullName: `${firstname} ${lastname}`,
          email: email || '',
        });

        try {
          this.setState({ isLoadingPrivileges: true });
          const privileges = await API.getRolePrivilegesForUser(
            this.props.user!.id
          );
          this.setState({
            privileges,
            email_settings: await API.getUserEmailSettings(),
          });
          this.setState({ isLoadingPrivileges: false });
        } catch (e) {
          errorMessage(e);
          this.setState({ isLoadingPrivileges: false });
        }
      }

      const { userLanguage } = this.props.userSettings;
      const { locale } = this.props.intl;
      const languages = {
        en: languagesEN,
        fi: languagesFI,
      };
      const languageOptions = userLanguage
        ? languages[userLanguage]
        : languages[locale];
      this.setState({ languageOptions });
    }
  };

  onPasswordOldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ passwordOld: e.currentTarget.value });
  };

  onPasswordNewChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ passwordNew: e.currentTarget.value });
  };

  onPasswordNew2Change = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ passwordNew2: e.currentTarget.value });
  };

  handleSubmit = (e: any) => {
    this.props.resetPassword({
      passwordOld: this.state.passwordOld,
      passwordNew: this.state.passwordNew,
    });
  };

  _renderEmailSummaries = () => {
    const { userPermissions } = this.props;
    return userPermissions && userPermissions.roles
      ? userPermissions.roles.map((role: { id: number; name: string }) => {
          return (
            <div key={role.id}>
              <Checkbox />
              {role.name}
            </div>
          );
        })
      : null;
  };

  onToggleSendEmailReports = (name: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({ ...this.state, [name]: event.target.checked });
  };

  public render() {
    const t = (id: string) => this.props.intl.formatMessage({ id });

    const { privileges, isLoadingPrivileges, languageOptions } = this.state;
    const { saveUserSettings } = this.props;
    const { locale } = this.props.intl;

    ValidatorForm.addValidationRule('isPasswordMatch', (value: string) => {
      return value === this.state.passwordNew;
    });

    const validators = [
      'required',
      `matchRegexp:^\\S*$`,
      'matchRegexp:w*[A-ZÄÖÅ]',
      'matchRegexp:w*[a-zäöå]',
      'matchRegexp:w*[0-9]',
      'minStringLength:8',
    ];
    const errorMessages = [
      t('passwordReset.requirements.required'),
      t('passwordReset.requirements.noSpaces'),
      t('passwordReset.requirements.hasCapitalLetters'),
      t('passwordReset.requirements.hasLowercaseLetters'),
      t('passwordReset.requirements.hasNumbers'),
      t('passwordReset.requirements.eightCharacters'),
    ];

    return (
      <MainLayout>
        <Breadcrumbs>
          <Link to="/">
            <FormattedMessage id="headers.home" />
          </Link>
          <FormattedMessage id="settings.header" />
        </Breadcrumbs>

        <h1>
          <FormattedMessage id="settings.header" />
        </h1>

        <div className="grid gridCols2">
          <div className="componentContainer">
            <div className="component userDetails">
              <h2>
                <FormattedMessage id="headers.PersonalInfo" />
              </h2>

              <div className="content">
                <ul>
                  <li>{this.state.fullName}</li>
                  <li>
                    <strong>
                      <FormattedMessage id="userDetails.email" />:{' '}
                    </strong>
                    {this.state.email}
                  </li>
                </ul>
              </div>
            </div>

            <div className="component userSettings">
              <h2>
                <FormattedMessage id="settings.header" />
              </h2>

              <div className="content">
                <ul>
                  <li>
                    <label>
                      {t('settings.userLanguage')}
                      <ReactSelect
                        menuPortalTarget={document.body}
                        isMulti={false}
                        placeholder={t('settings.selectUserLanguage')}
                        disabled={!languageOptions}
                        options={languageOptions}
                        value={languageOptions
                          .filter((option) => option.value === locale)
                          .pop()}
                        onChange={(language: LanguageOption) =>
                          saveUserSettings({
                            userLanguage: language.value,
                          })
                        }
                      />
                    </label>
                  </li>
                  {/* <li>
                    <FormControlLabel
                      label={t('settings.sendMails')}
                      control={
                        <Checkbox
                          onChange={this.onToggleSendEmailReports(
                            'isReceivingEmailReports'
                          )}
                          disabled={true}
                        />
                      }
                    />
                  </li> */}
                  <li>{this._renderEmailSummaries()}</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="component passwordReset">
            <h2>
              <FormattedMessage id="passwordReset.header" />
            </h2>
            {this.props.appstatus.resetPassword.loaded ? (
              <Notifier
                snack={{
                  variant: 'success',
                  message: t('passwordReset.success'),
                }}
              />
            ) : null}
            <div className="content">
              <pre>
                <FormattedMessage id="passwordReset.requirements" />
              </pre>
              <ValidatorForm onSubmit={this.handleSubmit}>
                <PasswordField
                  name="passwordOld"
                  label={t('passwordReset.passwordOld')}
                  value={this.state.passwordOld}
                  validators={validators}
                  errorMessages={errorMessages}
                  onChange={this.onPasswordOldChange}
                />
                <PasswordField
                  name="passwordNew"
                  label={t('passwordReset.passwordNew')}
                  value={this.state.passwordNew}
                  validators={validators}
                  errorMessages={errorMessages}
                  onChange={this.onPasswordNewChange}
                />
                <PasswordField
                  name="passwordNew2"
                  label={t('passwordReset.passwordNew2')}
                  value={this.state.passwordNew2}
                  validators={['isPasswordMatch']}
                  errorMessages={[
                    t('passwordReset.requirements.isPasswordMatch'),
                  ]}
                  onChange={this.onPasswordNew2Change}
                />
                <div className="footerActions">
                  <Button color="primary" type="submit">
                    <FormattedMessage id="passwordReset.submit" />
                  </Button>
                </div>
              </ValidatorForm>
            </div>
          </div>

          <div className="componentContainer">
            <div className="component agreements">
              <h2>
                <FormattedMessage id="settings.agreementsHeader" />
              </h2>
              <div className="content">
                <UserAgreementList />
              </div>
            </div>

            <div className="component support">
              <h2>
                <FormattedMessage id="settings.support" />
              </h2>
              <div className="content">
                <a
                  href="/docs/v1/usermanual"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage id="settings.userManual" />
                </a>
                <ReactMarkdown source={t('settings.supportMessage')} />
              </div>
            </div>

            <div className="component support">
              <h2>
                <FormattedMessage id="headers.emails" />
              </h2>
              <div className="content-no-overflow">
                <EmailSetttings
                  designDisciplines={this.props.designDisciplines}
                  getContractorWorkTypes={this.props.getContractorWorkTypes}
                  privileges={this.state.privileges || []}
                />
              </div>
            </div>
          </div>

          <div className="component roles">
            <h2>
              <FormattedMessage id="headers.roles" />
            </h2>

            {isLoadingPrivileges && <LinearProgress />}

            <div className="content">
              {privileges &&
                privileges.map(
                  (contract: UIUserRolePrivilege, index: number) => (
                    <div key={index}>
                      <h3>{contract.aitem && contract.aitem.name}</h3>
                      <ul>
                        {contract.roles.map((role, index) => (
                          <li key={index}>
                            <div>{role.name}</div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )
                )}
            </div>
          </div>
        </div>
      </MainLayout>
    );
  }
}

const Settings = container.StateConnector(AbstractSettings);

export default injectIntl(Settings);
