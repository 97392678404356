import * as React from 'react';
import * as container from '../../state/reducers/DocumentState';
import * as appStatecontainer from '../../state/reducers/ApplicationState';
import { FormattedMessage, injectIntl, InjectedIntlProps } from 'react-intl';
import * as Model from '../../../../shared/src/model';

import MainLayout from '../../layout/MainLayout';
import { Redirect } from 'react-router-dom';

import IconUp from '@material-ui/icons/ArrowUpwardOutlined';
import IconDown from '@material-ui/icons/ArrowDownwardOutlined';
import IconEdit from '@material-ui/icons/Edit';
import IconDelete from '@material-ui/icons/Delete';
import Button from '../Common/Button';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
} from '@material-ui/core';

import axios from 'axios';
import { APIService } from '../../services/api/src/frontend/api';
import { DocumentService } from '../../services/document/src/frontend/api';
import { Async } from '../Common/Async';
import ToggleArea from '../Common/ToggleArea';
import SearchResultsTable from 'components/SearchResults/SearchResultsTable';
import * as _ from 'lodash';

const API = APIService(axios);
const DOC = DocumentService(axios);

interface IHomeProps extends container.IProps, appStatecontainer.IProps {}

interface IHomeState {
  searches: any;
  goToSavedSearch?: string;
  navigateToDocument?: boolean;
  selectedDocument?: Model.UIDocument;
  selectedDocuments: Model.UIDocument[][];
  lastOrder: { [key: string]: 'ASC' | 'DESC' };
  isDocuments?: boolean;
  savedSearchToBeDeleted?: string;
}

type HomeProps = IHomeProps & InjectedIntlProps;

class AbstractHome extends React.Component<HomeProps, IHomeState> {
  constructor(props: HomeProps) {
    super(props);
    this.state = {
      isDocuments: true,
      lastOrder: {},
      searches: [],
      selectedDocuments: [],
    };
    this.onSelect = this.onSelect.bind(this);
  }

  componentDidMount() {
    // TODO: move to show searches component etc.
    this.props.getUserSearches();
  }

  componentDidUpdate(prevProps: HomeProps) {
    if (prevProps.searches !== this.props.searches) {
      this.setState({ searches: this.props.searches.savedSearches });
    }
  }

  async onSelect(savedSearch: Model.SearchRequest) {
    const res = await API.getBuildingsForContracts(
      savedSearch.contractFilter || []
    );
    this.props.assignBuildingElementTree(res);
    this.props.setActiveSearch(savedSearch);
    this.props.documentSearch(savedSearch);

    if (savedSearch.name) {
      this.setState({ goToSavedSearch: savedSearch.name });
    }
  }

  onOpenReviewItems() {
    console.log('onOpenReviewItems');
  }
  handleRedirectToDocument = (
    document: Model.UIDocument,
    isDocuments: boolean
  ) => {
    this.setState({
      selectedDocument: document,
      navigateToDocument: true,
      isDocuments,
    });
  };

  flipOrder = (name: string): 'ASC' | 'DESC' => {
    const lastOrder = this.state.lastOrder;
    if (!lastOrder.hasOwnProperty(name)) {
      this.setState({ lastOrder: { ...this.state.lastOrder, [name]: 'ASC' } });
      return 'ASC';
    }
    const flipped = lastOrder[name] === 'ASC' ? 'DESC' : 'ASC';
    this.setState({
      lastOrder: _.fromPairs(
        _.keys(lastOrder).map((key) => [
          key,
          key !== name ? lastOrder[key] : flipped,
        ])
      ),
    });
    return flipped;
  };

  public render() {
    if (this.state.navigateToDocument && this.state.selectedDocument) {
      const { selectedDocument } = this.state;
      let pathname = `/documents/${selectedDocument.id}`;
      if (
        selectedDocument.categoryNames &&
        selectedDocument.categoryNames.indexOf('TARKASTUSASIAKIRJA_DOC') !== -1
      ) {
        pathname = `/inspection-documents/target/${selectedDocument.parentId}/attachment/${selectedDocument.id}`;
      }
      if (!this.state.isDocuments) {
        pathname = `/items/${selectedDocument.id}`;
      }

      return (
        <Redirect
          to={{
            pathname,
            state: {
              document: this.props.searchResults
                .filter((doc) => doc.id === selectedDocument.id)
                .pop(),
            },
          }}
          push={true}
        />
      );
    }

    if (this.state.goToSavedSearch) {
      return (
        <Redirect
          to={{
            pathname: '/results',
            state: { savedSearchName: this.state.goToSavedSearch },
          }}
        />
      );
    }

    const searchList = this.props.searches.savedSearches
      ? [...this.props.searches.savedSearches]
      : [];
    searchList.reverse();
    const openSearch = async (search: Model.SearchRequest) => {
      const res = await API.getBuildingsForContracts(
        search.contractFilter || []
      );
      this.props.assignBuildingElementTree(res);
      this.props.setActiveSearch(search);
      this.props.documentSearch(search);
      this.props.setSidebarVisibility({
        isOpen: true,
        selectedPanel: 'search',
      });
      if (search.name) {
        this.setState({
          goToSavedSearch: search.name,
        });
      }
    };

    const reloader = (
      search: Model.SearchRequest,
      fieldName: Model.SearchField,
      order: 'ASC' | 'DESC'
    ) => {
      return async () =>
        DOC.documentSearchRequest({
          ...search,
          orderByFields: [
            {
              fieldName,
              order,
            },
          ],
        });
    };

    return (
      <MainLayout>
        <h1>
          <FormattedMessage id="headers.homeLong" />
        </h1>
        {this.state.savedSearchToBeDeleted &&
          this.confirmSavedSearchDeleteDialog()}

        <div className="grid gridCols1">
          {searchList.map((search) => (
            <ToggleArea
              key={search.name}
              title={search.name || ''}
              headerBarActions={
                <>
                  <IconDelete
                    onClick={(e) => {
                      e.stopPropagation();
                      if (search.name) {
                        this.setState({ savedSearchToBeDeleted: search.name });
                      }
                    }}
                  />
                  <IconEdit
                    onClick={(e) => {
                      e.stopPropagation();
                      openSearch(search);
                    }}
                  />
                  <IconUp
                    onClick={(e) => {
                      e.stopPropagation();
                      if (search.name) {
                        this.props.moveSearchUp(search.name);
                        this.props.saveUserSearches();
                      }
                    }}
                  />
                  <IconDown
                    onClick={(e) => {
                      e.stopPropagation();
                      if (search.name) {
                        this.props.moveSearchDown(search.name);
                        this.props.saveUserSearches();
                      }
                    }}
                  />
                </>
              }
            >
              <Async
                key={search.name}
                ms={300}
                placeholder={<LinearProgress />}
                loader={async () => {
                  const req = { ...search };
                  req.name = '';
                  return DOC.documentSearchRequest(req);
                }}
                render={(list, reload) => {
                  const resultsWithType = list
                    ? list.rows.filter((doc) => doc.type)
                    : [];
                  const resultType =
                    resultsWithType.length > 0
                      ? resultsWithType[0].type!
                      : 'document';
                  return (
                    <>
                      {list ? (
                        <SearchResultsTable
                          resultType={resultType}
                          estimatedCnt={list.estimatedCnt}
                          searchMainCategoryName={search.searchMainCategoryName}
                          defaultRowCount={20}
                          reload={reload}
                          onAskMoreData={() => {
                            reload(async () => {
                              const req = { ...search };
                              req.name = '';
                              req.cursor = {
                                offset: list.rows.length,
                                limit: 400,
                              };
                              const data = await DOC.documentSearchRequest(req);

                              return {
                                estimatedCnt: list.estimatedCnt,
                                rows: [...list.rows, ...data.rows],
                              };
                            });
                            // search.cursor = { offset: list.ro }
                          }}
                          contents={list.rows}
                          searchWithOrder={(field) => {
                            reload(
                              reloader(
                                search,
                                field,
                                search.name
                                  ? this.flipOrder(search.name)
                                  : 'ASC'
                              )
                            );
                          }}
                          handleRedirectToDocument={(document) =>
                            this.handleRedirectToDocument(
                              document,
                              resultType === 'document'
                            )
                          }
                          cols={search.cols}
                        />
                      ) : (
                        <FormattedMessage id="search.noSearchResults" />
                      )}
                    </>
                  );
                }}
              />
            </ToggleArea>
          ))}
        </div>
      </MainLayout>
    );
  }

  confirmSavedSearchDeleteDialog() {
    const t = (id: string, values?: {}) =>
      this.props.intl.formatMessage({ id }, values);
    const { savedSearchToBeDeleted } = this.state;
    const message = t('search.deleteSavedSearchMessage', {
      name: savedSearchToBeDeleted,
    });

    return (
      <Dialog
        className="confirmDialog"
        open={!!savedSearchToBeDeleted}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
      >
        <DialogTitle>
          <FormattedMessage id="search.deleteSavedSearchHeader" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText component={'div'}>{message}</DialogContentText>
        </DialogContent>
        <DialogActions className="dialogActions">
          <Button
            onClick={() => this.setState({ savedSearchToBeDeleted: undefined })}
          >
            <FormattedMessage id="buttons.cancel" />
          </Button>
          <Button
            onClick={() => {
              if (savedSearchToBeDeleted) {
                this.props.removeAndSaveUserSearches(savedSearchToBeDeleted);
                this.setState({ savedSearchToBeDeleted: undefined });
              }
            }}
          >
            <FormattedMessage id="buttons.delete" />
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const Home = appStatecontainer.StateConnector(
  container.StateConnector(AbstractHome)
);

export default injectIntl(Home);
