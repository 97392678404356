import * as React from 'react';
import * as container from '../../state/reducers/AdminState';
import { injectIntl, InjectedIntlProps } from 'react-intl';

import TextField from '../Common/TextField';
import PasswordField from '../Common/PasswordField';
import Button from '../Common/Button';
// import { Link } from 'react-router-dom';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { FormGroup } from '@material-ui/core';
// import { FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import Loading from '../Common/Loading';

interface INewUserProps extends container.IProps {}

interface INewUserState {
  email: string;
  password: string;
  firstname: string;
  lastname: string;
  organization: string;
  is_superuser: boolean;
  showPassword: boolean;
}

type NewUserProps = INewUserProps & InjectedIntlProps;

const initialState: INewUserState = {
  email: '@metti.fi',
  password: '',
  firstname: '',
  lastname: '',
  organization: '',
  is_superuser: false,
  showPassword: false,
};

class AbstractNewUser extends React.Component<NewUserProps, INewUserState> {
  constructor(props: NewUserProps) {
    super(props);

    this.state = {
      ...initialState,
    };
  }

  componentDidUpdate(prevProps: INewUserProps) {
    if (prevProps !== this.props && this.props.adminstatus.newuser.loaded) {
      this.resetState();
    }
  }

  resetState() {
    this.setState({ ...initialState });
  }

  onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ password: e.currentTarget.value });
  };

  onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ email: e.currentTarget.value });
  };

  onFirstnameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ firstname: e.currentTarget.value });
  };

  onLastnameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ lastname: e.currentTarget.value });
  };

  onOrganizationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ organization: e.currentTarget.value });
  };

  onIsAdminChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ is_superuser: !this.state.is_superuser });
  };

  handleSubmit = (e: any) => {
    this.props.createNewUser(this.state);
  };

  public render() {
    const t = this.props.intl.formatMessage;
    const adminstatus = this.props.adminstatus.newuser;
    const { loading, loaded } = adminstatus;
    return (
      <div className="newuser">
        {loading && (
          <Loading label={t({ id: 'messages.loading' })} loading={true} />
        )}
        {loaded && <div>{t({ id: 'newUser.userAdded' })}</div>}

        <div className="content">
          <ValidatorForm onSubmit={this.handleSubmit}>
            <TextField
              label={t({ id: 'userDetails.firstName' })}
              value={this.state.firstname}
              onChange={this.onFirstnameChange}
            />
            <TextField
              label={t({ id: 'userDetails.lastName' })}
              value={this.state.lastname}
              onChange={this.onLastnameChange}
            />
            <TextField
              label={t({ id: 'userDetails.organization' })}
              value={this.state.organization}
              onChange={this.onOrganizationChange}
            />
            <TextField
              label={t({ id: 'userDetails.email' })}
              value={this.state.email}
              onChange={this.onEmailChange}
            />
            <PasswordField
              name="password"
              label={t({ id: 'userDetails.password' })}
              value={this.state.password}
              onChange={this.onPasswordChange}
            />
            <FormGroup row={true}>
              {/* <FormControlLabel
                  control={
                    <Checkbox
                      value="admin"
                      checked={this.state.is_superuser}
                      onChange={this.onIsAdminChange}
                    />
                  }
                  label="Pääkäyttäjä"
                /> */}
              <Button color="primary" type="submit">
                {t({ id: 'newUser.addUser' })}
              </Button>
            </FormGroup>
          </ValidatorForm>
        </div>
      </div>
    );
  }
}

const NewUser = container.StateConnector(AbstractNewUser);

export default injectIntl(NewUser);
