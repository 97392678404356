import * as React from 'react';
import * as appState from '../../state/reducers/ApplicationState';
import * as documentState from '../../state/reducers/DocumentState';
import { injectIntl, InjectedIntlProps, FormattedMessage } from 'react-intl';

import MainLayout from '../../layout/MainLayout';

import * as Model from '../../../../shared/src/model/index';
import Loading from '../Common/Loading';
import DocumentTable from '../Common/DocumentTable';
import { Link, Redirect } from 'react-router-dom';
import Moment from 'react-moment';
import PlayArrow from '@material-ui/icons/PlayArrow';
import DocumentToolbar from '../Common/DocumentToolbar';
import Breadcrumbs from '../Common/Breadcrumbs';

interface IUploadDocumentsProps extends appState.IProps, documentState.IProps {}

interface IUploadDocumentsState {
  documentDocuments?: Model.AdminDocumentUpload;
  page: number;
  rowsPerPage: number;
  selectedDocumentIds: number[];
  selectedDWGFileName: string;
  showDWGInput: boolean;
  matchedRowsVisible: boolean;
  notMatchedRowsVisible: boolean;
  selectedDocuments: Model.UIDocument[];
  navigateToDocument: boolean;
  selectedDocument: number;
}

type UploadDocumentsProps = IUploadDocumentsProps & InjectedIntlProps;

class AbstractUploadDocuments extends React.Component<
  UploadDocumentsProps,
  IUploadDocumentsState
> {
  constructor(props: UploadDocumentsProps) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 10,
      selectedDocumentIds: [],
      selectedDWGFileName: '',
      showDWGInput: false,
      matchedRowsVisible: false,
      notMatchedRowsVisible: false,
      selectedDocuments: [],
      navigateToDocument: false,
      selectedDocument: 0,
    };
  }

  handleRedirectToDocument = (id: number) => {
    this.setState({
      selectedDocument: id,
      navigateToDocument: true,
    });
  };

  handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
  };

  public render() {
    const { loading } = this.props.docstatus.uploadDesignerDocuments;
    const t = this.props.intl;
    const loadingCollection = this.props.docstatus.uploadDocumentCollection
      .loading;

    const saveProgress = `${this.props.uploadResults.length}/${
      this.props.uploadDocumentAmount
    }`;

    const matchedRows =
      this.props.documentCollection &&
      this.props.documentCollection.matchedRows;
    const notMatchedRows =
      this.props.documentCollection &&
      this.props.documentCollection.notMatchedRows;

    if (this.state.navigateToDocument) {
      const id = this.state.selectedDocument;
      return (
        <Redirect
          to={{
            pathname: `/documents/${id}`,
            state: {
              document: this.props.searchResults
                .filter(doc => doc.id === id)
                .pop(),
            },
          }}
          push={true}
        />
      );
    }
    return (
      <MainLayout>
        <Breadcrumbs>
          <Link to="/">
            <FormattedMessage id="headers.home" />
          </Link>
          <FormattedMessage id="headers.documents" />
          <FormattedMessage id="upload.savedDocumentsHeader" />
        </Breadcrumbs>

        <h1>
          <FormattedMessage id="upload.savedDocumentsHeader" />
        </h1>

        <div className="grid gridCols1">
          <Loading
            loading={loading || loadingCollection}
            label={
              loadingCollection
                ? t.formatMessage({
                    id: 'upload.updatingDocumentsFromCollection',
                  })
                : `${t.formatMessage({
                    id: 'upload.saving',
                  })}... ${saveProgress}`
            }
          />

          <DocumentTable
            actionComponents={[
              <DocumentToolbar
                key={'toolbar1'}
                selectedDocuments={this.state.selectedDocuments}
              />,
            ]}
            setSelected={ids => {
              this.setState({
                selectedDocuments: this.props.uploadResults.filter(doc => {
                  return ids.indexOf(doc.id) >= 0;
                }),
              });
            }}
            hasSelect={true}
            title={<FormattedMessage id="upload.savedDocumentsHeader" />}
            rows={this.props.uploadResults.map(row => ({
              ...row,
              aItems: (
                <span>{row.aItems.map(item => item.name).join(', ')}</span>
              ),
              pItems: (
                <span>{row.pItems.map(item => item.name).join(', ')}</span>
              ),
              created: (
                <span>
                  <Moment date={row.created} format="DD.MM.YYYY HH:mm" />
                </span>
              ),
            }))}
            keys={{
              name: 'Title',
              aItems: 'Contracts',
              pItems: 'Buildings',
              revision: 'Revision',
              created: 'Created',
              dwgName: 'DWG',
              workflowStateName: 'Status',
            }}
            rowClick={row => this.handleRedirectToDocument(row.id)}
          />

          {matchedRows && (
            <div className="matches component">
              <h2
                onClick={() =>
                  this.setState({
                    matchedRowsVisible: !this.state.matchedRowsVisible,
                  })
                }
                className={`toggleable ${
                  this.state.matchedRowsVisible ? 'open' : ''
                }`}
              >
                <PlayArrow />
                <FormattedMessage
                  id="upload.matchedRows"
                  values={{ amount: matchedRows.length }}
                />
              </h2>
              {this.state.matchedRowsVisible && (
                <ol>
                  {matchedRows.map(
                    (row: Model.DocumentListingRow, index: number) => (
                      <li key={`doc_${index}`}>
                        <span title={row.fileName}>{row.fileName}</span>
                        <span title={row.content}>{row.content}</span>
                        <Link
                          style={{ float: 'right' }}
                          to={`/documents/${row.updatedDocumentId}`}
                        >
                          Open
                        </Link>
                      </li>
                    )
                  )}
                </ol>
              )}
            </div>
          )}

          {notMatchedRows && (
            <div className="matches component">
              <h2
                onClick={() =>
                  this.setState({
                    notMatchedRowsVisible: !this.state.notMatchedRowsVisible,
                  })
                }
                className={`toggleable ${
                  this.state.notMatchedRowsVisible ? 'open' : ''
                }`}
              >
                <PlayArrow />
                <FormattedMessage
                  id="upload.notMatchedRows"
                  values={{ amount: notMatchedRows.length }}
                />
              </h2>
              {this.state.notMatchedRowsVisible && (
                <ol>
                  {notMatchedRows.map(
                    (row: Model.DocumentListingRow, index: number) => (
                      <li key={`doc_${index}`}>
                        <span>{row.fileName}</span>
                        <span>{row.dwgFileName}</span>
                      </li>
                    )
                  )}
                </ol>
              )}
            </div>
          )}
        </div>
      </MainLayout>
    );
  }
}

const UploadDocuments = documentState.StateConnector(
  appState.StateConnector(AbstractUploadDocuments)
);

export default injectIntl(UploadDocuments);
