import translationsEn from './en';
import brandVariables from './brandVariables';

const bv = brandVariables['fi'];

const translations: { [K in keyof typeof translationsEn]: string } = {
  'generics.header.pitem': 'Kohteet',
  'generics.aitem.is_hidden': 'Piilotettu',
  'generics.aitem.name': 'Nimi',
  'generics.aitem.name_long': 'Urakan kuvaus',
  'generics.pitem.name': 'Tunnus',
  'generics.pitem.name_long': 'Kohteen kuvaus',
  'generics.pitem.route_start_pos': 'Alkupaalu',
  'generics.pitem.route_end_pos': 'Loppupaalu',
  'generics.pitem.specifier': 'Laitekoodi',
  'generics.pitem.warranty_expires': 'Takuuaika',
  'generics.pitem.additional_information': 'Lisätieto 1',
  'generics.pitem.additional_information2': 'Lisätieto 2',
  'generics.pitem.is_tracked': 'Seurannassa',
  'generics.pitem.is_tracked.setTrue': 'Merkitse seurantaan',
  'generics.pitem.is_tracked.setFalse': 'Poista seurannasta',
  'generics.pitem.upper_equipment_id': 'Päälaite',
  'generics.pitem.secondary_hierarchy_id': 'Muu hierarkia',
  'generics.pitem.criticality_class_id': 'Prioriteetti',
  'generics.pitem.area_id': 'Huoltoalue',
  'generics.pitem.systems_path': 'Järjestelmä',
  'generics.pitem.floor': 'Kerros',
  'generics.pitem.room': 'Huone',
  'generics.pitem.building': 'Rakennusosa',
  'generics.pitem.work_aitems': 'Urakat',
  'generics.pitem.pitemtypes': 'Kohdetyypit',
  'generics.pitem.target': 'Kohde',
  'generics.pitem.polerange': 'Paaluväli',
  'generics.pitem.parents': 'Ylikohteet',
  'generics.pitem.created_by_user': 'Lisääjä',
  'headers.emails': 'Sähköpostiasetukset',
  'summary/status': 'Yhteenveto',
  'summary/review': 'Tarkastusta odottavat',
  'summary/accept': 'Hyväksyntää odottavat',
  'summary/rejected': 'Hylätyt urakoitsijan dokumentit',
  'summary/newContractorDocs': 'Uudet hyväksytyt tilaajan dokumentit',
  parentName: 'Ylikategoria',
  name: 'Nimi',
  workflowId: 'Työnkierron nimi',
  'wf.DEFAULT':
    'Tilaajan dokumentit: Suunnittelija tarkastaa, tilaaja hyväksyy',
  'wf.URAK_DP': 'Urakoitsijan dokumenttipaketin työnkierto',
  'wf.DESIGNER_APPROVES':
    'Urakoitsijan dokumentit: Suunnittelija tarkastaa ja hyväksyy',
  'wf.DESIGNER_AND_INSPECTOR':
    'Urakoitsijan dokumentit: Suunnittelija tarkastaa, tilaaja hyväksyy',
  'wf.INSPECTOR_APPROVES':
    'Urakoitsijan dokumentit: Tilaaja tarkastaa ja hyväksyy',
  'wf.NO_WORKFLOW': 'Hyväksytty lisäyshetkellä',
  'wf.TA_INSPECTOR_APPROVES':
    'Tarkastusasiakirja: Valvoja tarkastaa ja hyväksyy',
  'wf.TA_DESIGNER_APPROVES':
    'Tarkastusasiakirja: Suunnittelija tarkastaa ja hyväksyy',
  'wf.TA_DESIGNER_INSPECTOR_APPROVES':
    'Tarkastusasiakirja: Suunnittelija tarkastaa, valvoja hyväksyy',

  'categories.documentCategories': 'Documenttikategoriat',
  'categories.crerateNewCategory': 'Luo uusi kategoria kategoriaan',
  'categories.designDisciplines': 'Suunnittelualat',
  'categories.categorySuccesfullyCrerated':
    'Uusi kategoria onnistuneesti luotu',
  'categories.changeWorkflow': 'Valitse työnkierto',
  'categories.changeWorkflowNotice':
    'Change of the workflow only affects new documents',
  'categories.workflowChanged':
    'Workflow of the category was successfully changed.',
  'categories.workPhases': 'Työlajit',
  'categories.contractorDocumentTypes': 'Urakoitsijan dokuumentit',
  'adminTools.moveToEam': 'Siirrä huoltokirjaan',
  'adminTools.categories': 'Kategoriat',
  'adminTools.removeContract': 'Poista urakka',
  'adminTools.removeContractSuccess': 'Urakka poistettu onnistuneesti',
  'adminTools.removeContractFail':
    'Urakan poisto epäonnistui, et voi poistaa urakkaa jolle on liitettynä dokukmentteja',
  'adminTools.docToSystem': 'Järjestelmät',
  'adminTools.docs': 'Dokumentit',
  'adminTools.building': 'Rakennusosat',
  'adminTools.sync': 'Synkronointi',
  'adminTools.selectDocsToSystemsExcel':
    'Valitse järjestelmät dokumentteihin -Excel',
  sync: 'Synkronointi',
  'terms.customer': 'Tilaaja',
  'messages.loading': 'Ladataan...',
  'buttons.edit': 'Muokkaa',
  'buttons.save': 'Tallenna',
  'buttons.search': 'Hae',
  'buttons.cancel': 'Peruuta',
  'buttons.open': 'Avaa',
  'buttons.delete': 'Poista',
  'buttons.close': 'Sulje',
  'buttons.downloadFiles': 'Lataa tiedostot',
  'buttons.filter': 'Suodata',
  'buttons.addComment': 'Kommentoi',
  'buttons.add': 'Lisää',
  'buttons.markAsIncomplete': 'Merkitse puutteeliseksi',
  'buttons.setAsTracked': 'Merkitse seurantaan',
  'buttons.unsetAsTracked': 'Poista seurannasta',
  'buttons.update': 'Päivitä',
  'buttons.setReplacement': 'Aseta korvaava dokumentti',
  'buttons.clearReplacement': 'Poista korvaava dokumentti',
  'buttons.defineReplacement': 'Määritä korvaavuus',
  'buttons.changeReplacement': 'Muuta korvaavuutta',
  'buttons.findSimilarDocs': 'Hae kohteet, joihin dokumentti liittyy',
  'headers.home': `${bv.applicationName}`,
  'headers.homeLong': `${bv.applicationLongName}`,
  'headers.inspectionDocument': 'Tarkastusasiakirja',
  'headers.managementTools': 'Hallintatyökalut',
  'headers.waitingForReview': 'Odottaa tarkastusta',
  'headers.documents': 'Dokumentit',
  'headers.document': 'Dokumentti',
  'headers.documentPackage': 'Dokumenttipaketti',
  'headers.item': 'Rakennustuote tai materiaali',
  'headers.addContractorDocuments': 'Lisää urakoitsijan dokumentteja',
  'headers.users': 'Käyttäjät',
  'headers.user': 'Käyttäjä',
  'headers.PersonalInfo': 'Omat tiedot',
  'headers.roles': 'Roolit',
  'headers.contracts': 'Urakat',
  'headers.approvalCycles': 'Hyväksyntäkierrot',
  'headers.areas': 'Alueet',
  'headers.misc': 'Muut',
  'headers.sentEmails': 'Lähetetyt sähköpostit',
  'login.forgotPassword': 'Unohditko salasanasi?',
  'login.header': 'Kirjautuminen',
  'login.login': 'Kirjaudu',
  'login.password': 'Salasana',
  'login.username': 'Käyttäjänimi',
  'login.loginError': 'Väärä käyttäjänimi tai salasana',
  'login.GDPRLink': 'Tietosuojaseloste',
  'login.systemDown':
    'Järjestelmässä on huoltokatko. Kirjautuminen on estetty.',
  'mainnavigation.logout': 'Kirjaudu ulos',
  'passwordReset.backToLogin': 'Takaisin kirjautumissivulle',
  'passwordReset.email': 'Sähköposti',
  'passwordReset.header': 'Vaihda salasana',
  'passwordReset.instructions':
    'Anna sähköpostiosoite, jolla olet aiemmin kirjautunut järjestelmään. Sinulle lähetetään tähän osoitteeseen linkki, jonka kautta pääset asettamaan salasanasi uudestaan',
  'passwordReset.messageSent':
    'Salasanan palautuslinkki lähetetty sähköpostiisi.',
  'passwordReset.passwordOld': 'Nykyinen salasana',
  'passwordReset.passwordNew': 'Uusi salasana',
  'passwordReset.passwordNew2': 'Toista uusi salasana',
  'passwordReset.requirements':
    'Salasanan on oltava vähintään 8 merkkiä pitkä ja sen pitää sisältää isoja ja pieniä kirjaimia, sekä numeroita. \n\nSalasanassa ei voi käyttää välilyöntejä.',
  'passwordReset.requirements.eightCharacters': 'Vähintään 8 merkkiä',
  'passwordReset.requirements.hasCapitalLetters':
    'Salasanassa on oltava isoja kirjaimia',
  'passwordReset.requirements.hasLowercaseLetters':
    'Salasanassa on oltava pieniä kirjaimia',
  'passwordReset.requirements.hasNumbers': 'Salasanassa on oltava numeroita',
  'passwordReset.requirements.isPasswordMatch':
    'Salasanojen pitää vastata toisiaan',
  'passwordReset.requirements.noSpaces': 'Ei saa sisältää välilyöntejä',
  'passwordReset.requirements.required': 'Pakollinen kenttä',
  'passwordReset.returnToLoginPage': 'Palaa kirjautumissivulle',
  'passwordReset.submit': 'Vaihda',
  'passwordReset.submitEmail': 'Lähetä',
  'passwordReset.success': 'Salasana vaihdettu',
  'passwordReset.invalidCredentials': 'Väärä salasana',
  'passwordReset.tokenExpired':
    'Salasanan palautuslinkki on vanhentunut. Ole hyvä ja tilaa uusi palautuslinkki.',
  'passwordReset.invalidToken': 'Virheellinen palautuskoodi',
  'passwordReset.userHasNoPermissions': `Teillä ei ole oikeuksia METTI-järjestelmään.
  Saitte mahdollisesti virheellisen viestin. Pahoittelemme häiriötä.`,
  'search.selectTarget': 'Valitse haun kohde',

  // Kohteen tiedot
  'pitem.code': 'Tunnus',
  'pitem.description': 'Kuvaus',
  'pitem.workAitems': 'Urakat',
  'pitem.target': 'Kohde',
  'pitem.targets': 'Kohteet',
  'pitem.isTracked': 'Seurannassa',

  // haun valikon pääkategoriat
  PITEMS: 'Kohteet',
  TARKASTUSASIAKIRJA: 'Tarkastusasiakirja',
  TIL_DOC: 'Tilaajan dokumentit',
  URAK_DOC: 'Urakoitsijan dokumentit',
  URAK_DP: 'Urakoitsijan dokumenttipaketti',

  'search.header': 'Haku',
  'search.clear': 'Tyhjennä',
  'search.resultsHeader': 'Hakutulokset',
  'search.categoryId': 'Dokumentin tyyppi',
  'search.freeTextSearch': 'Vapaasanahaku',
  'search.searchFromContent': 'Hae dokumenttien sisällöstä',
  'search.addSearchCondition': '+ Lisää hakuehtoja',
  'search.hideSearchConditions': '- Piilota hakuehdot',
  'search.SelectSearchCondition': 'Valitse hakuehto',
  'search.savedSearchHeader': 'Haun tallentaminen',
  'search.savedSearchName': 'Haun nimi',
  'search.deleteSavedSearchHeader': 'Tallennetun haun poisto',
  'search.deleteSavedSearchMessage':
    'Olet poistamassa tallennettua hakua "{name}". Haluatko jatkaa?',
  'search.noSearchResults': 'Ei hakutuloksia',
  'search.saveSuccess': 'Haku tallennettu onnistuneesti!',
  'search.columns': 'Valitse hakutulosten sarakkeet',
  'search.downloadAsZip': 'Lataa Zip-tiedostona',
  'search.downloadAsSpreadsheet': 'Lataa Excel-dokumenttina',
  'warning.documentNotFound': 'Dokumenttia ei löytynyt',
  'warning.invalidAdminPassword': 'Antamasi salasana oli virheellinen',
  'warning.invalidNumber': 'Virheellinen numero',
  'warning.numberMustBePositive': 'Numeron on oltava suurempi kuin nolla',
  'warning.update.denied': 'Päivittäminen kielletty',
  'documentMeta.inspectionDocTarget': 'Kohde',
  'documentMeta.saved': 'Tallennettu',
  'documentMeta.workPhases': 'Työvaiheet',
  'documentMeta.workPhase': 'Työvaihe',
  'documentMeta.addWorkphase': 'Lisää työvaihe',
  'documentMeta.chooseUploadMethod': 'Valitse lisäystapa',
  'documentMeta.rejectReason': 'Syy dokumentin hylkäykselle',
  'documentMeta.name': 'Aihe',
  'documentMeta.category': 'Kategoria',
  'documentMeta.designDiscipline': 'Suunnitteluala',
  'documentMeta.contractorDocumentType': 'Dokumenttityyppi',
  'documentMeta.isTracked': 'Seurannassa',
  'documentMeta.inspectionDocumentAttachmentDocumentType': 'Dokumenttityyppi',
  'documentMeta.fileType': 'Tiedostomuoto',
  'documentMeta.fileName': 'Dokumentti',
  'documentMeta.documentCategory': 'Dokumenttityyppi',
  'documentMeta.pitemTypes': 'Kohdetyypit',
  'documentMeta.documentType': 'Dokumentin tyyppi',
  'documentMeta.createdByUser': 'Lisääjä',
  'documentMeta.pitemTypeName': 'Kohdetyyppi',
  'documentMeta.modifiedByUser': 'Muokkaaja',
  'documentMeta.created': 'Lisätty',
  'documentMeta.modified': 'Muokattu',
  'documentMeta.location': 'Sijainti',
  'documentMeta.pitemTree': 'Sijainti',
  'documentMeta.floor': 'Kerros',
  'documentMeta.target': 'Rakennustarvike- tai tuote',
  'documentMeta.targetName': 'kohdetunnus',
  'documentMeta.targetcode': 'Tunnus',
  'documentMeta.projectPhase': 'Osuus',
  'documentMeta.contract': 'Urakka',
  'documentMeta.poleInterval': 'Paaluväli',
  'documentMeta.startPole': 'Alkupaalu',
  'documentMeta.endPole': 'Loppupaalu',
  'documentMeta.pole': 'Paalu',
  'documentMeta.polerange': 'Paaluväli',
  'documentMeta.building': 'Rakennus',
  'documentMeta.buildingElement': 'Rakennusosa',
  'documentMeta.inspectionDocument': 'Tarkastusasiakirja',
  'documentMeta.room': 'Huone',
  'documentMeta.roomName': 'Tilan nimi',
  'documentMeta.roomId': 'Huonetunnus',
  'documentMeta.buildingElementPlaceholder': 'Valitse rakennusosa',
  'documentMeta.area': 'Alue',
  'documentMeta.system': 'Järjestelmä',
  'documentMeta.areas': 'Alueet',
  'documentMeta.revision': 'Revisio',
  'documentMeta.aItems': 'Urakka',
  'documentMeta.pItems': 'Rakennusosa',
  'documentMeta.dwgName': 'DWG',
  'documentMeta.fileSize': 'Koko',
  'documentMeta.fileExtension': 'Tiedostopääte',
  'documentMeta.workflowStateName': 'Dokumentin tila',
  'documentMeta.categories': 'Tyyppi',
  'documentMeta.pitemType': 'Tyyppi',
  'documentMeta.targetTypes': 'Kohdetyypit',
  'documentMeta.dwg': 'DWG',
  'documentMeta.version': 'Versio',
  'documentMeta.workAitems': 'Urakat',
  'documentMeta.versionNumber': 'Versio',
  'documentMeta.workflowState': 'Dokumentin tila',
  'documentMeta.description': 'Aihe',
  'documentMeta.downloadLink': 'Lataa',
  'documentMeta.statusHistory': 'Statushistoria',
  'documentMeta.versionHistory': 'Versiohistoria',
  'documentMeta.reasonForRejection': 'Hylkäyksen syy',
  'documentMeta.documentLink': 'Linkittyvä tiedosto',
  'documentMeta.updateRevision': 'Uusi revisio',
  'documentMeta.SUBMITTED': 'Odottaa tarkastusta',
  'documentMeta.INITIAL_ACCEPT': 'Odottaa hyväksyntää',
  'documentMeta.ACCEPTED': 'Hyväksytty',
  'documentMeta.REJECTED': 'Hylätty',
  'documentMeta.INIT': 'Alustettu',
  'documentMeta.HAS_DOC': 'Dokumentteja lisätty',
  'documentMeta.ALL': 'Kaikki',
  'documentMeta.reasonForReject': 'Anna hylkäyksen syy',
  'documentMeta.removeSuccess': 'Dokumentit poistettu onnistuneesti',
  'documentMeta.acceptSuccess': 'Dokumenttien hyväksyntä onnistui',
  'documentMeta.selectType': 'Valitse dokumentin tyyppi',
  'documentMeta.multipleDocuments': 'Useita dokumentteja',
  'documentMeta.assortedDocuments': 'Yksittäisiä dokumentteja',
  'documentMeta.updateMetadata': 'Päivitä vain ominaisuustiedot',
  'documentMeta.designerDocumentType': 'Dokumenttityyppi',
  // Contractor work type was called "Työlaji" previously
  'documentMeta.contractorWorktype': 'Työvaihe', // Both spellings are needed
  'documentMeta.contractorWorkType': 'Työvaihe',
  'documentMeta.locationLongname': 'Tilan nimi',
  'documentMeta.documentHasNewVersion':
    'Dokumentista on olemassa uudempi versio. Katso uusin versio versiohistoriasta.',
  INITIAL_ACCEPT: 'Merkitse tarkastetuksi',
  ACCEPT: 'Hyväksy',
  ACCEPT_SUBMIT: 'Hyväksy',
  REJECT: 'Hylkää',
  REJECT2: 'Hylkää',
  'documentMeta.confirmInitialAccept':
    'Haluatko varmasti merkitä valitut dokumentit tarkastetuksi?',
  'documentMeta.confirmAccept':
    'Haluatko varmasti merkitä valitut dokumentit hyväksytyiksi?',
  'documentMeta.documentPackageError': 'Virheellinen dokumenttipaketti',
  'documentMeta.noDocumentPackages': 'Ei dokumenttipaketteja',
  'documentMeta.documentPackageAutomaticRejection':
    'Dokumenttipaketti on hylätty automaattisesti, koska se sisältää hylättyjä dokumentteja',
  'documentMeta.replacementDocument': 'Korvaava dokumentti',
  'documentMeta.name_long': 'Kohteen kuvaus',
  'documentMeta.specifier': 'Laitekoodi',
  'documentMeta.criticality_class': 'Prioriteetti',
  'documentMeta.warranty_expires': 'Takuuaika',
  'documentMeta.upper_equipment': 'Päälaite',
  'documentMeta.secondary_hierarchy': 'Muu hierarkia',
  'documentMeta.area_name': 'Huoltoalue',
  'documentMeta.additional_information': 'Lisätieto 1',
  'documentMeta.addtional_information2': 'Lisätieto 2',
  'documentMeta.similarDocumentsNotFound':
    'Samankaltaisia dokumentteja ei löytynyt',
  'itemMeta.itemType': 'Kohdetyyppi',
  'settings.header': 'Asetukset',
  'settings.savedSearchesHeader': 'Tallennetut haut',
  'settings.agreementsHeader': 'Hyväksytyt käyttöehdot',
  'settings.sendMails': 'Lähetä minulle sähköpostikoosteita',
  'settings.settings': 'Asetukset',
  'settings.userLanguage': 'Kieli',
  'settings.selectUserLanguage': 'Ladataan...',
  'settings.save': 'Tallenna',
  'settings.noPrivilegesGranted': 'Sinua ei ole liitetty yhteenkään urakkaan',
  'settings.support': 'Apu',
  'settings.userManual': 'Lataa ohjeet',
  'settings.supportMessage': `
Jos kaipaat apua järjestelmän käyttöön tai olet kohdannut järjestelmävirheen, ole yhteydessä Metti-tukipalveluun:
Sähköpostiosoite:
tuki@lansimetro.fi

Metti-tuki palvelee myös arkisin 9-15 numerossa 09 747 91199
  `,
  'upload.save': 'Tallenna',
  'upload.waiting': 'Tiedostoja siirretään...',
  'upload.ready': 'Tallennus valmis',
  'upload.selectDocumentCollection': 'Valitse asiakirjaluettelo',
  'upload.changeDocumentCollection': 'Vaihda asiakirjaluettelo',
  'upload.provideMetadata': 'Syötä metatiedot',
  'upload.addDocuments': 'Lisää dokumentteja',
  'upload.document.type': 'Tyyppi',
  'upload.document.type.placeholder': 'Valitse dokumentin tyyppi',
  'upload.document.contract': 'Urakka',
  'upload.document.contract.placeholder': 'Valitse urakka',
  'upload.document.subcontract': 'Aliurakka',
  'upload.document.subcontract.placeholder': 'Valitse aliurakoita',
  'upload.document.item': 'Kohde',
  'upload.document.item.placeholder': 'Valitse kohteet',
  'upload.document.addItem': 'Lisää toinen kohde',
  'upload.addMetadata': 'Lisää omainaisuustiedot',
  'upload.dropzoneInfo': 'Pudota dokumentit tähän',
  'upload.dropzoneInfoSingle': 'Pudota dokumentti tähän',
  'upload.loadingCollection': 'Luodaan asiakirjaluetteloa',
  'upload.documentCollection': 'Päivitettävät tiedot',
  'upload.uploadDocumentCollection': 'Tallennettavat dokumentit',
  'upload.updateDocumentCollection': 'Päivitä vain ominaisuustiedot',
  'upload.savedDocumentsHeader': 'Lisätyt dokumentit',
  'upload.saving': 'Tallennetaan',
  'upload.updatingDocumentsFromCollection':
    'Päivitetään dokumenttien tietoja asiakirjaluettelosta',
  'upload.documentsSuccessful': 'Dokumentit tallennettu onnistuneesti',
  'upload.collectionSuccessful': 'Asiakirjaluettelo tallennettu onnistuneesti',
  'upload.matchedRows':
    'Asiakirjaluettelon perusteella päivitetyt dokumentit ({amount} kpl)',
  'upload.notMatchedRows': 'Puuttuvat dokumentit ({amount} kpl)',
  'upload.selectedDocumentAmount': 'Valitut dokumentit: {amount} kpl',
  'upload.uploadContractorDocuments': 'Urakoitsijan dokumentteja',
  'upload.uploadDesignerDocumentsSuccess':
    '{amount} tiedoston lisääminen onnistui.',
  'upload.uploadDesignerDocumentsExists':
    'Sama versio oli jo olemassa: {filenames}',
  'documentTable.selected': 'Valittu',
  'documentTable.delete': 'Poista',
  'documentTable.selectDWG': 'Valitse DWG',
  'documentTable.labelRowsPerPage': 'Rivejä kerrallaan',
  'downloadBasket.addToBasket': 'Lisää latauskoriin',
  'downloadBasket.backToBasket': 'Takaisin latauskoriin',
  'downloadBasket.title': 'Latauskori',
  'downloadBasket.file': 'Tiedosto',
  'downloadBasket.fileType': 'Tiedoston tyyppi',
  'downloadBasket.empty': 'Ei lisättyjä dokumentteja',
  'downloadBasket.clear': 'Tyhjennä',
  'downloadBasket.downloadAll': 'Lataa kaikki',
  'downloadBasket.toPrintOrder': 'Tilaa tulosteita',
  'downloadBasket.someNonPrintable':
    'Osa tiedostoista on muussa kuin pdf formaatissa. Vain pdf-tiedostot lisätään tulostetilaukseen.',
  'userDetails.firstName': 'Etunimi',
  'userDetails.lastName': 'Sukunimi',
  'userDetails.email': 'Sähköposti',
  'userDetails.organization': 'Organisaatio',
  'userDetails.password': 'Salasana',
  'userDetails.phoneNumber': 'Puhelinnumero',
  'printOrder.title': 'Tulosteiden tilaus',
  'printOrder.basketEmpty': 'Latauskori on tyhjä.',
  'printOrder.step1': 'Tiedot',
  'printOrder.step2': 'Dokumentit',
  'printOrder.step3': 'Yhteenveto',
  'printOrder.step4': 'Valmis',
  'printOrder.placeHolder': 'placeholder',
  'printOrder.ordererInfo': 'Tilaajan tiedot',
  'printOrder.orderer': 'Tilaaja',
  'printOrder.project': 'Työn nimi/projekti',
  'printOrder.payerCompany': 'Maksajayritys',
  'printOrder.streetAddress': 'Katuosoite',
  'printOrder.orderAdditionalInfo': 'Lisätiedot koko tilaukseen',
  'printOrder.payerInfo': 'Maksajan tiedot',
  'printOrder.ordererContact': 'Tilaajan yhteyshenkilö',
  'printOrder.generalInfo': 'Tilauksen esitiedot',
  'printOrder.postalCode': 'postinumero',
  'printOrder.city': 'Kaupunki',
  'printOrder.country': 'Maa',
  'printOrder.orderRows': 'Dokumentit',
  'printOrder.amountsAndFoldings': 'Jakelun kappalemäärä ja käsittely',
  'printOrder.allDocuments': 'Kaikki dokumentit',
  'printOrder.deliveryDestination': 'Toimitusosoite',
  'printOrder.additionalInfo': 'Lisätiedot',
  'printOrder.deliverToCompany': 'Yritys / Yksikkö',
  'printOrder.deliveryMethod': 'Toimitustapa',
  'printOrder.next': 'Seuraava',
  'printOrder.previous': 'Edellinen',
  'printOrder.amount': 'Kpl',
  'printOrder.totalAmount': 'Kappalemäärä',
  'printOrder.document': 'Dokumentti',
  'printOrder.handling': 'Käsittely',
  'printOrder.addToOrder': 'Lisää tilaukseen',
  'printOrder.documentsInOrder':
    'Tilaukseen lisätyt dokumentit, käsittelyt, kappalemäärät, lisätiedot ja toimitusosoitteet',
  'printOrder.noDocumentsInOrder':
    'Ei lisättyjä dokumentteja. Lisää tilaukseen vähintään yksi dokumentti jatkaaksesi.',
  'printOrder.sendOrder': 'Lähetä tilaus',
  'printOrder.done': 'Tilaus tallennettu.',
  'printOrder.delivery.DeliveryGuy': 'Lähetti',
  'printOrder.delivery.Post': 'Posti',
  'printOrder.folding.Folded210mm': 'Taitettuna 210 mm',
  'printOrder.folding.Folded190mm': 'Taitettuna 190 mm',
  'printOrder.folding.Folded190mmSpine': 'Taitettuna 190 mm + Seläke',
  'printOrder.folding.Folded210mmSpine': 'Taitettuna 210 mm + Seläke',
  'printOrder.folding.Folded19_21': 'Taitettuna 19/21 mappitaitto',
  'printOrder.folding.StraightCopy': 'Suorana',
  'printOrder.folding.OriginalsReturn': 'Alkuperäisten nouto ja palautus',
  'printOrder.folding.PtintHotlaminate': 'Tuloste + kuumalaminointi',
  'printOrder.folding.Weatherproof': 'Säänkestävä (Tyvek)',
  'printOrder.folding.PrintPlastic': 'Tulostus muoville',
  'printOrder.folding.Scanning': 'Skannaus',
  'printOrder.folding.OtherHandling': 'Muu käsittely',
  'printOrder.errors.email': 'Sähköposti virheellinen',
  'printOrder.colorPrint': 'Värituloste',
  'error.unauthorized': 'Kirjautuminen vanhentunut',
  'error.documentNotFound': 'Dokumenttia ei löytynyt',
  'error.someDocumentsFailedToUpload':
    'Kaikkia dokumentteja ei pystytty tallentamaan onnistuneesti',
  'error.documentUploadFailed': 'Dokumentin {name} tallennus epäonnistui',
  'error.collectionUploadFailed':
    'Asiakirjaluettelon {name} tallennus epäonnistui',
  'error.fileSizeLimitExceeded':
    'Lisääminen epäonnistui. Yksittäisen dokumentin maksimikoko on 50Mt',
  'error.tooManyResults':
    'Haku palautti liikaa hakutuloksia. Ole hyvä ja tarkenna hakua.',
  'agreement.continue': 'Jatka',
  'agreement.accept': 'Hyväksyn ehdot',
  'agreement.close': 'Sulje',
  'agreement.agreed': 'Hyväksytty',
  'documentPreview.preview': 'Esikatselu',
  'documentPreview.previewNotAvailable': 'Esikatselu ei saatavilla',
  'documentPreview.error': 'Esikatselun lataus epäonnistui',
  'documentPreview.noData': 'Tiedostoa ei valittu',
  'documentPreview.page': 'Sivu',
  'documentPreview.page.error': 'Sivun lataus epäonnistui',
  'documentPreview.page.noData': 'Sivua ei valuttu',
  'documentPreview.page.prev': 'Edellinen',
  'documentPreview.page.next': 'Seuraava',
  'newUser.userAdded': 'Käyttäjä lisätty',
  'newUser.addUser': 'Lisää käyttäjä',
  // LDAP translations
  'Invalid Credentials': 'Virheellinen salasana',
  'Entry Already Exists': 'Tunnus on jo olemassa',
  'New user created': 'Uusi käyttäjä luotu',
  'inspectionDocuments.withoutDocuments': 'Asiakirja on luotu ilman liitteitä',
  'inspectionDocuments.documentsAdded': 'Liitteet on lisätty',
  'inspectionDocuments.documentsAccepted': 'Liitteet on hyväksytty',
  'inspectionDocuments.documentsRejected': 'Liitteitä on hylätty',
  'inspectionDocuments.viewBuildingPermits': 'Listaa rakennusluvat',
  'inspectionDocuments.buildingPermitsDialogHeader': 'Urakan rakennusluvat',
  'inspectionDocuments.adminView': 'Hallitse tarkastusasiakirjoja',
  'inspectionDocuments.adminViewHeader': 'Tarkastusasiakirjojen hallinta',
  'inspectionDocuments.newInspectionDocument': 'Uusi Tarkastusasiakirja',
  'inspectionDocuments.excelImportLabel':
    'Tuo Tarkastusasiakirjan tiedot Excelillä',
  'inspectionDocuments.documentName': 'Tarkastusasiakirjan nimi',
  'inspectionDocuments.inspectionDocuments': 'Tarkastusasiakirjat',
  'inspectionDocuments.target': 'Tarkastuksen kohde',
  'inspectionDocuments.targets': 'Kohteet',
  'inspectionDocuments.comment': 'Kommentti',
  'inspectionDocuments.comments': 'Kommentit',
  'inspectionDocuments.reason': 'Syy',
  'inspectionDocuments.inspectors': 'Tarkastajat',
  'inspectionDocuments.workflow': 'Hyväksyntäkierto',
  'inspectionDocuments.addDocuments': 'Lisää dokumentteja',
  'inspectionDocuments.document': 'Dokumentti',
  'inspectionDocuments.contract': 'Urakka',
  'inspectionDocuments.designer': 'Suunnittelija',
  'inspectionDocuments.supervisor': 'Valvoja',
  'inspectionDocuments.inspectionType': 'Tarkastusalue',
  'inspectionDocuments.workflowStateName': 'Tila',
  'inspectionDocuments.content': 'Sisältö',
  'inspectionDocuments.inspectionContent': 'Tarkastuksen sisältö',
  'inspectionDocuments.cancel': 'Peruuta',
  'inspectionDocuments.save': 'Tallenna',
  'inspectionDocuments.created': 'Luotu',
  'inspectionDocuments.modified': 'Muokattu',
  'inspectionDocuments.noComments': 'Ei kommentteja',
  'inspectionDocuments.subject': 'Otsikko',
  'inspectionDocuments.targetArea': 'Tarkastusalue',
  'inspectionDocuments.confirmTargetAreaDeletion':
    'Olet poistamassa tarkastusaluetta. Et voi perua tätä toimintoa.',
  'inspectionDocuments.targetAreaDeleted': 'Tarkastusalue poistettu',
  'inspectionDocuments.confirmTargetDeletion':
    'Olet poistamassa kohdetta. Et voi perua tätä toimintoa.',
  'inspectionDocuments.targetDeleted': 'Kohde poistettu',
  'inspectionDocuments.downloadInspectionDocumentAsSpreadsheet':
    'Lataa Excel-dokumenttina',
  'inspectionDocuments.downloadVisibleTargetsAsSpreadsheet':
    'Lataa näkyvät kohteet Excel-dokumenttina',
  'inspectionDocuments.noIspectionDocuments': 'Ei tarkastusasiakirjoja',
  'inspectionDocuments.newInspectionDocumentAddedSuccessfully':
    'Uusi tarkastusasiakirja lisätty onnistuneesti',
  'inspectionDocuments.inspectionDocumentDeletedSuccessfully':
    'Tarkastusasiakirja poistettu onnistuneesti',
  'inspectionDocuments.addNewTargetArea': 'Uusi tarkastusalue',
  'inspectionDocuments.addNewTarget': 'Uusi kohde',
  'inspectionDocuments.selectFile': 'Valitse tiedosto',
  'inspectionDocuments.attachmentName': 'Nimi',
  'inspectionDocuments.attachmentFileName': 'Tiedoston nimi',
  'inspectionDocuments.attachmentCategories': 'Kategoriat',
  'inspectionDocuments.attachmentState': 'Tila',
  'inspectionDocuments.addNewAttachment': 'Tallenna uusi dokumentti kohteelle',
  'inspectionDocuments.addNewAttachmentSuccess':
    'Dokumentti tallennettu onnistuneesti',
  'inspectionDocuments.updateDocumentSuccess':
    'Dokumentti päivitetty onnistuneesti',
  'inspectionDocuments.documentUploaded': 'Tiedosto {name} lisätty kohteelle',
  'inspectionDocuments.hasMissingAttachments': 'Kohteelta puuttuu liitteitä',
  'inspectionDocuments.documentNotUploaded':
    'Tiedostoa {name} ei voitu lisätä kohteelle.',
  'inspectionDocuments.tabulaRasa': `
**Tarkastusasiakirja on viranomaisen vaatima asiakirja, jonka tehtävänä on todentaa rakentamisen vaatimuksenmukaisuus.**

MRL § 150 f:

*”Rakennushankkeeseen ryhtyvän on huolehdittava, että rakennustyömaalla pidetään rakennustyön tarkastusasiakirjaa.
Rakennusluvassa tai aloituskokouksessa sovittujen rakennusvaiheiden vastuuhenkilöiden sekä työvaiheita tarkastaneiden on varmennettava tekemänsä tarkastukset rakennustyön tarkastusasiakirjaan.”*

UO kohta 10.1.1:

*Tarkastusasiakirjaan kootaan kaikki urakan ja sivu-urakoiden tekninen laatuaineisto todentamaan laatuvaatimusten täyttyminen sekä se, että kohteessa on käytetty ainoastaan hyväksyttyjä rakennustuotteita ja materiaaleja. Kaikkien käytettyjen rakennustuotteiden ja materiaalien alkuperä ja valmistuserä sekä tekniset ominaisuudet tulee ilmetä tarkastusasiakirjan kyseistä tarkastusta koskevissa liitteissä.*

**Tarkastusasiakirja Länsimetro projektissa**

Tarkastusasiakirjat on laadittu asemille ja ratalinjalle

* Aseman ja ratalinjan tarkastusasiakirjat sisältävät kunkin kohteen osalta kaikki urakka-alueella olevat rakennusluvan mukaiset työt
* PRU:lle ja radan rakentamiseen liittyville töille laaditaan oma erillinen tarkastusasiakirja

Tarkastusasiakirjat on jaettu kolmeen osioon:

* Yleinen
* Rakentaminen
* Talotekniikka
`,
  'inspectionDocuments.deleteInspectionDocumentHeader':
    'Tarkastusasiakirjan poisto',
  'inspectionDocuments.deleteInspectionDocumentMessage':
    'Olet poistamassa kohdetta **{name}**. Et voi perua tätä toimintoa. Anna salasanasi varmistaaksesi poiston',

  'inspectionDocuments.filterLabels.contractor.green':
    'Kaikki dokumentit tallennettu ja tarkastettu',
  'inspectionDocuments.filterLabels.contractor.yellow':
    'Kaikkia vaadittuja dokumentteja ei ole tallennettu',
  'inspectionDocuments.filterLabels.contractor.blue': 'Vain osa tarkastettu',
  'inspectionDocuments.filterLabels.contractor.red': 'Huomautuksia',

  'inspectionDocuments.filterLabels.inspector.green':
    'Kaikki dokumentit tallennettu ja tarkastettu',
  'inspectionDocuments.filterLabels.inspector.yellow':
    'Kaikki tai joitain dokumentteja tallennettu ja ne ovat valmiina tarkastettaviksi',
  'inspectionDocuments.filterLabels.inspector.blue':
    'Kaikkia edellytettyjä dokumentteja ei ole vielä tallennettu, mutta kaikki tallennetut dokumentit on tarkastettu',

  'inspectionDocuments.filterLabels.default.green':
    'Kaikki dokumentit tallennettu ja tarkastettu',
  'inspectionDocuments.filterLabels.default.yellow':
    'Kaikki tai joitain dokumentteja tallennettu ja ne ovat valmiina tarkastettaviksi',
  'inspectionDocuments.filterLabels.default.blue':
    'Kaikkia edellytettyjä dokumentteja ei ole vielä tallennettu, mutta kaikki tallennetut dokumentit on tarkastettu',

  TARKASTUSASIAKIRJA_DOC: 'Tarkastusasiakirjan dokumentti',
  TA_OTHER: 'Muu dokumentti',
  TA_REVIEW_DOC: 'Katselmuspöytäkirja',
  TA_RECEPTION_DOC: 'Vastaanottopöytäkirja',
  TA_INSPECTION_DOC: 'Tarkastuspöytäkirja',
  Z_dummy: 'Dummy',
  // Workflow states
  DEFAULT: 'Tilaajan dokumentit: Suunnittelija tarkastaa, tilaaja hyväksyy',
  DESIGNER_APPROVES:
    'Urakoitsijan dokumentit: Suunnittelija tarkastaa ja hyväksyy',
  DESIGNER_AND_INSPECTOR:
    'Urakoitsijan dokumentit: Suunnittelija tarkastaa, tilaaja hyväksyy',
  INSPECTOR_APPROVES: 'Urakoitsijan dokumentit: Tilaaja tarkastaa ja hyväksyy',
  NO_WORKFLOW: 'Hyväksytty lisäyshetkellä',
  TA_INSPECTOR_APPROVES: 'Tarkastusasiakirja: Valvoja tarkastaa ja hyväksyy',
  TA_DESIGNER_APPROVES:
    'Tarkastusasiakirja: Suunnittelija tarkastaa ja hyväksyy',
  TA_DESIGNER_INSPECTOR_APPROVES:
    'Tarkastusasiakirja: Suunnittelija tarkastaa, valvoja hyväksyy',

  'error.title': 'Virhetilanne',
  'error.instructions': `
# Sovelluksessa tapahtui ajonaikainen virhe!

Ole hyvä ja ota yhteyttä järjestelmän ylläpitoon

[Palaa takaisin etusivulle](/)
`,
  'workphase.uploadProgress': 'Siirretään tiedostoja',
  'workphase.selectContract': 'Valitse urakka',
  'workphase.selectWorkType': 'Valitse työvaihe',
  'workphase.selectFiles': 'Valitse vähintään yksi tiedosto',
  'workphase.selectFileType': 'Valitse tiedoston tyyppi: ',
  'contractorDocumentPackage.selectRoom': 'Valitse huone',
  'contractorDocumentPackage.selectSystem': 'Valitse järjestelmä',
  'contractorDocumentPackage.selectOr': '...tai',
  'contractorDocumentPackage.continue': 'Jatka',
  'contractorDocumentPackage.selectMainLevel':
    'Valitse urakka ja dokumenttien tyyppi',
  'contractorDocumentPackage.selectTargets':
    'Valitse kohteet, joille asiakirjoja lisätään',
  'contractorDocumentPackage.searchByName': 'Hae kohdetta nimellä',
  'contractorDocumentPackage.checkAndSelectTargets':
    'Rastita ja valitse kohteet',
  'contractorDocumentPackage.longerName': 'Pidempi nimi',
  'contractorDocumentPackage.pleaseSelectTargets':
    'Ole hyvä ja valitse ensin kohteet!',
  'contractorDocumentPackage.noTargetsSelected':
    'Et ole vielä valinnut yhtään kohdetta',
  // Work type was called "Työlaji" previously
  'contractorDocumentPackage.selectWorkType': 'Valitse työvaihe',
  'contractorDocumentPackage.selectedTargets': 'Valitut kohteet',
  'contractorDocumentPackage.selectDocumentFromFilesystem':
    'Valitse dokumentit koneeltasi',
  'contractorDocumentPackage.selectDocuments': 'Valitse dokumentit',
  'contractorDocumentPackage.file': 'Tiedosto',
  'contractorDocumentPackage.type': 'Tyyppi',
  'contractorDocumentPackage.acceptChoices': 'Hyväksy valinnat',
  'contractorDocumentPackage.updatedFiles': 'Päivitetyt tiedostot',
  'contractorDocumentPackage.isMissingDocuments':
    'Dokumenttipaketista puuttuu dokumentteja',
  'contractorDocumentPackage.allDocuments': 'Kaikki dokumentit',
  'adminTools.buildingChanged': 'Rakennusosa tai huone muuttunut',
  'adminTools.changeBuildings': 'Vaihda rakennusosa/huone',
  'adminTools.setSystemBreakMessage':
    'Aseta viesti, joka näkyy kaikille käyttäjille',
  'adminTools.stopMainetananceBreak': 'Lopeta huoltotila',
  'adminTools.startMainetananceBreak': 'Käynnistä huoltotila',
  'adminTools.systemHasMainetananceBreak': 'Järjestelmä on huoltotilassa!',
  'adminTools.mainteanance': 'Huoltotilan asettaminen',
  'adminTools.firstname': 'Etunimi',
  'adminTools.lastname': 'Sukunimi',
  'adminTools.name': 'Nimi',
  'adminTools.printOrders': 'Printit',
  'adminTools.email': 'Sähköpostiosoite',
  'adminTools.userInformation': 'Käyttäjän tiedot',
  'adminTools.adminUser': 'Admin-käyttäjä',
  'adminTools.unattachedUser': 'AD käyttäjä',
  'adminTools.disabledUser': 'Lukittu käyttäjä',
  'adminTools.addUserToLocalDB':
    'Liitä AD käyttäjä AssetPoint Online -järjestelmään',
  'adminTools.userPermissions': 'Käyttäjän oikeudet',
  'adminTools.users': 'Käyttäjät',
  'adminTools.roles': 'Roolit',
  'adminTools.role': 'Rooli',
  'adminTools.deleteItems': 'Poista kohteita',
  'adminTools.deviceLists': 'Laiteluettelot',
  'adminTools.allDevices': 'Kaikki',
  'adminTools.deviceAmount': 'Laitteita',
  'adminTools.deviceList': 'Laitelista',
  'adminTools.unknownSystems': 'Seuraavia järjestelmiä ei löytynyt',
  'adminTools.targetsWithoutMetaInfo':
    'Seuraaville kohdetunnuksille ei lueta ominaisuustietoja',
  'adminTools.targetsWithoutBuildingElement':
    'Seuraaville kohdetunnuksille ei ei ole asetettu rakennusosaa',
  'adminTools.targetsWithMultipleMetaInfo':
    'Seuraaville kohdetunnuksille yritetään lukea ominaisuustietoja useammasta kentästä',
  'adminTools.devicesToBeDeleted': 'Seuraavat laitteet poistetaan',
  'adminTools.isMissingTargetcodes':
    'Dokumentti sisältää laitteita, joiden "Uusi kohdetunnus" -määrittely on tyhjä',
  'adminTools.metadataUpdateSuccess':
    'Laitteiden ominaisuustiedot päivitettiin onnistuneesti',
  'adminTools.targetpage': 'Kohdesivu',
  'adminTools.phases': 'Vaiheet',
  'adminTools.contracts': 'Urakat',
  'adminTools.approvalCycles': 'Hyväksyntäkierrot',
  'adminTools.itemRegistry': 'Kohderekisteri',
  'adminTools.areas': 'Alueet',
  'adminTools.areaName': 'Alueen lyhenne',
  'adminTools.areaDescription': 'Alueen kuvaus',
  'adminTools.importArea': 'Tuo alueet',
  'adminTools.areaMFiles': 'MFiles synkronointi',
  'adminTools.section_name': 'Osuuden lyhenne',
  'adminTools.roomRegistry': 'Huoneet',
  'adminTools.addMissingRooms': 'Lisää puuttuvat huoneet',
  'adminTools.noMissingRooms': 'Ei puuttuvia huoneita',
  'adminTools.selectRoomDocument': 'Valitse huonetilat -Excel koneeltasi',
  'adminTools.misc': 'Muut',
  'adminTools.addedTargets': 'Jo lisätyt kohteet',
  'adminTools.alreadyAddedRooms': 'Kannassa jo valmiiksi olleet huoneet',
  'adminTools.roomsWithoutArea': 'Huoneet, joilla ei ole aluetta',
  'adminTools.addAreasToRooms': 'Lisää alueet huoneille',
  'adminTools.nameChanged': 'Huoneet, joiden nimi muuttunut',
  'adminTools.changeRoomNames': 'Vaihda huoneiden nimet',
  'adminTools.addPermission': 'Lisää oikeus',
  'adminTools.removePermissionVerifyTitle': 'Roolin poisto',
  'adminTools.removePermissionVerify':
    'Haluatko varmasti poistaa roolin käyttäjältä?',
  'adminTools.removePermissionAccept': 'Poista rooli',
  'adminTools.removePermissionCancel': 'Peruuta',
  'adminTools.privilegeType': 'Oikeus',
  'adminTools.serviceMessage': 'Huoltotila',
  'adminTools.sentEmails': 'Sähköpostit',
  'adminTools.sendPasswordResetLink': 'Lähetä salasanan palautuslinkki',
  'adminTools.passwordLinkValidity': 'Voimassa',
  'adminTools.passwordResetLinkSent': 'Palautuslinkki lähetetty!',
  'adminTools.hours': 'tuntia',
  'adminTools.phaseContractBuilding': 'Vaihe, Urakka, rakennusosa',
  'adminTools.missingRooms': 'Seuraavia huoneita ei ole luotuna',
  'adminTools.createType': 'Luo uusi tyyppi',
  'adminTools.replaceType':
    'Tai korvaa tyyppi Excelissä jollakin olemassaolevalla tyypillä',

  'adminTools.systems': 'Järjestelmät',
  'adminTools.newSystem': 'Uusi järjestelmä',
  'adminTools.saveNewParentSystem': 'Tallenna ylijärjestelmä',
  'adminTools.saveNewSystem': 'Tallenna järjestelmä',
  'adminTools.parentSystem': 'Ylijärjestelmä',
  'adminTools.systemId': 'Tunnus',
  'adminTools.systemDescription': 'Nimi',
  'adminTools.newSystemSuccess': 'Uusi järjestelmä tallennettu onnistuneesti',

  'adminTools.newPhase': 'Uusi vaihe',
  'adminTools.newContract': 'Uusi urakka',
  'adminTools.importWithSpreadsheet': 'Tuo Excel-tiedostolla',
  'adminTools.modify': 'Muokkaa ja poista',
  'adminTools.phase': 'Vaihe',
  'adminTools.setPhaseId': 'Vaihetunnus',
  'adminTools.setPhaseName': 'Vaiheen nimi',
  'adminTools.newPhaseSuccess': 'Uusi vaihe tallennettu onnistuneesti',
  'adminTools.contractName': 'Urakkatunnus',
  'adminTools.setContractName': 'Urakkatunnus',
  'adminTools.contractLongName': 'Urakan nimi',
  'adminTools.setContractLongName': 'Urakan nimi',
  'adminTools.newContractSuccess': 'Uusi urakka tallennettu onnistuneesti',
  'adminTools.workflows': 'Työnkierrot',
  'adminTools.importPitems': 'Tuo kohteet',
  'adminTools.updateDevices': 'Päivitä laitteet',
  'adminTools.newPitemsLegend': 'Uusia kohteita',
  'adminTools.preservedItemsLegend': 'Vanhoja kohteita',
  'adminTools.newPitems': 'Uudet kohteet',
  'adminTools.preservedItems': 'Vanhat kohteet',
  'permissions.permission': 'Oikeus',
  'permissions.permissions': 'Oikeudet',
  'permissions.create': 'Luonti',
  'permissions.read': 'Luku',
  'permissions.update': 'Päivitys',
  'permissions.delete': 'Poisto',
  'permissions.notRule': 'Estosääntö',
  'permissions.customRule': 'Spesifinen oikeus',
  'permissions.categories': 'Kategoriat',
  'permissions.documentStates': 'Dokumentin tilat',
  'permissions.pItemTypes': 'Kohteet',
  'permissions.action': 'Toiminto',
  'permissions.addPrivilege': 'Lisää oikeus',
  'usersTable.searchUser': 'Hae käyttäjää',
  'usersTable.labelRowsPerPage': 'Rivejä',
  'usersTable.showOnlyDbUsers': 'Vain tietokannassa',
  'contractor.workSecDocUploadInfo': 'Lisää työturvallisuusdokumentit tähän',
  'info.notMandatorySelect':
    'Tämä valinta ei ole pakollinen tälle aineistotyypille, jatka eteenpäin klikkaamalla tästä',
  'please.give.code': 'Ole hyvä ja täytä kenttä "Tunnus"',
  'please.give.description': 'Ole hyvä ja täytä kenttä "Kuvaus"',
  'please.give.pole': 'Ole hyvä ja anna paalu',
  'please.give.startpole': 'Ole hyvä ja anna alkupaalu',
  'please.give.endpole': 'Ole hyvä ja anna loppupaalu',
  'add.target': 'Lisää kohde',
  'added.items': 'Lisätyt kohteet',
  'pitem.already.exists': 'Laite samalla tunnuksella on jo olemassa!',
  'please.give.pitem.type': 'Anna kohdetyyppi',
  'confirm.delete': 'Oletko varma että haluat poistaa valitut kohteet?',
  'confirm.accept': 'Oletko varma että hyväksyä valitut kohteet?',
  'contractorDocumentPackage.replacesFile': 'Korvaa dokumentin',
  'general.gdpr': `${bv.gdprClause}`,
  'notifications.clearMessages': 'Tyhjennä ilmoitukset',
  'replacementDocument.setById': 'Aseta korvaava dokumentti ID:llä',
  'replacementDocument.setBySearch': 'Aseta korvaava dokumentti haulla',
  'errorMessage.canNotAccessDocument': 'Ei pääsyä kyseiseen dokumenttiin',
  'errorMessage.canNotReplaceByItself': 'Dokumenttia ei voi korvata itsellään',
  'errorMessage.canNotReplaceByRejectedDocument':
    'Dokumenttia ei voi korvata hylätyllä dokumentilla',
  'errorMessage.invalidValue': 'Virheellinen arvo',
  'errorMessage.documentAlreadyReplaced':
    'Dokumentti on jo korvattu toisella dokumentilla.',
  'errorMessage.searchTooLarge':
    'Haettuja dokumentteja on liikaa! Rajaa hakua.',
  'errorMessage.deleteInspectionAreaWithChildren':
    'Tarkastusaluetta ei voi poistaa, jos siihen liittyy tarkastuksen kohteita',
  'errorMessage.deleteInspectionTargetWithChildren':
    'Tarkastuksen kohdetta ei voi poistaa, jos siihen liittyy dokumentteja ',
};

export type FI_KEY = typeof translations;
export type FI_KEYWORD = keyof FI_KEY;

export default translations;

export const languages = [
  {
    label: 'Englanti',
    value: 'en',
  },
  {
    label: 'Suomi',
    value: 'fi',
  },
];
