import * as React from 'react';

import { FormControlLabel, Checkbox } from '@material-ui/core';
import { CheckboxProps as MuiCheckboxProps } from '@material-ui/core/Checkbox';
import { FormControlLabelProps as MuiFormControlLabelProps } from '@material-ui/core/FormControlLabel';

import { createStyles, withStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

const styles = (theme: Theme) => createStyles({});

export interface ICheckboxProps {
  classes?: any;
}

export interface ICheckboxState {
  checked?: boolean;
}

type CheckboxProps = MuiCheckboxProps &
  ICheckboxProps &
  MuiFormControlLabelProps;

class UiCheckbox extends React.Component<CheckboxProps, ICheckboxState> {
  constructor(props: CheckboxProps) {
    super(props);

    this.state = { checked: false };
  }

  render() {
    const classes = ['uiCheckbox'].join(' ');
    return (
      <FormControlLabel
        {...this.props}
        label={this.props.label}
        className={`${this.props.classes.root} ${classes}`}
        control={<Checkbox />}
      />
    );
  }
}

export default withStyles(styles)(UiCheckbox);
