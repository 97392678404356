import * as React from 'react';
import * as _ from 'lodash';
import * as container from '../../state/reducers/DocumentState';
import * as appstate from '../../state/reducers/ApplicationState';
import { injectIntl, InjectedIntlProps, FormattedMessage } from 'react-intl';

import ReactSelect from 'react-select';
import './index.scss';
import DocumentTable from '../Common/DocumentTable';
import { PItemToolbar } from '../Common/DocumentToolbar';
import MainLayout from '../../layout/MainLayout';

import {
  Checkbox,
  Stepper,
  Step,
  StepLabel,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Select,
  MenuItem,
  ListItemText,
  LinearProgress,
} from '@material-ui/core';
import { debounce } from '../../utils';
import { getChildrenOf } from '../../utils/metadata';
import axios from 'axios';
import { APIService } from '../../services/api/src/frontend/api';
import { DocumentService } from '../../services/document/src/frontend/api';
import * as Model from '../../../../shared/src/model';
// import { PItemTypes } from '../Common/DocumentMeta';
import { Item } from '../Common/AutosuggestChipSelection';
import Button from '../Common/Button';
// import { DataTable } from '../ManagementTools/DataTable';
import FileInput from '../Common/FileInput';
import { Contracts } from '../Common/DocumentMeta';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Loading from '../Common/Loading';
import CircleChecked from '@material-ui/icons/CheckCircle';
import { errorMessage } from '../Common/Notifier';
import { Redirect } from 'react-router';
import { Async } from '../Common/Async';
import CreateNewPitem from './CreateNewPitem';
import { ValueType } from 'react-select/lib/types';
// import DeleteIcon from '@material-ui/icons/Delete';
// import Circle from '@material-ui/icons/CheckCircle';

const API = APIService(axios);
const DOC = DocumentService(axios);
interface IContractsProps extends container.IProps, appstate.IProps {}

interface IContractsState {
  docs?: Model.UIDocument[];
  checkedFiles: { [key: string]: boolean };
  allDocumentSelected: boolean;
  alternatives: Model.UIDocument[];
  alternativesByFilename: Model.UIDocument[];
  selectedDocuments?: Model.UIDocument[];
  freeText: string;
  pitemTypes: number[];

  selectedFiles: File[];
  stepPosition: number;
  fileTypes: { [key: string]: number };
  replacesDocs: { [key: string]: Item[] };
  workTypeId: number;
  selectedName: string;

  mainLevelId: number;
  mainLevelIds: number[];
  mainLevels: Model.DocumentCategory[];

  contractId?: number;
  selectedSystemId: number;
  selectedContractItems: Item[];
  selectedBuildings: Item[];
  selectedBuildingDocs: Model.UIDocument[];

  // adding new pitem
  isAddingNewPitem?: boolean;

  // upload state
  isUploading: boolean;
  isUploadReady: boolean;
  uploadedFiles?: Model.UIDocument[];
  uploadErrors: string[];
  navigateToDocumentId?: number;
  completed: number;
  requiredSteps: number;
  isSearchingData: boolean;
}

type ContractsProps = IContractsProps & InjectedIntlProps;

class AbstractContracts extends React.Component<
  ContractsProps,
  IContractsState
> {
  delayedSearch = debounce(600);

  constructor(props: ContractsProps) {
    super(props);

    this.state = {
      checkedFiles: {},
      allDocumentSelected: false,
      alternatives: [],
      alternativesByFilename: [],
      freeText: '',
      pitemTypes: [],
      stepPosition: 0,
      selectedFiles: [],
      fileTypes: {},
      replacesDocs: {},
      workTypeId: 0,
      selectedName: '',
      mainLevelId: 0,
      selectedContractItems: [],
      selectedBuildings: [],
      selectedBuildingDocs: [],
      isUploading: false,
      isUploadReady: false,
      uploadedFiles: [],
      uploadErrors: [],
      mainLevelIds: [],
      mainLevels: [],
      completed: 0,
      requiredSteps: 1,
      isSearchingData: false,
      selectedSystemId: 0,
    };
  }

  // ...

  SeletedDocTable = (props: {
    docs: Model.UIDocument[];
    noRemove?: boolean;
  }) => {
    const t = this.props.intl;
    const translate = (id: string) => t.formatMessage({ id });
    return (
      <DocumentTable
        actionComponents={[
          <PItemToolbar
            key={'toolbar1'}
            selectedDocuments={this.state.selectedDocuments || []}
          />,
        ]}
        hasSelect={false}
        keys={{
          target: 'Kohteet, joille asiakirjoja lisätään: ' + props.docs.length,
          building: translate('documentMeta.building'),
          startPole: translate('documentMeta.startPole'),
          endPole: translate('documentMeta.startPole'),
          dwgName: translate('buttons.delete'),
        }}
        rows={
          props.docs
            ? props.docs.map((row) => ({
                ...row,
                building: row.building ? row.building.name : '',
                target: row.target
                  ? row.target.name +
                    (row.target.name_long ? ' / ' + row.target.name_long : '')
                  : row.name,
                dwgName: (
                  <Button
                    disabled={props.noRemove}
                    onClick={() => {
                      this.props.removeFromPitemTargets(row);
                    }}
                  >
                    x
                  </Button>
                ),
              }))
            : []
        }
      />
    );
  };

  convertToUIDocs = (): Model.UIDocument[] => {
    const res: Model.UIDocument[] = [];
    // remove extension from the file
    const getExt = (name: string): string => {
      const parts = name.split('.');
      if (parts.length > 1) {
        return parts[parts.length - 1];
      }
      return '';
    };
    const removeExt = (name: string): string => {
      const parts = name.split('.');
      if (parts.length === 1) {
        return name;
      }
      parts.pop(); // remove last part of the file name
      return parts.join('.');
    };
    const contracts = this.state.contractId ? [this.state.contractId] : [];
    for (const row of this.state.selectedFiles) {
      const o: Model.UIDocument = {
        id: 0,
        name: removeExt(row.name || 'Unknown File'),
        createdByUserId: 0,
        workflowStateId: 0,
        fileName: row.name || '',
        fileExtension: getExt(row.name),
        created: '',
        modified: '',
        categories: [
          this.state.workTypeId,
          this.state.fileTypes[row.name],
        ].filter((c) => c),
        contractIds: contracts,
        contractStr: '',
        buildingElement: '',
        pItems: this.props.pitemTargetting.map((item) => ({
          id: item.id,
          name: item.name,
          longName: item.name,
        })),
        aItems: contracts.map((id) => ({
          id,
          name: '',
        })),
        revision: '',
        description: '',
        dwgName: '',
        startPole: undefined,
        endPole: undefined,
        downloadUrl: '',
        ObjVer: undefined,
        allowedActions: [],
        versionNumber: 1,
        displayData: {
          updateEvenIfSame: true,
        },
      };
      res.push(o);
    }
    return res;
  };

  async uploadDocuments() {
    if (this.state.selectedFiles.length > 0) {
      try {
        const uiDocs = this.convertToUIDocs();
        const files = this.state.selectedFiles;
        this.setState({
          isUploadReady: false,
          isUploading: true,
          uploadErrors: [],
          uploadedFiles: [],
          completed: 0,
        });
        const config = {
          headers: {
            'content-type': 'multipart/form-data',
          },
        };
        let idx = 1;
        for (const doc of uiDocs) {
          const theFile = files.filter((f) => f.name === doc.fileName).pop();
          if (theFile) {
            try {
              // create next revision?
              if (this.state.replacesDocs[theFile.name]) {
                const altFiles = this.state.replacesDocs[theFile.name];
                if (altFiles && altFiles.length > 0) {
                  const altFile = altFiles[0];
                  doc.replace = this.state.alternatives
                    .filter((alt) => alt.fileName === altFile.label)
                    .map((alt) => {
                      const mainPItem = alt.pItems[0];
                      return {
                        pitemId: mainPItem ? mainPItem.id : undefined,
                        aitemId: alt.aItems[0].id,
                        documentId: alt.id,
                      } as Model.ReplaceDocumentInfo;
                    });
                }
              }

              doc.systemId = this.state.selectedSystemId;

              // pitem parts
              const MAX_UPLOAD_PER_REQ = 4;
              const uploadCnt = Math.ceil(
                doc.pItems.length / MAX_UPLOAD_PER_REQ
              );
              const origPitems = [...doc.pItems];
              let result_take: Model.UIDocument[] = [];
              for (let i = 0; i < uploadCnt; i++) {
                doc.pItems = origPitems.slice(
                  i * MAX_UPLOAD_PER_REQ,
                  i * MAX_UPLOAD_PER_REQ + MAX_UPLOAD_PER_REQ
                );
                const formData = new FormData();
                formData.append('info', JSON.stringify(doc));
                formData.append('file', theFile);
                result_take = (
                  await axios.post(
                    '/docs/v1/upload/contractordoc',
                    formData,
                    config
                  )
                ).data as Model.UIDocument[];
                this.setState((state) => {
                  return {
                    completed: Math.min(
                      100,
                      (idx - 1) * (100 / uiDocs.length) +
                        ((i + 1) / uploadCnt) * (100 / uiDocs.length)
                    ),
                  };
                });
              }
              this.setState((state) => {
                const prev = state.uploadedFiles || [];
                return {
                  uploadedFiles: [...prev, result_take[0]],
                  completed: Math.min(100, idx * (100 / uiDocs.length)),
                };
              });
            } catch (e) {
              errorMessage(e);
              this.setState((state) => {
                const prev = state.uploadErrors || [];
                return {
                  uploadErrors: [...prev, `${e}`],
                };
              });
            }
            idx++;
          }
        }
        this.setState({
          isUploadReady: true,
          isUploading: false,
        });
      } catch (e) {
        console.error(e);
        errorMessage(e);
      }
    }
  }

  trySearch = () => {
    this.setState({
      isSearchingData: true,
    });
    this.delayedSearch(async () => {
      const filteredTypeList = this.state.pitemTypes.filter((value) => value);
      const useTypes =
        filteredTypeList.length === 0
          ? this.props.pitemTypes
              .filter((t) => t.name === 'Rakennustuotteet')
              .map((t) => t.id)
          : filteredTypeList;
      const rows = (
        await API.searchPItems({
          buildingPartIds: (this.state.selectedBuildings || []).map(
            (item) => item.value
          ),
          freeText: this.state.freeText,
          pitemTypes: useTypes,
        })
      ).rows;
      this.setState({
        docs: rows,
        isSearchingData: false,
      });
    });
  };

  getReplaceOptions = async () => {
    this.setState({ alternatives: [], alternativesByFilename: [] });
    const alternatives = (
      await DOC.documentSearchRequest({
        itemIds: this.props.pitemTargetting.map((item) => item.id),
        contractorDocumentTypes: this.state.mainLevels.map((t) => t.id),
        contractorWorkTypes: [this.state.workTypeId].filter((v) => v),
        // contractorWorkTypes : this.state.mainLevels.map
      })
    ).rows;
    const alternativesByFilename: {
      [key: string]: Model.UIDocument;
    } = alternatives.reduce(
      (prev: { [key: string]: Model.UIDocument }, curr) => {
        return {
          ...prev,
          [curr.fileName]: curr,
        };
      },
      {}
    );

    this.setState({
      alternatives,
      alternativesByFilename: Object.keys(alternativesByFilename).map(
        (name) => alternativesByFilename[name]
      ),
    });
  };

  onSearchValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ freeText: e.currentTarget.value }, () => {
      this.trySearch();
    });
  };

  getMandatoryPhase = (levels: Model.DocumentCategory[]): number => {
    let value = 1;
    if (levels.filter((l) => l.name.indexOf('laatuaineisto') >= 0).length > 0) {
      value = 2;
    }
    if (
      levels.filter((l) => l.name.indexOf('Tarkepiirustukset') >= 0).length > 0
    ) {
      value = 3;
    }
    if (
      levels.filter((l) => l.name.indexOf('built aineisto') >= 0).length > 0
    ) {
      value = 3;
    }
    if (levels.filter((l) => l.name.indexOf('Huolto') >= 0).length > 0) {
      value = 4;
    }
    if (levels.filter((l) => l.name.indexOf('Laatu') >= 0).length > 0) {
      value = 4;
    }
    return value;
  };

  onAllDocumentSelectedChange = (checked: boolean) => {
    this.setState({
      allDocumentSelected: checked,
      checkedFiles: this.state.selectedFiles.reduce(
        (prev: { [key: string]: boolean }, curr) => {
          return {
            ...prev,
            [curr.name]: checked,
          };
        },
        {}
      ),
    });
  };

  public render() {
    const t = this.props.intl;
    const translate = (id: string) => t.formatMessage({ id });
    const orderSteps = [
      translate('workphase.selectContract'),
      translate('upload.document.item.placeholder'),
      translate('workphase.selectWorkType'),
      'Valitse dokumentit',
      'Valitse dokumenttien tyyppi',
    ];
    // /documents/1083
    if (this.state.navigateToDocumentId) {
      const pathname = `/documents/${this.state.navigateToDocumentId}`;
      return (
        <Redirect
          to={{
            pathname,
          }}
          push={true}
        />
      );
    }

    {/* THIS WANTED NOT BE POSSIBLE BUT ALSO DIDN'T WANT TO GIVE UP ALREADY MADE WORK */}
    {/* THAT'S REASON WHY BELOW CODE IS COMMENTED OUT AND NOT DELETED */}
    {/* SEE ALSO COMMENTED CODE BELOW */}
    // const mainLevels = this.props.getContractorMainDocumentypes.filter((c) => {
    //   return this.state.mainLevelIds.indexOf(c.id) >= 0;
    // });
    return (
      <MainLayout>
        <h1>
          <FormattedMessage id="headers.addContractorDocuments" />
        </h1>

        <div className="grid gridCols1 contractorDocumentAdd">
          <div style={{ backgroundColor: 'white', padding: '20px' }}>
            {this.state.selectedName ? (
              <div style={{ marginBottom: '10px' }}>
                <h2>{this.state.selectedName}</h2>
              </div>
            ) : null}
            <Stepper
              activeStep={this.state.stepPosition}
              alternativeLabel={true}
              style={{ paddingLeft: '0px', paddingRight: '0px' }}
            >
              {orderSteps.map((label, index) => (
                <Step
                  key={label}
                  onClick={() => {
                    if (this.state.stepPosition > index) {
                      if (index === 0) {
                        this.props.clearPItemTargets();
                        this.setState({
                          stepPosition: index,
                          fileTypes: {},
                          replacesDocs: {},
                          selectedFiles: [],
                          mainLevels: [],
                          freeText: '',
                          requiredSteps: 1,
                          workTypeId: 0,
                          selectedSystemId: 0,
                        });
                        return;
                      }
                      if (index === 3) {
                        this.setState({
                          stepPosition: index,
                          fileTypes: {},
                          replacesDocs: {},
                          selectedFiles: [],
                          checkedFiles: {},
                          allDocumentSelected: false,
                        });
                        return;
                      }
                      this.setState({
                        stepPosition: index,
                      });
                    }
                  }}
                >
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>

            {this.state.stepPosition === 0 ? (
              <>
                <div style={{ maxWidth: '300px' }}>
                  <Contracts
                    onChange={(e) => {
                      //
                      if (e.length === 0) {
                        this.setState({
                          contractId: undefined,
                          selectedContractItems: [],
                        });
                        return;
                      }
                      this.setState({
                        contractId: e[0].value,
                        selectedContractItems: e,
                      });
                    }}
                    contracts={this.state.selectedContractItems}
                    suggestions={this.props.userContractorDocWritableContracts}
                  />
                </div>
                {this.state.contractId ? (
                  <>
                    <List>
                      {this.props.getContractorMainDocumentypes.map((item) => {
                        const isSelected =
                          this.state.mainLevelIds.indexOf(item.id) >= 0;

                        return (
                          <React.Fragment key={item.id}>
                            <ListItem
                              button={true}
                              key={item.id}
                              onClick={() => {
                                if (!isSelected) {
                                  this.setState((state) => ({
                                    mainLevelIds: _.uniq([
                                      ...state.mainLevelIds,
                                      ...[item.id],
                                    ]),
                                  }));
                                } else {
                                  this.setState((state) => ({
                                    mainLevelIds: _.filter(
                                      state.mainLevelIds,
                                      (value) => value !== item.id
                                    ),
                                  }));
                                }
                              }}
                            >
                              <CircleChecked
                                style={{
                                  color: isSelected ? 'black' : '#eeeeee',
                                }}
                              />{' '}
                              <ListItemText
                                primary={item.name}
                                secondary={this.props.appDocumentCategories
                                  .filter((c) => c.parentId === item.id)
                                  .map((c) => c.name)
                                  .join(', ')}
                              />
                            </ListItem>
                          </React.Fragment>
                        );
                      })}
                    </List>
                    {this.state.mainLevelIds.length > 0 ? (
                      <Button
                        onClick={(e) => {
                          const mainLevels = this.props.getContractorMainDocumentypes.filter(
                            (c) => {
                              return this.state.mainLevelIds.indexOf(c.id) >= 0;
                            }
                          );
                          // isMandatoryPhase
                          this.setState({
                            stepPosition: this.state.stepPosition + 1,
                            docs: [],
                            mainLevels,
                            requiredSteps: this.getMandatoryPhase(mainLevels),
                          });
                          this.props.clearPItemTargets();
                        }}
                      >
                        Jatka
                      </Button>
                    ) : null}
                  </>
                ) : null}
              </>
            ) : null}
            {this.state.stepPosition === 1 ? (
              <>
                <div>
                  {this.state.requiredSteps === 1 ? (
                    <Button
                      variant="text"
                      color="primary"
                      className="notMandatory"
                      onClick={(e) => {
                        this.setState({
                          stepPosition: this.state.stepPosition + 1,
                        });
                      }}
                    >
                      {translate('info.notMandatorySelect')}
                    </Button>
                  ) : null}
                  {/* THIS WANTED NOT BE POSSIBLE BUT ALSO DIDN'T WANT TO GIVE UP ALREADY MADE WORK */}
                  {/* THAT'S REASON WHY BELOW CODE IS COMMENTED OUT AND NOT DELETED */}
                  {/* SEE ALSO COMMENTED CODE ABOVE */}
                  {/*{this.state.mainLevelIds.length === 1 &&*/}
                  {/*mainLevels[0].name.indexOf('As-built') >= 0 ? (*/}
                  {/*  <div>*/}
                  {/*    <h2>*/}
                  {/*      1. {translate('contractorDocumentPackage.selectSystem')}*/}
                  {/*    </h2>*/}
                  {/*    <div style={{ padding: '10px' }}>*/}
                  {/*      <Select*/}
                  {/*        value={*/}
                  {/*          this.state.selectedSystemId*/}
                  {/*          // this.state.selectedRole*/}
                  {/*          //   ? this.state.selectedRole.id*/}
                  {/*          //   : undefined*/}
                  {/*        }*/}
                  {/*        onChange={(event) => {*/}
                  {/*          console.log(event.target.value);*/}
                  {/*          this.setState({*/}
                  {/*            selectedSystemId: Number(event.target.value),*/}
                  {/*          });*/}
                  {/*        }}*/}
                  {/*      >*/}
                  {/*        <MenuItem value={0}>*/}
                  {/*          <em>*/}
                  {/*            {translate(*/}
                  {/*              'contractorDocumentPackage.selectSystem'*/}
                  {/*            )}*/}
                  {/*          </em>*/}
                  {/*        </MenuItem>*/}
                  {/*        {this.props.systems*/}
                  {/*          .filter((s) => s.parent_system_id)*/}
                  {/*          .map((sys) => {*/}
                  {/*            const pSys = this.props.systems.filter(*/}
                  {/*              (s) => s.id === sys.parent_system_id*/}
                  {/*            )[0];*/}
                  {/*            return (*/}
                  {/*              <MenuItem value={sys.id} key={sys.id}>*/}
                  {/*                {pSys!.system_name} {sys.system_name}*/}
                  {/*              </MenuItem>*/}
                  {/*            );*/}
                  {/*          })}*/}
                  {/*      </Select>*/}
                  {/*      {this.state.selectedSystemId > 0 ? (*/}
                  {/*        <div>*/}
                  {/*          <Button*/}
                  {/*            onClick={(e) => {*/}
                  {/*              this.setState({*/}
                  {/*                selectedDocuments: [],*/}
                  {/*                stepPosition: this.state.stepPosition + 1,*/}
                  {/*              });*/}
                  {/*              this.props.clearPItemTargets();*/}
                  {/*            }}*/}
                  {/*          >*/}
                  {/*            {translate('contractorDocumentPackage.continue')}*/}
                  {/*          </Button>*/}
                  {/*        </div>*/}
                  {/*      ) : (*/}
                  {/*        ''*/}
                  {/*      )}*/}
                  {/*    </div>*/}
                  {/*    <div style={{ padding: '10px' }}>*/}
                  {/*      {translate('contractorDocumentPackage.selectOr')}*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*) : (*/}
                  {/*  ''*/}
                  {/*)}*/}
                  <div style={{ marginBottom: '10px' }}>
                    <h2>
                      1. {translate('contractorDocumentPackage.selectTargets')}
                    </h2>
                  </div>

                  <div>
                    {!this.state.isAddingNewPitem ? (
                      <Button
                        className="addTargetButton"
                        onClick={() => {
                          this.setState({
                            isAddingNewPitem: !this.state.isAddingNewPitem,
                          });
                        }}
                      >
                        {'+ ' + translate('add.target')}
                      </Button>
                    ) : null}
                  </div>
                  {this.state.isAddingNewPitem ? (
                    <CreateNewPitem
                      header="Lisätään uusi rakennustarvike tai tuote"
                      contractId={this.state.contractId}
                      onEnd={() => {
                        // this.setState({ isAddingNewPitem: false });
                      }}
                      onCancel={() => {
                        this.setState({
                          isAddingNewPitem: false,
                        });
                      }}
                    />
                  ) : null}

                  <div style={{ maxWidth: '300px' }}>
                    <div>
                      <h4>
                        {translate('contractorDocumentPackage.searchByName')}
                      </h4>
                    </div>
                    <input
                      className="targetSearch"
                      value={this.state.freeText}
                      onChange={this.onSearchValue}
                    />
                    {this.state.isSearchingData ? <LinearProgress /> : <div />}
                    <Async
                      loader={() =>
                        API.searchPItems({
                          contractFilter: [this.state.contractId || 0].filter(
                            (n) => n
                          ),
                          pitemTypes: this.props.pitemTypes
                            .filter((n) => n.name === 'Rakennusosa')
                            .map((t) => t.id),
                        })
                      }
                      render={(data) => {
                        if (!data) {
                          return <div />;
                        }
                        return (
                          <>
                            <h4>{translate('documentMeta.buildingElement')}</h4>
                            <ReactSelect
                              isMulti={true}
                              placeholder={'Valitse rakennusosa'}
                              options={data.rows.map((item) => {
                                return {
                                  label:
                                    item.name +
                                    ' / ' +
                                    (item.building
                                      ? item.building.name_long
                                      : ''),
                                  value: item.id,
                                };
                              })}
                              onChange={(values: ValueType<Model.Item>) => {
                                if (values && Array.isArray(values)) {
                                  const ids = values.map((i) => i.value);
                                  this.setState(
                                    {
                                      selectedBuildings: values,
                                      selectedBuildingDocs: data.rows.filter(
                                        (d) => {
                                          return ids.indexOf(d.id) >= 0;
                                        }
                                      ),
                                    },
                                    () => {
                                      this.trySearch();
                                    }
                                  );
                                }
                                // this.onSelectDocumentCategory(values as Model.Item[]);
                              }}
                            />
                          </>
                        );
                      }}
                    />
                    <h4>{translate('itemMeta.itemType')}</h4>
                    <ReactSelect
                      className="dropdown"
                      isMulti={true}
                      placeholder={'Valitse kohdetyyppi'}
                      options={[
                        { value: 0, label: 'Kaikki' },
                        ...this.props.targetPitemTypes
                          .filter((cat) => {
                            return cat.upper_type_id && cat.upper_type_id > 0;
                          })
                          .map((category) => ({
                            label: category.name,
                            value: category.id,
                          })),
                      ]}
                      onChange={(values: ValueType<Model.Item>) => {
                        if (values && Array.isArray(values)) {
                          this.setState(
                            {
                              pitemTypes: values.map((item) => item.value),
                            },
                            () => {
                              this.trySearch();
                            }
                          );
                        }
                        // this.onSelectDocumentCategory(values as Model.Item[]);
                      }}
                    />
                  </div>
                </div>
                <div className="grid gridCols2">
                  <div>
                    <div>
                      {!this.state.docs || this.state.docs.length === 0 ? (
                        <div>{translate('search.noSearchResults')}</div>
                      ) : null}
                      {this.state.docs && this.state.docs.length > 0 ? (
                        <DocumentTable
                          title={translate(
                            'contractorDocumentPackage.checkAndSelectTargets'
                          )}
                          actionComponents={[
                            <PItemToolbar
                              key={'toolbar1'}
                              selectedDocuments={
                                this.state.selectedDocuments || []
                              }
                            />,
                          ]}
                          setSelected={(ids) => {
                            if (this.state.docs) {
                              this.setState({
                                selectedDocuments: this.state.docs.filter(
                                  (doc) => {
                                    return ids.indexOf(doc.id) >= 0;
                                  }
                                ),
                              });
                            }
                            // this.props.addToPitemTargets
                            // if (this.state.docs) {
                            /*
                              this.props.addToPitemTargets(
                                this.state.docs.filter(doc => {
                                  return ids.indexOf(doc.id) >= 0;
                                })
                              );
                              */
                            // }
                          }}
                          hasSelect={true}
                          keys={{
                            target: translate('pitem.code'),
                            description: translate(
                              'contractorDocumentPackage.longerName'
                            ),
                            building: translate('documentMeta.building'),
                            location: translate('documentMeta.location'),
                            pitemTypeName: 'Tyyppi',
                          }}
                          rows={
                            this.state.docs
                              ? this.state.docs.map((row) => ({
                                  ...row,
                                  location: row.room
                                    ? row.room.name
                                    : row.polerange
                                    ? row.polerange.name
                                    : row.pole
                                    ? row.pole.name
                                    : row.startPole
                                    ? `${row.startPole}${
                                        row.endPole ? ' - ' + row.endPole : ''
                                      }`
                                    : '',
                                  endPole: row.endPole,
                                  startPole: row.startPole,
                                  building: row.building
                                    ? row.building.name
                                    : '',
                                  target: row.target
                                    ? row.target.name
                                    : row.name,
                                }))
                              : []
                          }
                        />
                      ) : null}
                    </div>
                  </div>

                  <div>
                    <div>
                      {(this.props.pitemTargetting &&
                        this.props.pitemTargetting.length > 0) ||
                      this.state.requiredSteps === 1 ||
                      (this.state.requiredSteps === 2 &&
                        this.state.selectedBuildings.length > 0) ? (
                        <>
                          <Button
                            color="primary"
                            onClick={(e) => {
                              if (
                                this.props.pitemTargetting.length === 0 &&
                                this.state.requiredSteps === 2
                              ) {
                                // this.props.
                                // addToPitemTargets
                                this.props.addToPitemTargets(
                                  this.state.selectedBuildingDocs
                                );
                                this.setState({
                                  // selectedSystemId: 0,
                                  stepPosition: this.state.stepPosition + 1,
                                });
                                return;
                              }
                              this.setState({
                                // selectedSystemId: 0,
                                stepPosition: this.state.stepPosition + 1,
                              });
                            }}
                          >
                            Jatka eteenpäin
                          </Button>
                          <Button
                            onClick={() => {
                              this.props.clearPItemTargets();
                            }}
                          >
                            Tyhjennä
                          </Button>
                        </>
                      ) : (
                        translate('contractorDocumentPackage.noTargetsSelected')
                      )}
                    </div>
                    <div>
                      {this.props.pitemTargetting ? (
                        <this.SeletedDocTable
                          docs={this.props.pitemTargetting}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </>
            ) : null}

            {this.state.stepPosition === 2 ? (
              <div>
                {this.state.requiredSteps <= 3 ? (
                  <Button
                    variant="text"
                    color="primary"
                    className="notMandatory"
                    onClick={(e) => {
                      this.setState({
                        stepPosition: this.state.stepPosition + 1,
                        workTypeId: 0,
                      });
                    }}
                  >
                    {translate('info.notMandatorySelect')}
                  </Button>
                ) : null}
                <div style={{ marginBottom: '10px' }}>
                  <h2>
                    2. {translate('contractorDocumentPackage.selectWorkType')}
                  </h2>
                </div>
                <div className="grid gridCols2">
                  <div>
                    {this.props.getContractorWorkTypes.map((item) => {
                      return (
                        <Button
                          key={item.id}
                          style={{ margin: '5px' }}
                          onClick={(e) => {
                            this.setState({
                              workTypeId: item.id,
                              stepPosition: this.state.stepPosition + 1,
                            });
                          }}
                        >
                          {item.name}
                        </Button>
                      );
                    })}
                  </div>
                  <div>
                    {this.props.pitemTargetting ? (
                      <this.SeletedDocTable
                        docs={this.props.pitemTargetting}
                        noRemove={true}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            ) : null}

            {this.state.stepPosition === 3 ? (
              <div>
                <div style={{ marginBottom: '10px' }}>
                  <h2>{translate('upload.dropzoneInfo')}</h2>
                  <div style={{ maxWidth: '300px' }}>
                    <FileInput
                      id="contractor_files"
                      label={translate(
                        'contractorDocumentPackage.selectDocuments'
                      )}
                      onChange={(files) => {
                        this.setState({
                          selectedFiles: files,
                        });
                      }}
                    />
                  </div>
                  <Button
                    color="primary"
                    onClick={() => {
                      if (
                        this.state.selectedFiles &&
                        this.state.selectedFiles.length > 0
                      ) {
                        this.getReplaceOptions();
                        this.setState({
                          stepPosition: this.state.stepPosition + 1,
                          uploadedFiles: [],
                          fileTypes: {},
                          replacesDocs: {},
                          isUploading: false,
                          isUploadReady: false,
                          completed: 0,
                        });
                      }
                    }}
                  >
                    {translate('contractorDocumentPackage.acceptChoices')}
                  </Button>
                </div>
                {this.props.pitemTargetting ? (
                  <this.SeletedDocTable
                    docs={this.props.pitemTargetting}
                    noRemove={true}
                  />
                ) : null}
              </div>
            ) : null}

            {this.state.stepPosition === 4 ? (
              <div>
                <div style={{ marginBottom: '10px' }}>
                  <h2>
                    {this.state.isUploading
                      ? translate('upload.waiting') +
                        ` (${this.state.completed.toFixed(0)}%)`
                      : this.state.isUploadReady
                      ? translate('upload.ready')
                      : translate('upload.document.type.placeholder')}
                  </h2>
                  {this.state.isUploading ? (
                    <LinearProgress
                      variant="determinate"
                      value={this.state.completed}
                    />
                  ) : null}
                </div>
                {!this.state.isUploadReady && (
                  <>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>&nbsp;</TableCell>
                          <TableCell>
                            {translate('contractorDocumentPackage.file')}
                          </TableCell>
                          <TableCell>
                            {translate('contractorDocumentPackage.type')}
                          </TableCell>
                          <TableCell>
                            {translate(
                              'contractorDocumentPackage.replacesFile'
                            )}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <Checkbox
                              checked={this.state.allDocumentSelected}
                              onChange={(e, checked) =>
                                this.onAllDocumentSelectedChange(checked)
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <FormattedMessage id="contractorDocumentPackage.allDocuments" />
                          </TableCell>
                          <TableCell>
                            <Select
                              className="fileTypeSelect"
                              value={false}
                              onChange={(e) => {
                                const fileTypes = { ...this.state.fileTypes };

                                const { selectedFiles } = this.state;

                                for (const file of selectedFiles) {
                                  if (this.state.checkedFiles[file.name]) {
                                    fileTypes[file.name] = parseInt(
                                      e.target.value,
                                      10
                                    );
                                  }
                                }

                                this.setState({
                                  fileTypes,
                                });
                              }}
                            >
                              {getChildrenOf(
                                this.state.mainLevels,
                                this.props.getContractorDocumentypes
                              ).map((item) => {
                                return (
                                  <MenuItem key={item.id} value={item.id}>
                                    {item.name}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </TableCell>
                          <TableCell>&nbsp;</TableCell>
                        </TableRow>
                        {this.state.selectedFiles.map((file) => {
                          const possibleAlternatives = this.state.alternativesByFilename.filter(
                            (d) => {
                              if (
                                !d.replacedByDocumentId &&
                                d.categories &&
                                d.categories.indexOf(
                                  this.state.fileTypes[file.name] || 0
                                ) >= 0
                              ) {
                                return true;
                              }
                              return false;
                            }
                          );
                          return (
                            <TableRow key={file.name}>
                              <TableCell>
                                <Checkbox
                                  checked={
                                    this.state.checkedFiles[file.name] || false
                                  }
                                  onChange={(e, checked) =>
                                    this.setState({
                                      checkedFiles: {
                                        ...this.state.checkedFiles,
                                        [file.name]: checked,
                                      },
                                    })
                                  }
                                />
                              </TableCell>
                              <TableCell>{file.name}</TableCell>
                              <TableCell>
                                <Select
                                  className="fileTypeSelect"
                                  value={this.state.fileTypes[file.name] || 0}
                                  onChange={(e) => {
                                    this.setState({
                                      fileTypes: {
                                        ...this.state.fileTypes,
                                        [file.name]: parseInt(
                                          e.target.value,
                                          10
                                        ),
                                      },
                                      replacesDocs: {
                                        ...this.state.replacesDocs,
                                        [file.name]: [],
                                      },
                                    });
                                  }}
                                >
                                  {getChildrenOf(
                                    this.state.mainLevels,
                                    this.props.getContractorDocumentypes
                                  ).map((item) => {
                                    return (
                                      <MenuItem key={item.id} value={item.id}>
                                        {item.name}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </TableCell>
                              <TableCell>
                                {possibleAlternatives.length === 0 ? (
                                  <i>Aiempia versioita ei löytynyt</i>
                                ) : (
                                  <div style={{ width: '500px' }}>
                                    <ReactSelect
                                      isMulti={false}
                                      placeholder={'Valitse'}
                                      value={
                                        this.state.replacesDocs[file.name] &&
                                        this.state.replacesDocs[file.name]
                                          .length > 0
                                          ? this.state.replacesDocs[
                                              file.name
                                            ][0]
                                          : { label: '-', value: 0 }
                                      }
                                      onChange={(
                                        value: ValueType<Model.Item>
                                      ) => {
                                        //
                                        if (value && !Array.isArray(value)) {
                                          this.setState({
                                            replacesDocs: {
                                              ...this.state.replacesDocs,
                                              [file.name]: [
                                                value as Model.Item,
                                              ],
                                            },
                                          });
                                        }
                                      }}
                                      options={[
                                        { label: '-', value: 0 },
                                        ...possibleAlternatives.map((item) => {
                                          return {
                                            label: item.fileName,
                                            value: item.id,
                                          };
                                        }),
                                      ]}
                                    />
                                  </div>
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                    <div className="saveDocuments">
                      {Object.keys(this.state.fileTypes).length ===
                      this.state.selectedFiles.length ? (
                        <Button
                          color="primary"
                          onClick={() => {
                            if (!this.state.isUploading) {
                              this.uploadDocuments();
                            }
                          }}
                        >
                          {translate('upload.save')}
                        </Button>
                      ) : null}
                    </div>
                  </>
                )}

                {this.state.isUploading && !this.state.isUploadReady ? (
                  <Loading loading={true} />
                ) : null}
                {this.state.uploadedFiles &&
                this.state.uploadedFiles.length > 0 ? (
                  <DocumentTable
                    title={translate('contractorDocumentPackage.updatedFiles')}
                    rows={(this.state.uploadedFiles || []).map((row) => ({
                      ...row,
                    }))}
                    keys={{
                      fileName: 'Tiedosto',
                      fileSize: 'Koko',
                    }}
                    rowClick={(row) => {
                      this.setState({ navigateToDocumentId: row.id });
                    }}
                  />
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
      </MainLayout>
    );
  }
}

const ContractorDocumentUploader = appstate.StateConnector(
  container.StateConnector(AbstractContracts)
);

export default injectIntl(ContractorDocumentUploader);
