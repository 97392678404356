import * as React from 'react';
import * as _ from 'lodash';
import MainLayout from 'layout/MainLayout';
import Button from 'components/Common/Button';
import { pureDocumentSearch } from 'state/api';
import { SearchColumn } from '../../../../shared/src/model/index';
import { document } from '../../../../shared/src/model/projectSchema';
import { DataTable } from 'components/Common/DataTable';
import { LinearProgress, Input } from '@material-ui/core';
import { errorMessage } from 'components/Common/Notifier';

interface SearchPlaygroundProps {}

//
const TilaajanDokumentitHaku = {
  name: '',
  searchFromFileContents: false,
  freeText: '',
  contractFilter: [],
  itemIds: [],
  documentCategories: [],
  documentTypes: [],
  documentStates: [],
  fileTypes: [],
  pitemTypes: [],
  cols: [
    'name',
    'aItems',
    'pItems',
    'description',
    'workflowStateName',
    'designerDocumentType',
    'revision',
    'modified',
    'versionNumber',
  ] as SearchColumn[],
  searchMainCategory: 24,
  isInspectionDocument: false,
  targetType: 'document' as 'document',
  contractorWorkTypes: [],
  designDisciplines: [],
  contractorDocumentTypes: [],
  inspectionDocumentTypes: [],
  roomIds: [],
  parentDocumentIds: [],
};

const UrakDokumentitHaku = {
  name: '',
  searchFromFileContents: false,
  freeText: '',
  contractFilter: [],
  itemIds: [],
  documentCategories: [],
  documentTypes: [],
  documentStates: [],
  fileTypes: [],
  pitemTypes: [],
  cols: [
    'name',
    'aItems',
    'pItems',
    'description',
    'workflowStateName',
    'designerDocumentType',
    'revision',
    'modified',
    'versionNumber',
  ] as SearchColumn[],
  searchMainCategory: 60,
  isInspectionDocument: false,
  targetType: 'document' as 'document',
  contractorWorkTypes: [],
  designDisciplines: [],
  contractorDocumentTypes: [],
  inspectionDocumentTypes: [],
  roomIds: [],
  parentDocumentIds: [],
};

export function SearchPlayground(props: SearchPlaygroundProps) {
  const [searchResults, setSearchResults] = React.useState<document[]>([]);
  const [searchIsOn, setSearchIsOn] = React.useState(false);
  const [contractId, setContractId] = React.useState('');
  return (
    <MainLayout view="adminTools">
      Urakan ID:
      <Input
        value={contractId}
        onChange={e => {
          setContractId(e.target.value);
        }}
      />
      <Button
        disabled={searchIsOn}
        onClick={async () => {
          try {
            setSearchIsOn(true);
            setSearchResults(
              await pureDocumentSearch({
                ...TilaajanDokumentitHaku,
                contractFilter: contractId ? [Number(contractId)] : [],
              })
            );
            setSearchIsOn(false);
          } catch (e) {
            console.error(e);
            errorMessage(e);
            setSearchIsOn(false);
          }
        }}
      >
        Tilaajan Doc haku
      </Button>
      <Button
        disabled={searchIsOn}
        onClick={async () => {
          setSearchIsOn(true);
          setSearchResults(await pureDocumentSearch(UrakDokumentitHaku));
          setSearchIsOn(false);
        }}
      >
        Urakoitsijan Doc haku
      </Button>
      {searchIsOn ? <LinearProgress /> : false}
      <DataTable
        rows={searchResults}
        keys={[
          'id',
          'updated_at',
          'updater_name',
          'name',
          'owner_name',
          'filename',
          'filesize',
          'aitem_name',
          'description',
        ]}
      />
    </MainLayout>
  );
}
