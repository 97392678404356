import {
  ConnectedRouter,
  connectRouter,
  routerMiddleware,
} from 'connected-react-router';
import * as H from 'history';
import { SnackbarProvider } from 'notistack';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import reduxThunk from 'redux-thunk';
import App from './App';
import ConnectedIntlProvider from './ConnectedIntlProvider';
import { unregister } from './registerServiceWorker';
import { reducerObject } from './state/reducers/index';

const connectHistory = (h: H.History<any>) =>
  combineReducers({
    router: connectRouter(h),
    ...reducerObject,
  });

const history = H.createBrowserHistory();

// the application global state can be imported from here
export const store = createStore(
  connectHistory(history),
  compose(
    applyMiddleware(routerMiddleware(history), reduxThunk),
    window['__REDUX_DEVTOOLS_EXTENSION__']
      ? window['__REDUX_DEVTOOLS_EXTENSION__']()
      : (f: any) => f
  )
);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedIntlProvider>
      <ConnectedRouter history={history}>
        <SnackbarProvider
          maxSnack={3}
          autoHideDuration={8000}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          style={{ marginTop: '50px' }}
          hideIconVariant={true}
        >
          <App />
        </SnackbarProvider>
      </ConnectedRouter>
    </ConnectedIntlProvider>
  </Provider>,
  document.getElementById('root') as HTMLElement
);
unregister();
