import * as React from 'react';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import ToggleArea from '../Common/ToggleArea';
import * as Model from '../../../../shared/src/model';
import Moment from 'react-moment';

interface IRouterProps {
  rows: Model.DocStateHistoryRow[];
}

interface IRouterState {
  email: string;
  password: string;
}

export class StatusHistory extends React.Component<
  IRouterProps & InjectedIntlProps,
  IRouterState
> {
  render() {
    const t = (id: string) => this.props.intl.formatMessage({ id });

    return (
      <ToggleArea 
        title={t('documentMeta.statusHistory')}
      >
        <ol className="statusHistory">
          {this.props.rows.map(historyItem => (
            <li key={historyItem.id}>
              <div className="metadata">
                <span className="date">
                  <Moment
                    date={historyItem.dateStr}
                    format="DD.MM.YYYY HH:mm"
                  />
                </span>

                <span title={t(`documentMeta.${historyItem.newStateName}`)}>
                  {t(`documentMeta.${historyItem.newStateName}`)}
                </span>

                <span title={historyItem.userEmail}>{historyItem.userEmail}</span>
              </div>

              {historyItem.reasonForRejection ? (
                <div className="reasonForRejection">
                  <h3>
                    <FormattedMessage id="documentMeta.reasonForRejection" />
                  </h3>
                  <p>{historyItem.reasonForRejection}</p>
                </div>
              ) : null}
            </li>
          ))}
        </ol>
      </ToggleArea>
    );
  }
}

export default injectIntl(StatusHistory);
