import * as React from 'react';
import * as _ from 'lodash';
import { SubPropertyProps, ListRow } from '../index';
import { defaultValueStr, usePropertyCollections } from '../utils';
import { Link } from 'react-router-dom';
import { ClassNames } from 'properties/batch';

type RefOrRefs = 'ref' | 'refs';

export const GenericRefPropertyWithFetch = (
  props: SubPropertyProps<RefOrRefs>
) => {
  const { data, viewMode } = props;

  const value = React.useMemo(
    () => (data.value instanceof Array ? data.value : [data.value]),
    [data]
  );
  const [items] = usePropertyCollections(
    value,
    data.params!.refClass! as ClassNames,
    ['display.name']
  );
  const contents = items
    ? items.map((item, i) => {
        if (!item) {
          return null;
        }
        return [
          i > 0 ? ', ' : null,
          <Link
            key={value[i]}
            to={`/demo/${data.params!.refClass!}/${value[i]}`}
          >
            {item['display.name'] ? item['display.name'].value : '?'}
          </Link>,
        ];
      })
    : defaultValueStr(data);

  switch (viewMode) {
    case 'list':
      return <ListRow data={data} contents={contents} />;
    default:
      return <span>{contents}</span>;
  }
};

export const GenericRefPropertyWithChildren = (
  props: SubPropertyProps<RefOrRefs>
) => {
  const { data, viewMode } = props;
  const value = React.useMemo(
    () => (data.value instanceof Array ? data.value : [data.value]),
    [data]
  );
  const contents =
    data && data.children
      ? data.children.map((item, i) => [
          i > 0 ? ', ' : null,
          <Link
            key={value[i]}
            to={`/demo/${data.params!.refClass!}/${value[i]}`}
          >
            {item['display.name']
              ? item['display.name'].value
              : item['name']
              ? item['name'].value
              : (() => {
                  const [txtPropName] = Object.keys(item).filter(
                    (key) => item[key] && item[key].typedef === 'text'
                  );
                  if (txtPropName) {
                    const prop = item[txtPropName];
                    if (prop) {
                      return String(prop.value);
                    }
                  }
                  return `${item.id}`;
                })()}
          </Link>,
        ])
      : defaultValueStr(data);
  switch (viewMode) {
    case 'list':
      return <ListRow data={data} contents={contents} />;
    default:
      return <span>{contents}</span>;
  }
};

export const GenericRefProperty = (props: SubPropertyProps<RefOrRefs>) => {
  return props.data && props.data.children ? (
    <GenericRefPropertyWithChildren {...props} />
  ) : (
    <GenericRefPropertyWithFetch {...props} />
  );
};

// Special type of property meant for references to a class
// by their id number(s), which must be used for deducing the
// rendered data
const RefProperty = (props: SubPropertyProps<RefOrRefs>) => {
  if (!props.data.params || !props.data.params.refClass) return null;

  return <GenericRefProperty {...props} />;
};

export default RefProperty;
