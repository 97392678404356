import * as React from 'react';
import { useState } from 'react';
import { Paper, Tab, Tabs, Button } from '@material-ui/core';
import { useFormatMessage } from '../../../utils/translateHook';
import { Route } from 'react-router';
import useRouter from 'utils/routerHook';
import Loading from '../../Common/Loading';
import axios from 'axios';
import { APIService } from '../../../services/api/src/frontend/api';

import { readFileToWorksheet } from '../../../utils/excel';
import FileInput from '../../Common/FileInput';
import { DataTable } from 'components/Common/DataTable';

const API = APIService(axios);

type DocSystemRow = {
  state: string;
  index: number;
  doc: string;
  value: string;
};
interface ExcelProps {
  onData?: (data: Array<DocSystemRow>) => void;
}

function ReadExcel(props: ExcelProps) {
  // files
  const [files, setFiles] = React.useState<File[]>([]);
  const t = useFormatMessage();
  return (
    <FileInput
      id="item_excel_import"
      files={files}
      label={t('adminTools.selectDocsToSystemsExcel')}
      fileListRenderer={() => <div />}
      onChange={async (files) => {
        const excelData = await readFileToWorksheet(files[0]);
        console.log(excelData);
        const sheet: { [key: string]: any } =
          excelData.Sheets[excelData.SheetNames[0]];

        let i = 1;
        const rows: any[] = [];
        while (i <= 6 || (sheet[`A${i}`] && sheet[`B${i}`])) {
          if (sheet[`A${i}`] && sheet[`B${i}`]) {
            rows.push({
              state: '-',
              index: i,
              doc: sheet[`A${i}`].v,
              value: sheet[`B${i}`].v,
            });
          }
          i++;
        }
        console.log(rows);
        if (props.onData) {
          props.onData(rows);
          setFiles([]);
        }
      }}
    />
  );
}

export enum PItemPages {
  TargetImport = 0,
  PhaseContractBuilding,
}

function DocToSystem() {
  const [rows, setRows] = React.useState<Array<DocSystemRow>>();
  const [info, setInfo] = React.useState('');
  const [isUpdating, setIsUpdating] = React.useState(false);
  return (
    <div>
      <ReadExcel onData={setRows} />
      {isUpdating ? (
        <>
          <Loading loading={true} />
          <i>{info}</i>
        </>
      ) : (
        <Button
          onClick={async () => {
            if (rows) {
              setIsUpdating(true);
              setInfo('Started update');
              let i = 0;
              const total = rows.length;
              const newRows: DocSystemRow[] = [];
              for (const row of rows) {
                setInfo(`row ${i++}/${total}`);
                newRows.push((await API.updateDocsToSystems([row]))[0]);
              }
              setIsUpdating(false);
              setRows(newRows);
            }
          }}
        >
          Save
        </Button>
      )}

      <DataTable rows={rows || []} keys={['state', 'index', 'doc', 'value']} />
    </div>
  );
}

export default function MainView() {
  const t = useFormatMessage();
  const router = useRouter();

  // When the view is opened, '/admin-tools/docs' route triggers some errors
  // as it's not one of the sub tabs. 'false' here will set the Tabs component to state
  // where none of the Tabs is selected
  const currentTab =
    router.location.pathname === '/admin-tools/docs'
      ? false
      : router.location.pathname;
  const [activeTab, setActiveTab] = useState(currentTab);

  return (
    <Paper square={true}>
      <Tabs
        indicatorColor="primary"
        value={activeTab}
        textColor="primary"
        onChange={(e, newValue) => {
          setActiveTab(newValue);
          router.history.push(newValue);
        }}
      >
        <Tab
          label={t('adminTools.docToSystem')}
          value="/admin-tools/docs/doc-system"
        />
      </Tabs>
      <Route path="/admin-tools/docs/doc-system" component={DocToSystem} />
    </Paper>
  );
}
