import './document.scss';

import * as React from 'react';
import * as _ from 'lodash';

import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import Breadcrumbs from '../Common/Breadcrumbs';
import { Property, EditProperty } from 'properties';
import MainLayout from '../../layout/MainLayout';
import { usePropertyCollection } from 'properties/utils';
import Loading from 'components/Common/Loading';
import { ClassNames } from 'properties/batch';
import { ClassViewParams } from 'properties/ClassViewParams';
import { Button } from '@material-ui/core';

interface ClassDemoProps {
  match: {
    params: {
      className: string;
      id: number;
      mode?: string;
    };
  };
}

const ClassDemo: React.FC<ClassDemoProps> = ({
  match: {
    params: { className, id },
  },
}) => {
  // , setValue, saveValue
  const [row, setValue, save] = usePropertyCollection(
    id,
    className as ClassNames,
    ClassViewParams[className]
  );

  return (
    <MainLayout>
      <Breadcrumbs>
        <Link to="/">
          <FormattedMessage id="headers.home" />
        </Link>
        <FormattedMessage id={`generics.header.${className}`} />
      </Breadcrumbs>

      <h1>
        <FormattedMessage id={`generics.header.${className}`} /> - {id}
      </h1>

      <div className="grid gridCol1">
        <div className="documentMetaContainer">
          <div className="documentMeta component">
            <div className="content">
              <EditProperty
                id={id}
                className="aitem"
                fields={['name', 'name_long', 'is_hidden']}
              />

              {row ? (
                <div>
                  <dl style={{ gridTemplateColumns: '100%' }}>
                    {Object.keys(row).map((key) => (
                      <Property
                        key={key}
                        data={row[key]}
                        viewMode="list"
                        editMode={true}
                        onChange={(value) => {
                          console.log(row, value);
                          setValue(value);
                        }}
                      />
                    ))}
                  </dl>
                  <Button onClick={() => save(row)}>Save</Button>
                </div>
              ) : (
                <div>
                  <Loading loading={true} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default ClassDemo;
