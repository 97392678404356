import * as React from 'react';
import MainLayout from '../../layout/MainLayout';
import { Link } from 'react-router-dom';
import axios from 'axios';

import * as Model from '../../../../shared/src/model';
import './document.scss';
import DocumentPackage from './DocumentPackage';
import { Button } from '@material-ui/core';
import { PriorityHigh } from '@material-ui/icons';
import './document.scss';
import Breadcrumbs from '../Common/Breadcrumbs';
import { errorMessage } from '../Common/Notifier';
import { APIService } from '../../services/api/src/frontend/api';
import { useUnsafeFormatMessage } from 'utils/translateHook';
import * as _ from 'lodash';
import { useAppContext } from 'utils/AppContextProvider';
import { SubPropertyProps, Property, PropertyProps } from '../../properties';
import {
  flippedBooleanProperty,
  orderFieldsWithPriority,
  getPropertiesByFields,
  usePropertyCollection,
} from '../../properties/utils';
import { CollectionFetchParams } from 'properties/batch';
import { ClassViewParams } from 'properties/ClassViewParams';
import { PitemParentsProperty } from 'components/Common/ItemView';

const API = APIService(axios);

interface IPitemProps {
  match: {
    params: {
      itemId: number;
      mode?: string;
    };
  };
}

const BooleanProperty = (props: SubPropertyProps<'boolean'>) => {
  const t = useUnsafeFormatMessage();
  const state = props.data.value;

  if (props.editMode) {
    return (
      <>
        {
          <Button
            variant="text"
            color="primary"
            onClick={() =>
              props.onChange &&
              props.onChange(flippedBooleanProperty(props.data))
            }
          >
            <PriorityHigh
              style={{
                marginTop: '-3px',
                marginRight: '8px',
              }}
            />
            {state ? t('buttons.unsetAsTracked') : t('buttons.setAsTracked')}
          </Button>
        }
      </>
    );
  } else {
    return state ? (
      <h2 className="isTracked">
        <span className="dot red" />
        {t('pitem.isTracked')}
      </h2>
    ) : null;
  }
};

const CustomProperty = (props: PropertyProps<any>) => {
  if (!props.data) return null;

  const subProps: SubPropertyProps<any> = { ...props, data: props.data! };

  switch (props.data.typedef) {
    case 'boolean':
      return <BooleanProperty {...subProps} />;
    case 'refs':
      return props.data.field === 'parents' ? (
        <PitemParentsProperty {...props} />
      ) : (
        <Property {...props} />
      );
    default:
      return <Property {...props} />;
  }
};

export const PitemView: React.FC<IPitemProps> = (props) => {
  // Hooks
  const appState = useAppContext();
  const t = useUnsafeFormatMessage();
  const removedProps = [
    'room_pole_start',
    'room_pole_end',
    'route_id',
    'mfilesid',
    'system_id',
    'display.name',
    'parents',
  ];
  const [doc, setDoc] = usePropertyCollection(
    props.match.params.itemId,
    'pitem',
    [
      ...ClassViewParams.pitem.filter(
        (prop) =>
          !removedProps.find(
            (removed) => removed === prop || removed === prop[0]
          )
      ),
      ['parents', 'name', 'name_long', ['pitemtypes', 'name']],
    ] as CollectionFetchParams<'pitem'>,
    (properties) => setValidatedDoc(properties)
  );
  const [, setValidatedDoc] = React.useState<Model.PropertyCollection<any>>();
  const [isEditMode, setIsEditMode] = React.useState(false);

  const [editedProperties, setEditedProperties] = React.useState<{
    [key: string]: any;
  }>({});

  React.useEffect(() => {
    setEditedProperties({});
    // const field = validatedDoc || doc;
    // if (field) {
    //   setDoc(field);
    // }
  }, [isEditMode]);
  // End hooks

  if (!doc) {
    return <MainLayout />;
  }

  const savePitem = async (isTracked: Model.GenericProp<'boolean'>) => {
    try {
      API.setTrackedStatus({
        id: isTracked.id!,
        isTracked: isTracked.value ? true : false,
      });
    } catch (e) {
      setDoc(isTracked);
      // setDoc({
      //   ...doc,
      //   is_tracked: flippedBooleanProperty(isTracked),
      // });
      errorMessage(e.message ? e.message : String(e));
    }
  };

  const toggleTrackedStatus = (data: Model.GenericProp<'boolean'>) => {
    if (doc) {
      setDoc(data);
      // setDoc({ ...doc, is_tracked: data });
      savePitem(data);
    }
  };

  const [name, parents, is_tracked] = getPropertiesByFields(
    doc,
    'name',
    'parents',
    'is_tracked'
  );

  const orderedFields = orderFieldsWithPriority(doc, [
    'name',
    'name_long',
    'systems_path',
    'area_id',
    'work_aitems',
    'pitemtypes',
    'parents',
    'specifier',
    'criticality_class_id',
    'warranty_expires',
    'upper_equipment_id',
    'secondary_hierarchy_id',
    'additional_information',
    'additional_information2',
    'route_start_pos',
    'route_end_pos',
  ]);

  const hasErrors = (doc: Model.PropertyCollection<any>) => {
    if (doc) {
      return Object.keys(doc).reduce((prev, key) => {
        const prop = doc[key];
        if (prop.updateStatus && prop.updateStatus.errorMessage) {
          return true;
        }
        return prev;
      }, false);
    }
    return false;
  };

  return (
    <MainLayout>
      <Breadcrumbs>
        <Link to="/">{t('headers.home')}</Link>
        <span>{t('pitem.targets')}</span>
        <CustomProperty data={name} viewMode="default" />
      </Breadcrumbs>

      <h1>{t('headers.item')}</h1>
      {<CustomProperty data={is_tracked} viewMode="default" />}

      <div className="grid gridCols3">
        <div className="documentMetaContainer">
          <div className="documentMeta component">
            <h2>
              <CustomProperty data={name} viewMode="default" />
            </h2>
            <div>
              <Button onClick={() => setIsEditMode(!isEditMode)}>
                {isEditMode ? 'Cancel' : 'Edit'}
              </Button>
              {isEditMode ? (
                <Button
                  onClick={async () => {
                    console.log(editedProperties);
                    // if (Object.keys(editedProperties).length === 0) {
                    //   setIsEditMode(false);
                    //   return;
                    // }
                    // const res = await updateProperties(editedProperties);
                    // setDoc({ ...doc, ...res[0] });
                    // if (!hasErrors(res[0])) {
                    //   setValidatedDoc({ ...doc, ...res[0] });
                    //   setIsEditMode(false);
                    // }
                  }}
                >
                  Save
                </Button>
              ) : null}
            </div>

            {hasErrors(doc) ? (
              <div
                style={{
                  padding: '10px',
                  backgroundColor: '#ffaa88',
                  color: 'white',
                }}
              >
                Osa lomakkeen tiedoista oli väärin. Ole hyvä ja tarkasta arvot
              </div>
            ) : null}
            <div className="actions">
              {appState.user && appState.user.is_superuser ? (
                <CustomProperty
                  data={is_tracked}
                  editMode={isEditMode}
                  viewMode="default"
                  onChange={toggleTrackedStatus}
                />
              ) : null}
            </div>
            <div className="content">
              <form>
                <dl>
                  {orderedFields.map((propName, index) =>
                    doc[propName] ? (
                      <CustomProperty
                        key={propName}
                        data={doc[propName]}
                        viewMode="list"
                        editMode={isEditMode}
                        onChange={(data) => {
                          console.log(data);
                          setEditedProperties({
                            ...editedProperties,
                            [propName]: data,
                          });
                        }}
                      />
                    ) : null
                  )}
                </dl>
              </form>
            </div>
          </div>
        </div>
        {parents && parents.value ? (
          <DocumentPackage
            openByDefault={true}
            itemIds={parents.value as number[]}
            itemName={name && name.value ? (name.value as string) : ''}
          />
        ) : null}
      </div>
    </MainLayout>
  );
};

export default PitemView;
