import * as React from 'react';
import * as applicationState from '../../state/reducers/ApplicationState';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import Help from '../Common/Help';

import './gdpr.scss';

import PublicLayout from '../../layout/PublicLayout';
import { Link } from 'react-router-dom';

interface IGDPRProps extends applicationState.IProps {}

interface IGDPRState {}

type GDPRProps = IGDPRProps & InjectedIntlProps;

class AbstractGDPR extends React.Component<GDPRProps, IGDPRState> {
  constructor(props: GDPRProps) {
    super(props);

    this.state = {};
  }

  public render() {
    const t = (id: string) => this.props.intl.formatMessage({ id });

    return (
      <PublicLayout>
        <div className="grid gridCols1">
          <Link className="backToLogin" to="/">
            {t('passwordReset.backToLogin')}
          </Link>
          <Help content={'general.gdpr'} purpose="gdpr" />
        </div>
      </PublicLayout>
    );
  }
}

const GDPR = applicationState.StateConnector(AbstractGDPR);

export default injectIntl(GDPR);
