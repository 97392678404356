import * as React from 'react';
import * as _ from 'lodash';

import './propertyTable.scss';

import { DataView, ItemCellView } from '../components/Common/ItemView';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';

// import { getCollectionParamsKeys } from './utils';
import { PropertyCollection } from '../../../shared/src/model';
// import { Property, PropertyProps } from './index';
import { useFormatMessage } from 'utils/translateHook';
import { CollectionFetchParams, ClassNames } from './batch';

export type PropertyTableRenderers = Array<{
  id: string;
  title: React.FC<any>;
  cell: React.FC<{
    row: PropertyCollection<any>;
  }>;
}>;
interface PropertyTableProps<T extends ClassNames> {
  className: T;
  ids: number[];
  fields: CollectionFetchParams<T> | undefined;
  defaultRowsPerPage?: number;
  rowsPerPageOptions?: number[];
  onClick?: (item: PropertyCollection<any>) => void;
  rowStyles?: (
    row: PropertyCollection<any>
  ) => { [key: string]: string | undefined };
  onUserUpdateDefaultRowCount?: (rowCount: number) => void;
  empty?: string | Element | JSX.Element;
  headerClick?: (key: string) => void;
  renderers: PropertyTableRenderers;
}

const PropertyTable = <T extends ClassNames>(props: PropertyTableProps<T>) => {
  const {
    className,
    ids,
    fields,
    defaultRowsPerPage,
    rowsPerPageOptions,
    onClick,
    rowStyles,
    onUserUpdateDefaultRowCount,
    empty,
    renderers,
  } = props;

  const t = useFormatMessage();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    defaultRowsPerPage ? defaultRowsPerPage : 20
  );

  const cellWrapper = React.useCallback(
    (props) => <TableCell>{props.children}</TableCell>,
    [className + ids.join(',')]
  );

  const givenProps = React.useMemo(() => fields, [(fields || []).join(',')]);

  const addedRowStyles = rowStyles
    ? rowStyles
    : (_row: PropertyCollection<any>) => ({});

  return ids.length === 0 ? (
    <p>{empty}</p>
  ) : (
    <Paper style={{ overflow: 'scroll' }}>
      <Table className="dataTable">
        <TableHead>
          <TableRow>
            <TablePagination
              className="tablePagination"
              rowsPerPageOptions={
                rowsPerPageOptions ? rowsPerPageOptions : [20, 50, 100, 200]
              }
              count={ids.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                native: true,
              }}
              onChangePage={(_e, page: number) => setPage(page)}
              onChangeRowsPerPage={(event) => {
                const newRowsPerPage = parseInt(event.target.value, 10);
                setRowsPerPage(newRowsPerPage);
                if (onUserUpdateDefaultRowCount)
                  onUserUpdateDefaultRowCount(newRowsPerPage);
              }}
              labelRowsPerPage={t('documentTable.labelRowsPerPage')}
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} / ${count}`
              }
            />
          </TableRow>
          <TableRow>
            {renderers.map(({ id, title: Title }) => (
              <Title key={`${id}-title`} />
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {ids
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((id) => (
              <DataView
                key={className + id}
                className={className}
                id={id}
                properties={givenProps}
                render={(row) => {
                  if (!row) return <></>;
                  return (
                    <TableRow
                      onClick={() => {
                        if (onClick) onClick(row!);
                      }}
                      style={{
                        cursor: onClick ? 'pointer' : 'hand',
                        ...addedRowStyles(row!),
                      }}
                    >
                      <ItemCellView
                        row={row}
                        cellWrapper={cellWrapper}
                        renderers={renderers}
                      />
                    </TableRow>
                  );
                }}
              />
            ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default PropertyTable;
