/****************************************************************************************
 *                                                                                       *
 *   Redux Reducers and React Context API Provider/Consumer for state ApplicationState   *
 *   Generated by ts2redux from Source file ../ApplicationState.ts                       *
 *                                                                                       *
 ****************************************************************************************/

import * as immer from 'immer';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { IState } from './index';
import * as React from 'react';
import axios from 'axios';
import { APIService } from '../../services/api/src/frontend/api';
import { AuthService } from '../../services/auth/src/frontend/api';
import { DocumentService } from '../../services/document/src/frontend/api';
import * as Model from '../../../../shared/src/model';
import * as _ from 'lodash';
import * as schema from '../../../../shared/src/model/projectSchema';
import {
  ActionStatusDetail,
  SnackDetails,
  TranslationDetails,
} from '../helpers/Action';
import { createPitemType } from '../api';

const API = APIService(axios);
const Auth = AuthService(axios);
const Docs = DocumentService(axios);

export enum LoginStatus {
  Unknown = 'unknown',
  Logged = 'logged',
  NotLogged = 'notLogged',
  Pending = 'pending',
  Failed = 'failed',
}

export enum PasswordResetStatus {
  Unknown = 'unknown',
  Success = 'success',
  Failed = 'failed',
  Pending = 'pending',
}

export interface IUserDetails extends schema.user {
  requiredAgreements?: schema.user_agreements[];
}

export interface IDocument {
  id: number;
  name: string;
}

export interface ISettings {
  language: string;
}

export interface IPasswords {
  passwordNew: string;
  passwordOld: string;
}
export interface IPasswordToken {
  password: string;
  token: string;
}

export interface ILoginResponse extends Model.APLoginResponse {
  success?: boolean;
  user?: Model.APUser;
}

export type ILoginStatus = Model.APLoginStatus;

export type IContractsPermissions = Model.ContractsPermissions;

export type IUserSearches = Model.UserSearches;

type StatusCodes = keyof AppActionStatus;

export interface AppActionStatus {
  getPhases: ActionStatusDetail;
  getAreas: ActionStatusDetail;
  getSystems: ActionStatusDetail;
  getBuildingElementTree: ActionStatusDetail;
  acceptingAgreements: ActionStatusDetail;
  sendPasswordResetRequest: ActionStatusDetail;
  resetPassword: ActionStatusDetail;
  resetPasswordWithToken: ActionStatusDetail;
  getFileTypes: ActionStatusDetail;
  getRooms: ActionStatusDetail;
}

const createAction = (name: StatusCodes, loading = true) => ({
  name,
  loading,
  loaded: false,
  snack: undefined,
});

export interface SystemState {
  hasSystemError: boolean;
  error?: any;
}

export interface UIItem {
  value: number;
  label: string;
}

export type Panes = 'search' | 'upload';

export type UserSettings = Model.UserSettings;

export type SearchState = Model.SearchState;

/**
 * @redux true
 */
export class ApplicationState {
  // System error conditions
  systemState: SystemState = {
    hasSystemError: false,
  };
  setSystemError(err: any) {
    this.systemState.hasSystemError = true;
    this.systemState.error = err;
  }

  setSystemDown(down: boolean) {
    this.systemDown = down;
  }

  loading: boolean = false;
  systemDown: boolean = false;
  isSidebarOpen: boolean = false;
  selectedPanel: Panes = 'search';
  loginStatus: LoginStatus = LoginStatus.Unknown;
  appstatus: AppActionStatus = {
    getPhases: createAction('getPhases', false),
    getAreas: createAction('getAreas', false),
    getSystems: createAction('getSystems', false),
    getBuildingElementTree: createAction('getBuildingElementTree', false),
    acceptingAgreements: createAction('acceptingAgreements', false),
    sendPasswordResetRequest: createAction('sendPasswordResetRequest', false),
    resetPassword: createAction('resetPassword', false),
    resetPasswordWithToken: createAction('resetPasswordWithToken', false),
    getFileTypes: createAction('getFileTypes', false),
    getRooms: createAction('getRooms', false),
  };

  user?: IUserDetails;

  userSettings: UserSettings = {};

  searchState: SearchState = {
    currentPage: 0,
  };

  tokenValidity?: Model.TokenValidityResponse;

  async getUserSettings() {
    try {
      this.userSettings = await API.getUserSettings();
    } catch (e) {
      console.error(e);
    }
  }

  async getSystemState() {
    try {
      const status = await Auth.getServiceDown();
      this.setSystemDown(status.down);
    } catch (e) {
      this.setSystemDown(true);
    }
  }

  async updateSearchState(newSettings: SearchState) {
    this.searchState = newSettings;
  }

  async resetSearchState() {
    this.searchState = { currentPage: 0 };
  }

  async saveUserSettings(newSettings: UserSettings) {
    try {
      await API.saveUserSettings({ ...this.userSettings, ...newSettings });
    } catch (e) {
      console.error(e);
    }

    await this.getUserSettings();
  }

  passwordResetStatus: PasswordResetStatus = PasswordResetStatus.Unknown;
  loginResponse: ILoginResponse = { userid: 0 };

  // replaces "permissions"
  userPermissions?: Model.UserRolesAndContracts;
  userContractsOrig: Model.ContractInfo[] = [];
  contractTree: { [key: number]: Model.DBAItemTree } = {};
  // buildingItemTree: { [key: number]: schema.pitem } = {};

  // Selected Areas in the Search Navi
  searchNaviSelectedAreas: number[] = [];
  buildingItemTree: schema.pitem[] = [];
  rooms: Model.UIDocument[] = [];
  appDocumentCategories: Model.DocumentCategory[] = [];
  appDocumentStates: schema.documentstate[] = [];
  appDocumentActions: schema.action[] = [];
  categoryTree: Model.DocumentCategory[] = [];
  pitemTypes: schema.pitem_type[] = [];
  criticalityClasses: schema.criticality_class[] = [];
  typeConversions: { [key: string]: string } = {};
  fileTypes: string[] = [];
  areas: Model.Area[] = [];
  phases: any[] = [];
  systems: schema.system[] = [];

  contractPermissions?: Model.ContractPermissions;

  get targetPitemTypes(): schema.pitem_type[] {
    return this.pitemTypes.filter((t) => {
      if (t.name === 'all') return false;
      return t;
    });
  }

  get equipmentTypes(): schema.pitem_type[] {
    return this.pitemTypes.filter((t) => {
      return !!t.upper_type_id;
    });
  }

  get userContracts(): Model.ContractInfo[] {
    return this.userContractsOrig.sort((left, right) =>
      left.aitem_name.localeCompare(right.aitem_name)
    );
  }

  /**
   * List of contracts the user can write contractor documents.
   */
  get userContractorDocWritableContracts(): Model.ContractInfo[] {
    return this.userContractsOrig
      .filter((c) => {
        if (
          this.contractPermissions &&
          this.contractPermissions.createPermissions
        ) {
          return (
            this.contractPermissions.createPermissions.filter((item) => {
              return (
                item.aitem_id === c.aitem_id &&
                item.document_category_name === 'URAK_DOC'
              );
            }).length > 0
          );
        }
        return false;
      })
      .sort((left, right) => left.aitem_name.localeCompare(right.aitem_name));
  }

  get reverseTypeConversions(): { [key: string]: string } {
    const res: { [key: string]: string } = {};
    for (const key of Object.keys(this.typeConversions)) {
      res[this.typeConversions[key]] = key;
    }
    return res;
  }

  setSearchNaviSelectedAreas(areas: number[]) {
    this.searchNaviSelectedAreas = areas;
  }

  setSidebarVisibility(options: { isOpen: boolean; selectedPanel?: Panes }) {
    this.isSidebarOpen = options.isOpen;
    if (typeof options.selectedPanel !== 'undefined') {
      this.selectedPanel = options.selectedPanel;
    }
  }
  /**
   * @dispatch true
   */
  async ApplicationStateDispatcher(action: any) {
    // this is a magic method so that ts2redux knows to generate custom dispatcher
    // function which can be used to dispatch any redux actions inside the current model
  }

  async getFileTypes() {
    const action = this.appstatus.getFileTypes;
    try {
      this.setStatus({ action });
      const res = await API.getFileExtensions();
      this.assignFileTypes(res);
      this.setSuccess({ action });
    } catch (e) {
      this.setError({ action, message: e.message });
    }
  }

  async getPhases() {
    const action = this.appstatus.getPhases;
    try {
      this.setStatus({ action });
      const res = await API.getPhases();
      // TODO: Do the sorting in backend
      this.assignPhases(res.sort((a, b) => a.name!.localeCompare(b.name!)));
      this.setSuccess({ action });
    } catch (e) {
      this.setError({ action, message: e.message });
    }
  }

  async getSystems() {
    const action = this.appstatus.getSystems;
    try {
      this.setStatus({ action });
      const res = await Docs.fetchAllSystems();
      this.assignSystems(res);
      this.setSuccess({ action });
    } catch (e) {
      this.setError({ action, message: e.message });
    }
  }

  get getContractorWorkTypes(): Model.DocumentCategory[] {
    const list: Model.DocumentCategory[] = [];

    const walkTree = (
      nodes: Model.DocumentCategory[] | undefined,
      fn: (node: Model.DocumentCategory) => void
    ) => {
      if (nodes) {
        for (const n of nodes) {
          fn(n);
          walkTree(n.children, fn);
        }
      }
    };

    this.categoryTree
      .filter((c) => c.name === 'URAK_DOC')
      .forEach((cat) => {
        if (cat.children) {
          cat.children
            .filter((c) => c.name === 'Työlaji')
            .forEach((tl) => {
              walkTree(tl.children, (node) => {
                if (node.isLeaf) {
                  list.push(node);
                }
              });
            });
        }
      });
    return list.sort((a, b) => a.name.localeCompare(b.name));
  }
  get getContractorMainDocumentypes(): Model.DocumentCategory[] {
    return this.appDocumentCategories.filter(
      (cat) => cat.parentName === 'Dokumentin tyyppi'
    );
  }

  get getContractorDocumentPackageId(): number[] {
    return this.appDocumentCategories
      .filter((cat) => cat.name === 'URAK_DP')
      .map((value) => value.id);
  }

  get getContractorDocumentypes(): Model.DocumentCategory[] {
    const parents = this.appDocumentCategories.filter(
      (cat) => cat.parentName === 'Dokumentin tyyppi'
    );
    return this.appDocumentCategories.filter((cat) => {
      return parents.filter((p) => cat.parentId === p.id).length > 0;
    });
  }

  get designDisciplines(): Model.DocumentCategory[] {
    return this.appDocumentCategories.filter(
      (cat) => cat.parentName === 'Suunnitteluala'
    );
  }

  get docCategories(): Model.DocumentCategory[] {
    return this.appDocumentCategories.filter(
      (cat) => cat.parentName === 'Dokumenttityyppi'
    );
  }

  get inspectionDocCategories(): Model.DocumentCategory[] {
    return this.appDocumentCategories.filter(
      (cat) => cat.parentName === 'TARKASTUSASIAKIRJA_DOC'
    );
  }

  async createPitemType(name: string) {
    await createPitemType(name);
    await this.getDocumentCategories();
  }

  setStatus(params: {
    action: ActionStatusDetail;
    snack?: SnackDetails;
    finished?: boolean;
  }) {
    this.appstatus[params.action.name] = {
      ...this.appstatus[params.action.name],
      loading: params.finished ? false : true,
      loaded: false,
      snack: params.snack,
    };
  }

  setSuccess(params: {
    action: ActionStatusDetail;
    translation?: TranslationDetails;
  }) {
    const { action, ...snackParams } = params;
    const snack =
      snackParams && ({ variant: 'success', ...snackParams } as SnackDetails);
    this.setStatus({ action, snack });
    this.appstatus[action.name] = {
      ...this.appstatus[action.name],
      loading: false,
      loaded: true,
      snack,
    };
  }

  setError(params: {
    action: ActionStatusDetail;
    message?: string;
    translation?: TranslationDetails;
  }) {
    const { action, ...snackParams } = params;
    const snack = { variant: 'error', ...snackParams } as SnackDetails;
    this.setStatus({ action, snack });
    this.appstatus[params.action.name] = {
      ...this.appstatus[params.action.name],
      loading: false,
      loaded: false,
      snack,
    };
  }

  resetAppSnacks() {
    Object.keys(this.appstatus).map((key) => {
      this.appstatus[key].snack = undefined;
    });
  }

  async getUserPermissions() {
    // has: roles, contracts, items
    // this.userPermissions = await API.getUserRolesAndContracts();
  }

  async getUserHasPermissions(token: string) {
    this.tokenValidity = await Auth.checkTokenValidity(token);
  }

  get permissions(): IContractsPermissions[] | undefined {
    return this.userPermissions ? this.userPermissions.contracts : undefined;
  }

  get filteredPermissions(): IContractsPermissions[] {
    if (!this.userPermissions || !this.userPermissions.contracts) {
      return [];
    }
    return this.userPermissions.contracts.slice().sort((a, b) => {
      return a.aitem_name.localeCompare(b.aitem_name);
    });
  }

  async getUserContracts() {
    this.loading = true;
    const res = await API.getUserContracts();
    this.assignUserContracts(res);
    this.loading = false;
  }

  assignUserContracts(contracts: Model.ContractInfo[]) {
    if (contracts) {
      this.userContractsOrig = contracts;
    }
  }

  async getBuildingElementTree(contractIds: number[]) {
    const action = this.appstatus.getBuildingElementTree;
    try {
      // Get areas too for the UI filtering purposes
      await this.getAreas();
      this.setStatus({ action });
      const res = await API.getBuildingsForContracts(contractIds);
      this.assignBuildingElementTree(res);
      this.setSuccess({ action });
    } catch (e) {
      this.setError({ action, message: e.message });
    }
  }

  async getRooms(param: { contractIds: number[]; pitemIds: number[] }) {
    if (param.contractIds.length === 0 && param.pitemIds.length === 0) {
      // Empty room array if no contract or pitem filter selected
      this.assignRooms([]);
      return;
    }
    const roomType = this.pitemTypes.filter((t) => t.name === 'Huone').pop();
    const action = this.appstatus.getRooms;
    try {
      this.setStatus({ action });
      const res = await API.searchPItems({
        contractFilter: param.contractIds,
        buildingPartIds: param.pitemIds,
        pitemTypes: roomType ? [roomType.id] : [0],
      });
      this.assignRooms(res.rows);
      this.setSuccess({ action });
    } catch (e) {
      this.setError({ action, message: e.message });
    }
  }

  get uiBuildingElements(): UIItem[] {
    // collect all buildings which are allowed based on the selected area in the UI
    const selectedAreaBuildings: { [key: number]: boolean } = {};
    this.searchNaviSelectedAreas.forEach((areaid) => {
      this.areas
        .filter((area) => area.id === areaid)
        .forEach((area) => {
          area.sections.forEach((section) => {
            if (section.pitem_id) {
              selectedAreaBuildings[section.pitem_id] = true;
            }
          });
        });
    });

    // if areas are used in filtering hasAreaFilter is true
    const hasAreaFilter = this.searchNaviSelectedAreas.length > 0;

    // filter the building tree based on the values of this list
    return this.buildingItemTree
      .filter((item) => {
        if (hasAreaFilter) {
          return selectedAreaBuildings[item.id];
        }
        return true;
      })
      .map((item) => ({
        value: item.id,
        label: item.name + ' / ' + item.name_long,
      }));
  }

  assignBuildingElementTree(buildingElementTree: schema.pitem[]) {
    this.buildingItemTree = buildingElementTree;
  }

  assignFileTypes(fileTypes: string[]) {
    this.fileTypes = fileTypes;
  }

  assignPhases(phases: any[]) {
    this.phases = phases;
  }

  assignSystems(systems: schema.system[]) {
    this.systems = systems;
  }

  assignRooms(rooms: Model.UIDocument[]) {
    this.rooms = rooms;
  }

  async getAreas() {
    const action = this.appstatus.getAreas;
    try {
      this.setStatus({ action });
      const res = await API.getAreas();
      this.assignAreas(res);
      this.setSuccess({ action });
    } catch (error) {
      console.error(error);
      this.setError({ action, message: error.message });
    }
  }

  assignAreas(areas: Model.Area[]) {
    this.areas = areas;
  }

  async updateArea(area: schema.area) {
    try {
      await API.updateArea(area);
    } catch (error) {
      console.error(error);
    }
  }

  async updateAreaSection(section: schema.area_section) {
    try {
      await API.updateAreaSection(section);
    } catch (error) {
      console.error(error);
    }
  }

  async getDocumentCategories() {
    try {
      const res = await Docs.getClientSettings();

      // #1632: Sort the pitem type list so that "Kaikki kohdetyypit"
      // is the first item of the list, "Rakennustuotteet" is the second
      // and the rest will fallow in alphabetical order
      const firstPitemTypes: schema.pitem_type[] = [];
      const first = res.pitemTypes
        .filter(
          (type) => type.name === 'Kaikki kohdetyypit' || type.name === 'Kaikki'
        )
        .pop();
      const second = res.pitemTypes
        .filter((type) => type.name === 'Rakennustuotteet')
        .pop();

      if (first) {
        firstPitemTypes.push(first);
      }

      if (second) {
        firstPitemTypes.push(second);
      }

      const sortedPitemTypes = firstPitemTypes.concat(
        res.pitemTypes
          .filter((type) => {
            if (
              type.name === 'Kaikki kohdetyypit' ||
              type.name === 'Kaikki' ||
              type.name === 'Rakennustuotteet'
            ) {
              return false;
            }
            return true;
          })
          .sort((a, b) => a.name!.localeCompare(b.name!))
      );

      this.appDocumentStates = res.states;
      this.appDocumentActions = res.actions;
      this.pitemTypes = sortedPitemTypes;
      this.typeConversions = res.typeConversions;
      this.contractPermissions = await API.getContractPermissions();
      this.criticalityClasses = res.criticality_classes;
      this.assignDocumentCategories(res.categories);
    } catch (e) {
      this.logout();
    }
  }

  assignDocumentCategories(origCats: Model.DocumentCategory[]) {
    this.appDocumentCategories = origCats;

    const categories = origCats.map((cat) => ({
      ...cat,
      children: [],
      isLeaf: true,
    }));

    const perId = categories.reduce((prev, curr: Model.DocumentCategory) => {
      return {
        ...prev,
        [curr.id]: curr,
      };
    }, {});
    categories.forEach((cat) => {
      if (cat.parentId) {
        const p = perId[cat.parentId];
        if (!p.children) {
          p.children = [];
        }
        p.children.push(cat);
        p.isLeaf = false;
      }
    });
    this.categoryTree = categories
      .filter((cat) => {
        return !cat.parentId;
      })
      .map((cat) => {
        return perId[cat.id];
      });
  }

  async sendPasswordResetRequest(email: string) {
    const action = this.appstatus.sendPasswordResetRequest;
    this.setStatus({ action });

    try {
      this.setStatus({ action });
      await Auth.requestPasswordChangeLink(email);
      this.setSuccess({ action });
    } catch (e) {
      this.setError({ action, message: e.message });
    }
  }

  async resetPassword(passwords: IPasswords) {
    const action = this.appstatus.resetPassword;
    try {
      this.setStatus({ action });
      await Auth.changePassword(passwords);
      this.setSuccess({ action });
    } catch (error) {
      this.setError({
        action,
        translation: { id: error.response.data.id || 'Error' },
      });
    }
    // await Auth.changePassword(passwords);
    // this.passwordChanged();
  }

  passwordChanged() {
    this.passwordResetStatus = PasswordResetStatus.Success;
  }

  async resetPasswordWithToken(params: IPasswordToken) {
    const action = this.appstatus.resetPasswordWithToken;
    try {
      this.setStatus({ action });
      await Auth.changePasswordWithToken({ ...params });
      this.setSuccess({ action, translation: { id: 'passwordReset.success' } });
    } catch (error) {
      if (error.response.data.id) {
        this.setError({ action, translation: { id: error.response.data.id } });
      } else {
        this.setError({ action, message: error.message });
      }
    }
  }

  public async login(loginInfo: Model.APLoginCredidentials) {
    try {
      await Auth.login(loginInfo);
      await Docs.getClientSettings();
      this.setLoginStatus(LoginStatus.Logged);
      try {
        this.getUserDetails();
      } catch (e) {
        this.setSystemError(e);
      }
    } catch (e) {
      this.setLoginStatus(LoginStatus.Failed);
    }
  }

  setLoginStatus(loginStatus: LoginStatus) {
    this.loginStatus = loginStatus;
  }

  public async getUserDetails() {
    try {
      if (this.systemState.hasSystemError) {
        return;
      }
      const details = await Auth.getUserDetails();
      this.setUserDetails(details);
      this.setLoginStatus(LoginStatus.Logged);
    } catch (e) {
      this.setSystemError(e);
      this.setLoginStatus(LoginStatus.NotLogged);
    }
  }

  setUserDetails(user?: IUserDetails) {
    this.user = user;
  }

  public async acceptingAgreements(agreementIds: number[]) {
    const action = this.appstatus.acceptingAgreements;
    this.setStatus({ action });
    try {
      await Auth.agreedAgreements(agreementIds);
      this.setSuccess({ action });
    } catch (e) {
      this.setError({ action, message: e.message });
    }
  }

  public async logout() {
    try {
      await Auth.signout();

      // Delete cookie so we don't reauthenticate instantly back to the application
      document.cookie = 'Authorization=; expires=Thu, 01 Jan 1970 00:00:00 GMT';
      this.setUserDetails(undefined);
      this.setLoginStatus(LoginStatus.NotLogged);
    } catch (e) {
      throw new Error(e);
    }
  }

  async checkLoginStatus() {
    if (this.systemState.hasSystemError) {
      return;
    }
    try {
      const loginStatus: ILoginStatus = await Auth.loginStatus();
      if (loginStatus.logged) {
        this.loginStatus = LoginStatus.Logged;
        await this.getUserDetails();
      } else {
        this.loginStatus = LoginStatus.NotLogged;
      }
    } catch (e) {
      this.setSystemError(e);
    }
  }
}

export interface IContainerPropsMethods {
  setSystemError: (err: any) => any;
  setSystemDown: (down: boolean) => any;
  getUserSettings: () => any;
  getSystemState: () => any;
  updateSearchState: (newSettings: SearchState) => any;
  resetSearchState: () => any;
  saveUserSettings: (newSettings: UserSettings) => any;
  setSearchNaviSelectedAreas: (areas: number[]) => any;
  setSidebarVisibility: (options: {
    isOpen: boolean;
    selectedPanel?: Panes;
  }) => any;
  ApplicationStateDispatcher: (action: any) => any;
  getFileTypes: () => any;
  getPhases: () => any;
  getSystems: () => any;
  createPitemType: (name: string) => any;
  setStatus: (params: {
    action: ActionStatusDetail;
    snack?: SnackDetails;
    finished?: boolean;
  }) => any;
  setSuccess: (params: {
    action: ActionStatusDetail;
    translation?: TranslationDetails;
  }) => any;
  setError: (params: {
    action: ActionStatusDetail;
    message?: string;
    translation?: TranslationDetails;
  }) => any;
  resetAppSnacks: () => any;
  getUserPermissions: () => any;
  getUserHasPermissions: (token: string) => any;
  getUserContracts: () => any;
  assignUserContracts: (contracts: Model.ContractInfo[]) => any;
  getBuildingElementTree: (contractIds: number[]) => any;
  getRooms: (param: { contractIds: number[]; pitemIds: number[] }) => any;
  assignBuildingElementTree: (buildingElementTree: schema.pitem[]) => any;
  assignFileTypes: (fileTypes: string[]) => any;
  assignPhases: (phases: any[]) => any;
  assignSystems: (systems: schema.system[]) => any;
  assignRooms: (rooms: Model.UIDocument[]) => any;
  getAreas: () => any;
  assignAreas: (areas: Model.Area[]) => any;
  updateArea: (area: schema.area) => any;
  updateAreaSection: (section: schema.area_section) => any;
  getDocumentCategories: () => any;
  assignDocumentCategories: (origCats: Model.DocumentCategory[]) => any;
  sendPasswordResetRequest: (email: string) => any;
  resetPassword: (passwords: IPasswords) => any;
  passwordChanged: () => any;
  resetPasswordWithToken: (params: IPasswordToken) => any;
  login: (loginInfo: Model.APLoginCredidentials) => any;
  setLoginStatus: (loginStatus: LoginStatus) => any;
  getUserDetails: () => any;
  setUserDetails: (user?: IUserDetails) => any;
  acceptingAgreements: (agreementIds: number[]) => any;
  logout: () => any;
  checkLoginStatus: () => any;
}
export interface IApplicationState {
  // System error conditions
  systemState: SystemState;
  loading: boolean;
  systemDown: boolean;
  isSidebarOpen: boolean;
  selectedPanel: Panes;
  loginStatus: LoginStatus;
  appstatus: AppActionStatus;
  user?: IUserDetails;
  userSettings: UserSettings;
  searchState: SearchState;
  tokenValidity?: Model.TokenValidityResponse;
  passwordResetStatus: PasswordResetStatus;
  loginResponse: ILoginResponse;
  // replaces "permissions"
  userPermissions?: Model.UserRolesAndContracts;
  userContractsOrig: Model.ContractInfo[];
  contractTree: {
    [key: number]: Model.DBAItemTree;
  };
  // buildingItemTree: { [key: number]: schema.pitem } = {};
  // Selected Areas in the Search Navi
  searchNaviSelectedAreas: number[];
  buildingItemTree: schema.pitem[];
  rooms: Model.UIDocument[];
  appDocumentCategories: Model.DocumentCategory[];
  appDocumentStates: schema.documentstate[];
  appDocumentActions: schema.action[];
  categoryTree: Model.DocumentCategory[];
  pitemTypes: schema.pitem_type[];
  criticalityClasses: schema.criticality_class[];
  typeConversions: {
    [key: string]: string;
  };
  fileTypes: string[];
  areas: Model.Area[];
  phases: any[];
  systems: schema.system[];
  contractPermissions?: Model.ContractPermissions;
}
export const systemStateSelectorFn = (state: IApplicationState): SystemState =>
  state.systemState;
export const loadingSelectorFn = (state: IApplicationState): boolean =>
  state.loading;
export const systemDownSelectorFn = (state: IApplicationState): boolean =>
  state.systemDown;
export const isSidebarOpenSelectorFn = (state: IApplicationState): boolean =>
  state.isSidebarOpen;
export const selectedPanelSelectorFn = (state: IApplicationState): Panes =>
  state.selectedPanel;
export const loginStatusSelectorFn = (state: IApplicationState): LoginStatus =>
  state.loginStatus;
export const appstatusSelectorFn = (
  state: IApplicationState
): AppActionStatus => state.appstatus;
export const userSelectorFn = (
  state: IApplicationState
): IUserDetails | undefined => state.user;
export const userSettingsSelectorFn = (
  state: IApplicationState
): UserSettings => state.userSettings;
export const searchStateSelectorFn = (state: IApplicationState): SearchState =>
  state.searchState;
export const tokenValiditySelectorFn = (
  state: IApplicationState
): Model.TokenValidityResponse | undefined => state.tokenValidity;
export const passwordResetStatusSelectorFn = (
  state: IApplicationState
): PasswordResetStatus => state.passwordResetStatus;
export const loginResponseSelectorFn = (
  state: IApplicationState
): ILoginResponse => state.loginResponse;
export const userPermissionsSelectorFn = (
  state: IApplicationState
): Model.UserRolesAndContracts | undefined => state.userPermissions;
export const userContractsOrigSelectorFn = (
  state: IApplicationState
): Model.ContractInfo[] => state.userContractsOrig;
export const contractTreeSelectorFn = (
  state: IApplicationState
): {
  [key: number]: Model.DBAItemTree;
} => state.contractTree;
export const searchNaviSelectedAreasSelectorFn = (
  state: IApplicationState
): number[] => state.searchNaviSelectedAreas;
export const buildingItemTreeSelectorFn = (
  state: IApplicationState
): schema.pitem[] => state.buildingItemTree;
export const roomsSelectorFn = (state: IApplicationState): Model.UIDocument[] =>
  state.rooms;
export const appDocumentCategoriesSelectorFn = (
  state: IApplicationState
): Model.DocumentCategory[] => state.appDocumentCategories;
export const appDocumentStatesSelectorFn = (
  state: IApplicationState
): schema.documentstate[] => state.appDocumentStates;
export const appDocumentActionsSelectorFn = (
  state: IApplicationState
): schema.action[] => state.appDocumentActions;
export const categoryTreeSelectorFn = (
  state: IApplicationState
): Model.DocumentCategory[] => state.categoryTree;
export const pitemTypesSelectorFn = (
  state: IApplicationState
): schema.pitem_type[] => state.pitemTypes;
export const criticalityClassesSelectorFn = (
  state: IApplicationState
): schema.criticality_class[] => state.criticalityClasses;
export const typeConversionsSelectorFn = (
  state: IApplicationState
): {
  [key: string]: string;
} => state.typeConversions;
export const fileTypesSelectorFn = (state: IApplicationState): string[] =>
  state.fileTypes;
export const areasSelectorFn = (state: IApplicationState): Model.Area[] =>
  state.areas;
export const phasesSelectorFn = (state: IApplicationState): any[] =>
  state.phases;
export const systemsSelectorFn = (state: IApplicationState): schema.system[] =>
  state.systems;
export const contractPermissionsSelectorFn = (
  state: IApplicationState
): Model.ContractPermissions | undefined => state.contractPermissions;
export const targetPitemTypesSelectorFnCreator = () =>
  createSelector([pitemTypesSelectorFn], (pitemTypes) => {
    const o = new ApplicationState();
    o.pitemTypes = pitemTypes;
    return o.targetPitemTypes;
  });
export const targetPitemTypesSelector = targetPitemTypesSelectorFnCreator();
export const equipmentTypesSelectorFnCreator = () =>
  createSelector([pitemTypesSelectorFn], (pitemTypes) => {
    const o = new ApplicationState();
    o.pitemTypes = pitemTypes;
    return o.equipmentTypes;
  });
export const equipmentTypesSelector = equipmentTypesSelectorFnCreator();
export const userContractsSelectorFnCreator = () =>
  createSelector([userContractsOrigSelectorFn], (userContractsOrig) => {
    const o = new ApplicationState();
    o.userContractsOrig = userContractsOrig;
    return o.userContracts;
  });
export const userContractsSelector = userContractsSelectorFnCreator();
export const userContractorDocWritableContractsSelectorFnCreator = () =>
  createSelector(
    [userContractsOrigSelectorFn, contractPermissionsSelectorFn],
    (userContractsOrig, contractPermissions) => {
      const o = new ApplicationState();
      o.userContractsOrig = userContractsOrig;
      o.contractPermissions = contractPermissions;
      return o.userContractorDocWritableContracts;
    }
  );
export const userContractorDocWritableContractsSelector = userContractorDocWritableContractsSelectorFnCreator();
export const reverseTypeConversionsSelectorFnCreator = () =>
  createSelector([typeConversionsSelectorFn], (typeConversions) => {
    const o = new ApplicationState();
    o.typeConversions = typeConversions;
    return o.reverseTypeConversions;
  });
export const reverseTypeConversionsSelector = reverseTypeConversionsSelectorFnCreator();
export const getContractorWorkTypesSelectorFnCreator = () =>
  createSelector([categoryTreeSelectorFn], (categoryTree) => {
    const o = new ApplicationState();
    o.categoryTree = categoryTree;
    return o.getContractorWorkTypes;
  });
export const getContractorWorkTypesSelector = getContractorWorkTypesSelectorFnCreator();
export const getContractorMainDocumentypesSelectorFnCreator = () =>
  createSelector([appDocumentCategoriesSelectorFn], (appDocumentCategories) => {
    const o = new ApplicationState();
    o.appDocumentCategories = appDocumentCategories;
    return o.getContractorMainDocumentypes;
  });
export const getContractorMainDocumentypesSelector = getContractorMainDocumentypesSelectorFnCreator();
export const getContractorDocumentPackageIdSelectorFnCreator = () =>
  createSelector([appDocumentCategoriesSelectorFn], (appDocumentCategories) => {
    const o = new ApplicationState();
    o.appDocumentCategories = appDocumentCategories;
    return o.getContractorDocumentPackageId;
  });
export const getContractorDocumentPackageIdSelector = getContractorDocumentPackageIdSelectorFnCreator();
export const getContractorDocumentypesSelectorFnCreator = () =>
  createSelector([appDocumentCategoriesSelectorFn], (appDocumentCategories) => {
    const o = new ApplicationState();
    o.appDocumentCategories = appDocumentCategories;
    return o.getContractorDocumentypes;
  });
export const getContractorDocumentypesSelector = getContractorDocumentypesSelectorFnCreator();
export const designDisciplinesSelectorFnCreator = () =>
  createSelector([appDocumentCategoriesSelectorFn], (appDocumentCategories) => {
    const o = new ApplicationState();
    o.appDocumentCategories = appDocumentCategories;
    return o.designDisciplines;
  });
export const designDisciplinesSelector = designDisciplinesSelectorFnCreator();
export const docCategoriesSelectorFnCreator = () =>
  createSelector([appDocumentCategoriesSelectorFn], (appDocumentCategories) => {
    const o = new ApplicationState();
    o.appDocumentCategories = appDocumentCategories;
    return o.docCategories;
  });
export const docCategoriesSelector = docCategoriesSelectorFnCreator();
export const inspectionDocCategoriesSelectorFnCreator = () =>
  createSelector([appDocumentCategoriesSelectorFn], (appDocumentCategories) => {
    const o = new ApplicationState();
    o.appDocumentCategories = appDocumentCategories;
    return o.inspectionDocCategories;
  });
export const inspectionDocCategoriesSelector = inspectionDocCategoriesSelectorFnCreator();
export const permissionsSelectorFnCreator = () =>
  createSelector([userPermissionsSelectorFn], (userPermissions) => {
    const o = new ApplicationState();
    o.userPermissions = userPermissions;
    return o.permissions;
  });
export const permissionsSelector = permissionsSelectorFnCreator();
export const filteredPermissionsSelectorFnCreator = () =>
  createSelector([userPermissionsSelectorFn], (userPermissions) => {
    const o = new ApplicationState();
    o.userPermissions = userPermissions;
    return o.filteredPermissions;
  });
export const filteredPermissionsSelector = filteredPermissionsSelectorFnCreator();
export const uiBuildingElementsSelectorFnCreator = () =>
  createSelector(
    [
      searchNaviSelectedAreasSelectorFn,
      areasSelectorFn,
      buildingItemTreeSelectorFn,
    ],
    (searchNaviSelectedAreas, areas, buildingItemTree) => {
      const o = new ApplicationState();
      o.searchNaviSelectedAreas = searchNaviSelectedAreas;
      o.areas = areas;
      o.buildingItemTree = buildingItemTree;
      return o.uiBuildingElements;
    }
  );
export const uiBuildingElementsSelector = uiBuildingElementsSelectorFnCreator();

export interface IContainerPropsState extends IApplicationState {
  targetPitemTypes: schema.pitem_type[];
  equipmentTypes: schema.pitem_type[];
  userContracts: Model.ContractInfo[];
  userContractorDocWritableContracts: Model.ContractInfo[];
  reverseTypeConversions: {
    [key: string]: string;
  };
  getContractorWorkTypes: Model.DocumentCategory[];
  getContractorMainDocumentypes: Model.DocumentCategory[];
  getContractorDocumentPackageId: number[];
  getContractorDocumentypes: Model.DocumentCategory[];
  designDisciplines: Model.DocumentCategory[];
  docCategories: Model.DocumentCategory[];
  inspectionDocCategories: Model.DocumentCategory[];
  permissions: IContractsPermissions[] | undefined;
  filteredPermissions: IContractsPermissions[];
  uiBuildingElements: UIItem[];
}
export interface IProps extends IContainerPropsState, IContainerPropsMethods {}

function pick<T, K extends keyof T>(o: T, ...props: K[]) {
  return props.reduce((a, e) => ({ ...a, [e]: o[e] }), {}) as Pick<T, K>;
}
export function mapStateToPropsWithKeys<K extends keyof IContainerPropsState>(
  state: IState,
  keys: K[]
): Pick<IContainerPropsState, K> {
  return pick(state.ApplicationState as IContainerPropsState, ...keys);
}

export const mapStateToProps = (state: IState): IContainerPropsState => {
  return {
    systemState: state.ApplicationState.systemState,
    loading: state.ApplicationState.loading,
    systemDown: state.ApplicationState.systemDown,
    isSidebarOpen: state.ApplicationState.isSidebarOpen,
    selectedPanel: state.ApplicationState.selectedPanel,
    loginStatus: state.ApplicationState.loginStatus,
    appstatus: state.ApplicationState.appstatus,
    user: state.ApplicationState.user,
    userSettings: state.ApplicationState.userSettings,
    searchState: state.ApplicationState.searchState,
    tokenValidity: state.ApplicationState.tokenValidity,
    passwordResetStatus: state.ApplicationState.passwordResetStatus,
    loginResponse: state.ApplicationState.loginResponse,
    userPermissions: state.ApplicationState.userPermissions,
    userContractsOrig: state.ApplicationState.userContractsOrig,
    contractTree: state.ApplicationState.contractTree,
    searchNaviSelectedAreas: state.ApplicationState.searchNaviSelectedAreas,
    buildingItemTree: state.ApplicationState.buildingItemTree,
    rooms: state.ApplicationState.rooms,
    appDocumentCategories: state.ApplicationState.appDocumentCategories,
    appDocumentStates: state.ApplicationState.appDocumentStates,
    appDocumentActions: state.ApplicationState.appDocumentActions,
    categoryTree: state.ApplicationState.categoryTree,
    pitemTypes: state.ApplicationState.pitemTypes,
    criticalityClasses: state.ApplicationState.criticalityClasses,
    typeConversions: state.ApplicationState.typeConversions,
    fileTypes: state.ApplicationState.fileTypes,
    areas: state.ApplicationState.areas,
    phases: state.ApplicationState.phases,
    systems: state.ApplicationState.systems,
    contractPermissions: state.ApplicationState.contractPermissions,
    targetPitemTypes: targetPitemTypesSelector(state.ApplicationState),
    equipmentTypes: equipmentTypesSelector(state.ApplicationState),
    userContracts: userContractsSelector(state.ApplicationState),
    userContractorDocWritableContracts: userContractorDocWritableContractsSelector(
      state.ApplicationState
    ),
    reverseTypeConversions: reverseTypeConversionsSelector(
      state.ApplicationState
    ),
    getContractorWorkTypes: getContractorWorkTypesSelector(
      state.ApplicationState
    ),
    getContractorMainDocumentypes: getContractorMainDocumentypesSelector(
      state.ApplicationState
    ),
    getContractorDocumentPackageId: getContractorDocumentPackageIdSelector(
      state.ApplicationState
    ),
    getContractorDocumentypes: getContractorDocumentypesSelector(
      state.ApplicationState
    ),
    designDisciplines: designDisciplinesSelector(state.ApplicationState),
    docCategories: docCategoriesSelector(state.ApplicationState),
    inspectionDocCategories: inspectionDocCategoriesSelector(
      state.ApplicationState
    ),
    permissions: permissionsSelector(state.ApplicationState),
    filteredPermissions: filteredPermissionsSelector(state.ApplicationState),
    uiBuildingElements: uiBuildingElementsSelector(state.ApplicationState),
  };
};

function mapDispatchToPropsWithKeys<K extends keyof IContainerPropsMethods>(
  dispatch: any,
  keys: K[]
): Pick<IContainerPropsMethods, K> {
  return pick(mapDispatchToProps(dispatch), ...keys);
}

export const mapDispatchToProps = (dispatch: any): IContainerPropsMethods => {
  return {
    setSystemError: (err: any) => {
      return dispatch(RApplicationState.setSystemError(err));
    },
    setSystemDown: (down: boolean) => {
      return dispatch(RApplicationState.setSystemDown(down));
    },
    getUserSettings: () => {
      return dispatch(RApplicationState.getUserSettings());
    },
    getSystemState: () => {
      return dispatch(RApplicationState.getSystemState());
    },
    updateSearchState: (newSettings: SearchState) => {
      return dispatch(RApplicationState.updateSearchState(newSettings));
    },
    resetSearchState: () => {
      return dispatch(RApplicationState.resetSearchState());
    },
    saveUserSettings: (newSettings: UserSettings) => {
      return dispatch(RApplicationState.saveUserSettings(newSettings));
    },
    setSearchNaviSelectedAreas: (areas: number[]) => {
      return dispatch(RApplicationState.setSearchNaviSelectedAreas(areas));
    },
    setSidebarVisibility: (options: {
      isOpen: boolean;
      selectedPanel?: Panes;
    }) => {
      return dispatch(RApplicationState.setSidebarVisibility(options));
    },

    ApplicationStateDispatcher: (action: any) => {
      return dispatch(action);
    },
    getFileTypes: () => {
      return dispatch(RApplicationState.getFileTypes());
    },
    getPhases: () => {
      return dispatch(RApplicationState.getPhases());
    },
    getSystems: () => {
      return dispatch(RApplicationState.getSystems());
    },
    createPitemType: (name: string) => {
      return dispatch(RApplicationState.createPitemType(name));
    },
    setStatus: (params: {
      action: ActionStatusDetail;
      snack?: SnackDetails;
      finished?: boolean;
    }) => {
      return dispatch(RApplicationState.setStatus(params));
    },
    setSuccess: (params: {
      action: ActionStatusDetail;
      translation?: TranslationDetails;
    }) => {
      return dispatch(RApplicationState.setSuccess(params));
    },
    setError: (params: {
      action: ActionStatusDetail;
      message?: string;
      translation?: TranslationDetails;
    }) => {
      return dispatch(RApplicationState.setError(params));
    },
    resetAppSnacks: () => {
      return dispatch(RApplicationState.resetAppSnacks());
    },
    getUserPermissions: () => {
      return dispatch(RApplicationState.getUserPermissions());
    },
    getUserHasPermissions: (token: string) => {
      return dispatch(RApplicationState.getUserHasPermissions(token));
    },
    getUserContracts: () => {
      return dispatch(RApplicationState.getUserContracts());
    },
    assignUserContracts: (contracts: Model.ContractInfo[]) => {
      return dispatch(RApplicationState.assignUserContracts(contracts));
    },
    getBuildingElementTree: (contractIds: number[]) => {
      return dispatch(RApplicationState.getBuildingElementTree(contractIds));
    },
    getRooms: (param: { contractIds: number[]; pitemIds: number[] }) => {
      return dispatch(RApplicationState.getRooms(param));
    },
    assignBuildingElementTree: (buildingElementTree: schema.pitem[]) => {
      return dispatch(
        RApplicationState.assignBuildingElementTree(buildingElementTree)
      );
    },
    assignFileTypes: (fileTypes: string[]) => {
      return dispatch(RApplicationState.assignFileTypes(fileTypes));
    },
    assignPhases: (phases: any[]) => {
      return dispatch(RApplicationState.assignPhases(phases));
    },
    assignSystems: (systems: schema.system[]) => {
      return dispatch(RApplicationState.assignSystems(systems));
    },
    assignRooms: (rooms: Model.UIDocument[]) => {
      return dispatch(RApplicationState.assignRooms(rooms));
    },
    getAreas: () => {
      return dispatch(RApplicationState.getAreas());
    },
    assignAreas: (areas: Model.Area[]) => {
      return dispatch(RApplicationState.assignAreas(areas));
    },
    updateArea: (area: schema.area) => {
      return dispatch(RApplicationState.updateArea(area));
    },
    updateAreaSection: (section: schema.area_section) => {
      return dispatch(RApplicationState.updateAreaSection(section));
    },
    getDocumentCategories: () => {
      return dispatch(RApplicationState.getDocumentCategories());
    },
    assignDocumentCategories: (origCats: Model.DocumentCategory[]) => {
      return dispatch(RApplicationState.assignDocumentCategories(origCats));
    },
    sendPasswordResetRequest: (email: string) => {
      return dispatch(RApplicationState.sendPasswordResetRequest(email));
    },
    resetPassword: (passwords: IPasswords) => {
      return dispatch(RApplicationState.resetPassword(passwords));
    },
    passwordChanged: () => {
      return dispatch(RApplicationState.passwordChanged());
    },
    resetPasswordWithToken: (params: IPasswordToken) => {
      return dispatch(RApplicationState.resetPasswordWithToken(params));
    },
    login: (loginInfo: Model.APLoginCredidentials) => {
      return dispatch(RApplicationState.login(loginInfo));
    },
    setLoginStatus: (loginStatus: LoginStatus) => {
      return dispatch(RApplicationState.setLoginStatus(loginStatus));
    },
    getUserDetails: () => {
      return dispatch(RApplicationState.getUserDetails());
    },
    setUserDetails: (user?: IUserDetails) => {
      return dispatch(RApplicationState.setUserDetails(user));
    },
    acceptingAgreements: (agreementIds: number[]) => {
      return dispatch(RApplicationState.acceptingAgreements(agreementIds));
    },
    logout: () => {
      return dispatch(RApplicationState.logout());
    },
    checkLoginStatus: () => {
      return dispatch(RApplicationState.checkLoginStatus());
    },
  };
};

export function ConnectKeys<
  K extends keyof IApplicationState,
  J extends keyof IContainerPropsMethods
>(keys: K[], methods: J[]) {
  return connect(
    (state: IState) => mapStateToPropsWithKeys(state, keys),
    (dispatch: any) => mapDispatchToPropsWithKeys(dispatch, methods)
  );
}

export const StateConnector = connect(mapStateToProps, mapDispatchToProps);

const initApplicationState = () => {
  const o = new ApplicationState();
  return {
    systemState: o.systemState,
    loading: o.loading,
    systemDown: o.systemDown,
    isSidebarOpen: o.isSidebarOpen,
    selectedPanel: o.selectedPanel,
    loginStatus: o.loginStatus,
    appstatus: o.appstatus,
    user: o.user,
    userSettings: o.userSettings,
    searchState: o.searchState,
    tokenValidity: o.tokenValidity,
    passwordResetStatus: o.passwordResetStatus,
    loginResponse: o.loginResponse,
    userPermissions: o.userPermissions,
    userContractsOrig: o.userContractsOrig,
    contractTree: o.contractTree,
    searchNaviSelectedAreas: o.searchNaviSelectedAreas,
    buildingItemTree: o.buildingItemTree,
    rooms: o.rooms,
    appDocumentCategories: o.appDocumentCategories,
    appDocumentStates: o.appDocumentStates,
    appDocumentActions: o.appDocumentActions,
    categoryTree: o.categoryTree,
    pitemTypes: o.pitemTypes,
    criticalityClasses: o.criticalityClasses,
    typeConversions: o.typeConversions,
    fileTypes: o.fileTypes,
    areas: o.areas,
    phases: o.phases,
    systems: o.systems,
    contractPermissions: o.contractPermissions,
  };
};
const initWithMethodsApplicationState = () => {
  const o = new ApplicationState();
  return {
    systemState: o.systemState,
    loading: o.loading,
    systemDown: o.systemDown,
    isSidebarOpen: o.isSidebarOpen,
    selectedPanel: o.selectedPanel,
    loginStatus: o.loginStatus,
    appstatus: o.appstatus,
    user: o.user,
    userSettings: o.userSettings,
    searchState: o.searchState,
    tokenValidity: o.tokenValidity,
    passwordResetStatus: o.passwordResetStatus,
    loginResponse: o.loginResponse,
    userPermissions: o.userPermissions,
    userContractsOrig: o.userContractsOrig,
    contractTree: o.contractTree,
    searchNaviSelectedAreas: o.searchNaviSelectedAreas,
    buildingItemTree: o.buildingItemTree,
    rooms: o.rooms,
    appDocumentCategories: o.appDocumentCategories,
    appDocumentStates: o.appDocumentStates,
    appDocumentActions: o.appDocumentActions,
    categoryTree: o.categoryTree,
    pitemTypes: o.pitemTypes,
    criticalityClasses: o.criticalityClasses,
    typeConversions: o.typeConversions,
    fileTypes: o.fileTypes,
    areas: o.areas,
    phases: o.phases,
    systems: o.systems,
    contractPermissions: o.contractPermissions,
    setSystemError: o.setSystemError,
    setSystemDown: o.setSystemDown,
    getUserSettings: o.getUserSettings,
    getSystemState: o.getSystemState,
    updateSearchState: o.updateSearchState,
    resetSearchState: o.resetSearchState,
    saveUserSettings: o.saveUserSettings,
    setSearchNaviSelectedAreas: o.setSearchNaviSelectedAreas,
    setSidebarVisibility: o.setSidebarVisibility,
    ApplicationStateDispatcher: o.ApplicationStateDispatcher,
    getFileTypes: o.getFileTypes,
    getPhases: o.getPhases,
    getSystems: o.getSystems,
    createPitemType: o.createPitemType,
    setStatus: o.setStatus,
    setSuccess: o.setSuccess,
    setError: o.setError,
    resetAppSnacks: o.resetAppSnacks,
    getUserPermissions: o.getUserPermissions,
    getUserHasPermissions: o.getUserHasPermissions,
    getUserContracts: o.getUserContracts,
    assignUserContracts: o.assignUserContracts,
    getBuildingElementTree: o.getBuildingElementTree,
    getRooms: o.getRooms,
    assignBuildingElementTree: o.assignBuildingElementTree,
    assignFileTypes: o.assignFileTypes,
    assignPhases: o.assignPhases,
    assignSystems: o.assignSystems,
    assignRooms: o.assignRooms,
    getAreas: o.getAreas,
    assignAreas: o.assignAreas,
    updateArea: o.updateArea,
    updateAreaSection: o.updateAreaSection,
    getDocumentCategories: o.getDocumentCategories,
    assignDocumentCategories: o.assignDocumentCategories,
    sendPasswordResetRequest: o.sendPasswordResetRequest,
    resetPassword: o.resetPassword,
    passwordChanged: o.passwordChanged,
    resetPasswordWithToken: o.resetPasswordWithToken,
    login: o.login,
    setLoginStatus: o.setLoginStatus,
    getUserDetails: o.getUserDetails,
    setUserDetails: o.setUserDetails,
    acceptingAgreements: o.acceptingAgreements,
    logout: o.logout,
    checkLoginStatus: o.checkLoginStatus,
    targetPitemTypes: o.targetPitemTypes,
    equipmentTypes: o.equipmentTypes,
    userContracts: o.userContracts,
    userContractorDocWritableContracts: o.userContractorDocWritableContracts,
    reverseTypeConversions: o.reverseTypeConversions,
    getContractorWorkTypes: o.getContractorWorkTypes,
    getContractorMainDocumentypes: o.getContractorMainDocumentypes,
    getContractorDocumentPackageId: o.getContractorDocumentPackageId,
    getContractorDocumentypes: o.getContractorDocumentypes,
    designDisciplines: o.designDisciplines,
    docCategories: o.docCategories,
    inspectionDocCategories: o.inspectionDocCategories,
    permissions: o.permissions,
    filteredPermissions: o.filteredPermissions,
    uiBuildingElements: o.uiBuildingElements,
  };
};

/**
 * @generated true
 */
export class RApplicationState {
  private _state?: IApplicationState;
  private _dispatch?: <A extends {}, T extends {}>(action: A) => T;
  private _getState?: () => any;
  constructor(
    state?: IApplicationState,
    dispatch?: (action: any) => any,
    getState?: () => any
  ) {
    this._state = state;
    this._dispatch = dispatch;
    this._getState = getState;
  }
  get systemState(): SystemState {
    if (this._getState) {
      return this._getState().ApplicationState.systemState;
    } else {
      if (this._state) {
        return this._state.systemState;
      }
    }
    throw new Error('Invalid State in ApplicationState_systemState');
  }
  set systemState(value: SystemState) {
    if (this._state && typeof value !== 'undefined') {
      this._state.systemState = value;
    } else {
      // dispatch change for item systemState
      if (this._dispatch) {
        this._dispatch({
          type: ApplicationStateEnums.ApplicationState_systemState,
          payload: value,
        });
      }
    }
  }
  get loading(): boolean {
    if (this._getState) {
      return this._getState().ApplicationState.loading;
    } else {
      if (this._state) {
        return this._state.loading;
      }
    }
    throw new Error('Invalid State in ApplicationState_loading');
  }
  set loading(value: boolean) {
    if (this._state && typeof value !== 'undefined') {
      this._state.loading = value;
    } else {
      // dispatch change for item loading
      if (this._dispatch) {
        this._dispatch({
          type: ApplicationStateEnums.ApplicationState_loading,
          payload: value,
        });
      }
    }
  }
  get systemDown(): boolean {
    if (this._getState) {
      return this._getState().ApplicationState.systemDown;
    } else {
      if (this._state) {
        return this._state.systemDown;
      }
    }
    throw new Error('Invalid State in ApplicationState_systemDown');
  }
  set systemDown(value: boolean) {
    if (this._state && typeof value !== 'undefined') {
      this._state.systemDown = value;
    } else {
      // dispatch change for item systemDown
      if (this._dispatch) {
        this._dispatch({
          type: ApplicationStateEnums.ApplicationState_systemDown,
          payload: value,
        });
      }
    }
  }
  get isSidebarOpen(): boolean {
    if (this._getState) {
      return this._getState().ApplicationState.isSidebarOpen;
    } else {
      if (this._state) {
        return this._state.isSidebarOpen;
      }
    }
    throw new Error('Invalid State in ApplicationState_isSidebarOpen');
  }
  set isSidebarOpen(value: boolean) {
    if (this._state && typeof value !== 'undefined') {
      this._state.isSidebarOpen = value;
    } else {
      // dispatch change for item isSidebarOpen
      if (this._dispatch) {
        this._dispatch({
          type: ApplicationStateEnums.ApplicationState_isSidebarOpen,
          payload: value,
        });
      }
    }
  }
  get selectedPanel(): Panes {
    if (this._getState) {
      return this._getState().ApplicationState.selectedPanel;
    } else {
      if (this._state) {
        return this._state.selectedPanel;
      }
    }
    throw new Error('Invalid State in ApplicationState_selectedPanel');
  }
  set selectedPanel(value: Panes) {
    if (this._state && typeof value !== 'undefined') {
      this._state.selectedPanel = value;
    } else {
      // dispatch change for item selectedPanel
      if (this._dispatch) {
        this._dispatch({
          type: ApplicationStateEnums.ApplicationState_selectedPanel,
          payload: value,
        });
      }
    }
  }
  get loginStatus(): LoginStatus {
    if (this._getState) {
      return this._getState().ApplicationState.loginStatus;
    } else {
      if (this._state) {
        return this._state.loginStatus;
      }
    }
    throw new Error('Invalid State in ApplicationState_loginStatus');
  }
  set loginStatus(value: LoginStatus) {
    if (this._state && typeof value !== 'undefined') {
      this._state.loginStatus = value;
    } else {
      // dispatch change for item loginStatus
      if (this._dispatch) {
        this._dispatch({
          type: ApplicationStateEnums.ApplicationState_loginStatus,
          payload: value,
        });
      }
    }
  }
  get appstatus(): AppActionStatus {
    if (this._getState) {
      return this._getState().ApplicationState.appstatus;
    } else {
      if (this._state) {
        return this._state.appstatus;
      }
    }
    throw new Error('Invalid State in ApplicationState_appstatus');
  }
  set appstatus(value: AppActionStatus) {
    if (this._state && typeof value !== 'undefined') {
      this._state.appstatus = value;
    } else {
      // dispatch change for item appstatus
      if (this._dispatch) {
        this._dispatch({
          type: ApplicationStateEnums.ApplicationState_appstatus,
          payload: value,
        });
      }
    }
  }
  get user(): IUserDetails | undefined {
    if (this._getState) {
      return this._getState().ApplicationState.user;
    } else {
      if (this._state) {
        return this._state.user;
      }
    }
    return undefined;
  }
  set user(value: IUserDetails | undefined) {
    if (this._state && typeof value !== 'undefined') {
      this._state.user = value;
    } else {
      // dispatch change for item user
      if (this._dispatch) {
        this._dispatch({
          type: ApplicationStateEnums.ApplicationState_user,
          payload: value,
        });
      }
    }
  }
  get userSettings(): UserSettings {
    if (this._getState) {
      return this._getState().ApplicationState.userSettings;
    } else {
      if (this._state) {
        return this._state.userSettings;
      }
    }
    throw new Error('Invalid State in ApplicationState_userSettings');
  }
  set userSettings(value: UserSettings) {
    if (this._state && typeof value !== 'undefined') {
      this._state.userSettings = value;
    } else {
      // dispatch change for item userSettings
      if (this._dispatch) {
        this._dispatch({
          type: ApplicationStateEnums.ApplicationState_userSettings,
          payload: value,
        });
      }
    }
  }
  get searchState(): SearchState {
    if (this._getState) {
      return this._getState().ApplicationState.searchState;
    } else {
      if (this._state) {
        return this._state.searchState;
      }
    }
    throw new Error('Invalid State in ApplicationState_searchState');
  }
  set searchState(value: SearchState) {
    if (this._state && typeof value !== 'undefined') {
      this._state.searchState = value;
    } else {
      // dispatch change for item searchState
      if (this._dispatch) {
        this._dispatch({
          type: ApplicationStateEnums.ApplicationState_searchState,
          payload: value,
        });
      }
    }
  }
  get tokenValidity(): Model.TokenValidityResponse | undefined {
    if (this._getState) {
      return this._getState().ApplicationState.tokenValidity;
    } else {
      if (this._state) {
        return this._state.tokenValidity;
      }
    }
    return undefined;
  }
  set tokenValidity(value: Model.TokenValidityResponse | undefined) {
    if (this._state && typeof value !== 'undefined') {
      this._state.tokenValidity = value;
    } else {
      // dispatch change for item tokenValidity
      if (this._dispatch) {
        this._dispatch({
          type: ApplicationStateEnums.ApplicationState_tokenValidity,
          payload: value,
        });
      }
    }
  }
  get passwordResetStatus(): PasswordResetStatus {
    if (this._getState) {
      return this._getState().ApplicationState.passwordResetStatus;
    } else {
      if (this._state) {
        return this._state.passwordResetStatus;
      }
    }
    throw new Error('Invalid State in ApplicationState_passwordResetStatus');
  }
  set passwordResetStatus(value: PasswordResetStatus) {
    if (this._state && typeof value !== 'undefined') {
      this._state.passwordResetStatus = value;
    } else {
      // dispatch change for item passwordResetStatus
      if (this._dispatch) {
        this._dispatch({
          type: ApplicationStateEnums.ApplicationState_passwordResetStatus,
          payload: value,
        });
      }
    }
  }
  get loginResponse(): ILoginResponse {
    if (this._getState) {
      return this._getState().ApplicationState.loginResponse;
    } else {
      if (this._state) {
        return this._state.loginResponse;
      }
    }
    throw new Error('Invalid State in ApplicationState_loginResponse');
  }
  set loginResponse(value: ILoginResponse) {
    if (this._state && typeof value !== 'undefined') {
      this._state.loginResponse = value;
    } else {
      // dispatch change for item loginResponse
      if (this._dispatch) {
        this._dispatch({
          type: ApplicationStateEnums.ApplicationState_loginResponse,
          payload: value,
        });
      }
    }
  }
  get userPermissions(): Model.UserRolesAndContracts | undefined {
    if (this._getState) {
      return this._getState().ApplicationState.userPermissions;
    } else {
      if (this._state) {
        return this._state.userPermissions;
      }
    }
    return undefined;
  }
  set userPermissions(value: Model.UserRolesAndContracts | undefined) {
    if (this._state && typeof value !== 'undefined') {
      this._state.userPermissions = value;
    } else {
      // dispatch change for item userPermissions
      if (this._dispatch) {
        this._dispatch({
          type: ApplicationStateEnums.ApplicationState_userPermissions,
          payload: value,
        });
      }
    }
  }
  get userContractsOrig(): Model.ContractInfo[] {
    if (this._getState) {
      return this._getState().ApplicationState.userContractsOrig;
    } else {
      if (this._state) {
        return this._state.userContractsOrig;
      }
    }
    throw new Error('Invalid State in ApplicationState_userContractsOrig');
  }
  set userContractsOrig(value: Model.ContractInfo[]) {
    if (this._state && typeof value !== 'undefined') {
      this._state.userContractsOrig = value;
    } else {
      // dispatch change for item userContractsOrig
      if (this._dispatch) {
        this._dispatch({
          type: ApplicationStateEnums.ApplicationState_userContractsOrig,
          payload: value,
        });
      }
    }
  }
  get contractTree(): {
    [key: number]: Model.DBAItemTree;
  } {
    if (this._getState) {
      return this._getState().ApplicationState.contractTree;
    } else {
      if (this._state) {
        return this._state.contractTree;
      }
    }
    throw new Error('Invalid State in ApplicationState_contractTree');
  }
  set contractTree(value: { [key: number]: Model.DBAItemTree }) {
    if (this._state && typeof value !== 'undefined') {
      this._state.contractTree = value;
    } else {
      // dispatch change for item contractTree
      if (this._dispatch) {
        this._dispatch({
          type: ApplicationStateEnums.ApplicationState_contractTree,
          payload: value,
        });
      }
    }
  }
  get searchNaviSelectedAreas(): number[] {
    if (this._getState) {
      return this._getState().ApplicationState.searchNaviSelectedAreas;
    } else {
      if (this._state) {
        return this._state.searchNaviSelectedAreas;
      }
    }
    throw new Error(
      'Invalid State in ApplicationState_searchNaviSelectedAreas'
    );
  }
  set searchNaviSelectedAreas(value: number[]) {
    if (this._state && typeof value !== 'undefined') {
      this._state.searchNaviSelectedAreas = value;
    } else {
      // dispatch change for item searchNaviSelectedAreas
      if (this._dispatch) {
        this._dispatch({
          type: ApplicationStateEnums.ApplicationState_searchNaviSelectedAreas,
          payload: value,
        });
      }
    }
  }
  get buildingItemTree(): schema.pitem[] {
    if (this._getState) {
      return this._getState().ApplicationState.buildingItemTree;
    } else {
      if (this._state) {
        return this._state.buildingItemTree;
      }
    }
    throw new Error('Invalid State in ApplicationState_buildingItemTree');
  }
  set buildingItemTree(value: schema.pitem[]) {
    if (this._state && typeof value !== 'undefined') {
      this._state.buildingItemTree = value;
    } else {
      // dispatch change for item buildingItemTree
      if (this._dispatch) {
        this._dispatch({
          type: ApplicationStateEnums.ApplicationState_buildingItemTree,
          payload: value,
        });
      }
    }
  }
  get rooms(): Model.UIDocument[] {
    if (this._getState) {
      return this._getState().ApplicationState.rooms;
    } else {
      if (this._state) {
        return this._state.rooms;
      }
    }
    throw new Error('Invalid State in ApplicationState_rooms');
  }
  set rooms(value: Model.UIDocument[]) {
    if (this._state && typeof value !== 'undefined') {
      this._state.rooms = value;
    } else {
      // dispatch change for item rooms
      if (this._dispatch) {
        this._dispatch({
          type: ApplicationStateEnums.ApplicationState_rooms,
          payload: value,
        });
      }
    }
  }
  get appDocumentCategories(): Model.DocumentCategory[] {
    if (this._getState) {
      return this._getState().ApplicationState.appDocumentCategories;
    } else {
      if (this._state) {
        return this._state.appDocumentCategories;
      }
    }
    throw new Error('Invalid State in ApplicationState_appDocumentCategories');
  }
  set appDocumentCategories(value: Model.DocumentCategory[]) {
    if (this._state && typeof value !== 'undefined') {
      this._state.appDocumentCategories = value;
    } else {
      // dispatch change for item appDocumentCategories
      if (this._dispatch) {
        this._dispatch({
          type: ApplicationStateEnums.ApplicationState_appDocumentCategories,
          payload: value,
        });
      }
    }
  }
  get appDocumentStates(): schema.documentstate[] {
    if (this._getState) {
      return this._getState().ApplicationState.appDocumentStates;
    } else {
      if (this._state) {
        return this._state.appDocumentStates;
      }
    }
    throw new Error('Invalid State in ApplicationState_appDocumentStates');
  }
  set appDocumentStates(value: schema.documentstate[]) {
    if (this._state && typeof value !== 'undefined') {
      this._state.appDocumentStates = value;
    } else {
      // dispatch change for item appDocumentStates
      if (this._dispatch) {
        this._dispatch({
          type: ApplicationStateEnums.ApplicationState_appDocumentStates,
          payload: value,
        });
      }
    }
  }
  get appDocumentActions(): schema.action[] {
    if (this._getState) {
      return this._getState().ApplicationState.appDocumentActions;
    } else {
      if (this._state) {
        return this._state.appDocumentActions;
      }
    }
    throw new Error('Invalid State in ApplicationState_appDocumentActions');
  }
  set appDocumentActions(value: schema.action[]) {
    if (this._state && typeof value !== 'undefined') {
      this._state.appDocumentActions = value;
    } else {
      // dispatch change for item appDocumentActions
      if (this._dispatch) {
        this._dispatch({
          type: ApplicationStateEnums.ApplicationState_appDocumentActions,
          payload: value,
        });
      }
    }
  }
  get categoryTree(): Model.DocumentCategory[] {
    if (this._getState) {
      return this._getState().ApplicationState.categoryTree;
    } else {
      if (this._state) {
        return this._state.categoryTree;
      }
    }
    throw new Error('Invalid State in ApplicationState_categoryTree');
  }
  set categoryTree(value: Model.DocumentCategory[]) {
    if (this._state && typeof value !== 'undefined') {
      this._state.categoryTree = value;
    } else {
      // dispatch change for item categoryTree
      if (this._dispatch) {
        this._dispatch({
          type: ApplicationStateEnums.ApplicationState_categoryTree,
          payload: value,
        });
      }
    }
  }
  get pitemTypes(): schema.pitem_type[] {
    if (this._getState) {
      return this._getState().ApplicationState.pitemTypes;
    } else {
      if (this._state) {
        return this._state.pitemTypes;
      }
    }
    throw new Error('Invalid State in ApplicationState_pitemTypes');
  }
  set pitemTypes(value: schema.pitem_type[]) {
    if (this._state && typeof value !== 'undefined') {
      this._state.pitemTypes = value;
    } else {
      // dispatch change for item pitemTypes
      if (this._dispatch) {
        this._dispatch({
          type: ApplicationStateEnums.ApplicationState_pitemTypes,
          payload: value,
        });
      }
    }
  }
  get criticalityClasses(): schema.criticality_class[] {
    if (this._getState) {
      return this._getState().ApplicationState.criticalityClasses;
    } else {
      if (this._state) {
        return this._state.criticalityClasses;
      }
    }
    throw new Error('Invalid State in ApplicationState_criticalityClasses');
  }
  set criticalityClasses(value: schema.criticality_class[]) {
    if (this._state && typeof value !== 'undefined') {
      this._state.criticalityClasses = value;
    } else {
      // dispatch change for item criticalityClasses
      if (this._dispatch) {
        this._dispatch({
          type: ApplicationStateEnums.ApplicationState_criticalityClasses,
          payload: value,
        });
      }
    }
  }
  get typeConversions(): {
    [key: string]: string;
  } {
    if (this._getState) {
      return this._getState().ApplicationState.typeConversions;
    } else {
      if (this._state) {
        return this._state.typeConversions;
      }
    }
    throw new Error('Invalid State in ApplicationState_typeConversions');
  }
  set typeConversions(value: { [key: string]: string }) {
    if (this._state && typeof value !== 'undefined') {
      this._state.typeConversions = value;
    } else {
      // dispatch change for item typeConversions
      if (this._dispatch) {
        this._dispatch({
          type: ApplicationStateEnums.ApplicationState_typeConversions,
          payload: value,
        });
      }
    }
  }
  get fileTypes(): string[] {
    if (this._getState) {
      return this._getState().ApplicationState.fileTypes;
    } else {
      if (this._state) {
        return this._state.fileTypes;
      }
    }
    throw new Error('Invalid State in ApplicationState_fileTypes');
  }
  set fileTypes(value: string[]) {
    if (this._state && typeof value !== 'undefined') {
      this._state.fileTypes = value;
    } else {
      // dispatch change for item fileTypes
      if (this._dispatch) {
        this._dispatch({
          type: ApplicationStateEnums.ApplicationState_fileTypes,
          payload: value,
        });
      }
    }
  }
  get areas(): Model.Area[] {
    if (this._getState) {
      return this._getState().ApplicationState.areas;
    } else {
      if (this._state) {
        return this._state.areas;
      }
    }
    throw new Error('Invalid State in ApplicationState_areas');
  }
  set areas(value: Model.Area[]) {
    if (this._state && typeof value !== 'undefined') {
      this._state.areas = value;
    } else {
      // dispatch change for item areas
      if (this._dispatch) {
        this._dispatch({
          type: ApplicationStateEnums.ApplicationState_areas,
          payload: value,
        });
      }
    }
  }
  get phases(): any[] {
    if (this._getState) {
      return this._getState().ApplicationState.phases;
    } else {
      if (this._state) {
        return this._state.phases;
      }
    }
    throw new Error('Invalid State in ApplicationState_phases');
  }
  set phases(value: any[]) {
    if (this._state && typeof value !== 'undefined') {
      this._state.phases = value;
    } else {
      // dispatch change for item phases
      if (this._dispatch) {
        this._dispatch({
          type: ApplicationStateEnums.ApplicationState_phases,
          payload: value,
        });
      }
    }
  }
  get systems(): schema.system[] {
    if (this._getState) {
      return this._getState().ApplicationState.systems;
    } else {
      if (this._state) {
        return this._state.systems;
      }
    }
    throw new Error('Invalid State in ApplicationState_systems');
  }
  set systems(value: schema.system[]) {
    if (this._state && typeof value !== 'undefined') {
      this._state.systems = value;
    } else {
      // dispatch change for item systems
      if (this._dispatch) {
        this._dispatch({
          type: ApplicationStateEnums.ApplicationState_systems,
          payload: value,
        });
      }
    }
  }
  get contractPermissions(): Model.ContractPermissions | undefined {
    if (this._getState) {
      return this._getState().ApplicationState.contractPermissions;
    } else {
      if (this._state) {
        return this._state.contractPermissions;
      }
    }
    return undefined;
  }
  set contractPermissions(value: Model.ContractPermissions | undefined) {
    if (this._state && typeof value !== 'undefined') {
      this._state.contractPermissions = value;
    } else {
      // dispatch change for item contractPermissions
      if (this._dispatch) {
        this._dispatch({
          type: ApplicationStateEnums.ApplicationState_contractPermissions,
          payload: value,
        });
      }
    }
  }

  setSystemError(err: any) {
    if (this._state) {
      this.systemState.hasSystemError = true;
      this.systemState.error = err;
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: ApplicationStateEnums.ApplicationState_setSystemError,
          payload: err,
        });
      }
    }
  }

  public static setSystemError(err: any) {
    return (dispatcher: any, getState: any) => {
      return new RApplicationState(
        undefined,
        dispatcher,
        getState
      ).setSystemError(err);
    };
  }
  setSystemDown(down: boolean) {
    if (this._state) {
      this.systemDown = down;
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: ApplicationStateEnums.ApplicationState_setSystemDown,
          payload: down,
        });
      }
    }
  }

  public static setSystemDown(down: boolean) {
    return (dispatcher: any, getState: any) => {
      return new RApplicationState(
        undefined,
        dispatcher,
        getState
      ).setSystemDown(down);
    };
  }
  async getUserSettings() {
    try {
      this.userSettings = await API.getUserSettings();
    } catch (e) {
      console.error(e);
    }
  }

  public static getUserSettings() {
    return (dispatcher: any, getState: any) => {
      return new RApplicationState(
        undefined,
        dispatcher,
        getState
      ).getUserSettings();
    };
  }
  async getSystemState() {
    try {
      const status = await Auth.getServiceDown();
      this.setSystemDown(status.down);
    } catch (e) {
      this.setSystemDown(true);
    }
  }

  public static getSystemState() {
    return (dispatcher: any, getState: any) => {
      return new RApplicationState(
        undefined,
        dispatcher,
        getState
      ).getSystemState();
    };
  }
  async updateSearchState(newSettings: SearchState) {
    this.searchState = newSettings;
  }

  public static updateSearchState(newSettings: SearchState) {
    return (dispatcher: any, getState: any) => {
      return new RApplicationState(
        undefined,
        dispatcher,
        getState
      ).updateSearchState(newSettings);
    };
  }
  async resetSearchState() {
    this.searchState = { currentPage: 0 };
  }

  public static resetSearchState() {
    return (dispatcher: any, getState: any) => {
      return new RApplicationState(
        undefined,
        dispatcher,
        getState
      ).resetSearchState();
    };
  }
  async saveUserSettings(newSettings: UserSettings) {
    try {
      await API.saveUserSettings({ ...this.userSettings, ...newSettings });
    } catch (e) {
      console.error(e);
    }
    await this.getUserSettings();
  }

  public static saveUserSettings(newSettings: UserSettings) {
    return (dispatcher: any, getState: any) => {
      return new RApplicationState(
        undefined,
        dispatcher,
        getState
      ).saveUserSettings(newSettings);
    };
  }
  setSearchNaviSelectedAreas(areas: number[]) {
    if (this._state) {
      this.searchNaviSelectedAreas = areas;
    } else {
      if (this._dispatch) {
        this._dispatch({
          type:
            ApplicationStateEnums.ApplicationState_setSearchNaviSelectedAreas,
          payload: areas,
        });
      }
    }
  }

  public static setSearchNaviSelectedAreas(areas: number[]) {
    return (dispatcher: any, getState: any) => {
      return new RApplicationState(
        undefined,
        dispatcher,
        getState
      ).setSearchNaviSelectedAreas(areas);
    };
  }
  setSidebarVisibility(options: { isOpen: boolean; selectedPanel?: Panes }) {
    if (this._state) {
      this.isSidebarOpen = options.isOpen;
      if (typeof options.selectedPanel !== 'undefined') {
        this.selectedPanel = options.selectedPanel;
      }
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: ApplicationStateEnums.ApplicationState_setSidebarVisibility,
          payload: options,
        });
      }
    }
  }

  public static setSidebarVisibility(options: {
    isOpen: boolean;
    selectedPanel?: Panes;
  }) {
    return (dispatcher: any, getState: any) => {
      return new RApplicationState(
        undefined,
        dispatcher,
        getState
      ).setSidebarVisibility(options);
    };
  }

  async ApplicationStateDispatcher(action: any) {
    if (typeof this._dispatch !== 'undefined') {
      this._dispatch(action);
    }
  }

  public static ApplicationStateDispatcher(action: any) {
    return (dispatcher: any, getState: any) => {
      return new RApplicationState(
        undefined,
        dispatcher,
        getState
      ).ApplicationStateDispatcher(action);
    };
  }
  async getFileTypes() {
    const action = this.appstatus.getFileTypes;
    try {
      this.setStatus({ action });
      const res = await API.getFileExtensions();
      this.assignFileTypes(res);
      this.setSuccess({ action });
    } catch (e) {
      this.setError({ action, message: e.message });
    }
  }

  public static getFileTypes() {
    return (dispatcher: any, getState: any) => {
      return new RApplicationState(
        undefined,
        dispatcher,
        getState
      ).getFileTypes();
    };
  }
  async getPhases() {
    const action = this.appstatus.getPhases;
    try {
      this.setStatus({ action });
      const res = await API.getPhases();
      // TODO: Do the sorting in backend
      this.assignPhases(res.sort((a, b) => a.name!.localeCompare(b.name!)));
      this.setSuccess({ action });
    } catch (e) {
      this.setError({ action, message: e.message });
    }
  }

  public static getPhases() {
    return (dispatcher: any, getState: any) => {
      return new RApplicationState(undefined, dispatcher, getState).getPhases();
    };
  }
  async getSystems() {
    const action = this.appstatus.getSystems;
    try {
      this.setStatus({ action });
      const res = await Docs.fetchAllSystems();
      this.assignSystems(res);
      this.setSuccess({ action });
    } catch (e) {
      this.setError({ action, message: e.message });
    }
  }

  public static getSystems() {
    return (dispatcher: any, getState: any) => {
      return new RApplicationState(
        undefined,
        dispatcher,
        getState
      ).getSystems();
    };
  }
  async createPitemType(name: string) {
    await createPitemType(name);
    await this.getDocumentCategories();
  }

  public static createPitemType(name: string) {
    return (dispatcher: any, getState: any) => {
      return new RApplicationState(
        undefined,
        dispatcher,
        getState
      ).createPitemType(name);
    };
  }
  setStatus(params: {
    action: ActionStatusDetail;
    snack?: SnackDetails;
    finished?: boolean;
  }) {
    if (this._state) {
      this.appstatus[params.action.name] = {
        ...this.appstatus[params.action.name],
        loading: params.finished ? false : true,
        loaded: false,
        snack: params.snack,
      };
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: ApplicationStateEnums.ApplicationState_setStatus,
          payload: params,
        });
      }
    }
  }

  public static setStatus(params: {
    action: ActionStatusDetail;
    snack?: SnackDetails;
    finished?: boolean;
  }) {
    return (dispatcher: any, getState: any) => {
      return new RApplicationState(undefined, dispatcher, getState).setStatus(
        params
      );
    };
  }
  setSuccess(params: {
    action: ActionStatusDetail;
    translation?: TranslationDetails;
  }) {
    if (this._state) {
      const { action, ...snackParams } = params;
      const snack =
        snackParams && ({ variant: 'success', ...snackParams } as SnackDetails);
      this.setStatus({ action, snack });
      this.appstatus[action.name] = {
        ...this.appstatus[action.name],
        loading: false,
        loaded: true,
        snack,
      };
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: ApplicationStateEnums.ApplicationState_setSuccess,
          payload: params,
        });
      }
    }
  }

  public static setSuccess(params: {
    action: ActionStatusDetail;
    translation?: TranslationDetails;
  }) {
    return (dispatcher: any, getState: any) => {
      return new RApplicationState(undefined, dispatcher, getState).setSuccess(
        params
      );
    };
  }
  setError(params: {
    action: ActionStatusDetail;
    message?: string;
    translation?: TranslationDetails;
  }) {
    if (this._state) {
      const { action, ...snackParams } = params;
      const snack = { variant: 'error', ...snackParams } as SnackDetails;
      this.setStatus({ action, snack });
      this.appstatus[params.action.name] = {
        ...this.appstatus[params.action.name],
        loading: false,
        loaded: false,
        snack,
      };
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: ApplicationStateEnums.ApplicationState_setError,
          payload: params,
        });
      }
    }
  }

  public static setError(params: {
    action: ActionStatusDetail;
    message?: string;
    translation?: TranslationDetails;
  }) {
    return (dispatcher: any, getState: any) => {
      return new RApplicationState(undefined, dispatcher, getState).setError(
        params
      );
    };
  }
  resetAppSnacks() {
    if (this._state) {
      Object.keys(this.appstatus).map((key) => {
        this.appstatus[key].snack = undefined;
      });
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: ApplicationStateEnums.ApplicationState_resetAppSnacks,
        });
      }
    }
  }

  public static resetAppSnacks() {
    return (dispatcher: any, getState: any) => {
      return new RApplicationState(
        undefined,
        dispatcher,
        getState
      ).resetAppSnacks();
    };
  }
  async getUserPermissions() {
    // has: roles, contracts, items
    // this.userPermissions = await API.getUserRolesAndContracts();
  }

  public static getUserPermissions() {
    return (dispatcher: any, getState: any) => {
      return new RApplicationState(
        undefined,
        dispatcher,
        getState
      ).getUserPermissions();
    };
  }
  async getUserHasPermissions(token: string) {
    this.tokenValidity = await Auth.checkTokenValidity(token);
  }

  public static getUserHasPermissions(token: string) {
    return (dispatcher: any, getState: any) => {
      return new RApplicationState(
        undefined,
        dispatcher,
        getState
      ).getUserHasPermissions(token);
    };
  }
  async getUserContracts() {
    this.loading = true;
    const res = await API.getUserContracts();
    this.assignUserContracts(res);
    this.loading = false;
  }

  public static getUserContracts() {
    return (dispatcher: any, getState: any) => {
      return new RApplicationState(
        undefined,
        dispatcher,
        getState
      ).getUserContracts();
    };
  }
  assignUserContracts(contracts: Model.ContractInfo[]) {
    if (this._state) {
      if (contracts) {
        this.userContractsOrig = contracts;
      }
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: ApplicationStateEnums.ApplicationState_assignUserContracts,
          payload: contracts,
        });
      }
    }
  }

  public static assignUserContracts(contracts: Model.ContractInfo[]) {
    return (dispatcher: any, getState: any) => {
      return new RApplicationState(
        undefined,
        dispatcher,
        getState
      ).assignUserContracts(contracts);
    };
  }
  async getBuildingElementTree(contractIds: number[]) {
    const action = this.appstatus.getBuildingElementTree;
    try {
      // Get areas too for the UI filtering purposes
      await this.getAreas();
      this.setStatus({ action });
      const res = await API.getBuildingsForContracts(contractIds);
      this.assignBuildingElementTree(res);
      this.setSuccess({ action });
    } catch (e) {
      this.setError({ action, message: e.message });
    }
  }

  public static getBuildingElementTree(contractIds: number[]) {
    return (dispatcher: any, getState: any) => {
      return new RApplicationState(
        undefined,
        dispatcher,
        getState
      ).getBuildingElementTree(contractIds);
    };
  }
  async getRooms(param: { contractIds: number[]; pitemIds: number[] }) {
    if (param.contractIds.length === 0 && param.pitemIds.length === 0) {
      // Empty room array if no contract or pitem filter selected
      this.assignRooms([]);
      return;
    }
    const roomType = this.pitemTypes.filter((t) => t.name === 'Huone').pop();
    const action = this.appstatus.getRooms;
    try {
      this.setStatus({ action });
      const res = await API.searchPItems({
        contractFilter: param.contractIds,
        buildingPartIds: param.pitemIds,
        pitemTypes: roomType ? [roomType.id] : [0],
      });
      this.assignRooms(res.rows);
      this.setSuccess({ action });
    } catch (e) {
      this.setError({ action, message: e.message });
    }
  }

  public static getRooms(param: { contractIds: number[]; pitemIds: number[] }) {
    return (dispatcher: any, getState: any) => {
      return new RApplicationState(undefined, dispatcher, getState).getRooms(
        param
      );
    };
  }
  assignBuildingElementTree(buildingElementTree: schema.pitem[]) {
    if (this._state) {
      this.buildingItemTree = buildingElementTree;
    } else {
      if (this._dispatch) {
        this._dispatch({
          type:
            ApplicationStateEnums.ApplicationState_assignBuildingElementTree,
          payload: buildingElementTree,
        });
      }
    }
  }

  public static assignBuildingElementTree(buildingElementTree: schema.pitem[]) {
    return (dispatcher: any, getState: any) => {
      return new RApplicationState(
        undefined,
        dispatcher,
        getState
      ).assignBuildingElementTree(buildingElementTree);
    };
  }
  assignFileTypes(fileTypes: string[]) {
    if (this._state) {
      this.fileTypes = fileTypes;
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: ApplicationStateEnums.ApplicationState_assignFileTypes,
          payload: fileTypes,
        });
      }
    }
  }

  public static assignFileTypes(fileTypes: string[]) {
    return (dispatcher: any, getState: any) => {
      return new RApplicationState(
        undefined,
        dispatcher,
        getState
      ).assignFileTypes(fileTypes);
    };
  }
  assignPhases(phases: any[]) {
    if (this._state) {
      this.phases = phases;
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: ApplicationStateEnums.ApplicationState_assignPhases,
          payload: phases,
        });
      }
    }
  }

  public static assignPhases(phases: any[]) {
    return (dispatcher: any, getState: any) => {
      return new RApplicationState(
        undefined,
        dispatcher,
        getState
      ).assignPhases(phases);
    };
  }
  assignSystems(systems: schema.system[]) {
    if (this._state) {
      this.systems = systems;
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: ApplicationStateEnums.ApplicationState_assignSystems,
          payload: systems,
        });
      }
    }
  }

  public static assignSystems(systems: schema.system[]) {
    return (dispatcher: any, getState: any) => {
      return new RApplicationState(
        undefined,
        dispatcher,
        getState
      ).assignSystems(systems);
    };
  }
  assignRooms(rooms: Model.UIDocument[]) {
    if (this._state) {
      this.rooms = rooms;
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: ApplicationStateEnums.ApplicationState_assignRooms,
          payload: rooms,
        });
      }
    }
  }

  public static assignRooms(rooms: Model.UIDocument[]) {
    return (dispatcher: any, getState: any) => {
      return new RApplicationState(undefined, dispatcher, getState).assignRooms(
        rooms
      );
    };
  }
  async getAreas() {
    const action = this.appstatus.getAreas;
    try {
      this.setStatus({ action });
      const res = await API.getAreas();
      this.assignAreas(res);
      this.setSuccess({ action });
    } catch (error) {
      console.error(error);
      this.setError({ action, message: error.message });
    }
  }

  public static getAreas() {
    return (dispatcher: any, getState: any) => {
      return new RApplicationState(undefined, dispatcher, getState).getAreas();
    };
  }
  assignAreas(areas: Model.Area[]) {
    if (this._state) {
      this.areas = areas;
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: ApplicationStateEnums.ApplicationState_assignAreas,
          payload: areas,
        });
      }
    }
  }

  public static assignAreas(areas: Model.Area[]) {
    return (dispatcher: any, getState: any) => {
      return new RApplicationState(undefined, dispatcher, getState).assignAreas(
        areas
      );
    };
  }
  async updateArea(area: schema.area) {
    try {
      await API.updateArea(area);
    } catch (error) {
      console.error(error);
    }
  }

  public static updateArea(area: schema.area) {
    return (dispatcher: any, getState: any) => {
      return new RApplicationState(undefined, dispatcher, getState).updateArea(
        area
      );
    };
  }
  async updateAreaSection(section: schema.area_section) {
    try {
      await API.updateAreaSection(section);
    } catch (error) {
      console.error(error);
    }
  }

  public static updateAreaSection(section: schema.area_section) {
    return (dispatcher: any, getState: any) => {
      return new RApplicationState(
        undefined,
        dispatcher,
        getState
      ).updateAreaSection(section);
    };
  }
  async getDocumentCategories() {
    try {
      const res = await Docs.getClientSettings();
      // #1632: Sort the pitem type list so that "Kaikki kohdetyypit"
      // is the first item of the list, "Rakennustuotteet" is the second
      // and the rest will fallow in alphabetical order
      const firstPitemTypes: schema.pitem_type[] = [];
      const first = res.pitemTypes
        .filter(
          (type) => type.name === 'Kaikki kohdetyypit' || type.name === 'Kaikki'
        )
        .pop();
      const second = res.pitemTypes
        .filter((type) => type.name === 'Rakennustuotteet')
        .pop();
      if (first) {
        firstPitemTypes.push(first);
      }
      if (second) {
        firstPitemTypes.push(second);
      }
      const sortedPitemTypes = firstPitemTypes.concat(
        res.pitemTypes
          .filter((type) => {
            if (
              type.name === 'Kaikki kohdetyypit' ||
              type.name === 'Kaikki' ||
              type.name === 'Rakennustuotteet'
            ) {
              return false;
            }
            return true;
          })
          .sort((a, b) => a.name!.localeCompare(b.name!))
      );
      this.appDocumentStates = res.states;
      this.appDocumentActions = res.actions;
      this.pitemTypes = sortedPitemTypes;
      this.typeConversions = res.typeConversions;
      this.contractPermissions = await API.getContractPermissions();
      this.criticalityClasses = res.criticality_classes;
      this.assignDocumentCategories(res.categories);
    } catch (e) {
      this.logout();
    }
  }

  public static getDocumentCategories() {
    return (dispatcher: any, getState: any) => {
      return new RApplicationState(
        undefined,
        dispatcher,
        getState
      ).getDocumentCategories();
    };
  }
  assignDocumentCategories(origCats: Model.DocumentCategory[]) {
    if (this._state) {
      this.appDocumentCategories = origCats;
      const categories = origCats.map((cat) => ({
        ...cat,
        children: [],
        isLeaf: true,
      }));
      const perId = categories.reduce((prev, curr: Model.DocumentCategory) => {
        return {
          ...prev,
          [curr.id]: curr,
        };
      }, {});
      categories.forEach((cat) => {
        if (cat.parentId) {
          const p = perId[cat.parentId];
          if (!p.children) {
            p.children = [];
          }
          p.children.push(cat);
          p.isLeaf = false;
        }
      });
      this.categoryTree = categories
        .filter((cat) => {
          return !cat.parentId;
        })
        .map((cat) => {
          return perId[cat.id];
        });
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: ApplicationStateEnums.ApplicationState_assignDocumentCategories,
          payload: origCats,
        });
      }
    }
  }

  public static assignDocumentCategories(origCats: Model.DocumentCategory[]) {
    return (dispatcher: any, getState: any) => {
      return new RApplicationState(
        undefined,
        dispatcher,
        getState
      ).assignDocumentCategories(origCats);
    };
  }
  async sendPasswordResetRequest(email: string) {
    const action = this.appstatus.sendPasswordResetRequest;
    this.setStatus({ action });
    try {
      this.setStatus({ action });
      await Auth.requestPasswordChangeLink(email);
      this.setSuccess({ action });
    } catch (e) {
      this.setError({ action, message: e.message });
    }
  }

  public static sendPasswordResetRequest(email: string) {
    return (dispatcher: any, getState: any) => {
      return new RApplicationState(
        undefined,
        dispatcher,
        getState
      ).sendPasswordResetRequest(email);
    };
  }
  async resetPassword(passwords: IPasswords) {
    const action = this.appstatus.resetPassword;
    try {
      this.setStatus({ action });
      await Auth.changePassword(passwords);
      this.setSuccess({ action });
    } catch (error) {
      this.setError({
        action,
        translation: { id: error.response.data.id || 'Error' },
      });
    }
    // await Auth.changePassword(passwords);
    // this.passwordChanged();
  }

  public static resetPassword(passwords: IPasswords) {
    return (dispatcher: any, getState: any) => {
      return new RApplicationState(
        undefined,
        dispatcher,
        getState
      ).resetPassword(passwords);
    };
  }
  passwordChanged() {
    if (this._state) {
      this.passwordResetStatus = PasswordResetStatus.Success;
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: ApplicationStateEnums.ApplicationState_passwordChanged,
        });
      }
    }
  }

  public static passwordChanged() {
    return (dispatcher: any, getState: any) => {
      return new RApplicationState(
        undefined,
        dispatcher,
        getState
      ).passwordChanged();
    };
  }
  async resetPasswordWithToken(params: IPasswordToken) {
    const action = this.appstatus.resetPasswordWithToken;
    try {
      this.setStatus({ action });
      await Auth.changePasswordWithToken({ ...params });
      this.setSuccess({ action, translation: { id: 'passwordReset.success' } });
    } catch (error) {
      if (error.response.data.id) {
        this.setError({ action, translation: { id: error.response.data.id } });
      } else {
        this.setError({ action, message: error.message });
      }
    }
  }

  public static resetPasswordWithToken(params: IPasswordToken) {
    return (dispatcher: any, getState: any) => {
      return new RApplicationState(
        undefined,
        dispatcher,
        getState
      ).resetPasswordWithToken(params);
    };
  }
  public async login(loginInfo: Model.APLoginCredidentials) {
    try {
      await Auth.login(loginInfo);
      await Docs.getClientSettings();
      this.setLoginStatus(LoginStatus.Logged);
      try {
        this.getUserDetails();
      } catch (e) {
        this.setSystemError(e);
      }
    } catch (e) {
      this.setLoginStatus(LoginStatus.Failed);
    }
  }

  public static login(loginInfo: Model.APLoginCredidentials) {
    return (dispatcher: any, getState: any) => {
      return new RApplicationState(undefined, dispatcher, getState).login(
        loginInfo
      );
    };
  }
  setLoginStatus(loginStatus: LoginStatus) {
    if (this._state) {
      this.loginStatus = loginStatus;
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: ApplicationStateEnums.ApplicationState_setLoginStatus,
          payload: loginStatus,
        });
      }
    }
  }

  public static setLoginStatus(loginStatus: LoginStatus) {
    return (dispatcher: any, getState: any) => {
      return new RApplicationState(
        undefined,
        dispatcher,
        getState
      ).setLoginStatus(loginStatus);
    };
  }
  public async getUserDetails() {
    try {
      if (this.systemState.hasSystemError) {
        return;
      }
      const details = await Auth.getUserDetails();
      this.setUserDetails(details);
      this.setLoginStatus(LoginStatus.Logged);
    } catch (e) {
      this.setSystemError(e);
      this.setLoginStatus(LoginStatus.NotLogged);
    }
  }

  public static getUserDetails() {
    return (dispatcher: any, getState: any) => {
      return new RApplicationState(
        undefined,
        dispatcher,
        getState
      ).getUserDetails();
    };
  }
  setUserDetails(user?: IUserDetails) {
    if (this._state) {
      this.user = user;
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: ApplicationStateEnums.ApplicationState_setUserDetails,
          payload: user,
        });
      }
    }
  }

  public static setUserDetails(user?: IUserDetails) {
    return (dispatcher: any, getState: any) => {
      return new RApplicationState(
        undefined,
        dispatcher,
        getState
      ).setUserDetails(user);
    };
  }
  public async acceptingAgreements(agreementIds: number[]) {
    const action = this.appstatus.acceptingAgreements;
    this.setStatus({ action });
    try {
      await Auth.agreedAgreements(agreementIds);
      this.setSuccess({ action });
    } catch (e) {
      this.setError({ action, message: e.message });
    }
  }

  public static acceptingAgreements(agreementIds: number[]) {
    return (dispatcher: any, getState: any) => {
      return new RApplicationState(
        undefined,
        dispatcher,
        getState
      ).acceptingAgreements(agreementIds);
    };
  }
  public async logout() {
    try {
      await Auth.signout();
      // Delete cookie so we don't reauthenticate instantly back to the application
      document.cookie = 'Authorization=; expires=Thu, 01 Jan 1970 00:00:00 GMT';
      this.setUserDetails(undefined);
      this.setLoginStatus(LoginStatus.NotLogged);
    } catch (e) {
      throw new Error(e);
    }
  }

  public static logout() {
    return (dispatcher: any, getState: any) => {
      return new RApplicationState(undefined, dispatcher, getState).logout();
    };
  }
  async checkLoginStatus() {
    if (this.systemState.hasSystemError) {
      return;
    }
    try {
      const loginStatus: ILoginStatus = await Auth.loginStatus();
      if (loginStatus.logged) {
        this.loginStatus = LoginStatus.Logged;
        await this.getUserDetails();
      } else {
        this.loginStatus = LoginStatus.NotLogged;
      }
    } catch (e) {
      this.setSystemError(e);
    }
  }

  public static checkLoginStatus() {
    return (dispatcher: any, getState: any) => {
      return new RApplicationState(
        undefined,
        dispatcher,
        getState
      ).checkLoginStatus();
    };
  }
}

export const ApplicationStateEnums = {
  ApplicationState_systemState: 'ApplicationState_systemState',
  ApplicationState_loading: 'ApplicationState_loading',
  ApplicationState_systemDown: 'ApplicationState_systemDown',
  ApplicationState_isSidebarOpen: 'ApplicationState_isSidebarOpen',
  ApplicationState_selectedPanel: 'ApplicationState_selectedPanel',
  ApplicationState_loginStatus: 'ApplicationState_loginStatus',
  ApplicationState_appstatus: 'ApplicationState_appstatus',
  ApplicationState_user: 'ApplicationState_user',
  ApplicationState_userSettings: 'ApplicationState_userSettings',
  ApplicationState_searchState: 'ApplicationState_searchState',
  ApplicationState_tokenValidity: 'ApplicationState_tokenValidity',
  ApplicationState_passwordResetStatus: 'ApplicationState_passwordResetStatus',
  ApplicationState_loginResponse: 'ApplicationState_loginResponse',
  ApplicationState_userPermissions: 'ApplicationState_userPermissions',
  ApplicationState_userContractsOrig: 'ApplicationState_userContractsOrig',
  ApplicationState_contractTree: 'ApplicationState_contractTree',
  ApplicationState_searchNaviSelectedAreas:
    'ApplicationState_searchNaviSelectedAreas',
  ApplicationState_buildingItemTree: 'ApplicationState_buildingItemTree',
  ApplicationState_rooms: 'ApplicationState_rooms',
  ApplicationState_appDocumentCategories:
    'ApplicationState_appDocumentCategories',
  ApplicationState_appDocumentStates: 'ApplicationState_appDocumentStates',
  ApplicationState_appDocumentActions: 'ApplicationState_appDocumentActions',
  ApplicationState_categoryTree: 'ApplicationState_categoryTree',
  ApplicationState_pitemTypes: 'ApplicationState_pitemTypes',
  ApplicationState_criticalityClasses: 'ApplicationState_criticalityClasses',
  ApplicationState_typeConversions: 'ApplicationState_typeConversions',
  ApplicationState_fileTypes: 'ApplicationState_fileTypes',
  ApplicationState_areas: 'ApplicationState_areas',
  ApplicationState_phases: 'ApplicationState_phases',
  ApplicationState_systems: 'ApplicationState_systems',
  ApplicationState_contractPermissions: 'ApplicationState_contractPermissions',
  ApplicationState_setSystemError: 'ApplicationState_setSystemError',
  ApplicationState_setSystemDown: 'ApplicationState_setSystemDown',
  ApplicationState_setSearchNaviSelectedAreas:
    'ApplicationState_setSearchNaviSelectedAreas',
  ApplicationState_setSidebarVisibility:
    'ApplicationState_setSidebarVisibility',
  ApplicationState_setStatus: 'ApplicationState_setStatus',
  ApplicationState_setSuccess: 'ApplicationState_setSuccess',
  ApplicationState_setError: 'ApplicationState_setError',
  ApplicationState_resetAppSnacks: 'ApplicationState_resetAppSnacks',
  ApplicationState_assignUserContracts: 'ApplicationState_assignUserContracts',
  ApplicationState_assignBuildingElementTree:
    'ApplicationState_assignBuildingElementTree',
  ApplicationState_assignFileTypes: 'ApplicationState_assignFileTypes',
  ApplicationState_assignPhases: 'ApplicationState_assignPhases',
  ApplicationState_assignSystems: 'ApplicationState_assignSystems',
  ApplicationState_assignRooms: 'ApplicationState_assignRooms',
  ApplicationState_assignAreas: 'ApplicationState_assignAreas',
  ApplicationState_assignDocumentCategories:
    'ApplicationState_assignDocumentCategories',
  ApplicationState_passwordChanged: 'ApplicationState_passwordChanged',
  ApplicationState_setLoginStatus: 'ApplicationState_setLoginStatus',
  ApplicationState_setUserDetails: 'ApplicationState_setUserDetails',
};

export const ApplicationStateReducer = (
  state: IApplicationState = initApplicationState(),
  action: any
) => {
  return immer.produce(state, (draft: IApplicationState) => {
    switch (action.type) {
      case ApplicationStateEnums.ApplicationState_systemState:
        new RApplicationState(draft).systemState = action.payload;
        break;
      case ApplicationStateEnums.ApplicationState_loading:
        new RApplicationState(draft).loading = action.payload;
        break;
      case ApplicationStateEnums.ApplicationState_systemDown:
        new RApplicationState(draft).systemDown = action.payload;
        break;
      case ApplicationStateEnums.ApplicationState_isSidebarOpen:
        new RApplicationState(draft).isSidebarOpen = action.payload;
        break;
      case ApplicationStateEnums.ApplicationState_selectedPanel:
        new RApplicationState(draft).selectedPanel = action.payload;
        break;
      case ApplicationStateEnums.ApplicationState_loginStatus:
        new RApplicationState(draft).loginStatus = action.payload;
        break;
      case ApplicationStateEnums.ApplicationState_appstatus:
        new RApplicationState(draft).appstatus = action.payload;
        break;
      case ApplicationStateEnums.ApplicationState_user:
        new RApplicationState(draft).user = action.payload;
        break;
      case ApplicationStateEnums.ApplicationState_userSettings:
        new RApplicationState(draft).userSettings = action.payload;
        break;
      case ApplicationStateEnums.ApplicationState_searchState:
        new RApplicationState(draft).searchState = action.payload;
        break;
      case ApplicationStateEnums.ApplicationState_tokenValidity:
        new RApplicationState(draft).tokenValidity = action.payload;
        break;
      case ApplicationStateEnums.ApplicationState_passwordResetStatus:
        new RApplicationState(draft).passwordResetStatus = action.payload;
        break;
      case ApplicationStateEnums.ApplicationState_loginResponse:
        new RApplicationState(draft).loginResponse = action.payload;
        break;
      case ApplicationStateEnums.ApplicationState_userPermissions:
        new RApplicationState(draft).userPermissions = action.payload;
        break;
      case ApplicationStateEnums.ApplicationState_userContractsOrig:
        new RApplicationState(draft).userContractsOrig = action.payload;
        break;
      case ApplicationStateEnums.ApplicationState_contractTree:
        new RApplicationState(draft).contractTree = action.payload;
        break;
      case ApplicationStateEnums.ApplicationState_searchNaviSelectedAreas:
        new RApplicationState(draft).searchNaviSelectedAreas = action.payload;
        break;
      case ApplicationStateEnums.ApplicationState_buildingItemTree:
        new RApplicationState(draft).buildingItemTree = action.payload;
        break;
      case ApplicationStateEnums.ApplicationState_rooms:
        new RApplicationState(draft).rooms = action.payload;
        break;
      case ApplicationStateEnums.ApplicationState_appDocumentCategories:
        new RApplicationState(draft).appDocumentCategories = action.payload;
        break;
      case ApplicationStateEnums.ApplicationState_appDocumentStates:
        new RApplicationState(draft).appDocumentStates = action.payload;
        break;
      case ApplicationStateEnums.ApplicationState_appDocumentActions:
        new RApplicationState(draft).appDocumentActions = action.payload;
        break;
      case ApplicationStateEnums.ApplicationState_categoryTree:
        new RApplicationState(draft).categoryTree = action.payload;
        break;
      case ApplicationStateEnums.ApplicationState_pitemTypes:
        new RApplicationState(draft).pitemTypes = action.payload;
        break;
      case ApplicationStateEnums.ApplicationState_criticalityClasses:
        new RApplicationState(draft).criticalityClasses = action.payload;
        break;
      case ApplicationStateEnums.ApplicationState_typeConversions:
        new RApplicationState(draft).typeConversions = action.payload;
        break;
      case ApplicationStateEnums.ApplicationState_fileTypes:
        new RApplicationState(draft).fileTypes = action.payload;
        break;
      case ApplicationStateEnums.ApplicationState_areas:
        new RApplicationState(draft).areas = action.payload;
        break;
      case ApplicationStateEnums.ApplicationState_phases:
        new RApplicationState(draft).phases = action.payload;
        break;
      case ApplicationStateEnums.ApplicationState_systems:
        new RApplicationState(draft).systems = action.payload;
        break;
      case ApplicationStateEnums.ApplicationState_contractPermissions:
        new RApplicationState(draft).contractPermissions = action.payload;
        break;
      case ApplicationStateEnums.ApplicationState_setSystemError:
        new RApplicationState(draft).setSystemError(action.payload);
        break;
      case ApplicationStateEnums.ApplicationState_setSystemDown:
        new RApplicationState(draft).setSystemDown(action.payload);
        break;
      case ApplicationStateEnums.ApplicationState_setSearchNaviSelectedAreas:
        new RApplicationState(draft).setSearchNaviSelectedAreas(action.payload);
        break;
      case ApplicationStateEnums.ApplicationState_setSidebarVisibility:
        new RApplicationState(draft).setSidebarVisibility(action.payload);
        break;
      case ApplicationStateEnums.ApplicationState_setStatus:
        new RApplicationState(draft).setStatus(action.payload);
        break;
      case ApplicationStateEnums.ApplicationState_setSuccess:
        new RApplicationState(draft).setSuccess(action.payload);
        break;
      case ApplicationStateEnums.ApplicationState_setError:
        new RApplicationState(draft).setError(action.payload);
        break;
      case ApplicationStateEnums.ApplicationState_resetAppSnacks:
        new RApplicationState(draft).resetAppSnacks();
        break;
      case ApplicationStateEnums.ApplicationState_assignUserContracts:
        new RApplicationState(draft).assignUserContracts(action.payload);
        break;
      case ApplicationStateEnums.ApplicationState_assignBuildingElementTree:
        new RApplicationState(draft).assignBuildingElementTree(action.payload);
        break;
      case ApplicationStateEnums.ApplicationState_assignFileTypes:
        new RApplicationState(draft).assignFileTypes(action.payload);
        break;
      case ApplicationStateEnums.ApplicationState_assignPhases:
        new RApplicationState(draft).assignPhases(action.payload);
        break;
      case ApplicationStateEnums.ApplicationState_assignSystems:
        new RApplicationState(draft).assignSystems(action.payload);
        break;
      case ApplicationStateEnums.ApplicationState_assignRooms:
        new RApplicationState(draft).assignRooms(action.payload);
        break;
      case ApplicationStateEnums.ApplicationState_assignAreas:
        new RApplicationState(draft).assignAreas(action.payload);
        break;
      case ApplicationStateEnums.ApplicationState_assignDocumentCategories:
        new RApplicationState(draft).assignDocumentCategories(action.payload);
        break;
      case ApplicationStateEnums.ApplicationState_passwordChanged:
        new RApplicationState(draft).passwordChanged();
        break;
      case ApplicationStateEnums.ApplicationState_setLoginStatus:
        new RApplicationState(draft).setLoginStatus(action.payload);
        break;
      case ApplicationStateEnums.ApplicationState_setUserDetails:
        new RApplicationState(draft).setUserDetails(action.payload);
        break;
    }
  });
};
/********************************
 * React Context API component   *
 ********************************/
export const ApplicationStateContext = React.createContext<IProps>(
  initWithMethodsApplicationState()
);
export const ApplicationStateConsumer = ApplicationStateContext.Consumer;
let instanceCnt = 1;
export class ApplicationStateProvider extends React.Component {
  public state: IApplicationState = initApplicationState();
  public lastSetState: IApplicationState;
  private __devTools: any = null;
  private __selectortargetPitemTypes: any = null;
  private __selectorequipmentTypes: any = null;
  private __selectoruserContracts: any = null;
  private __selectoruserContractorDocWritableContracts: any = null;
  private __selectorreverseTypeConversions: any = null;
  private __selectorgetContractorWorkTypes: any = null;
  private __selectorgetContractorMainDocumentypes: any = null;
  private __selectorgetContractorDocumentPackageId: any = null;
  private __selectorgetContractorDocumentypes: any = null;
  private __selectordesignDisciplines: any = null;
  private __selectordocCategories: any = null;
  private __selectorinspectionDocCategories: any = null;
  private __selectorpermissions: any = null;
  private __selectorfilteredPermissions: any = null;
  private __selectoruiBuildingElements: any = null;
  constructor(props: any) {
    super(props);
    this.lastSetState = this.state;
    this.setSystemError = this.setSystemError.bind(this);
    this.setSystemDown = this.setSystemDown.bind(this);
    this.getUserSettings = this.getUserSettings.bind(this);
    this.getSystemState = this.getSystemState.bind(this);
    this.updateSearchState = this.updateSearchState.bind(this);
    this.resetSearchState = this.resetSearchState.bind(this);
    this.saveUserSettings = this.saveUserSettings.bind(this);
    this.setSearchNaviSelectedAreas = this.setSearchNaviSelectedAreas.bind(
      this
    );
    this.setSidebarVisibility = this.setSidebarVisibility.bind(this);
    this.ApplicationStateDispatcher = this.ApplicationStateDispatcher.bind(
      this
    );
    this.getFileTypes = this.getFileTypes.bind(this);
    this.getPhases = this.getPhases.bind(this);
    this.getSystems = this.getSystems.bind(this);
    this.createPitemType = this.createPitemType.bind(this);
    this.setStatus = this.setStatus.bind(this);
    this.setSuccess = this.setSuccess.bind(this);
    this.setError = this.setError.bind(this);
    this.resetAppSnacks = this.resetAppSnacks.bind(this);
    this.getUserPermissions = this.getUserPermissions.bind(this);
    this.getUserHasPermissions = this.getUserHasPermissions.bind(this);
    this.getUserContracts = this.getUserContracts.bind(this);
    this.assignUserContracts = this.assignUserContracts.bind(this);
    this.getBuildingElementTree = this.getBuildingElementTree.bind(this);
    this.getRooms = this.getRooms.bind(this);
    this.assignBuildingElementTree = this.assignBuildingElementTree.bind(this);
    this.assignFileTypes = this.assignFileTypes.bind(this);
    this.assignPhases = this.assignPhases.bind(this);
    this.assignSystems = this.assignSystems.bind(this);
    this.assignRooms = this.assignRooms.bind(this);
    this.getAreas = this.getAreas.bind(this);
    this.assignAreas = this.assignAreas.bind(this);
    this.updateArea = this.updateArea.bind(this);
    this.updateAreaSection = this.updateAreaSection.bind(this);
    this.getDocumentCategories = this.getDocumentCategories.bind(this);
    this.assignDocumentCategories = this.assignDocumentCategories.bind(this);
    this.sendPasswordResetRequest = this.sendPasswordResetRequest.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.passwordChanged = this.passwordChanged.bind(this);
    this.resetPasswordWithToken = this.resetPasswordWithToken.bind(this);
    this.login = this.login.bind(this);
    this.setLoginStatus = this.setLoginStatus.bind(this);
    this.getUserDetails = this.getUserDetails.bind(this);
    this.setUserDetails = this.setUserDetails.bind(this);
    this.acceptingAgreements = this.acceptingAgreements.bind(this);
    this.logout = this.logout.bind(this);
    this.checkLoginStatus = this.checkLoginStatus.bind(this);
    this.__selectortargetPitemTypes = targetPitemTypesSelectorFnCreator();
    this.__selectorequipmentTypes = equipmentTypesSelectorFnCreator();
    this.__selectoruserContracts = userContractsSelectorFnCreator();
    this.__selectoruserContractorDocWritableContracts = userContractorDocWritableContractsSelectorFnCreator();
    this.__selectorreverseTypeConversions = reverseTypeConversionsSelectorFnCreator();
    this.__selectorgetContractorWorkTypes = getContractorWorkTypesSelectorFnCreator();
    this.__selectorgetContractorMainDocumentypes = getContractorMainDocumentypesSelectorFnCreator();
    this.__selectorgetContractorDocumentPackageId = getContractorDocumentPackageIdSelectorFnCreator();
    this.__selectorgetContractorDocumentypes = getContractorDocumentypesSelectorFnCreator();
    this.__selectordesignDisciplines = designDisciplinesSelectorFnCreator();
    this.__selectordocCategories = docCategoriesSelectorFnCreator();
    this.__selectorinspectionDocCategories = inspectionDocCategoriesSelectorFnCreator();
    this.__selectorpermissions = permissionsSelectorFnCreator();
    this.__selectorfilteredPermissions = filteredPermissionsSelectorFnCreator();
    this.__selectoruiBuildingElements = uiBuildingElementsSelectorFnCreator();
    const devs = window['__REDUX_DEVTOOLS_EXTENSION__']
      ? window['__REDUX_DEVTOOLS_EXTENSION__']
      : null;
    if (devs) {
      this.__devTools = devs.connect({
        name: 'ApplicationState' + instanceCnt++,
      });
      this.__devTools.init(this.state);
      this.__devTools.subscribe((msg: any) => {
        if (msg.type === 'DISPATCH' && msg.state) {
          this.setState(JSON.parse(msg.state));
        }
      });
    }
  }
  public componentWillUnmount() {
    if (this.__devTools) {
      this.__devTools.unsubscribe();
    }
  }
  public setStateSync(state: IApplicationState) {
    this.lastSetState = state;
    this.setState(state);
  }
  setSystemError(err: any) {
    const nextState = immer.produce(this.state, (draft: IApplicationState) =>
      new RApplicationState(draft).setSystemError(err)
    );
    if (this.__devTools) {
      this.__devTools.send('setSystemError', nextState);
    }
    this.setStateSync(nextState);
  }
  setSystemDown(down: boolean) {
    const nextState = immer.produce(this.state, (draft: IApplicationState) =>
      new RApplicationState(draft).setSystemDown(down)
    );
    if (this.__devTools) {
      this.__devTools.send('setSystemDown', nextState);
    }
    this.setStateSync(nextState);
  }
  async getUserSettings() {
    return new RApplicationState(
      undefined,
      (action: any) => {
        const nextState = ApplicationStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ ApplicationState: this.lastSetState })
    ).getUserSettings();
  }
  async getSystemState() {
    return new RApplicationState(
      undefined,
      (action: any) => {
        const nextState = ApplicationStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ ApplicationState: this.lastSetState })
    ).getSystemState();
  }
  async updateSearchState(newSettings: SearchState) {
    return new RApplicationState(
      undefined,
      (action: any) => {
        const nextState = ApplicationStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ ApplicationState: this.lastSetState })
    ).updateSearchState(newSettings);
  }
  async resetSearchState() {
    return new RApplicationState(
      undefined,
      (action: any) => {
        const nextState = ApplicationStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ ApplicationState: this.lastSetState })
    ).resetSearchState();
  }
  async saveUserSettings(newSettings: UserSettings) {
    return new RApplicationState(
      undefined,
      (action: any) => {
        const nextState = ApplicationStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ ApplicationState: this.lastSetState })
    ).saveUserSettings(newSettings);
  }
  setSearchNaviSelectedAreas(areas: number[]) {
    const nextState = immer.produce(this.state, (draft: IApplicationState) =>
      new RApplicationState(draft).setSearchNaviSelectedAreas(areas)
    );
    if (this.__devTools) {
      this.__devTools.send('setSearchNaviSelectedAreas', nextState);
    }
    this.setStateSync(nextState);
  }
  setSidebarVisibility(options: { isOpen: boolean; selectedPanel?: Panes }) {
    const nextState = immer.produce(this.state, (draft: IApplicationState) =>
      new RApplicationState(draft).setSidebarVisibility(options)
    );
    if (this.__devTools) {
      this.__devTools.send('setSidebarVisibility', nextState);
    }
    this.setStateSync(nextState);
  }
  /**
   * @dispatch true
   */
  async ApplicationStateDispatcher(action: any) {
    return new RApplicationState(
      undefined,
      (action: any) => {
        const nextState = ApplicationStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ ApplicationState: this.lastSetState })
    ).ApplicationStateDispatcher(action);
  }
  async getFileTypes() {
    return new RApplicationState(
      undefined,
      (action: any) => {
        const nextState = ApplicationStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ ApplicationState: this.lastSetState })
    ).getFileTypes();
  }
  async getPhases() {
    return new RApplicationState(
      undefined,
      (action: any) => {
        const nextState = ApplicationStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ ApplicationState: this.lastSetState })
    ).getPhases();
  }
  async getSystems() {
    return new RApplicationState(
      undefined,
      (action: any) => {
        const nextState = ApplicationStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ ApplicationState: this.lastSetState })
    ).getSystems();
  }
  async createPitemType(name: string) {
    return new RApplicationState(
      undefined,
      (action: any) => {
        const nextState = ApplicationStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ ApplicationState: this.lastSetState })
    ).createPitemType(name);
  }
  setStatus(params: {
    action: ActionStatusDetail;
    snack?: SnackDetails;
    finished?: boolean;
  }) {
    const nextState = immer.produce(this.state, (draft: IApplicationState) =>
      new RApplicationState(draft).setStatus(params)
    );
    if (this.__devTools) {
      this.__devTools.send('setStatus', nextState);
    }
    this.setStateSync(nextState);
  }
  setSuccess(params: {
    action: ActionStatusDetail;
    translation?: TranslationDetails;
  }) {
    const nextState = immer.produce(this.state, (draft: IApplicationState) =>
      new RApplicationState(draft).setSuccess(params)
    );
    if (this.__devTools) {
      this.__devTools.send('setSuccess', nextState);
    }
    this.setStateSync(nextState);
  }
  setError(params: {
    action: ActionStatusDetail;
    message?: string;
    translation?: TranslationDetails;
  }) {
    const nextState = immer.produce(this.state, (draft: IApplicationState) =>
      new RApplicationState(draft).setError(params)
    );
    if (this.__devTools) {
      this.__devTools.send('setError', nextState);
    }
    this.setStateSync(nextState);
  }
  resetAppSnacks() {
    const nextState = immer.produce(this.state, (draft: IApplicationState) =>
      new RApplicationState(draft).resetAppSnacks()
    );
    if (this.__devTools) {
      this.__devTools.send('resetAppSnacks', nextState);
    }
    this.setStateSync(nextState);
  }
  async getUserPermissions() {
    return new RApplicationState(
      undefined,
      (action: any) => {
        const nextState = ApplicationStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ ApplicationState: this.lastSetState })
    ).getUserPermissions();
  }
  async getUserHasPermissions(token: string) {
    return new RApplicationState(
      undefined,
      (action: any) => {
        const nextState = ApplicationStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ ApplicationState: this.lastSetState })
    ).getUserHasPermissions(token);
  }
  async getUserContracts() {
    return new RApplicationState(
      undefined,
      (action: any) => {
        const nextState = ApplicationStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ ApplicationState: this.lastSetState })
    ).getUserContracts();
  }
  assignUserContracts(contracts: Model.ContractInfo[]) {
    const nextState = immer.produce(this.state, (draft: IApplicationState) =>
      new RApplicationState(draft).assignUserContracts(contracts)
    );
    if (this.__devTools) {
      this.__devTools.send('assignUserContracts', nextState);
    }
    this.setStateSync(nextState);
  }
  async getBuildingElementTree(contractIds: number[]) {
    return new RApplicationState(
      undefined,
      (action: any) => {
        const nextState = ApplicationStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ ApplicationState: this.lastSetState })
    ).getBuildingElementTree(contractIds);
  }
  async getRooms(param: { contractIds: number[]; pitemIds: number[] }) {
    return new RApplicationState(
      undefined,
      (action: any) => {
        const nextState = ApplicationStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ ApplicationState: this.lastSetState })
    ).getRooms(param);
  }
  assignBuildingElementTree(buildingElementTree: schema.pitem[]) {
    const nextState = immer.produce(this.state, (draft: IApplicationState) =>
      new RApplicationState(draft).assignBuildingElementTree(
        buildingElementTree
      )
    );
    if (this.__devTools) {
      this.__devTools.send('assignBuildingElementTree', nextState);
    }
    this.setStateSync(nextState);
  }
  assignFileTypes(fileTypes: string[]) {
    const nextState = immer.produce(this.state, (draft: IApplicationState) =>
      new RApplicationState(draft).assignFileTypes(fileTypes)
    );
    if (this.__devTools) {
      this.__devTools.send('assignFileTypes', nextState);
    }
    this.setStateSync(nextState);
  }
  assignPhases(phases: any[]) {
    const nextState = immer.produce(this.state, (draft: IApplicationState) =>
      new RApplicationState(draft).assignPhases(phases)
    );
    if (this.__devTools) {
      this.__devTools.send('assignPhases', nextState);
    }
    this.setStateSync(nextState);
  }
  assignSystems(systems: schema.system[]) {
    const nextState = immer.produce(this.state, (draft: IApplicationState) =>
      new RApplicationState(draft).assignSystems(systems)
    );
    if (this.__devTools) {
      this.__devTools.send('assignSystems', nextState);
    }
    this.setStateSync(nextState);
  }
  assignRooms(rooms: Model.UIDocument[]) {
    const nextState = immer.produce(this.state, (draft: IApplicationState) =>
      new RApplicationState(draft).assignRooms(rooms)
    );
    if (this.__devTools) {
      this.__devTools.send('assignRooms', nextState);
    }
    this.setStateSync(nextState);
  }
  async getAreas() {
    return new RApplicationState(
      undefined,
      (action: any) => {
        const nextState = ApplicationStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ ApplicationState: this.lastSetState })
    ).getAreas();
  }
  assignAreas(areas: Model.Area[]) {
    const nextState = immer.produce(this.state, (draft: IApplicationState) =>
      new RApplicationState(draft).assignAreas(areas)
    );
    if (this.__devTools) {
      this.__devTools.send('assignAreas', nextState);
    }
    this.setStateSync(nextState);
  }
  async updateArea(area: schema.area) {
    return new RApplicationState(
      undefined,
      (action: any) => {
        const nextState = ApplicationStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ ApplicationState: this.lastSetState })
    ).updateArea(area);
  }
  async updateAreaSection(section: schema.area_section) {
    return new RApplicationState(
      undefined,
      (action: any) => {
        const nextState = ApplicationStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ ApplicationState: this.lastSetState })
    ).updateAreaSection(section);
  }
  async getDocumentCategories() {
    return new RApplicationState(
      undefined,
      (action: any) => {
        const nextState = ApplicationStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ ApplicationState: this.lastSetState })
    ).getDocumentCategories();
  }
  assignDocumentCategories(origCats: Model.DocumentCategory[]) {
    const nextState = immer.produce(this.state, (draft: IApplicationState) =>
      new RApplicationState(draft).assignDocumentCategories(origCats)
    );
    if (this.__devTools) {
      this.__devTools.send('assignDocumentCategories', nextState);
    }
    this.setStateSync(nextState);
  }
  async sendPasswordResetRequest(email: string) {
    return new RApplicationState(
      undefined,
      (action: any) => {
        const nextState = ApplicationStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ ApplicationState: this.lastSetState })
    ).sendPasswordResetRequest(email);
  }
  async resetPassword(passwords: IPasswords) {
    return new RApplicationState(
      undefined,
      (action: any) => {
        const nextState = ApplicationStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ ApplicationState: this.lastSetState })
    ).resetPassword(passwords);
  }
  passwordChanged() {
    const nextState = immer.produce(this.state, (draft: IApplicationState) =>
      new RApplicationState(draft).passwordChanged()
    );
    if (this.__devTools) {
      this.__devTools.send('passwordChanged', nextState);
    }
    this.setStateSync(nextState);
  }
  async resetPasswordWithToken(params: IPasswordToken) {
    return new RApplicationState(
      undefined,
      (action: any) => {
        const nextState = ApplicationStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ ApplicationState: this.lastSetState })
    ).resetPasswordWithToken(params);
  }
  public async login(loginInfo: Model.APLoginCredidentials) {
    return new RApplicationState(
      undefined,
      (action: any) => {
        const nextState = ApplicationStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ ApplicationState: this.lastSetState })
    ).login(loginInfo);
  }
  setLoginStatus(loginStatus: LoginStatus) {
    const nextState = immer.produce(this.state, (draft: IApplicationState) =>
      new RApplicationState(draft).setLoginStatus(loginStatus)
    );
    if (this.__devTools) {
      this.__devTools.send('setLoginStatus', nextState);
    }
    this.setStateSync(nextState);
  }
  public async getUserDetails() {
    return new RApplicationState(
      undefined,
      (action: any) => {
        const nextState = ApplicationStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ ApplicationState: this.lastSetState })
    ).getUserDetails();
  }
  setUserDetails(user?: IUserDetails) {
    const nextState = immer.produce(this.state, (draft: IApplicationState) =>
      new RApplicationState(draft).setUserDetails(user)
    );
    if (this.__devTools) {
      this.__devTools.send('setUserDetails', nextState);
    }
    this.setStateSync(nextState);
  }
  public async acceptingAgreements(agreementIds: number[]) {
    return new RApplicationState(
      undefined,
      (action: any) => {
        const nextState = ApplicationStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ ApplicationState: this.lastSetState })
    ).acceptingAgreements(agreementIds);
  }
  public async logout() {
    return new RApplicationState(
      undefined,
      (action: any) => {
        const nextState = ApplicationStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ ApplicationState: this.lastSetState })
    ).logout();
  }
  async checkLoginStatus() {
    return new RApplicationState(
      undefined,
      (action: any) => {
        const nextState = ApplicationStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ ApplicationState: this.lastSetState })
    ).checkLoginStatus();
  }
  public render() {
    return (
      <ApplicationStateContext.Provider
        value={{
          ...this.state,
          setSystemError: this.setSystemError,
          setSystemDown: this.setSystemDown,
          getUserSettings: this.getUserSettings,
          getSystemState: this.getSystemState,
          updateSearchState: this.updateSearchState,
          resetSearchState: this.resetSearchState,
          saveUserSettings: this.saveUserSettings,
          setSearchNaviSelectedAreas: this.setSearchNaviSelectedAreas,
          setSidebarVisibility: this.setSidebarVisibility,
          ApplicationStateDispatcher: this.ApplicationStateDispatcher,
          getFileTypes: this.getFileTypes,
          getPhases: this.getPhases,
          getSystems: this.getSystems,
          createPitemType: this.createPitemType,
          setStatus: this.setStatus,
          setSuccess: this.setSuccess,
          setError: this.setError,
          resetAppSnacks: this.resetAppSnacks,
          getUserPermissions: this.getUserPermissions,
          getUserHasPermissions: this.getUserHasPermissions,
          getUserContracts: this.getUserContracts,
          assignUserContracts: this.assignUserContracts,
          getBuildingElementTree: this.getBuildingElementTree,
          getRooms: this.getRooms,
          assignBuildingElementTree: this.assignBuildingElementTree,
          assignFileTypes: this.assignFileTypes,
          assignPhases: this.assignPhases,
          assignSystems: this.assignSystems,
          assignRooms: this.assignRooms,
          getAreas: this.getAreas,
          assignAreas: this.assignAreas,
          updateArea: this.updateArea,
          updateAreaSection: this.updateAreaSection,
          getDocumentCategories: this.getDocumentCategories,
          assignDocumentCategories: this.assignDocumentCategories,
          sendPasswordResetRequest: this.sendPasswordResetRequest,
          resetPassword: this.resetPassword,
          passwordChanged: this.passwordChanged,
          resetPasswordWithToken: this.resetPasswordWithToken,
          login: this.login,
          setLoginStatus: this.setLoginStatus,
          getUserDetails: this.getUserDetails,
          setUserDetails: this.setUserDetails,
          acceptingAgreements: this.acceptingAgreements,
          logout: this.logout,
          checkLoginStatus: this.checkLoginStatus,
          targetPitemTypes: this.__selectortargetPitemTypes(this.state),
          equipmentTypes: this.__selectorequipmentTypes(this.state),
          userContracts: this.__selectoruserContracts(this.state),
          userContractorDocWritableContracts: this.__selectoruserContractorDocWritableContracts(
            this.state
          ),
          reverseTypeConversions: this.__selectorreverseTypeConversions(
            this.state
          ),
          getContractorWorkTypes: this.__selectorgetContractorWorkTypes(
            this.state
          ),
          getContractorMainDocumentypes: this.__selectorgetContractorMainDocumentypes(
            this.state
          ),
          getContractorDocumentPackageId: this.__selectorgetContractorDocumentPackageId(
            this.state
          ),
          getContractorDocumentypes: this.__selectorgetContractorDocumentypes(
            this.state
          ),
          designDisciplines: this.__selectordesignDisciplines(this.state),
          docCategories: this.__selectordocCategories(this.state),
          inspectionDocCategories: this.__selectorinspectionDocCategories(
            this.state
          ),
          permissions: this.__selectorpermissions(this.state),
          filteredPermissions: this.__selectorfilteredPermissions(this.state),
          uiBuildingElements: this.__selectoruiBuildingElements(this.state),
        }}
      >
        {' '}
        {this.props.children}
      </ApplicationStateContext.Provider>
    );
  }
}
