import PlayArrow from '@material-ui/icons/PlayArrow';
import * as React from 'react';
import * as container from '../../state/reducers/DocumentState';
import Loading from '../Common/Loading';
import { Notifier } from '../Common/Notifier';

interface IToggleAreaProps extends container.IProps {
  title: string;
  open?: boolean;
  classNames?: string;
  headerBarActions?: any;
  isLoading?: boolean;
  isLoaded?: boolean;
  error?: any;
}
interface IToggleAreaState {
  isVisible: boolean;
}

class AbstractToggleArea extends React.Component<
  IToggleAreaProps,
  IToggleAreaState
> {
  constructor(props: IToggleAreaProps) {
    super(props);
    this.state = { isVisible: this.props.open ? true : false };
    this.onToggleVisibility = this.onToggleVisibility.bind(this);
  }

  componentDidUpdate(prevProps: IToggleAreaProps) {
    if (prevProps.open !== this.props.open) {
      this.setState({ isVisible: !!this.props.open });
    }
  }

  async onToggleVisibility() {
    this.setState({ isVisible: !this.state.isVisible });
  }

  public render() {
    const {
      classNames,
      title,
      headerBarActions,
      error,
      isLoading,
    } = this.props;

    return (
      <div className={`ToggleArea component ${classNames}`}>
        <h2
          onClick={this.onToggleVisibility}
          className={`toggleable ${this.state.isVisible ? 'open' : ''}`}
        >
          <div className="header">{title}</div>

          <div className="filler" />

          <div className="headerBarActions">
            {headerBarActions}
            <PlayArrow className={`${this.state.isVisible ? 'open' : ''}`} />
          </div>
        </h2>
        {error && (
          <Notifier
            snack={{
              variant: 'error',
              message: `${error}`,
            }}
          />
        )}

        {isLoading && <Loading loading={true} />}

        {this.state.isVisible && (
          <div className="content">{this.props.children}</div>
        )}
      </div>
    );
  }
}

const ToggleArea = container.StateConnector(AbstractToggleArea);

export default ToggleArea;
