import * as React from 'react';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import * as container from '../../state/reducers/ApplicationState';
import * as documentState from '../../state/reducers/DocumentState';
import * as Model from '../../../../shared/src/model';
import DocumentTable from '../Common/DocumentTable';
import DocumentToolbar from '../Common/DocumentToolbar';
import { push } from 'connected-react-router';
import Moment from 'react-moment';
import { TableCell, TableRow } from '@material-ui/core';

interface IRouterProps {
  data: Model.UIDocument[];
  reload: () => void;
}

interface IRouterState {
  selectedDocuments: Model.UIDocument[];
  openPanels: { [key: string]: boolean };
  rows: Model.UIDocument[];
}

export class StatusHistory extends React.Component<
  IRouterProps & InjectedIntlProps & container.IProps & documentState.IProps,
  IRouterState
> {
  state: IRouterState = { selectedDocuments: [], openPanels: {}, rows: [] };

  updateRows() {
    const docPackageType = this.props.appDocumentCategories
      .filter(t => t.name === 'URAK_DP')
      .pop();
    const dataRows = (this.props.data || [])
      .filter(doc => {
        if (doc.categories && docPackageType) {
          return !(doc.categories.indexOf(docPackageType.id) >= 0);
        }
        return true;
      })
      .map(row => {
        row.contractorDocCategory = row.categories
          ? row.categories
              .map(c => {
                const cType = this.props.getContractorDocumentypes.filter(
                  t => t.id === c
                );
                if (cType.length === 0) return '';
                return cType[0].name;
              })
              .join('')
          : '';
        return row;
      })
      .sort((a, b) => {
        return (a.contractorDocCategory || '').localeCompare(
          b.contractorDocCategory || ''
        );
      });
    this.setState({
      rows: dataRows,
    });
  }

  // cache state update only when data props change
  componentDidUpdate(prevProps: IRouterProps) {
    if (prevProps.data !== this.props.data) {
      this.updateRows();
    }
  }

  // initial update of the state
  componentDidMount() {
    this.updateRows();
  }

  render() {
    const t = (id: string) => this.props.intl.formatMessage({ id });
    return (
      <DocumentTable
        hasSelect={true}
        keys={{
          fileName: t('documentMeta.fileName'),
          createdByUser: t('documentMeta.createdByUser'),
          workflowStateName: t('documentMeta.workflowStateName'),
          modified: t('documentMeta.modified'),
        }}
        actionComponents={[
          <DocumentToolbar
            didUpdateDocuments={() => {
              this.props.reload();
              this.setState({ selectedDocuments: [] });
            }}
            key={'toolbar1'}
            selectedDocuments={this.state.selectedDocuments}
          />,
        ]}
        setSelected={ids => {
          this.setState({
            selectedDocuments: this.props.data.filter(doc => {
              return ids.indexOf(doc.id) >= 0;
            }),
          });
        }}
        selectedDocuments={this.state.selectedDocuments}
        rowClick={doc => {
          const selectedDoc = (this.props.data || []).filter(
            row => row.id === doc.id
          );
          if (selectedDoc.length === 0) {
            return;
          }
          this.setState({
            openPanels: {},
          });
          this.props.DocumentStateDispatcher(
            push('/documents/' + selectedDoc[0].id)
          );
        }}
        rowHeader={(() => {
          let lastValue = '';
          return (index: number, rowIndex: number): React.ReactNode => {
            if (index === 0) {
              lastValue = '';
            }
            const row = this.state.rows[index];
            if (
              lastValue !== row.contractorDocCategory &&
              row.contractorDocCategory
            ) {
              lastValue = row.contractorDocCategory;
              return (
                <TableRow className="subHeaderTR">
                  <TableCell colSpan={5} className="subHeaderTD">
                    <h3>{row.contractorDocCategory}</h3>
                  </TableCell>
                </TableRow>
              );
            }
            lastValue = row.contractorDocCategory || '';
            return <></>;
          };
        })()}
        dataRows={this.state.rows}
        rows={this.state.rows.map(doc => {
          return {
            ...doc,
            modified: <Moment date={doc.modified} format="DD.MM.YYYY HH:mm" />,
          };
        })}
      />
    );
  }
}

export default documentState.StateConnector(
  container.StateConnector(injectIntl(StatusHistory))
);
