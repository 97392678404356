import * as React from 'react';
import { withStyles, Theme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = (theme: Theme) => ({
  progress: {
    margin: theme.spacing.unit * 2,
    color: 'rgb(39, 99, 158)',
  },
});

interface LoadingProps {
  classes: Record<'progress', string>;
  loading: boolean;
  label?: string;
  style?: any;
}

const Loading = (props: LoadingProps) => {
  const { classes, loading, label, style } = props;
  return (
    <div className="loading">
      {loading && (
        <div style={{ textAlign: 'center', ...style }}>
          <CircularProgress
            size={32}
            color="primary"
            className={classes.progress}
          />
          {label && <p>{label}</p>}
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(Loading);
