import * as React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import * as applicationState from '../../state/reducers/ApplicationState';
import { setBackgroundImage } from '../../utils/index';
import AdminTools from './index';
import './adminTools.scss';
import { Async } from '../Common/Async';

import axios from 'axios';
import { APIService } from '../../services/api/src/frontend/api';
import { Paper, Card, CardContent } from '@material-ui/core';
import { DataTable } from '../Common/DataTable';

const API = APIService(axios);

interface IMailTasksProps extends applicationState.IProps {
  match: {
    params: {
      id: number;
    };
  };
}

interface IMailTasksState {}

type MailTasksProps = IMailTasksProps & InjectedIntlProps;

class AbstractMailTasks extends React.Component<
  MailTasksProps,
  IMailTasksState
> {
  constructor(props: MailTasksProps) {
    super(props);

    this.state = {};
  }

  componentDidMount = async () => {
    setBackgroundImage();
  };

  render() {
    // getEmailInfo
    return (
      <AdminTools>
        <Async
          loader={() => API.getEmailInfo(this.props.match.params.id)}
          render={data => {
            if (!data || data.length === 0) {
              return <div />;
            }
            const mail = data[0];
            const keys = Object.keys(mail).filter(v => v !== 'rendered_html');
            const dataRows: Array<{ key: string; value: any }> = new Array(
              keys.length
            );
            for (const key of keys) {
              dataRows.push({
                key,
                value: mail[key],
              });
            }
            return (
              <div>
                <Paper>
                  <DataTable
                    rows={dataRows}
                    keys={['key', 'value']}
                    render={{
                      value: row => <pre>{JSON.stringify(row.value)}</pre>,
                    }}
                  />
                  <Card>
                    <CardContent>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: mail.rendered_html || '',
                        }}
                      />
                    </CardContent>
                  </Card>
                </Paper>
              </div>
            );
          }}
        />
      </AdminTools>
    );
  }
}

const MailTasks = applicationState.StateConnector(AbstractMailTasks);

export default injectIntl(MailTasks);
