import * as React from 'react';
import * as container from '../state/reducers/ApplicationState';
import Health from '../components/Common/Health';
import { ErrorBar } from 'components/Common/ErrorBar';
import { ErrorStateConsumer } from 'state/reducers/ErrorState';
import { GlobalNotifier } from '../components/Common/Notifier';

interface IPublicLayoutStateProps extends container.IProps {
  something?: '';
}

type PublicLayoutProps = IPublicLayoutStateProps;

interface IPublicLayoutState {
  something: '';
}

class AbstractPublicLayout extends React.Component<
  PublicLayoutProps,
  IPublicLayoutState
> {
  componentDidMount() {
    this.props.checkLoginStatus();
  }

  render() {
    return (
      <div className="publicContentWrapper contentArea">
        <Health />
        <ErrorStateConsumer>
          {ctx => <GlobalNotifier ctx={ctx} />}
        </ErrorStateConsumer>

        <div className="globalNotifications">
          <ErrorBar />
        </div>
        {this.props.children}
      </div>
    );
  }
}

const PublicLayout = container.StateConnector(AbstractPublicLayout);

export default PublicLayout;
