import * as React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import * as applicationState from '../../state/reducers/ApplicationState';
import { setBackgroundImage } from '../../utils/index';
import AdminTools from './index';
import './adminTools.scss';
import { Async } from '../Common/Async';
import { push } from 'connected-react-router';
import axios from 'axios';
import { APIService } from '../../services/api/src/frontend/api';
import { DataTable } from '../Common/DataTable';
import Moment from 'react-moment';
import { Cached, HighlightOffRounded, CheckCircle } from '@material-ui/icons';
const API = APIService(axios);

interface IMailTasksProps extends applicationState.IProps {}

interface IMailTasksState {}

type MailTasksProps = IMailTasksProps & InjectedIntlProps;

class AbstractMailTasks extends React.Component<
  MailTasksProps,
  IMailTasksState
> {
  constructor(props: MailTasksProps) {
    super(props);

    this.state = {};
  }

  componentDidMount = async () => {
    setBackgroundImage();
  };

  render() {
    return (
      <AdminTools>
        <Async
          loader={() => API.getLatestPrintOrders()}
          render={data => {
            if (!data) {
              return <div />;
            }
            return (
              <DataTable
                rows={data}
                keys={[
                  'print_order_id',
                  'order_success',
                  'sent_at',
                  'company',
                  'address',
                  'city',
                  'postal_code',
                  'additional_info',
                  'send_attempts',
                ]}
                titles={{
                  print_order_id: 'ID',
                }}
                onClick={row => {
                  this.props.ApplicationStateDispatcher(
                    push(`/admin-tools/print-orders/${row.id}`)
                  );
                }}
                render={{
                  order_success: row => {
                    return row.order_success ? (
                      <CheckCircle style={{ color: 'green' }} />
                    ) : row.send_attempts! < 10 ? (
                      <Cached />
                    ) : (
                      <HighlightOffRounded style={{ color: 'red' }} />
                    );
                  },
                  sent_at: row =>
                    row.sent_at ? (
                      <Moment date={row.sent_at} format="DD.MM.YYYY HH:mm" />
                    ) : (
                      <span>-</span>
                    ),
                }}
              />
            );
          }}
        />
      </AdminTools>
    );
  }
}

const MailTasks = applicationState.StateConnector(AbstractMailTasks);

export default injectIntl(MailTasks);
