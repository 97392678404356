import * as React from 'react';

export const EditMetadataIcon = () => (
  <svg
    className="custom"
    viewBox="0 0 30 30"
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    fill="transparent"
  >
    <path className="st0" d="M0,0h24v24H0V0z" />
    <g id="XMLID_1_">
      <path
        className="st1"
        fill="#eee"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20,8v12c0,1.1-0.9,2-2,2H6c-1.1,0-2-0.9-2-2L4,4c0-1.1,0.9-2,2-2h8L20,8z M13,9h5.5L13,3.5V9z M17.8,20.1
		v-2.4l-7.1-7.1L8.3,13l7.1,7.1H17.8z M7.6,12.4L10,10L8.8,8.8c-0.2-0.2-0.6-0.2-0.9,0l-1.5,1.5c-0.2,0.2-0.2,0.7,0,0.9L7.6,12.4z"
      />
    </g>
  </svg>
);

export const Comment = () => (
  <svg viewBox="0 0 113 92" version="1.1">
    <path d="M40.3446475,75.9428744 L9.16132019,91.3770465 L20.7293199,68.0049655 C9.23076481,60.8658813 2,50.5158131 2,39 C2,17.4608948 27.2959116,0 58.5,0 C89.7040884,0 115,17.4608948 115,39 C115,60.5391052 89.7040884,78 58.5,78 C52.1489808,78 46.0427085,77.2766802 40.3446475,75.9428744 Z" />
  </svg>
);
