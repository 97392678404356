const brandVariables = {
  'msapplication-TileColor': '#003255',
  'meta-theme-color': '#ffffff',
  fi: {
    applicationTitle: 'METTI',
    applicationName: 'METTI',
    applicationLongName: 'METTI-portaali: Etusivu',
    gdprClause: ``,
  },
  en: {
    applicationTitle: 'METTI',
    applicationName: 'METTI',
    applicationLongName: 'METTI portal: Front Page',
    gdprClause: ``,
  },
};

brandVariables.fi.gdprClause = `
# METTI tietosuojaseloste (verkkosivu)

Euroopan yleisen tietosuoja-asetuksen (EU 2016/679) mukainen tietosuojaseloste.

## Rekisterin ylläpitäjä:
Länsimetro Oy
Piispanportti 10
02200 ESPOO

## Rekisterin yhteyshenkilö
Rekisteriä koskeviin kysymyksiin ja palautteisiin vastaa yhteyshenkilö:
Juhani Penttilä
juhani.penttila@lansimetro.fi

## Rekisterin nimi
METTI - Metrotietojärjestelmä

## Rekisterin käyttötarkoitus ja henkilötietojen käsittelyn tarkoitus
Rekisterin tarkoitus on ylläpitää järjestelmään tallennettujen dokumenttien ja muiden kohteiden versiohistoriaa sekä käsittelytietoja, jotta voidaan jäljittää tietoja käsitelleet tahot.

## Rekisterin sisältämät tiedot
METTI-portaalia käytetään erillisen käyttöoikeushallinnan kautta, jolloin tunnus ja käyttöoikeudet sulkeutuvat samalla, kun oikeus verkon käyttöön sulkeutuu.

Rekisteri voi sisältää seuraavat tiedot asiakkaista:
*	Sähköpostiosoite (METTI-portaali)
*	Etunimi (käyttöoikeushallinta)
*	Sukunimi (käyttöoikeushallinta)
*	Näytettävä nimi (jos eri kuin etunimi sukunimi) (käyttöoikeushallinta)
*	Sähköposti (käyttöoikeushallinta)
*	Puhelinnumero (käyttöoikeushallinta)

## Rekisterin tietolähteet
Käyttäjätiedot kerätään käyttöönoton yhteydessä sekä käytön aikana luomalla järjestelmään käyttäjät ja antamalla järjestelmään käyttöoikeudet. Käyttäjäksi rekisteröityminen tapahtuu Länsimetro Oy:n hallinnoiman prosessin kautta. Rekisterin henkilötietoja ei yhdistetä muihin tietolähteisiin.

## Henkilötietojen säilytysaika
Henkilötietoja säilytetään palvelun toiminnan ajan. Rekisterinpitäjä tuhoaa rekisteröidyn henkilötiedot viipymättä palvelun käyttösuhteen päättymisen jälkeen tai erikseen rekisteröidyn pyynnöstä.
Henkilötietojen luovutukset
Henkilötietoja ei luovuteta edelleen kolmansille osapuolille normaalissa toiminnassa. Järjestelmän sisällä käyttäjät voivat nähdä toisten käyttäjien nimitiedot.

## Rekisterin suojaus
Tiedot kerätään tietokantoihin, jotka ovat palomuurein, salasanoin ja muunlaisin teknisin keinoin suojattuja. Talletettuihin tietoihin on rajoitetut käyttöoikeudet. Käyttäjärekisteriin pääsevät käsiksi vain sellaiset henkilöt, joilla työtehtäviensä vuoksi on käyttöoikeus tietokantoihin.

## Rekisteröidyn oikeudet
Rekisteröidyllä on henkilötietolain 26§ mukaan oikeus tarkastaa, mitä häntä koskevia tietoja henkilörekisteriin on tallennettu. Rekisteröidyllä on oikeus vaatia rekisterissä olevan virheellisen henkilötiedon oikaisemista. Rekisteröidyllä on oikeus kieltää rekisterinpitäjää käsittelemästä häntä itseään koskevia tietoja tai rajoittaa henkilötietojensa käsittelyä, tai muuten vastustaa henkilötietojensa käsittelyä. Rekisteröidyllä on oikeus siirtää henkilötietonsa rekisteristä toiseen.

Pyyntö rekisteröidyn oikeuksien käyttämiseen tulee lähettää kirjallisesti ja allekirjoitettuna osoitteeseen rekisterin ylläpitäjälle:

##Valitusoikeus
Rekisteröidyllä on oikeus tehdä valitus valvontaviranomaiselle henkilötietojensa käyttöä koskevassa asiassa.

## Evästeiden käyttö
Eväste on tekstitiedosto, joka verkkosivulla käydessä tallentuu käyttäjän tietokoneelle. Se sisältää tietoja ja sitä muun muassa käytetään helpottamaan verkkosivuston käyttöä. Nämä tiedot liittyvät käyttäjän tietokoneen yhteysosoitteeseen (IP-osoite), niihin alisivuihin, joilla käyttäjä vierailee, vierailun päivämäärään ja kestoon, ja käyttäjän käyttämään päätelaitteeseen, käyttöjärjestelmään ja verkkoselaimeen sekä siihen verkkosivustoon tai muuhun lähtöpisteeseen, josta käyttäjä siirtyi METTI verkkosivustolle. Evästeitä käytetään vain käyttäjän pääsynhallintaan ja tunnistautumistiedon ylläpitoon. Henkilöllisyys ei käy ilmi evästeiden avulla. Eväste ei vahingoita päätelaitetta.

Käyttäjällä on mahdollisuus estää evästeiden käyttö muuttamalla selaimensa asetuksia. Evästeiden käytön estäminen vaikuttaa sivuston tai sen osien toiminnallisuuteen.

## Linkit muihin sivustoihin
METTI-verkkosivusto saattaa sisältää linkkejä muille websivustoille. Kävijän tulisi kuitenkin huomioida, että muille sivuille siirtyessä kävijä on itse vastuussa toimistaan. Rekisterin ylläpitäjällä ei ole mahdollisuutta kontrolloida ulkoisten sivustojen sisältöä. Tämän vuoksi rekisterin ylläpitäjä ei voi ottaa vastuuta käyttäjän yksityisyydensuojasta tai turvallisuudesta hänen vieraillessaan sivuston ulkopuolella.
`;

brandVariables.en.gdprClause = `
# METTI tietosuojaseloste (verkkosivu)

Euroopan yleisen tietosuoja-asetuksen (EU 2016/679) mukainen tietosuojaseloste.

## Rekisterin ylläpitäjä:
Länsimetro Oy
Piispanportti 10
02200 ESPOO

## Rekisterin yhteyshenkilö
Rekisteriä koskeviin kysymyksiin ja palautteisiin vastaa yhteyshenkilö:
Ulla Valtonen
ulla.valtonen@lansimetro.fi

## Rekisterin nimi
METTI - Metrotietojärjestelmä

## Rekisterin käyttötarkoitus ja henkilötietojen käsittelyn tarkoitus
Rekisterin tarkoitus on ylläpitää järjestelmään tallennettujen dokumenttien ja muiden kohteiden versiohistoriaa sekä käsittelytietoja, jotta voidaan jäljittää tietoja käsitelleet tahot.

## Rekisterin sisältämät tiedot
METTI-portaalia käytetään erillisen käyttöoikeushallinnan kautta, jolloin tunnus ja käyttöoikeudet sulkeutuvat samalla, kun oikeus verkon käyttöön sulkeutuu.

Rekisteri voi sisältää seuraavat tiedot asiakkaista:
*	Sähköpostiosoite (METTI-portaali)
*	Etunimi (käyttöoikeushallinta)
*	Sukunimi (käyttöoikeushallinta)
*	Näytettävä nimi (jos eri kuin etunimi sukunimi) (käyttöoikeushallinta)
*	Sähköposti (käyttöoikeushallinta)
*	Puhelinnumero (käyttöoikeushallinta)

## Rekisterin tietolähteet
Käyttäjätiedot kerätään käyttöönoton yhteydessä sekä käytön aikana luomalla järjestelmään käyttäjät ja antamalla järjestelmään käyttöoikeudet. Käyttäjäksi rekisteröityminen tapahtuu Länsimetro Oy:n hallinnoiman prosessin kautta. Rekisterin henkilötietoja ei yhdistetä muihin tietolähteisiin.

## Henkilötietojen säilytysaika
Henkilötietoja säilytetään palvelun toiminnan ajan. Rekisterinpitäjä tuhoaa rekisteröidyn henkilötiedot viipymättä palvelun käyttösuhteen päättymisen jälkeen tai erikseen rekisteröidyn pyynnöstä.
Henkilötietojen luovutukset
Henkilötietoja ei luovuteta edelleen kolmansille osapuolille normaalissa toiminnassa. Järjestelmän sisällä käyttäjät voivat nähdä toisten käyttäjien nimitiedot.

## Rekisterin suojaus
Tiedot kerätään tietokantoihin, jotka ovat palomuurein, salasanoin ja muunlaisin teknisin keinoin suojattuja. Talletettuihin tietoihin on rajoitetut käyttöoikeudet. Käyttäjärekisteriin pääsevät käsiksi vain sellaiset henkilöt, joilla työtehtäviensä vuoksi on käyttöoikeus tietokantoihin.

## Rekisteröidyn oikeudet
Rekisteröidyllä on henkilötietolain 26§ mukaan oikeus tarkastaa, mitä häntä koskevia tietoja henkilörekisteriin on tallennettu. Rekisteröidyllä on oikeus vaatia rekisterissä olevan virheellisen henkilötiedon oikaisemista. Rekisteröidyllä on oikeus kieltää rekisterinpitäjää käsittelemästä häntä itseään koskevia tietoja tai rajoittaa henkilötietojensa käsittelyä, tai muuten vastustaa henkilötietojensa käsittelyä. Rekisteröidyllä on oikeus siirtää henkilötietonsa rekisteristä toiseen.

Pyyntö rekisteröidyn oikeuksien käyttämiseen tulee lähettää kirjallisesti ja allekirjoitettuna osoitteeseen rekisterin ylläpitäjälle:

##Valitusoikeus
Rekisteröidyllä on oikeus tehdä valitus valvontaviranomaiselle henkilötietojensa käyttöä koskevassa asiassa.

## Evästeiden käyttö
Eväste on tekstitiedosto, joka verkkosivulla käydessä tallentuu käyttäjän tietokoneelle. Se sisältää tietoja ja sitä muun muassa käytetään helpottamaan verkkosivuston käyttöä. Nämä tiedot liittyvät käyttäjän tietokoneen yhteysosoitteeseen (IP-osoite), niihin alisivuihin, joilla käyttäjä vierailee, vierailun päivämäärään ja kestoon, ja käyttäjän käyttämään päätelaitteeseen, käyttöjärjestelmään ja verkkoselaimeen sekä siihen verkkosivustoon tai muuhun lähtöpisteeseen, josta käyttäjä siirtyi METTI verkkosivustolle. Evästeitä käytetään vain käyttäjän pääsynhallintaan ja tunnistautumistiedon ylläpitoon. Henkilöllisyys ei käy ilmi evästeiden avulla. Eväste ei vahingoita päätelaitetta.

Käyttäjällä on mahdollisuus estää evästeiden käyttö muuttamalla selaimensa asetuksia. Evästeiden käytön estäminen vaikuttaa sivuston tai sen osien toiminnallisuuteen.

## Linkit muihin sivustoihin
METTI-verkkosivusto saattaa sisältää linkkejä muille websivustoille. Kävijän tulisi kuitenkin huomioida, että muille sivuille siirtyessä kävijä on itse vastuussa toimistaan. Rekisterin ylläpitäjällä ei ole mahdollisuutta kontrolloida ulkoisten sivustojen sisältöä. Tämän vuoksi rekisterin ylläpitäjä ei voi ottaa vastuuta käyttäjän yksityisyydensuojasta tai turvallisuudesta hänen vieraillessaan sivuston ulkopuolella.
`;

export default brandVariables;
