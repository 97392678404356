import * as React from 'react';
import {
  readFileToWorksheet,
  parseRoomInformation,
} from '../../../utils/excel';
import FileInput from '../../Common/FileInput';
import { RoomListing } from '../../../../../shared/src/model/index';
import { useFormatMessage } from 'utils/translateHook';

interface ItemRegistryProps {
  onData?: (data: RoomListing) => void;
}

export function RoomExcelImport(props: ItemRegistryProps) {
  // files
  const [files, setFiles] = React.useState<File[]>([]);
  const t = useFormatMessage();
  return (
    <FileInput
      id="item_excel_import"
      files={files}
      label={t('adminTools.selectRoomDocument')}
      fileListRenderer={() => <div />}
      onChange={async files => {
        if (props.onData) {
          props.onData(
            parseRoomInformation(await readFileToWorksheet(files[0]))
          );
          setFiles([]);
        }
      }}
    />
  );
}
