import {
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Step,
  StepLabel,
  Stepper,
  TextField,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import * as React from 'react';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Link } from 'react-router-dom';
import {
  DeliveryMethod,
  OrderRow,
  OrderRowDocument,
  PrintOrder,
  UIDocument,
  Folding,
} from '../../../../shared/src/model/index';
import MainLayout from '../../layout/MainLayout';
import * as applicationState from '../../state/reducers/ApplicationState';
import * as documentState from '../../state/reducers/DocumentState';
import Button from '../Common/Button';
import DocumentSelector, { DocumentRow } from './DocumentSelector';
import './PrintOrder.scss';
import PrintOrderRowsTable from './PrintOrderRowsTable';
import PrintOrderSummary from './PrintOrderSummary';
import { normalizeExtension } from '../../utils/filename';
import Breadcrumbs from '../Common/Breadcrumbs';
import SmallNumberPicker from './SmallNumberPicker';

// Logical order: result of submitting this form. What user thinks of as a single order.
// Actual order: single print order that is sent to print supplier.
// Logical order can contain many actual orders.

interface IPrintOrderCheckoutProps
  extends applicationState.IProps,
    documentState.IProps {}

interface PrintOrderCheckoutState {
  activeStep: number;
  orderState: PrintOrder;
  // State of new row that can be added to the order with a button click.
  orderRowState: OrderRow;
  sendingOrder: boolean;

  orderAmount: number;
  folding: Folding;
}

type PrintOrderCheckoutProps = IPrintOrderCheckoutProps & InjectedIntlProps;

const defaultOrderState: PrintOrder = {
  orderer: 'tuki@lansimetro.fi',
  ordererFirstName: '',
  ordererLastName: '',
  ordererEmail: '',
  ordererPhoneNumber: '',
  projectName: '',
  payerCompany: '',
  payerAddress: '',
  payerPostalCode: '',
  payerCity: '',
  payerCountry: '',
  orderAdditionalInfo: '',
  orderRows: [],
};

const defaultOrderRowState: OrderRow = {
  rowKey: -1,
  companyName: '',
  address: '',
  city: '',
  postalCode: '',
  deliveryMethod: 'DeliveryGuy',
  additionalInfo: '',
  documents: [],
};

const foldingTypes: Folding[] = [
  'Folded210mm',
  'Folded190mm',
  'Folded190mmSpine',
  'Folded210mmSpine',
  'Folded19_21',
  'StraightCopy',
  'OriginalsReturn',
  'PtintHotlaminate',
  'Weatherproof',
  'PrintPlastic',
  'Scanning',
  'OtherHandling',
];

class PrintOrderCheckout extends React.Component<
  PrintOrderCheckoutProps,
  PrintOrderCheckoutState
> {
  state: PrintOrderCheckoutState = {
    activeStep: 0,
    sendingOrder: false,
    orderState: { ...defaultOrderState },
    orderRowState: { ...defaultOrderRowState },

    orderAmount: 1,
    folding: 'Folded210mm',
  };

  private orderRowKey = 1;
  private orderSteps = [
    'printOrder.step1',
    'printOrder.step2',
    'printOrder.step3',
    'printOrder.step4',
  ];

  private deliveryMethods: DeliveryMethod[] = ['DeliveryGuy', 'Post'];
  private downloadBasketDocuments: UIDocument[] = [];

  constructor(props: PrintOrderCheckoutProps) {
    super(props);
    this.translate = this.translate.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.prevStep = this.prevStep.bind(this);
    this.onOrderRowDocumentsChange = this.onOrderRowDocumentsChange.bind(this);
    this.onAddToOrderClicked = this.onAddToOrderClicked.bind(this);
    this.onDocumentRemovedFromOrder = this.onDocumentRemovedFromOrder.bind(
      this
    );
    this.sendOrder = this.sendOrder.bind(this);
    this.orderSteps = this.orderSteps.map(this.translate);

    this.downloadBasketDocuments = this.props.downloadBasket.filter(
      doc => normalizeExtension(doc.fileExtension) === 'pdf'
    );
  }

  shouldComponentUpdate(
    nextProps: PrintOrderCheckoutProps,
    nextState: PrintOrderCheckoutState
  ) {
    return nextState !== this.state || nextProps.user !== this.props.user;
  }

  translate(id: string) {
    return this.props.intl.formatMessage({ id });
  }

  // TODO scroll to top of the form on step change
  nextStep() {
    this.setState(state =>
      state.activeStep < 3 ? { activeStep: state.activeStep + 1 } : null
    );
  }

  prevStep() {
    this.setState(state =>
      state.activeStep > 0 ? { activeStep: state.activeStep - 1 } : null
    );
  }

  onOrderPropModified = (propName: keyof PrintOrder) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.currentTarget.value;
    this.setState(state => ({
      orderState: {
        ...state.orderState,
        [propName]: value,
      },
    }));
  };

  onOrderRowPropModified = (propName: keyof OrderRow) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.currentTarget.value;
    this.setState(state => ({
      orderRowState: {
        ...state.orderRowState,
        [propName]: value,
      },
    }));
  };

  onOrderRowDeliveryMethodChanged = (newMethod: DeliveryMethod) => {
    this.setState(state => ({
      orderRowState: { ...state.orderRowState, deliveryMethod: newMethod },
    }));
  };

  onOrderRowDocumentsChange(documents: DocumentRow[]) {
    const orderRowDocuments: OrderRowDocument[] = documents
      .filter(doc => doc.selected && doc.amount > 0)
      .map(doc => ({
        id: doc.id,
        name: doc.name,
        amount: doc.amount,
        folding: doc.folding,
        isColor: doc.isColor,
      }));

    this.setState(state => ({
      orderRowState: { ...state.orderRowState, documents: orderRowDocuments },
    }));
  }

  onAddToOrderClicked() {
    this.setState(state => {
      if (state.orderRowState.documents.length === 0) {
        return null;
      }
      return {
        orderState: {
          ...state.orderState,
          orderRows: [
            ...state.orderState.orderRows,
            { ...state.orderRowState, rowKey: this.orderRowKey++ },
          ],
        },
      };
    });
  }

  onDocumentRemovedFromOrder(rowKey: number, removedDocId: number) {
    this.setState(state => {
      const updatedOrderRows = state.orderState.orderRows
        .map(row =>
          row.rowKey !== rowKey
            ? row
            : {
                ...row,
                documents: row.documents.filter(doc => doc.id !== removedDocId),
              }
        )
        .filter(row => row.documents.length > 0);

      return {
        orderState: {
          ...state.orderState,
          orderRows: updatedOrderRows,
        },
      };
    });
  }

  componentDidUpdate(prevProps: PrintOrderCheckoutProps) {
    if (
      !this.props.docstatus.sendPrintOrder.loading &&
      this.state.sendingOrder
    ) {
      this.setState({ sendingOrder: false });
    }
    // if (
    //   this.props.docstatus.sendPrintOrder.loaded &&
    //   this.state.activeStep === 2
    // ) {
    //   // Order sent success, go to final step
    //   this.nextStep();
    //   this.resetOrderState();
    // }
  }

  async sendOrder() {
    this.setState({ sendingOrder: true });
    await this.props.sendPrintOrder(this.state.orderState);
    this.props.clearBasket();
    this.setState({
      sendingOrder: false,
      orderState: { ...defaultOrderState },
      orderRowState: { ...defaultOrderRowState },
      activeStep: 3,
    });
    // We react to order request completion in componentDidUpdate
  }

  resetOrderState() {
    this.setState({
      orderState: { ...defaultOrderState },
      orderRowState: { ...defaultOrderRowState },
    });
  }

  onDocumentsAmountChange(orderAmount: number) {
    const { documents } = this.state.orderRowState;
    const updatedDocuments = documents.map(doc => ({
      ...doc,
      amount: orderAmount,
    }));

    // Update new amount for all the documents
    this.setState({
      orderAmount,
      orderRowState: {
        ...this.state.orderRowState,
        documents: updatedDocuments,
      },
    });
  }

  onDocumentsFoldingChange(folding: Folding) {
    const { documents } = this.state.orderRowState;
    const updatedDocuments = documents.map(doc => ({
      ...doc,
      folding,
    }));

    this.setState({
      folding,
      orderRowState: {
        ...this.state.orderRowState,
        documents: updatedDocuments,
      },
    });
  }

  render() {
    return (
      <MainLayout>
        <Breadcrumbs>
          <Link to="/">
            <FormattedMessage id="headers.home" />
          </Link>
          <FormattedMessage id="downloadBasket.title" />
        </Breadcrumbs>

        <h1>
          <FormattedMessage id="downloadBasket.title" />
        </h1>

        <div className="grid gridCols1">
          <Paper>
            <div className="printOrderContent">
              {this.downloadBasketDocuments.length > 0 && this.renderForm()}
              {this.downloadBasketDocuments.length === 0 && (
                <p>
                  <FormattedMessage id="printOrder.basketEmpty" />
                </p>
              )}
            </div>
          </Paper>
        </div>
      </MainLayout>
    );
  }

  renderFoldingSelectItems() {
    return foldingTypes.map((foldingType, i) => (
      <MenuItem value={foldingType} key={i}>
        <FormattedMessage id={`printOrder.folding.${foldingType}`} />
      </MenuItem>
    ));
  }

  renderForm() {
    const activeStep = this.state.activeStep;
    return (
      <div>
        <h2>{this.translate('printOrder.title')}</h2>
        <Stepper
          activeStep={this.state.activeStep}
          alternativeLabel={true}
          style={{ paddingLeft: '0px', paddingRight: '0px' }}
        >
          {this.orderSteps.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div className="printOrderForm">
          {activeStep === 0 && this.renderStep1()}
          {/* Only hide step 2 so all the subcomponents there keep their state without having to save it somewhere */}
          <div style={{ display: activeStep === 1 ? 'block' : 'none' }}>
            {this.renderStep2()}
          </div>
          {activeStep === 2 && this.renderStep3()}
          {activeStep === 3 && this.renderStep4()}
        </div>
      </div>
    );
  }

  renderStep1() {
    const t = this.translate;

    return (
      <ValidatorForm onSubmit={this.nextStep} instantValidate={false}>
        <div className="formGroup">
          <h3>{t('printOrder.generalInfo')}</h3>
          <div className="formGroupContent">
            <TextField
              label={t('printOrder.orderer')}
              // TODO this might need to be a company name.
              value={this.state.orderState.orderer}
              margin="dense"
              className="textInput"
              disabled={true}
              fullWidth={true}
              inputProps={{ maxLength: 150 }}
            />

            <div className="multipartInput">
              <label>{t('printOrder.ordererContact')}</label>
              <Grid
                className="multipartInputContent"
                container={true}
                spacing={8}
                justify="space-between"
                alignItems="flex-start"
              >
                <Grid item={true} xs={12} sm={6} md={3}>
                  <TextField
                    label={t('userDetails.firstName')}
                    defaultValue={this.state.orderState.ordererFirstName}
                    onBlur={this.onOrderPropModified('ordererFirstName')}
                    margin="none"
                    className="textInput"
                    inputProps={{ maxLength: 150 }}
                    required={true}
                    fullWidth={true}
                  />
                </Grid>
                <Grid item={true} xs={12} sm={6} md={3}>
                  <TextField
                    label={t('userDetails.lastName')}
                    defaultValue={this.state.orderState.ordererLastName}
                    onBlur={this.onOrderPropModified('ordererLastName')}
                    margin="none"
                    className="textInput"
                    required={true}
                    fullWidth={true}
                    inputProps={{ maxLength: 150 }}
                  />
                </Grid>
                <Grid item={true} xs={12} sm={6} md={3}>
                  <TextValidator
                    name="ordererEmail"
                    label={t('userDetails.email')}
                    value={this.state.orderState.ordererEmail}
                    onChange={this.onOrderPropModified('ordererEmail')}
                    margin="none"
                    className="textInput"
                    required={true}
                    fullWidth={true}
                    inputProps={{ maxLength: 50 }}
                    validators={['isEmail']}
                    errorMessages={[t('printOrder.errors.email')]}
                  />
                </Grid>
                <Grid item={true} xs={12} sm={6} md={3}>
                  <TextField
                    label={t('userDetails.phoneNumber')}
                    defaultValue={this.state.orderState.ordererPhoneNumber}
                    onBlur={this.onOrderPropModified('ordererPhoneNumber')}
                    margin="none"
                    className="textInput"
                    required={true}
                    fullWidth={true}
                    inputProps={{ maxLength: 25 }}
                  />
                </Grid>
              </Grid>
            </div>

            <TextField
              label={t('printOrder.project')}
              defaultValue={this.state.orderState.projectName}
              onBlur={this.onOrderPropModified('projectName')}
              margin="dense"
              className="textInput"
              required={true}
              fullWidth={true}
              inputProps={{ maxLength: 100 }}
            />
          </div>
        </div>

        <div className="formGroup">
          <h3>{t('printOrder.payerInfo')}</h3>
          <div className="formGroupContent">
            <TextField
              label={t('printOrder.payerCompany')}
              defaultValue={this.state.orderState.payerCompany}
              onBlur={this.onOrderPropModified('payerCompany')}
              margin="dense"
              className="textInput"
              required={true}
              fullWidth={true}
              inputProps={{ maxLength: 150 }}
            />
            <TextField
              label={t('printOrder.streetAddress')}
              defaultValue={this.state.orderState.payerAddress}
              onBlur={this.onOrderPropModified('payerAddress')}
              margin="dense"
              className="textInput"
              fullWidth={true}
              inputProps={{ maxLength: 50 }}
            />
            <TextField
              label={t('printOrder.postalCode')}
              defaultValue={this.state.orderState.payerPostalCode}
              onBlur={this.onOrderPropModified('payerPostalCode')}
              margin="dense"
              className="textInput"
              fullWidth={true}
              inputProps={{ maxLength: 20 }}
            />
            <TextField
              label={t('printOrder.city')}
              defaultValue={this.state.orderState.payerCity}
              onBlur={this.onOrderPropModified('payerCity')}
              margin="dense"
              className="textInput"
              fullWidth={true}
              inputProps={{ maxLength: 50 }}
            />
            <TextField
              label={t('printOrder.country')}
              defaultValue={this.state.orderState.payerCountry}
              onBlur={this.onOrderPropModified('payerCountry')}
              margin="dense"
              className="textInput"
              fullWidth={true}
              inputProps={{ maxLength: 50 }}
            />
          </div>
        </div>

        <div className="formGroup">
          {/* Order/CAD/ @additionalInformation */}
          <TextField
            label={t('printOrder.orderAdditionalInfo')}
            defaultValue={this.state.orderState.orderAdditionalInfo}
            onBlur={this.onOrderPropModified('orderAdditionalInfo')}
            margin="dense"
            multiline={true}
            variant="outlined"
            rows={4}
            fullWidth={true}
            inputProps={{ maxLength: 500 }}
          />
        </div>
        <div className="buttonRow">
          <Link to="/downloads">
            <Button color="primary">{t('downloadBasket.backToBasket')}</Button>
          </Link>

          <Button type="submit" color="primary">
            {t('printOrder.next')}
          </Button>
        </div>
      </ValidatorForm>
    );
  }

  renderStep2() {
    const t = this.translate;

    return (
      <div>
        <ValidatorForm onSubmit={this.onAddToOrderClicked}>
          <div className="formGroup">
            <h3>{t('printOrder.orderRows')}</h3>
            <div className="formGroupContent">
              <Grid container={true} spacing={8} justify="space-around">
                <Grid item={true} xs={12} md={8}>
                  <DocumentSelector
                    documents={this.downloadBasketDocuments}
                    onChange={this.onOrderRowDocumentsChange}
                    orderAmount={this.state.orderAmount}
                    folding={this.state.folding}
                  />
                </Grid>
                <Grid item={true} xs={12} md={3}>
                  <div className="multipartInput">
                    <label>{t('printOrder.deliveryDestination')}</label>
                    <div className="multipartInputContent">
                      <TextValidator
                        name="deliverToCompany"
                        label={t('printOrder.deliverToCompany')}
                        defaultValue={this.state.orderRowState.companyName}
                        onBlur={this.onOrderRowPropModified('companyName')}
                        margin="none"
                        className="textInput"
                        required={true}
                        fullWidth={true}
                        inputProps={{ maxLength: 150 }}
                      />
                      <TextField
                        label={t('printOrder.streetAddress')}
                        defaultValue={this.state.orderRowState.address}
                        onBlur={this.onOrderRowPropModified('address')}
                        margin="none"
                        className="textInput"
                        fullWidth={true}
                        inputProps={{ maxLength: 50 }}
                      />
                      <TextField
                        label={t('printOrder.postalCode')}
                        defaultValue={this.state.orderRowState.postalCode}
                        onBlur={this.onOrderRowPropModified('postalCode')}
                        margin="none"
                        className="textInput"
                        fullWidth={true}
                        inputProps={{ maxLength: 20 }}
                      />
                      <TextField
                        label={t('printOrder.city')}
                        defaultValue={this.state.orderRowState.city}
                        onBlur={this.onOrderRowPropModified('city')}
                        margin="none"
                        className="textInput"
                        fullWidth={true}
                        inputProps={{ maxLength: 50 }}
                      />
                      <FormControl fullWidth={true}>
                        <InputLabel>
                          {t('printOrder.deliveryMethod')}
                        </InputLabel>
                        <Select
                          className="textInput"
                          value={this.state.orderRowState.deliveryMethod}
                          onChange={e =>
                            this.onOrderRowDeliveryMethodChanged(
                              e.target.value as DeliveryMethod
                            )
                          }
                        >
                          {this.deliveryMethods.map((deliveryMethod, i) => (
                            <MenuItem value={deliveryMethod} key={i}>
                              <FormattedMessage
                                id={`printOrder.delivery.${deliveryMethod}`}
                              />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  {/* Order/CAD/DistributionInstruction/Delivery/AdditionalInformation */}
                  <TextField
                    label={t('printOrder.additionalInfo')}
                    defaultValue={this.state.orderRowState.additionalInfo}
                    onBlur={this.onOrderRowPropModified('additionalInfo')}
                    margin="dense"
                    className="textInput"
                    fullWidth={true}
                    inputProps={{ maxLength: 255 }}
                  />
                </Grid>
              </Grid>
            </div>

            <h3>{t('printOrder.amountsAndFoldings')}</h3>

            <div className="options">
              <label>
                {t('printOrder.totalAmount')}
                <SmallNumberPicker
                  value={this.state.orderAmount}
                  minValue={1}
                  maxValue={1000}
                  showButtons={true}
                  onChange={newVal => this.onDocumentsAmountChange(newVal)}
                />
              </label>

              <label>
                {t('printOrder.handling')}
                <Select
                  value={this.state.folding}
                  onChange={e =>
                    this.onDocumentsFoldingChange(e.target.value as Folding)
                  }
                >
                  {this.renderFoldingSelectItems()}
                </Select>
              </label>
            </div>

            <Button
              type="submit"
              style={{ marginTop: '10px' }}
              color="primary"
              disabled={this.state.orderRowState.documents.length === 0}
            >
              <AddIcon style={{ marginRight: '12px' }} />
              {t('printOrder.addToOrder')}
            </Button>
          </div>
        </ValidatorForm>

        <Divider style={{ marginTop: '15px', marginBottom: '15px' }} />
        <h5>{t('printOrder.documentsInOrder')}:</h5>
        <div>
          {this.state.orderState.orderRows.length === 0 && (
            <p>{t('printOrder.noDocumentsInOrder')}</p>
          )}
          {this.state.orderState.orderRows.length > 0 && (
            <PrintOrderRowsTable
              showActions={true}
              orderRows={this.state.orderState.orderRows}
              onDelete={this.onDocumentRemovedFromOrder}
            />
          )}
        </div>

        <div className="buttonRow">
          <Button color="primary" onClick={this.prevStep}>
            {t('printOrder.previous')}
          </Button>
          <Button
            color="primary"
            disabled={this.state.orderState.orderRows.length === 0}
            onClick={this.nextStep}
          >
            {t('printOrder.next')}
          </Button>
        </div>
      </div>
    );
  }

  renderStep3() {
    const t = this.translate;
    return (
      <div>
        <PrintOrderSummary order={this.state.orderState} />
        <div className="buttonRow">
          <Button
            color="primary"
            onClick={this.prevStep}
            disabled={this.state.sendingOrder}
          >
            {t('printOrder.previous')}
          </Button>
          <Button
            color="primary"
            onClick={this.sendOrder}
            disabled={this.state.sendingOrder}
          >
            {t('printOrder.sendOrder')}
          </Button>
        </div>
      </div>
    );
  }

  renderStep4() {
    return <p>{this.translate('printOrder.done')}</p>;
  }
}

export default injectIntl(
  documentState.StateConnector(
    applicationState.StateConnector(PrintOrderCheckout)
  )
);
