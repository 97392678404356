import * as React from 'react';
import '../admin.scss';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Fragment } from 'react';
import Divider from '@material-ui/core/Divider';
import { SettingsProps } from '../admin';
import { SpecificPrivilege } from '../../../../../shared/src/model/security';

import Chip from '@material-ui/core/Chip';

function uniqBy<T>(list: T[], identity: (item: T) => string): T[] {
  const res: T[] = [];
  const keys: { [key: string]: boolean } = {};
  list.forEach(item => {
    const id = identity(item);
    if (!keys[id]) {
      keys[id] = true;
      res.push(item);
    }
  });
  return res;
}

interface IPrivilegeDetails extends SettingsProps {
  priv: SpecificPrivilege;
}

interface ICrud {
  priv: SpecificPrivilege;
}

export const CRUD = (props: ICrud) => {
  const specPriv = props.priv;
  return (
    <Fragment>
      {specPriv.c ? <Chip label="C" color="primary" /> : ''}
      {specPriv.r ? <Chip label="R" color="primary" /> : ''}
      {specPriv.u ? <Chip label="U" color="primary" /> : ''}
      {specPriv.d ? <Chip label="D" color="primary" /> : ''}
    </Fragment>
  );
};

export const Action = (props: IPrivilegeDetails) => {
  const specPriv = props.priv;
  if (specPriv.action_id && props.systemModel) {
    const action = props.systemModel.actions
      .filter(act => act.id === specPriv.action_id)
      .pop();
    if (action) {
      return (
        <Fragment>
          {specPriv.action_id ? (
            <Chip label={action.action_name} color="secondary" />
          ) : (
            ''
          )}
        </Fragment>
      );
    }
  }
  return <Fragment />;
};

export const PrivilegeDetails = (props: IPrivilegeDetails) => {
  // {priv.action_id ? <ListItemText primary="Action" /> : ''}
  if (props.systemModel) {
    const specPriv = props.priv;
    console.log(specPriv, props.systemModel.privileges);
    const privilege = props.systemModel.privileges
      .filter(p => specPriv.privilege_id === p.id)
      .pop();
    return (
      <ListItem
        button={true}
        onClick={() => {
          /**/
        }}
      >
        <ListItemText
          primary={privilege ? privilege.description : ''}
          secondary={
            <Fragment>
              <CRUD priv={specPriv} />
              <Action {...props} />
            </Fragment>
          }
        />
      </ListItem>
    );
  } else {
    return (
      <ListItem
        button={true}
        onClick={() => {
          /** */
        }}
      >
        <ListItemText primary="no privilege specified" />
      </ListItem>
    );
  }
};

export const AdminSettingsView = (props: SettingsProps) => {
  if (
    props.systemView.name === 'roles' &&
    props.systemView.role &&
    props.systemView.role_privileges
  ) {
    const privilegeList = uniqBy(
      props.systemView.role_privileges,
      _ => `${_.privilege_id}`
    );
    return (
      <div>
        <h2>{props.systemView.role.role_name}</h2>

        <List component="nav">
          {privilegeList.map(priv => {
            return (
              <Fragment key={priv.id}>
                <Divider light={true} />
                <PrivilegeDetails {...props} priv={priv} />
              </Fragment>
            );
          })}
        </List>
      </div>
    );
  }
  return (
    <div>
      <h2>{props.systemView.name || ''}</h2>
    </div>
  );
};
