import * as React from 'react';
import { ErrorStateContext } from 'state/reducers/ErrorState';
import { NotificationMessage } from 'state/ErrorState';
import * as _ from 'lodash';
import useRouter from 'utils/routerHook';
import { useUnsafeFormatMessage } from 'utils/translateHook';
import Button from '../Common/Button';
// import Clear from '@material-ui/icons/Clear';

function uniqueMessages(msgs: NotificationMessage[]) {
  return _.uniqBy(msgs, item => item.message + item.messageType);
}

export function ErrorBar() {
  const errs = React.useContext(ErrorStateContext);
  const router = useRouter();
  const t = useUnsafeFormatMessage();

  const messages = React.useMemo(() => {
    const list = uniqueMessages(errs.messages);
    return list.map(msg => {
      let txt = msg.message;
      if (txt.substring(0, 3) === '###') {
        txt = t(txt.substring(3));
      }
      return { ...msg, message: txt };
    });
  }, [errs.messages]);

  React.useEffect(() => {
    const id = setInterval(() => {
      errs.filterOldMessages();
    }, 1000);
    return () => clearInterval(id);
  }, []);

  const cleanUp = () => errs.clearMessages();

  React.useEffect(() => {
    // router.history.listen returns unlisten -function
    return router.history.listen(cleanUp);
  }, []); // <- [] means the effect fires only once

  return (
    <>
      <ul>
        {messages.map(e => (
          <li className={`notification ${e.messageType}`} key={e.id}>
            <div className="message">{e.message}</div>
            {/* <Clear className="clear" style={{ fontSize: '20px' }} /> */}
          </li>
        ))}
      </ul>
      {messages.length > 0 && (
        <div className="clearMessages">
          <Button variant="text" onClick={() => errs.clearMessages()}>
            {t('notifications.clearMessages')}
          </Button>
        </div>
      )}
    </>
  );
}
