/***********************************************
 *                                              *
 *     Combined Reducers for main application   *
 *     Generated by ts2redux                    *
 *                                              *
 ***********************************************/
import * as redux from 'redux';
import { AdminStateReducer, IAdminState } from './AdminState';
import { ApplicationStateReducer, IApplicationState } from './ApplicationState';
import { DocumentStateReducer, IDocumentState } from './DocumentState';
import { ErrorStateReducer, IErrorState } from './ErrorState';
export interface IState {
  AdminState: IAdminState;
  ApplicationState: IApplicationState;
  DocumentState: IDocumentState;
  ErrorState: IErrorState;
}
// use reducerObject if you combine reducers manually
// for example when using connected-react-router
export const reducerObject = {
  AdminState: AdminStateReducer,
  ApplicationState: ApplicationStateReducer,
  DocumentState: DocumentStateReducer,
  ErrorState: ErrorStateReducer,
};
export const reducers = redux.combineReducers<IState>(reducerObject);
