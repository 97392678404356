import * as Model from '../../../../shared/src/model/index';
import * as React from 'react';
import * as container from '../../state/reducers/ApplicationState';
import * as schema from '../../../../shared/src/model/projectSchema';

import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';

import AssignmentLate from '@material-ui/icons/AssignmentLate';
import Button from '../Common/Button';
import CloudDownload from '@material-ui/icons/CloudDownload';
import { DocumentService } from '../../services/document/src/frontend/api';
import { DocumentUIMetadata } from '../../utils/metadata';
import Edit from '@material-ui/icons/Edit';
import FindReplaceIcon from '@material-ui/icons/FindReplace';
import NoteAdd from '@material-ui/icons/NoteAdd';
import axios from 'axios';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import FindSimilar from '@material-ui/icons/FindInPage';
const DOC = DocumentService(axios);

interface IRouterProps {
  doc: Model.UIDocument;
  meta: DocumentUIMetadata;
  isUploading?: boolean;
  isEditing?: boolean;
  isDocumentPackage?: boolean;
  onEdit?: () => void;
  onNewRevision?: () => void;
  onDownload?: () => void;
  onAddToBasket?: () => void;
  onAction?: (action: schema.action) => void;
  askForReload?: () => void;
  onReplace?: () => void;
  onFindSimilar?: () => void;
  isReplacing?: boolean;
}

interface IRouterState {
  email: string;
  password: string;
}

export class DocToolbar extends React.Component<
  IRouterProps & InjectedIntlProps & container.IProps,
  IRouterState
> {
  render() {
    const t = this.props.intl;
    const meta = this.props.meta;
    const isAcceptedOrRejected = false; // meta.isAccepted || meta.isRejected;
    const hasReplacement =
      this.props.doc.replacedByDocumentId &&
      this.props.doc.replacedByDocumentId !== -1;

    return (
      <div className="actions">
        {meta.isDocumentPackage &&
        this.props.user &&
        this.props.user.is_superuser ? (
          <Button
            disabled={this.props.isUploading}
            variant="text"
            color="primary"
            onClick={async () => {
              console.log(meta.documentId);

              await DOC.fixDocPackage(meta.documentId);
              if (this.props.askForReload) {
                this.props.askForReload();
              }
              // fixDocPackage
            }}
          >
            <Edit style={{ marginTop: '-3px', marginRight: '8px' }} />
            Korjaa
          </Button>
        ) : null}
        {meta.canEdit && (
          <>
            {!isAcceptedOrRejected &&
            !meta.isContractorDoc &&
            !hasReplacement ? (
              <Button
                disabled={this.props.isUploading || this.props.isReplacing}
                variant="text"
                color="primary"
                onClick={this.props.onEdit}
              >
                <Edit style={{ marginTop: '-3px', marginRight: '8px' }} />
                <FormattedMessage id="buttons.edit" />
              </Button>
            ) : null}
            {!this.props.isDocumentPackage && !hasReplacement && (
              <Button
                disabled={
                  (this.props.isEditing && !this.props.isUploading) ||
                  this.props.isReplacing
                }
                variant="text"
                color="primary"
                onClick={this.props.onNewRevision}
              >
                <NoteAdd style={{ marginTop: '-3px', marginRight: '8px' }} />
                <FormattedMessage id="documentMeta.updateRevision" />
              </Button>
            )}
          </>
        )}
        {meta.canEdit && (
          <>
            <Button
              disabled={this.props.isEditing || this.props.isUploading}
              variant="text"
              color="primary"
              onClick={this.props.onReplace}
            >
              <FindReplaceIcon
                style={{ marginTop: '-3px', marginRight: '8px' }}
              />
              <FormattedMessage
                id={
                  this.props.doc.replacedByDocumentId
                    ? 'buttons.changeReplacement'
                    : 'buttons.defineReplacement'
                }
              />
            </Button>
          </>
        )}
        {meta.isDownloadable ? (
          <Button
            variant="text"
            color="primary"
            onClick={this.props.onDownload}
          >
            <CloudDownload style={{ marginTop: '-3px', marginRight: '8px' }} />
            <FormattedMessage id="documentMeta.downloadLink" />
          </Button>
        ) : null}

        <>
          <Button
            disabled={this.props.isEditing || this.props.isUploading}
            variant="text"
            color="primary"
            onClick={this.props.onFindSimilar}
          >
            <FindSimilar style={{ marginTop: '-3px', marginRight: '8px' }} />
            <FormattedMessage id={'buttons.findSimilarDocs'} />
          </Button>
        </>

        {this.props.onAddToBasket ? (
          <Button
            variant="text"
            color="primary"
            onClick={this.props.onAddToBasket}
          >
            <ShoppingBasketIcon
              style={{ marginTop: '-3px', marginRight: '8px' }}
            />{' '}
            <FormattedMessage id="downloadBasket.addToBasket" />
          </Button>
        ) : null}
        {this.props.appDocumentActions.map((field) => {
          if (this.props.doc.allowedActions.indexOf(field.id) < 0) {
            return null;
          }
          return (
            <Button
              key={field.action_name!}
              variant="text"
              color="primary"
              onClick={() => {
                if (this.props.onAction) {
                  this.props.onAction(field);
                }
              }}
            >
              <AssignmentLate
                style={{ marginTop: '-3px', marginRight: '8px' }}
              />
              {t.formatMessage({
                id: field.action_name!,
              })}
            </Button>
          );
        })}
      </div>
    );
  }
}

export default container.StateConnector(injectIntl(DocToolbar));
