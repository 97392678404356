import React, { useEffect, useState } from 'react';
import { useAdminContext, useAppContext } from 'utils/AppContextProvider';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { TextField } from '@material-ui/core/';
import Button from '../Common/Button';
import { useFormatMessage } from '../../utils/translateHook';
import AdminTools from '../AdminTools';
import * as schema from '../../../../shared/src/model/projectSchema';

interface PhaseProps {}

export default function Phases(props: PhaseProps) {
  const appCtx = useAppContext();
  const adminCtx = useAdminContext();
  const t = useFormatMessage();
  const [phaseId, setPhaseId] = useState<string>('');
  const [phaseName, setPhaseName] = useState<string>('');
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [selectedPhase, setSelectedPhase] = useState<schema.aitem | null>(null);

  useEffect(() => {
    appCtx.getPhases();
  }, []);

  useEffect(() => {
    if (isDisabled) {
      setTimeout(() => {
        setIsDisabled(false);
      }, 2000);
    }
  }, [isDisabled]);

  const saveNewPhase = async () => {
    setIsDisabled(true);

    await adminCtx.createNewPhase({
      phaseName: phaseId.trim(),
      longName: phaseName.trim(),
    });

    setPhaseId('');
    setPhaseName('');
    appCtx.getPhases();
  };

  const nameString = (phase: schema.aitem) => {
    return phase.name_long ? `${phase.name} – ${phase.name_long}` : phase.name;
  };

  const newPhase = (
    <ValidatorForm className="newItem" onSubmit={saveNewPhase}>
      <h3>{t('adminTools.newPhase')}</h3>
      <div>
        <TextField
          style={{ width: '100%' }}
          label={t('adminTools.setPhaseId')}
          value={phaseId}
          onChange={e => setPhaseId(e.target.value)}
        />
      </div>
      <div>
        <TextField
          style={{ width: '100%' }}
          label={t('adminTools.setPhaseName')}
          value={phaseName}
          onChange={e => setPhaseName(e.target.value)}
        />
      </div>
      <Button
        type="submit"
        style={{ margin: '10px 0 0' }}
        disabled={!phaseId.trim() || !phaseName.trim() || isDisabled}
      >
        {t('buttons.save')}
      </Button>
    </ValidatorForm>
  );

  return (
    <AdminTools>
      <div className="grid gridCols3">
        <div className="component master">
          <h2>{t('adminTools.phases')}</h2>
          <div className="content">
            {newPhase}
            <ul>
              {appCtx.phases &&
                appCtx.phases.map(phase => (
                  <li key={phase.id} onClick={() => setSelectedPhase(phase)}>
                    {nameString(phase)}
                  </li>
                ))}
            </ul>
          </div>
        </div>

        {selectedPhase && (
          <div className="detail">
            <div className="component">
              <h2>{nameString(selectedPhase)}</h2>
              <div className="content">
                <dl>
                  <dt>{t('adminTools.contractName')}</dt>
                  <dd>{selectedPhase.name}</dd>
                  <dt>{t('adminTools.contractLongName')}</dt>
                  <dd>{selectedPhase.name_long}</dd>
                </dl>
              </div>
            </div>
          </div>
        )}
      </div>
    </AdminTools>
  );
}
