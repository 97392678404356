import * as React from 'react';
import * as _ from 'lodash';
import { Property, ViewModes, PropertyProps } from 'properties';
import {
  usePropertyCollection,
  getIdFromProps,
  getCollectionParamsKeys,
  getCollectionKeys,
} from 'properties/utils';
import { PropertyCollection, GenericProp } from '../../../../shared/src/model';
import { ClassNames, CollectionFetchParams } from 'properties/batch';
import { Link } from 'react-router-dom';
import { PropertyTableRenderers } from 'properties/PropertyTable';

export interface RowParams {
  row?: PropertyCollection<any>;
}
export interface CellParams {
  cell?: GenericProp<any>;
}

interface ItemParameters<T extends ClassNames> {
  className: T;
  id: number;
  properties?: CollectionFetchParams<T>;
  rowWrapper?: React.FC<RowParams>;
  cellWrapper?: React.FC<CellParams>;
  render?: React.FC<RowParams>;
  editMode?: boolean;
  viewMode?: ViewModes;
  customProperty?: React.FC<PropertyProps<any>>;
}

interface ItemDataParameters<T extends ClassNames> {
  className: T;
  id: number;
  properties?: CollectionFetchParams<T>;
}

interface ItemCellParameters {
  row?: PropertyCollection<any>;
  rowWrapper?: React.FC<RowParams>;
  cellWrapper?: React.FC<CellParams>;
  render?: React.FC<RowParams>;
  editMode?: boolean;
  viewMode?: ViewModes;
  customProperty?: React.FC<PropertyProps<any>>;
}

const defaultRowWrapper: React.FC<RowParams> = (props) => <>{props.children}</>;
const defaultCellWrapper: React.FC<CellParams> = (props) => (
  <>{props.children}</>
);

export const DataView = (
  props: ItemDataParameters<ClassNames> & {
    render: (row: PropertyCollection<any> | undefined) => JSX.Element;
  }
) => {
  const [row] = usePropertyCollection(
    props.id,
    props.className,
    props.properties
  );
  return props.render(row);
};

interface ItemCellViewParameters extends ItemCellParameters {
  renderers?: PropertyTableRenderers;
}
export const ItemCellView = React.memo((props: ItemCellViewParameters) => {
  const RowWrap = props.rowWrapper || defaultRowWrapper;
  const CellWrap = props.cellWrapper || defaultCellWrapper;
  const PropertyComponent = props.customProperty
    ? props.customProperty
    : Property;
  const row = props.row;
  if (!row) {
    return null;
  }
  return (
    <RowWrap row={row} key={'' + row.id}>
      {row
        ? props.renderers
          ? props.renderers.map(({ id, cell: Cell }) => (
              <CellWrap key={id}>
                <Cell row={row} />
              </CellWrap>
            ))
          : getCollectionKeys(row).map((key) => (
              <CellWrap cell={row[key]} key={key + row.id}>
                {props.render ? (
                  <props.render row={row} />
                ) : (
                  <PropertyComponent
                    key={key}
                    data={row[key]}
                    editMode={props.editMode}
                    viewMode={props.viewMode}
                  />
                )}
              </CellWrap>
            ))
        : null}
    </RowWrap>
  );
});

const ItemView = React.memo((props: ItemParameters<ClassNames>) => {
  const RowWrap = props.rowWrapper || defaultRowWrapper;
  const CellWrap = props.cellWrapper || defaultCellWrapper;
  const PropertyComponent = props.customProperty
    ? props.customProperty
    : Property;
  return (
    <DataView
      {...props}
      render={(row) => (
        <RowWrap row={row} key={props.className + props.id}>
          {row
            ? getCollectionParamsKeys(props.properties).map((key) => (
                <CellWrap
                  cell={row[key]}
                  key={props.className + key + props.id}
                >
                  {props.render ? (
                    <props.render row={row} />
                  ) : (
                    <PropertyComponent
                      key={key}
                      data={row[key]}
                      editMode={props.editMode}
                      viewMode={props.viewMode}
                    />
                  )}
                </CellWrap>
              ))
            : null}
        </RowWrap>
      )}
    />
  );
});

export default ItemView;

/*
    <ParentOfType
      className="pitem"
      id={id}
      parentPitemType="Rakennusosa"
    /> 
*/
export const ParentOfType = (props: {
  className: 'pitem';
  id: number;
  parentPitemType: string;
}) => {
  return (
    <ItemView
      className={props.className}
      id={props.id}
      properties={[['parents', 'name', ['pitemtypes', 'name']]]}
      render={(p) => {
        const row = p.row;
        if (!row) return <></>;
        return (
          <span>
            {row['parents']
              .children!.filter(
                (p) =>
                  p['pitemtypes'].children!.filter(
                    (type) => type['name']!.value === props.parentPitemType
                  ).length > 0
              )
              .map((huone) => huone['name'].value)
              .join(', ')}
          </span>
        );
      }}
    />
  );
};

// If id is explicitly specified, we fetch data, otherwise use provided data
interface PitemParentsPropertyProps extends PropertyProps<any> {
  id?: number;
}
export const PitemParentsProperty = (props: PitemParentsPropertyProps) => {
  // Used for rendering ['parents', 'name', 'name_long', ['pitemtypes', 'name']]
  const renderer = (data: GenericProp<any>) => {
    const childrenByPitemtypes = _.groupBy(
      data.children!.filter((child) => getIdFromProps(child) !== data.id),
      (child) =>
        child.pitemtypes.children!.map((type) => type.name!.value).join(', ')
    );
    return (
      <>
        {_.keys(childrenByPitemtypes).map((pitemtypes) => {
          const children = childrenByPitemtypes[pitemtypes];
          return (
            <span key={pitemtypes}>
              <dt>{pitemtypes}</dt>
              <dd>
                {children.map((child, i) => {
                  const id = getIdFromProps(child);
                  const content = _.keys(child)
                    .filter((key) => key !== 'pitemtypes')
                    .map((key) => child[key].value)
                    .join(' / ');
                  return [
                    i > 0 ? ', ' : null,
                    id ? (
                      <Link key={id} to={`/itemsgeneric/${id}`}>
                        {content}
                      </Link>
                    ) : (
                      content
                    ),
                  ];
                })}
              </dd>
            </span>
          );
        })}
      </>
    );
  };

  return !props.data && !props.id ? null : props.id ? (
    <ItemView
      className={'pitem'}
      id={props.id}
      properties={[['parents', 'name', 'name_long', ['pitemtypes', 'name']]]}
      render={(p) => (p.row ? renderer(p.row.parents) : null)}
    />
  ) : (
    renderer(props.data!)
  );
};
