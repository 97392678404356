import { FormattedMessage } from 'react-intl';
import * as React from 'react';

interface IDocumentTextRow {
  id: string;
  value?: string | JSX.Element[];
}

export default function DocumentTextRow(props: IDocumentTextRow) {
  const definitionValue = (definition: any): JSX.Element => {
    if (definition && Array.isArray(definition)) {
      return (
        <dd>
          {definition.map((value, idx) => (
            <div key={idx}>{value}</div>
          ))}
        </dd>
      );
    } else if (definition) {
      return <dd>{definition}</dd>;
    } else {
      return <dd>-</dd>;
    }
  };

  return (
    <>
      <dt>
        <FormattedMessage id={props.id} />
      </dt>

      {definitionValue(props.value)}
    </>
  );
}
