import * as React from 'react';

class Breadcrumbs extends React.Component {
  render() {
    return (
      <>
        <div className="breadcrumbs">
          {this.props.children && Array.isArray(this.props.children) ? (
            this.props.children.map((crumb, index) => (
              <div className="crumb" key={`crumb_${index}`}>
                {crumb}
              </div>
            ))
          ) : (
            <div className="crumb">{this.props.children}</div>
          )}
        </div>
      </>
    );
  }
}

export default Breadcrumbs;
