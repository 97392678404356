import * as _ from 'lodash';
import { InjectedNotistackProps, withSnackbar } from 'notistack';
import * as React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { SnackDetails } from '../../state/helpers/Action';
import * as adminstate from '../../state/reducers/AdminState';
import * as appstate from '../../state/reducers/ApplicationState';
import * as documentstate from '../../state/reducers/DocumentState';
import { NotificationMessageType } from 'state/reducers/ErrorState';
import { NotificationMessage } from 'state/ErrorState';
import { IProps } from 'state/reducers/ErrorState';

const messageQueue: SnackDetails[] = [];

export function errorMessage(msg: string | any) {
  console.log('errorMessage', msg);
  if (typeof msg === 'object') {
    if (msg.response && msg.response.data) {
      if (msg.response.data.message) {
        messageQueue.push({
          variant: 'error',
          message: msg.response.data.message,
        });
      } else {
        if (typeof msg.response.data === 'string') {
          messageQueue.push({
            variant: 'error',
            message: msg.response.data,
          });
        } else {
          messageQueue.push({
            variant: 'error',
            message: String(msg),
          });
        }
      }
    } else {
      messageQueue.push({
        variant: 'error',
        message: String(msg),
      });
    }
    console.log(messageQueue);
    return;
  }
  if (msg.length === 0) {
    return;
  }
  messageQueue.push({
    variant: 'error',
    message: msg,
    time: Date.now(),
  });
}

export function warningMessage(msg: string) {
  if (msg.length === 0) {
    return;
  }
  messageQueue.push({
    variant: 'warning',
    message: msg,
    time: Date.now(),
  });
}

export function successMessage(msg: string) {
  if (msg.length === 0) {
    return;
  }
  messageQueue.push({
    variant: 'success',
    message: msg,
  });
}

export function infoMessage(msg: string) {
  if (msg.length === 0) {
    return;
  }
  messageQueue.push({
    variant: 'info',
    message: msg,
  });
}

interface GlobalNotifierProps
  extends InjectedNotistackProps,
    appstate.IProps,
    adminstate.IProps,
    documentstate.IProps,
    InjectedIntlProps {
  ctx: IProps;
}

type NewNotificationMessage = Omit<NotificationMessage, 'id'>;

/**
 * Shows snackbars from redux state and the messageQueue defined above.
 * There should only be one active instance of this component.
 */
class AbstractGlobalNotifier extends React.Component<GlobalNotifierProps> {
  refreshInterval: any = null;
  messagesToAdd: NewNotificationMessage[] = [];
  getSnacks(): SnackDetails[] {
    const props = this.props;
    const allSnacks: SnackDetails[] = [...messageQueue];

    Object.keys(props.appstatus).forEach(action => {
      const snack = props.appstatus[action].snack;
      if (snack) {
        allSnacks.push(snack);
      }
    });
    Object.keys(props.adminstatus).forEach(action => {
      const snack = props.adminstatus[action].snack;
      if (snack) {
        allSnacks.push(snack);
      }
    });
    Object.keys(props.docstatus).forEach(action => {
      const snack = props.docstatus[action].snack;
      if (snack) {
        allSnacks.push(snack);
      }
    });

    return allSnacks;
  }

  getSnackMessage = (snack: SnackDetails) => {
    const t = this.props.intl;
    return snack.translation
      ? t.formatMessage(
          { id: snack.translation.id },
          { ...snack.translation.values }
        )
      : snack.message || '';
  };

  componentDidMount() {
    this.refreshInterval = setInterval(() => {
      if (messageQueue.length > 0) {
        this.forceUpdate();
      }
    }, 300);
  }

  componentWillUnmount() {
    if (this.refreshInterval !== null) {
      clearInterval(this.refreshInterval);
    }
  }

  componentDidUpdate() {
    // deduplicate messages
    const snacks = _.uniqBy(
      this.getSnacks(),
      item => item.variant + item.message
    );

    if (snacks.length === 0) {
      return;
    }

    // reset the message queue
    messageQueue.length = 0;
    this.props.resetAppSnacks();
    this.props.resetDocSnacks();
    this.props.resetAdminSnacks();

    for (const snack of snacks) {
      if (!snack.translation && !snack.message) continue;

      const newMessage: NewNotificationMessage = {
        message: this.getSnackMessage(snack),
        messageType: snack.variant as NotificationMessageType,
      };

      // Hide 'success' and 'info' messages after short period
      if (['success', 'info'].indexOf(newMessage.messageType) > -1) {
        newMessage.timeout = 5;
      }
      this.props.ctx.addMessage(newMessage);
      // this.props.enqueueSnackbar(message, { variant: snack.variant });
    }
  }

  render() {
    return null;
  }
}

export const GlobalNotifier = documentstate.StateConnector(
  adminstate.StateConnector(
    appstate.StateConnector(injectIntl(withSnackbar(AbstractGlobalNotifier)))
  )
);

interface NotifierProps extends InjectedNotistackProps, InjectedIntlProps {
  snack: SnackDetails;
}

/**
 * Shows a given snackbar when mounted.
 */
class AbstractNotifier extends React.Component<NotifierProps> {
  shouldComponentUpdate() {
    return false;
  }

  getSnackMessage = (snack: SnackDetails) => {
    const t = this.props.intl;
    return snack.translation
      ? t.formatMessage(
          { id: snack.translation.id },
          { ...snack.translation.values }
        )
      : snack.message || '';
  };

  componentDidMount() {
    const message = this.getSnackMessage(this.props.snack);
    this.props.enqueueSnackbar(message, {
      variant: this.props.snack.variant,
    });
  }

  render() {
    return null;
  }
}

export const Notifier = injectIntl(withSnackbar(AbstractNotifier));
