import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { OrderRow, OrderRowDocument } from '../../../../shared/src/model/index';

interface PrintOrderRowsTableProps {
  orderRows: OrderRow[];
  showActions?: boolean;
  onDelete?: (rowKey: number, documentId: number) => void;
}

interface PrintOrderRowsTableState {
  page: number;
  rowsPerPage: number;
}

const styles = {
  tr: {
    height: '30px',
  },
  documentColumn: {
    wordBreak: 'break-all',
  },
  actionColumn: {
    textAlign: 'right',
    width: '40px',
  },
  action: {
    padding: '5px',
  },
} as { [key: string]: React.CSSProperties };

/**
 * Lists documents for all orderRows.
 */
export default class PrintOrderRowsTable extends React.PureComponent<
  PrintOrderRowsTableProps,
  PrintOrderRowsTableState
> {
  state = {
    page: 0,
    rowsPerPage: 20,
  };

  onPageChange = (_: any, page: number) => {
    this.setState({ page });
  };

  onRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ rowsPerPage: parseInt(event.currentTarget.value, 10) });
  };

  onDeleteClick(orderRow: OrderRow, document: OrderRowDocument) {
    if (this.props.onDelete) {
      this.props.onDelete(orderRow.rowKey, document.id);
    }
  }

  public render() {
    const firstRow = this.state.page * this.state.rowsPerPage;
    const lastRow = firstRow + this.state.rowsPerPage - 1;
    let currentRow = 0;

    return (
      <div id="printOrderRowsTable">
        <Table padding="none" id="printOrderDocumentTable">
          <TableHead>
            <TableRow>
              <TableCell>
                <FormattedMessage id="printOrder.document" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="printOrder.colorPrint" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="printOrder.amount" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="printOrder.handling" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="printOrder.additionalInfo" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="printOrder.deliverToCompany" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="printOrder.deliveryDestination" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="printOrder.deliveryMethod" />
              </TableCell>
              {this.props.showActions && <TableCell />}
            </TableRow>
          </TableHead>

          {this.props.orderRows.map(orderRow => {
            return (
              <TableBody key={orderRow.rowKey}>
                {orderRow.documents
                  .filter(() => {
                    const rowInPage =
                      currentRow >= firstRow && currentRow <= lastRow;
                    currentRow++;
                    return rowInPage;
                  })
                  .map(doc => (
                    <TableRow key={doc.id} style={styles.tr}>
                      <TableCell style={styles.documentColumn}>
                        {doc.name}
                      </TableCell>
                      <TableCell align="center">
                        {doc.isColor && (
                          <CheckCircle style={{ color: 'green' }} />
                        )}
                      </TableCell>
                      <TableCell>{doc.amount}</TableCell>
                      <TableCell>
                        <FormattedMessage
                          id={`printOrder.folding.${doc.folding}`}
                        />
                      </TableCell>
                      <TableCell>{orderRow.additionalInfo || '-'}</TableCell>
                      <TableCell>{orderRow.companyName || '-'}</TableCell>
                      <TableCell>
                        {orderRow.address} {orderRow.postalCode} {orderRow.city}
                      </TableCell>
                      <TableCell>
                        <FormattedMessage
                          id={`printOrder.delivery.${orderRow.deliveryMethod}`}
                        />
                      </TableCell>
                      {this.props.showActions && (
                        <TableCell style={styles.actionColumn}>
                          <IconButton
                            style={styles.action}
                            onClick={_ => this.onDeleteClick(orderRow, doc)}
                          >
                            <DeleteIcon color="primary" fontSize="small" />
                          </IconButton>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
              </TableBody>
            );
          })}
        </Table>
        <TablePagination
          component="div"
          count={this.props.orderRows.reduce(
            (acc, row) => acc + row.documents.length,
            0
          )}
          rowsPerPage={this.state.rowsPerPage}
          page={this.state.page}
          SelectProps={{
            native: true,
          }}
          rowsPerPageOptions={[10, 20, 30]}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={this.onPageChange}
          onChangeRowsPerPage={this.onRowsPerPageChange}
        />
      </div>
    );
  }
}
