/**
 * Common utilities for handling item metadata
 */
import * as Model from '../../../shared/src/model';

import * as _ from 'lodash';
import { store } from '../index';
import { documentstate, action } from '../../../shared/src/model/projectSchema';

export function useAppState() {
  return store.getState().ApplicationState;
}

export function useDocumentState() {
  return store.getState().DocumentState;
}

// TODO: change the root type names to be more descriptive
export type RootTypes =
  | 'Työlaji'
  | 'Dokumentin tyyppi'
  | 'Dokumenttityyppi'
  | 'Suunnitteluala';

export const categoriesHaveBeenLoaded = _.memoize(
  () =>
    new Promise(resolve => {
      const state = store.getState();
      if (state.ApplicationState.appDocumentCategories.length > 0) {
        resolve();
        return;
      }
      const unsubscribe = store.subscribe(() => {
        if (
          store.getState().ApplicationState.appDocumentCategories.length > 0
        ) {
          unsubscribe();
          resolve();
        }
      });
    })
);

export function getChildrenOf(
  catList: Model.DocumentCategory[],
  usingList: Model.DocumentCategory[]
): Model.DocumentCategory[] {
  return catList.reduce((acc: Model.DocumentCategory[], cat) => {
    const children = usingList.filter(c => c.parentId === cat.id);
    return acc.concat(children, getChildrenOf(children, usingList));
  }, []);
}

// _.memoize(
export const getTypesUnderRootTypeSync = (
  rootType: RootTypes,
  doc: Model.UIDocument,
  usingList: Model.DocumentCategory[]
): Model.DocumentCategory[] => {
  function getChildrenOf(
    catList: Model.DocumentCategory[]
  ): Model.DocumentCategory[] {
    return catList.reduce((acc: Model.DocumentCategory[], cat) => {
      const children = usingList.filter(c => c.parentId === cat.id);
      return acc.concat(children, getChildrenOf(children));
    }, []);
  }
  return getChildrenOf(
    usingList.filter(cat => cat.name === rootType)
  ).filter(c => (doc.categories ? doc.categories.indexOf(c.id) >= 0 : false));
};

export const getTypesUnderRootType = async (
  rootType: RootTypes,
  doc: Model.UIDocument,
  usingList?: Model.DocumentCategory[]
): Promise<Model.DocumentCategory[]> => {
  await categoriesHaveBeenLoaded();
  const state = store.getState();
  if (usingList) {
    return usingList.filter(cat => {
      return doc.categories && doc.categories.indexOf(cat.id) >= 0;
    });
  }
  function getChildrenOf(
    catList: Model.DocumentCategory[]
  ): Model.DocumentCategory[] {
    return catList.reduce((acc: Model.DocumentCategory[], cat) => {
      const children = state.ApplicationState.appDocumentCategories.filter(
        c => c.parentId === cat.id
      );
      return acc.concat(children, getChildrenOf(children));
    }, []);
  }
  return getChildrenOf(
    state.ApplicationState.appDocumentCategories.filter(
      cat => cat.name === rootType
    )
  ).filter(c => (doc.categories ? doc.categories.indexOf(c.id) >= 0 : false));
};

export const getIdForCategoryName = async (name: string): Promise<number> => {
  await categoriesHaveBeenLoaded();
  const state = store.getState();
  const list = state.ApplicationState.appDocumentCategories.filter(
    cat => cat.name === name
  );
  if (list.length === 0) return 0;
  return list[0].id;
};

export const getNameForCategoryId = async (id: number): Promise<string> => {
  await categoriesHaveBeenLoaded();
  const state = store.getState();
  const list = state.ApplicationState.appDocumentCategories.filter(
    cat => cat.id === id
  );
  if (list.length === 0) return '';
  return list[0].name;
};

export const findAction = async (id: number): Promise<action> => {
  await categoriesHaveBeenLoaded();
  const state = store.getState();
  const list = state.ApplicationState.appDocumentActions.filter(
    cat => cat.id === id
  );
  return list[0];
};

export interface DocumentUIMetadata {
  documentId: number;
  selectedCategories: Model.DocumentCategory[];
  selectedDisciplines: Model.DocumentCategory[];
  contractorDocumentTypes: Model.DocumentCategory[];
  contractorWorkTypes: Model.DocumentCategory[];
  isContractorDoc: boolean;
  currentState: documentstate | undefined;
  isAccepted: boolean;
  isRejected: boolean;
  canEdit: boolean;
  isDownloadable: boolean;
  pitemTree: Model.DBPitem[];
  primaryTypeId: number;
  isDocumentPackage: boolean;
}

export const collectDocumentMetadata = async (
  doc: Model.UIDocument,
  options?: {
    worktypes?: Model.DocumentCategory[];
  }
): Promise<DocumentUIMetadata> => {
  await categoriesHaveBeenLoaded();
  const state = store.getState();
  const workTypes = await getTypesUnderRootType(
    'Työlaji',
    doc,
    options && options.worktypes
  );
  const currentState = state.ApplicationState.appDocumentStates
    .filter(s => s.id === doc.workflowStateId)
    .pop();
  if (!currentState) {
    console.log(state.ApplicationState);
    throw new Error('State is missing ' + doc.workflowStateId);
  }
  const docPackageType = state.ApplicationState.appDocumentCategories
    .filter(t => t.name === 'URAK_DP')
    .pop();

  if (!docPackageType) {
    throw new Error('Document package type is missing');
  }
  if (!doc.categories) {
    throw new Error('Categories is missing');
  }
  const urakDocTypeId = await getIdForCategoryName('URAK_DOC');
  const isDocumentPackage = doc.categories.indexOf(docPackageType.id) >= 0;
  let pitemTree: Model.DBPitem[] = [];
  const primaryTypeId = doc.pitemtypes
    ? doc.pitemtypes[doc.pitemtypes.length - 1]
    : 0;

  if (doc.pitemTree) {
    const keys = Object.keys(doc.pitemTree);
    if (keys.length > 0) {
      pitemTree = doc.pitemTree[keys[0]];
    }
  }
  const isContractorDoc = doc.categories
    ? doc.categories.indexOf(urakDocTypeId) >= 0
    : false;

  return {
    documentId: doc.id,
    selectedCategories: await getTypesUnderRootType('Dokumenttityyppi', doc),
    selectedDisciplines: await getTypesUnderRootType('Suunnitteluala', doc),
    contractorDocumentTypes: await getTypesUnderRootType(
      'Dokumentin tyyppi',
      doc
    ),
    contractorWorkTypes: workTypes,
    isContractorDoc,
    currentState,
    isAccepted: currentState ? currentState.name === 'ACCEPTED' : false,
    isRejected: currentState ? currentState.name === 'REJECTED' : false,
    canEdit: !!_.get(doc.privileges, 'canUpdate'),
    isDownloadable: !isDocumentPackage && (doc.canBeDownloaded || false),
    pitemTree,
    primaryTypeId,
    isDocumentPackage,
  };
};

export const getFilenameExtension = (name: string): string => {
  const parts = name.split('.');
  if (parts.length > 1) {
    return parts[parts.length - 1];
  }
  return '';
};
export const removeFilenameExtension = (name: string): string => {
  const parts = name.split('.');
  if (parts.length === 1) {
    return name;
  }
  parts.pop(); // remove last part of the file name
  return parts.join('.');
};

export interface UploadOptions {
  categoryIds: number[];
  pitemIds: number[];
  aitemIds: number[];
}

export const convertFileToUIDocument = (
  row: File,
  options: UploadOptions
): Model.UIDocument => {
  return {
    id: 0,
    name: removeFilenameExtension(row.name || 'Unknown File'),
    createdByUserId: 0,
    workflowStateId: 0,
    fileName: row.name || '',
    fileExtension: getFilenameExtension(row.name),
    created: '',
    modified: '',
    categories: options.categoryIds,
    contractIds: options.aitemIds,
    contractStr: '',
    buildingElement: '',
    pItems: options.pitemIds.map(id => ({
      id,
      name: '',
      longName: '',
    })),
    aItems: options.aitemIds.map(id => ({
      id,
      name: '',
    })),
    revision: '',
    description: '',
    dwgName: '',
    startPole: undefined,
    endPole: undefined,
    downloadUrl: '',
    ObjVer: undefined,
    allowedActions: [],
    versionNumber: 1,
    displayData: {
      updateEvenIfSame: true,
    },
  };
};

export interface NewDocumentOptions {
  name: string;
  categoryIds: number[];
  parentId?: number;
  contractIds?: number[];
  contracts: Model.UIDocumentAItem[];
  children?: Model.UIDocument[];
}

export const createNewUIDocument = (
  options: NewDocumentOptions
): Model.UIDocument => {
  return {
    id: 0,
    name: removeFilenameExtension(options.name),
    createdByUserId: 0,
    workflowStateId: 0,
    fileName: options.name || '',
    fileExtension: getFilenameExtension(options.name),
    created: '',
    modified: '',
    categories: options.categoryIds,
    contractIds: options.contractIds ? options.contractIds : [],
    parentId: options.parentId,
    contractStr: '',
    buildingElement: '',
    pItems: [],
    aItems: options.contracts,
    revision: '',
    description: '',
    dwgName: '',
    startPole: undefined,
    endPole: undefined,
    downloadUrl: '',
    ObjVer: undefined,
    allowedActions: [],
    versionNumber: 1,
    displayData: {
      updateEvenIfSame: true,
    },
    children: options.children ? options.children : [],
  };
};
