import * as React from 'react';
import MainLayout from '../../layout/MainLayout';
import { FormattedMessage } from 'react-intl';

import Help from '../Common/Help';
export class ErrorBoundary extends React.Component<
  any,
  { hasError: boolean; error?: Error; errorInfo?: React.ErrorInfo }
> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // TODO: Log errors to error reporting service
    console.error(error);
    console.log(errorInfo);
    this.setState({ errorInfo });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <MainLayout>
          <div className="breadcrumbs">
            <div className="crumb">
              <span>
                <FormattedMessage id="error.title" />
              </span>
            </div>
          </div>
          <div className="grid gridCols1">
            <Help
              purpose="errorBoundary"
              content={'error.instructions'}
              error={this.state.error}
              errorInfo={this.state.errorInfo}
            />
          </div>
        </MainLayout>
      );
    }
    return this.props.children;
  }
}
