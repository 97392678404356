/*************************************************************************************
 *                                                                                    *
 *   Redux Reducers and React Context API Provider/Consumer for state DocumentState   *
 *   Generated by ts2redux from Source file ../DocumentState.ts                       *
 *                                                                                    *
 *************************************************************************************/

import * as immer from 'immer';
import { connect } from 'react-redux';
import { IState } from './index';
import * as React from 'react';
import axios from 'axios';
import { APIService } from '../../services/api/src/frontend/api';
import { DocumentService } from '../../services/document/src/frontend/api';
import * as Model from '../../../../shared/src/model';
import {
  ActionStatusDetail,
  SnackDetails,
  TranslationDetails,
} from '../helpers/Action';
import { push } from 'connected-react-router';
import * as _ from 'lodash';
import { arraymove } from '../../utils';

const API = APIService(axios);
const DOCAPI = DocumentService(axios);

export interface DocActionStatus {
  searchRequest: ActionStatusDetail;
  uploadDesignerDocuments: ActionStatusDetail;
  uploadDocumentCollection: ActionStatusDetail;
  fetchDocument: ActionStatusDetail;
  setDocumentState: ActionStatusDetail;
  fetchDocumentHistory: ActionStatusDetail;
  linkToDwgDocs: ActionStatusDetail;
  uploadNewDocumentRevision: ActionStatusDetail;
  sendPrintOrder: ActionStatusDetail;
  getDocumentComments: ActionStatusDetail;
  addDocumentComment: ActionStatusDetail;
  uploadDocuments: ActionStatusDetail;
  uploadTargetAttachment: ActionStatusDetail;
  togglePitemTrackedStatus: ActionStatusDetail;
}
type StatusCodes = keyof DocActionStatus;
const createAction = (name: StatusCodes, loading = true) => ({
  name,
  loading,
  loaded: false,
  snack: undefined,
});

export interface DocCollectionUpload {
  contractId?: number;
  disciplineId?: number;
  files?: File[];
  documentCollection?: File;
  uuid?: string;
  onlyMetadata?: boolean;
}

export type IUserSearches = Model.UserSearches;

let uuidCnt = 1;

/**
 * @redux true
 */
export class DocumentState {
  redirectToId: number = 0;

  networkError = false;
  metadata?: Model.UIDocMetadata;

  docstatus: DocActionStatus = {
    searchRequest: createAction('searchRequest', false),
    uploadDesignerDocuments: createAction('uploadDesignerDocuments', false),
    uploadDocumentCollection: createAction('uploadDocumentCollection', false),
    fetchDocument: createAction('fetchDocument', false),
    fetchDocumentHistory: createAction('fetchDocumentHistory', false),
    setDocumentState: createAction('setDocumentState', false),
    linkToDwgDocs: createAction('linkToDwgDocs', false),
    uploadNewDocumentRevision: createAction('uploadNewDocumentRevision', false),
    sendPrintOrder: createAction('sendPrintOrder', false),
    getDocumentComments: createAction('getDocumentComments', false),
    addDocumentComment: createAction('addDocumentComment', false),
    uploadDocuments: createAction('uploadDocuments', false),
    uploadTargetAttachment: createAction('uploadTargetAttachment', false),
    togglePitemTrackedStatus: createAction('togglePitemTrackedStatus', false),
  };

  documents: { [id: number]: Model.UIDocument } = {};
  documentCollection: Model.DocumentCollectionResults | undefined = undefined;

  searchResultCnt = 0;
  searchResults: Model.UIDocument[] = [];
  searchResultsTree: Model.UIDocument[] = [];

  uploadResults: Model.UIDocument[] = [];
  uploadDocumentAmount: number = 0;

  searches: IUserSearches = {
    version: 1,
    savedSearches: [],
  };
  activeSearch: Model.SearchRequest = {};
  activeSearchOrdering: Model.SearchOrder[] = [];

  activeDocument?: Model.UIDocument;
  activeDocumentHistory?: Model.DocStateHistoryRow[];
  activeDocumentComments?: Model.UIDocumentComment[];

  isLoadingWaitingForReview = false;
  waitingForReview: Model.UIDocument[] = [];

  isLoadingSearchResults = false;

  downloadBasket: Model.UIDocument[] = JSON.parse(
    window.localStorage.getItem('download_basket') || '[]'
  );

  pitemTargetting: Model.UIDocument[] = [];

  documentCollectionUpload: DocCollectionUpload = {};

  searchRequestKeys: { [key in Model.SearchColumn]: string } = {
    name: 'documentMeta.name',
    fileName: 'documentMeta.fileName',
    area: 'documentMeta.area',
    aItems: 'documentMeta.contract',
    pItems: 'documentMeta.building',
    revision: 'documentMeta.revision',
    created: 'documentMeta.created',
    dwgName: 'documentMeta.dwg',
    workflowStateName: 'documentMeta.workflowState',
    startPole: 'documentMeta.startPole',
    endPole: 'documentMeta.endPole',
    fileExtension: 'documentMeta.fileExtension',
    fileSize: 'documentMeta.fileSize',
    categories: 'documentMeta.categories',
    versionNumber: 'documentMeta.version',
    description: 'documentMeta.description',
    modified: 'documentMeta.modified',
    createdByUser: 'documentMeta.createdByUser',
    pitemTree: 'documentMeta.location',
    floor: 'documentMeta.floor',
    room: 'documentMeta.room',
    building: 'documentMeta.building',
    target: 'documentMeta.target',
    targetName: 'documentMeta.targetName',
    targetcode: 'documentMeta.targetcode',
    modifiedByUser: 'documentMeta.modifiedByUser',
    pitemTypes: 'documentMeta.pitemTypes',
    roomId: 'documentMeta.roomId',
    roomName: 'documentMeta.roomName',
    pitemTypeName: 'documentMeta.pitemTypeName',
    pole: 'documentMeta.pole',
    polerange: 'documentMeta.polerange',
    locationLongname: 'documentMeta.locationLongname',
    location: 'documentMeta.location',
    designDiscipline: 'documentMeta.designDiscipline',
    designerDocumentType: 'documentMeta.designerDocumentType',
    contractorDocumentType: 'documentMeta.contractorDocumentType',
    contractorWorktype: 'documentMeta.contractorWorkType',
    isTracked: 'documentMeta.isTracked',
    workAitems: 'documentMeta.workAitems',
    inspectionDocTarget: 'documentMeta.inspectionDocTarget',
  };

  noOpCnt = 0;

  private createUUID(): string {
    return `${uuidCnt++}`;
  }

  noOp() {
    this.noOpCnt++;
  }

  setDocCollection(data: DocCollectionUpload) {
    this.documentCollectionUpload = data;
    this.documentCollectionUpload.uuid = this.createUUID();
  }

  setDocCollectionDocumentTypeId(id: number) {
    this.documentCollectionUpload.disciplineId = id;
    this.documentCollectionUpload.uuid = this.createUUID();
  }

  addToPitemTargets(list: Model.UIDocument[]) {
    const keys: {
      [key: number]: Model.UIDocument;
    } = this.pitemTargetting.reduce((data, item) => {
      return { ...data, [item.id]: item };
    }, {});
    const newItems = list.filter(
      (item) => typeof keys[item.id] === 'undefined'
    );
    this.pitemTargetting = [...this.pitemTargetting, ...newItems];
  }
  removeFromPitemTargets(removedItem: Model.UIDocument) {
    this.pitemTargetting = this.pitemTargetting.filter(
      (item) => item.id !== removedItem.id
    );
  }

  clearPItemTargets() {
    this.pitemTargetting = [];
  }

  async findSimilarDocuments(doc_id: number) {
    return (await DOCAPI.findSimilarDocuments(doc_id)) as Model.UIDocument[];
  }

  async getPitemCntForPitemType(pitem_type_id: number) {
    return await DOCAPI.getPitemTypePitemCnt(pitem_type_id);
  }

  async removePitemType(pitem_type_id: number) {
    return await DOCAPI.deletePItemType(pitem_type_id);
  }

  /**
   * @dispatch true
   */
  async DocumentStateDispatcher(action: any) {
    // this is a magic method so that ts2redux knows to generate custom dispatcher
    // function which can be used to dispatch any redux actions inside the current model
  }

  async removeDocumentCategory(id: number) {
    await DOCAPI.deleteCategory(id);
    await this.getMetadata();
  }

  async removePItemType(id: number) {
    await DOCAPI.deletePItemType(id);
    await this.getMetadata();
  }

  async addOrUpdatePItemType(opions: Partial<Model.DocumentCategory>) {
    return await DOCAPI.addOrUpdatePitemType(opions);
  }

  async getDocCnt(id: number) {
    return await DOCAPI.getDocumentCnt(id);
  }

  async addOrUpdateCategory(opions: Partial<Model.DocumentCategory>) {
    return await DOCAPI.addOrUpdateCategory(opions);
  }

  async loadWaitingForReview() {
    if (this.isLoadingWaitingForReview) {
      return;
    }
    this.isLoadingWaitingForReview = true;
    const docs = await DOCAPI.waitingForReview();
    this.waitingForReview = docs;
    this.isLoadingWaitingForReview = false;
  }

  setStatus(params: {
    action: ActionStatusDetail;
    snack?: SnackDetails;
    finished?: boolean;
  }) {
    this.docstatus[params.action.name] = {
      ...this.docstatus[params.action.name],
      loading: params.finished ? false : true,
      loaded: false,
      snack: params.snack,
    };
  }

  setLoading(action: ActionStatusDetail) {
    this.docstatus[action.name] = {
      ...this.docstatus[action.name],
      loading: true,
      loaded: false,
    };
  }

  setSuccess(params: {
    action: ActionStatusDetail;
    translation?: TranslationDetails;
  }) {
    const { action, ...snackParams } = params;
    const snack =
      snackParams && ({ variant: 'success', ...snackParams } as SnackDetails);
    this.docstatus[action.name] = {
      ...this.docstatus[action.name],
      loading: false,
      loaded: true,
      snack,
    };
  }

  setError(params: {
    action: ActionStatusDetail;
    message?: string;
    translation?: TranslationDetails;
  }) {
    const { action, ...snackParams } = params;
    const snack = { variant: 'error', ...snackParams } as SnackDetails;
    this.setStatus({ action, snack });
    this.docstatus[params.action.name] = {
      ...this.docstatus[params.action.name],
      loading: false,
      loaded: false,
      snack,
    };
  }

  resetDocSnacks() {
    Object.keys(this.docstatus).map((key) => {
      this.docstatus[key].snack = undefined;
    });
  }

  setRedirect(id: number) {
    this.redirectToId = id;
  }

  updateActiveDocument(newDoc: Model.UIDocument) {
    this.activeDocument = newDoc;

    // Update the document in search results too
    // if the user browses back to search results
    // after updating the document
    let index = -1;
    this.searchResults.forEach((d, i) => {
      if (d.id === newDoc.id) {
        index = i;
      }
    });
    if (index >= 0) {
      this.searchResults[index] = newDoc;
    }
  }

  setActiveSearch(search: Model.SearchRequest) {
    this.activeSearch = search;
  }

  saveDocument(doc: Model.UIDocument) {
    this.documents[doc.id] = doc;
    // TODO: make this smarter
    let index = -1;
    this.searchResults.forEach((d, i) => {
      if (d.id === doc.id) {
        index = i;
      }
    });
    if (index >= 0) {
      this.searchResults[index] = doc;
    }
    this.activeDocument = doc;
  }

  addToSearchResults(doc: Model.UIDocument) {
    this.documents[doc.id] = doc;
    const index = _.findIndex(this.searchResults, { id: doc.id });
    if (index > 0) {
      this.searchResults.splice(index, 1, doc);
    } else {
      this.searchResults.push(doc);
    }
  }

  revertDocument(doc: Model.UIDocument) {
    this.activeDocument = this.documents[doc.id];
  }

  async cleanRedirectURL() {
    this.redirectToId = 0;
  }

  async saveActiveDocument() {
    if (this.activeDocument) {
      // this.saveDocument(this.activeDocument);
      const origId = this.activeDocument.id;
      const nextDoc = await DOCAPI.updateDocument(this.activeDocument);
      // NOTE: this is a test for the redirect
      if (nextDoc.id !== origId) {
        this.DocumentStateDispatcher(push('/documents/' + nextDoc.id));
      }
      this.saveDocument(nextDoc);
      this.fetchDocumentHistory(nextDoc.id);
    }
  }

  async getMetadata() {
    try {
      const meta = await API.getDocumentMetadata();

      this.metadata = {
        workflowStates: meta.workflowStates,
        documentCategories: meta.documentCategories.map((cat) => ({
          id: cat.id,
          name: cat.category_name as string,
          parentId: cat.upper_category_id,
        })),
        pitemTypes: meta.pitemTypes,
        aitemTypes: meta.aitemTypes,
        workflows: meta.workflows,
      };
    } catch (e) {
      this.networkError = true;
    }
  }

  resetDoc() {
    this.activeDocument = undefined;
    this.activeDocumentHistory = [];
  }

  resetDocHistory() {
    this.activeDocumentHistory = [];
  }

  async fetchDocument(id: number) {
    const action = this.docstatus.fetchDocument;
    if (action.loading) {
      return;
    }
    if (this.activeDocument) {
      if (this.activeDocument.id !== id) {
        this.resetDoc();
      }
    }
    try {
      this.setLoading(action);
      const doc = await DOCAPI.fetchDocument(id);
      this.saveDocument(doc);
      this.activeDocument = doc;
      this.setSuccess({ action });
    } catch (e) {
      this.networkError = true;
      this.setError({ action, translation: { id: 'error.documentNotFound' } });
    }
  }

  async fetchDocumentHistory(id: number) {
    const action = this.docstatus.fetchDocumentHistory;
    if (action.loading) {
      return;
    }
    if (this.activeDocument) {
      if (this.activeDocument.id !== id) {
        this.resetDocHistory();
      }
    }
    try {
      this.setLoading(action);
      this.activeDocumentHistory = await DOCAPI.fetchDocumentHistory(id);
      this.setSuccess({ action });
    } catch (e) {
      this.networkError = true;
      this.setError({ action, message: e.message });
    }
  }

  async repeatSearchWithOrder(order: Model.SearchOrder) {
    const newSearch = {
      ...this.activeSearch,
      orderByFields: [order],
    };
    this.setActiveSearch(newSearch);
    this.documentSearch(newSearch);
  }

  setSearchResults(list: Model.UIDocument[]) {
    const parentDocs: Model.UIDocument[] = [];
    const byId: { [key: number]: Model.UIDocument } = {};
    for (const item of list) {
      byId[item.id] = item;
    }
    for (const item of list) {
      if (item.parentId) {
        const parent = byId[item.parentId];
        if (parent) {
          if (!parent.children) {
            parent.children = [];
          }
          if (parent && parent.children) {
            parent.children.push(item);
          }
        }
      } else {
        parentDocs.push(item);
      }
    }
    this.searchResultsTree = parentDocs;
    this.searchResults = list;
  }

  async loadMoreDocuments(params: Model.SearchRequest) {
    try {
      const reqData = { ...params };
      reqData.cursor = {
        offset: this.searchResults.length,
        limit: 400,
      };
      reqData.name = '';
      const res = await DOCAPI.documentSearchRequest(reqData);
      this.setSearchResults([...this.searchResults, ...res.rows]);
    } catch (e) {}
  }

  async documentSearch(params: Model.SearchRequest) {
    const action = this.docstatus.searchRequest;
    if (action.loading) {
      return;
    }
    try {
      this.searchResultCnt = -1;
      // remove name parameter, because it has a conflict with freeText
      const reqData = { ...params };
      reqData.name = '';
      // this.setLoading(action);
      this.setStatus({ action });
      const res = await DOCAPI.documentSearchRequest(reqData);

      // activeSearchResultCnt = -1;
      this.setSearchResults(res.rows);
      this.setSuccess({ action });
      this.searchResultCnt = res.estimatedCnt;
    } catch (e) {
      this.setError({ action, translation: { id: 'error.tooManyResults' } });
    }
  }

  moveSearchUp(name: string) {
    if (!this.searches.savedSearches) {
      return;
    }
    const searchItem = this.searches.savedSearches
      .filter((item) => item.name === name)
      .pop();
    if (!searchItem) return;
    const idx = this.searches.savedSearches.indexOf(searchItem);
    if (idx >= this.searches.savedSearches.length - 1) {
      return;
    }
    arraymove(this.searches.savedSearches, idx, idx + 1);
  }

  moveSearchDown(name: string) {
    if (!this.searches.savedSearches) {
      return;
    }
    const searchItem = this.searches.savedSearches
      .filter((item) => item.name === name)
      .pop();
    if (!searchItem) return;
    const idx = this.searches.savedSearches.indexOf(searchItem);
    if (idx < 1) {
      return;
    }
    arraymove(this.searches.savedSearches, idx, idx - 1);
  }

  initSearch(searches: Model.UserSearches) {
    if (!searches.savedSearches) {
      return;
    }
    this.searches = searches;
  }

  async getUserSearches() {
    this.initSearch(await API.getUserSearches());
  }

  async saveUserSearches() {
    if (this.searches) {
      await API.saveUserSearches(this.searches);
    }
  }

  async addAndSaveUserSearches(newSearch: Model.SearchRequest) {
    if (this.searches) {
      this.addSearchRequest(newSearch);
      await API.saveUserSearches(this.searches);
    }
  }

  async removeAndSaveUserSearches(removedSearch: string) {
    if (this.searches) {
      this.removeSearchRequest(removedSearch);
      await API.saveUserSearches(this.searches);
    }
  }

  addSearchRequest(newSearch: Model.SearchRequest) {
    if (!this.searches) {
      return;
    }

    if (!this.searches.savedSearches) {
      this.searches.savedSearches = [];
    }

    // For now the search name must be unique so test that we don't
    // try to save search with name that already exist
    let index = 0;
    const matchingSearches = this.searches.savedSearches.filter(
      (search, idx) => {
        if (search.name === newSearch.name) {
          index = idx;
          return true;
        }
        return false;
      }
    );

    if (matchingSearches.length === 0) {
      this.searches.savedSearches.push(newSearch);
    } else {
      // replace
      this.searches.savedSearches[index] = newSearch;
    }
  }

  removeSearchRequest(removedSearch: string) {
    if (!this.searches) {
      return;
    }

    if (!this.searches.savedSearches) {
      this.searches.savedSearches = [];
    }

    const searches = this.searches.savedSearches.filter((search) => {
      return search.name !== removedSearch;
    });
    this.searches.savedSearches = searches;
  }

  async uploadDesignerDocuments(data: Model.IUploadState) {
    const action = this.docstatus.uploadDesignerDocuments;
    if (!data.files) {
      return;
    }
    try {
      this.setStatus({ action });
      // Clear upload results list
      this.setUploadResults([]);
      this.DocumentStateDispatcher(push('/upload/documents'));
      // In the end...
      // this.DocumentStateDispatcher(push('/upload/documents'));
      const { files, documentCollectionFile, ...restProps } = data;
      this.setDocumentUploadAmount(files.length);

      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      const uploadedFiles = (await Promise.all(
        data.files.map(async (file) => {
          const formData = new FormData();
          formData.append('info', JSON.stringify(restProps));
          formData.append('file', file);
          const result = (
            await axios.post('/docs/v1/upload/doc', formData, config)
          ).data as Model.UIDocument[];
          this.addUploadResults(result);
          return result[0];
        })
      )) as Model.UIDocument[];

      const notUpdated: string[] = [];
      const successFiles: Model.UIDocument[] = [];
      for (const doc of uploadedFiles) {
        if (doc.displayData && doc.displayData.fileWasNotUpdated) {
          notUpdated.push(doc.fileName);
        } else {
          successFiles.push(doc);
        }
      }
      if (successFiles.length > 0) {
        this.setSuccess({
          action,
          translation: {
            id: 'upload.uploadDesignerDocumentsSuccess',
            values: { amount: successFiles.length },
          },
        });
      }
      if (notUpdated.length > 0) {
        this.setStatus({
          action,
          finished: true,
          snack: {
            variant: 'warning',
            time: 50 * 1000,
            translation: {
              id: 'upload.uploadDesignerDocumentsExists',
              values: { filenames: notUpdated.join(', ') },
            },
          },
        });
      }

      if (successFiles.length > 0) {
        this.setSuccess({ action });
        this.setUploadResults(successFiles);
      }
    } catch (e) {
      this.setError({
        action,
        translation: { id: 'error.someDocumentsFailedToUpload' },
      });
    }
  }

  setDocumentUploadAmount(amount: number) {
    this.uploadDocumentAmount = amount;
  }

  setUploadResults(results: Model.UIDocument[]) {
    this.uploadResults = results;
  }

  addUploadResults(results: Model.UIDocument[]) {
    this.uploadResults = this.uploadResults.concat(results);
  }

  async uploadDocumentCollection(file: File) {
    const action = this.docstatus.uploadDocumentCollection;
    if (!file) {
      return;
    }
    this.setLoading(action);
    const formData = new FormData();
    formData.append('file', file);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };

    try {
      const res = (
        await axios.post('/docs/v1/upload/documentcollection', formData, config)
      ).data as Model.DocumentCollectionResults;
      this.setSuccess({
        action,
        translation: { id: 'upload.collectionSuccessful' },
      });
      this.setDocumentCollection(res);
    } catch (e) {
      this.setError({
        action,
        translation: {
          id: 'error.collectionUploadFailed',
          values: { name: file.name },
        },
      });
    }
  }

  setDocumentCollection(collection: Model.DocumentCollectionResults) {
    this.documentCollection = collection;
  }

  async uploadNewDocumentRevision(file: File) {
    const action = this.docstatus.uploadNewDocumentRevision;
    if (!file) return;

    this.setLoading(action);

    const formData = new FormData();
    formData.append('info', JSON.stringify(this.activeDocument));
    formData.append('file', file);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };

    try {
      const result = (
        await axios.post('/docs/v1/upload/nextrevision', formData, config)
      ).data as Model.UIDocument[];
      const newDoc = result[0];
      this.DocumentStateDispatcher(push('/documents/' + newDoc.id));
      // this.setRedirect(newDoc.id);
      this.saveDocument(newDoc);

      this.setSuccess({ action });
    } catch (e) {
      this.setError({ action, message: e.message });
      console.error(e);
    }
  }

  async uploadTargetAttachment(data: {
    files: File[];
    categoryId: number;
    subject: string;
  }) {
    const action = this.docstatus.uploadTargetAttachment;
    const { activeDocument } = this;

    if (!data.files || !activeDocument) return;

    const { files, categoryId, subject } = data;

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    this.setStatus({ action });
    await Promise.all(
      files.map(async (file) => {
        try {
          const info = _.merge({}, activeDocument, {
            name: file.name,
            categories: [categoryId],
            description: subject,
            parentId: activeDocument.id,
            dwgName: '',
          });

          const formData = new FormData();
          formData.append('info', JSON.stringify(info));
          formData.append('file', file);
          const result = await axios.post(
            '/docs/v1/upload/doc2',
            formData,
            config
          );

          this.addToSearchResults(result.data[0]);

          this.setStatus({
            action,
            snack: {
              variant: 'success',
              translation: {
                id: 'inspectionDocuments.documentUploaded',
                values: { name: file.name },
              },
            },
          });
        } catch (error) {
          this.setError({
            action,
            message: error.message,
            translation: {
              id: 'inspectionDocuments.documentNotUploaded',
              values: { name: file.name },
            },
          });
        }
      })
    );

    this.setSuccess({ action });
  }

  async sendPrintOrder(data: Model.PrintOrder) {
    const action = this.docstatus.sendPrintOrder;
    try {
      this.setLoading(action);
      await DOCAPI.documentPrintOrder(data);
      this.setSuccess({ action });
    } catch (e) {
      console.log(e.response.data);
      this.setError({ action, message: e.message });
    }
  }

  async linkToDwgDocs(data: Model.UpdateDWG) {
    const action = this.docstatus.linkToDwgDocs;
    try {
      this.setLoading(action);
      await DOCAPI.linkToDwgDocs(data);
      this.setSuccess({ action });
    } catch (e) {
      this.setError({ action, message: e.message });
    }
  }

  resetDocState() {
    this.searchResults = [];
    this.activeDocument = undefined;
    this.activeSearch = {};
    this.documents = {};
    this.documentCollection = undefined;
    this.waitingForReview = [];
    this.downloadBasket = [];
    this.searches = {
      version: 1,
      savedSearches: [],
    };
  }
  clearBasket() {
    this.downloadBasket = [];
    window.localStorage.setItem(
      'download_basket',
      JSON.stringify(this.downloadBasket)
    );
  }
  removeFromBasket(item: Model.UIDocument) {
    this.downloadBasket = this.downloadBasket.filter(
      (row) => row.id !== item.id
    );
  }

  addToBasket(list: Model.UIDocument[]) {
    const keys: {
      [key: number]: Model.UIDocument;
    } = this.downloadBasket.reduce((data, item) => {
      return { ...data, [item.id]: item };
    }, {});

    const oldItemHash: { [key: string]: boolean } = {};
    this.downloadBasket.forEach((doc) => {
      if (doc.fileHash) {
        oldItemHash[doc.fileHash] = true;
      }
    });
    const newItems = list.filter((item) => {
      const canBeAdded =
        typeof keys[item.id] === 'undefined' &&
        !oldItemHash[item.fileHash || ''];
      oldItemHash[item.fileHash || ''] = true;
      return canBeAdded;
    });
    this.downloadBasket = [...this.downloadBasket, ...newItems];
    window.localStorage.setItem(
      'download_basket',
      JSON.stringify(this.downloadBasket)
    );
  }

  async setDocumentState(data: {
    id: number;
    stateValue: number;
    reason: string;
  }) {
    const action = this.docstatus.setDocumentState;
    if (!document) return;
    this.setLoading(action);
    try {
      await DOCAPI.changeDocState({
        docid: data.id,
        newStateId: data.stateValue,
        reasonForRejection: data.reason,
      });
      if (this.activeDocument) this.saveDocument(this.activeDocument);
      await this.fetchDocument(data.id);
      this.fetchDocumentHistory(data.id);
      this.setSuccess({ action });
    } catch (e) {
      this.setError({ action, message: e.message });
    }
  }

  async getDocumentComments(documentId: number) {
    const action = this.docstatus.getDocumentComments;
    try {
      this.setStatus({ action });

      const comments = await DOCAPI.getDocumentComments(documentId);
      // Mark fetched comments as seen if not seen
      const documentComments = comments[documentId];
      if (documentComments && documentComments.length > 0) {
        await Promise.all(
          documentComments.map(async (comment) => {
            if (!comment.seen_at && comment.id) {
              await DOCAPI.documentCommentSeen(comment.id);
            }
          })
        );
      }

      this.setActiveDocumentComments(comments);
      this.setSuccess({ action });
    } catch (error) {
      this.setError({ action, message: error.message });
    }
  }

  setActiveDocumentComments(comments: {
    [id: number]: Model.UIDocumentComment[];
  }) {
    if (this.activeDocument) {
      this.activeDocumentComments = comments[this.activeDocument.id];
    }
  }

  async addDocumentComment(data: Model.DocumentComment) {
    const action = this.docstatus.addDocumentComment;
    try {
      this.setStatus({ action });
      await DOCAPI.addDocumentComment(data);
      this.setSuccess({ action });
    } catch (error) {
      this.setError({ action, message: error.message });
    }
  }

  async createInspectionDocument(request: { meta: any; data: any }) {
    DOCAPI.createInspectionDocument(request);
  }

  async togglePitemTrackedStatus(oldPitem: Model.UIDocument) {
    const action = this.docstatus.togglePitemTrackedStatus;
    this.setStatus({ action });

    // Clone the old pitem to trigger props update
    const pitem = { ...oldPitem };
    let index = -1;

    this.searchResults.forEach((d, i) => {
      if (d.id === pitem.id) {
        index = i;
      }
    });

    if (index >= 0) {
      pitem.isTracked = !pitem.isTracked;

      this.searchResults[index] = pitem;

      try {
        await API.setTrackedStatus({
          id: pitem.id,
          isTracked: pitem.isTracked,
        });
        this.setSuccess({ action });
      } catch (e) {
        this.setError({ action });
        this.searchResults[index] = oldPitem;
      }
    } else {
      this.setError({ action });
    }
  }
}

export interface IContainerPropsMethods {
  noOp: () => any;
  setDocCollection: (data: DocCollectionUpload) => any;
  setDocCollectionDocumentTypeId: (id: number) => any;
  addToPitemTargets: (list: Model.UIDocument[]) => any;
  removeFromPitemTargets: (removedItem: Model.UIDocument) => any;
  clearPItemTargets: () => any;
  findSimilarDocuments: (doc_id: number) => any;
  getPitemCntForPitemType: (pitem_type_id: number) => any;
  removePitemType: (pitem_type_id: number) => any;
  DocumentStateDispatcher: (action: any) => any;
  removeDocumentCategory: (id: number) => any;
  removePItemType: (id: number) => any;
  addOrUpdatePItemType: (opions: Partial<Model.DocumentCategory>) => any;
  getDocCnt: (id: number) => any;
  addOrUpdateCategory: (opions: Partial<Model.DocumentCategory>) => any;
  loadWaitingForReview: () => any;
  setStatus: (params: {
    action: ActionStatusDetail;
    snack?: SnackDetails;
    finished?: boolean;
  }) => any;
  setLoading: (action: ActionStatusDetail) => any;
  setSuccess: (params: {
    action: ActionStatusDetail;
    translation?: TranslationDetails;
  }) => any;
  setError: (params: {
    action: ActionStatusDetail;
    message?: string;
    translation?: TranslationDetails;
  }) => any;
  resetDocSnacks: () => any;
  setRedirect: (id: number) => any;
  updateActiveDocument: (newDoc: Model.UIDocument) => any;
  setActiveSearch: (search: Model.SearchRequest) => any;
  saveDocument: (doc: Model.UIDocument) => any;
  addToSearchResults: (doc: Model.UIDocument) => any;
  revertDocument: (doc: Model.UIDocument) => any;
  cleanRedirectURL: () => any;
  saveActiveDocument: () => any;
  getMetadata: () => any;
  resetDoc: () => any;
  resetDocHistory: () => any;
  fetchDocument: (id: number) => any;
  fetchDocumentHistory: (id: number) => any;
  repeatSearchWithOrder: (order: Model.SearchOrder) => any;
  setSearchResults: (list: Model.UIDocument[]) => any;
  loadMoreDocuments: (params: Model.SearchRequest) => any;
  documentSearch: (params: Model.SearchRequest) => any;
  moveSearchUp: (name: string) => any;
  moveSearchDown: (name: string) => any;
  initSearch: (searches: Model.UserSearches) => any;
  getUserSearches: () => any;
  saveUserSearches: () => any;
  addAndSaveUserSearches: (newSearch: Model.SearchRequest) => any;
  removeAndSaveUserSearches: (removedSearch: string) => any;
  addSearchRequest: (newSearch: Model.SearchRequest) => any;
  removeSearchRequest: (removedSearch: string) => any;
  uploadDesignerDocuments: (data: Model.IUploadState) => any;
  setDocumentUploadAmount: (amount: number) => any;
  setUploadResults: (results: Model.UIDocument[]) => any;
  addUploadResults: (results: Model.UIDocument[]) => any;
  uploadDocumentCollection: (file: File) => any;
  setDocumentCollection: (collection: Model.DocumentCollectionResults) => any;
  uploadNewDocumentRevision: (file: File) => any;
  uploadTargetAttachment: (data: {
    files: File[];
    categoryId: number;
    subject: string;
  }) => any;
  sendPrintOrder: (data: Model.PrintOrder) => any;
  linkToDwgDocs: (data: Model.UpdateDWG) => any;
  resetDocState: () => any;
  clearBasket: () => any;
  removeFromBasket: (item: Model.UIDocument) => any;
  addToBasket: (list: Model.UIDocument[]) => any;
  setDocumentState: (data: {
    id: number;
    stateValue: number;
    reason: string;
  }) => any;
  getDocumentComments: (documentId: number) => any;
  setActiveDocumentComments: (comments: {
    [id: number]: Model.UIDocumentComment[];
  }) => any;
  addDocumentComment: (data: Model.DocumentComment) => any;
  createInspectionDocument: (request: { meta: any; data: any }) => any;
  togglePitemTrackedStatus: (oldPitem: Model.UIDocument) => any;
}
export interface IDocumentState {
  redirectToId: number;
  networkError: boolean;
  metadata?: Model.UIDocMetadata;
  docstatus: DocActionStatus;
  documents: {
    [id: number]: Model.UIDocument;
  };
  documentCollection: Model.DocumentCollectionResults | undefined;
  searchResultCnt: number;
  searchResults: Model.UIDocument[];
  searchResultsTree: Model.UIDocument[];
  uploadResults: Model.UIDocument[];
  uploadDocumentAmount: number;
  searches: IUserSearches;
  activeSearch: Model.SearchRequest;
  activeSearchOrdering: Model.SearchOrder[];
  activeDocument?: Model.UIDocument;
  activeDocumentHistory?: Model.DocStateHistoryRow[];
  activeDocumentComments?: Model.UIDocumentComment[];
  isLoadingWaitingForReview: boolean;
  waitingForReview: Model.UIDocument[];
  isLoadingSearchResults: boolean;
  downloadBasket: Model.UIDocument[];
  pitemTargetting: Model.UIDocument[];
  documentCollectionUpload: DocCollectionUpload;
  searchRequestKeys: {
    [key in Model.SearchColumn]: string;
  };
  noOpCnt: number;
}

export type IContainerPropsState = IDocumentState;
export interface IProps extends IContainerPropsState, IContainerPropsMethods {}

function pick<T, K extends keyof T>(o: T, ...props: K[]) {
  return props.reduce((a, e) => ({ ...a, [e]: o[e] }), {}) as Pick<T, K>;
}
export function mapStateToPropsWithKeys<K extends keyof IContainerPropsState>(
  state: IState,
  keys: K[]
): Pick<IContainerPropsState, K> {
  return pick(state.DocumentState as IContainerPropsState, ...keys);
}

export const mapStateToProps = (state: IState): IContainerPropsState => {
  return {
    redirectToId: state.DocumentState.redirectToId,
    networkError: state.DocumentState.networkError,
    metadata: state.DocumentState.metadata,
    docstatus: state.DocumentState.docstatus,
    documents: state.DocumentState.documents,
    documentCollection: state.DocumentState.documentCollection,
    searchResultCnt: state.DocumentState.searchResultCnt,
    searchResults: state.DocumentState.searchResults,
    searchResultsTree: state.DocumentState.searchResultsTree,
    uploadResults: state.DocumentState.uploadResults,
    uploadDocumentAmount: state.DocumentState.uploadDocumentAmount,
    searches: state.DocumentState.searches,
    activeSearch: state.DocumentState.activeSearch,
    activeSearchOrdering: state.DocumentState.activeSearchOrdering,
    activeDocument: state.DocumentState.activeDocument,
    activeDocumentHistory: state.DocumentState.activeDocumentHistory,
    activeDocumentComments: state.DocumentState.activeDocumentComments,
    isLoadingWaitingForReview: state.DocumentState.isLoadingWaitingForReview,
    waitingForReview: state.DocumentState.waitingForReview,
    isLoadingSearchResults: state.DocumentState.isLoadingSearchResults,
    downloadBasket: state.DocumentState.downloadBasket,
    pitemTargetting: state.DocumentState.pitemTargetting,
    documentCollectionUpload: state.DocumentState.documentCollectionUpload,
    searchRequestKeys: state.DocumentState.searchRequestKeys,
    noOpCnt: state.DocumentState.noOpCnt,
  };
};

function mapDispatchToPropsWithKeys<K extends keyof IContainerPropsMethods>(
  dispatch: any,
  keys: K[]
): Pick<IContainerPropsMethods, K> {
  return pick(mapDispatchToProps(dispatch), ...keys);
}

export const mapDispatchToProps = (dispatch: any): IContainerPropsMethods => {
  return {
    noOp: () => {
      return dispatch(RDocumentState.noOp());
    },
    setDocCollection: (data: DocCollectionUpload) => {
      return dispatch(RDocumentState.setDocCollection(data));
    },
    setDocCollectionDocumentTypeId: (id: number) => {
      return dispatch(RDocumentState.setDocCollectionDocumentTypeId(id));
    },
    addToPitemTargets: (list: Model.UIDocument[]) => {
      return dispatch(RDocumentState.addToPitemTargets(list));
    },
    removeFromPitemTargets: (removedItem: Model.UIDocument) => {
      return dispatch(RDocumentState.removeFromPitemTargets(removedItem));
    },
    clearPItemTargets: () => {
      return dispatch(RDocumentState.clearPItemTargets());
    },
    findSimilarDocuments: (doc_id: number) => {
      return dispatch(RDocumentState.findSimilarDocuments(doc_id));
    },
    getPitemCntForPitemType: (pitem_type_id: number) => {
      return dispatch(RDocumentState.getPitemCntForPitemType(pitem_type_id));
    },
    removePitemType: (pitem_type_id: number) => {
      return dispatch(RDocumentState.removePitemType(pitem_type_id));
    },

    DocumentStateDispatcher: (action: any) => {
      return dispatch(action);
    },
    removeDocumentCategory: (id: number) => {
      return dispatch(RDocumentState.removeDocumentCategory(id));
    },
    removePItemType: (id: number) => {
      return dispatch(RDocumentState.removePItemType(id));
    },
    addOrUpdatePItemType: (opions: Partial<Model.DocumentCategory>) => {
      return dispatch(RDocumentState.addOrUpdatePItemType(opions));
    },
    getDocCnt: (id: number) => {
      return dispatch(RDocumentState.getDocCnt(id));
    },
    addOrUpdateCategory: (opions: Partial<Model.DocumentCategory>) => {
      return dispatch(RDocumentState.addOrUpdateCategory(opions));
    },
    loadWaitingForReview: () => {
      return dispatch(RDocumentState.loadWaitingForReview());
    },
    setStatus: (params: {
      action: ActionStatusDetail;
      snack?: SnackDetails;
      finished?: boolean;
    }) => {
      return dispatch(RDocumentState.setStatus(params));
    },
    setLoading: (action: ActionStatusDetail) => {
      return dispatch(RDocumentState.setLoading(action));
    },
    setSuccess: (params: {
      action: ActionStatusDetail;
      translation?: TranslationDetails;
    }) => {
      return dispatch(RDocumentState.setSuccess(params));
    },
    setError: (params: {
      action: ActionStatusDetail;
      message?: string;
      translation?: TranslationDetails;
    }) => {
      return dispatch(RDocumentState.setError(params));
    },
    resetDocSnacks: () => {
      return dispatch(RDocumentState.resetDocSnacks());
    },
    setRedirect: (id: number) => {
      return dispatch(RDocumentState.setRedirect(id));
    },
    updateActiveDocument: (newDoc: Model.UIDocument) => {
      return dispatch(RDocumentState.updateActiveDocument(newDoc));
    },
    setActiveSearch: (search: Model.SearchRequest) => {
      return dispatch(RDocumentState.setActiveSearch(search));
    },
    saveDocument: (doc: Model.UIDocument) => {
      return dispatch(RDocumentState.saveDocument(doc));
    },
    addToSearchResults: (doc: Model.UIDocument) => {
      return dispatch(RDocumentState.addToSearchResults(doc));
    },
    revertDocument: (doc: Model.UIDocument) => {
      return dispatch(RDocumentState.revertDocument(doc));
    },
    cleanRedirectURL: () => {
      return dispatch(RDocumentState.cleanRedirectURL());
    },
    saveActiveDocument: () => {
      return dispatch(RDocumentState.saveActiveDocument());
    },
    getMetadata: () => {
      return dispatch(RDocumentState.getMetadata());
    },
    resetDoc: () => {
      return dispatch(RDocumentState.resetDoc());
    },
    resetDocHistory: () => {
      return dispatch(RDocumentState.resetDocHistory());
    },
    fetchDocument: (id: number) => {
      return dispatch(RDocumentState.fetchDocument(id));
    },
    fetchDocumentHistory: (id: number) => {
      return dispatch(RDocumentState.fetchDocumentHistory(id));
    },
    repeatSearchWithOrder: (order: Model.SearchOrder) => {
      return dispatch(RDocumentState.repeatSearchWithOrder(order));
    },
    setSearchResults: (list: Model.UIDocument[]) => {
      return dispatch(RDocumentState.setSearchResults(list));
    },
    loadMoreDocuments: (params: Model.SearchRequest) => {
      return dispatch(RDocumentState.loadMoreDocuments(params));
    },
    documentSearch: (params: Model.SearchRequest) => {
      return dispatch(RDocumentState.documentSearch(params));
    },
    moveSearchUp: (name: string) => {
      return dispatch(RDocumentState.moveSearchUp(name));
    },
    moveSearchDown: (name: string) => {
      return dispatch(RDocumentState.moveSearchDown(name));
    },
    initSearch: (searches: Model.UserSearches) => {
      return dispatch(RDocumentState.initSearch(searches));
    },
    getUserSearches: () => {
      return dispatch(RDocumentState.getUserSearches());
    },
    saveUserSearches: () => {
      return dispatch(RDocumentState.saveUserSearches());
    },
    addAndSaveUserSearches: (newSearch: Model.SearchRequest) => {
      return dispatch(RDocumentState.addAndSaveUserSearches(newSearch));
    },
    removeAndSaveUserSearches: (removedSearch: string) => {
      return dispatch(RDocumentState.removeAndSaveUserSearches(removedSearch));
    },
    addSearchRequest: (newSearch: Model.SearchRequest) => {
      return dispatch(RDocumentState.addSearchRequest(newSearch));
    },
    removeSearchRequest: (removedSearch: string) => {
      return dispatch(RDocumentState.removeSearchRequest(removedSearch));
    },
    uploadDesignerDocuments: (data: Model.IUploadState) => {
      return dispatch(RDocumentState.uploadDesignerDocuments(data));
    },
    setDocumentUploadAmount: (amount: number) => {
      return dispatch(RDocumentState.setDocumentUploadAmount(amount));
    },
    setUploadResults: (results: Model.UIDocument[]) => {
      return dispatch(RDocumentState.setUploadResults(results));
    },
    addUploadResults: (results: Model.UIDocument[]) => {
      return dispatch(RDocumentState.addUploadResults(results));
    },
    uploadDocumentCollection: (file: File) => {
      return dispatch(RDocumentState.uploadDocumentCollection(file));
    },
    setDocumentCollection: (collection: Model.DocumentCollectionResults) => {
      return dispatch(RDocumentState.setDocumentCollection(collection));
    },
    uploadNewDocumentRevision: (file: File) => {
      return dispatch(RDocumentState.uploadNewDocumentRevision(file));
    },
    uploadTargetAttachment: (data: {
      files: File[];
      categoryId: number;
      subject: string;
    }) => {
      return dispatch(RDocumentState.uploadTargetAttachment(data));
    },
    sendPrintOrder: (data: Model.PrintOrder) => {
      return dispatch(RDocumentState.sendPrintOrder(data));
    },
    linkToDwgDocs: (data: Model.UpdateDWG) => {
      return dispatch(RDocumentState.linkToDwgDocs(data));
    },
    resetDocState: () => {
      return dispatch(RDocumentState.resetDocState());
    },
    clearBasket: () => {
      return dispatch(RDocumentState.clearBasket());
    },
    removeFromBasket: (item: Model.UIDocument) => {
      return dispatch(RDocumentState.removeFromBasket(item));
    },
    addToBasket: (list: Model.UIDocument[]) => {
      return dispatch(RDocumentState.addToBasket(list));
    },
    setDocumentState: (data: {
      id: number;
      stateValue: number;
      reason: string;
    }) => {
      return dispatch(RDocumentState.setDocumentState(data));
    },
    getDocumentComments: (documentId: number) => {
      return dispatch(RDocumentState.getDocumentComments(documentId));
    },
    setActiveDocumentComments: (comments: {
      [id: number]: Model.UIDocumentComment[];
    }) => {
      return dispatch(RDocumentState.setActiveDocumentComments(comments));
    },
    addDocumentComment: (data: Model.DocumentComment) => {
      return dispatch(RDocumentState.addDocumentComment(data));
    },
    createInspectionDocument: (request: { meta: any; data: any }) => {
      return dispatch(RDocumentState.createInspectionDocument(request));
    },
    togglePitemTrackedStatus: (oldPitem: Model.UIDocument) => {
      return dispatch(RDocumentState.togglePitemTrackedStatus(oldPitem));
    },
  };
};

export function ConnectKeys<
  K extends keyof IDocumentState,
  J extends keyof IContainerPropsMethods
>(keys: K[], methods: J[]) {
  return connect(
    (state: IState) => mapStateToPropsWithKeys(state, keys),
    (dispatch: any) => mapDispatchToPropsWithKeys(dispatch, methods)
  );
}

export const StateConnector = connect(mapStateToProps, mapDispatchToProps);

const initDocumentState = () => {
  const o = new DocumentState();
  return {
    redirectToId: o.redirectToId,
    networkError: o.networkError,
    metadata: o.metadata,
    docstatus: o.docstatus,
    documents: o.documents,
    documentCollection: o.documentCollection,
    searchResultCnt: o.searchResultCnt,
    searchResults: o.searchResults,
    searchResultsTree: o.searchResultsTree,
    uploadResults: o.uploadResults,
    uploadDocumentAmount: o.uploadDocumentAmount,
    searches: o.searches,
    activeSearch: o.activeSearch,
    activeSearchOrdering: o.activeSearchOrdering,
    activeDocument: o.activeDocument,
    activeDocumentHistory: o.activeDocumentHistory,
    activeDocumentComments: o.activeDocumentComments,
    isLoadingWaitingForReview: o.isLoadingWaitingForReview,
    waitingForReview: o.waitingForReview,
    isLoadingSearchResults: o.isLoadingSearchResults,
    downloadBasket: o.downloadBasket,
    pitemTargetting: o.pitemTargetting,
    documentCollectionUpload: o.documentCollectionUpload,
    searchRequestKeys: o.searchRequestKeys,
    noOpCnt: o.noOpCnt,
  };
};
const initWithMethodsDocumentState = () => {
  const o = new DocumentState();
  return {
    redirectToId: o.redirectToId,
    networkError: o.networkError,
    metadata: o.metadata,
    docstatus: o.docstatus,
    documents: o.documents,
    documentCollection: o.documentCollection,
    searchResultCnt: o.searchResultCnt,
    searchResults: o.searchResults,
    searchResultsTree: o.searchResultsTree,
    uploadResults: o.uploadResults,
    uploadDocumentAmount: o.uploadDocumentAmount,
    searches: o.searches,
    activeSearch: o.activeSearch,
    activeSearchOrdering: o.activeSearchOrdering,
    activeDocument: o.activeDocument,
    activeDocumentHistory: o.activeDocumentHistory,
    activeDocumentComments: o.activeDocumentComments,
    isLoadingWaitingForReview: o.isLoadingWaitingForReview,
    waitingForReview: o.waitingForReview,
    isLoadingSearchResults: o.isLoadingSearchResults,
    downloadBasket: o.downloadBasket,
    pitemTargetting: o.pitemTargetting,
    documentCollectionUpload: o.documentCollectionUpload,
    searchRequestKeys: o.searchRequestKeys,
    noOpCnt: o.noOpCnt,
    noOp: o.noOp,
    setDocCollection: o.setDocCollection,
    setDocCollectionDocumentTypeId: o.setDocCollectionDocumentTypeId,
    addToPitemTargets: o.addToPitemTargets,
    removeFromPitemTargets: o.removeFromPitemTargets,
    clearPItemTargets: o.clearPItemTargets,
    findSimilarDocuments: o.findSimilarDocuments,
    getPitemCntForPitemType: o.getPitemCntForPitemType,
    removePitemType: o.removePitemType,
    DocumentStateDispatcher: o.DocumentStateDispatcher,
    removeDocumentCategory: o.removeDocumentCategory,
    removePItemType: o.removePItemType,
    addOrUpdatePItemType: o.addOrUpdatePItemType,
    getDocCnt: o.getDocCnt,
    addOrUpdateCategory: o.addOrUpdateCategory,
    loadWaitingForReview: o.loadWaitingForReview,
    setStatus: o.setStatus,
    setLoading: o.setLoading,
    setSuccess: o.setSuccess,
    setError: o.setError,
    resetDocSnacks: o.resetDocSnacks,
    setRedirect: o.setRedirect,
    updateActiveDocument: o.updateActiveDocument,
    setActiveSearch: o.setActiveSearch,
    saveDocument: o.saveDocument,
    addToSearchResults: o.addToSearchResults,
    revertDocument: o.revertDocument,
    cleanRedirectURL: o.cleanRedirectURL,
    saveActiveDocument: o.saveActiveDocument,
    getMetadata: o.getMetadata,
    resetDoc: o.resetDoc,
    resetDocHistory: o.resetDocHistory,
    fetchDocument: o.fetchDocument,
    fetchDocumentHistory: o.fetchDocumentHistory,
    repeatSearchWithOrder: o.repeatSearchWithOrder,
    setSearchResults: o.setSearchResults,
    loadMoreDocuments: o.loadMoreDocuments,
    documentSearch: o.documentSearch,
    moveSearchUp: o.moveSearchUp,
    moveSearchDown: o.moveSearchDown,
    initSearch: o.initSearch,
    getUserSearches: o.getUserSearches,
    saveUserSearches: o.saveUserSearches,
    addAndSaveUserSearches: o.addAndSaveUserSearches,
    removeAndSaveUserSearches: o.removeAndSaveUserSearches,
    addSearchRequest: o.addSearchRequest,
    removeSearchRequest: o.removeSearchRequest,
    uploadDesignerDocuments: o.uploadDesignerDocuments,
    setDocumentUploadAmount: o.setDocumentUploadAmount,
    setUploadResults: o.setUploadResults,
    addUploadResults: o.addUploadResults,
    uploadDocumentCollection: o.uploadDocumentCollection,
    setDocumentCollection: o.setDocumentCollection,
    uploadNewDocumentRevision: o.uploadNewDocumentRevision,
    uploadTargetAttachment: o.uploadTargetAttachment,
    sendPrintOrder: o.sendPrintOrder,
    linkToDwgDocs: o.linkToDwgDocs,
    resetDocState: o.resetDocState,
    clearBasket: o.clearBasket,
    removeFromBasket: o.removeFromBasket,
    addToBasket: o.addToBasket,
    setDocumentState: o.setDocumentState,
    getDocumentComments: o.getDocumentComments,
    setActiveDocumentComments: o.setActiveDocumentComments,
    addDocumentComment: o.addDocumentComment,
    createInspectionDocument: o.createInspectionDocument,
    togglePitemTrackedStatus: o.togglePitemTrackedStatus,
  };
};

/**
 * @generated true
 */
export class RDocumentState {
  private _state?: IDocumentState;
  private _dispatch?: <A extends {}, T extends {}>(action: A) => T;
  private _getState?: () => any;
  constructor(
    state?: IDocumentState,
    dispatch?: (action: any) => any,
    getState?: () => any
  ) {
    this._state = state;
    this._dispatch = dispatch;
    this._getState = getState;
  }
  get redirectToId(): number {
    if (this._getState) {
      return this._getState().DocumentState.redirectToId;
    } else {
      if (this._state) {
        return this._state.redirectToId;
      }
    }
    throw new Error('Invalid State in DocumentState_redirectToId');
  }
  set redirectToId(value: number) {
    if (this._state && typeof value !== 'undefined') {
      this._state.redirectToId = value;
    } else {
      // dispatch change for item redirectToId
      if (this._dispatch) {
        this._dispatch({
          type: DocumentStateEnums.DocumentState_redirectToId,
          payload: value,
        });
      }
    }
  }
  get networkError(): boolean {
    if (this._getState) {
      return this._getState().DocumentState.networkError;
    } else {
      if (this._state) {
        return this._state.networkError;
      }
    }
    throw new Error('Invalid State in DocumentState_networkError');
  }
  set networkError(value: boolean) {
    if (this._state && typeof value !== 'undefined') {
      this._state.networkError = value;
    } else {
      // dispatch change for item networkError
      if (this._dispatch) {
        this._dispatch({
          type: DocumentStateEnums.DocumentState_networkError,
          payload: value,
        });
      }
    }
  }
  get metadata(): Model.UIDocMetadata | undefined {
    if (this._getState) {
      return this._getState().DocumentState.metadata;
    } else {
      if (this._state) {
        return this._state.metadata;
      }
    }
    return undefined;
  }
  set metadata(value: Model.UIDocMetadata | undefined) {
    if (this._state && typeof value !== 'undefined') {
      this._state.metadata = value;
    } else {
      // dispatch change for item metadata
      if (this._dispatch) {
        this._dispatch({
          type: DocumentStateEnums.DocumentState_metadata,
          payload: value,
        });
      }
    }
  }
  get docstatus(): DocActionStatus {
    if (this._getState) {
      return this._getState().DocumentState.docstatus;
    } else {
      if (this._state) {
        return this._state.docstatus;
      }
    }
    throw new Error('Invalid State in DocumentState_docstatus');
  }
  set docstatus(value: DocActionStatus) {
    if (this._state && typeof value !== 'undefined') {
      this._state.docstatus = value;
    } else {
      // dispatch change for item docstatus
      if (this._dispatch) {
        this._dispatch({
          type: DocumentStateEnums.DocumentState_docstatus,
          payload: value,
        });
      }
    }
  }
  get documents(): {
    [id: number]: Model.UIDocument;
  } {
    if (this._getState) {
      return this._getState().DocumentState.documents;
    } else {
      if (this._state) {
        return this._state.documents;
      }
    }
    throw new Error('Invalid State in DocumentState_documents');
  }
  set documents(value: { [id: number]: Model.UIDocument }) {
    if (this._state && typeof value !== 'undefined') {
      this._state.documents = value;
    } else {
      // dispatch change for item documents
      if (this._dispatch) {
        this._dispatch({
          type: DocumentStateEnums.DocumentState_documents,
          payload: value,
        });
      }
    }
  }
  get documentCollection(): Model.DocumentCollectionResults | undefined {
    if (this._getState) {
      return this._getState().DocumentState.documentCollection;
    } else {
      if (this._state) {
        return this._state.documentCollection;
      }
    }
    throw new Error('Invalid State in DocumentState_documentCollection');
  }
  set documentCollection(value: Model.DocumentCollectionResults | undefined) {
    if (this._state && typeof value !== 'undefined') {
      this._state.documentCollection = value;
    } else {
      // dispatch change for item documentCollection
      if (this._dispatch) {
        this._dispatch({
          type: DocumentStateEnums.DocumentState_documentCollection,
          payload: value,
        });
      }
    }
  }
  get searchResultCnt(): number {
    if (this._getState) {
      return this._getState().DocumentState.searchResultCnt;
    } else {
      if (this._state) {
        return this._state.searchResultCnt;
      }
    }
    throw new Error('Invalid State in DocumentState_searchResultCnt');
  }
  set searchResultCnt(value: number) {
    if (this._state && typeof value !== 'undefined') {
      this._state.searchResultCnt = value;
    } else {
      // dispatch change for item searchResultCnt
      if (this._dispatch) {
        this._dispatch({
          type: DocumentStateEnums.DocumentState_searchResultCnt,
          payload: value,
        });
      }
    }
  }
  get searchResults(): Model.UIDocument[] {
    if (this._getState) {
      return this._getState().DocumentState.searchResults;
    } else {
      if (this._state) {
        return this._state.searchResults;
      }
    }
    throw new Error('Invalid State in DocumentState_searchResults');
  }
  set searchResults(value: Model.UIDocument[]) {
    if (this._state && typeof value !== 'undefined') {
      this._state.searchResults = value;
    } else {
      // dispatch change for item searchResults
      if (this._dispatch) {
        this._dispatch({
          type: DocumentStateEnums.DocumentState_searchResults,
          payload: value,
        });
      }
    }
  }
  get searchResultsTree(): Model.UIDocument[] {
    if (this._getState) {
      return this._getState().DocumentState.searchResultsTree;
    } else {
      if (this._state) {
        return this._state.searchResultsTree;
      }
    }
    throw new Error('Invalid State in DocumentState_searchResultsTree');
  }
  set searchResultsTree(value: Model.UIDocument[]) {
    if (this._state && typeof value !== 'undefined') {
      this._state.searchResultsTree = value;
    } else {
      // dispatch change for item searchResultsTree
      if (this._dispatch) {
        this._dispatch({
          type: DocumentStateEnums.DocumentState_searchResultsTree,
          payload: value,
        });
      }
    }
  }
  get uploadResults(): Model.UIDocument[] {
    if (this._getState) {
      return this._getState().DocumentState.uploadResults;
    } else {
      if (this._state) {
        return this._state.uploadResults;
      }
    }
    throw new Error('Invalid State in DocumentState_uploadResults');
  }
  set uploadResults(value: Model.UIDocument[]) {
    if (this._state && typeof value !== 'undefined') {
      this._state.uploadResults = value;
    } else {
      // dispatch change for item uploadResults
      if (this._dispatch) {
        this._dispatch({
          type: DocumentStateEnums.DocumentState_uploadResults,
          payload: value,
        });
      }
    }
  }
  get uploadDocumentAmount(): number {
    if (this._getState) {
      return this._getState().DocumentState.uploadDocumentAmount;
    } else {
      if (this._state) {
        return this._state.uploadDocumentAmount;
      }
    }
    throw new Error('Invalid State in DocumentState_uploadDocumentAmount');
  }
  set uploadDocumentAmount(value: number) {
    if (this._state && typeof value !== 'undefined') {
      this._state.uploadDocumentAmount = value;
    } else {
      // dispatch change for item uploadDocumentAmount
      if (this._dispatch) {
        this._dispatch({
          type: DocumentStateEnums.DocumentState_uploadDocumentAmount,
          payload: value,
        });
      }
    }
  }
  get searches(): IUserSearches {
    if (this._getState) {
      return this._getState().DocumentState.searches;
    } else {
      if (this._state) {
        return this._state.searches;
      }
    }
    throw new Error('Invalid State in DocumentState_searches');
  }
  set searches(value: IUserSearches) {
    if (this._state && typeof value !== 'undefined') {
      this._state.searches = value;
    } else {
      // dispatch change for item searches
      if (this._dispatch) {
        this._dispatch({
          type: DocumentStateEnums.DocumentState_searches,
          payload: value,
        });
      }
    }
  }
  get activeSearch(): Model.SearchRequest {
    if (this._getState) {
      return this._getState().DocumentState.activeSearch;
    } else {
      if (this._state) {
        return this._state.activeSearch;
      }
    }
    throw new Error('Invalid State in DocumentState_activeSearch');
  }
  set activeSearch(value: Model.SearchRequest) {
    if (this._state && typeof value !== 'undefined') {
      this._state.activeSearch = value;
    } else {
      // dispatch change for item activeSearch
      if (this._dispatch) {
        this._dispatch({
          type: DocumentStateEnums.DocumentState_activeSearch,
          payload: value,
        });
      }
    }
  }
  get activeSearchOrdering(): Model.SearchOrder[] {
    if (this._getState) {
      return this._getState().DocumentState.activeSearchOrdering;
    } else {
      if (this._state) {
        return this._state.activeSearchOrdering;
      }
    }
    throw new Error('Invalid State in DocumentState_activeSearchOrdering');
  }
  set activeSearchOrdering(value: Model.SearchOrder[]) {
    if (this._state && typeof value !== 'undefined') {
      this._state.activeSearchOrdering = value;
    } else {
      // dispatch change for item activeSearchOrdering
      if (this._dispatch) {
        this._dispatch({
          type: DocumentStateEnums.DocumentState_activeSearchOrdering,
          payload: value,
        });
      }
    }
  }
  get activeDocument(): Model.UIDocument | undefined {
    if (this._getState) {
      return this._getState().DocumentState.activeDocument;
    } else {
      if (this._state) {
        return this._state.activeDocument;
      }
    }
    return undefined;
  }
  set activeDocument(value: Model.UIDocument | undefined) {
    if (this._state && typeof value !== 'undefined') {
      this._state.activeDocument = value;
    } else {
      // dispatch change for item activeDocument
      if (this._dispatch) {
        this._dispatch({
          type: DocumentStateEnums.DocumentState_activeDocument,
          payload: value,
        });
      }
    }
  }
  get activeDocumentHistory(): Model.DocStateHistoryRow[] | undefined {
    if (this._getState) {
      return this._getState().DocumentState.activeDocumentHistory;
    } else {
      if (this._state) {
        return this._state.activeDocumentHistory;
      }
    }
    return undefined;
  }
  set activeDocumentHistory(value: Model.DocStateHistoryRow[] | undefined) {
    if (this._state && typeof value !== 'undefined') {
      this._state.activeDocumentHistory = value;
    } else {
      // dispatch change for item activeDocumentHistory
      if (this._dispatch) {
        this._dispatch({
          type: DocumentStateEnums.DocumentState_activeDocumentHistory,
          payload: value,
        });
      }
    }
  }
  get activeDocumentComments(): Model.UIDocumentComment[] | undefined {
    if (this._getState) {
      return this._getState().DocumentState.activeDocumentComments;
    } else {
      if (this._state) {
        return this._state.activeDocumentComments;
      }
    }
    return undefined;
  }
  set activeDocumentComments(value: Model.UIDocumentComment[] | undefined) {
    if (this._state && typeof value !== 'undefined') {
      this._state.activeDocumentComments = value;
    } else {
      // dispatch change for item activeDocumentComments
      if (this._dispatch) {
        this._dispatch({
          type: DocumentStateEnums.DocumentState_activeDocumentComments,
          payload: value,
        });
      }
    }
  }
  get isLoadingWaitingForReview(): boolean {
    if (this._getState) {
      return this._getState().DocumentState.isLoadingWaitingForReview;
    } else {
      if (this._state) {
        return this._state.isLoadingWaitingForReview;
      }
    }
    throw new Error('Invalid State in DocumentState_isLoadingWaitingForReview');
  }
  set isLoadingWaitingForReview(value: boolean) {
    if (this._state && typeof value !== 'undefined') {
      this._state.isLoadingWaitingForReview = value;
    } else {
      // dispatch change for item isLoadingWaitingForReview
      if (this._dispatch) {
        this._dispatch({
          type: DocumentStateEnums.DocumentState_isLoadingWaitingForReview,
          payload: value,
        });
      }
    }
  }
  get waitingForReview(): Model.UIDocument[] {
    if (this._getState) {
      return this._getState().DocumentState.waitingForReview;
    } else {
      if (this._state) {
        return this._state.waitingForReview;
      }
    }
    throw new Error('Invalid State in DocumentState_waitingForReview');
  }
  set waitingForReview(value: Model.UIDocument[]) {
    if (this._state && typeof value !== 'undefined') {
      this._state.waitingForReview = value;
    } else {
      // dispatch change for item waitingForReview
      if (this._dispatch) {
        this._dispatch({
          type: DocumentStateEnums.DocumentState_waitingForReview,
          payload: value,
        });
      }
    }
  }
  get isLoadingSearchResults(): boolean {
    if (this._getState) {
      return this._getState().DocumentState.isLoadingSearchResults;
    } else {
      if (this._state) {
        return this._state.isLoadingSearchResults;
      }
    }
    throw new Error('Invalid State in DocumentState_isLoadingSearchResults');
  }
  set isLoadingSearchResults(value: boolean) {
    if (this._state && typeof value !== 'undefined') {
      this._state.isLoadingSearchResults = value;
    } else {
      // dispatch change for item isLoadingSearchResults
      if (this._dispatch) {
        this._dispatch({
          type: DocumentStateEnums.DocumentState_isLoadingSearchResults,
          payload: value,
        });
      }
    }
  }
  get downloadBasket(): Model.UIDocument[] {
    if (this._getState) {
      return this._getState().DocumentState.downloadBasket;
    } else {
      if (this._state) {
        return this._state.downloadBasket;
      }
    }
    throw new Error('Invalid State in DocumentState_downloadBasket');
  }
  set downloadBasket(value: Model.UIDocument[]) {
    if (this._state && typeof value !== 'undefined') {
      this._state.downloadBasket = value;
    } else {
      // dispatch change for item downloadBasket
      if (this._dispatch) {
        this._dispatch({
          type: DocumentStateEnums.DocumentState_downloadBasket,
          payload: value,
        });
      }
    }
  }
  get pitemTargetting(): Model.UIDocument[] {
    if (this._getState) {
      return this._getState().DocumentState.pitemTargetting;
    } else {
      if (this._state) {
        return this._state.pitemTargetting;
      }
    }
    throw new Error('Invalid State in DocumentState_pitemTargetting');
  }
  set pitemTargetting(value: Model.UIDocument[]) {
    if (this._state && typeof value !== 'undefined') {
      this._state.pitemTargetting = value;
    } else {
      // dispatch change for item pitemTargetting
      if (this._dispatch) {
        this._dispatch({
          type: DocumentStateEnums.DocumentState_pitemTargetting,
          payload: value,
        });
      }
    }
  }
  get documentCollectionUpload(): DocCollectionUpload {
    if (this._getState) {
      return this._getState().DocumentState.documentCollectionUpload;
    } else {
      if (this._state) {
        return this._state.documentCollectionUpload;
      }
    }
    throw new Error('Invalid State in DocumentState_documentCollectionUpload');
  }
  set documentCollectionUpload(value: DocCollectionUpload) {
    if (this._state && typeof value !== 'undefined') {
      this._state.documentCollectionUpload = value;
    } else {
      // dispatch change for item documentCollectionUpload
      if (this._dispatch) {
        this._dispatch({
          type: DocumentStateEnums.DocumentState_documentCollectionUpload,
          payload: value,
        });
      }
    }
  }
  get searchRequestKeys(): {
    [key in Model.SearchColumn]: string;
  } {
    if (this._getState) {
      return this._getState().DocumentState.searchRequestKeys;
    } else {
      if (this._state) {
        return this._state.searchRequestKeys;
      }
    }
    throw new Error('Invalid State in DocumentState_searchRequestKeys');
  }
  set searchRequestKeys(value: {
    [key in Model.SearchColumn]: string;
  }) {
    if (this._state && typeof value !== 'undefined') {
      this._state.searchRequestKeys = value;
    } else {
      // dispatch change for item searchRequestKeys
      if (this._dispatch) {
        this._dispatch({
          type: DocumentStateEnums.DocumentState_searchRequestKeys,
          payload: value,
        });
      }
    }
  }
  get noOpCnt(): number {
    if (this._getState) {
      return this._getState().DocumentState.noOpCnt;
    } else {
      if (this._state) {
        return this._state.noOpCnt;
      }
    }
    throw new Error('Invalid State in DocumentState_noOpCnt');
  }
  set noOpCnt(value: number) {
    if (this._state && typeof value !== 'undefined') {
      this._state.noOpCnt = value;
    } else {
      // dispatch change for item noOpCnt
      if (this._dispatch) {
        this._dispatch({
          type: DocumentStateEnums.DocumentState_noOpCnt,
          payload: value,
        });
      }
    }
  }

  private createUUID(): string {
    return `${uuidCnt++}`;
  }
  noOp() {
    if (this._state) {
      this.noOpCnt++;
    } else {
      if (this._dispatch) {
        this._dispatch({ type: DocumentStateEnums.DocumentState_noOp });
      }
    }
  }

  public static noOp() {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(undefined, dispatcher, getState).noOp();
    };
  }
  setDocCollection(data: DocCollectionUpload) {
    if (this._state) {
      this.documentCollectionUpload = data;
      this.documentCollectionUpload.uuid = this.createUUID();
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: DocumentStateEnums.DocumentState_setDocCollection,
          payload: data,
        });
      }
    }
  }

  public static setDocCollection(data: DocCollectionUpload) {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(
        undefined,
        dispatcher,
        getState
      ).setDocCollection(data);
    };
  }
  setDocCollectionDocumentTypeId(id: number) {
    if (this._state) {
      this.documentCollectionUpload.disciplineId = id;
      this.documentCollectionUpload.uuid = this.createUUID();
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: DocumentStateEnums.DocumentState_setDocCollectionDocumentTypeId,
          payload: id,
        });
      }
    }
  }

  public static setDocCollectionDocumentTypeId(id: number) {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(
        undefined,
        dispatcher,
        getState
      ).setDocCollectionDocumentTypeId(id);
    };
  }
  addToPitemTargets(list: Model.UIDocument[]) {
    if (this._state) {
      const keys: {
        [key: number]: Model.UIDocument;
      } = this.pitemTargetting.reduce((data, item) => {
        return { ...data, [item.id]: item };
      }, {});
      const newItems = list.filter(
        (item) => typeof keys[item.id] === 'undefined'
      );
      this.pitemTargetting = [...this.pitemTargetting, ...newItems];
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: DocumentStateEnums.DocumentState_addToPitemTargets,
          payload: list,
        });
      }
    }
  }

  public static addToPitemTargets(list: Model.UIDocument[]) {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(
        undefined,
        dispatcher,
        getState
      ).addToPitemTargets(list);
    };
  }
  removeFromPitemTargets(removedItem: Model.UIDocument) {
    if (this._state) {
      this.pitemTargetting = this.pitemTargetting.filter(
        (item) => item.id !== removedItem.id
      );
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: DocumentStateEnums.DocumentState_removeFromPitemTargets,
          payload: removedItem,
        });
      }
    }
  }

  public static removeFromPitemTargets(removedItem: Model.UIDocument) {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(
        undefined,
        dispatcher,
        getState
      ).removeFromPitemTargets(removedItem);
    };
  }
  clearPItemTargets() {
    if (this._state) {
      this.pitemTargetting = [];
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: DocumentStateEnums.DocumentState_clearPItemTargets,
        });
      }
    }
  }

  public static clearPItemTargets() {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(
        undefined,
        dispatcher,
        getState
      ).clearPItemTargets();
    };
  }
  async findSimilarDocuments(doc_id: number) {
    return (await DOCAPI.findSimilarDocuments(doc_id)) as Model.UIDocument[];
  }

  public static findSimilarDocuments(doc_id: number) {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(
        undefined,
        dispatcher,
        getState
      ).findSimilarDocuments(doc_id);
    };
  }
  async getPitemCntForPitemType(pitem_type_id: number) {
    return await DOCAPI.getPitemTypePitemCnt(pitem_type_id);
  }

  public static getPitemCntForPitemType(pitem_type_id: number) {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(
        undefined,
        dispatcher,
        getState
      ).getPitemCntForPitemType(pitem_type_id);
    };
  }
  async removePitemType(pitem_type_id: number) {
    return await DOCAPI.deletePItemType(pitem_type_id);
  }

  public static removePitemType(pitem_type_id: number) {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(
        undefined,
        dispatcher,
        getState
      ).removePitemType(pitem_type_id);
    };
  }

  async DocumentStateDispatcher(action: any) {
    if (typeof this._dispatch !== 'undefined') {
      this._dispatch(action);
    }
  }

  public static DocumentStateDispatcher(action: any) {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(
        undefined,
        dispatcher,
        getState
      ).DocumentStateDispatcher(action);
    };
  }
  async removeDocumentCategory(id: number) {
    await DOCAPI.deleteCategory(id);
    await this.getMetadata();
  }

  public static removeDocumentCategory(id: number) {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(
        undefined,
        dispatcher,
        getState
      ).removeDocumentCategory(id);
    };
  }
  async removePItemType(id: number) {
    await DOCAPI.deletePItemType(id);
    await this.getMetadata();
  }

  public static removePItemType(id: number) {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(
        undefined,
        dispatcher,
        getState
      ).removePItemType(id);
    };
  }
  async addOrUpdatePItemType(opions: Partial<Model.DocumentCategory>) {
    return await DOCAPI.addOrUpdatePitemType(opions);
  }

  public static addOrUpdatePItemType(opions: Partial<Model.DocumentCategory>) {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(
        undefined,
        dispatcher,
        getState
      ).addOrUpdatePItemType(opions);
    };
  }
  async getDocCnt(id: number) {
    return await DOCAPI.getDocumentCnt(id);
  }

  public static getDocCnt(id: number) {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(undefined, dispatcher, getState).getDocCnt(id);
    };
  }
  async addOrUpdateCategory(opions: Partial<Model.DocumentCategory>) {
    return await DOCAPI.addOrUpdateCategory(opions);
  }

  public static addOrUpdateCategory(opions: Partial<Model.DocumentCategory>) {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(
        undefined,
        dispatcher,
        getState
      ).addOrUpdateCategory(opions);
    };
  }
  async loadWaitingForReview() {
    if (this.isLoadingWaitingForReview) {
      return;
    }
    this.isLoadingWaitingForReview = true;
    const docs = await DOCAPI.waitingForReview();
    this.waitingForReview = docs;
    this.isLoadingWaitingForReview = false;
  }

  public static loadWaitingForReview() {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(
        undefined,
        dispatcher,
        getState
      ).loadWaitingForReview();
    };
  }
  setStatus(params: {
    action: ActionStatusDetail;
    snack?: SnackDetails;
    finished?: boolean;
  }) {
    if (this._state) {
      this.docstatus[params.action.name] = {
        ...this.docstatus[params.action.name],
        loading: params.finished ? false : true,
        loaded: false,
        snack: params.snack,
      };
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: DocumentStateEnums.DocumentState_setStatus,
          payload: params,
        });
      }
    }
  }

  public static setStatus(params: {
    action: ActionStatusDetail;
    snack?: SnackDetails;
    finished?: boolean;
  }) {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(undefined, dispatcher, getState).setStatus(
        params
      );
    };
  }
  setLoading(action: ActionStatusDetail) {
    if (this._state) {
      this.docstatus[action.name] = {
        ...this.docstatus[action.name],
        loading: true,
        loaded: false,
      };
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: DocumentStateEnums.DocumentState_setLoading,
          payload: action,
        });
      }
    }
  }

  public static setLoading(action: ActionStatusDetail) {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(undefined, dispatcher, getState).setLoading(
        action
      );
    };
  }
  setSuccess(params: {
    action: ActionStatusDetail;
    translation?: TranslationDetails;
  }) {
    if (this._state) {
      const { action, ...snackParams } = params;
      const snack =
        snackParams && ({ variant: 'success', ...snackParams } as SnackDetails);
      this.docstatus[action.name] = {
        ...this.docstatus[action.name],
        loading: false,
        loaded: true,
        snack,
      };
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: DocumentStateEnums.DocumentState_setSuccess,
          payload: params,
        });
      }
    }
  }

  public static setSuccess(params: {
    action: ActionStatusDetail;
    translation?: TranslationDetails;
  }) {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(undefined, dispatcher, getState).setSuccess(
        params
      );
    };
  }
  setError(params: {
    action: ActionStatusDetail;
    message?: string;
    translation?: TranslationDetails;
  }) {
    if (this._state) {
      const { action, ...snackParams } = params;
      const snack = { variant: 'error', ...snackParams } as SnackDetails;
      this.setStatus({ action, snack });
      this.docstatus[params.action.name] = {
        ...this.docstatus[params.action.name],
        loading: false,
        loaded: false,
        snack,
      };
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: DocumentStateEnums.DocumentState_setError,
          payload: params,
        });
      }
    }
  }

  public static setError(params: {
    action: ActionStatusDetail;
    message?: string;
    translation?: TranslationDetails;
  }) {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(undefined, dispatcher, getState).setError(
        params
      );
    };
  }
  resetDocSnacks() {
    if (this._state) {
      Object.keys(this.docstatus).map((key) => {
        this.docstatus[key].snack = undefined;
      });
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: DocumentStateEnums.DocumentState_resetDocSnacks,
        });
      }
    }
  }

  public static resetDocSnacks() {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(
        undefined,
        dispatcher,
        getState
      ).resetDocSnacks();
    };
  }
  setRedirect(id: number) {
    if (this._state) {
      this.redirectToId = id;
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: DocumentStateEnums.DocumentState_setRedirect,
          payload: id,
        });
      }
    }
  }

  public static setRedirect(id: number) {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(undefined, dispatcher, getState).setRedirect(
        id
      );
    };
  }
  updateActiveDocument(newDoc: Model.UIDocument) {
    if (this._state) {
      this.activeDocument = newDoc;
      // Update the document in search results too
      // if the user browses back to search results
      // after updating the document
      let index = -1;
      this.searchResults.forEach((d, i) => {
        if (d.id === newDoc.id) {
          index = i;
        }
      });
      if (index >= 0) {
        this.searchResults[index] = newDoc;
      }
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: DocumentStateEnums.DocumentState_updateActiveDocument,
          payload: newDoc,
        });
      }
    }
  }

  public static updateActiveDocument(newDoc: Model.UIDocument) {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(
        undefined,
        dispatcher,
        getState
      ).updateActiveDocument(newDoc);
    };
  }
  setActiveSearch(search: Model.SearchRequest) {
    if (this._state) {
      this.activeSearch = search;
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: DocumentStateEnums.DocumentState_setActiveSearch,
          payload: search,
        });
      }
    }
  }

  public static setActiveSearch(search: Model.SearchRequest) {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(
        undefined,
        dispatcher,
        getState
      ).setActiveSearch(search);
    };
  }
  saveDocument(doc: Model.UIDocument) {
    if (this._state) {
      this.documents[doc.id] = doc;
      // TODO: make this smarter
      let index = -1;
      this.searchResults.forEach((d, i) => {
        if (d.id === doc.id) {
          index = i;
        }
      });
      if (index >= 0) {
        this.searchResults[index] = doc;
      }
      this.activeDocument = doc;
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: DocumentStateEnums.DocumentState_saveDocument,
          payload: doc,
        });
      }
    }
  }

  public static saveDocument(doc: Model.UIDocument) {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(undefined, dispatcher, getState).saveDocument(
        doc
      );
    };
  }
  addToSearchResults(doc: Model.UIDocument) {
    if (this._state) {
      this.documents[doc.id] = doc;
      const index = _.findIndex(this.searchResults, { id: doc.id });
      if (index > 0) {
        this.searchResults.splice(index, 1, doc);
      } else {
        this.searchResults.push(doc);
      }
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: DocumentStateEnums.DocumentState_addToSearchResults,
          payload: doc,
        });
      }
    }
  }

  public static addToSearchResults(doc: Model.UIDocument) {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(
        undefined,
        dispatcher,
        getState
      ).addToSearchResults(doc);
    };
  }
  revertDocument(doc: Model.UIDocument) {
    if (this._state) {
      this.activeDocument = this.documents[doc.id];
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: DocumentStateEnums.DocumentState_revertDocument,
          payload: doc,
        });
      }
    }
  }

  public static revertDocument(doc: Model.UIDocument) {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(undefined, dispatcher, getState).revertDocument(
        doc
      );
    };
  }
  async cleanRedirectURL() {
    this.redirectToId = 0;
  }

  public static cleanRedirectURL() {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(
        undefined,
        dispatcher,
        getState
      ).cleanRedirectURL();
    };
  }
  async saveActiveDocument() {
    if (this.activeDocument) {
      // this.saveDocument(this.activeDocument);
      const origId = this.activeDocument.id;
      const nextDoc = await DOCAPI.updateDocument(this.activeDocument);
      // NOTE: this is a test for the redirect
      if (nextDoc.id !== origId) {
        this.DocumentStateDispatcher(push('/documents/' + nextDoc.id));
      }
      this.saveDocument(nextDoc);
      this.fetchDocumentHistory(nextDoc.id);
    }
  }

  public static saveActiveDocument() {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(
        undefined,
        dispatcher,
        getState
      ).saveActiveDocument();
    };
  }
  async getMetadata() {
    try {
      const meta = await API.getDocumentMetadata();
      this.metadata = {
        workflowStates: meta.workflowStates,
        documentCategories: meta.documentCategories.map((cat) => ({
          id: cat.id,
          name: cat.category_name as string,
          parentId: cat.upper_category_id,
        })),
        pitemTypes: meta.pitemTypes,
        aitemTypes: meta.aitemTypes,
        workflows: meta.workflows,
      };
    } catch (e) {
      this.networkError = true;
    }
  }

  public static getMetadata() {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(undefined, dispatcher, getState).getMetadata();
    };
  }
  resetDoc() {
    if (this._state) {
      this.activeDocument = undefined;
      this.activeDocumentHistory = [];
    } else {
      if (this._dispatch) {
        this._dispatch({ type: DocumentStateEnums.DocumentState_resetDoc });
      }
    }
  }

  public static resetDoc() {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(undefined, dispatcher, getState).resetDoc();
    };
  }
  resetDocHistory() {
    if (this._state) {
      this.activeDocumentHistory = [];
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: DocumentStateEnums.DocumentState_resetDocHistory,
        });
      }
    }
  }

  public static resetDocHistory() {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(
        undefined,
        dispatcher,
        getState
      ).resetDocHistory();
    };
  }
  async fetchDocument(id: number) {
    const action = this.docstatus.fetchDocument;
    if (action.loading) {
      return;
    }
    if (this.activeDocument) {
      if (this.activeDocument.id !== id) {
        this.resetDoc();
      }
    }
    try {
      this.setLoading(action);
      const doc = await DOCAPI.fetchDocument(id);
      this.saveDocument(doc);
      this.activeDocument = doc;
      this.setSuccess({ action });
    } catch (e) {
      this.networkError = true;
      this.setError({ action, translation: { id: 'error.documentNotFound' } });
    }
  }

  public static fetchDocument(id: number) {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(undefined, dispatcher, getState).fetchDocument(
        id
      );
    };
  }
  async fetchDocumentHistory(id: number) {
    const action = this.docstatus.fetchDocumentHistory;
    if (action.loading) {
      return;
    }
    if (this.activeDocument) {
      if (this.activeDocument.id !== id) {
        this.resetDocHistory();
      }
    }
    try {
      this.setLoading(action);
      this.activeDocumentHistory = await DOCAPI.fetchDocumentHistory(id);
      this.setSuccess({ action });
    } catch (e) {
      this.networkError = true;
      this.setError({ action, message: e.message });
    }
  }

  public static fetchDocumentHistory(id: number) {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(
        undefined,
        dispatcher,
        getState
      ).fetchDocumentHistory(id);
    };
  }
  async repeatSearchWithOrder(order: Model.SearchOrder) {
    const newSearch = {
      ...this.activeSearch,
      orderByFields: [order],
    };
    this.setActiveSearch(newSearch);
    this.documentSearch(newSearch);
  }

  public static repeatSearchWithOrder(order: Model.SearchOrder) {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(
        undefined,
        dispatcher,
        getState
      ).repeatSearchWithOrder(order);
    };
  }
  setSearchResults(list: Model.UIDocument[]) {
    if (this._state) {
      const parentDocs: Model.UIDocument[] = [];
      const byId: {
        [key: number]: Model.UIDocument;
      } = {};
      for (const item of list) {
        byId[item.id] = item;
      }
      for (const item of list) {
        if (item.parentId) {
          const parent = byId[item.parentId];
          if (parent) {
            if (!parent.children) {
              parent.children = [];
            }
            if (parent && parent.children) {
              parent.children.push(item);
            }
          }
        } else {
          parentDocs.push(item);
        }
      }
      this.searchResultsTree = parentDocs;
      this.searchResults = list;
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: DocumentStateEnums.DocumentState_setSearchResults,
          payload: list,
        });
      }
    }
  }

  public static setSearchResults(list: Model.UIDocument[]) {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(
        undefined,
        dispatcher,
        getState
      ).setSearchResults(list);
    };
  }
  async loadMoreDocuments(params: Model.SearchRequest) {
    try {
      const reqData = { ...params };
      reqData.cursor = {
        offset: this.searchResults.length,
        limit: 400,
      };
      reqData.name = '';
      const res = await DOCAPI.documentSearchRequest(reqData);
      this.setSearchResults([...this.searchResults, ...res.rows]);
    } catch (e) {}
  }

  public static loadMoreDocuments(params: Model.SearchRequest) {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(
        undefined,
        dispatcher,
        getState
      ).loadMoreDocuments(params);
    };
  }
  async documentSearch(params: Model.SearchRequest) {
    const action = this.docstatus.searchRequest;
    if (action.loading) {
      return;
    }
    try {
      this.searchResultCnt = -1;
      // remove name parameter, because it has a conflict with freeText
      const reqData = { ...params };
      reqData.name = '';
      // this.setLoading(action);
      this.setStatus({ action });
      const res = await DOCAPI.documentSearchRequest(reqData);
      // activeSearchResultCnt = -1;
      this.setSearchResults(res.rows);
      this.setSuccess({ action });
      this.searchResultCnt = res.estimatedCnt;
    } catch (e) {
      this.setError({ action, translation: { id: 'error.tooManyResults' } });
    }
  }

  public static documentSearch(params: Model.SearchRequest) {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(undefined, dispatcher, getState).documentSearch(
        params
      );
    };
  }
  moveSearchUp(name: string) {
    if (this._state) {
      if (!this.searches.savedSearches) {
        return;
      }
      const searchItem = this.searches.savedSearches
        .filter((item) => item.name === name)
        .pop();
      if (!searchItem) return;
      const idx = this.searches.savedSearches.indexOf(searchItem);
      if (idx >= this.searches.savedSearches.length - 1) {
        return;
      }
      arraymove(this.searches.savedSearches, idx, idx + 1);
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: DocumentStateEnums.DocumentState_moveSearchUp,
          payload: name,
        });
      }
    }
  }

  public static moveSearchUp(name: string) {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(undefined, dispatcher, getState).moveSearchUp(
        name
      );
    };
  }
  moveSearchDown(name: string) {
    if (this._state) {
      if (!this.searches.savedSearches) {
        return;
      }
      const searchItem = this.searches.savedSearches
        .filter((item) => item.name === name)
        .pop();
      if (!searchItem) return;
      const idx = this.searches.savedSearches.indexOf(searchItem);
      if (idx < 1) {
        return;
      }
      arraymove(this.searches.savedSearches, idx, idx - 1);
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: DocumentStateEnums.DocumentState_moveSearchDown,
          payload: name,
        });
      }
    }
  }

  public static moveSearchDown(name: string) {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(undefined, dispatcher, getState).moveSearchDown(
        name
      );
    };
  }
  initSearch(searches: Model.UserSearches) {
    if (this._state) {
      if (!searches.savedSearches) {
        return;
      }
      this.searches = searches;
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: DocumentStateEnums.DocumentState_initSearch,
          payload: searches,
        });
      }
    }
  }

  public static initSearch(searches: Model.UserSearches) {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(undefined, dispatcher, getState).initSearch(
        searches
      );
    };
  }
  async getUserSearches() {
    this.initSearch(await API.getUserSearches());
  }

  public static getUserSearches() {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(
        undefined,
        dispatcher,
        getState
      ).getUserSearches();
    };
  }
  async saveUserSearches() {
    if (this.searches) {
      await API.saveUserSearches(this.searches);
    }
  }

  public static saveUserSearches() {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(
        undefined,
        dispatcher,
        getState
      ).saveUserSearches();
    };
  }
  async addAndSaveUserSearches(newSearch: Model.SearchRequest) {
    if (this.searches) {
      this.addSearchRequest(newSearch);
      await API.saveUserSearches(this.searches);
    }
  }

  public static addAndSaveUserSearches(newSearch: Model.SearchRequest) {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(
        undefined,
        dispatcher,
        getState
      ).addAndSaveUserSearches(newSearch);
    };
  }
  async removeAndSaveUserSearches(removedSearch: string) {
    if (this.searches) {
      this.removeSearchRequest(removedSearch);
      await API.saveUserSearches(this.searches);
    }
  }

  public static removeAndSaveUserSearches(removedSearch: string) {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(
        undefined,
        dispatcher,
        getState
      ).removeAndSaveUserSearches(removedSearch);
    };
  }
  addSearchRequest(newSearch: Model.SearchRequest) {
    if (this._state) {
      if (!this.searches) {
        return;
      }
      if (!this.searches.savedSearches) {
        this.searches.savedSearches = [];
      }
      // For now the search name must be unique so test that we don't
      // try to save search with name that already exist
      let index = 0;
      const matchingSearches = this.searches.savedSearches.filter(
        (search, idx) => {
          if (search.name === newSearch.name) {
            index = idx;
            return true;
          }
          return false;
        }
      );
      if (matchingSearches.length === 0) {
        this.searches.savedSearches.push(newSearch);
      } else {
        // replace
        this.searches.savedSearches[index] = newSearch;
      }
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: DocumentStateEnums.DocumentState_addSearchRequest,
          payload: newSearch,
        });
      }
    }
  }

  public static addSearchRequest(newSearch: Model.SearchRequest) {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(
        undefined,
        dispatcher,
        getState
      ).addSearchRequest(newSearch);
    };
  }
  removeSearchRequest(removedSearch: string) {
    if (this._state) {
      if (!this.searches) {
        return;
      }
      if (!this.searches.savedSearches) {
        this.searches.savedSearches = [];
      }
      const searches = this.searches.savedSearches.filter((search) => {
        return search.name !== removedSearch;
      });
      this.searches.savedSearches = searches;
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: DocumentStateEnums.DocumentState_removeSearchRequest,
          payload: removedSearch,
        });
      }
    }
  }

  public static removeSearchRequest(removedSearch: string) {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(
        undefined,
        dispatcher,
        getState
      ).removeSearchRequest(removedSearch);
    };
  }
  async uploadDesignerDocuments(data: Model.IUploadState) {
    const action = this.docstatus.uploadDesignerDocuments;
    if (!data.files) {
      return;
    }
    try {
      this.setStatus({ action });
      // Clear upload results list
      this.setUploadResults([]);
      this.DocumentStateDispatcher(push('/upload/documents'));
      // In the end...
      // this.DocumentStateDispatcher(push('/upload/documents'));
      const { files, documentCollectionFile, ...restProps } = data;
      this.setDocumentUploadAmount(files.length);
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      const uploadedFiles = (await Promise.all(
        data.files.map(async (file) => {
          const formData = new FormData();
          formData.append('info', JSON.stringify(restProps));
          formData.append('file', file);
          const result = (
            await axios.post('/docs/v1/upload/doc', formData, config)
          ).data as Model.UIDocument[];
          this.addUploadResults(result);
          return result[0];
        })
      )) as Model.UIDocument[];
      const notUpdated: string[] = [];
      const successFiles: Model.UIDocument[] = [];
      for (const doc of uploadedFiles) {
        if (doc.displayData && doc.displayData.fileWasNotUpdated) {
          notUpdated.push(doc.fileName);
        } else {
          successFiles.push(doc);
        }
      }
      if (successFiles.length > 0) {
        this.setSuccess({
          action,
          translation: {
            id: 'upload.uploadDesignerDocumentsSuccess',
            values: { amount: successFiles.length },
          },
        });
      }
      if (notUpdated.length > 0) {
        this.setStatus({
          action,
          finished: true,
          snack: {
            variant: 'warning',
            time: 50 * 1000,
            translation: {
              id: 'upload.uploadDesignerDocumentsExists',
              values: { filenames: notUpdated.join(', ') },
            },
          },
        });
      }
      if (successFiles.length > 0) {
        this.setSuccess({ action });
        this.setUploadResults(successFiles);
      }
    } catch (e) {
      this.setError({
        action,
        translation: { id: 'error.someDocumentsFailedToUpload' },
      });
    }
  }

  public static uploadDesignerDocuments(data: Model.IUploadState) {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(
        undefined,
        dispatcher,
        getState
      ).uploadDesignerDocuments(data);
    };
  }
  setDocumentUploadAmount(amount: number) {
    if (this._state) {
      this.uploadDocumentAmount = amount;
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: DocumentStateEnums.DocumentState_setDocumentUploadAmount,
          payload: amount,
        });
      }
    }
  }

  public static setDocumentUploadAmount(amount: number) {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(
        undefined,
        dispatcher,
        getState
      ).setDocumentUploadAmount(amount);
    };
  }
  setUploadResults(results: Model.UIDocument[]) {
    if (this._state) {
      this.uploadResults = results;
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: DocumentStateEnums.DocumentState_setUploadResults,
          payload: results,
        });
      }
    }
  }

  public static setUploadResults(results: Model.UIDocument[]) {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(
        undefined,
        dispatcher,
        getState
      ).setUploadResults(results);
    };
  }
  addUploadResults(results: Model.UIDocument[]) {
    if (this._state) {
      this.uploadResults = this.uploadResults.concat(results);
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: DocumentStateEnums.DocumentState_addUploadResults,
          payload: results,
        });
      }
    }
  }

  public static addUploadResults(results: Model.UIDocument[]) {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(
        undefined,
        dispatcher,
        getState
      ).addUploadResults(results);
    };
  }
  async uploadDocumentCollection(file: File) {
    const action = this.docstatus.uploadDocumentCollection;
    if (!file) {
      return;
    }
    this.setLoading(action);
    const formData = new FormData();
    formData.append('file', file);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    try {
      const res = (
        await axios.post('/docs/v1/upload/documentcollection', formData, config)
      ).data as Model.DocumentCollectionResults;
      this.setSuccess({
        action,
        translation: { id: 'upload.collectionSuccessful' },
      });
      this.setDocumentCollection(res);
    } catch (e) {
      this.setError({
        action,
        translation: {
          id: 'error.collectionUploadFailed',
          values: { name: file.name },
        },
      });
    }
  }

  public static uploadDocumentCollection(file: File) {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(
        undefined,
        dispatcher,
        getState
      ).uploadDocumentCollection(file);
    };
  }
  setDocumentCollection(collection: Model.DocumentCollectionResults) {
    if (this._state) {
      this.documentCollection = collection;
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: DocumentStateEnums.DocumentState_setDocumentCollection,
          payload: collection,
        });
      }
    }
  }

  public static setDocumentCollection(
    collection: Model.DocumentCollectionResults
  ) {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(
        undefined,
        dispatcher,
        getState
      ).setDocumentCollection(collection);
    };
  }
  async uploadNewDocumentRevision(file: File) {
    const action = this.docstatus.uploadNewDocumentRevision;
    if (!file) return;
    this.setLoading(action);
    const formData = new FormData();
    formData.append('info', JSON.stringify(this.activeDocument));
    formData.append('file', file);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    try {
      const result = (
        await axios.post('/docs/v1/upload/nextrevision', formData, config)
      ).data as Model.UIDocument[];
      const newDoc = result[0];
      this.DocumentStateDispatcher(push('/documents/' + newDoc.id));
      // this.setRedirect(newDoc.id);
      this.saveDocument(newDoc);
      this.setSuccess({ action });
    } catch (e) {
      this.setError({ action, message: e.message });
      console.error(e);
    }
  }

  public static uploadNewDocumentRevision(file: File) {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(
        undefined,
        dispatcher,
        getState
      ).uploadNewDocumentRevision(file);
    };
  }
  async uploadTargetAttachment(data: {
    files: File[];
    categoryId: number;
    subject: string;
  }) {
    const action = this.docstatus.uploadTargetAttachment;
    const { activeDocument } = this;
    if (!data.files || !activeDocument) return;
    const { files, categoryId, subject } = data;
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    this.setStatus({ action });
    await Promise.all(
      files.map(async (file) => {
        try {
          const info = _.merge({}, activeDocument, {
            name: file.name,
            categories: [categoryId],
            description: subject,
            parentId: activeDocument.id,
            dwgName: '',
          });
          const formData = new FormData();
          formData.append('info', JSON.stringify(info));
          formData.append('file', file);
          const result = await axios.post(
            '/docs/v1/upload/doc2',
            formData,
            config
          );
          this.addToSearchResults(result.data[0]);
          this.setStatus({
            action,
            snack: {
              variant: 'success',
              translation: {
                id: 'inspectionDocuments.documentUploaded',
                values: { name: file.name },
              },
            },
          });
        } catch (error) {
          this.setError({
            action,
            message: error.message,
            translation: {
              id: 'inspectionDocuments.documentNotUploaded',
              values: { name: file.name },
            },
          });
        }
      })
    );
    this.setSuccess({ action });
  }

  public static uploadTargetAttachment(data: {
    files: File[];
    categoryId: number;
    subject: string;
  }) {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(
        undefined,
        dispatcher,
        getState
      ).uploadTargetAttachment(data);
    };
  }
  async sendPrintOrder(data: Model.PrintOrder) {
    const action = this.docstatus.sendPrintOrder;
    try {
      this.setLoading(action);
      await DOCAPI.documentPrintOrder(data);
      this.setSuccess({ action });
    } catch (e) {
      console.log(e.response.data);
      this.setError({ action, message: e.message });
    }
  }

  public static sendPrintOrder(data: Model.PrintOrder) {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(undefined, dispatcher, getState).sendPrintOrder(
        data
      );
    };
  }
  async linkToDwgDocs(data: Model.UpdateDWG) {
    const action = this.docstatus.linkToDwgDocs;
    try {
      this.setLoading(action);
      await DOCAPI.linkToDwgDocs(data);
      this.setSuccess({ action });
    } catch (e) {
      this.setError({ action, message: e.message });
    }
  }

  public static linkToDwgDocs(data: Model.UpdateDWG) {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(undefined, dispatcher, getState).linkToDwgDocs(
        data
      );
    };
  }
  resetDocState() {
    if (this._state) {
      this.searchResults = [];
      this.activeDocument = undefined;
      this.activeSearch = {};
      this.documents = {};
      this.documentCollection = undefined;
      this.waitingForReview = [];
      this.downloadBasket = [];
      this.searches = {
        version: 1,
        savedSearches: [],
      };
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: DocumentStateEnums.DocumentState_resetDocState,
        });
      }
    }
  }

  public static resetDocState() {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(
        undefined,
        dispatcher,
        getState
      ).resetDocState();
    };
  }
  clearBasket() {
    if (this._state) {
      this.downloadBasket = [];
      window.localStorage.setItem(
        'download_basket',
        JSON.stringify(this.downloadBasket)
      );
    } else {
      if (this._dispatch) {
        this._dispatch({ type: DocumentStateEnums.DocumentState_clearBasket });
      }
    }
  }

  public static clearBasket() {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(undefined, dispatcher, getState).clearBasket();
    };
  }
  removeFromBasket(item: Model.UIDocument) {
    if (this._state) {
      this.downloadBasket = this.downloadBasket.filter(
        (row) => row.id !== item.id
      );
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: DocumentStateEnums.DocumentState_removeFromBasket,
          payload: item,
        });
      }
    }
  }

  public static removeFromBasket(item: Model.UIDocument) {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(
        undefined,
        dispatcher,
        getState
      ).removeFromBasket(item);
    };
  }
  addToBasket(list: Model.UIDocument[]) {
    if (this._state) {
      const keys: {
        [key: number]: Model.UIDocument;
      } = this.downloadBasket.reduce((data, item) => {
        return { ...data, [item.id]: item };
      }, {});
      const oldItemHash: {
        [key: string]: boolean;
      } = {};
      this.downloadBasket.forEach((doc) => {
        if (doc.fileHash) {
          oldItemHash[doc.fileHash] = true;
        }
      });
      const newItems = list.filter((item) => {
        const canBeAdded =
          typeof keys[item.id] === 'undefined' &&
          !oldItemHash[item.fileHash || ''];
        oldItemHash[item.fileHash || ''] = true;
        return canBeAdded;
      });
      this.downloadBasket = [...this.downloadBasket, ...newItems];
      window.localStorage.setItem(
        'download_basket',
        JSON.stringify(this.downloadBasket)
      );
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: DocumentStateEnums.DocumentState_addToBasket,
          payload: list,
        });
      }
    }
  }

  public static addToBasket(list: Model.UIDocument[]) {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(undefined, dispatcher, getState).addToBasket(
        list
      );
    };
  }
  async setDocumentState(data: {
    id: number;
    stateValue: number;
    reason: string;
  }) {
    const action = this.docstatus.setDocumentState;
    if (!document) return;
    this.setLoading(action);
    try {
      await DOCAPI.changeDocState({
        docid: data.id,
        newStateId: data.stateValue,
        reasonForRejection: data.reason,
      });
      if (this.activeDocument) this.saveDocument(this.activeDocument);
      await this.fetchDocument(data.id);
      this.fetchDocumentHistory(data.id);
      this.setSuccess({ action });
    } catch (e) {
      this.setError({ action, message: e.message });
    }
  }

  public static setDocumentState(data: {
    id: number;
    stateValue: number;
    reason: string;
  }) {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(
        undefined,
        dispatcher,
        getState
      ).setDocumentState(data);
    };
  }
  async getDocumentComments(documentId: number) {
    const action = this.docstatus.getDocumentComments;
    try {
      this.setStatus({ action });
      const comments = await DOCAPI.getDocumentComments(documentId);
      // Mark fetched comments as seen if not seen
      const documentComments = comments[documentId];
      if (documentComments && documentComments.length > 0) {
        await Promise.all(
          documentComments.map(async (comment) => {
            if (!comment.seen_at && comment.id) {
              await DOCAPI.documentCommentSeen(comment.id);
            }
          })
        );
      }
      this.setActiveDocumentComments(comments);
      this.setSuccess({ action });
    } catch (error) {
      this.setError({ action, message: error.message });
    }
  }

  public static getDocumentComments(documentId: number) {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(
        undefined,
        dispatcher,
        getState
      ).getDocumentComments(documentId);
    };
  }
  setActiveDocumentComments(comments: {
    [id: number]: Model.UIDocumentComment[];
  }) {
    if (this._state) {
      if (this.activeDocument) {
        this.activeDocumentComments = comments[this.activeDocument.id];
      }
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: DocumentStateEnums.DocumentState_setActiveDocumentComments,
          payload: comments,
        });
      }
    }
  }

  public static setActiveDocumentComments(comments: {
    [id: number]: Model.UIDocumentComment[];
  }) {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(
        undefined,
        dispatcher,
        getState
      ).setActiveDocumentComments(comments);
    };
  }
  async addDocumentComment(data: Model.DocumentComment) {
    const action = this.docstatus.addDocumentComment;
    try {
      this.setStatus({ action });
      await DOCAPI.addDocumentComment(data);
      this.setSuccess({ action });
    } catch (error) {
      this.setError({ action, message: error.message });
    }
  }

  public static addDocumentComment(data: Model.DocumentComment) {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(
        undefined,
        dispatcher,
        getState
      ).addDocumentComment(data);
    };
  }
  async createInspectionDocument(request: { meta: any; data: any }) {
    DOCAPI.createInspectionDocument(request);
  }

  public static createInspectionDocument(request: { meta: any; data: any }) {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(
        undefined,
        dispatcher,
        getState
      ).createInspectionDocument(request);
    };
  }
  async togglePitemTrackedStatus(oldPitem: Model.UIDocument) {
    const action = this.docstatus.togglePitemTrackedStatus;
    this.setStatus({ action });
    // Clone the old pitem to trigger props update
    const pitem = { ...oldPitem };
    let index = -1;
    this.searchResults.forEach((d, i) => {
      if (d.id === pitem.id) {
        index = i;
      }
    });
    if (index >= 0) {
      pitem.isTracked = !pitem.isTracked;
      this.searchResults[index] = pitem;
      try {
        await API.setTrackedStatus({
          id: pitem.id,
          isTracked: pitem.isTracked,
        });
        this.setSuccess({ action });
      } catch (e) {
        this.setError({ action });
        this.searchResults[index] = oldPitem;
      }
    } else {
      this.setError({ action });
    }
  }

  public static togglePitemTrackedStatus(oldPitem: Model.UIDocument) {
    return (dispatcher: any, getState: any) => {
      return new RDocumentState(
        undefined,
        dispatcher,
        getState
      ).togglePitemTrackedStatus(oldPitem);
    };
  }
}

export const DocumentStateEnums = {
  DocumentState_redirectToId: 'DocumentState_redirectToId',
  DocumentState_networkError: 'DocumentState_networkError',
  DocumentState_metadata: 'DocumentState_metadata',
  DocumentState_docstatus: 'DocumentState_docstatus',
  DocumentState_documents: 'DocumentState_documents',
  DocumentState_documentCollection: 'DocumentState_documentCollection',
  DocumentState_searchResultCnt: 'DocumentState_searchResultCnt',
  DocumentState_searchResults: 'DocumentState_searchResults',
  DocumentState_searchResultsTree: 'DocumentState_searchResultsTree',
  DocumentState_uploadResults: 'DocumentState_uploadResults',
  DocumentState_uploadDocumentAmount: 'DocumentState_uploadDocumentAmount',
  DocumentState_searches: 'DocumentState_searches',
  DocumentState_activeSearch: 'DocumentState_activeSearch',
  DocumentState_activeSearchOrdering: 'DocumentState_activeSearchOrdering',
  DocumentState_activeDocument: 'DocumentState_activeDocument',
  DocumentState_activeDocumentHistory: 'DocumentState_activeDocumentHistory',
  DocumentState_activeDocumentComments: 'DocumentState_activeDocumentComments',
  DocumentState_isLoadingWaitingForReview:
    'DocumentState_isLoadingWaitingForReview',
  DocumentState_waitingForReview: 'DocumentState_waitingForReview',
  DocumentState_isLoadingSearchResults: 'DocumentState_isLoadingSearchResults',
  DocumentState_downloadBasket: 'DocumentState_downloadBasket',
  DocumentState_pitemTargetting: 'DocumentState_pitemTargetting',
  DocumentState_documentCollectionUpload:
    'DocumentState_documentCollectionUpload',
  DocumentState_searchRequestKeys: 'DocumentState_searchRequestKeys',
  DocumentState_noOpCnt: 'DocumentState_noOpCnt',
  DocumentState_createUUID: 'DocumentState_createUUID',
  DocumentState_noOp: 'DocumentState_noOp',
  DocumentState_setDocCollection: 'DocumentState_setDocCollection',
  DocumentState_setDocCollectionDocumentTypeId:
    'DocumentState_setDocCollectionDocumentTypeId',
  DocumentState_addToPitemTargets: 'DocumentState_addToPitemTargets',
  DocumentState_removeFromPitemTargets: 'DocumentState_removeFromPitemTargets',
  DocumentState_clearPItemTargets: 'DocumentState_clearPItemTargets',
  DocumentState_setStatus: 'DocumentState_setStatus',
  DocumentState_setLoading: 'DocumentState_setLoading',
  DocumentState_setSuccess: 'DocumentState_setSuccess',
  DocumentState_setError: 'DocumentState_setError',
  DocumentState_resetDocSnacks: 'DocumentState_resetDocSnacks',
  DocumentState_setRedirect: 'DocumentState_setRedirect',
  DocumentState_updateActiveDocument: 'DocumentState_updateActiveDocument',
  DocumentState_setActiveSearch: 'DocumentState_setActiveSearch',
  DocumentState_saveDocument: 'DocumentState_saveDocument',
  DocumentState_addToSearchResults: 'DocumentState_addToSearchResults',
  DocumentState_revertDocument: 'DocumentState_revertDocument',
  DocumentState_resetDoc: 'DocumentState_resetDoc',
  DocumentState_resetDocHistory: 'DocumentState_resetDocHistory',
  DocumentState_setSearchResults: 'DocumentState_setSearchResults',
  DocumentState_moveSearchUp: 'DocumentState_moveSearchUp',
  DocumentState_moveSearchDown: 'DocumentState_moveSearchDown',
  DocumentState_initSearch: 'DocumentState_initSearch',
  DocumentState_addSearchRequest: 'DocumentState_addSearchRequest',
  DocumentState_removeSearchRequest: 'DocumentState_removeSearchRequest',
  DocumentState_setDocumentUploadAmount:
    'DocumentState_setDocumentUploadAmount',
  DocumentState_setUploadResults: 'DocumentState_setUploadResults',
  DocumentState_addUploadResults: 'DocumentState_addUploadResults',
  DocumentState_setDocumentCollection: 'DocumentState_setDocumentCollection',
  DocumentState_resetDocState: 'DocumentState_resetDocState',
  DocumentState_clearBasket: 'DocumentState_clearBasket',
  DocumentState_removeFromBasket: 'DocumentState_removeFromBasket',
  DocumentState_addToBasket: 'DocumentState_addToBasket',
  DocumentState_setActiveDocumentComments:
    'DocumentState_setActiveDocumentComments',
};

export const DocumentStateReducer = (
  state: IDocumentState = initDocumentState(),
  action: any
) => {
  return immer.produce(state, (draft: IDocumentState) => {
    switch (action.type) {
      case DocumentStateEnums.DocumentState_redirectToId:
        new RDocumentState(draft).redirectToId = action.payload;
        break;
      case DocumentStateEnums.DocumentState_networkError:
        new RDocumentState(draft).networkError = action.payload;
        break;
      case DocumentStateEnums.DocumentState_metadata:
        new RDocumentState(draft).metadata = action.payload;
        break;
      case DocumentStateEnums.DocumentState_docstatus:
        new RDocumentState(draft).docstatus = action.payload;
        break;
      case DocumentStateEnums.DocumentState_documents:
        new RDocumentState(draft).documents = action.payload;
        break;
      case DocumentStateEnums.DocumentState_documentCollection:
        new RDocumentState(draft).documentCollection = action.payload;
        break;
      case DocumentStateEnums.DocumentState_searchResultCnt:
        new RDocumentState(draft).searchResultCnt = action.payload;
        break;
      case DocumentStateEnums.DocumentState_searchResults:
        new RDocumentState(draft).searchResults = action.payload;
        break;
      case DocumentStateEnums.DocumentState_searchResultsTree:
        new RDocumentState(draft).searchResultsTree = action.payload;
        break;
      case DocumentStateEnums.DocumentState_uploadResults:
        new RDocumentState(draft).uploadResults = action.payload;
        break;
      case DocumentStateEnums.DocumentState_uploadDocumentAmount:
        new RDocumentState(draft).uploadDocumentAmount = action.payload;
        break;
      case DocumentStateEnums.DocumentState_searches:
        new RDocumentState(draft).searches = action.payload;
        break;
      case DocumentStateEnums.DocumentState_activeSearch:
        new RDocumentState(draft).activeSearch = action.payload;
        break;
      case DocumentStateEnums.DocumentState_activeSearchOrdering:
        new RDocumentState(draft).activeSearchOrdering = action.payload;
        break;
      case DocumentStateEnums.DocumentState_activeDocument:
        new RDocumentState(draft).activeDocument = action.payload;
        break;
      case DocumentStateEnums.DocumentState_activeDocumentHistory:
        new RDocumentState(draft).activeDocumentHistory = action.payload;
        break;
      case DocumentStateEnums.DocumentState_activeDocumentComments:
        new RDocumentState(draft).activeDocumentComments = action.payload;
        break;
      case DocumentStateEnums.DocumentState_isLoadingWaitingForReview:
        new RDocumentState(draft).isLoadingWaitingForReview = action.payload;
        break;
      case DocumentStateEnums.DocumentState_waitingForReview:
        new RDocumentState(draft).waitingForReview = action.payload;
        break;
      case DocumentStateEnums.DocumentState_isLoadingSearchResults:
        new RDocumentState(draft).isLoadingSearchResults = action.payload;
        break;
      case DocumentStateEnums.DocumentState_downloadBasket:
        new RDocumentState(draft).downloadBasket = action.payload;
        break;
      case DocumentStateEnums.DocumentState_pitemTargetting:
        new RDocumentState(draft).pitemTargetting = action.payload;
        break;
      case DocumentStateEnums.DocumentState_documentCollectionUpload:
        new RDocumentState(draft).documentCollectionUpload = action.payload;
        break;
      case DocumentStateEnums.DocumentState_searchRequestKeys:
        new RDocumentState(draft).searchRequestKeys = action.payload;
        break;
      case DocumentStateEnums.DocumentState_noOpCnt:
        new RDocumentState(draft).noOpCnt = action.payload;
        break;
      case DocumentStateEnums.DocumentState_noOp:
        new RDocumentState(draft).noOp();
        break;
      case DocumentStateEnums.DocumentState_setDocCollection:
        new RDocumentState(draft).setDocCollection(action.payload);
        break;
      case DocumentStateEnums.DocumentState_setDocCollectionDocumentTypeId:
        new RDocumentState(draft).setDocCollectionDocumentTypeId(
          action.payload
        );
        break;
      case DocumentStateEnums.DocumentState_addToPitemTargets:
        new RDocumentState(draft).addToPitemTargets(action.payload);
        break;
      case DocumentStateEnums.DocumentState_removeFromPitemTargets:
        new RDocumentState(draft).removeFromPitemTargets(action.payload);
        break;
      case DocumentStateEnums.DocumentState_clearPItemTargets:
        new RDocumentState(draft).clearPItemTargets();
        break;
      case DocumentStateEnums.DocumentState_setStatus:
        new RDocumentState(draft).setStatus(action.payload);
        break;
      case DocumentStateEnums.DocumentState_setLoading:
        new RDocumentState(draft).setLoading(action.payload);
        break;
      case DocumentStateEnums.DocumentState_setSuccess:
        new RDocumentState(draft).setSuccess(action.payload);
        break;
      case DocumentStateEnums.DocumentState_setError:
        new RDocumentState(draft).setError(action.payload);
        break;
      case DocumentStateEnums.DocumentState_resetDocSnacks:
        new RDocumentState(draft).resetDocSnacks();
        break;
      case DocumentStateEnums.DocumentState_setRedirect:
        new RDocumentState(draft).setRedirect(action.payload);
        break;
      case DocumentStateEnums.DocumentState_updateActiveDocument:
        new RDocumentState(draft).updateActiveDocument(action.payload);
        break;
      case DocumentStateEnums.DocumentState_setActiveSearch:
        new RDocumentState(draft).setActiveSearch(action.payload);
        break;
      case DocumentStateEnums.DocumentState_saveDocument:
        new RDocumentState(draft).saveDocument(action.payload);
        break;
      case DocumentStateEnums.DocumentState_addToSearchResults:
        new RDocumentState(draft).addToSearchResults(action.payload);
        break;
      case DocumentStateEnums.DocumentState_revertDocument:
        new RDocumentState(draft).revertDocument(action.payload);
        break;
      case DocumentStateEnums.DocumentState_resetDoc:
        new RDocumentState(draft).resetDoc();
        break;
      case DocumentStateEnums.DocumentState_resetDocHistory:
        new RDocumentState(draft).resetDocHistory();
        break;
      case DocumentStateEnums.DocumentState_setSearchResults:
        new RDocumentState(draft).setSearchResults(action.payload);
        break;
      case DocumentStateEnums.DocumentState_moveSearchUp:
        new RDocumentState(draft).moveSearchUp(action.payload);
        break;
      case DocumentStateEnums.DocumentState_moveSearchDown:
        new RDocumentState(draft).moveSearchDown(action.payload);
        break;
      case DocumentStateEnums.DocumentState_initSearch:
        new RDocumentState(draft).initSearch(action.payload);
        break;
      case DocumentStateEnums.DocumentState_addSearchRequest:
        new RDocumentState(draft).addSearchRequest(action.payload);
        break;
      case DocumentStateEnums.DocumentState_removeSearchRequest:
        new RDocumentState(draft).removeSearchRequest(action.payload);
        break;
      case DocumentStateEnums.DocumentState_setDocumentUploadAmount:
        new RDocumentState(draft).setDocumentUploadAmount(action.payload);
        break;
      case DocumentStateEnums.DocumentState_setUploadResults:
        new RDocumentState(draft).setUploadResults(action.payload);
        break;
      case DocumentStateEnums.DocumentState_addUploadResults:
        new RDocumentState(draft).addUploadResults(action.payload);
        break;
      case DocumentStateEnums.DocumentState_setDocumentCollection:
        new RDocumentState(draft).setDocumentCollection(action.payload);
        break;
      case DocumentStateEnums.DocumentState_resetDocState:
        new RDocumentState(draft).resetDocState();
        break;
      case DocumentStateEnums.DocumentState_clearBasket:
        new RDocumentState(draft).clearBasket();
        break;
      case DocumentStateEnums.DocumentState_removeFromBasket:
        new RDocumentState(draft).removeFromBasket(action.payload);
        break;
      case DocumentStateEnums.DocumentState_addToBasket:
        new RDocumentState(draft).addToBasket(action.payload);
        break;
      case DocumentStateEnums.DocumentState_setActiveDocumentComments:
        new RDocumentState(draft).setActiveDocumentComments(action.payload);
        break;
    }
  });
};
/********************************
 * React Context API component   *
 ********************************/
export const DocumentStateContext = React.createContext<IProps>(
  initWithMethodsDocumentState()
);
export const DocumentStateConsumer = DocumentStateContext.Consumer;
let instanceCnt = 1;
export class DocumentStateProvider extends React.Component {
  public state: IDocumentState = initDocumentState();
  public lastSetState: IDocumentState;
  private __devTools: any = null;
  constructor(props: any) {
    super(props);
    this.lastSetState = this.state;
    this.noOp = this.noOp.bind(this);
    this.setDocCollection = this.setDocCollection.bind(this);
    this.setDocCollectionDocumentTypeId =
      this.setDocCollectionDocumentTypeId.bind(this);
    this.addToPitemTargets = this.addToPitemTargets.bind(this);
    this.removeFromPitemTargets = this.removeFromPitemTargets.bind(this);
    this.clearPItemTargets = this.clearPItemTargets.bind(this);
    this.findSimilarDocuments = this.findSimilarDocuments.bind(this);
    this.getPitemCntForPitemType = this.getPitemCntForPitemType.bind(this);
    this.removePitemType = this.removePitemType.bind(this);
    this.DocumentStateDispatcher = this.DocumentStateDispatcher.bind(this);
    this.removeDocumentCategory = this.removeDocumentCategory.bind(this);
    this.removePItemType = this.removePItemType.bind(this);
    this.addOrUpdatePItemType = this.addOrUpdatePItemType.bind(this);
    this.getDocCnt = this.getDocCnt.bind(this);
    this.addOrUpdateCategory = this.addOrUpdateCategory.bind(this);
    this.loadWaitingForReview = this.loadWaitingForReview.bind(this);
    this.setStatus = this.setStatus.bind(this);
    this.setLoading = this.setLoading.bind(this);
    this.setSuccess = this.setSuccess.bind(this);
    this.setError = this.setError.bind(this);
    this.resetDocSnacks = this.resetDocSnacks.bind(this);
    this.setRedirect = this.setRedirect.bind(this);
    this.updateActiveDocument = this.updateActiveDocument.bind(this);
    this.setActiveSearch = this.setActiveSearch.bind(this);
    this.saveDocument = this.saveDocument.bind(this);
    this.addToSearchResults = this.addToSearchResults.bind(this);
    this.revertDocument = this.revertDocument.bind(this);
    this.cleanRedirectURL = this.cleanRedirectURL.bind(this);
    this.saveActiveDocument = this.saveActiveDocument.bind(this);
    this.getMetadata = this.getMetadata.bind(this);
    this.resetDoc = this.resetDoc.bind(this);
    this.resetDocHistory = this.resetDocHistory.bind(this);
    this.fetchDocument = this.fetchDocument.bind(this);
    this.fetchDocumentHistory = this.fetchDocumentHistory.bind(this);
    this.repeatSearchWithOrder = this.repeatSearchWithOrder.bind(this);
    this.setSearchResults = this.setSearchResults.bind(this);
    this.loadMoreDocuments = this.loadMoreDocuments.bind(this);
    this.documentSearch = this.documentSearch.bind(this);
    this.moveSearchUp = this.moveSearchUp.bind(this);
    this.moveSearchDown = this.moveSearchDown.bind(this);
    this.initSearch = this.initSearch.bind(this);
    this.getUserSearches = this.getUserSearches.bind(this);
    this.saveUserSearches = this.saveUserSearches.bind(this);
    this.addAndSaveUserSearches = this.addAndSaveUserSearches.bind(this);
    this.removeAndSaveUserSearches = this.removeAndSaveUserSearches.bind(this);
    this.addSearchRequest = this.addSearchRequest.bind(this);
    this.removeSearchRequest = this.removeSearchRequest.bind(this);
    this.uploadDesignerDocuments = this.uploadDesignerDocuments.bind(this);
    this.setDocumentUploadAmount = this.setDocumentUploadAmount.bind(this);
    this.setUploadResults = this.setUploadResults.bind(this);
    this.addUploadResults = this.addUploadResults.bind(this);
    this.uploadDocumentCollection = this.uploadDocumentCollection.bind(this);
    this.setDocumentCollection = this.setDocumentCollection.bind(this);
    this.uploadNewDocumentRevision = this.uploadNewDocumentRevision.bind(this);
    this.uploadTargetAttachment = this.uploadTargetAttachment.bind(this);
    this.sendPrintOrder = this.sendPrintOrder.bind(this);
    this.linkToDwgDocs = this.linkToDwgDocs.bind(this);
    this.resetDocState = this.resetDocState.bind(this);
    this.clearBasket = this.clearBasket.bind(this);
    this.removeFromBasket = this.removeFromBasket.bind(this);
    this.addToBasket = this.addToBasket.bind(this);
    this.setDocumentState = this.setDocumentState.bind(this);
    this.getDocumentComments = this.getDocumentComments.bind(this);
    this.setActiveDocumentComments = this.setActiveDocumentComments.bind(this);
    this.addDocumentComment = this.addDocumentComment.bind(this);
    this.createInspectionDocument = this.createInspectionDocument.bind(this);
    this.togglePitemTrackedStatus = this.togglePitemTrackedStatus.bind(this);
    const devs = window['__REDUX_DEVTOOLS_EXTENSION__']
      ? window['__REDUX_DEVTOOLS_EXTENSION__']
      : null;
    if (devs) {
      this.__devTools = devs.connect({ name: 'DocumentState' + instanceCnt++ });
      this.__devTools.init(this.state);
      this.__devTools.subscribe((msg: any) => {
        if (msg.type === 'DISPATCH' && msg.state) {
          this.setState(JSON.parse(msg.state));
        }
      });
    }
  }
  public componentWillUnmount() {
    if (this.__devTools) {
      this.__devTools.unsubscribe();
    }
  }
  public setStateSync(state: IDocumentState) {
    this.lastSetState = state;
    this.setState(state);
  }
  noOp() {
    const nextState = immer.produce(this.state, (draft: IDocumentState) =>
      new RDocumentState(draft).noOp()
    );
    if (this.__devTools) {
      this.__devTools.send('noOp', nextState);
    }
    this.setStateSync(nextState);
  }
  setDocCollection(data: DocCollectionUpload) {
    const nextState = immer.produce(this.state, (draft: IDocumentState) =>
      new RDocumentState(draft).setDocCollection(data)
    );
    if (this.__devTools) {
      this.__devTools.send('setDocCollection', nextState);
    }
    this.setStateSync(nextState);
  }
  setDocCollectionDocumentTypeId(id: number) {
    const nextState = immer.produce(this.state, (draft: IDocumentState) =>
      new RDocumentState(draft).setDocCollectionDocumentTypeId(id)
    );
    if (this.__devTools) {
      this.__devTools.send('setDocCollectionDocumentTypeId', nextState);
    }
    this.setStateSync(nextState);
  }
  addToPitemTargets(list: Model.UIDocument[]) {
    const nextState = immer.produce(this.state, (draft: IDocumentState) =>
      new RDocumentState(draft).addToPitemTargets(list)
    );
    if (this.__devTools) {
      this.__devTools.send('addToPitemTargets', nextState);
    }
    this.setStateSync(nextState);
  }
  removeFromPitemTargets(removedItem: Model.UIDocument) {
    const nextState = immer.produce(this.state, (draft: IDocumentState) =>
      new RDocumentState(draft).removeFromPitemTargets(removedItem)
    );
    if (this.__devTools) {
      this.__devTools.send('removeFromPitemTargets', nextState);
    }
    this.setStateSync(nextState);
  }
  clearPItemTargets() {
    const nextState = immer.produce(this.state, (draft: IDocumentState) =>
      new RDocumentState(draft).clearPItemTargets()
    );
    if (this.__devTools) {
      this.__devTools.send('clearPItemTargets', nextState);
    }
    this.setStateSync(nextState);
  }
  async findSimilarDocuments(doc_id: number) {
    return new RDocumentState(
      undefined,
      (action: any) => {
        const nextState = DocumentStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ DocumentState: this.lastSetState })
    ).findSimilarDocuments(doc_id);
  }
  async getPitemCntForPitemType(pitem_type_id: number) {
    return new RDocumentState(
      undefined,
      (action: any) => {
        const nextState = DocumentStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ DocumentState: this.lastSetState })
    ).getPitemCntForPitemType(pitem_type_id);
  }
  async removePitemType(pitem_type_id: number) {
    return new RDocumentState(
      undefined,
      (action: any) => {
        const nextState = DocumentStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ DocumentState: this.lastSetState })
    ).removePitemType(pitem_type_id);
  }
  /**
   * @dispatch true
   */
  async DocumentStateDispatcher(action: any) {
    return new RDocumentState(
      undefined,
      (action: any) => {
        const nextState = DocumentStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ DocumentState: this.lastSetState })
    ).DocumentStateDispatcher(action);
  }
  async removeDocumentCategory(id: number) {
    return new RDocumentState(
      undefined,
      (action: any) => {
        const nextState = DocumentStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ DocumentState: this.lastSetState })
    ).removeDocumentCategory(id);
  }
  async removePItemType(id: number) {
    return new RDocumentState(
      undefined,
      (action: any) => {
        const nextState = DocumentStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ DocumentState: this.lastSetState })
    ).removePItemType(id);
  }
  async addOrUpdatePItemType(opions: Partial<Model.DocumentCategory>) {
    return new RDocumentState(
      undefined,
      (action: any) => {
        const nextState = DocumentStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ DocumentState: this.lastSetState })
    ).addOrUpdatePItemType(opions);
  }
  async getDocCnt(id: number) {
    return new RDocumentState(
      undefined,
      (action: any) => {
        const nextState = DocumentStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ DocumentState: this.lastSetState })
    ).getDocCnt(id);
  }
  async addOrUpdateCategory(opions: Partial<Model.DocumentCategory>) {
    return new RDocumentState(
      undefined,
      (action: any) => {
        const nextState = DocumentStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ DocumentState: this.lastSetState })
    ).addOrUpdateCategory(opions);
  }
  async loadWaitingForReview() {
    return new RDocumentState(
      undefined,
      (action: any) => {
        const nextState = DocumentStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ DocumentState: this.lastSetState })
    ).loadWaitingForReview();
  }
  setStatus(params: {
    action: ActionStatusDetail;
    snack?: SnackDetails;
    finished?: boolean;
  }) {
    const nextState = immer.produce(this.state, (draft: IDocumentState) =>
      new RDocumentState(draft).setStatus(params)
    );
    if (this.__devTools) {
      this.__devTools.send('setStatus', nextState);
    }
    this.setStateSync(nextState);
  }
  setLoading(action: ActionStatusDetail) {
    const nextState = immer.produce(this.state, (draft: IDocumentState) =>
      new RDocumentState(draft).setLoading(action)
    );
    if (this.__devTools) {
      this.__devTools.send('setLoading', nextState);
    }
    this.setStateSync(nextState);
  }
  setSuccess(params: {
    action: ActionStatusDetail;
    translation?: TranslationDetails;
  }) {
    const nextState = immer.produce(this.state, (draft: IDocumentState) =>
      new RDocumentState(draft).setSuccess(params)
    );
    if (this.__devTools) {
      this.__devTools.send('setSuccess', nextState);
    }
    this.setStateSync(nextState);
  }
  setError(params: {
    action: ActionStatusDetail;
    message?: string;
    translation?: TranslationDetails;
  }) {
    const nextState = immer.produce(this.state, (draft: IDocumentState) =>
      new RDocumentState(draft).setError(params)
    );
    if (this.__devTools) {
      this.__devTools.send('setError', nextState);
    }
    this.setStateSync(nextState);
  }
  resetDocSnacks() {
    const nextState = immer.produce(this.state, (draft: IDocumentState) =>
      new RDocumentState(draft).resetDocSnacks()
    );
    if (this.__devTools) {
      this.__devTools.send('resetDocSnacks', nextState);
    }
    this.setStateSync(nextState);
  }
  setRedirect(id: number) {
    const nextState = immer.produce(this.state, (draft: IDocumentState) =>
      new RDocumentState(draft).setRedirect(id)
    );
    if (this.__devTools) {
      this.__devTools.send('setRedirect', nextState);
    }
    this.setStateSync(nextState);
  }
  updateActiveDocument(newDoc: Model.UIDocument) {
    const nextState = immer.produce(this.state, (draft: IDocumentState) =>
      new RDocumentState(draft).updateActiveDocument(newDoc)
    );
    if (this.__devTools) {
      this.__devTools.send('updateActiveDocument', nextState);
    }
    this.setStateSync(nextState);
  }
  setActiveSearch(search: Model.SearchRequest) {
    const nextState = immer.produce(this.state, (draft: IDocumentState) =>
      new RDocumentState(draft).setActiveSearch(search)
    );
    if (this.__devTools) {
      this.__devTools.send('setActiveSearch', nextState);
    }
    this.setStateSync(nextState);
  }
  saveDocument(doc: Model.UIDocument) {
    const nextState = immer.produce(this.state, (draft: IDocumentState) =>
      new RDocumentState(draft).saveDocument(doc)
    );
    if (this.__devTools) {
      this.__devTools.send('saveDocument', nextState);
    }
    this.setStateSync(nextState);
  }
  addToSearchResults(doc: Model.UIDocument) {
    const nextState = immer.produce(this.state, (draft: IDocumentState) =>
      new RDocumentState(draft).addToSearchResults(doc)
    );
    if (this.__devTools) {
      this.__devTools.send('addToSearchResults', nextState);
    }
    this.setStateSync(nextState);
  }
  revertDocument(doc: Model.UIDocument) {
    const nextState = immer.produce(this.state, (draft: IDocumentState) =>
      new RDocumentState(draft).revertDocument(doc)
    );
    if (this.__devTools) {
      this.__devTools.send('revertDocument', nextState);
    }
    this.setStateSync(nextState);
  }
  async cleanRedirectURL() {
    return new RDocumentState(
      undefined,
      (action: any) => {
        const nextState = DocumentStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ DocumentState: this.lastSetState })
    ).cleanRedirectURL();
  }
  async saveActiveDocument() {
    return new RDocumentState(
      undefined,
      (action: any) => {
        const nextState = DocumentStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ DocumentState: this.lastSetState })
    ).saveActiveDocument();
  }
  async getMetadata() {
    return new RDocumentState(
      undefined,
      (action: any) => {
        const nextState = DocumentStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ DocumentState: this.lastSetState })
    ).getMetadata();
  }
  resetDoc() {
    const nextState = immer.produce(this.state, (draft: IDocumentState) =>
      new RDocumentState(draft).resetDoc()
    );
    if (this.__devTools) {
      this.__devTools.send('resetDoc', nextState);
    }
    this.setStateSync(nextState);
  }
  resetDocHistory() {
    const nextState = immer.produce(this.state, (draft: IDocumentState) =>
      new RDocumentState(draft).resetDocHistory()
    );
    if (this.__devTools) {
      this.__devTools.send('resetDocHistory', nextState);
    }
    this.setStateSync(nextState);
  }
  async fetchDocument(id: number) {
    return new RDocumentState(
      undefined,
      (action: any) => {
        const nextState = DocumentStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ DocumentState: this.lastSetState })
    ).fetchDocument(id);
  }
  async fetchDocumentHistory(id: number) {
    return new RDocumentState(
      undefined,
      (action: any) => {
        const nextState = DocumentStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ DocumentState: this.lastSetState })
    ).fetchDocumentHistory(id);
  }
  async repeatSearchWithOrder(order: Model.SearchOrder) {
    return new RDocumentState(
      undefined,
      (action: any) => {
        const nextState = DocumentStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ DocumentState: this.lastSetState })
    ).repeatSearchWithOrder(order);
  }
  setSearchResults(list: Model.UIDocument[]) {
    const nextState = immer.produce(this.state, (draft: IDocumentState) =>
      new RDocumentState(draft).setSearchResults(list)
    );
    if (this.__devTools) {
      this.__devTools.send('setSearchResults', nextState);
    }
    this.setStateSync(nextState);
  }
  async loadMoreDocuments(params: Model.SearchRequest) {
    return new RDocumentState(
      undefined,
      (action: any) => {
        const nextState = DocumentStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ DocumentState: this.lastSetState })
    ).loadMoreDocuments(params);
  }
  async documentSearch(params: Model.SearchRequest) {
    return new RDocumentState(
      undefined,
      (action: any) => {
        const nextState = DocumentStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ DocumentState: this.lastSetState })
    ).documentSearch(params);
  }
  moveSearchUp(name: string) {
    const nextState = immer.produce(this.state, (draft: IDocumentState) =>
      new RDocumentState(draft).moveSearchUp(name)
    );
    if (this.__devTools) {
      this.__devTools.send('moveSearchUp', nextState);
    }
    this.setStateSync(nextState);
  }
  moveSearchDown(name: string) {
    const nextState = immer.produce(this.state, (draft: IDocumentState) =>
      new RDocumentState(draft).moveSearchDown(name)
    );
    if (this.__devTools) {
      this.__devTools.send('moveSearchDown', nextState);
    }
    this.setStateSync(nextState);
  }
  initSearch(searches: Model.UserSearches) {
    const nextState = immer.produce(this.state, (draft: IDocumentState) =>
      new RDocumentState(draft).initSearch(searches)
    );
    if (this.__devTools) {
      this.__devTools.send('initSearch', nextState);
    }
    this.setStateSync(nextState);
  }
  async getUserSearches() {
    return new RDocumentState(
      undefined,
      (action: any) => {
        const nextState = DocumentStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ DocumentState: this.lastSetState })
    ).getUserSearches();
  }
  async saveUserSearches() {
    return new RDocumentState(
      undefined,
      (action: any) => {
        const nextState = DocumentStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ DocumentState: this.lastSetState })
    ).saveUserSearches();
  }
  async addAndSaveUserSearches(newSearch: Model.SearchRequest) {
    return new RDocumentState(
      undefined,
      (action: any) => {
        const nextState = DocumentStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ DocumentState: this.lastSetState })
    ).addAndSaveUserSearches(newSearch);
  }
  async removeAndSaveUserSearches(removedSearch: string) {
    return new RDocumentState(
      undefined,
      (action: any) => {
        const nextState = DocumentStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ DocumentState: this.lastSetState })
    ).removeAndSaveUserSearches(removedSearch);
  }
  addSearchRequest(newSearch: Model.SearchRequest) {
    const nextState = immer.produce(this.state, (draft: IDocumentState) =>
      new RDocumentState(draft).addSearchRequest(newSearch)
    );
    if (this.__devTools) {
      this.__devTools.send('addSearchRequest', nextState);
    }
    this.setStateSync(nextState);
  }
  removeSearchRequest(removedSearch: string) {
    const nextState = immer.produce(this.state, (draft: IDocumentState) =>
      new RDocumentState(draft).removeSearchRequest(removedSearch)
    );
    if (this.__devTools) {
      this.__devTools.send('removeSearchRequest', nextState);
    }
    this.setStateSync(nextState);
  }
  async uploadDesignerDocuments(data: Model.IUploadState) {
    return new RDocumentState(
      undefined,
      (action: any) => {
        const nextState = DocumentStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ DocumentState: this.lastSetState })
    ).uploadDesignerDocuments(data);
  }
  setDocumentUploadAmount(amount: number) {
    const nextState = immer.produce(this.state, (draft: IDocumentState) =>
      new RDocumentState(draft).setDocumentUploadAmount(amount)
    );
    if (this.__devTools) {
      this.__devTools.send('setDocumentUploadAmount', nextState);
    }
    this.setStateSync(nextState);
  }
  setUploadResults(results: Model.UIDocument[]) {
    const nextState = immer.produce(this.state, (draft: IDocumentState) =>
      new RDocumentState(draft).setUploadResults(results)
    );
    if (this.__devTools) {
      this.__devTools.send('setUploadResults', nextState);
    }
    this.setStateSync(nextState);
  }
  addUploadResults(results: Model.UIDocument[]) {
    const nextState = immer.produce(this.state, (draft: IDocumentState) =>
      new RDocumentState(draft).addUploadResults(results)
    );
    if (this.__devTools) {
      this.__devTools.send('addUploadResults', nextState);
    }
    this.setStateSync(nextState);
  }
  async uploadDocumentCollection(file: File) {
    return new RDocumentState(
      undefined,
      (action: any) => {
        const nextState = DocumentStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ DocumentState: this.lastSetState })
    ).uploadDocumentCollection(file);
  }
  setDocumentCollection(collection: Model.DocumentCollectionResults) {
    const nextState = immer.produce(this.state, (draft: IDocumentState) =>
      new RDocumentState(draft).setDocumentCollection(collection)
    );
    if (this.__devTools) {
      this.__devTools.send('setDocumentCollection', nextState);
    }
    this.setStateSync(nextState);
  }
  async uploadNewDocumentRevision(file: File) {
    return new RDocumentState(
      undefined,
      (action: any) => {
        const nextState = DocumentStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ DocumentState: this.lastSetState })
    ).uploadNewDocumentRevision(file);
  }
  async uploadTargetAttachment(data: {
    files: File[];
    categoryId: number;
    subject: string;
  }) {
    return new RDocumentState(
      undefined,
      (action: any) => {
        const nextState = DocumentStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ DocumentState: this.lastSetState })
    ).uploadTargetAttachment(data);
  }
  async sendPrintOrder(data: Model.PrintOrder) {
    return new RDocumentState(
      undefined,
      (action: any) => {
        const nextState = DocumentStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ DocumentState: this.lastSetState })
    ).sendPrintOrder(data);
  }
  async linkToDwgDocs(data: Model.UpdateDWG) {
    return new RDocumentState(
      undefined,
      (action: any) => {
        const nextState = DocumentStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ DocumentState: this.lastSetState })
    ).linkToDwgDocs(data);
  }
  resetDocState() {
    const nextState = immer.produce(this.state, (draft: IDocumentState) =>
      new RDocumentState(draft).resetDocState()
    );
    if (this.__devTools) {
      this.__devTools.send('resetDocState', nextState);
    }
    this.setStateSync(nextState);
  }
  clearBasket() {
    const nextState = immer.produce(this.state, (draft: IDocumentState) =>
      new RDocumentState(draft).clearBasket()
    );
    if (this.__devTools) {
      this.__devTools.send('clearBasket', nextState);
    }
    this.setStateSync(nextState);
  }
  removeFromBasket(item: Model.UIDocument) {
    const nextState = immer.produce(this.state, (draft: IDocumentState) =>
      new RDocumentState(draft).removeFromBasket(item)
    );
    if (this.__devTools) {
      this.__devTools.send('removeFromBasket', nextState);
    }
    this.setStateSync(nextState);
  }
  addToBasket(list: Model.UIDocument[]) {
    const nextState = immer.produce(this.state, (draft: IDocumentState) =>
      new RDocumentState(draft).addToBasket(list)
    );
    if (this.__devTools) {
      this.__devTools.send('addToBasket', nextState);
    }
    this.setStateSync(nextState);
  }
  async setDocumentState(data: {
    id: number;
    stateValue: number;
    reason: string;
  }) {
    return new RDocumentState(
      undefined,
      (action: any) => {
        const nextState = DocumentStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ DocumentState: this.lastSetState })
    ).setDocumentState(data);
  }
  async getDocumentComments(documentId: number) {
    return new RDocumentState(
      undefined,
      (action: any) => {
        const nextState = DocumentStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ DocumentState: this.lastSetState })
    ).getDocumentComments(documentId);
  }
  setActiveDocumentComments(comments: {
    [id: number]: Model.UIDocumentComment[];
  }) {
    const nextState = immer.produce(this.state, (draft: IDocumentState) =>
      new RDocumentState(draft).setActiveDocumentComments(comments)
    );
    if (this.__devTools) {
      this.__devTools.send('setActiveDocumentComments', nextState);
    }
    this.setStateSync(nextState);
  }
  async addDocumentComment(data: Model.DocumentComment) {
    return new RDocumentState(
      undefined,
      (action: any) => {
        const nextState = DocumentStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ DocumentState: this.lastSetState })
    ).addDocumentComment(data);
  }
  async createInspectionDocument(request: { meta: any; data: any }) {
    return new RDocumentState(
      undefined,
      (action: any) => {
        const nextState = DocumentStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ DocumentState: this.lastSetState })
    ).createInspectionDocument(request);
  }
  async togglePitemTrackedStatus(oldPitem: Model.UIDocument) {
    return new RDocumentState(
      undefined,
      (action: any) => {
        const nextState = DocumentStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ DocumentState: this.lastSetState })
    ).togglePitemTrackedStatus(oldPitem);
  }
  public render() {
    return (
      <DocumentStateContext.Provider
        value={{
          ...this.state,
          noOp: this.noOp,
          setDocCollection: this.setDocCollection,
          setDocCollectionDocumentTypeId: this.setDocCollectionDocumentTypeId,
          addToPitemTargets: this.addToPitemTargets,
          removeFromPitemTargets: this.removeFromPitemTargets,
          clearPItemTargets: this.clearPItemTargets,
          findSimilarDocuments: this.findSimilarDocuments,
          getPitemCntForPitemType: this.getPitemCntForPitemType,
          removePitemType: this.removePitemType,
          DocumentStateDispatcher: this.DocumentStateDispatcher,
          removeDocumentCategory: this.removeDocumentCategory,
          removePItemType: this.removePItemType,
          addOrUpdatePItemType: this.addOrUpdatePItemType,
          getDocCnt: this.getDocCnt,
          addOrUpdateCategory: this.addOrUpdateCategory,
          loadWaitingForReview: this.loadWaitingForReview,
          setStatus: this.setStatus,
          setLoading: this.setLoading,
          setSuccess: this.setSuccess,
          setError: this.setError,
          resetDocSnacks: this.resetDocSnacks,
          setRedirect: this.setRedirect,
          updateActiveDocument: this.updateActiveDocument,
          setActiveSearch: this.setActiveSearch,
          saveDocument: this.saveDocument,
          addToSearchResults: this.addToSearchResults,
          revertDocument: this.revertDocument,
          cleanRedirectURL: this.cleanRedirectURL,
          saveActiveDocument: this.saveActiveDocument,
          getMetadata: this.getMetadata,
          resetDoc: this.resetDoc,
          resetDocHistory: this.resetDocHistory,
          fetchDocument: this.fetchDocument,
          fetchDocumentHistory: this.fetchDocumentHistory,
          repeatSearchWithOrder: this.repeatSearchWithOrder,
          setSearchResults: this.setSearchResults,
          loadMoreDocuments: this.loadMoreDocuments,
          documentSearch: this.documentSearch,
          moveSearchUp: this.moveSearchUp,
          moveSearchDown: this.moveSearchDown,
          initSearch: this.initSearch,
          getUserSearches: this.getUserSearches,
          saveUserSearches: this.saveUserSearches,
          addAndSaveUserSearches: this.addAndSaveUserSearches,
          removeAndSaveUserSearches: this.removeAndSaveUserSearches,
          addSearchRequest: this.addSearchRequest,
          removeSearchRequest: this.removeSearchRequest,
          uploadDesignerDocuments: this.uploadDesignerDocuments,
          setDocumentUploadAmount: this.setDocumentUploadAmount,
          setUploadResults: this.setUploadResults,
          addUploadResults: this.addUploadResults,
          uploadDocumentCollection: this.uploadDocumentCollection,
          setDocumentCollection: this.setDocumentCollection,
          uploadNewDocumentRevision: this.uploadNewDocumentRevision,
          uploadTargetAttachment: this.uploadTargetAttachment,
          sendPrintOrder: this.sendPrintOrder,
          linkToDwgDocs: this.linkToDwgDocs,
          resetDocState: this.resetDocState,
          clearBasket: this.clearBasket,
          removeFromBasket: this.removeFromBasket,
          addToBasket: this.addToBasket,
          setDocumentState: this.setDocumentState,
          getDocumentComments: this.getDocumentComments,
          setActiveDocumentComments: this.setActiveDocumentComments,
          addDocumentComment: this.addDocumentComment,
          createInspectionDocument: this.createInspectionDocument,
          togglePitemTrackedStatus: this.togglePitemTrackedStatus,
        }}
      >
        {' '}
        {this.props.children}
      </DocumentStateContext.Provider>
    );
  }
}
