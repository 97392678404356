import * as React from 'react';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import { TextFieldWithAcceptReject } from './TextField';
import * as doc from '../../state/reducers/DocumentState';
import * as appState from '../../state/reducers/ApplicationState';
import { errorMessage, successMessage } from './Notifier';

import * as schema from '../../../../shared/src/model/projectSchema';

import axios from 'axios';
import { DocumentService } from '../../services/document/src/frontend/api';
const DOCAPI = DocumentService(axios);

interface IDocumentRejectState {
  action: 'reject' | 'download' | 'dwg' | undefined;
}
interface IDocumentRejectProps extends doc.IProps, appState.IProps {
  activeAction?: schema.action;
  documentIds: number[];
  onUpdate?: (didCancel: boolean) => void;
}
type ToolbarProps = IDocumentRejectProps & InjectedIntlProps;

class DocumentReject extends React.Component<
  ToolbarProps,
  IDocumentRejectState
> {
  constructor(props: ToolbarProps) {
    super(props);
    this.state = { action: undefined };
  }
  public render() {
    const t = this.props.intl;
    return (
      <div>
        <TextFieldWithAcceptReject
          label={t.formatMessage({ id: 'documentMeta.reasonForReject' })}
          onAccept={async rejectReason => {
            try {
              if (rejectReason.trim().length === 0) {
                errorMessage(
                  t.formatMessage({ id: 'documentMeta.reasonForReject' })
                );
                return;
              }
              const act = this.props.activeAction;
              if (!act) {
                this.setState({ action: undefined });
                return;
              }
              await DOCAPI.docActionEvent({
                documentIds: this.props.documentIds,
                actionId: act.id,
                reasonForRejection: rejectReason,
              });
              if (this.props.onUpdate) {
                this.props.onUpdate(false);
              }
              successMessage(t.formatMessage({ id: 'documentMeta.REJECTED' }));
            } catch (e) {
              errorMessage(e);
            }
          }}
          onReject={() => {
            if (this.props.onUpdate) {
              this.props.onUpdate(true);
            }
          }}
        />
      </div>
    );
  }
}

export default appState.StateConnector(
  doc.StateConnector(injectIntl(DocumentReject))
);
