import * as React from 'react';
import { useState } from 'react';
import { Paper, Tab, Tabs } from '@material-ui/core';
import { useFormatMessage } from '../../../utils/translateHook';
import { ItemImport } from './ItemImport';
import PhaseContractBuildings from './PhaseContractBuildings';
import { Route } from 'react-router';
import useRouter from 'utils/routerHook';
import DeleteOldItems from './DeleteOldItems';
import { RoomImport } from './RoomImport';
import { DeviceLists } from './DeviceLists';
import Buildings from './Buildings';

export enum PItemPages {
  TargetImport = 0,
  PhaseContractBuilding,
}

export default function MainView() {
  const t = useFormatMessage();
  const router = useRouter();

  // When the view is opened, '/admin-tools/item-registry/' route triggers some errors
  // as it's not one of the sub tabs. 'false' here will set the Tabs component to state
  // where none of the Tabs is selected
  const currentTab =
    router.location.pathname === '/admin-tools/item-registry'
      ? false
      : router.location.pathname;
  const [activeTab, setActiveTab] = useState(currentTab);

  return (
    <Paper square={true}>
      <Tabs
        indicatorColor="primary"
        value={activeTab}
        textColor="primary"
        onChange={(e, newValue) => {
          setActiveTab(newValue);
          router.history.push(newValue);
        }}
      >
        <Tab
          label={t('adminTools.itemRegistry')}
          value="/admin-tools/item-registry/items"
        />
        <Tab
          label={t('adminTools.building')}
          value="/admin-tools/item-registry/buildings"
        />
        <Tab
          label={t('adminTools.roomRegistry')}
          value="/admin-tools/item-registry/rooms"
        />
        <Tab
          label={t('adminTools.phaseContractBuilding')}
          value="/admin-tools/item-registry/phases"
        />
        <Tab
          label={t('adminTools.deleteItems')}
          value="/admin-tools/item-registry/delete"
        />
        <Tab
          label={t('adminTools.deviceLists')}
          value="/admin-tools/item-registry/device-lists"
        />
      </Tabs>
      <Route path="/admin-tools/item-registry/items" component={ItemImport} />
      <Route path="/admin-tools/item-registry/rooms" component={RoomImport} />
      <Route
        path="/admin-tools/item-registry/buildings"
        component={Buildings}
      />
      <Route
        path="/admin-tools/item-registry/phases"
        component={PhaseContractBuildings}
      />
      <Route
        path="/admin-tools/item-registry/delete"
        component={DeleteOldItems}
      />
      <Route
        path="/admin-tools/item-registry/device-lists"
        component={DeviceLists}
      />
    </Paper>
  );
}
