import {
  GenericProp,
  PropertyCollection,
  GenericPropTypes,
} from '../../../shared/src/model';
import * as _ from 'lodash';
import { useState, useEffect } from 'react';
import { errorMessage } from 'components/Common/Notifier';
import {
  fetchPropertyCollection,
  CollectionFetchParams,
  ClassNames,
  updateProperties,
} from './batch';
import { notEmpty } from 'utils';

export const defaultValue = <T extends GenericPropTypes>(
  data: GenericProp<T>
) => (data.params && data.params.defaultValue ? data.params.defaultValue : '-');

export const defaultValueStr = <T extends GenericPropTypes>(
  data: GenericProp<T>
): string => `${defaultValue(data)}`;

export const propTranslateString = (
  data: GenericProp<any>,
  addition?: string
): string =>
  `generics.${data.className}.${data.field}${addition ? `.${addition}` : ''}`;

type BooleanProp = GenericProp<'boolean'>;
export const flippedBooleanProperty = (prop: BooleanProp): BooleanProp => ({
  ...prop,
  value: !prop.value,
});

export const getIdFromProps = (props: PropertyCollection<any>) => {
  return _.compact(_.values(props).map((prop) => prop.id)).pop();
};

export const getClassFromProps = (props: PropertyCollection<any>) => {
  return _.compact(_.values(props).map((prop) => prop.className)).pop();
};

export const getCollectionParamsKeys = <T extends ClassNames>(
  list?: CollectionFetchParams<T> | string[]
): string[] => {
  if (!list) return [];
  return (list as Array<string | string[]>).map((value) =>
    typeof value === 'string' ? value : (value[0] as string)
  );
};

export const getCollectionKeys = (list?: PropertyCollection<any>): string[] => {
  if (!list) return [];
  return Object.keys(list);
};

export const getPropertyByFieldSafe = (
  props: PropertyCollection<any>,
  field: string,
  fallback?: GenericProp<any>
) => _.get(props, field, fallback ? fallback : undefined);

export const getPropertiesByFields = (
  props: PropertyCollection<any>,
  ...fields: string[]
): Array<GenericProp<any> | undefined> =>
  fields.map((field) => getPropertyByFieldSafe(props, field));

export const orderFieldsWithPriority = (
  props: PropertyCollection<any>,
  priorityList: string[]
) => {
  const ordering = _.fromPairs(priorityList.map((val, idx) => [val, idx + 1]));
  return _.sortBy(_.keys(props), [
    (key) => ordering[key] || ordering.length + 1,
  ]);
};

type UseStateValues<T> = [
  T | undefined,
  (prop: GenericProp<any>) => void,
  (
    props?: PropertyCollection<any> | Array<PropertyCollection<any>>
  ) => Promise<void>
];

type UseMultipleStateValues<T> = [
  T[],
  (props: Array<PropertyCollection<any>>) => void
];

export const usePropertyCollection = <T extends ClassNames>(
  id: number,
  className: T,
  fields?: CollectionFetchParams<T>,
  customCallback?: (props: PropertyCollection<any>) => void
): UseStateValues<PropertyCollection<any>> => {
  const [properties, setProperties] = useState<PropertyCollection<any>>();
  const [editedProperties, setEditedProperties] = useState<{
    [key: string]: any;
  }>({});
  useEffect(() => {
    (async () => {
      try {
        const result = await fetchPropertyCollection({
          className,
          fields,
          id,
        });
        setProperties(result || ({} as PropertyCollection<any>));
        if (customCallback) customCallback(result);
      } catch (e) {
        errorMessage(e);
        setProperties({} as PropertyCollection<any>);
      }
    })();
  }, [id, className]);

  const setProperty = (prop: GenericProp<any>) => {
    setEditedProperties({ ...editedProperties, [prop.field]: prop });
    setProperties({ ...properties, [prop.field]: prop });
  };

  const saveProperties = async (
    props?: PropertyCollection<any> | Array<PropertyCollection<any>>
  ) => {
    const [result] = await updateProperties(editedProperties);
    setProperties({ ...properties, ...result });
  };

  return [properties, setProperty, saveProperties];
};

export const usePropertyCollections = <T extends ClassNames>(
  ids: number[],
  className: T,
  fields?: CollectionFetchParams<T>,
  customCallback?: (props: Array<PropertyCollection<any>>) => void
): UseMultipleStateValues<PropertyCollection<any>> => {
  const [properties, setProperties] = useState<Array<PropertyCollection<any>>>(
    []
  );
  const hashKey =
    ids.join(',') + ':' + className + ':' + JSON.stringify(fields);
  useEffect(() => {
    (async () => {
      try {
        const cleanIds = ids.filter(notEmpty);
        if (cleanIds.length === 0) {
          // in case of empty resultset
          setProperties([]);
          return;
        }
        const result = await Promise.all(
          cleanIds.map(
            async (id) =>
              await fetchPropertyCollection({ className, fields, id })
          )
        );
        setProperties(result);
        if (customCallback) customCallback(result);
      } catch (e) {
        errorMessage(e);
      }
    })();
  }, [hashKey]);

  return [properties, setProperties];
};
