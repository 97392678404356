// getPrivilegesForRole
import * as React from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
// import * as schema from '../../../../shared/src/model/projectSchema';
import * as Model from '../../../../shared/src/model/index';
import * as adminState from '../../state/reducers/AdminState';

import {
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  Chip,
  ExpansionPanelDetails,
  Divider,
} from '@material-ui/core';
// import PermIdentity from '@material-ui/icons/PermIdentity';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Group from '@material-ui/icons/Group';
import PrivilegeCard from './PrivilegeCard';

const basicStyles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    icon: {
      verticalAlign: 'bottom',
      height: 20,
      width: 20,
    },
    details: {
      alignItems: 'center',
    },
    column1: {
      flexBasis: '30%',
    },
    column2: {
      flexBasis: '60%',
    },
    column3: {
      flexBasis: '100%',
    },
    helper: {
      borderLeft: `2px solid ${theme.palette.divider}`,
      padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    },
    link: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    chip: {
      margin: theme.spacing.unit / 2,
      paddingLeft: '4px',
    },
  });

export interface PanelProps extends adminState.IProps {
  classes?: any;
  privilege: Model.UIUserRolePrivilege;
  index: number;
  expanded?: boolean;
  onClick?: (idx: number) => void;
  onRemoveRole?: (roleId: number) => void;
}

type PermissionPanelProps = PanelProps;

class PermissionPanel extends React.Component<PermissionPanelProps> {
  componentDidMount() {
    this.props.getAvailableActions();
  }

  render() {
    const { privilege } = this.props;
    const privilegeTargetName = privilege.aitem
      ? privilege.aitem.name
      : privilege.pitem
      ? privilege.pitem.name
      : 'N/A';
    return (
      <div className={`${this.props.classes.root}`}>
        <ExpansionPanel expanded={this.props.expanded || false}>
          <ExpansionPanelSummary
            onClick={() =>
              this.props.onClick && this.props.onClick(this.props.index)
            }
            expandIcon={<ExpandMoreIcon />}
          >
            <div className={`${this.props.classes.column1}`}>
              <Typography variant="h6">{privilegeTargetName}</Typography>
            </div>
            <div className={`${this.props.classes.column2}`}>
              {privilege.roles.map(role => (
                <Chip
                  key={role.id}
                  icon={<Group />}
                  label={role.name}
                  clickable={false}
                  className={`${this.props.classes.chip}`}
                  color="primary"
                />
              ))}
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={`${this.props.classes.details}`}>
            <div className={`${this.props.classes.column3}`}>
              {privilege.roles.map(role => (
                <PrivilegeCard
                  key={`privilegeCard_${role.id}`}
                  role={role}
                  onRemoveRoleFromUser={roleId =>
                    this.props.onRemoveRole && this.props.onRemoveRole(roleId)
                  }
                  metadata={this.props.adminSearch.metadata}
                  actions={this.props.actions}
                />
              ))}
            </div>
          </ExpansionPanelDetails>

          <Divider />
          {/* <ExpansionPanelActions>
            <Button size="small">Cancel</Button>
            <Button size="small" color="primary">
              Save
            </Button>
          </ExpansionPanelActions> */}
        </ExpansionPanel>
      </div>
    );
  }
}

// export const ToolbarButton = withStyles(styleWithMargin)(ActionButton);

export default withStyles(basicStyles)(
  adminState.StateConnector(PermissionPanel)
);
