import * as React from 'react';
import Dropzone from 'react-dropzone';
import { CloudUpload, Clear } from '@material-ui/icons/';
import { FormattedMessage } from 'react-intl';
import * as _ from 'lodash';
import { Button } from '@material-ui/core';
import { errorMessage } from '../Common/Notifier';
import { InjectedIntlProps, injectIntl } from 'react-intl';

export interface FileProps extends InjectedIntlProps {
  id: string;
  label: string;
  single?: boolean;
  files?: File[];
  onChange?: (files: File[]) => void;
  accept?: string;
  style?: React.CSSProperties;
  fileListRenderer?: (files: File[]) => JSX.Element;
}

interface State {
  files: File[] | null;
}

class FileInput extends React.Component<FileProps, State> {
  constructor(props: FileProps) {
    super(props);
    this.state = {
      files: null,
    };
  }

  componentDidUpdate(prevProps: FileProps) {
    if (this.props.files !== prevProps.files) {
      this.setState({
        files: this.props.files || null,
      });
    }
  }

  onRemoveFromList = (file: File) => {
    if (this.state.files) {
      const newFileList = this.state.files.filter(f => f.name !== file.name);
      this.setState({
        files: newFileList,
      });
      if (this.props.onChange) {
        this.props.onChange(newFileList);
      }
    }
  };

  fileListRenderer = (files: File[]) => {
    return (
      <ul className="uploadFileList">
        {Array.prototype.map.call(files, (file: File, i: number) => (
          <li key={i} title={file.name}>
            <Button
              className="remove"
              onClick={() => this.onRemoveFromList(file)}
              size="small"
            >
              <Clear style={{ fontSize: '20px' }} />
            </Button>
            <span className="filename">{file.name}</span>
          </li>
        ))}
      </ul>
    );
  };

  handleChange = (files: File[] | null) => {
    if (files != null && this.props.onChange) {
      const existingFiles = (!this.props.single && this.state.files) || [];
      this.setState(
        {
          files: _.uniqBy([...existingFiles, ...files], file => {
            return file.name;
          }),
        },
        () => {
          if (this.props.onChange) {
            this.props.onChange(this.state.files || []);
          }
        }
      );
    }
  };

  onDropRejected = () => {
    const t = (id: string) => this.props.intl.formatMessage({ id });
    errorMessage(t('error.fileSizeLimitExceeded'));
  };

  render() {
    const { label, style } = this.props;

    return (
      <div className="input-file">
        <Dropzone
          className="dropzone"
          onDrop={this.handleChange}
          style={style}
          multiple={!this.props.single}
          maxSize={52428800} // 50MB
          onDropRejected={this.onDropRejected}
        >
          {this.state.files ? (
            <CloudUpload
              style={{
                width: '70%',
                height: '80%',
              }}
              color={'primary'}
            />
          ) : (
            <CloudUpload
              style={{
                width: '70%',
                height: '80%',
              }}
              color={'primary'}
            />
          )}

          <p className="labeltext">
            {this.state.files && this.state.files.length > 0 ? (
              <FormattedMessage
                id={'upload.selectedDocumentAmount'}
                values={{ amount: this.state.files.length }}
              />
            ) : (
              label
            )}
          </p>
        </Dropzone>
        <div>
          {this.state.files &&
            (this.props.fileListRenderer
              ? this.props.fileListRenderer(this.state.files)
              : this.fileListRenderer(this.state.files))}
        </div>
      </div>
    );
  }
}

export default injectIntl(FileInput);
