import * as React from 'react';
import { NavLink } from 'react-router-dom';
import * as appstate from '../../state/reducers/ApplicationState';
import * as adminstate from '../../state/reducers/AdminState';
import * as documentstate from '../../state/reducers/DocumentState';
import { FormattedMessage, injectIntl, InjectedIntlProps } from 'react-intl';
import LinearProgress from '@material-ui/core/LinearProgress';

import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import Settings from '@material-ui/icons/Settings';

// import InfoBar from '../Common/InfoBar';
import Button from '../Common/Button';
import { Badge } from '@material-ui/core';

interface IMainNavigationProps
  extends appstate.IProps,
    adminstate.IProps,
    documentstate.IProps {}

interface IMainNavigationState {
  email: string;
  password: string;
}

type MainNavigationProps = IMainNavigationProps & InjectedIntlProps;

class AbstractMainNavigation extends React.Component<
  MainNavigationProps,
  IMainNavigationState
> {
  checkLoading() {
    return (
      Object.keys(this.props.appstatus).filter(
        action => this.props.appstatus[action].loading
      ).length > 0 ||
      Object.keys(this.props.adminstatus).filter(
        action => this.props.adminstatus[action].loading
      ).length > 0 ||
      Object.keys(this.props.docstatus).filter(
        action => this.props.docstatus[action].loading
      ).length > 0
    );
  }

  public render() {
    // const t = this.props.intl;
    const { user } = this.props;
    const loading = this.checkLoading();
    const downloadCount = this.props.downloadBasket.length;

    return (
      <header className="mainNavigation">
        <div className="logo">
          <NavLink to="/">
            <img src="/images/main_navigation_banner.svg" />
          </NavLink>
        </div>
        <nav>
          <li>
            <NavLink activeClassName="active" exact={true} to="/">
              <FormattedMessage id="headers.home" />
            </NavLink>
          </li>

          <li>
            <NavLink activeClassName="active" to="/inspection-documents">
              <FormattedMessage id="headers.inspectionDocument" />
            </NavLink>
          </li>

          {/* Old admin view used while the application was on early stages of development */}
          {/* {user && user.is_superuser ? (
            <li>
              <NavLink activeClassName="active" to="/managementTools">
                <FormattedMessage id="headers.managementTools" />
              </NavLink>
            </li>
          ) : null} */}

          {user && user.is_superuser && (
            <li>
              <NavLink
                activeClassName="active"
                to="/admin-tools"
                style={{ width: '50px' }}
              >
                <Settings />
              </NavLink>
            </li>
          )}
        </nav>
        <div className="actions">
          <div className="user">
            <NavLink to="/settings">
              {user ? `${user.email}` : ''}
            </NavLink>
          </div>

          {downloadCount > 0 ? (
            <NavLink to="/downloads" className="downloadsLink">
              <Badge color="secondary" badgeContent={downloadCount}>
                <ShoppingBasketIcon color="primary" />
              </Badge>
            </NavLink>
          ) : (
            <NavLink to="/downloads" className="downloadsLink">
              <ShoppingBasketIcon color="primary" />
            </NavLink>
          )}

          <Button
            className="logout"
            color="secondary"
            variant="contained"
            onClick={e => {
              this.props.logout();
              this.props.resetDocState();
            }}
          >
            <FormattedMessage id="mainnavigation.logout" />
          </Button>
        </div>
        {loading && (
          <LinearProgress
            style={{
              height: '2px',
              width: '100%',
              position: 'absolute',
              top: 50,
            }}
          />
        )}
      </header>
    );
  }
}

const MainNavigation = documentstate.StateConnector(
  adminstate.StateConnector(appstate.StateConnector(AbstractMainNavigation))
);

export default injectIntl(MainNavigation);
