import { Checkbox, Chip, MenuItem, Paper } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { InputProps } from '@material-ui/core/Input';
import {
  StyleRulesCallback,
  Theme,
  withStyles,
} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
// import * as keycode from 'keycode';
import Downshift, { DownshiftProps, GetItemPropsOptions } from 'downshift';
import * as _ from 'lodash';
import * as React from 'react';

// AutosuggestChipSelector Item type
export interface Item {
  label: string;
  value: number;
}

interface IRenderSuggestion {
  highlightedIndex: number | null | undefined;
  index: number;
  itemProps: object;
  selectedItem: Item[];
  suggestion: Item;
}

interface PropGetters extends DownshiftProps<any>, DownshiftProps<any> {
  getInputProps<T>(extraProps?: T): T & InputProps;
  getItemProps: (options: GetItemPropsOptions<Item>) => any;
}

interface IAutosuggestChipSelectionProps {
  suggestions: Item[] | undefined;
  classes: Record<any, string>;
  innerRef?:
    | ((instance: any) => void)
    | React.RefObject<any>
    | null
    | undefined;
  label?: string | JSX.Element;
  placeholder?: string;
  selectedItem: Item[];
  loading?: boolean;
  required?: boolean;
  // ref: React.RefObject<AutosuggestionChipSelectionProps>;
  onChange: (items: Item[]) => void;
}

interface IAutosuggestChipSelectionState {
  inputValue?: string;
  selectedItem: Item[];
  isOpen?: boolean;
  multi?: boolean;
}

export type AutosuggestionChipSelectionProps = IAutosuggestChipSelectionProps &
  IAutosuggestChipSelectionState;

class AutosuggestChipSelection extends React.Component<
  AutosuggestionChipSelectionProps,
  IAutosuggestChipSelectionState
> {
  constructor(props: AutosuggestionChipSelectionProps) {
    super(props);
    this.state = {
      inputValue: props.inputValue || '',
      selectedItem: props.selectedItem || undefined,
      isOpen: false,
    };
  }

  // TODO: Very likely does not do anything useful.
  componentDidUpdate(prevProps: IAutosuggestChipSelectionProps) {
    if (prevProps !== this.props) {
      this.setState({
        selectedItem: prevProps.selectedItem,
      });
    }
  }

  renderSuggestion({
    suggestion,
    index,
    itemProps,
    highlightedIndex,
    selectedItem,
  }: IRenderSuggestion) {
    const isHighlighted = highlightedIndex === index;
    const isSelected =
      selectedItem.filter((item) => item.value === suggestion.value).length > 0;

    return (
      <MenuItem
        {...itemProps}
        key={suggestion.value}
        // key={index + '_' + suggestion.label}
        selected={isHighlighted}
        component="div"
        style={{
          fontWeight: isSelected ? 500 : 400,
        }}
      >
        {this.props.multi && <Checkbox checked={isSelected} />}
        {suggestion.label}
      </MenuItem>
    );
  }

  getSuggestions(value: string | null | undefined, suggestions: Item[]) {
    const inputValue = value ? _.deburr(value.trim()).toLowerCase() : '';
    const inputLength = inputValue.length;
    let count = 0;

    return inputLength === 0
      ? suggestions
      : suggestions.filter((suggestion) => {
          if (suggestion.label) {
            const keep =
              count < 5 &&
              suggestion.label.slice(0, inputLength).toLowerCase() ===
                inputValue;

            if (keep) {
              count += 1;
            }
            return keep;
          } else {
            return {};
          }
        });
  }

  handleKeyDown = (event: any) => {
    // FIXME: keycode not working with TS
    // const { inputValue, selectedItem } = this.state;
    // // &&
    // // keycode(event) === 'backspace'
    // if (selectedItem.length && !inputValue.length) {
    //   this.setState({
    //     selectedItem: selectedItem.slice(0, selectedItem.length - 1),
    //   });
    // }
  };

  handleInputChange = (event: any) => {
    this.setState({ inputValue: event.target.value });
  };

  handleChange = (item: any) => {
    let { selectedItem } = this.state;

    if (!this.props.multi) {
      selectedItem = [item];
    } else {
      const existsIdx = selectedItem.findIndex((i) => i.value === item.value);
      if (existsIdx > -1) {
        selectedItem.splice(existsIdx, 1);
      } else {
        selectedItem = [...selectedItem, item];
      }
    }

    this.setState({
      inputValue: '',
      selectedItem,
      isOpen: this.props.multi ? true : false,
    });

    this.props.onChange(selectedItem);
  };

  handleDelete = (item: any) => () => {
    this.setState((state) => {
      const selectedItem = [...state.selectedItem];
      selectedItem.splice(selectedItem.indexOf(item), 1);
      this.props.onChange(selectedItem);
      return { selectedItem };
    });
  };

  render() {
    const { classes, suggestions } = this.props;
    const { inputValue, selectedItem } = this.state;
    const multi = this.props.multi;

    const renderInput = (inputProps: any) => {
      const { InputProps, classes, ref, ...other } = inputProps;
      const { startAdornment, ...restProps } = InputProps;

      return (
        <TextField
          required={this.props.required}
          disabled={this.props.loading}
          onClick={() => {
            if (!this.props.loading) this.setState({ isOpen: true });
          }}
          InputProps={
            !this.props.loading
              ? {
                  inputRef: ref,
                  classes: {
                    root: multi && classes.inputRoot,
                    input: classes.inputInput,
                  },
                  ...restProps,
                  startAdornment:
                    startAdornment.length > 0 ? startAdornment : null,
                }
              : {
                  startAdornment: (
                    <CircularProgress
                      style={{ width: '20px', height: '20px' }}
                    />
                  ),
                  ...restProps,
                }
          }
          InputLabelProps={{
            shrink: startAdornment.length > 0 || InputProps.selected,
          }}
          {...other}
        />
      );
    };

    return (
      <div className={classes.root}>
        <Downshift
          itemToString={(item) => item.value}
          inputValue={inputValue}
          onChange={this.handleChange}
          selectedItem={selectedItem}
          onOuterClick={() => {
            this.setState({ isOpen: false, inputValue: '' });
          }}
          isOpen={this.state.isOpen}
        >
          {({
            getInputProps,
            getItemProps,
            inputValue: inputValue2,
            selectedItem: selectedItem2,
            isOpen,
            highlightedIndex,
          }: PropGetters) => (
            <div className={classes.container}>
              {renderInput({
                fullWidth: true,
                classes,
                InputProps: getInputProps({
                  startAdornment: selectedItem.map((item: Item, index) => {
                    if (multi) {
                      return (
                        <Chip
                          key={'chip_' + index + '_' + item}
                          tabIndex={-1}
                          label={item.label}
                          className={classes.chip}
                          onDelete={this.handleDelete(item)}
                        />
                      );
                    } else {
                      return (
                        <div
                          hidden={isOpen}
                          style={{ width: '100%' }}
                          key={'chip_' + index + '_' + item}
                          tabIndex={-1}
                          className={classes.chip}
                        >
                          {item.label}
                        </div>
                      );
                    }
                  }),
                  // <Typography
                  //   variant="h6"
                  //   style={{ backgroundColor: 'blue' }}
                  // >
                  //   {selectedItem.length > 0 ? selectedItem[0].label : ''}
                  // </Typography>
                  onChange: this.handleInputChange,
                  onKeyDown: this.handleKeyDown,
                  // placeholder:
                  //   selectedItem.length > 0 ? '' : this.props.placeholder,
                }),
                label: this.props.label,
              })}
              {isOpen ? (
                <Paper className={classes.paper} square={true}>
                  {suggestions &&
                    this.getSuggestions(inputValue2, suggestions).map(
                      (suggestion, index) => {
                        if (suggestion.label) {
                          return this.renderSuggestion({
                            suggestion,
                            index,
                            itemProps: getItemProps({
                              item: {
                                label: suggestion.label,
                                value: suggestion.value,
                              },
                            }),
                            highlightedIndex,
                            selectedItem: selectedItem2,
                          });
                        } else {
                          return {};
                        }
                      }
                    )}
                </Paper>
              ) : null}
            </div>
          )}
        </Downshift>
      </div>
    );
  }
}

const styles: StyleRulesCallback = (theme: Theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: 20,
  },
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  inputRoot: {
    flexWrap: 'wrap',
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1,
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
});

export default withStyles(styles)(AutosuggestChipSelection);
