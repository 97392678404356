import {
  Create,
  PlayArrow,
  Star,
  StarBorder,
  DragIndicator,
} from '@material-ui/icons/';
import axios from 'axios';
import * as React from 'react';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Moment from 'react-moment';
import { Link, Redirect } from 'react-router-dom';
import {
  UIDocument,
  AreaDocumentState,
} from '../../../../shared/src/model/index';
import * as schema from '../../../../shared/src/model/projectSchema';
import { DocumentService } from '../../services/document/src/frontend/api';
import { getIdForCategoryName as categoryId } from '../../utils/metadata';
import Button from '../Common/Button';
import DocumentTable from '../Common/DocumentTable';
import { Comment } from '../Common/Icons';
import { SortableHandle } from 'react-sortable-hoc';

const DOC = DocumentService(axios);

interface ITargetRowProps {
  style?: React.CSSProperties;
  isContractor: boolean;
  target: UIDocument;
  index: number;
  targetDocs: AreaDocumentState[];
  favourites: number[];
  workflows: schema.workflow[];
  onTargetNameChange: (targetName: string, target: UIDocument) => void;
  onDeleteTarget: (target: UIDocument) => void;
  onSetFavourites: (id: number) => void;
  isEditing?: boolean;
  onEditTarget: (targetId: number | undefined) => void;
  onCancelEdit: () => void;
}

interface ITargetRowState {
  documents?: UIDocument[];
  isOpen: boolean;
  isFavorite: boolean;
  selectedDocument?: number;
  selectedDocumentParent?: number;
  navigateToDocument?: boolean;
  isEditing: boolean;
  targetName: string;
  isConfirmingDeletion: boolean;
}

type TargetRowProps = ITargetRowProps & InjectedIntlProps;

class TargetRow extends React.Component<TargetRowProps, ITargetRowState> {
  constructor(props: TargetRowProps) {
    super(props);

    const { target, favourites } = this.props;

    this.state = {
      isOpen: false,
      isFavorite: favourites.indexOf(target.id) !== -1 ? true : false,
      isEditing: props.isEditing ? true : false,
      targetName: props.target.name,
      isConfirmingDeletion: false,
    };
  }

  onToggleVisibility = () => {
    this.setState({ isOpen: !this.state.isOpen }, async () => {
      // Reload documents every time the view is opened
      if (this.state.isOpen) {
        const parentDocumentIds = [this.props.target.id];
        DOC.documentSearchRequest({
          parentDocumentIds,
          documentCategories: [await categoryId('TARKASTUSASIAKIRJA_DOC')],
          cursor: {
            offset: 0,
            limit: 500,
          },
        }).then((documents) => {
          this.setState({ documents: documents.rows });
        });
      }
    });
  };

  onToggleEditMode = () => {
    this.props.onEditTarget(
      this.state.isEditing ? undefined : this.props.target.id
    );
    // this.setState({ isEditing: !this.state.isEditing });
  };

  onUpdateName = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ targetName: event.target.value });
  };

  onToggleFavorState = (id: number) => {
    this.setState({ isFavorite: !this.state.isFavorite });
    this.props.onSetFavourites(id);
  };

  onDeleteTarget = () => {
    this.setState({ isConfirmingDeletion: true });
  };

  handleRedirectToDocument = (document: any) => {
    this.setState({
      selectedDocument: document.id,
      selectedDocumentParent: document.parentId,
      navigateToDocument: true,
    });

    // const { id, parentId } = document;

    // this.props.DocumentStateDispatcher(
    //   push(`/inspection-documents/target/${parentId}/attachment/${id}`)
    // );
  };

  onCancelEdit = () => {
    this.setState({ targetName: this.props.target.name });
    this.props.onCancelEdit();
    this.onToggleEditMode();
  };

  onCancelTargetDeletion = () => {
    this.setState({ isConfirmingDeletion: false });
  };

  onTargetDeletion = (target: UIDocument) => {
    this.props.onDeleteTarget(target);
    this.setState({ isConfirmingDeletion: false });
  };

  render() {
    const t = (id: string) => this.props.intl.formatMessage({ id });

    const { isContractor, target, workflows } = this.props;
    const { isFavorite, isEditing, isConfirmingDeletion } = this.state;
    const documents = this.state.documents || [];
    const stateColor = target.uiMeta ? target.uiMeta.stateColor : '';

    const SortableDragHandle = SortableHandle(() => (
      <DragIndicator className="dragHandle" />
    ));

    const workflow = workflows
      .filter((workflow) => workflow.id === target.workflowId)
      .pop();

    if (this.state.navigateToDocument) {
      const id = this.state.selectedDocument;
      const parentId = this.state.selectedDocumentParent;
      return (
        <Redirect
          to={{
            pathname: `/inspection-documents/target/${parentId}/attachment/${id}`,
          }}
          push={true}
        />
      );
    }

    const stateDocs = this.props.targetDocs;

    // Jos dokumentteja on lisätty, mutta niitä ei ole vielä hyväksytty, tila on "Liitteet on lisätty"
    const someAdded = stateDocs.length > 0;
    // Jos dokumentteja on lisätty ja ne kaikki on hyväksytty, tila on "Liitteet on hyväksytty"
    const allAccepted =
      stateDocs.filter((doc) => doc.state_name !== 'ACCEPTED').length === 0;
    // Jos dokumenteista yksikin on hylätty, tila on "Liitteitä on hylätty"
    const someRejected =
      stateDocs.filter((doc) => doc.state_name === 'REJECTED').length > 0;

    const stateText = someRejected
      ? t('inspectionDocuments.documentsRejected')
      : someAdded && allAccepted
      ? t('inspectionDocuments.documentsAccepted')
      : someAdded
      ? t('inspectionDocuments.documentsAdded')
      : t('inspectionDocuments.withoutDocuments');

    return (
      <div className={`target`} key={target.id}>
        {isEditing ? (
          <ValidatorForm
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
            className="content editMode"
            onSubmit={() => {
              this.onToggleEditMode();

              this.props.onTargetNameChange(this.state.targetName, target);
            }}
          >
            <div className="fields">
              <input
                type="text"
                className="basic"
                value={this.state.targetName}
                onChange={this.onUpdateName}
              />
            </div>
            <div className="actions">
              <Button
                size="small"
                disabled={isConfirmingDeletion}
                onClick={this.onCancelEdit}
              >
                <FormattedMessage id="buttons.cancel" />
              </Button>
              <Button
                size="small"
                disabled={
                  !(target.privileges && target.privileges.canDelete) ||
                  isConfirmingDeletion
                }
                onClick={this.onDeleteTarget}
              >
                <FormattedMessage id="buttons.delete" />
              </Button>
              <Button
                size="small"
                disabled={isConfirmingDeletion}
                type="submit"
              >
                <FormattedMessage id="buttons.save" />
              </Button>
            </div>
            <SortableDragHandle />
          </ValidatorForm>
        ) : (
          <>
            <div
              className={`content status-${stateColor}`}
              onClick={this.onToggleVisibility}
            >
              <div
                className={`favorite button ${isFavorite && 'isFavorite'}`}
                onClick={(e) => {
                  e.stopPropagation();
                  this.onToggleFavorState(target.id);
                }}
              >
                <span className="star">
                  <Star />
                </span>
                <span className="starBorder">
                  <StarBorder />
                </span>
              </div>
              <div className="meta">
                <div className="column">
                  <Link to={`/inspection-documents/target/${target.id}`}>
                    {target.name}
                  </Link>
                </div>
                <div className="column">{target.description}</div>
                <div className="column">{stateText}</div>
                <div className="column">
                  {workflow && t(workflow.workflow_name!)}
                </div>
              </div>

              {/* <div className="filler" /> */}
              <div className="targetActions">
                {target.comments && target.comments.length > 0 && (
                  <div
                    className={`comments button ${target.comments.some(
                      (comment: any) => comment.seen_at === null
                    ) && 'unreadComments'} ${isContractor && 'contractor'}`}
                  >
                    <Comment />
                  </div>
                )}
                {target.privileges && target.privileges.canUpdate && (
                  <div
                    className="edit button"
                    onClick={(e) => {
                      e.stopPropagation();
                      this.onToggleEditMode();
                    }}
                  >
                    <Create />
                  </div>
                )}
              </div>
              <div
                className={`toggle button ${this.state.isOpen ? 'open' : ''}`}
              >
                <PlayArrow />
              </div>
            </div>

            {this.state.isOpen && (
              <div className="documents">
                <DocumentTable
                  actionComponents={[]}
                  setSelected={(ids) => {
                    if (documents) {
                      // this.props.addToPitemTargets(
                      //   this.state.docs.filter(doc => {
                      //     return ids.indexOf(doc.id) >= 0;
                      //   })
                      // );
                    }
                  }}
                  // hasSelect={true}
                  keys={{
                    name: t('documentMeta.fileName'),
                    description: t('documentMeta.name'),
                    workflowStateName: t('documentMeta.workflowStateName'),
                    modified: t('documentMeta.modified'),
                  }}
                  rows={
                    documents.map((document: UIDocument) => ({
                      ...document,
                      modified: (
                        <Moment date={document.modified} format="DD.MM.YYYY" />
                      ),
                    })) || []
                  }
                  rowClick={(document) =>
                    this.handleRedirectToDocument(document)
                  }
                />
              </div>
            )}
          </>
        )}

        {isConfirmingDeletion && (
          <div className="confirm">
            <p>
              <FormattedMessage id="inspectionDocuments.confirmTargetDeletion" />
            </p>
            <div className="actions">
              <Button size="small" onClick={this.onCancelTargetDeletion}>
                <FormattedMessage id="buttons.cancel" />
              </Button>
              <Button
                size="small"
                onClick={() => this.onTargetDeletion(target)}
              >
                <FormattedMessage id="buttons.delete" />
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default injectIntl(TargetRow);
