import * as React from 'react';
import { TextValidator } from 'react-material-ui-form-validator';
import { TextFieldProps as MuiTextFieldProps } from '@material-ui/core/TextField';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      fontSize: 16,
      borderRadius: 0,
    },
  });

export interface IPasswordFieldProps {
  classes?: any;
  validators?: string[];
  errorMessages?: string[];
  name: string;
}

export interface IPasswordFieldState {
  showPassword: boolean;
}

type PasswordFieldProps = MuiTextFieldProps & IPasswordFieldProps;

class UiTextField extends React.Component<
  PasswordFieldProps,
  IPasswordFieldState
> {
  constructor(props: PasswordFieldProps) {
    super(props);

    this.state = { showPassword: false };
  }

  handleClickShowPassword = () => {
    this.setState((state) => ({ showPassword: !state.showPassword }));
  };

  render() {
    return (
      <TextValidator
        {...this.props}
        className={`${this.props.classes}`}
        variant="standard"
        type={this.state.showPassword ? 'text' : 'password'}
        fullWidth={true}
        InputProps={{
          endAdornment: (
            <InputAdornment variant="filled" position="end">
              <IconButton
                // Prevent user from accidentally revealing their password by tabbing
                tabIndex={-1}
                aria-label="Toggle password visibility"
                onClick={this.handleClickShowPassword}
              >
                {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    );
  }
}

export default withStyles(styles)(UiTextField);
