import axios from 'axios';
import { APIService } from '../services/api/src/frontend/api';
import { DocumentService } from '../services/document/src/frontend/api';
import {
  SearchRequest,
  RoomPosition,
  NewPitem,
} from '../../../shared/src/model/index';
// import { store } from '../App';
const API = APIService(axios);
const DOC = DocumentService(axios);

export async function findPitemsForType(
  buildings: string[],
  pitemType: string
) {
  // TODO: lisää rakennusosatunnus
  return API.getPitemsOfType({ buildings, typename: pitemType });
}

export async function createPItem(options: NewPitem) {
  return API.createPItem(options);
}

export async function updatePitemNAme(id: number, longName: string) {
  return API.updatePitemName(id, longName);
}

export async function getDocument(id: number) {
  return DOC.fetchDocument(id);
}

export async function searchDocuments(req: SearchRequest) {
  return await DOC.documentSearchRequest(req);
}

export async function pureDocumentSearch(req: SearchRequest) {
  return await DOC.pureDocumentSearch(req);
}

export async function getAllBuildings() {
  return API.getAllBuildings();
}

export async function findRooms(rooms: RoomPosition[]) {
  return API.findRooms(rooms);
}

export async function findPitemType(typename: string) {
  return await API.findPitemType(typename);
}

export async function createPitemType(typename: string) {
  return await API.createPitemType(typename);
}

export async function checkPItemDuplicate(name: string) {
  return await API.checkPItemDuplicate({ name });
}

export async function getPitemByName(name: string) {
  return await API.getPitemByName(name);
}

export async function createNewPItem(options: NewPitem) {
  const pitem = await API.createPItem(options);
  return pitem;
}
export async function findPitems(text: string) {
  return await API.searchPItems({ freeText: text });
}

export async function deletePitem(id: number) {
  return await API.deletePitem(id);
}

export async function createNewPItemIntoRoom(params: {
  code: string;
  description: string;
  roomName: string;
  pitemTypeName: string;
}) {
  const findRoom = await API.findRoom(params.roomName);
  const pitemType = await API.findPitemType(params.pitemTypeName);
  const pitem = await API.createPItem({
    name: params.code,
    name_long: params.description,
    parentItemId: findRoom.id,
    pitemTypeId: pitemType.id,
  });
  return pitem;
}
