import Clear from '@material-ui/icons/Clear';
import axios from 'axios';
import * as React from 'react';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Link } from 'react-router-dom';
import ReactSelect from 'react-select';
import * as xlsx from 'xlsx';
import {
  CreateInspectionDocumentRequest,
  UIDocument,
} from '../../../../shared/src/model/index';
import MainLayout from '../../layout/MainLayout';
import { AuthService } from '../../services/auth/src/frontend/api';
import { DocumentService } from '../../services/document/src/frontend/api';
import * as applicationState from '../../state/reducers/ApplicationState';
import * as documentState from '../../state/reducers/DocumentState';
import { setBackgroundImage } from '../../utils';
import { getIdForCategoryName as categoryId } from '../../utils/metadata';
import { Item } from '../Common/AutosuggestChipSelection';
import Button from '../Common/Button';
import FileInput from '../Common/FileInput';
import { errorMessage, successMessage } from '../Common/Notifier';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
} from '@material-ui/core';
import { ValueType } from 'react-select/lib/types';
import * as _ from 'lodash';
// tslint:disable-next-line:no-var-requires
const ReactMarkdown = require('react-markdown');

const AUTH = AuthService(axios);
const DOC = DocumentService(axios);

interface IAdminViewProps
  extends applicationState.IProps,
    documentState.IProps {}

interface IAdminViewState {
  inspectionDocuments: UIDocument[];
  inspectionDocumentToBeDeleted?: UIDocument;
  selectedPhase?: Item;
  selectedContract?: Item;
  configurationFile?: File;
  configurationData?: CreateInspectionDocumentRequest;
  inspectionDocumentName: string;
  inspectionDocumentFilter: string;
  isSavingNewInspectionDocument: boolean;
  password: string;
}

type AdminViewProps = IAdminViewProps & InjectedIntlProps;

class AbstractAdminView extends React.Component<
  AdminViewProps,
  IAdminViewState
> {
  constructor(props: AdminViewProps) {
    super(props);

    this.state = {
      inspectionDocuments: [],
      inspectionDocumentName: '',
      inspectionDocumentFilter: '',
      isSavingNewInspectionDocument: false,
      password: '',
    };
  }

  componentDidMount() {
    setBackgroundImage();
    this.updateInspectionDocumentList();
  }

  updateInspectionDocumentList = async () => {
    try {
      const unsortedInspectionDocuments = await DOC.documentSearchRequest({
        documentCategories: [await categoryId('TARKASTUSASIAKIRJA')],
      });

      // localSearchQuery returns documents in order of last update
      const inspectionDocuments = unsortedInspectionDocuments.rows.sort(
        (a, b) => a.name.localeCompare(b.name, 'fi')
      );

      this.setState({ inspectionDocuments });
    } catch (e) {
      errorMessage(e);
    }
  };

  onChangePhase(selection: Item) {
    this.setState({ selectedPhase: selection });
    // TODO: Load inspection documents
  }

  onChangeContract(selection: Item) {
    this.setState({ selectedContract: selection });

    // Get Inspection documents filtered by selected contract
  }

  onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ inspectionDocumentName: e.currentTarget.value });
  };

  onSaveNewInspectionDocument = async () => {
    const t = (id: string) => this.props.intl.formatMessage({ id });
    const { configurationData, selectedContract } = this.state;

    // Get contract id and inspection document name from user inputs
    if (configurationData && selectedContract) {
      configurationData.meta.contractId = selectedContract.value;
      configurationData.meta.name = this.state.inspectionDocumentName;
    }

    this.setState({ isSavingNewInspectionDocument: true });

    if (this.state.selectedContract && configurationData) {
      try {
        await DOC.createInspectionDocument(configurationData);

        successMessage(
          t('inspectionDocuments.newInspectionDocumentAddedSuccessfully')
        );
        this.setState({ isSavingNewInspectionDocument: false });
        this.updateInspectionDocumentList();
      } catch (e) {
        errorMessage(e);
      }
    }
  };

  onInspectionDocumentFilterChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({ inspectionDocumentFilter: e.currentTarget.value.trim() });
  };

  setInspectionDocumentToBeDeleted(inspectionDocumentToBeDeleted: UIDocument) {
    this.setState({ inspectionDocumentToBeDeleted });
  }

  onDeleteInspectionDocument = async (document: UIDocument) => {
    const t = (id: string) => this.props.intl.formatMessage({ id });
    const username = this.props.user && this.props.user.email;
    const { password } = this.state;
    const currentInspectionDocuments = [...this.state.inspectionDocuments];
    const inspectionDocuments = currentInspectionDocuments.filter(
      (iDoc: UIDocument) => iDoc.id !== document.id
    );

    /*
      The password check here is only for adding extra step to inspection
      document deletion process and to make sure that the admin understands
      what they are doing.

      This DOES NOT prevent the client from using the API endpoint for deleting
      inspection documents without their password. Again: The implementation
      here is an UX feature, not an security feature.
    */
    try {
      // Check the admin password before sending delete request
      await AUTH.login({
        username: username || '',
        password: password || '',
      });

      await DOC.deleteDocument(document.id);

      this.setState({
        inspectionDocuments,
        inspectionDocumentToBeDeleted: undefined,
        password: '',
      });

      successMessage(
        t('inspectionDocuments.inspectionDocumentDeletedSuccessfully')
      );
    } catch (e) {
      if (
        (e.response && e.response.status === 400) ||
        e.response.status === 401
      ) {
        errorMessage(t('warning.invalidAdminPassword'));
      } else {
        errorMessage(e);
      }
    }
  };

  parseSpreadsheet(document: any) {
    const inspectionDocumentTab = document.Sheets[document.SheetNames[0]];
    const parsedInspectionDocumentData = xlsx.utils.sheet_to_json(
      inspectionDocumentTab,
      { header: 1 }
    ) as any[];

    // Take the name from the first row
    const inspectionDocumentName = parsedInspectionDocumentData[0][1];
    const headers = parsedInspectionDocumentData[3] as string[];
    const data = parsedInspectionDocumentData.slice(4) as string[][];

    // Find the contract name from the second cell of the second row
    const selectedContract = this.props.userContracts
      .map((v) => ({
        label: v.aitem_name,
        value: v.aitem_id,
      }))
      .filter((c) => c.label === parsedInspectionDocumentData[1][1])[0];

    // Collect building permits from the second tab
    const buildingPermitsTab = document.Sheets[document.SheetNames[1]];
    const buildingPermits = xlsx.utils
      .sheet_to_json(buildingPermitsTab, {
        header: 1,
      })
      .slice(1) as string[];

    const configurationData: CreateInspectionDocumentRequest = {
      meta: {
        contractId: selectedContract.value,
        name: inspectionDocumentName,
        buildingPermits,
      },
      data: {
        headers,
        data,
      },
    };

    return {
      configurationData,
      selectedContract,
      inspectionDocumentName,
    };
  }

  onFileSelection = (files: File[]) => {
    const file = files[0];
    this.setState({ configurationFile: file });

    const reader = new FileReader();

    reader.onload = async () => {
      try {
        const data = reader.result;
        const document = xlsx.read(data, {
          type: 'binary',
        });

        const parsedData = this.parseSpreadsheet(document);
        this.setState(parsedData);
      } catch (e) {
        console.log('Failed to load the document', e);
      }
    };

    reader.onerror = (error) => {
      console.log(error);
    };

    reader.readAsBinaryString(file);
  };

  onAdminPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ password: e.currentTarget.value });
  };

  render() {
    const t = (id: string) => this.props.intl.formatMessage({ id });
    const { inspectionDocuments } = this.state;

    // const phaseOptionsMock = [
    //   { label: 'Kaikki', value: 0 },
    //   { label: 'Ensimmäinen', value: 1 },
    //   { label: 'Toinen', value: 2 },
    // ];

    const filteredInspectionDocuments = inspectionDocuments
      ? inspectionDocuments.filter((document) => {
          return (
            document.name
              .toLowerCase()
              .indexOf(this.state.inspectionDocumentFilter) !== -1
          );
        })
      : [];

    const contractName = (contractId: number) => {
      const relatedContractName = _.find(this.props.userContracts, {
        aitem_id: contractId,
      });

      return relatedContractName ? relatedContractName.aitem_name : '';
    };

    return (
      <MainLayout>
        <div className="breadcrumbs">
          <div className="crumb">
            <Link to="/inspection-documents">
              <FormattedMessage id="headers.inspectionDocument" />
            </Link>
          </div>
          <div className="crumb">
            <FormattedMessage id="inspectionDocuments.adminViewHeader" />
          </div>
        </div>

        <h1>
          <FormattedMessage id="inspectionDocuments.adminViewHeader" />
        </h1>

        <div className="inspectionDocumentAdminTasks grid gridCols3">
          <div className="fileUpload component">
            <h2>
              <FormattedMessage id="inspectionDocuments.newInspectionDocument" />
            </h2>
            {this.state.isSavingNewInspectionDocument && <LinearProgress />}
            <div className="content">
              <ValidatorForm onSubmit={this.onSaveNewInspectionDocument}>
                <div className="contractPicker">
                  {/* <ReactSelect
                    className="selectComponent"
                    isMulti={false}
                    placeholder={t('documentMeta.projectPhase')}
                    defaultValue={phaseOptionsMock[0]}
                    options={phaseOptionsMock}
                    value={this.state.selectedPhase}
                    onChange={value => this.onChangePhase(value as Item)}
                  /> */}
                  <div className="filler" />
                  <ReactSelect
                    className="selectComponent"
                    isMulti={false}
                    placeholder={t('documentMeta.contract')}
                    options={this.props.userContracts.map((v) => ({
                      label: v.aitem_name,
                      value: v.aitem_id,
                    }))}
                    value={this.state.selectedContract}
                    onChange={(value: ValueType<Item>) =>
                      this.onChangeContract(value as Item)
                    }
                  />
                </div>

                <FileInput
                  single={true}
                  id="inspectionDocumentExcelUpload"
                  label={t('inspectionDocuments.excelImportLabel')}
                  onChange={(files) => {
                    this.onFileSelection(files);
                  }}
                />

                <div className="inputs">
                  <input
                    type="text"
                    className="basic"
                    placeholder={t('inspectionDocuments.documentName')}
                    value={this.state.inspectionDocumentName}
                    onChange={this.onNameChange}
                  />
                  <Button
                    disabled={
                      !this.state.selectedContract ||
                      !this.state.configurationFile ||
                      this.state.isSavingNewInspectionDocument
                    }
                    type="submit"
                  >
                    <FormattedMessage id="buttons.save" />
                  </Button>
                </div>
              </ValidatorForm>
            </div>
          </div>

          <div className="inspectionDocuments component">
            <h2>
              <FormattedMessage id="inspectionDocuments.inspectionDocuments" />
            </h2>
            <div className="actions">
              <input
                type="text"
                className="basic filterTerm"
                placeholder={t('buttons.filter')}
                onChange={this.onInspectionDocumentFilterChange}
              />
            </div>
            {this.state.inspectionDocumentToBeDeleted &&
              this.deleteInspectionDocumentDialog()}
            <ul>
              {filteredInspectionDocuments.map((document, index) => {
                return (
                  <li key={index} className="inspectionDocument">
                    <div className="name">{document.name}</div>
                    <div className="contract">
                      {contractName(document.contractIds[0])}
                    </div>
                    <Button
                      onClick={() =>
                        this.setInspectionDocumentToBeDeleted(document)
                      }
                      className="actionButton"
                    >
                      <Clear style={{ fontSize: '20px' }} />
                    </Button>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </MainLayout>
    );
  }

  deleteInspectionDocumentDialog() {
    const t = (id: string, values?: {}) =>
      this.props.intl.formatMessage({ id }, values);
    const { ...document } = this.state.inspectionDocumentToBeDeleted;

    const markdown = t('inspectionDocuments.deleteInspectionDocumentMessage', {
      name: document.name,
    });

    return (
      <Dialog
        className="confirmDialog"
        open={!!document}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
      >
        <DialogTitle>
          <FormattedMessage id="inspectionDocuments.deleteInspectionDocumentHeader" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText component={'div'}>
            <ReactMarkdown source={markdown} />
            <ValidatorForm
              onSubmit={() => this.onDeleteInspectionDocument(document)}
            >
              <input
                className="basic"
                type="password"
                placeholder={t('userDetails.password')}
                autoFocus={true}
                value={this.state.password}
                onChange={this.onAdminPasswordChange}
              />
            </ValidatorForm>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialogActions">
          <Button
            onClick={() =>
              this.setState({ inspectionDocumentToBeDeleted: undefined })
            }
          >
            <FormattedMessage id="buttons.cancel" />
          </Button>
          <Button onClick={() => this.onDeleteInspectionDocument(document)}>
            <FormattedMessage id="buttons.delete" />
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const AdminView = applicationState.StateConnector(
  documentState.StateConnector(AbstractAdminView)
);

export default injectIntl(AdminView);
