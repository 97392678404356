import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { useState } from 'react';
import { TextField, TablePagination } from '@material-ui/core';
import useDebounce from 'utils/debounceHook';

interface IDataTable<T, K extends keyof T> {
  rows: T[];
  keys: K[];
  titles?: { [key in keyof T]?: string | Element | JSX.Element };
  render?: { [key in keyof T]?: (row: T) => JSX.Element };
  rowPrefix?: (row: T) => JSX.Element;
  onClick?: (item: T) => void;
  renderDetails?: (item: T) => JSX.Element;
  alignDefault?: 'left' | 'center' | 'right';
  align?: { [key in keyof T]?: 'left' | 'center' | 'right' };
  rowStyles?: (row: T) => { [key: string]: string | undefined };
  defaultRowsPerPage?: number;
  onUserUpdateDefaultRowCount?: (rowCount: number) => void;
}

export function DataTable<T, K extends keyof T>(props: IDataTable<T, K>) {
  const defaultRowsPerPage = props.defaultRowsPerPage
    ? props.defaultRowsPerPage
    : 100;
  const [filter, setFilter] = useState('');
  const [cnt, setCnt] = useState(defaultRowsPerPage);
  const [page, setPage] = useState(0);
  const debouncedFilter = useDebounce(filter, 300);

  const rows = React.useMemo(
    () =>
      props.rows.filter((value) => {
        const filters = debouncedFilter
          .split(' ')
          .map((v) => v.trim().toLocaleLowerCase())
          .filter((value) => value);
        if (filters.length === 0) {
          return true;
        }
        let matchCnt = 0;
        for (const f of filters) {
          let match = false;
          for (const key of props.keys) {
            const v = value[key];
            if (typeof v === 'number') {
              match = match || v === Number(f);
              continue;
            }
            if (typeof v === 'string') {
              match = match || v.toLocaleLowerCase().indexOf(f) >= 0;
              continue;
            }
            match = match || String(v) === String(f);
          }
          if (match) {
            matchCnt++;
          }
        }
        return matchCnt === filters.length;
      }),
    [debouncedFilter, props.rows]
  );

  const defaultAlign = props.alignDefault || 'left';
  const align = (key: keyof T) =>
    props.align && props.align[key] ? props.align[key] : defaultAlign;

  const addedRowStyles = props.rowStyles ? props.rowStyles : (_row: T) => ({});

  return (
    <Paper style={{ overflow: 'scroll' }}>
      <form style={{ padding: '20px' }}>
        <TextField
          label="Filtteröi näkymää"
          value={filter}
          onChange={(e) => {
            setFilter(e.target.value);
          }}
        />
      </form>
      <Table className="dataTable">
        <TableHead>
          <TableRow>
            <TablePagination
              page={page}
              count={rows.length}
              rowsPerPage={cnt}
              rowsPerPageOptions={[20, 50, 100, 200]}
              onChangePage={(e, page: number) => {
                setPage(page);
              }}
              onChangeRowsPerPage={(event) => {
                const rowCount = parseInt(event.target.value, 10);
                setCnt(rowCount);
                if (props.onUserUpdateDefaultRowCount)
                  props.onUserUpdateDefaultRowCount(rowCount);
              }}
            />
          </TableRow>

          <TableRow>
            {props.rowPrefix ? <TableCell /> : null}
            {props.keys.map((key) => {
              return (
                <TableCell key={key + 'key'} align={align(key)}>
                  {props.titles ? props.titles[key] || key : key}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.slice(page * cnt, page * cnt + cnt).map((row, index) => {
            return (
              <TableRow
                key={index}
                onClick={() => {
                  if (props.onClick) {
                    props.onClick(row);
                  }
                }}
                style={{
                  cursor: props.onClick ? 'pointer' : 'hand',
                  ...addedRowStyles(row),
                }}
              >
                {props.rowPrefix ? props.rowPrefix(row) : null}
                {props.keys.map((key) => {
                  if (!props.render || !props.render[key]) {
                    return (
                      <TableCell key={String(key)} align={align(key)}>
                        {row[key]}
                      </TableCell>
                    );
                  }
                  const fn = props.render[key] as (row: T) => JSX.Element;
                  return (
                    <TableCell key={String(key)} align={align(key)}>
                      {fn(row)}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Paper>
  );
}
