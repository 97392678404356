import * as React from 'react';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import * as container from '../../state/reducers/ApplicationState';
import * as documentState from '../../state/reducers/DocumentState';
import Button from '../Common/Button';
import * as Model from '../../../../shared/src/model';
import { errorMessage, successMessage } from '../Common/Notifier';
import * as schema from '../../../../shared/src/model/projectSchema';
import DocumentReject from '../Common/DocumentReject';
import axios from 'axios';
import { DocumentService } from '../../services/document/src/frontend/api';
import { findAction } from '../../utils/metadata';
import './DocumentPackage.scss';
const DOC = DocumentService(axios);

interface IDpProps {
  docPackage: Model.UIDocument;
  onUpdated?: () => void;
}

interface IDpAcceptRejectState {
  isRejecting?: boolean;
  activeAction?: schema.action;
}

export class DpAcceptReject extends React.Component<
  IDpProps & InjectedIntlProps & container.IProps & documentState.IProps,
  IDpAcceptRejectState
> {
  state: IDpAcceptRejectState = {};
  translate = (m: string) => this.props.intl.formatMessage({ id: m });

  async actionOnDocument(
    doc: Model.UIDocument,
    act: schema.action,
    onSuccess: () => void
  ) {
    try {
      await DOC.docActionEvent({
        documentIds: [doc.id],
        actionId: act.id,
        reasonForRejection: '',
      });
      // TODO: translate this
      successMessage(this.translate('documentMeta.acceptSuccess'));
      onSuccess();
    } catch (e) {
      errorMessage(e);
    }
  }

  render() {
    const docPackage = this.props.docPackage;

    return (
      <>
        <div className="acceptOrReject">
          {docPackage.allowedActions.map(act => {
            if (act === 8) {
              return (
                <Button
                  key={act}
                  onClick={async () => {
                    this.actionOnDocument(
                      docPackage,
                      await findAction(act),
                      () => {
                        if (this.props.onUpdated) {
                          this.props.onUpdated();
                        }
                      }
                    );
                  }}
                >
                  {this.translate('ACCEPT')}
                </Button>
              );
            }
            return (
              <Button
                key={act}
                onClick={async () => {
                  this.setState({
                    activeAction: await findAction(act),
                    isRejecting: true,
                  });
                }}
              >
                {this.translate('REJECT')}
              </Button>
            );
          })}
        </div>
        {this.state.isRejecting ? (
          <div>
            <DocumentReject
              activeAction={this.state.activeAction}
              documentIds={[this.props.docPackage.id]}
              onUpdate={didCancel => {
                if (this.props.onUpdated) {
                  this.props.onUpdated();
                }
                this.setState({ isRejecting: false });
              }}
            />
          </div>
        ) : null}
      </>
    );
  }
}

export default documentState.StateConnector(
  container.StateConnector(injectIntl(DpAcceptReject))
);
