import * as xlsx from 'xlsx';
import * as model from '../../../shared/src/model/index';
import * as _ from 'lodash';

export interface SheetColumn {
  index: number;
  name: string;
  value: string;
  unknown?: boolean;
}

export interface SheetRow {
  mainContractTag: string;
  values: Array<{
    index: number;
    col?: SheetColumn;
    value: string;
  }>;
  index?: number;
}

type RowData = string | string[] | boolean | number | undefined | Date;

/**
 *
 * Takes spreadsheet data and returns it converted to JavaScript format
 *
 * @param worksheet Tab from the spreadsheet file
 */
const parseData = (worksheet: xlsx.WorkSheet) =>
  xlsx.utils.sheet_to_json(worksheet, {
    header: 1,
    blankrows: false,
  });

/**
 *
 * Takes spreadsheet headers, data rows and object for
 * mapping headers to db columns and returns a new object
 * with mapped data.
 *
 * @param spreadsheetHeaders Column headers from source spreadsheet
 * @param rowData Spreadsheets data content
 * @param headersToDbKeys Object conteining information about relation
 * between headers and database columns
 */
const parseSpreadsheetData = (
  spreadsheetHeaders: string[],
  rowData: RowData[][],
  headersToDbKeys: { [key: string]: string }
) => {
  return rowData.map(row => {
    const resultRow = {};

    row.map((cell, cellIndex) => {
      const columnName = spreadsheetHeaders[cellIndex];

      if (typeof cell === 'string') {
        cell = cell.trim();
      }

      if (columnName in _.invert(headersToDbKeys)) {
        resultRow[_.invert(headersToDbKeys)[columnName]] = cell;
      }
    });

    return resultRow;
  }) as Array<{ [K in keyof typeof headersToDbKeys]: RowData }>;
};

/**
 *
 * Read a file into Excel workbook.
 *
 * @param file File to convert into xlsx.Workbook
 */
export async function readFileToWorksheet(file: File): Promise<xlsx.WorkBook> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const data = reader.result;
      const workbook = xlsx.read(data, {
        type: 'binary',
        cellDates: true,
      });
      resolve(workbook);
    };
    reader.onerror = ex => {
      reject(ex);
    };
    reader.readAsBinaryString(file);
  });
}

export function parseExcelCellCode(letters: string): [number, number, string] {
  const chrs = ' ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const mode = chrs.length - 1;
  let number = 0;
  let nbr = '';
  let key = '';
  for (const chr of letters) {
    if (chrs.indexOf(chr) < 0) {
      nbr += chr;
    } else {
      number = number * mode + chrs.indexOf(chr);
      key += chr;
    }
  }
  return [number, parseInt(nbr, 10), key];
}

type DocKeys = keyof model.DocumentListingRow;

const createColmap = (currentRow: number, data: any[]) => {
  const row: { [key: string]: any } = data[currentRow];
  const colMap: { [key: string]: SheetColumn } = {};
  Object.keys(row).forEach((key, index) => {
    colMap[key] = {
      index,
      name: key,
      value: row[key],
    };
  });
  return colMap;
};

export const WalkRows = (
  data: any[],
  colMap: { [key: string]: SheetColumn },
  fromRow: number,
  rowCallback: (row: SheetRow) => void
) => {
  let currentRow = fromRow;
  while (currentRow < data.length) {
    const row = data[currentRow];
    const sheetRow: SheetRow = { mainContractTag: '', values: [] };
    Object.keys(row).map((key, index) => {
      if (colMap[key]) {
        const col = colMap[key];
        sheetRow.values.push({
          index: col.index,
          col: colMap[key],
          value: row[key] + '',
        });
      } else {
        sheetRow.values.push({
          index,
          value: row[key] + '',
        });
      }
    });
    rowCallback(sheetRow);
    currentRow++;
  }
};

const pickExcelValues = (
  rows: SheetRow,
  result: model.DocumentListingRow,
  keys: DocKeys[],
  fromKeys: string[]
) => {
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const from = fromKeys[i];
    const index = parseInt(from, 10);
    if (!isNaN(index) && index >= 0 && index < 1000) {
      rows.values.forEach(v => {
        if (v.index === index) {
          if (typeof v.value === 'string') {
            (result as any)[key] = v.value.trim();
          } else {
            (result as any)[key] = v.value;
          }
        }
      });
    } else {
      rows.values.forEach(v => {
        if (v.col && v.col.value === from) {
          if (typeof v.value === 'string') {
            (result as any)[key] = v.value.trim();
          } else {
            (result as any)[key] = v.value;
          }
        }
      });
    }
  }
};

export const createDocumentListing = (
  workbook: xlsx.WorkBook
): model.DocumentListing => {
  const result: model.DocumentListing = {
    targetContractTag: '',
    rows: [],
  };

  const getRow = (index: number) => {
    if (!result.rows[index]) {
      result.rows[index] = {
        belongsToContractNames: [],
      };
    }
    return result.rows[index];
  };

  const positions = [
    [
      'fileName',
      'startPole',
      'endPole',
      'targetId',
      'content',
      'docLohko',
      'docNumero',
      'docSuunnitteluala',
      'docTunnus',
      'mittakaava',
      'koko',
      'revision',
      'muutosPvm',
      'pvm',
      'dwgFileName',
      'suunnittelija',
    ],
    [
      'I13',
      'G13',
      'H13',
      'F13',
      'E13',
      'D13',
      'C13',
      'A13',
      'B13',
      'J13',
      'K13',
      'M13',
      'N13',
      'L13',
      'P13',
      'O13.',
    ],
  ];

  let idx = 0;

  for (const sheetName of Object.keys(workbook.Sheets)) {
    if (idx++ === 0) {
      const sheet = workbook.Sheets[sheetName];
      const fields = positions[0];

      result.targetContractTag = sheet.F3.v;

      let firstRowsIndex = 1;

      while (
        !(
          sheet[`B${firstRowsIndex}`] &&
          sheet[`B${firstRowsIndex}`].v &&
          sheet[`B${firstRowsIndex}`].v.trim() === 'tunnus'
        )
      ) {
        firstRowsIndex++;
        if (firstRowsIndex > 50) {
          // must be error
          throw new Error('Error parsing Excel');
        }
      }

      const cells = positions[1].map(value => {
        // I13 -> "I" + firstRowIndex
        return value.substring(0, 1) + (firstRowsIndex + 1);
      });

      for (let i = 0; i < fields.length; i++) {
        const field = fields[i];
        const cell = cells[i];
        const [, startRow, colName] = parseExcelCellCode(cell);

        // A13: {t: "s", v: "AK", r: "<t>AK</t>", h: "AK", w: "AK"}
        let rowIndex = startRow;
        while (sheet[colName + rowIndex]) {
          const cellValue = sheet[colName + rowIndex];
          const row = getRow(rowIndex);
          row[field] = cellValue.v;
          rowIndex++;
        }
      }
    }
  }

  return result;
};

export const createDocumentListing2 = (
  workbook: xlsx.WorkBook
): model.DocumentListing => {
  const result: model.DocumentListing = {
    targetContractTag: '',
    rows: [],
  };
  console.log(workbook);
  ParseDesignerDocumentExcel(workbook, rows => {
    if (!result.targetContractTag && rows.values) {
      result.targetContractTag = rows.mainContractTag;
    }
    const row: model.DocumentListingRow = {
      belongsToContractNames: [],
    };
    pickExcelValues(
      rows,
      row,
      [
        'fileName',
        'startPole',
        'endPole',
        'targetId',
        'content',
        'docLohko',
        'docNumero',
        'docSuunnitteluala',
        'docTunnus',
        'mittakaava',
        'koko',
        'revision',
        'muutosPvm',
        'pvm',
        'dwgFileName',
        'suunnittelija',
      ],
      [
        'TIEDOSTO',
        'paalu1',
        'paalu2',
        'SIJAINTI',
        'ASIAKIRJAN SISÄLTÖ\n',
        'lohko',
        'numero',
        'suun.ala',
        'tunnus',
        'MITTA-KAAVA',
        'KOKO',
        'M.\nREV.',
        'MUUTOS PVM',
        '11',
        'dwg',
        'LYH.',
      ]
    );

    rows.values
      .filter(v => v.col && v.col.index >= 17)
      .forEach(cell => {
        if (cell.col && row.belongsToContractNames) {
          row.belongsToContractNames.push(cell.col.value);
        }
      });

    result.rows.push(row);
    return;
  });
  if (result.rows.length === 0) {
    throw new Error('No data in Excel');
  }
  return result;
};

export const ParseDesignerDocumentExcel = (
  workbook: xlsx.WorkBook,
  rowCallback: (row: SheetRow) => void
) => {
  // TODO: create this as streaming parser ?
  // https://docs.sheetjs.com/#streaming-read

  // xlsx.read()

  const sheet_name_list = workbook.SheetNames;
  sheet_name_list.forEach(y => {
    const worksheet = workbook.Sheets[y];

    const data = xlsx.utils.sheet_to_json(worksheet);

    console.log('Sheet ', y);
    console.log(data);

    const row1: any = data[1];
    const mainContractTag = row1.__EMPTY_4;
    if (!mainContractTag) {
      throw new Error('Invalid Excel');
    }
    let currentRow = 6;
    const row: { [key: string]: any } = (data as any[])[currentRow];
    const colMap: { [key: string]: SheetColumn } = {};
    Object.keys(row).map((key, index) => {
      let keyIndex = index;
      if (key.indexOf('__EMPTY_') === 0) {
        keyIndex = parseInt(key.substr('__EMPTY_'.length), 10);
        colMap[key] = {
          index: keyIndex + 2,
          name: key,
          value: row[key],
        };
      }
      return row[key];
    });
    console.log('Colmap ', colMap);
    colMap['__EMPTY'] = {
      index: 1,
      name: '__EMPTY',
      value: 'tunnus',
    };
    colMap['__EMPTY_1'] = {
      index: 1,
      name: '__EMPTY_1',
      value: 'numero',
    };
    colMap['__EMPTY_2'] = {
      index: 1,
      name: '__EMPTY_2',
      value: 'lohko',
    };
    colMap['__EMPTY_5'] = {
      index: 1,
      name: '__EMPTY_5',
      value: 'paalu1',
    };
    colMap['__EMPTY_6'] = {
      index: 1,
      name: '__EMPTY_6',
      value: 'paalu2',
    };
    colMap['ASIAKIRJALUETTELO'] = {
      index: 0,
      name: 'ASIAKIRJALUETTELO',
      value: 'suun.ala',
    };
    colMap[' (EI-TULOSTETTAVAT SARAKKEET )'] = {
      name: ' (EI-TULOSTETTAVAT SARAKKEET )',
      index: 15,
      value: 'dwg',
    };
    currentRow++;
    while (++currentRow < data.length) {
      const row: any = data[currentRow];
      const sheetRow: SheetRow = {
        mainContractTag,
        values: [],
        index: currentRow,
      };
      Object.keys(row).map((key, index) => {
        if (colMap[key]) {
          const col = colMap[key];
          sheetRow.values.push({
            index: col.index,
            col: colMap[key],
            value: row[key] + '',
          });
          // console.log(colMap[key].value, ' => ', row[key]);
        } else {
          sheetRow.values.push({
            index,
            value: row[key] + '',
          });
        }
      });
      rowCallback(sheetRow);
    }
    // console.log(colMap);
  });
  return sheet_name_list;
};

export const parseBuildingInformation = (
  workBook: xlsx.WorkBook
): model.BuildingListing => {
  const res: model.BuildingListing = {
    rows: [],
  };
  const sheet_name_list = workBook.SheetNames;
  sheet_name_list.forEach((y, index) => {
    console.log('parseBuildingInformation', y);
    if (y !== 'segments') {
      return;
    }

    const worksheet = workBook.Sheets[y];
    const data = xlsx.utils.sheet_to_json(worksheet) as any[];
    const colMap = createColmap(0, data);
    console.log(colMap);
    if (Object.keys(colMap).length !== 3) throw new Error('Not a building map');
    try {
      WalkRows(data, colMap, 3, row => {
        const getValue = (idx: number): string => {
          let res = '';
          row.values.forEach(v => {
            if (v.col && v.col.index === idx) {
              res = v.value;
            }
          });
          return res;
        };
        res.rows.push({
          Id: getValue(0),
          Name: getValue(1),
          Order: getValue(2),
        });
      });
    } catch (e) {
      console.error(e);
    }
  });
  if (res.rows.length === 0) {
    throw new Error('No data in Excel');
  }
  return res;
};

export const parseRoomInformation = (
  workBook: xlsx.WorkBook
): model.RoomListing => {
  const res: model.RoomListing = {
    rows: [],
  };
  const sheet_name_list = workBook.SheetNames;
  sheet_name_list.forEach((y, index) => {
    if (index > 0) {
      return;
    }
    // if (index === 0) {
    const worksheet = workBook.Sheets[y];
    const data = xlsx.utils.sheet_to_json(worksheet) as any[];
    const colMap = createColmap(0, data);

    // if (Object.keys(colMap).length !== 5) throw new Error('Not a room map');
    try {
      WalkRows(data, colMap, 5, row => {
        const getValue = (idx: number): string => {
          let res = '';
          row.values.forEach(v => {
            if (v.col && v.col.index === idx) {
              res = v.value;
            }
          });
          return res;
        };
        res.rows.push({
          Id: getValue(0),
          Floor: getValue(1),
          RoomId: getValue(2),
          Segment: getValue(3),
          RoomName: getValue(4),
          AreaName: getValue(5),
        });
      });
    } catch (e) {
      console.error(e);
    }
  });
  if (res.rows.length === 0) {
    throw new Error('No data in Excel');
  }
  return res;
};

export const parseAreaInformation = (
  workBook: xlsx.WorkBook
): model.AreaListing => {
  const res: model.AreaListing = {
    rows: [],
  };

  for (const sheetName of Object.keys(workBook.Sheets)) {
    const sheet = workBook.Sheets[sheetName];
    const header = xlsx.utils.sheet_to_json(sheet, {
      header: 1,
      defval: '',
      blankrows: true,
    }) as any[];

    const header_values =
      header.length > 0
        ? header[0].filter(h => h.length > 0 && h.length < 8)
        : [];

    const data = xlsx.utils.sheet_to_json(sheet) as any[];

    for (const row of data) {
      const keys = Object.keys(row).filter(
        k => k !== '__EMPTY' && k !== '__rowNum__'
      );

      if (keys[0] && row.__EMPTY && header_values.indexOf(keys[0]) !== -1) {
        const section_value = row.__EMPTY.split(' ');
        // const section = section_value[0];
        const pos = section_value[1];
        const key = keys[0];
        let existingIndex = -1;
        res.rows.forEach((r, i) => {
          if (r.name === key) existingIndex = i;
        });

        const section = {
          name: section_value[0],
          pole_start_pos: pos && pos.split('-')[0],
          pole_end_pos: pos && pos.split('-')[1],
        };

        if (existingIndex < 0) {
          // res[key] = [];
          res.rows.push({ name: key, sections: [section] });
        } else {
          const existing = res.rows[existingIndex];
          if (existing && existing.sections) {
            existing.sections.push(section);
            res.rows[existingIndex] = existing;
          } else {
            res.rows[existingIndex].sections = [section];
          }
        }
      }
    }
  }

  return res;
};

export async function parseDeviceList(
  file: File
): Promise<model.DeviceListRow[]> {
  const workBook: xlsx.WorkBook = await readFileToWorksheet(file);

  const worksheet = workBook.Sheets[workBook.SheetNames[0]];
  const spreadsheetHeaders = parseData(worksheet)[0] as string[];
  const rowData = parseData(worksheet).slice(1) as RowData[][];

  const headersToDbKeys = {
    readMetaInfo: 'Ominaisuustiedot luetaan',
    oldId: 'old_id',
    newOldId: 'new_old_id',
    targetcode: 'Uusi kohdetunnus',
    oldTargetcode: 'Kohdetunnus',
    pitemTypeName: 'Kohdetyyppi',
    description: 'Kohteen kuvaus',
    priority: 'Prioriteetti',
    system: 'Järjestelmän nimi',
    upperEquipment: 'Päälaite',
    buildingElement: 'Rakennusosa',
    location: 'Huonetunnus',
    maintenanceArea: 'Huoltoalue',
    documents: 'Dokumentit',
    warrantyExpires: 'Takuuaika',
    secondaryHierarchy: 'Muu hierarkia',
    additionalInformation: 'Lisätieto1',
    additionalInformation2: 'Lisätieto2',
    roomPoleStart: 'Alkupaalu',
    roomPoleEnd: 'Loppupaalu',
    specifier: 'Laitekoodi',
  };

  const rows = parseSpreadsheetData(
    spreadsheetHeaders,
    rowData,
    headersToDbKeys
  );

  rows.map(row => {
    // Documents are listed in row.documents, separated by semicolon
    if (row.documents && typeof row.documents === 'string') {
      row.documents = String(row.documents).split(';');
    }

    // Cleanup malformed warranty dates
    if (!(row.warrantyExpires instanceof Date)) {
      row.warrantyExpires = undefined;
    }

    return row;
  });

  return (rows as unknown) as model.DeviceListRow[];
}

export const parseItemInformation = (workBook: xlsx.WorkBook) => {
  const res: model.ItemListing = {
    pitemType: '',
    rows: [],
  };

  // Get the pitem type from the first cell of the second sheet in the file
  // `.v` is cells raw value https://docs.sheetjs.com/#cell-object
  res.pitemType = workBook.Sheets[workBook.SheetNames[1]].A1.v;

  // Collect the data from the workbooks first sheet
  const worksheet = workBook.Sheets[workBook.SheetNames[0]];
  const headers = parseData(worksheet)[1] as string[];
  const rowData = parseData(worksheet).slice(5) as RowData[][];

  // Build the response
  rowData.forEach((row, rowIndex) => {
    row = row.map((cell, cellIndex) => {
      switch (headers[cellIndex]) {
        case 'Tracking':
          return (
            typeof cell === 'string' && cell.toUpperCase().trim() === 'TRUE'
          );
        case 'Contracts':
          return typeof cell === 'string'
            ? cell.split(',').map(aitemId => aitemId.trim())
            : undefined;
        default:
          return cell;
      }
    });

    // Build the final object from headers and rows
    res.rows[rowIndex] = (_.zipObject(
      headers,
      row
    ) as unknown) as model.ItemDefinitionRow;
  });

  if (res.rows.length === 0) {
    throw new Error('No data in Excel');
  }

  return res;
};

export const parsePhaseInfo = (workBook: xlsx.WorkBook): model.RoomListing => {
  const res: model.RoomListing = {
    rows: [],
  };
  const sheet_name_list = workBook.SheetNames;
  sheet_name_list.forEach((y, index) => {
    if (index > 0) {
      return;
    }
    const worksheet = workBook.Sheets[y];
    const data = xlsx.utils.sheet_to_json(worksheet) as any[];
    const colMap = createColmap(2, data);
    if (Object.keys(colMap).length !== 5) throw new Error('Not a room map');
    try {
      WalkRows(data, colMap, 5, row => {
        const getValue = (idx: number): string => {
          let res = '';
          row.values.forEach(v => {
            if (v.col && v.col.index === idx) {
              res = v.value;
            }
          });
          return res;
        };
        res.rows.push({
          Id: getValue(0),
          Floor: getValue(1),
          RoomId: getValue(2),
          Segment: getValue(3),
          RoomName: getValue(4),
        });
      });
    } catch (e) {
      console.error(e);
    }
  });
  if (res.rows.length === 0) {
    throw new Error('No data in Excel');
  }
  return res;
};

export const parsePhaseContractInfo = (
  workBook: xlsx.WorkBook
): model.PhaseContractBuilding => {
  const res: model.PhaseContractBuilding = {
    phases: {},
  };
  const sheet_name_list = workBook.SheetNames;
  sheet_name_list.forEach((y, index) => {
    if (index > 0) {
      return;
    }
    const worksheet = workBook.Sheets[y];
    const data = xlsx.utils.sheet_to_json(worksheet) as any[];
    const colMap = createColmap(0, data);
    const colVaiheUrakka: { [key: string]: string[] } = {};
    if (colMap['Vaihe'].value === 'Urakka') {
      Object.keys(colMap).forEach(key => {
        const item = colMap[key];
        const parts = key.split('_');
        const vaihe = parseInt(parts[0], 10);
        if (!isNaN(vaihe) && parts[0] !== 'Vaihe') {
          colVaiheUrakka[key] = [parts[0], item.value];
          if (!res.phases[vaihe]) {
            res.phases[vaihe] = {
              contracts: {},
            };
          }
        }
      });
      WalkRows(data, colMap, 1, row => {
        row.values.forEach(cell => {
          if (!cell || !cell.col) return;
          if (colVaiheUrakka[cell.col.name]) {
            const [vaihe, urakka] = colVaiheUrakka[cell.col.name];
            if (!res.phases[vaihe].contracts[urakka]) {
              res.phases[vaihe].contracts[urakka] = [];
            }
            res.phases[vaihe].contracts[urakka].push(cell.value);
          }
        });
      });
    } else {
      throw new Error('Not Phase listing');
    }
  });
  return res;
};
