import * as React from 'react';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { PrintOrder } from '../../../../shared/src/model/index';
import PrintOrderRowsTable from './PrintOrderRowsTable';

interface PrintOrderSummaryProps {
  order: PrintOrder;
}

class PrintOrderSummary extends React.PureComponent<
  PrintOrderSummaryProps & InjectedIntlProps
> {
  tableRow(cells: string[]) {
    return (
      <div style={{ display: 'table-row' }}>
        {cells.map((cell, i) => (
          <div key={i} style={{ display: 'table-cell', paddingRight: '15px' }}>
            {cell}
          </div>
        ))}
      </div>
    );
  }

  public render() {
    const order = this.props.order;
    const t = (id: string) => this.props.intl.formatMessage({ id });
    return (
      <div>
        <h2>
          <FormattedMessage id="printOrder.ordererInfo" />
        </h2>
        <br />
        <div style={{ display: 'table' }}>
          {this.tableRow([t('printOrder.orderer'), order.orderer])}
          {this.tableRow([t('printOrder.project'), order.projectName])}
        </div>
        <br />

        <h3>
          <FormattedMessage id="printOrder.ordererContact" />
        </h3>
        <br />
        <p>
          {order.ordererFirstName} {order.ordererLastName}
        </p>
        <p>{order.ordererEmail}</p>
        <p>{order.ordererPhoneNumber}</p>
        <br />

        <h2>
          <FormattedMessage id="printOrder.payerInfo" />
        </h2>
        <br />
        <p>{order.payerCompany}</p>
        <p>{order.payerAddress}</p>
        <p>
          {order.payerPostalCode} {order.payerCity}
        </p>
        <p>{order.payerCountry}</p>

        <br />
        {order.orderAdditionalInfo && (
          <div>
            <h2>
              <FormattedMessage id="printOrder.orderAdditionalInfo" />
            </h2>
            <br />
            <p style={{ whiteSpace: 'pre-wrap' }}>
              {order.orderAdditionalInfo}
            </p>
            <br />
          </div>
        )}

        <h5>
          <FormattedMessage id="printOrder.documentsInOrder" />:
        </h5>
        <PrintOrderRowsTable
          orderRows={this.props.order.orderRows}
          showActions={false}
        />
      </div>
    );
  }
}

export default injectIntl(PrintOrderSummary);
