import * as React from 'react';
import * as appState from '../../state/reducers/ApplicationState';
import * as documentState from '../../state/reducers/DocumentState';
import { FormattedMessage, injectIntl, InjectedIntlProps } from 'react-intl';

import './documentcollection.scss';

import MainLayout from '../../layout/MainLayout';

import * as Model from '../../../../shared/src/model/index';
import * as excelImport from '../../utils/excel';
import Loading from '../Common/Loading';
// import DocumentTable from '../Common/DocumentTable';
import * as xlsx from 'xlsx';
import WarningIcon from '@material-ui/icons/WarningRounded';

import OkIcon from '@material-ui/icons/CheckCircle';

import axios from 'axios';
import { DocumentService } from '../../services/document/src/frontend/api';
import { APIService } from '../../services/api/src/frontend/api';
import DocumentListing, { DocumentListingResponse } from './DocumentListing';

import { pitem } from '../../../../shared/src/model/projectSchema';
import { DataTable } from './DataTable';
import { Card, CardContent } from '@material-ui/core';
import DocumentTable from '../Common/DocumentTable';
import { push } from 'connected-react-router';
import { Notifier, errorMessage, warningMessage } from '../Common/Notifier';

import { Link } from 'react-router-dom';
import Breadcrumbs from '../Common/Breadcrumbs';

const DOCAPI = DocumentService(axios);
const API = APIService(axios);
// import Moment from 'react-moment';

interface IUploadDocumentsProps extends appState.IProps, documentState.IProps {}

interface IUploadDocumentsState {
  conversionErrors: string[];

  isProcessing: boolean;
  isProcessingReady: boolean;

  docListing?: Model.DocumentListing;
  matchedRows?: Model.DocumentListingRow[];
  notMatchedFiles?: File[];
  databaseFiles?: Model.UIDocument[];
  buildings?: pitem[];

  documentDocuments?: Model.AdminDocumentUpload;

  isUploading: boolean;
  isUploadReady: boolean;
  uploadedFiles?: Model.UIDocument[];
  uploadErrors: string[];

  uploadUUID: string;
}

type UploadDocumentsProps = IUploadDocumentsProps & InjectedIntlProps;

class AbstractUploadDocuments extends React.Component<
  UploadDocumentsProps,
  IUploadDocumentsState
> {
  updateUUID = '';
  initCnt = 1;

  constructor(props: UploadDocumentsProps) {
    super(props);
    this.state = {
      isProcessing: false,
      isProcessingReady: false,
      isUploading: false,
      uploadErrors: [],
      isUploadReady: false,
      uploadUUID: '',
      conversionErrors: [],
    };
  }

  componentDidMount() {
    this.updateDocuments();
    if (this.state.uploadUUID !== this.props.documentCollectionUpload.uuid) {
      this.setState({
        isUploading: false,
      });
    }
  }

  async uploadDesignerDocuments(data: DocumentListingResponse) {
    if (this.props.documentCollectionUpload.files) {
      try {
        const uiDocs = this.convertToUIDocs(data);
        const files = this.props.documentCollectionUpload.files;
        this.setState({
          isUploading: true,
          uploadErrors: [],
          uploadedFiles: [],
        });
        const config = {
          headers: {
            'content-type': 'multipart/form-data',
          },
        };
        for (const doc of uiDocs) {
          if (this.props.documentCollectionUpload.onlyMetadata) {
            // console.log('Upload for ', doc);
            if (this.state.databaseFiles) {
              const dbFiles = this.state.databaseFiles.filter(
                f => f.fileName === doc.fileName
              );
              if (dbFiles.length > 0) {
                const dbFile = dbFiles[0];
                doc.id = dbFile.id;
                // Then, try updating
                const resultDoc = await DOCAPI.updateDocument(doc);
                this.setState(state => {
                  const prev = state.uploadedFiles || [];
                  return {
                    uploadedFiles: [...prev, resultDoc],
                  };
                });
              }
            }
          } else {
            const theFile = files.filter(f => f.name === doc.fileName).pop();
            if (theFile) {
              try {
                const formData = new FormData();
                formData.append('info', JSON.stringify(doc));
                formData.append('file', theFile);
                const result = (
                  await axios.post('/docs/v1/upload/doc2', formData, config)
                ).data as Model.UIDocument[];
                this.setState(state => {
                  const prev = state.uploadedFiles || [];
                  return {
                    uploadedFiles: [...prev, result[0]],
                  };
                });
              } catch (e) {
                this.setState(state => {
                  const prev = state.uploadErrors || [];
                  return {
                    uploadErrors: [...prev, `${e}`],
                  };
                });
              }
            }
          }
        }
        this.setState({
          isUploadReady: true,
        });
      } catch (e) {
        errorMessage(e);
        // TODO: display error on the page if error was thrown
      }
    }
  }

  convertToUIDocs = (data: DocumentListingResponse): Model.UIDocument[] => {
    const res: Model.UIDocument[] = [];
    for (const row of data.rows) {
      const ddList: any[] = [];
      if (row.docSuunnitteluala) {
        const sa = this.props.reverseTypeConversions[row.docSuunnitteluala];
        const list = this.props.designDisciplines.filter(
          item => item.name.localeCompare(sa) === 0
        );
        if (list.length) {
          ddList.push(list[0].id);
        }
      }

      const o: Model.UIDocument = {
        id: 0,
        name: row.fileName || '',
        createdByUserId: 0,
        workflowStateId: 0,
        fileName: row.fileName || '',
        fileExtension: '',
        created: '',
        modified: '',
        categories: data.discipline
          ? [...ddList, data.discipline.id]
          : [...ddList],
        contractIds: [],
        contractStr: '',
        buildingElement: '',
        pItems: data.buildings
          .filter(item => item.name === row.targetId)
          .map(item => ({
            id: item.id,
            name: item.name!,
            longName: item.name_long!,
          })),
        aItems: this.props.userContracts
          .filter(c => c.aitem_name === data.aItemName)
          .map(row => ({
            id: row.aitem_id,
            name: row.aitem_name,
          })),
        revision: row.revision || '',
        description: row.content || '',
        dwgName: row.dwgFileName || '',
        startPole: row.startPole ? parseInt(row.startPole, 10) : undefined,
        endPole: row.endPole ? parseInt(row.endPole, 10) : undefined,
        downloadUrl: '',
        ObjVer: undefined,
        allowedActions: [],
        versionNumber: 1,
        displayData: {
          updateEvenIfSame: true,
        },
      };
      if (o.startPole && isNaN(o.startPole)) {
        o.startPole = undefined;
      }
      if (o.endPole && isNaN(o.endPole)) {
        o.endPole = undefined;
      }
      res.push(o);
    }
    return res;
  };
  handleRedirectToDocument = (id: number) => {
    this.props.DocumentStateDispatcher(push('/documents/' + id));
  };
  async updateDocuments() {
    if (
      this.props.documentCollectionUpload.documentCollection &&
      this.updateUUID !== this.props.documentCollectionUpload.uuid &&
      this.props.documentCollectionUpload.uuid
    ) {
      this.updateUUID = this.props.documentCollectionUpload.uuid;
      const reader = new FileReader();
      reader.onload = async (e: any) => {
        try {
          const data = reader.result;
          const workbook = xlsx.read(data, {
            type: 'binary',
          });
          const docList = excelImport.createDocumentListing(workbook);

          // NOTE: files list must be empty, if
          // this.props.documentCollectionUpload.onlyMetadata
          if (this.props.documentCollectionUpload.files) {
            const files = this.props.documentCollectionUpload.files;
            const fileNames: { [key: string]: File } = files.reduce(
              (prev, curr) => {
                return {
                  ...prev,
                  [curr.name]: curr,
                };
              },
              {}
            );

            const lowerCaseFileNames: { [key: string]: File } = files.reduce(
              (prev, curr) => {
                return {
                  ...prev,
                  [curr.name.toLocaleLowerCase()]: curr,
                };
              },
              {}
            );
            const notMatchedFiles: File[] = [];
            const foundFiles: { [key: string]: boolean } = {};
            // if only metadata is needed...
            const matched = this.props.documentCollectionUpload.onlyMetadata
              ? docList.rows
              : docList.rows.filter(row => {
                  if (!row.fileName) {
                    return false;
                  }
                  if (fileNames[row.fileName]) {
                    foundFiles[row.fileName] = true;
                  }
                  if (
                    lowerCaseFileNames[row.fileName.toLocaleLowerCase()] &&
                    !fileNames[row.fileName]
                  ) {
                    errorMessage(
                      `Tiedostossa ${row.fileName} tiedoston nimessä isot ja pienet kirjaimet väärin`
                    );
                  }
                  if (row.dwgFileName) {
                    if (fileNames[row.dwgFileName]) {
                      foundFiles[row.dwgFileName] = true;
                    }
                  }
                  return (
                    fileNames[row.fileName] ||
                    (row.dwgFileName ? fileNames[row.dwgFileName] : false)
                  );
                });

            if (!this.props.documentCollectionUpload.onlyMetadata) {
              files.forEach(file => {
                if (!foundFiles[file.name]) {
                  notMatchedFiles.push(file);
                }
              });
            }
            // Get Buildings based on contract names...
            const buildings = await API.getBuildingsForContractName(
              docList.targetContractTag
            );
            const resultFiles = [
              ...matched.map(row => row.fileName || ''),
              ...matched.map(row => row.dwgFileName || ''),
            ].filter(item => item && item.length > 0);
            const linked = await DOCAPI.getLinkedDocs({
              aitemname: docList.targetContractTag,
              docs: resultFiles,
              categoryId: this.props.documentCollectionUpload.disciplineId,
            });
            const linkedNames: { [key: string]: boolean } = linked.reduce(
              (prev, curr) => {
                return { ...prev, [curr.fileName]: true };
              },
              {}
            );
            this.initCnt++;
            const finalRows = !this.props.documentCollectionUpload.onlyMetadata
              ? matched
              : matched.filter(doc => {
                  if (!doc.fileName) {
                    return false;
                  }
                  return linkedNames[doc.fileName];
                });

            if (finalRows.length === 0) {
              warningMessage(
                'Yhtään asiakirjaluetteloa vastaavaa tiedostoa ei löytynyt'
              );
            }
            this.setState({
              docListing: docList,
              matchedRows: finalRows,
              notMatchedFiles,
              buildings,
              isProcessing: false,
              isProcessingReady: true,
              databaseFiles: linked,
              conversionErrors: [],
            });
          }
        } catch (e) {
          this.setState({
            conversionErrors: ['Asiakirjaluettelo oli virheellinen'],
          });
        }
      };

      reader.onerror = ex => {
        console.log(ex);
      };
      this.setState(
        {
          isProcessing: true,
          isProcessingReady: false,
          isUploading: false,
          isUploadReady: false,
          uploadedFiles: [],
          uploadErrors: [],
        },
        () => {
          if (this.props.documentCollectionUpload.documentCollection) {
            reader.readAsBinaryString(
              this.props.documentCollectionUpload.documentCollection
            );
          }
        }
      );
    }
  }

  componentDidUpdate() {
    this.updateDocuments();
  }

  public render() {
    const { loading } = this.props.docstatus.uploadDesignerDocuments;
    const t = this.props.intl;
    const loadingCollection = this.props.docstatus.uploadDocumentCollection
      .loading;

    if (this.state.conversionErrors.length > 0) {
      return (
        <MainLayout>
          <Notifier
            snack={{
              variant: 'warning',
              message: this.state.conversionErrors.join(' '),
            }}
          />
        </MainLayout>
      );
    }

    // if nothing selected
    if (!this.props.documentCollectionUpload.documentCollection) {
      return (
        <MainLayout>
          <Breadcrumbs>
            <Link to="/">
              <FormattedMessage id="headers.home" />
            </Link>
            <FormattedMessage id="upload.documentCollection" />
          </Breadcrumbs>

          <h1>
            <FormattedMessage id="upload.documentCollection" />
          </h1>
        </MainLayout>
      );
    }

    const saveProgress = `${this.props.uploadResults.length}/${this.props.uploadDocumentAmount}`;

    const wasUpdatedInfo = (doc: Model.UIDocument) => {
      if (doc.displayData && doc.displayData.fileWasNotUpdated) {
        return (
          <span>
            <WarningIcon className="warningIcon" /> Tiedostoa ei voitu
            päivittää: sama tiedosto oli jo lisätty aiemmin
          </span>
        );
      }
      return (
        <span>
          <OkIcon className="successIcon" /> Onnistui
        </span>
      );
    };
    return (
      <MainLayout>
        <Breadcrumbs>
          <Link to="/">
            <FormattedMessage id="headers.home" />
          </Link>
          <FormattedMessage id="upload.documentCollection" />
        </Breadcrumbs>

        <h1>
          <FormattedMessage id="upload.documentCollection" />
        </h1>

        <div className="grid gridCols1">
          {!(
            this.state.docListing &&
            this.state.matchedRows &&
            this.state.databaseFiles &&
            this.state.buildings &&
            this.props.designDisciplines.length > 0
          ) ? (
            <Loading loading={true} />
          ) : (
            <>
              <div className="pageTitle">
                <Loading
                  loading={loading || loadingCollection}
                  label={
                    loadingCollection
                      ? t.formatMessage({
                          id: 'upload.updatingDocumentsFromCollection',
                        })
                      : `${t.formatMessage({
                          id: 'upload.saving',
                        })}... ${saveProgress}`
                  }
                />
              </div>

              {this.state.isUploading ? (
                <>
                  {this.state.uploadErrors &&
                  this.state.uploadErrors.length > 0 ? (
                    <Card>
                      <CardContent>
                        <div style={{ marginTop: '10px' }}>Virheet</div>
                        <DataTable
                          keys={['text']}
                          rows={
                            this.state.uploadErrors.map(s => ({ text: s })) ||
                            []
                          }
                        />
                      </CardContent>
                    </Card>
                  ) : null}
                  <DocumentTable
                    title="Päivitetyt tiedostot"
                    rows={(this.state.uploadedFiles || []).map(row => ({
                      ...row,
                      aItems: (
                        <span>
                          {row.aItems.map(item => item.name).join(', ')}
                        </span>
                      ),
                      pItems: (
                        <span>
                          {row.pItems.map(item => item.name).join(', ')}
                        </span>
                      ),
                      displayData: wasUpdatedInfo(row),
                    }))}
                    keys={{
                      displayData: 'Tilanne',
                      fileName: 'Tiedosto',
                      fileSize: 'Koko',
                      startPole: '1. Paalu',
                      endPole: '2. Paalu',
                      revision: 'Revisio',
                      pItems: 'Kohde',
                      aItems: 'Urakka',
                    }}
                    rowClick={row => this.handleRedirectToDocument(row.id)}
                  />{' '}
                </>
              ) : null}

              <>
                {!this.state.isProcessing &&
                this.state.matchedRows &&
                this.state.matchedRows.length > 0 ? (
                  <DocumentListing
                    uploadReady={this.state.isUploadReady}
                    updateId={this.updateUUID + '' + this.initCnt}
                    discipline={this.props.docCategories
                      .filter(
                        item =>
                          item.id ===
                          this.props.documentCollectionUpload.disciplineId
                      )
                      .pop()}
                    buildings={this.state.buildings}
                    docListing={this.state.docListing}
                    matchedRows={this.state.matchedRows}
                    databaseFiles={this.state.databaseFiles}
                    onSave={data => {
                      this.uploadDesignerDocuments(data);
                    }}
                    onUpdateCategory={id => {
                      this.props.setDocCollectionDocumentTypeId(id);
                      this.updateDocuments();
                    }}
                  />
                ) : null}

                {this.state.notMatchedFiles &&
                this.state.notMatchedFiles.length > 0 ? (
                  <Card>
                    <CardContent>
                      <div style={{ marginTop: '10px' }}>
                        Seuraavia dokumentteja ei löytynyt asiakirjaluettelosta
                      </div>
                      <DataTable
                        keys={['name', 'size']}
                        rows={this.state.notMatchedFiles}
                      />
                    </CardContent>
                  </Card>
                ) : null}
              </>
            </>
          )}
        </div>
      </MainLayout>
    );
  }
}

const UploadDocuments = documentState.StateConnector(
  appState.StateConnector(AbstractUploadDocuments)
);

export default injectIntl(UploadDocuments);
