import * as React from 'react';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import * as applicationState from '../../state/reducers/ApplicationState';
// import { setBackgroundImage } from '../../utils';
import AdminTools from '../AdminTools';
import './adminTools.scss';

import axios from 'axios';
import { AuthService } from '../../services/auth/src/frontend/api';
import { APIService } from '../../services/api/src/frontend/api';
import { Button } from '@material-ui/core';
import TextField from '../Common/TextField';
const AUTH = AuthService(axios);
const API = APIService(axios);

interface IServiceMessageProps extends applicationState.IProps {}

interface IServiceMessageState {
  message: string;
  isDown: boolean;
}

type ServiceMessageProps = IServiceMessageProps & InjectedIntlProps;

class AbstractServiceMessage extends React.Component<
  ServiceMessageProps,
  IServiceMessageState
> {
  constructor(props: ServiceMessageProps) {
    super(props);
    this.state = { message: '', isDown: false };
  }

  componentDidMount = async () => {
    this.setState({
      isDown: (await AUTH.getServiceDown()).down,
    });
  };

  setServiceDown = async () => {
    if (!this.state.isDown) {
      if (
        !window.confirm(
          'Oletko varma, että haluat asettaa järjestelmän huoltotilaan? Kaikki käyttäjät kirjataan ulos.'
        )
      ) {
        return;
      }
    }
    this.setState({ isDown: !this.state.isDown });
    await AUTH.setServiceDown({ down: !this.state.isDown });
  };

  render() {
    return (
      <AdminTools>
        <div className="grid gridCols3">
          <div className="component master content">
            <h2>
              <FormattedMessage id="adminTools.mainteanance" />
            </h2>

            <div className="serviceStateArea">
              <div>
                {this.state.isDown ? (
                  <div className="systemIsAtDownState">
                    <FormattedMessage id="adminTools.systemHasMainetananceBreak" />
                  </div>
                ) : null}
                <div>
                  <TextField
                    value={this.state.message}
                    multiline={true}
                    onChange={e => {
                      this.setState({ message: e.target.value });
                    }}
                    label={
                      <FormattedMessage id="adminTools.setSystemBreakMessage" />
                    }
                  />
                </div>
                <Button
                  color="primary"
                  onClick={async () => {
                    API.systemDownMessage(this.state.message || ' ');
                  }}
                >
                  Päivitä viesti
                </Button>
              </div>

              <Button
                color="primary"
                onClick={async () => await this.setServiceDown()}
              >
                {this.state.isDown ? (
                  <FormattedMessage id="adminTools.stopMainetananceBreak" />
                ) : (
                  <FormattedMessage id="adminTools.startMainetananceBreak" />
                )}
              </Button>
            </div>
          </div>
        </div>
      </AdminTools>
    );
  }
}

const AprovalCycles = applicationState.StateConnector(AbstractServiceMessage);

export default injectIntl(AprovalCycles);
