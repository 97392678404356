import * as React from 'react';
import * as Model from '../../../shared/src/model';
import { useUnsafeFormatMessage } from 'utils/translateHook';
import * as _ from 'lodash';
import {
  defaultValue,
  propTranslateString,
  usePropertyCollection,
} from './utils';
import Moment from 'react-moment';
import RefProperty from './ref/RefProperty';
import { TextField } from '@material-ui/core';
import Checkbox from '../components/Common/Checkbox';
import { ClassNames, CollectionFetchParams } from './batch';
import { Button } from '@material-ui/core';
import Loading from '../components/Common/Loading';

export type ViewModes = 'list' | 'default';
interface CommonPropertyProps<T extends Model.GenericPropTypes> {
  viewMode?: ViewModes;
  editMode?: boolean;
  onChange?: (data: Model.GenericProp<T>) => void;
}
export interface PropertyProps<T extends Model.GenericPropTypes>
  extends CommonPropertyProps<T> {
  data?: Model.GenericProp<T>;
}
export interface SubPropertyProps<T extends Model.GenericPropTypes>
  extends CommonPropertyProps<T> {
  data: Model.GenericProp<T>;
}

export const ListRow = (props: {
  data: Model.GenericProp<any>;
  contents: any;
}) => {
  const t = useUnsafeFormatMessage();
  return (
    <>
      <dt>{t(propTranslateString(props.data))}</dt>
      <dd>{props.contents}</dd>
    </>
  );
};

export const TextProperty = (props: SubPropertyProps<'text' | 'number'>) => {
  const [value, setValue] = React.useState(props.data.value || '');
  const t = useUnsafeFormatMessage();
  const { data, viewMode, editMode } = props;
  const contents = data.value ? data.value : defaultValue(data);

  React.useEffect(() => {
    setValue(props.data.value || '');
  }, [editMode]);

  if (editMode) {
    switch (viewMode) {
      case 'list':
        return (
          <div>
            <TextField
              label={t(propTranslateString(props.data))}
              type={'text'}
              value={value}
              name={data.field}
              fullWidth={true}
              error={
                props.data.updateStatus && props.data.updateStatus.errorMessage
                  ? true
                  : false
              }
              helperText={
                props.data.updateStatus && props.data.updateStatus.errorMessage
                  ? t(props.data.updateStatus.errorMessage)
                  : null
              }
              onChange={(e) => {
                setValue(e.target.value);
                if (props.onChange) {
                  props.onChange({ ...props.data, value: e.target.value });
                }
              }}
            />
          </div>
        );
      default:
        return <span>{contents}</span>;
    }
  }
  switch (viewMode) {
    case 'list':
      return <ListRow data={data} contents={contents} />;
    default:
      return <span>{contents}</span>;
  }
};

export const BooleanProperty = (props: SubPropertyProps<'boolean'>) => {
  const t = useUnsafeFormatMessage();
  const row = props.data;

  const contents = (
    <div>
      <div style={{ color: 'red' }}>
        {props.data.updateStatus &&
          props.data.updateStatus.errorMessage &&
          t(props.data.updateStatus.errorMessage)}
      </div>{' '}
      <Checkbox
        checked={row.value as boolean}
        label={t(propTranslateString(props.data))}
        onChange={(e, checked) => {
          if (props.editMode) {
            props.onChange && props.onChange({ ...row, value: checked });
          }
        }}
        control={<div />}
      />
    </div>
  );

  if (props.editMode) {
    return contents;
  }

  switch (props.viewMode) {
    case 'list':
      return <ListRow data={props.data} contents={contents} />;
    default:
      return contents;
  }
};

export const DateProperty = (props: SubPropertyProps<'date'>) => {
  const { data, viewMode } = props;
  const contents = data.value ? (
    <Moment date={data.value} format="DD.MM.YYYY HH:mm" />
  ) : (
    `${defaultValue(data)}`
  );

  switch (viewMode) {
    case 'list':
      return <ListRow data={data} contents={contents} />;
    default:
      return <span>{contents}</span>;
  }
};

// Generic & Default property view which can be extended (used as a fallback)
// if a component only needs to customize certain typedefs
export const Property: React.FC<PropertyProps<any>> = (props) => {
  // If no actual property was provided to this component, we can't render anything
  if (!props.data) return null;

  // SubProperties can always assume that there is a prop to be rendered
  // however, they must take into account potential undefined/null value fields
  const subProps: SubPropertyProps<any> = { ...props, data: props.data! };

  switch (props.data.typedef) {
    case 'number':
    /*  return <NumberProperty {...subProps} />;*/
    case 'text':
      return <TextProperty {...subProps} />;
    case 'boolean':
      return <BooleanProperty {...subProps} />;
    case 'date':
      return <DateProperty {...subProps} />;
    case 'ref':
      return <RefProperty {...subProps} />;
    case 'refs':
      return <RefProperty {...subProps} />;
    default:
      return null;
  }
};

// property which hosts property and it's updates
export const EditProperty = <T extends ClassNames>(props: {
  id: number;
  className: T;
  fields: CollectionFetchParams<T>;
}) => {
  const t = useUnsafeFormatMessage();
  const [row, setValue, save] = usePropertyCollection(
    props.id,
    props.className,
    props.fields
  );

  if (!row) {
    return <Loading loading={true} />;
  }
  const fieldNames = Object.keys(row);
  return (
    <>
      {fieldNames.map((field) => {
        const data = row[field];
        return (
          <Property
            key={field}
            data={data}
            viewMode="list"
            editMode={true}
            onChange={(value) => {
              setValue(value);
            }}
          />
        );
      })}
      <div>
        <Button onClick={() => save(row)}>{t('settings.save')}</Button>
      </div>
    </>
  );
};
