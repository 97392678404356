/**********************************************************************************
 *                                                                                 *
 *   Redux Reducers and React Context API Provider/Consumer for state AdminState   *
 *   Generated by ts2redux from Source file ../AdminState.ts                       *
 *                                                                                 *
 **********************************************************************************/

import * as immer from 'immer';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { IState } from './index';
import * as React from 'react';
import { APIService } from '../../services/api/src/frontend/api';
import { DocumentService } from '../../services/document/src/frontend/api';
import { AuthService } from '../../services/auth/src/frontend/api';

import axios from 'axios';
import { push } from 'connected-react-router';

// import * as Model from '../../../shared/src/model';
import * as schema from '../../../../shared/src/model/projectSchema';
import * as model from '../../../../shared/src/model';
import * as secmodel from '../../../../shared/src/model/security';
import {
  Fixture,
  DocumentCategory,
} from '../../../../shared/src/api/testing/types';
import * as Model from '../../../../shared/src/model';
import {
  SnackDetails,
  ActionStatusDetail,
  TranslationDetails,
} from '../helpers/Action';
import * as _ from 'lodash';

const API = APIService(axios);
const DOCAPI = DocumentService(axios);
const AUTH = AuthService(axios);

export interface Permission {
  id: number;
}

type StatusCodes = keyof ActionStatus;

export interface ActionStatus {
  newuser: ActionStatusDetail;
  newUserFromLdap: ActionStatusDetail;
  sendPasswordResetLink: ActionStatusDetail;
  getMailTasks: ActionStatusDetail;
  getAllUsers: ActionStatusDetail;
  getAllBuildings: ActionStatusDetail;
  createNewPhase: ActionStatusDetail;
  createNewContract: ActionStatusDetail;
  createNewSystem: ActionStatusDetail;
}

export interface PasswordResetParams {
  email: string;
  validForHours: number;
}

export interface ContractNode {
  id: number;
  name: string;
  children: ContractNode[];
  permissions: Permission[];
}

export interface SystemView {
  name?: string;
  selectedRoleId?: number;
  role?: schema.role;
  role_privileges?: secmodel.SpecificPrivilege[];
  privilege?: schema.privilege;

  // if user view has been selected
  activeUser?: schema.user;
  userPrivileges?: schema.role_privilege[];
  user_role_privileges?: schema.user_role_privilege[];
}

export interface AdminSearch {
  // if the view is still loading settings
  hasLoaded: boolean;

  // current view for the admin search page
  view?: string;

  // active document
  activeDocument?: Model.UIDocument;

  // the active search
  activeSearch: Model.SearchRequest;

  // results
  searchResults?: Model.UIDocument[];

  // saved search results
  savedSearches?: Model.UserSearches;

  metadata?: Model.UIDocMetadata;

  // loaded documents
  documents: { [id: number]: Model.UIDocument };
}

export type FixtureKeys = keyof Fixture;

const createAction = (name: StatusCodes, loading = true) => ({
  name,
  loading,
  loaded: false,
  snack: undefined,
});

/**
 * @redux true
 */
export class AdminState {
  isInitRun = false;
  networkError = false;

  errors = {};

  adminstatus: ActionStatus = {
    newuser: createAction('newuser', false),
    newUserFromLdap: createAction('newUserFromLdap', false),
    sendPasswordResetLink: createAction('sendPasswordResetLink', false),
    getMailTasks: createAction('getMailTasks', false),
    getAllUsers: createAction('getAllUsers', false),
    getAllBuildings: createAction('getAllBuildings', false),
    createNewPhase: createAction('createNewPhase', false),
    createNewContract: createAction('createNewContract', false),
    createNewSystem: createAction('createNewSystem', false),
  };

  view: string = '';

  users: Array<schema.user & { disabled?: boolean }> = [];
  ldapUsers: model.LDAPUser[] = [];
  allUsers: model.UsersTableRow[] = [];
  allBuildings: schema.pitem[] = [];
  items: schema.pitem[] = [];
  contracts: schema.aitem[] = [];
  roles: model.APRole[] = [];
  actions: schema.action[] = [];

  rootContracts: schema.aitem[] = [];
  contractNodes: ContractNode[] = [];

  activeItemID: number = 0;
  activeContract?: ContractNode;

  activeFixtureTable: FixtureKeys = 'user';
  dbFixture: Fixture = {};
  initMessage = '';

  selectedIdList: number[] = [];

  systemModel?: secmodel.SystemSettings;
  systemView: SystemView = {};

  loading_aitems = false;
  aitemCache: { [key: number]: schema.aitem } = {};
  loading_pitems = false;
  pitemCache: { [key: number]: schema.pitem } = {};

  uploadedExcel?: model.DocumentListing;
  latestUpload?: model.AdminDocumentUpload;

  adminSearch: AdminSearch = {
    hasLoaded: false,
    documents: {},
    activeSearch: {},
  };

  isLoadingResults = false;
  isRunningTests = false;
  testResults?: model.TestResultRow[];

  activeTestSet?: string;

  isLoadingCategories = false;
  documentCategories?: DocumentCategory[];

  isResettingDatabase = false;
  didResetDatabase = false;

  /**
   * @dispatch true
   */
  async AdminStateDispatcher(action: any) {
    // this is a magic method so that ts2redux knows to generate custom dispatcher
    // function which can be used to dispatch any redux actions inside the current model
  }

  setStatus(params: {
    action: ActionStatusDetail;
    snack?: SnackDetails;
    finished?: boolean;
  }) {
    this.adminstatus[params.action.name] = {
      ...this.adminstatus[params.action.name],
      loading: params.finished ? false : true,
      loaded: false,
      snack: params.snack,
    };
  }

  setSuccess(params: {
    action: ActionStatusDetail;
    translation?: TranslationDetails;
  }) {
    const { action, ...snackParams } = params;
    const snack =
      snackParams && ({ variant: 'success', ...snackParams } as SnackDetails);
    this.adminstatus[action.name] = {
      ...this.adminstatus[action.name],
      loading: false,
      loaded: true,
      snack,
    };
  }

  setError(params: {
    action: ActionStatusDetail;
    message?: string;
    translation?: TranslationDetails;
  }) {
    const { action, ...snackParams } = params;
    const snack = { variant: 'error', ...snackParams } as SnackDetails;
    this.setStatus({ action, snack });
    this.adminstatus[params.action.name] = {
      ...this.adminstatus[params.action.name],
      loading: false,
      loaded: false,
      snack,
    };
  }

  resetAdminSnacks() {
    Object.keys(this.adminstatus).map((key) => {
      this.adminstatus[key].snack = undefined;
    });
  }

  setTestResults(rows: model.TestResultRow[]) {
    this.testResults = rows;
  }

  setSelectedTestResult(name: string) {
    this.activeTestSet = name;
  }

  async getTestResults() {
    try {
      if (!this.isLoadingResults) {
        this.isLoadingResults = true;
        this.setTestResults(await API.getLatestTestRuns());
        this.isLoadingResults = false;
      }
    } catch (e) {
      this.isLoadingResults = false;
    }
  }

  async runOnlyTestset(name: string) {
    try {
      if (name.length === 0) {
        return;
      }
      if (!this.isRunningTests) {
        this.isRunningTests = true;

        const current = await API.getLatestTestRuns();
        this.setTestResults(current);

        // then run the tests, notice: this function will return before test are run

        switch (name) {
          case 'api':
            await API.runTests();
            break;
          case 'document':
            await DOCAPI.runTests();
            break;
          case 'auth':
            await AUTH.runTests();
            break;
        }

        // wait until 100 seconds until all tests have been updated
        for (let i = 0; i < 100; i++) {
          const results = await API.getLatestTestRuns();
          let same_cnt = 1;
          for (const res of results) {
            const found = current
              .filter((curr) => res.service_name === curr.service_name)
              .pop();
            if (found && found.service_name === name) {
              if (found.updated_at !== res.updated_at) {
                same_cnt--;
              }
            }
          }
          if (same_cnt === 0) {
            this.setTestResults(results);
            break;
          }
          // wait for a second
          await new Promise((res) => setTimeout(res, 1000));
        }
        this.isRunningTests = false;
      }
    } catch (e) {
      this.isRunningTests = false;
    }
  }

  async runTestResults() {
    try {
      if (!this.isRunningTests) {
        this.isRunningTests = true;

        const current = await API.getLatestTestRuns();

        // fill state with the latest results
        this.setTestResults(current);

        // then run the tests, notice: this function will return before test are run
        await API.runTests();
        await DOCAPI.runTests();
        await AUTH.runTests();

        // wait until 100 seconds until all tests have been updated
        for (let i = 0; i < 100; i++) {
          const results = await API.getLatestTestRuns();
          let same_cnt = current.length;
          for (const res of results) {
            const found = current
              .filter((curr) => res.service_name === curr.service_name)
              .pop();
            if (found) {
              if (found.updated_at !== res.updated_at) {
                same_cnt--;
              }
            }
          }
          if (same_cnt === 0) {
            this.setTestResults(results);
            break;
          }
          // wait for a second
          await new Promise((res) => setTimeout(res, 1000));
        }
        this.isRunningTests = false;
      }
    } catch (e) {
      this.isRunningTests = false;
    }
  }

  // Admin search component

  setFreeText(value: string) {
    this.adminSearch.activeSearch.freeText = value;
  }

  setContractFilter(value: string) {
    this.adminSearch.activeSearch.freeText = value;
  }
  /*
  // Contract name filter
  contractText?: string;

  // Contract name filter
  buildingElementText?: string;

  // Contract name filter
  roomText?: string;

  // Contract name filter
  floorText?: string;

  // Contract name filter
  itemText?: string;
*/

  setBuildingText(value: string) {
    this.adminSearch.activeSearch.buildingElementText = value;
  }
  setRoomText(value: string) {
    this.adminSearch.activeSearch.roomText = value;
  }
  setFloorText(value: string) {
    this.adminSearch.activeSearch.floorText = value;
  }
  setContractText(value: string) {
    this.adminSearch.activeSearch.contractText = value;
  }
  setItemText(value: string) {
    this.adminSearch.activeSearch.itemText = value;
  }

  async getMetadata() {
    try {
      const meta = await API.getDocumentMetadata();
      this.adminSearch.metadata = {
        workflowStates: meta.workflowStates,
        documentCategories: meta.documentCategories.map((cat) => ({
          id: cat.id,
          name: cat.category_name as string,
          parentId: cat.upper_category_id,
        })),
        pitemTypes: meta.pitemTypes,
        aitemTypes: meta.aitemTypes,
        workflows: meta.workflows,
      };
    } catch (e) {
      this.networkError = true;
    }
  }

  saveDocument(doc: Model.UIDocument) {
    this.adminSearch.documents[doc.id] = doc;
  }

  setActiveDocument(doc: Model.UIDocument) {
    this.adminSearch.activeDocument = doc;
  }

  async fetchDocument(id: number) {
    try {
      const doc = await DOCAPI.fetchDocument(id);
      this.saveDocument(doc);
      this.setActiveDocument(doc);
    } catch (e) {
      this.networkError = true;
    }
  }

  setSearchResults(list: model.UIDocument[]) {
    this.adminSearch.searchResults = list;
  }

  async documentSearch(params: Model.SearchRequest) {
    try {
      this.setSearchResults((await DOCAPI.documentSearchRequest(params)).rows);
    } catch (e) {
      this.networkError = true;
    }
  }

  initSearch(searches: Model.UserSearches) {
    if (!searches.savedSearches) {
      return;
    }
    this.adminSearch.savedSearches = searches;
  }

  async getUserSearches() {
    this.initSearch(await API.getUserSearches());
  }

  async fileUpload(options: { file: any; url: string }) {
    const url = options.url;
    const formData = new FormData();
    formData.append('file', options.file);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    const result = await axios.post(url, formData, config);
    this.uploadedExcel = result.data;
  }

  setSystemView(name: string) {
    this.systemView.name = name;
  }

  setActiveRole(role: schema.role) {
    this.systemView.role = role;
  }

  setUserRolePrivileges(privs: schema.user_role_privilege[]) {
    this.systemView.user_role_privileges = privs;
  }

  setRolePrivileges(privs: secmodel.SpecificPrivilege[]) {
    this.systemView.role_privileges = privs;
  }

  setActiveUserPrivileges(list: schema.role_privilege[]) {
    this.systemView.userPrivileges = list;
  }
  setActiveUser(user: schema.user | undefined) {
    this.systemView.activeUser = user;
  }

  fillAItemCache(items: schema.aitem[]) {
    items.forEach((item) => {
      this.aitemCache[item.id] = item;
    });
  }
  async getAItems(list: number[]) {
    this.loading_aitems = true;
    this.fillAItemCache(await API.getAItems(list));
    this.loading_aitems = false;
  }

  removePrivilege(priv: schema.user_role_privilege) {
    if (!this.systemView || !this.systemView.userPrivileges) {
      return;
    }
    this.systemView.userPrivileges = this.systemView.userPrivileges.filter(
      (p) => {
        return p.id !== priv.role_privilege_id;
      }
    );
  }

  async removeUserPrivilege(priv: schema.user_role_privilege) {
    try {
      this.removePrivilege(priv);
      console.log('Should call removePrivilege', priv);
      // await API.removePrivilege(priv.id);
      this.setActiveUserPrivileges(await API.getUserPrivileges(priv.user_id));
      this.setUserRolePrivileges(await API.getUserRolePrivileges(priv.user_id));
    } catch (e) {
      console.log(e);
    }
  }

  async addPrivilegeToUser(priv: secmodel.GrantPrivilegeToUser) {
    try {
      // this.removePrivilege(priv);
      // console.log('Should call removePrivilege', priv);
      console.log(priv);
      // await API.addPrivilegeToUser(priv);
      this.setActiveUserPrivileges(await API.getUserPrivileges(priv.user_id));
      this.setUserRolePrivileges(await API.getUserRolePrivileges(priv.user_id));
    } catch (e) {
      console.log(e);
    }
  }

  fillPItemCache(items: schema.pitem[]) {
    items.forEach((item) => {
      this.pitemCache[item.id] = item;
    });
  }
  async getPItems(list: number[]) {
    this.loading_pitems = true;
    this.fillPItemCache(await API.getPItems(list));
    this.loading_pitems = false;
  }

  async selectUser(user: schema.user) {
    this.setActiveUser(user);
    this.setActiveUserPrivileges(await API.getUserPrivileges(user.id));
    this.setUserRolePrivileges(await API.getUserRolePrivileges(user.id));
  }

  async newUser() {
    this.setActiveUser(undefined);
  }

  async createNewUser(user: model.NewUserFields) {
    const action = this.adminstatus.newuser;
    this.setStatus({ action });
    try {
      await AUTH.createNewUser(user);
      this.setActiveUser(undefined);
      this.setSuccess({ action });
    } catch (e) {
      const message =
        _.get(e, 'response.data.lde_message') ||
        _.get(e, 'response.data.detail') ||
        e.message;
      this.setError({ action, message });
      console.log(e);
    }
  }

  async createNewUserFromLdap(user: model.LDAPUser) {
    const action = this.adminstatus.newUserFromLdap;
    this.setStatus({ action });
    try {
      const newUser = await AUTH.createNewUserFromLdap(user);
      this.getAllUsers();
      this.AdminStateDispatcher(push('/admin-tools/users/' + newUser.id));
      this.setSuccess({ action });
    } catch (e) {
      this.setError({ action, message: e.message });
      console.error(e);
    }
  }

  async sendPasswordResetLinkAdmin(params: PasswordResetParams) {
    const action = this.adminstatus.sendPasswordResetLink;
    this.setStatus({ action });
    try {
      await AUTH.requestPasswordChangeLinkAdmin({ ...params });
      this.setSuccess({
        action,
        translation: { id: 'adminTools.passwordResetLinkSent' },
      });
    } catch (e) {
      this.setError({ action, message: e.message });
    }
  }

  async loadRolePrivileges(id: number) {
    // this.setRolePrivileges(await API.getPrivilegesForRole(id));
  }

  setActivePrivilege(privilege: schema.privilege) {
    this.systemView.privilege = privilege;
  }

  setSelectedItem(id: number) {
    if (this.selectedIdList && this.selectedIdList.indexOf(id) < 0) {
      this.selectedIdList.push(id);
    }
  }

  unsetSelectedItem(id: number) {
    if (this.selectedIdList && this.selectedIdList.indexOf(id) >= 0) {
      this.selectedIdList.splice(this.selectedIdList.indexOf(id), 1);
    }
  }

  setActiveItem(id: number) {
    this.activeItemID = id;
  }

  selectFictureTable(name: FixtureKeys) {
    this.activeFixtureTable = name;
  }

  setActiveContract(id: number) {
    if (this.contractNodes) {
      this.activeContract = this.findContractNode(this.contractNodes, id);
    }
  }

  updateLocalFixture(value: Fixture | undefined) {
    if (typeof value === 'undefined') {
      return;
    }
    this.dbFixture = value;
  }

  async loadSettings() {
    this.systemModel = await API.getSystemAdminSettings();
  }

  async loadFixture() {
    this.dbFixture = await API.getBaseFixture();
  }
  async initDBWithFixture() {
    if (typeof this.dbFixture === 'undefined') {
      return;
    }
    try {
      this.isResettingDatabase = true;
      await API.initWithFixture(this.dbFixture);
      this.isResettingDatabase = false;
      this.didResetDatabase = true;
      this.initMessage = 'Database initialized';
    } catch (e) {
      this.initMessage = 'Initialization failed';
    }
  }

  setView(name: string) {
    this.view = name;
  }

  get listedUsers(): schema.user[] {
    return this.users;
  }

  async initializeDB() {
    this.isResettingDatabase = true;
    await API.initializeDB();
    this.users = await API.getDBUsers();
    this.isInitRun = true;
    this.isResettingDatabase = false;
    this.didResetDatabase = true;
  }

  async getUsers() {
    this.users = await API.getDBUsers();
  }

  async getAllUsers(onlyDBUsers?: boolean) {
    const action = this.adminstatus.getAllUsers;
    try {
      this.setStatus({ action });
      this.users = await API.getDBUsers();
      if (!onlyDBUsers) {
        this.ldapUsers = await AUTH.getAllUsers();
      }

      const users = this.users.reduce(
        (prev, curr) => ({ ...prev, [curr.ldapid]: curr }),
        {}
      );
      const ldapUsers = this.ldapUsers.reduce(
        (prev, curr) => ({ ...prev, [curr.dn]: curr }),
        {}
      );

      const allUsers: model.UsersTableRow[] = [];
      this.users.map((user) => {
        const ldapUser = onlyDBUsers ? undefined : ldapUsers[user.ldapid];
        if (ldapUser || onlyDBUsers) {
          allUsers.push({
            name: user.firstname + ' ' + user.lastname,
            email: user.email!,
            id: user.id,
            disabled: (ldapUser && ldapUser.disabled) || false,
          });
        }
      });
      if (!onlyDBUsers) {
        this.ldapUsers.map((user, index) => {
          const addedUser = users[user.dn];
          if (!addedUser) {
            const name =
              user.mail || user.displayName || user.cn + ' ' + user.sn;
            // user.cn && user.sn
            //   ? user.cn + ' ' + user.sn
            //   : user.displayName || user.mail;
            allUsers.push({
              email: user.mail,
              name,
              id: -(index - 1),
              disabled: user.disabled,
            });
          }
        });
      }
      this.setSuccess({ action });
      this.setAllUsers(allUsers);
    } catch (e) {
      this.setError({ action });
      console.error('Something went wrong: ', e);
    }
  }

  setAllUsers(users: model.UsersTableRow[]) {
    this.allUsers = users;
  }

  async getItems() {
    this.items = await API.getItems();
    this.view = 'items';
  }

  private findContractNode(
    list: ContractNode[],
    id: number
  ): ContractNode | undefined {
    if (!list) {
      return;
    }
    const active = list.filter((item) => item.id === id).pop();
    if (active) {
      return active;
    }

    for (const ch of list) {
      const a = this.findContractNode(ch.children, id);
      if (a) {
        return a;
      }
    }
    return undefined;
  }
  addChildContracts(params: { parentId: number; children: schema.aitem[] }) {
    if (!this.contractNodes) {
      return;
    }
    const active = this.findContractNode(this.contractNodes, params.parentId);
    if (active) {
      active.children = params.children.map((item) => {
        return {
          id: item.id,
          name: item.name as string,
          children: [],
          permissions: [],
        };
      });
    }
  }

  async getUserPrivileges(forID: number) {
    const permissions = await API.getUserPrivileges(forID);
    console.log(permissions);
  }

  async getContracts() {
    this.contracts = await API.getContracts();
  }

  async getAllContracts() {
    const contracts = await API.getAllContracts();
    // TODO: Do the sorting in backend
    this.contracts = contracts.sort((a, b) => a.name!.localeCompare(b.name!));
  }

  async removeContract(id: number) {
    try {
      return await API.removeAitem({ id });
    } catch (e) {}
    return false;
  }

  async getAvailableRoles() {
    const roles = await API.getAvailableRoles();
    this.setRoles(roles);
  }

  setRoles(roles: Model.APRole[]) {
    this.roles = roles;
  }

  async getAvailableActions() {
    const actions = await API.getAllActions();
    this.setActions(actions);
  }

  setActions(actions: schema.action[]) {
    this.actions = actions;
  }

  async getChildContracts(forID: number) {
    const children = await API.getChildContractNodes(forID);
    this.addChildContracts({ parentId: forID, children });
    this.view = 'contracts';
  }

  async refreshContracts() {
    this.rootContracts = await API.getRootContractNodes();
    this.contractNodes = this.rootContracts
      .sort((a, b) => a.id - b.id)
      .map((item) => {
        return {
          id: item.id,
          name: item.name as string,
          children: [],
          permissions: [],
        };
      });
    this.view = 'contracts';
  }

  async getRootContractNodes() {
    if (this.rootContracts && this.rootContracts.length === 0) {
      this.rootContracts = await API.getRootContractNodes();
      this.contractNodes = this.rootContracts
        .sort((a, b) => a.id - b.id)
        .map((item) => {
          return {
            id: item.id,
            name: item.name as string,
            children: [],
            permissions: [],
          };
        });
    }
    this.view = 'contracts';
  }
  async uploadAdminDocumentCollection(files: File[]) {
    if (!files) {
      return;
    }
    const formData = new FormData();
    formData.append('file', files[0]);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    this.latestUpload = (
      await axios.post('/docs/v1/upload/documentcollection', formData, config)
    ).data as model.AdminDocumentUpload;
  }

  async uploadNewDocuments(files: File[]) {
    if (!files) {
      return;
    }
    const formData = new FormData();
    formData.append('file', files[0]);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    this.latestUpload = (
      await axios.post('/docs/v1/upload/adminexcel', formData, config)
    ).data as model.AdminDocumentUpload;
  }

  async loadDocumentCategories() {
    try {
      if (!this.isLoadingCategories) {
        this.isLoadingCategories = true;
        this.documentCategories = await API.getDocumentTypes();
        this.isLoadingCategories = false;
      }
    } catch (e) {
      this.isLoadingCategories = false;
    }
  }

  async getAllBuildings() {
    try {
      const buildings = await API.getAllBuildings();
      this.setAllBuildings(buildings);
    } catch (e) {
      console.log(e);
    }
  }

  setAllBuildings(buildings: schema.pitem[]) {
    this.allBuildings = buildings;
  }

  async createNewPhase(params: { phaseName: string; longName: string }) {
    const action = this.adminstatus.createNewPhase;
    this.setStatus({ action });
    try {
      await API.createNewPhase(params);
      this.setSuccess({
        action,
        translation: { id: 'adminTools.newPhaseSuccess' },
      });
    } catch (e) {
      this.setError({ action, message: e.message });
    }
  }

  async createNewContract(params: {
    phaseId: number;
    contractName: string;
    longName: string;
  }) {
    const action = this.adminstatus.createNewContract;
    this.setStatus({ action });
    try {
      await API.createNewContract(params);
      this.setSuccess({
        action,
        translation: { id: 'adminTools.newContractSuccess' },
      });
    } catch (e) {
      this.setError({ action, message: e.message });
    }
  }

  async createNewSystem(params: {
    parent_system_id: number | undefined;
    system_name: string;
    description: string;
  }) {
    const action = this.adminstatus.createNewSystem;
    this.setStatus({ action });
    try {
      await DOCAPI.createNewSystem(params);
      this.setSuccess({
        action,
        translation: { id: 'adminTools.newSystemSuccess' },
      });
    } catch (e) {
      this.setError({ action, message: e.message });
    }
  }
}

export interface IContainerPropsMethods {
  AdminStateDispatcher: (action: any) => any;
  setStatus: (params: {
    action: ActionStatusDetail;
    snack?: SnackDetails;
    finished?: boolean;
  }) => any;
  setSuccess: (params: {
    action: ActionStatusDetail;
    translation?: TranslationDetails;
  }) => any;
  setError: (params: {
    action: ActionStatusDetail;
    message?: string;
    translation?: TranslationDetails;
  }) => any;
  resetAdminSnacks: () => any;
  setTestResults: (rows: model.TestResultRow[]) => any;
  setSelectedTestResult: (name: string) => any;
  getTestResults: () => any;
  runOnlyTestset: (name: string) => any;
  runTestResults: () => any;
  setFreeText: (value: string) => any;
  setContractFilter: (value: string) => any;
  setBuildingText: (value: string) => any;
  setRoomText: (value: string) => any;
  setFloorText: (value: string) => any;
  setContractText: (value: string) => any;
  setItemText: (value: string) => any;
  getMetadata: () => any;
  saveDocument: (doc: Model.UIDocument) => any;
  setActiveDocument: (doc: Model.UIDocument) => any;
  fetchDocument: (id: number) => any;
  setSearchResults: (list: model.UIDocument[]) => any;
  documentSearch: (params: Model.SearchRequest) => any;
  initSearch: (searches: Model.UserSearches) => any;
  getUserSearches: () => any;
  fileUpload: (options: { file: any; url: string }) => any;
  setSystemView: (name: string) => any;
  setActiveRole: (role: schema.role) => any;
  setUserRolePrivileges: (privs: schema.user_role_privilege[]) => any;
  setRolePrivileges: (privs: secmodel.SpecificPrivilege[]) => any;
  setActiveUserPrivileges: (list: schema.role_privilege[]) => any;
  setActiveUser: (user: schema.user | undefined) => any;
  fillAItemCache: (items: schema.aitem[]) => any;
  getAItems: (list: number[]) => any;
  removePrivilege: (priv: schema.user_role_privilege) => any;
  removeUserPrivilege: (priv: schema.user_role_privilege) => any;
  addPrivilegeToUser: (priv: secmodel.GrantPrivilegeToUser) => any;
  fillPItemCache: (items: schema.pitem[]) => any;
  getPItems: (list: number[]) => any;
  selectUser: (user: schema.user) => any;
  newUser: () => any;
  createNewUser: (user: model.NewUserFields) => any;
  createNewUserFromLdap: (user: model.LDAPUser) => any;
  sendPasswordResetLinkAdmin: (params: PasswordResetParams) => any;
  loadRolePrivileges: (id: number) => any;
  setActivePrivilege: (privilege: schema.privilege) => any;
  setSelectedItem: (id: number) => any;
  unsetSelectedItem: (id: number) => any;
  setActiveItem: (id: number) => any;
  selectFictureTable: (name: FixtureKeys) => any;
  setActiveContract: (id: number) => any;
  updateLocalFixture: (value: Fixture | undefined) => any;
  loadSettings: () => any;
  loadFixture: () => any;
  initDBWithFixture: () => any;
  setView: (name: string) => any;
  initializeDB: () => any;
  getUsers: () => any;
  getAllUsers: (onlyDBUsers?: boolean) => any;
  setAllUsers: (users: model.UsersTableRow[]) => any;
  getItems: () => any;
  addChildContracts: (params: {
    parentId: number;
    children: schema.aitem[];
  }) => any;
  getUserPrivileges: (forID: number) => any;
  getContracts: () => any;
  getAllContracts: () => any;
  removeContract: (id: number) => any;
  getAvailableRoles: () => any;
  setRoles: (roles: Model.APRole[]) => any;
  getAvailableActions: () => any;
  setActions: (actions: schema.action[]) => any;
  getChildContracts: (forID: number) => any;
  refreshContracts: () => any;
  getRootContractNodes: () => any;
  uploadAdminDocumentCollection: (files: File[]) => any;
  uploadNewDocuments: (files: File[]) => any;
  loadDocumentCategories: () => any;
  getAllBuildings: () => any;
  setAllBuildings: (buildings: schema.pitem[]) => any;
  createNewPhase: (params: { phaseName: string; longName: string }) => any;
  createNewContract: (params: {
    phaseId: number;
    contractName: string;
    longName: string;
  }) => any;
  createNewSystem: (params: {
    parent_system_id: number | undefined;
    system_name: string;
    description: string;
  }) => any;
}
export interface IAdminState {
  isInitRun: boolean;
  networkError: boolean;
  errors: any;
  adminstatus: ActionStatus;
  view: string;
  users: Array<
    schema.user & {
      disabled?: boolean;
    }
  >;
  ldapUsers: model.LDAPUser[];
  allUsers: model.UsersTableRow[];
  allBuildings: schema.pitem[];
  items: schema.pitem[];
  contracts: schema.aitem[];
  roles: model.APRole[];
  actions: schema.action[];
  rootContracts: schema.aitem[];
  contractNodes: ContractNode[];
  activeItemID: number;
  activeContract?: ContractNode;
  activeFixtureTable: FixtureKeys;
  dbFixture: Fixture;
  initMessage: string;
  selectedIdList: number[];
  systemModel?: secmodel.SystemSettings;
  systemView: SystemView;
  loading_aitems: boolean;
  aitemCache: {
    [key: number]: schema.aitem;
  };
  loading_pitems: boolean;
  pitemCache: {
    [key: number]: schema.pitem;
  };
  uploadedExcel?: model.DocumentListing;
  latestUpload?: model.AdminDocumentUpload;
  adminSearch: AdminSearch;
  isLoadingResults: boolean;
  isRunningTests: boolean;
  testResults?: model.TestResultRow[];
  activeTestSet?: string;
  isLoadingCategories: boolean;
  documentCategories?: DocumentCategory[];
  isResettingDatabase: boolean;
  didResetDatabase: boolean;
}
export const isInitRunSelectorFn = (state: IAdminState): boolean =>
  state.isInitRun;
export const networkErrorSelectorFn = (state: IAdminState): boolean =>
  state.networkError;
export const errorsSelectorFn = (state: IAdminState): any => state.errors;
export const adminstatusSelectorFn = (state: IAdminState): ActionStatus =>
  state.adminstatus;
export const viewSelectorFn = (state: IAdminState): string => state.view;
export const usersSelectorFn = (
  state: IAdminState
): Array<schema.user & {
  disabled?: boolean;
}> => state.users;
export const ldapUsersSelectorFn = (state: IAdminState): model.LDAPUser[] =>
  state.ldapUsers;
export const allUsersSelectorFn = (state: IAdminState): model.UsersTableRow[] =>
  state.allUsers;
export const allBuildingsSelectorFn = (state: IAdminState): schema.pitem[] =>
  state.allBuildings;
export const itemsSelectorFn = (state: IAdminState): schema.pitem[] =>
  state.items;
export const contractsSelectorFn = (state: IAdminState): schema.aitem[] =>
  state.contracts;
export const rolesSelectorFn = (state: IAdminState): model.APRole[] =>
  state.roles;
export const actionsSelectorFn = (state: IAdminState): schema.action[] =>
  state.actions;
export const rootContractsSelectorFn = (state: IAdminState): schema.aitem[] =>
  state.rootContracts;
export const contractNodesSelectorFn = (state: IAdminState): ContractNode[] =>
  state.contractNodes;
export const activeItemIDSelectorFn = (state: IAdminState): number =>
  state.activeItemID;
export const activeContractSelectorFn = (
  state: IAdminState
): ContractNode | undefined => state.activeContract;
export const activeFixtureTableSelectorFn = (state: IAdminState): FixtureKeys =>
  state.activeFixtureTable;
export const dbFixtureSelectorFn = (state: IAdminState): Fixture =>
  state.dbFixture;
export const initMessageSelectorFn = (state: IAdminState): string =>
  state.initMessage;
export const selectedIdListSelectorFn = (state: IAdminState): number[] =>
  state.selectedIdList;
export const systemModelSelectorFn = (
  state: IAdminState
): secmodel.SystemSettings | undefined => state.systemModel;
export const systemViewSelectorFn = (state: IAdminState): SystemView =>
  state.systemView;
export const loading_aitemsSelectorFn = (state: IAdminState): boolean =>
  state.loading_aitems;
export const aitemCacheSelectorFn = (
  state: IAdminState
): {
  [key: number]: schema.aitem;
} => state.aitemCache;
export const loading_pitemsSelectorFn = (state: IAdminState): boolean =>
  state.loading_pitems;
export const pitemCacheSelectorFn = (
  state: IAdminState
): {
  [key: number]: schema.pitem;
} => state.pitemCache;
export const uploadedExcelSelectorFn = (
  state: IAdminState
): model.DocumentListing | undefined => state.uploadedExcel;
export const latestUploadSelectorFn = (
  state: IAdminState
): model.AdminDocumentUpload | undefined => state.latestUpload;
export const adminSearchSelectorFn = (state: IAdminState): AdminSearch =>
  state.adminSearch;
export const isLoadingResultsSelectorFn = (state: IAdminState): boolean =>
  state.isLoadingResults;
export const isRunningTestsSelectorFn = (state: IAdminState): boolean =>
  state.isRunningTests;
export const testResultsSelectorFn = (
  state: IAdminState
): model.TestResultRow[] | undefined => state.testResults;
export const activeTestSetSelectorFn = (
  state: IAdminState
): string | undefined => state.activeTestSet;
export const isLoadingCategoriesSelectorFn = (state: IAdminState): boolean =>
  state.isLoadingCategories;
export const documentCategoriesSelectorFn = (
  state: IAdminState
): DocumentCategory[] | undefined => state.documentCategories;
export const isResettingDatabaseSelectorFn = (state: IAdminState): boolean =>
  state.isResettingDatabase;
export const didResetDatabaseSelectorFn = (state: IAdminState): boolean =>
  state.didResetDatabase;
export const listedUsersSelectorFnCreator = () =>
  createSelector([usersSelectorFn], (users) => {
    const o = new AdminState();
    o.users = users;
    return o.listedUsers;
  });
export const listedUsersSelector = listedUsersSelectorFnCreator();

export interface IContainerPropsState extends IAdminState {
  listedUsers: schema.user[];
}
export interface IProps extends IContainerPropsState, IContainerPropsMethods {}

function pick<T, K extends keyof T>(o: T, ...props: K[]) {
  return props.reduce((a, e) => ({ ...a, [e]: o[e] }), {}) as Pick<T, K>;
}
export function mapStateToPropsWithKeys<K extends keyof IContainerPropsState>(
  state: IState,
  keys: K[]
): Pick<IContainerPropsState, K> {
  return pick(state.AdminState as IContainerPropsState, ...keys);
}

export const mapStateToProps = (state: IState): IContainerPropsState => {
  return {
    isInitRun: state.AdminState.isInitRun,
    networkError: state.AdminState.networkError,
    errors: state.AdminState.errors,
    adminstatus: state.AdminState.adminstatus,
    view: state.AdminState.view,
    users: state.AdminState.users,
    ldapUsers: state.AdminState.ldapUsers,
    allUsers: state.AdminState.allUsers,
    allBuildings: state.AdminState.allBuildings,
    items: state.AdminState.items,
    contracts: state.AdminState.contracts,
    roles: state.AdminState.roles,
    actions: state.AdminState.actions,
    rootContracts: state.AdminState.rootContracts,
    contractNodes: state.AdminState.contractNodes,
    activeItemID: state.AdminState.activeItemID,
    activeContract: state.AdminState.activeContract,
    activeFixtureTable: state.AdminState.activeFixtureTable,
    dbFixture: state.AdminState.dbFixture,
    initMessage: state.AdminState.initMessage,
    selectedIdList: state.AdminState.selectedIdList,
    systemModel: state.AdminState.systemModel,
    systemView: state.AdminState.systemView,
    loading_aitems: state.AdminState.loading_aitems,
    aitemCache: state.AdminState.aitemCache,
    loading_pitems: state.AdminState.loading_pitems,
    pitemCache: state.AdminState.pitemCache,
    uploadedExcel: state.AdminState.uploadedExcel,
    latestUpload: state.AdminState.latestUpload,
    adminSearch: state.AdminState.adminSearch,
    isLoadingResults: state.AdminState.isLoadingResults,
    isRunningTests: state.AdminState.isRunningTests,
    testResults: state.AdminState.testResults,
    activeTestSet: state.AdminState.activeTestSet,
    isLoadingCategories: state.AdminState.isLoadingCategories,
    documentCategories: state.AdminState.documentCategories,
    isResettingDatabase: state.AdminState.isResettingDatabase,
    didResetDatabase: state.AdminState.didResetDatabase,
    listedUsers: listedUsersSelector(state.AdminState),
  };
};

function mapDispatchToPropsWithKeys<K extends keyof IContainerPropsMethods>(
  dispatch: any,
  keys: K[]
): Pick<IContainerPropsMethods, K> {
  return pick(mapDispatchToProps(dispatch), ...keys);
}

export const mapDispatchToProps = (dispatch: any): IContainerPropsMethods => {
  return {
    AdminStateDispatcher: (action: any) => {
      return dispatch(action);
    },
    setStatus: (params: {
      action: ActionStatusDetail;
      snack?: SnackDetails;
      finished?: boolean;
    }) => {
      return dispatch(RAdminState.setStatus(params));
    },
    setSuccess: (params: {
      action: ActionStatusDetail;
      translation?: TranslationDetails;
    }) => {
      return dispatch(RAdminState.setSuccess(params));
    },
    setError: (params: {
      action: ActionStatusDetail;
      message?: string;
      translation?: TranslationDetails;
    }) => {
      return dispatch(RAdminState.setError(params));
    },
    resetAdminSnacks: () => {
      return dispatch(RAdminState.resetAdminSnacks());
    },
    setTestResults: (rows: model.TestResultRow[]) => {
      return dispatch(RAdminState.setTestResults(rows));
    },
    setSelectedTestResult: (name: string) => {
      return dispatch(RAdminState.setSelectedTestResult(name));
    },
    getTestResults: () => {
      return dispatch(RAdminState.getTestResults());
    },
    runOnlyTestset: (name: string) => {
      return dispatch(RAdminState.runOnlyTestset(name));
    },
    runTestResults: () => {
      return dispatch(RAdminState.runTestResults());
    },
    setFreeText: (value: string) => {
      return dispatch(RAdminState.setFreeText(value));
    },
    setContractFilter: (value: string) => {
      return dispatch(RAdminState.setContractFilter(value));
    },
    setBuildingText: (value: string) => {
      return dispatch(RAdminState.setBuildingText(value));
    },
    setRoomText: (value: string) => {
      return dispatch(RAdminState.setRoomText(value));
    },
    setFloorText: (value: string) => {
      return dispatch(RAdminState.setFloorText(value));
    },
    setContractText: (value: string) => {
      return dispatch(RAdminState.setContractText(value));
    },
    setItemText: (value: string) => {
      return dispatch(RAdminState.setItemText(value));
    },
    getMetadata: () => {
      return dispatch(RAdminState.getMetadata());
    },
    saveDocument: (doc: Model.UIDocument) => {
      return dispatch(RAdminState.saveDocument(doc));
    },
    setActiveDocument: (doc: Model.UIDocument) => {
      return dispatch(RAdminState.setActiveDocument(doc));
    },
    fetchDocument: (id: number) => {
      return dispatch(RAdminState.fetchDocument(id));
    },
    setSearchResults: (list: model.UIDocument[]) => {
      return dispatch(RAdminState.setSearchResults(list));
    },
    documentSearch: (params: Model.SearchRequest) => {
      return dispatch(RAdminState.documentSearch(params));
    },
    initSearch: (searches: Model.UserSearches) => {
      return dispatch(RAdminState.initSearch(searches));
    },
    getUserSearches: () => {
      return dispatch(RAdminState.getUserSearches());
    },
    fileUpload: (options: { file: any; url: string }) => {
      return dispatch(RAdminState.fileUpload(options));
    },
    setSystemView: (name: string) => {
      return dispatch(RAdminState.setSystemView(name));
    },
    setActiveRole: (role: schema.role) => {
      return dispatch(RAdminState.setActiveRole(role));
    },
    setUserRolePrivileges: (privs: schema.user_role_privilege[]) => {
      return dispatch(RAdminState.setUserRolePrivileges(privs));
    },
    setRolePrivileges: (privs: secmodel.SpecificPrivilege[]) => {
      return dispatch(RAdminState.setRolePrivileges(privs));
    },
    setActiveUserPrivileges: (list: schema.role_privilege[]) => {
      return dispatch(RAdminState.setActiveUserPrivileges(list));
    },
    setActiveUser: (user: schema.user | undefined) => {
      return dispatch(RAdminState.setActiveUser(user));
    },
    fillAItemCache: (items: schema.aitem[]) => {
      return dispatch(RAdminState.fillAItemCache(items));
    },
    getAItems: (list: number[]) => {
      return dispatch(RAdminState.getAItems(list));
    },
    removePrivilege: (priv: schema.user_role_privilege) => {
      return dispatch(RAdminState.removePrivilege(priv));
    },
    removeUserPrivilege: (priv: schema.user_role_privilege) => {
      return dispatch(RAdminState.removeUserPrivilege(priv));
    },
    addPrivilegeToUser: (priv: secmodel.GrantPrivilegeToUser) => {
      return dispatch(RAdminState.addPrivilegeToUser(priv));
    },
    fillPItemCache: (items: schema.pitem[]) => {
      return dispatch(RAdminState.fillPItemCache(items));
    },
    getPItems: (list: number[]) => {
      return dispatch(RAdminState.getPItems(list));
    },
    selectUser: (user: schema.user) => {
      return dispatch(RAdminState.selectUser(user));
    },
    newUser: () => {
      return dispatch(RAdminState.newUser());
    },
    createNewUser: (user: model.NewUserFields) => {
      return dispatch(RAdminState.createNewUser(user));
    },
    createNewUserFromLdap: (user: model.LDAPUser) => {
      return dispatch(RAdminState.createNewUserFromLdap(user));
    },
    sendPasswordResetLinkAdmin: (params: PasswordResetParams) => {
      return dispatch(RAdminState.sendPasswordResetLinkAdmin(params));
    },
    loadRolePrivileges: (id: number) => {
      return dispatch(RAdminState.loadRolePrivileges(id));
    },
    setActivePrivilege: (privilege: schema.privilege) => {
      return dispatch(RAdminState.setActivePrivilege(privilege));
    },
    setSelectedItem: (id: number) => {
      return dispatch(RAdminState.setSelectedItem(id));
    },
    unsetSelectedItem: (id: number) => {
      return dispatch(RAdminState.unsetSelectedItem(id));
    },
    setActiveItem: (id: number) => {
      return dispatch(RAdminState.setActiveItem(id));
    },
    selectFictureTable: (name: FixtureKeys) => {
      return dispatch(RAdminState.selectFictureTable(name));
    },
    setActiveContract: (id: number) => {
      return dispatch(RAdminState.setActiveContract(id));
    },
    updateLocalFixture: (value: Fixture | undefined) => {
      return dispatch(RAdminState.updateLocalFixture(value));
    },
    loadSettings: () => {
      return dispatch(RAdminState.loadSettings());
    },
    loadFixture: () => {
      return dispatch(RAdminState.loadFixture());
    },
    initDBWithFixture: () => {
      return dispatch(RAdminState.initDBWithFixture());
    },
    setView: (name: string) => {
      return dispatch(RAdminState.setView(name));
    },
    initializeDB: () => {
      return dispatch(RAdminState.initializeDB());
    },
    getUsers: () => {
      return dispatch(RAdminState.getUsers());
    },
    getAllUsers: (onlyDBUsers?: boolean) => {
      return dispatch(RAdminState.getAllUsers(onlyDBUsers));
    },
    setAllUsers: (users: model.UsersTableRow[]) => {
      return dispatch(RAdminState.setAllUsers(users));
    },
    getItems: () => {
      return dispatch(RAdminState.getItems());
    },
    addChildContracts: (params: {
      parentId: number;
      children: schema.aitem[];
    }) => {
      return dispatch(RAdminState.addChildContracts(params));
    },
    getUserPrivileges: (forID: number) => {
      return dispatch(RAdminState.getUserPrivileges(forID));
    },
    getContracts: () => {
      return dispatch(RAdminState.getContracts());
    },
    getAllContracts: () => {
      return dispatch(RAdminState.getAllContracts());
    },
    removeContract: (id: number) => {
      return dispatch(RAdminState.removeContract(id));
    },
    getAvailableRoles: () => {
      return dispatch(RAdminState.getAvailableRoles());
    },
    setRoles: (roles: Model.APRole[]) => {
      return dispatch(RAdminState.setRoles(roles));
    },
    getAvailableActions: () => {
      return dispatch(RAdminState.getAvailableActions());
    },
    setActions: (actions: schema.action[]) => {
      return dispatch(RAdminState.setActions(actions));
    },
    getChildContracts: (forID: number) => {
      return dispatch(RAdminState.getChildContracts(forID));
    },
    refreshContracts: () => {
      return dispatch(RAdminState.refreshContracts());
    },
    getRootContractNodes: () => {
      return dispatch(RAdminState.getRootContractNodes());
    },
    uploadAdminDocumentCollection: (files: File[]) => {
      return dispatch(RAdminState.uploadAdminDocumentCollection(files));
    },
    uploadNewDocuments: (files: File[]) => {
      return dispatch(RAdminState.uploadNewDocuments(files));
    },
    loadDocumentCategories: () => {
      return dispatch(RAdminState.loadDocumentCategories());
    },
    getAllBuildings: () => {
      return dispatch(RAdminState.getAllBuildings());
    },
    setAllBuildings: (buildings: schema.pitem[]) => {
      return dispatch(RAdminState.setAllBuildings(buildings));
    },
    createNewPhase: (params: { phaseName: string; longName: string }) => {
      return dispatch(RAdminState.createNewPhase(params));
    },
    createNewContract: (params: {
      phaseId: number;
      contractName: string;
      longName: string;
    }) => {
      return dispatch(RAdminState.createNewContract(params));
    },
    createNewSystem: (params: {
      parent_system_id: number | undefined;
      system_name: string;
      description: string;
    }) => {
      return dispatch(RAdminState.createNewSystem(params));
    },
  };
};

export function ConnectKeys<
  K extends keyof IAdminState,
  J extends keyof IContainerPropsMethods
>(keys: K[], methods: J[]) {
  return connect(
    (state: IState) => mapStateToPropsWithKeys(state, keys),
    (dispatch: any) => mapDispatchToPropsWithKeys(dispatch, methods)
  );
}

export const StateConnector = connect(mapStateToProps, mapDispatchToProps);

const initAdminState = () => {
  const o = new AdminState();
  return {
    isInitRun: o.isInitRun,
    networkError: o.networkError,
    errors: o.errors,
    adminstatus: o.adminstatus,
    view: o.view,
    users: o.users,
    ldapUsers: o.ldapUsers,
    allUsers: o.allUsers,
    allBuildings: o.allBuildings,
    items: o.items,
    contracts: o.contracts,
    roles: o.roles,
    actions: o.actions,
    rootContracts: o.rootContracts,
    contractNodes: o.contractNodes,
    activeItemID: o.activeItemID,
    activeContract: o.activeContract,
    activeFixtureTable: o.activeFixtureTable,
    dbFixture: o.dbFixture,
    initMessage: o.initMessage,
    selectedIdList: o.selectedIdList,
    systemModel: o.systemModel,
    systemView: o.systemView,
    loading_aitems: o.loading_aitems,
    aitemCache: o.aitemCache,
    loading_pitems: o.loading_pitems,
    pitemCache: o.pitemCache,
    uploadedExcel: o.uploadedExcel,
    latestUpload: o.latestUpload,
    adminSearch: o.adminSearch,
    isLoadingResults: o.isLoadingResults,
    isRunningTests: o.isRunningTests,
    testResults: o.testResults,
    activeTestSet: o.activeTestSet,
    isLoadingCategories: o.isLoadingCategories,
    documentCategories: o.documentCategories,
    isResettingDatabase: o.isResettingDatabase,
    didResetDatabase: o.didResetDatabase,
  };
};
const initWithMethodsAdminState = () => {
  const o = new AdminState();
  return {
    isInitRun: o.isInitRun,
    networkError: o.networkError,
    errors: o.errors,
    adminstatus: o.adminstatus,
    view: o.view,
    users: o.users,
    ldapUsers: o.ldapUsers,
    allUsers: o.allUsers,
    allBuildings: o.allBuildings,
    items: o.items,
    contracts: o.contracts,
    roles: o.roles,
    actions: o.actions,
    rootContracts: o.rootContracts,
    contractNodes: o.contractNodes,
    activeItemID: o.activeItemID,
    activeContract: o.activeContract,
    activeFixtureTable: o.activeFixtureTable,
    dbFixture: o.dbFixture,
    initMessage: o.initMessage,
    selectedIdList: o.selectedIdList,
    systemModel: o.systemModel,
    systemView: o.systemView,
    loading_aitems: o.loading_aitems,
    aitemCache: o.aitemCache,
    loading_pitems: o.loading_pitems,
    pitemCache: o.pitemCache,
    uploadedExcel: o.uploadedExcel,
    latestUpload: o.latestUpload,
    adminSearch: o.adminSearch,
    isLoadingResults: o.isLoadingResults,
    isRunningTests: o.isRunningTests,
    testResults: o.testResults,
    activeTestSet: o.activeTestSet,
    isLoadingCategories: o.isLoadingCategories,
    documentCategories: o.documentCategories,
    isResettingDatabase: o.isResettingDatabase,
    didResetDatabase: o.didResetDatabase,
    AdminStateDispatcher: o.AdminStateDispatcher,
    setStatus: o.setStatus,
    setSuccess: o.setSuccess,
    setError: o.setError,
    resetAdminSnacks: o.resetAdminSnacks,
    setTestResults: o.setTestResults,
    setSelectedTestResult: o.setSelectedTestResult,
    getTestResults: o.getTestResults,
    runOnlyTestset: o.runOnlyTestset,
    runTestResults: o.runTestResults,
    setFreeText: o.setFreeText,
    setContractFilter: o.setContractFilter,
    setBuildingText: o.setBuildingText,
    setRoomText: o.setRoomText,
    setFloorText: o.setFloorText,
    setContractText: o.setContractText,
    setItemText: o.setItemText,
    getMetadata: o.getMetadata,
    saveDocument: o.saveDocument,
    setActiveDocument: o.setActiveDocument,
    fetchDocument: o.fetchDocument,
    setSearchResults: o.setSearchResults,
    documentSearch: o.documentSearch,
    initSearch: o.initSearch,
    getUserSearches: o.getUserSearches,
    fileUpload: o.fileUpload,
    setSystemView: o.setSystemView,
    setActiveRole: o.setActiveRole,
    setUserRolePrivileges: o.setUserRolePrivileges,
    setRolePrivileges: o.setRolePrivileges,
    setActiveUserPrivileges: o.setActiveUserPrivileges,
    setActiveUser: o.setActiveUser,
    fillAItemCache: o.fillAItemCache,
    getAItems: o.getAItems,
    removePrivilege: o.removePrivilege,
    removeUserPrivilege: o.removeUserPrivilege,
    addPrivilegeToUser: o.addPrivilegeToUser,
    fillPItemCache: o.fillPItemCache,
    getPItems: o.getPItems,
    selectUser: o.selectUser,
    newUser: o.newUser,
    createNewUser: o.createNewUser,
    createNewUserFromLdap: o.createNewUserFromLdap,
    sendPasswordResetLinkAdmin: o.sendPasswordResetLinkAdmin,
    loadRolePrivileges: o.loadRolePrivileges,
    setActivePrivilege: o.setActivePrivilege,
    setSelectedItem: o.setSelectedItem,
    unsetSelectedItem: o.unsetSelectedItem,
    setActiveItem: o.setActiveItem,
    selectFictureTable: o.selectFictureTable,
    setActiveContract: o.setActiveContract,
    updateLocalFixture: o.updateLocalFixture,
    loadSettings: o.loadSettings,
    loadFixture: o.loadFixture,
    initDBWithFixture: o.initDBWithFixture,
    setView: o.setView,
    initializeDB: o.initializeDB,
    getUsers: o.getUsers,
    getAllUsers: o.getAllUsers,
    setAllUsers: o.setAllUsers,
    getItems: o.getItems,
    addChildContracts: o.addChildContracts,
    getUserPrivileges: o.getUserPrivileges,
    getContracts: o.getContracts,
    getAllContracts: o.getAllContracts,
    removeContract: o.removeContract,
    getAvailableRoles: o.getAvailableRoles,
    setRoles: o.setRoles,
    getAvailableActions: o.getAvailableActions,
    setActions: o.setActions,
    getChildContracts: o.getChildContracts,
    refreshContracts: o.refreshContracts,
    getRootContractNodes: o.getRootContractNodes,
    uploadAdminDocumentCollection: o.uploadAdminDocumentCollection,
    uploadNewDocuments: o.uploadNewDocuments,
    loadDocumentCategories: o.loadDocumentCategories,
    getAllBuildings: o.getAllBuildings,
    setAllBuildings: o.setAllBuildings,
    createNewPhase: o.createNewPhase,
    createNewContract: o.createNewContract,
    createNewSystem: o.createNewSystem,
    listedUsers: o.listedUsers,
  };
};

/**
 * @generated true
 */
export class RAdminState {
  private _state?: IAdminState;
  private _dispatch?: <A extends {}, T extends {}>(action: A) => T;
  private _getState?: () => any;
  constructor(
    state?: IAdminState,
    dispatch?: (action: any) => any,
    getState?: () => any
  ) {
    this._state = state;
    this._dispatch = dispatch;
    this._getState = getState;
  }
  get isInitRun(): boolean {
    if (this._getState) {
      return this._getState().AdminState.isInitRun;
    } else {
      if (this._state) {
        return this._state.isInitRun;
      }
    }
    throw new Error('Invalid State in AdminState_isInitRun');
  }
  set isInitRun(value: boolean) {
    if (this._state && typeof value !== 'undefined') {
      this._state.isInitRun = value;
    } else {
      // dispatch change for item isInitRun
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_isInitRun,
          payload: value,
        });
      }
    }
  }
  get networkError(): boolean {
    if (this._getState) {
      return this._getState().AdminState.networkError;
    } else {
      if (this._state) {
        return this._state.networkError;
      }
    }
    throw new Error('Invalid State in AdminState_networkError');
  }
  set networkError(value: boolean) {
    if (this._state && typeof value !== 'undefined') {
      this._state.networkError = value;
    } else {
      // dispatch change for item networkError
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_networkError,
          payload: value,
        });
      }
    }
  }
  get errors(): any {
    if (this._getState) {
      return this._getState().AdminState.errors;
    } else {
      if (this._state) {
        return this._state.errors;
      }
    }
    throw new Error('Invalid State in AdminState_errors');
  }
  set errors(value: any) {
    if (this._state && typeof value !== 'undefined') {
      this._state.errors = value;
    } else {
      // dispatch change for item errors
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_errors,
          payload: value,
        });
      }
    }
  }
  get adminstatus(): ActionStatus {
    if (this._getState) {
      return this._getState().AdminState.adminstatus;
    } else {
      if (this._state) {
        return this._state.adminstatus;
      }
    }
    throw new Error('Invalid State in AdminState_adminstatus');
  }
  set adminstatus(value: ActionStatus) {
    if (this._state && typeof value !== 'undefined') {
      this._state.adminstatus = value;
    } else {
      // dispatch change for item adminstatus
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_adminstatus,
          payload: value,
        });
      }
    }
  }
  get view(): string {
    if (this._getState) {
      return this._getState().AdminState.view;
    } else {
      if (this._state) {
        return this._state.view;
      }
    }
    throw new Error('Invalid State in AdminState_view');
  }
  set view(value: string) {
    if (this._state && typeof value !== 'undefined') {
      this._state.view = value;
    } else {
      // dispatch change for item view
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_view,
          payload: value,
        });
      }
    }
  }
  get users(): Array<
    schema.user & {
      disabled?: boolean;
    }
  > {
    if (this._getState) {
      return this._getState().AdminState.users;
    } else {
      if (this._state) {
        return this._state.users;
      }
    }
    throw new Error('Invalid State in AdminState_users');
  }
  set users(
    value: Array<
      schema.user & {
        disabled?: boolean;
      }
    >
  ) {
    if (this._state && typeof value !== 'undefined') {
      this._state.users = value;
    } else {
      // dispatch change for item users
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_users,
          payload: value,
        });
      }
    }
  }
  get ldapUsers(): model.LDAPUser[] {
    if (this._getState) {
      return this._getState().AdminState.ldapUsers;
    } else {
      if (this._state) {
        return this._state.ldapUsers;
      }
    }
    throw new Error('Invalid State in AdminState_ldapUsers');
  }
  set ldapUsers(value: model.LDAPUser[]) {
    if (this._state && typeof value !== 'undefined') {
      this._state.ldapUsers = value;
    } else {
      // dispatch change for item ldapUsers
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_ldapUsers,
          payload: value,
        });
      }
    }
  }
  get allUsers(): model.UsersTableRow[] {
    if (this._getState) {
      return this._getState().AdminState.allUsers;
    } else {
      if (this._state) {
        return this._state.allUsers;
      }
    }
    throw new Error('Invalid State in AdminState_allUsers');
  }
  set allUsers(value: model.UsersTableRow[]) {
    if (this._state && typeof value !== 'undefined') {
      this._state.allUsers = value;
    } else {
      // dispatch change for item allUsers
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_allUsers,
          payload: value,
        });
      }
    }
  }
  get allBuildings(): schema.pitem[] {
    if (this._getState) {
      return this._getState().AdminState.allBuildings;
    } else {
      if (this._state) {
        return this._state.allBuildings;
      }
    }
    throw new Error('Invalid State in AdminState_allBuildings');
  }
  set allBuildings(value: schema.pitem[]) {
    if (this._state && typeof value !== 'undefined') {
      this._state.allBuildings = value;
    } else {
      // dispatch change for item allBuildings
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_allBuildings,
          payload: value,
        });
      }
    }
  }
  get items(): schema.pitem[] {
    if (this._getState) {
      return this._getState().AdminState.items;
    } else {
      if (this._state) {
        return this._state.items;
      }
    }
    throw new Error('Invalid State in AdminState_items');
  }
  set items(value: schema.pitem[]) {
    if (this._state && typeof value !== 'undefined') {
      this._state.items = value;
    } else {
      // dispatch change for item items
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_items,
          payload: value,
        });
      }
    }
  }
  get contracts(): schema.aitem[] {
    if (this._getState) {
      return this._getState().AdminState.contracts;
    } else {
      if (this._state) {
        return this._state.contracts;
      }
    }
    throw new Error('Invalid State in AdminState_contracts');
  }
  set contracts(value: schema.aitem[]) {
    if (this._state && typeof value !== 'undefined') {
      this._state.contracts = value;
    } else {
      // dispatch change for item contracts
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_contracts,
          payload: value,
        });
      }
    }
  }
  get roles(): model.APRole[] {
    if (this._getState) {
      return this._getState().AdminState.roles;
    } else {
      if (this._state) {
        return this._state.roles;
      }
    }
    throw new Error('Invalid State in AdminState_roles');
  }
  set roles(value: model.APRole[]) {
    if (this._state && typeof value !== 'undefined') {
      this._state.roles = value;
    } else {
      // dispatch change for item roles
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_roles,
          payload: value,
        });
      }
    }
  }
  get actions(): schema.action[] {
    if (this._getState) {
      return this._getState().AdminState.actions;
    } else {
      if (this._state) {
        return this._state.actions;
      }
    }
    throw new Error('Invalid State in AdminState_actions');
  }
  set actions(value: schema.action[]) {
    if (this._state && typeof value !== 'undefined') {
      this._state.actions = value;
    } else {
      // dispatch change for item actions
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_actions,
          payload: value,
        });
      }
    }
  }
  get rootContracts(): schema.aitem[] {
    if (this._getState) {
      return this._getState().AdminState.rootContracts;
    } else {
      if (this._state) {
        return this._state.rootContracts;
      }
    }
    throw new Error('Invalid State in AdminState_rootContracts');
  }
  set rootContracts(value: schema.aitem[]) {
    if (this._state && typeof value !== 'undefined') {
      this._state.rootContracts = value;
    } else {
      // dispatch change for item rootContracts
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_rootContracts,
          payload: value,
        });
      }
    }
  }
  get contractNodes(): ContractNode[] {
    if (this._getState) {
      return this._getState().AdminState.contractNodes;
    } else {
      if (this._state) {
        return this._state.contractNodes;
      }
    }
    throw new Error('Invalid State in AdminState_contractNodes');
  }
  set contractNodes(value: ContractNode[]) {
    if (this._state && typeof value !== 'undefined') {
      this._state.contractNodes = value;
    } else {
      // dispatch change for item contractNodes
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_contractNodes,
          payload: value,
        });
      }
    }
  }
  get activeItemID(): number {
    if (this._getState) {
      return this._getState().AdminState.activeItemID;
    } else {
      if (this._state) {
        return this._state.activeItemID;
      }
    }
    throw new Error('Invalid State in AdminState_activeItemID');
  }
  set activeItemID(value: number) {
    if (this._state && typeof value !== 'undefined') {
      this._state.activeItemID = value;
    } else {
      // dispatch change for item activeItemID
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_activeItemID,
          payload: value,
        });
      }
    }
  }
  get activeContract(): ContractNode | undefined {
    if (this._getState) {
      return this._getState().AdminState.activeContract;
    } else {
      if (this._state) {
        return this._state.activeContract;
      }
    }
    return undefined;
  }
  set activeContract(value: ContractNode | undefined) {
    if (this._state && typeof value !== 'undefined') {
      this._state.activeContract = value;
    } else {
      // dispatch change for item activeContract
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_activeContract,
          payload: value,
        });
      }
    }
  }
  get activeFixtureTable(): FixtureKeys {
    if (this._getState) {
      return this._getState().AdminState.activeFixtureTable;
    } else {
      if (this._state) {
        return this._state.activeFixtureTable;
      }
    }
    throw new Error('Invalid State in AdminState_activeFixtureTable');
  }
  set activeFixtureTable(value: FixtureKeys) {
    if (this._state && typeof value !== 'undefined') {
      this._state.activeFixtureTable = value;
    } else {
      // dispatch change for item activeFixtureTable
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_activeFixtureTable,
          payload: value,
        });
      }
    }
  }
  get dbFixture(): Fixture {
    if (this._getState) {
      return this._getState().AdminState.dbFixture;
    } else {
      if (this._state) {
        return this._state.dbFixture;
      }
    }
    throw new Error('Invalid State in AdminState_dbFixture');
  }
  set dbFixture(value: Fixture) {
    if (this._state && typeof value !== 'undefined') {
      this._state.dbFixture = value;
    } else {
      // dispatch change for item dbFixture
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_dbFixture,
          payload: value,
        });
      }
    }
  }
  get initMessage(): string {
    if (this._getState) {
      return this._getState().AdminState.initMessage;
    } else {
      if (this._state) {
        return this._state.initMessage;
      }
    }
    throw new Error('Invalid State in AdminState_initMessage');
  }
  set initMessage(value: string) {
    if (this._state && typeof value !== 'undefined') {
      this._state.initMessage = value;
    } else {
      // dispatch change for item initMessage
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_initMessage,
          payload: value,
        });
      }
    }
  }
  get selectedIdList(): number[] {
    if (this._getState) {
      return this._getState().AdminState.selectedIdList;
    } else {
      if (this._state) {
        return this._state.selectedIdList;
      }
    }
    throw new Error('Invalid State in AdminState_selectedIdList');
  }
  set selectedIdList(value: number[]) {
    if (this._state && typeof value !== 'undefined') {
      this._state.selectedIdList = value;
    } else {
      // dispatch change for item selectedIdList
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_selectedIdList,
          payload: value,
        });
      }
    }
  }
  get systemModel(): secmodel.SystemSettings | undefined {
    if (this._getState) {
      return this._getState().AdminState.systemModel;
    } else {
      if (this._state) {
        return this._state.systemModel;
      }
    }
    return undefined;
  }
  set systemModel(value: secmodel.SystemSettings | undefined) {
    if (this._state && typeof value !== 'undefined') {
      this._state.systemModel = value;
    } else {
      // dispatch change for item systemModel
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_systemModel,
          payload: value,
        });
      }
    }
  }
  get systemView(): SystemView {
    if (this._getState) {
      return this._getState().AdminState.systemView;
    } else {
      if (this._state) {
        return this._state.systemView;
      }
    }
    throw new Error('Invalid State in AdminState_systemView');
  }
  set systemView(value: SystemView) {
    if (this._state && typeof value !== 'undefined') {
      this._state.systemView = value;
    } else {
      // dispatch change for item systemView
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_systemView,
          payload: value,
        });
      }
    }
  }
  get loading_aitems(): boolean {
    if (this._getState) {
      return this._getState().AdminState.loading_aitems;
    } else {
      if (this._state) {
        return this._state.loading_aitems;
      }
    }
    throw new Error('Invalid State in AdminState_loading_aitems');
  }
  set loading_aitems(value: boolean) {
    if (this._state && typeof value !== 'undefined') {
      this._state.loading_aitems = value;
    } else {
      // dispatch change for item loading_aitems
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_loading_aitems,
          payload: value,
        });
      }
    }
  }
  get aitemCache(): {
    [key: number]: schema.aitem;
  } {
    if (this._getState) {
      return this._getState().AdminState.aitemCache;
    } else {
      if (this._state) {
        return this._state.aitemCache;
      }
    }
    throw new Error('Invalid State in AdminState_aitemCache');
  }
  set aitemCache(value: { [key: number]: schema.aitem }) {
    if (this._state && typeof value !== 'undefined') {
      this._state.aitemCache = value;
    } else {
      // dispatch change for item aitemCache
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_aitemCache,
          payload: value,
        });
      }
    }
  }
  get loading_pitems(): boolean {
    if (this._getState) {
      return this._getState().AdminState.loading_pitems;
    } else {
      if (this._state) {
        return this._state.loading_pitems;
      }
    }
    throw new Error('Invalid State in AdminState_loading_pitems');
  }
  set loading_pitems(value: boolean) {
    if (this._state && typeof value !== 'undefined') {
      this._state.loading_pitems = value;
    } else {
      // dispatch change for item loading_pitems
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_loading_pitems,
          payload: value,
        });
      }
    }
  }
  get pitemCache(): {
    [key: number]: schema.pitem;
  } {
    if (this._getState) {
      return this._getState().AdminState.pitemCache;
    } else {
      if (this._state) {
        return this._state.pitemCache;
      }
    }
    throw new Error('Invalid State in AdminState_pitemCache');
  }
  set pitemCache(value: { [key: number]: schema.pitem }) {
    if (this._state && typeof value !== 'undefined') {
      this._state.pitemCache = value;
    } else {
      // dispatch change for item pitemCache
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_pitemCache,
          payload: value,
        });
      }
    }
  }
  get uploadedExcel(): model.DocumentListing | undefined {
    if (this._getState) {
      return this._getState().AdminState.uploadedExcel;
    } else {
      if (this._state) {
        return this._state.uploadedExcel;
      }
    }
    return undefined;
  }
  set uploadedExcel(value: model.DocumentListing | undefined) {
    if (this._state && typeof value !== 'undefined') {
      this._state.uploadedExcel = value;
    } else {
      // dispatch change for item uploadedExcel
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_uploadedExcel,
          payload: value,
        });
      }
    }
  }
  get latestUpload(): model.AdminDocumentUpload | undefined {
    if (this._getState) {
      return this._getState().AdminState.latestUpload;
    } else {
      if (this._state) {
        return this._state.latestUpload;
      }
    }
    return undefined;
  }
  set latestUpload(value: model.AdminDocumentUpload | undefined) {
    if (this._state && typeof value !== 'undefined') {
      this._state.latestUpload = value;
    } else {
      // dispatch change for item latestUpload
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_latestUpload,
          payload: value,
        });
      }
    }
  }
  get adminSearch(): AdminSearch {
    if (this._getState) {
      return this._getState().AdminState.adminSearch;
    } else {
      if (this._state) {
        return this._state.adminSearch;
      }
    }
    throw new Error('Invalid State in AdminState_adminSearch');
  }
  set adminSearch(value: AdminSearch) {
    if (this._state && typeof value !== 'undefined') {
      this._state.adminSearch = value;
    } else {
      // dispatch change for item adminSearch
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_adminSearch,
          payload: value,
        });
      }
    }
  }
  get isLoadingResults(): boolean {
    if (this._getState) {
      return this._getState().AdminState.isLoadingResults;
    } else {
      if (this._state) {
        return this._state.isLoadingResults;
      }
    }
    throw new Error('Invalid State in AdminState_isLoadingResults');
  }
  set isLoadingResults(value: boolean) {
    if (this._state && typeof value !== 'undefined') {
      this._state.isLoadingResults = value;
    } else {
      // dispatch change for item isLoadingResults
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_isLoadingResults,
          payload: value,
        });
      }
    }
  }
  get isRunningTests(): boolean {
    if (this._getState) {
      return this._getState().AdminState.isRunningTests;
    } else {
      if (this._state) {
        return this._state.isRunningTests;
      }
    }
    throw new Error('Invalid State in AdminState_isRunningTests');
  }
  set isRunningTests(value: boolean) {
    if (this._state && typeof value !== 'undefined') {
      this._state.isRunningTests = value;
    } else {
      // dispatch change for item isRunningTests
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_isRunningTests,
          payload: value,
        });
      }
    }
  }
  get testResults(): model.TestResultRow[] | undefined {
    if (this._getState) {
      return this._getState().AdminState.testResults;
    } else {
      if (this._state) {
        return this._state.testResults;
      }
    }
    return undefined;
  }
  set testResults(value: model.TestResultRow[] | undefined) {
    if (this._state && typeof value !== 'undefined') {
      this._state.testResults = value;
    } else {
      // dispatch change for item testResults
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_testResults,
          payload: value,
        });
      }
    }
  }
  get activeTestSet(): string | undefined {
    if (this._getState) {
      return this._getState().AdminState.activeTestSet;
    } else {
      if (this._state) {
        return this._state.activeTestSet;
      }
    }
    return undefined;
  }
  set activeTestSet(value: string | undefined) {
    if (this._state && typeof value !== 'undefined') {
      this._state.activeTestSet = value;
    } else {
      // dispatch change for item activeTestSet
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_activeTestSet,
          payload: value,
        });
      }
    }
  }
  get isLoadingCategories(): boolean {
    if (this._getState) {
      return this._getState().AdminState.isLoadingCategories;
    } else {
      if (this._state) {
        return this._state.isLoadingCategories;
      }
    }
    throw new Error('Invalid State in AdminState_isLoadingCategories');
  }
  set isLoadingCategories(value: boolean) {
    if (this._state && typeof value !== 'undefined') {
      this._state.isLoadingCategories = value;
    } else {
      // dispatch change for item isLoadingCategories
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_isLoadingCategories,
          payload: value,
        });
      }
    }
  }
  get documentCategories(): DocumentCategory[] | undefined {
    if (this._getState) {
      return this._getState().AdminState.documentCategories;
    } else {
      if (this._state) {
        return this._state.documentCategories;
      }
    }
    return undefined;
  }
  set documentCategories(value: DocumentCategory[] | undefined) {
    if (this._state && typeof value !== 'undefined') {
      this._state.documentCategories = value;
    } else {
      // dispatch change for item documentCategories
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_documentCategories,
          payload: value,
        });
      }
    }
  }
  get isResettingDatabase(): boolean {
    if (this._getState) {
      return this._getState().AdminState.isResettingDatabase;
    } else {
      if (this._state) {
        return this._state.isResettingDatabase;
      }
    }
    throw new Error('Invalid State in AdminState_isResettingDatabase');
  }
  set isResettingDatabase(value: boolean) {
    if (this._state && typeof value !== 'undefined') {
      this._state.isResettingDatabase = value;
    } else {
      // dispatch change for item isResettingDatabase
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_isResettingDatabase,
          payload: value,
        });
      }
    }
  }
  get didResetDatabase(): boolean {
    if (this._getState) {
      return this._getState().AdminState.didResetDatabase;
    } else {
      if (this._state) {
        return this._state.didResetDatabase;
      }
    }
    throw new Error('Invalid State in AdminState_didResetDatabase');
  }
  set didResetDatabase(value: boolean) {
    if (this._state && typeof value !== 'undefined') {
      this._state.didResetDatabase = value;
    } else {
      // dispatch change for item didResetDatabase
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_didResetDatabase,
          payload: value,
        });
      }
    }
  }

  async AdminStateDispatcher(action: any) {
    if (typeof this._dispatch !== 'undefined') {
      this._dispatch(action);
    }
  }

  public static AdminStateDispatcher(action: any) {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(
        undefined,
        dispatcher,
        getState
      ).AdminStateDispatcher(action);
    };
  }
  setStatus(params: {
    action: ActionStatusDetail;
    snack?: SnackDetails;
    finished?: boolean;
  }) {
    if (this._state) {
      this.adminstatus[params.action.name] = {
        ...this.adminstatus[params.action.name],
        loading: params.finished ? false : true,
        loaded: false,
        snack: params.snack,
      };
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_setStatus,
          payload: params,
        });
      }
    }
  }

  public static setStatus(params: {
    action: ActionStatusDetail;
    snack?: SnackDetails;
    finished?: boolean;
  }) {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(undefined, dispatcher, getState).setStatus(params);
    };
  }
  setSuccess(params: {
    action: ActionStatusDetail;
    translation?: TranslationDetails;
  }) {
    if (this._state) {
      const { action, ...snackParams } = params;
      const snack =
        snackParams && ({ variant: 'success', ...snackParams } as SnackDetails);
      this.adminstatus[action.name] = {
        ...this.adminstatus[action.name],
        loading: false,
        loaded: true,
        snack,
      };
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_setSuccess,
          payload: params,
        });
      }
    }
  }

  public static setSuccess(params: {
    action: ActionStatusDetail;
    translation?: TranslationDetails;
  }) {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(undefined, dispatcher, getState).setSuccess(
        params
      );
    };
  }
  setError(params: {
    action: ActionStatusDetail;
    message?: string;
    translation?: TranslationDetails;
  }) {
    if (this._state) {
      const { action, ...snackParams } = params;
      const snack = { variant: 'error', ...snackParams } as SnackDetails;
      this.setStatus({ action, snack });
      this.adminstatus[params.action.name] = {
        ...this.adminstatus[params.action.name],
        loading: false,
        loaded: false,
        snack,
      };
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_setError,
          payload: params,
        });
      }
    }
  }

  public static setError(params: {
    action: ActionStatusDetail;
    message?: string;
    translation?: TranslationDetails;
  }) {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(undefined, dispatcher, getState).setError(params);
    };
  }
  resetAdminSnacks() {
    if (this._state) {
      Object.keys(this.adminstatus).map((key) => {
        this.adminstatus[key].snack = undefined;
      });
    } else {
      if (this._dispatch) {
        this._dispatch({ type: AdminStateEnums.AdminState_resetAdminSnacks });
      }
    }
  }

  public static resetAdminSnacks() {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(
        undefined,
        dispatcher,
        getState
      ).resetAdminSnacks();
    };
  }
  setTestResults(rows: model.TestResultRow[]) {
    if (this._state) {
      this.testResults = rows;
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_setTestResults,
          payload: rows,
        });
      }
    }
  }

  public static setTestResults(rows: model.TestResultRow[]) {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(undefined, dispatcher, getState).setTestResults(
        rows
      );
    };
  }
  setSelectedTestResult(name: string) {
    if (this._state) {
      this.activeTestSet = name;
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_setSelectedTestResult,
          payload: name,
        });
      }
    }
  }

  public static setSelectedTestResult(name: string) {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(
        undefined,
        dispatcher,
        getState
      ).setSelectedTestResult(name);
    };
  }
  async getTestResults() {
    try {
      if (!this.isLoadingResults) {
        this.isLoadingResults = true;
        this.setTestResults(await API.getLatestTestRuns());
        this.isLoadingResults = false;
      }
    } catch (e) {
      this.isLoadingResults = false;
    }
  }

  public static getTestResults() {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(undefined, dispatcher, getState).getTestResults();
    };
  }
  async runOnlyTestset(name: string) {
    try {
      if (name.length === 0) {
        return;
      }
      if (!this.isRunningTests) {
        this.isRunningTests = true;
        const current = await API.getLatestTestRuns();
        this.setTestResults(current);
        // then run the tests, notice: this function will return before test are run
        switch (name) {
          case 'api':
            await API.runTests();
            break;
          case 'document':
            await DOCAPI.runTests();
            break;
          case 'auth':
            await AUTH.runTests();
            break;
        }
        // wait until 100 seconds until all tests have been updated
        for (let i = 0; i < 100; i++) {
          const results = await API.getLatestTestRuns();
          let same_cnt = 1;
          for (const res of results) {
            const found = current
              .filter((curr) => res.service_name === curr.service_name)
              .pop();
            if (found && found.service_name === name) {
              if (found.updated_at !== res.updated_at) {
                same_cnt--;
              }
            }
          }
          if (same_cnt === 0) {
            this.setTestResults(results);
            break;
          }
          // wait for a second
          await new Promise((res) => setTimeout(res, 1000));
        }
        this.isRunningTests = false;
      }
    } catch (e) {
      this.isRunningTests = false;
    }
  }

  public static runOnlyTestset(name: string) {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(undefined, dispatcher, getState).runOnlyTestset(
        name
      );
    };
  }
  async runTestResults() {
    try {
      if (!this.isRunningTests) {
        this.isRunningTests = true;
        const current = await API.getLatestTestRuns();
        // fill state with the latest results
        this.setTestResults(current);
        // then run the tests, notice: this function will return before test are run
        await API.runTests();
        await DOCAPI.runTests();
        await AUTH.runTests();
        // wait until 100 seconds until all tests have been updated
        for (let i = 0; i < 100; i++) {
          const results = await API.getLatestTestRuns();
          let same_cnt = current.length;
          for (const res of results) {
            const found = current
              .filter((curr) => res.service_name === curr.service_name)
              .pop();
            if (found) {
              if (found.updated_at !== res.updated_at) {
                same_cnt--;
              }
            }
          }
          if (same_cnt === 0) {
            this.setTestResults(results);
            break;
          }
          // wait for a second
          await new Promise((res) => setTimeout(res, 1000));
        }
        this.isRunningTests = false;
      }
    } catch (e) {
      this.isRunningTests = false;
    }
  }

  public static runTestResults() {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(undefined, dispatcher, getState).runTestResults();
    };
  }
  setFreeText(value: string) {
    if (this._state) {
      this.adminSearch.activeSearch.freeText = value;
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_setFreeText,
          payload: value,
        });
      }
    }
  }

  public static setFreeText(value: string) {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(undefined, dispatcher, getState).setFreeText(
        value
      );
    };
  }
  setContractFilter(value: string) {
    if (this._state) {
      this.adminSearch.activeSearch.freeText = value;
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_setContractFilter,
          payload: value,
        });
      }
    }
  }

  public static setContractFilter(value: string) {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(undefined, dispatcher, getState).setContractFilter(
        value
      );
    };
  }
  setBuildingText(value: string) {
    if (this._state) {
      this.adminSearch.activeSearch.buildingElementText = value;
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_setBuildingText,
          payload: value,
        });
      }
    }
  }

  public static setBuildingText(value: string) {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(undefined, dispatcher, getState).setBuildingText(
        value
      );
    };
  }
  setRoomText(value: string) {
    if (this._state) {
      this.adminSearch.activeSearch.roomText = value;
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_setRoomText,
          payload: value,
        });
      }
    }
  }

  public static setRoomText(value: string) {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(undefined, dispatcher, getState).setRoomText(
        value
      );
    };
  }
  setFloorText(value: string) {
    if (this._state) {
      this.adminSearch.activeSearch.floorText = value;
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_setFloorText,
          payload: value,
        });
      }
    }
  }

  public static setFloorText(value: string) {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(undefined, dispatcher, getState).setFloorText(
        value
      );
    };
  }
  setContractText(value: string) {
    if (this._state) {
      this.adminSearch.activeSearch.contractText = value;
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_setContractText,
          payload: value,
        });
      }
    }
  }

  public static setContractText(value: string) {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(undefined, dispatcher, getState).setContractText(
        value
      );
    };
  }
  setItemText(value: string) {
    if (this._state) {
      this.adminSearch.activeSearch.itemText = value;
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_setItemText,
          payload: value,
        });
      }
    }
  }

  public static setItemText(value: string) {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(undefined, dispatcher, getState).setItemText(
        value
      );
    };
  }
  async getMetadata() {
    try {
      const meta = await API.getDocumentMetadata();
      this.adminSearch.metadata = {
        workflowStates: meta.workflowStates,
        documentCategories: meta.documentCategories.map((cat) => ({
          id: cat.id,
          name: cat.category_name as string,
          parentId: cat.upper_category_id,
        })),
        pitemTypes: meta.pitemTypes,
        aitemTypes: meta.aitemTypes,
        workflows: meta.workflows,
      };
    } catch (e) {
      this.networkError = true;
    }
  }

  public static getMetadata() {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(undefined, dispatcher, getState).getMetadata();
    };
  }
  saveDocument(doc: Model.UIDocument) {
    if (this._state) {
      this.adminSearch.documents[doc.id] = doc;
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_saveDocument,
          payload: doc,
        });
      }
    }
  }

  public static saveDocument(doc: Model.UIDocument) {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(undefined, dispatcher, getState).saveDocument(doc);
    };
  }
  setActiveDocument(doc: Model.UIDocument) {
    if (this._state) {
      this.adminSearch.activeDocument = doc;
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_setActiveDocument,
          payload: doc,
        });
      }
    }
  }

  public static setActiveDocument(doc: Model.UIDocument) {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(undefined, dispatcher, getState).setActiveDocument(
        doc
      );
    };
  }
  async fetchDocument(id: number) {
    try {
      const doc = await DOCAPI.fetchDocument(id);
      this.saveDocument(doc);
      this.setActiveDocument(doc);
    } catch (e) {
      this.networkError = true;
    }
  }

  public static fetchDocument(id: number) {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(undefined, dispatcher, getState).fetchDocument(id);
    };
  }
  setSearchResults(list: model.UIDocument[]) {
    if (this._state) {
      this.adminSearch.searchResults = list;
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_setSearchResults,
          payload: list,
        });
      }
    }
  }

  public static setSearchResults(list: model.UIDocument[]) {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(undefined, dispatcher, getState).setSearchResults(
        list
      );
    };
  }
  async documentSearch(params: Model.SearchRequest) {
    try {
      this.setSearchResults((await DOCAPI.documentSearchRequest(params)).rows);
    } catch (e) {
      this.networkError = true;
    }
  }

  public static documentSearch(params: Model.SearchRequest) {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(undefined, dispatcher, getState).documentSearch(
        params
      );
    };
  }
  initSearch(searches: Model.UserSearches) {
    if (this._state) {
      if (!searches.savedSearches) {
        return;
      }
      this.adminSearch.savedSearches = searches;
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_initSearch,
          payload: searches,
        });
      }
    }
  }

  public static initSearch(searches: Model.UserSearches) {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(undefined, dispatcher, getState).initSearch(
        searches
      );
    };
  }
  async getUserSearches() {
    this.initSearch(await API.getUserSearches());
  }

  public static getUserSearches() {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(undefined, dispatcher, getState).getUserSearches();
    };
  }
  async fileUpload(options: { file: any; url: string }) {
    const url = options.url;
    const formData = new FormData();
    formData.append('file', options.file);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    const result = await axios.post(url, formData, config);
    this.uploadedExcel = result.data;
  }

  public static fileUpload(options: { file: any; url: string }) {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(undefined, dispatcher, getState).fileUpload(
        options
      );
    };
  }
  setSystemView(name: string) {
    if (this._state) {
      this.systemView.name = name;
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_setSystemView,
          payload: name,
        });
      }
    }
  }

  public static setSystemView(name: string) {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(undefined, dispatcher, getState).setSystemView(
        name
      );
    };
  }
  setActiveRole(role: schema.role) {
    if (this._state) {
      this.systemView.role = role;
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_setActiveRole,
          payload: role,
        });
      }
    }
  }

  public static setActiveRole(role: schema.role) {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(undefined, dispatcher, getState).setActiveRole(
        role
      );
    };
  }
  setUserRolePrivileges(privs: schema.user_role_privilege[]) {
    if (this._state) {
      this.systemView.user_role_privileges = privs;
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_setUserRolePrivileges,
          payload: privs,
        });
      }
    }
  }

  public static setUserRolePrivileges(privs: schema.user_role_privilege[]) {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(
        undefined,
        dispatcher,
        getState
      ).setUserRolePrivileges(privs);
    };
  }
  setRolePrivileges(privs: secmodel.SpecificPrivilege[]) {
    if (this._state) {
      this.systemView.role_privileges = privs;
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_setRolePrivileges,
          payload: privs,
        });
      }
    }
  }

  public static setRolePrivileges(privs: secmodel.SpecificPrivilege[]) {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(undefined, dispatcher, getState).setRolePrivileges(
        privs
      );
    };
  }
  setActiveUserPrivileges(list: schema.role_privilege[]) {
    if (this._state) {
      this.systemView.userPrivileges = list;
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_setActiveUserPrivileges,
          payload: list,
        });
      }
    }
  }

  public static setActiveUserPrivileges(list: schema.role_privilege[]) {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(
        undefined,
        dispatcher,
        getState
      ).setActiveUserPrivileges(list);
    };
  }
  setActiveUser(user: schema.user | undefined) {
    if (this._state) {
      this.systemView.activeUser = user;
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_setActiveUser,
          payload: user,
        });
      }
    }
  }

  public static setActiveUser(user: schema.user | undefined) {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(undefined, dispatcher, getState).setActiveUser(
        user
      );
    };
  }
  fillAItemCache(items: schema.aitem[]) {
    if (this._state) {
      items.forEach((item) => {
        this.aitemCache[item.id] = item;
      });
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_fillAItemCache,
          payload: items,
        });
      }
    }
  }

  public static fillAItemCache(items: schema.aitem[]) {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(undefined, dispatcher, getState).fillAItemCache(
        items
      );
    };
  }
  async getAItems(list: number[]) {
    this.loading_aitems = true;
    this.fillAItemCache(await API.getAItems(list));
    this.loading_aitems = false;
  }

  public static getAItems(list: number[]) {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(undefined, dispatcher, getState).getAItems(list);
    };
  }
  removePrivilege(priv: schema.user_role_privilege) {
    if (this._state) {
      if (!this.systemView || !this.systemView.userPrivileges) {
        return;
      }
      this.systemView.userPrivileges = this.systemView.userPrivileges.filter(
        (p) => {
          return p.id !== priv.role_privilege_id;
        }
      );
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_removePrivilege,
          payload: priv,
        });
      }
    }
  }

  public static removePrivilege(priv: schema.user_role_privilege) {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(undefined, dispatcher, getState).removePrivilege(
        priv
      );
    };
  }
  async removeUserPrivilege(priv: schema.user_role_privilege) {
    try {
      this.removePrivilege(priv);
      console.log('Should call removePrivilege', priv);
      // await API.removePrivilege(priv.id);
      this.setActiveUserPrivileges(await API.getUserPrivileges(priv.user_id));
      this.setUserRolePrivileges(await API.getUserRolePrivileges(priv.user_id));
    } catch (e) {
      console.log(e);
    }
  }

  public static removeUserPrivilege(priv: schema.user_role_privilege) {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(
        undefined,
        dispatcher,
        getState
      ).removeUserPrivilege(priv);
    };
  }
  async addPrivilegeToUser(priv: secmodel.GrantPrivilegeToUser) {
    try {
      // this.removePrivilege(priv);
      // console.log('Should call removePrivilege', priv);
      console.log(priv);
      // await API.addPrivilegeToUser(priv);
      this.setActiveUserPrivileges(await API.getUserPrivileges(priv.user_id));
      this.setUserRolePrivileges(await API.getUserRolePrivileges(priv.user_id));
    } catch (e) {
      console.log(e);
    }
  }

  public static addPrivilegeToUser(priv: secmodel.GrantPrivilegeToUser) {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(
        undefined,
        dispatcher,
        getState
      ).addPrivilegeToUser(priv);
    };
  }
  fillPItemCache(items: schema.pitem[]) {
    if (this._state) {
      items.forEach((item) => {
        this.pitemCache[item.id] = item;
      });
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_fillPItemCache,
          payload: items,
        });
      }
    }
  }

  public static fillPItemCache(items: schema.pitem[]) {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(undefined, dispatcher, getState).fillPItemCache(
        items
      );
    };
  }
  async getPItems(list: number[]) {
    this.loading_pitems = true;
    this.fillPItemCache(await API.getPItems(list));
    this.loading_pitems = false;
  }

  public static getPItems(list: number[]) {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(undefined, dispatcher, getState).getPItems(list);
    };
  }
  async selectUser(user: schema.user) {
    this.setActiveUser(user);
    this.setActiveUserPrivileges(await API.getUserPrivileges(user.id));
    this.setUserRolePrivileges(await API.getUserRolePrivileges(user.id));
  }

  public static selectUser(user: schema.user) {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(undefined, dispatcher, getState).selectUser(user);
    };
  }
  async newUser() {
    this.setActiveUser(undefined);
  }

  public static newUser() {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(undefined, dispatcher, getState).newUser();
    };
  }
  async createNewUser(user: model.NewUserFields) {
    const action = this.adminstatus.newuser;
    this.setStatus({ action });
    try {
      await AUTH.createNewUser(user);
      this.setActiveUser(undefined);
      this.setSuccess({ action });
    } catch (e) {
      const message =
        _.get(e, 'response.data.lde_message') ||
        _.get(e, 'response.data.detail') ||
        e.message;
      this.setError({ action, message });
      console.log(e);
    }
  }

  public static createNewUser(user: model.NewUserFields) {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(undefined, dispatcher, getState).createNewUser(
        user
      );
    };
  }
  async createNewUserFromLdap(user: model.LDAPUser) {
    const action = this.adminstatus.newUserFromLdap;
    this.setStatus({ action });
    try {
      const newUser = await AUTH.createNewUserFromLdap(user);
      this.getAllUsers();
      this.AdminStateDispatcher(push('/admin-tools/users/' + newUser.id));
      this.setSuccess({ action });
    } catch (e) {
      this.setError({ action, message: e.message });
      console.error(e);
    }
  }

  public static createNewUserFromLdap(user: model.LDAPUser) {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(
        undefined,
        dispatcher,
        getState
      ).createNewUserFromLdap(user);
    };
  }
  async sendPasswordResetLinkAdmin(params: PasswordResetParams) {
    const action = this.adminstatus.sendPasswordResetLink;
    this.setStatus({ action });
    try {
      await AUTH.requestPasswordChangeLinkAdmin({ ...params });
      this.setSuccess({
        action,
        translation: { id: 'adminTools.passwordResetLinkSent' },
      });
    } catch (e) {
      this.setError({ action, message: e.message });
    }
  }

  public static sendPasswordResetLinkAdmin(params: PasswordResetParams) {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(
        undefined,
        dispatcher,
        getState
      ).sendPasswordResetLinkAdmin(params);
    };
  }
  async loadRolePrivileges(id: number) {
    // this.setRolePrivileges(await API.getPrivilegesForRole(id));
  }

  public static loadRolePrivileges(id: number) {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(
        undefined,
        dispatcher,
        getState
      ).loadRolePrivileges(id);
    };
  }
  setActivePrivilege(privilege: schema.privilege) {
    if (this._state) {
      this.systemView.privilege = privilege;
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_setActivePrivilege,
          payload: privilege,
        });
      }
    }
  }

  public static setActivePrivilege(privilege: schema.privilege) {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(
        undefined,
        dispatcher,
        getState
      ).setActivePrivilege(privilege);
    };
  }
  setSelectedItem(id: number) {
    if (this._state) {
      if (this.selectedIdList && this.selectedIdList.indexOf(id) < 0) {
        this.selectedIdList.push(id);
      }
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_setSelectedItem,
          payload: id,
        });
      }
    }
  }

  public static setSelectedItem(id: number) {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(undefined, dispatcher, getState).setSelectedItem(
        id
      );
    };
  }
  unsetSelectedItem(id: number) {
    if (this._state) {
      if (this.selectedIdList && this.selectedIdList.indexOf(id) >= 0) {
        this.selectedIdList.splice(this.selectedIdList.indexOf(id), 1);
      }
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_unsetSelectedItem,
          payload: id,
        });
      }
    }
  }

  public static unsetSelectedItem(id: number) {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(undefined, dispatcher, getState).unsetSelectedItem(
        id
      );
    };
  }
  setActiveItem(id: number) {
    if (this._state) {
      this.activeItemID = id;
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_setActiveItem,
          payload: id,
        });
      }
    }
  }

  public static setActiveItem(id: number) {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(undefined, dispatcher, getState).setActiveItem(id);
    };
  }
  selectFictureTable(name: FixtureKeys) {
    if (this._state) {
      this.activeFixtureTable = name;
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_selectFictureTable,
          payload: name,
        });
      }
    }
  }

  public static selectFictureTable(name: FixtureKeys) {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(
        undefined,
        dispatcher,
        getState
      ).selectFictureTable(name);
    };
  }
  setActiveContract(id: number) {
    if (this._state) {
      if (this.contractNodes) {
        this.activeContract = this.findContractNode(this.contractNodes, id);
      }
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_setActiveContract,
          payload: id,
        });
      }
    }
  }

  public static setActiveContract(id: number) {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(undefined, dispatcher, getState).setActiveContract(
        id
      );
    };
  }
  updateLocalFixture(value: Fixture | undefined) {
    if (this._state) {
      if (typeof value === 'undefined') {
        return;
      }
      this.dbFixture = value;
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_updateLocalFixture,
          payload: value,
        });
      }
    }
  }

  public static updateLocalFixture(value: Fixture | undefined) {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(
        undefined,
        dispatcher,
        getState
      ).updateLocalFixture(value);
    };
  }
  async loadSettings() {
    this.systemModel = await API.getSystemAdminSettings();
  }

  public static loadSettings() {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(undefined, dispatcher, getState).loadSettings();
    };
  }
  async loadFixture() {
    this.dbFixture = await API.getBaseFixture();
  }

  public static loadFixture() {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(undefined, dispatcher, getState).loadFixture();
    };
  }
  async initDBWithFixture() {
    if (typeof this.dbFixture === 'undefined') {
      return;
    }
    try {
      this.isResettingDatabase = true;
      await API.initWithFixture(this.dbFixture);
      this.isResettingDatabase = false;
      this.didResetDatabase = true;
      this.initMessage = 'Database initialized';
    } catch (e) {
      this.initMessage = 'Initialization failed';
    }
  }

  public static initDBWithFixture() {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(
        undefined,
        dispatcher,
        getState
      ).initDBWithFixture();
    };
  }
  setView(name: string) {
    if (this._state) {
      this.view = name;
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_setView,
          payload: name,
        });
      }
    }
  }

  public static setView(name: string) {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(undefined, dispatcher, getState).setView(name);
    };
  }
  async initializeDB() {
    this.isResettingDatabase = true;
    await API.initializeDB();
    this.users = await API.getDBUsers();
    this.isInitRun = true;
    this.isResettingDatabase = false;
    this.didResetDatabase = true;
  }

  public static initializeDB() {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(undefined, dispatcher, getState).initializeDB();
    };
  }
  async getUsers() {
    this.users = await API.getDBUsers();
  }

  public static getUsers() {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(undefined, dispatcher, getState).getUsers();
    };
  }
  async getAllUsers(onlyDBUsers?: boolean) {
    const action = this.adminstatus.getAllUsers;
    try {
      this.setStatus({ action });
      this.users = await API.getDBUsers();
      if (!onlyDBUsers) {
        this.ldapUsers = await AUTH.getAllUsers();
      }
      const users = this.users.reduce(
        (prev, curr) => ({ ...prev, [curr.ldapid]: curr }),
        {}
      );
      const ldapUsers = this.ldapUsers.reduce(
        (prev, curr) => ({ ...prev, [curr.dn]: curr }),
        {}
      );
      const allUsers: model.UsersTableRow[] = [];
      this.users.map((user) => {
        const ldapUser = onlyDBUsers ? undefined : ldapUsers[user.ldapid];
        if (ldapUser || onlyDBUsers) {
          allUsers.push({
            name: user.firstname + ' ' + user.lastname,
            email: user.email!,
            id: user.id,
            disabled: (ldapUser && ldapUser.disabled) || false,
          });
        }
      });
      if (!onlyDBUsers) {
        this.ldapUsers.map((user, index) => {
          const addedUser = users[user.dn];
          if (!addedUser) {
            const name =
              user.mail || user.displayName || user.cn + ' ' + user.sn;
            // user.cn && user.sn
            //   ? user.cn + ' ' + user.sn
            //   : user.displayName || user.mail;
            allUsers.push({
              email: user.mail,
              name,
              id: -(index - 1),
              disabled: user.disabled,
            });
          }
        });
      }
      this.setSuccess({ action });
      this.setAllUsers(allUsers);
    } catch (e) {
      this.setError({ action });
      console.error('Something went wrong: ', e);
    }
  }

  public static getAllUsers(onlyDBUsers?: boolean) {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(undefined, dispatcher, getState).getAllUsers(
        onlyDBUsers
      );
    };
  }
  setAllUsers(users: model.UsersTableRow[]) {
    if (this._state) {
      this.allUsers = users;
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_setAllUsers,
          payload: users,
        });
      }
    }
  }

  public static setAllUsers(users: model.UsersTableRow[]) {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(undefined, dispatcher, getState).setAllUsers(
        users
      );
    };
  }
  async getItems() {
    this.items = await API.getItems();
    this.view = 'items';
  }

  public static getItems() {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(undefined, dispatcher, getState).getItems();
    };
  }
  private findContractNode(
    list: ContractNode[],
    id: number
  ): ContractNode | undefined {
    if (!list) {
      return;
    }
    const active = list.filter((item) => item.id === id).pop();
    if (active) {
      return active;
    }
    for (const ch of list) {
      const a = this.findContractNode(ch.children, id);
      if (a) {
        return a;
      }
    }
    return undefined;
  }
  addChildContracts(params: { parentId: number; children: schema.aitem[] }) {
    if (this._state) {
      if (!this.contractNodes) {
        return;
      }
      const active = this.findContractNode(this.contractNodes, params.parentId);
      if (active) {
        active.children = params.children.map((item) => {
          return {
            id: item.id,
            name: item.name as string,
            children: [],
            permissions: [],
          };
        });
      }
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_addChildContracts,
          payload: params,
        });
      }
    }
  }

  public static addChildContracts(params: {
    parentId: number;
    children: schema.aitem[];
  }) {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(undefined, dispatcher, getState).addChildContracts(
        params
      );
    };
  }
  async getUserPrivileges(forID: number) {
    const permissions = await API.getUserPrivileges(forID);
    console.log(permissions);
  }

  public static getUserPrivileges(forID: number) {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(undefined, dispatcher, getState).getUserPrivileges(
        forID
      );
    };
  }
  async getContracts() {
    this.contracts = await API.getContracts();
  }

  public static getContracts() {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(undefined, dispatcher, getState).getContracts();
    };
  }
  async getAllContracts() {
    const contracts = await API.getAllContracts();
    // TODO: Do the sorting in backend
    this.contracts = contracts.sort((a, b) => a.name!.localeCompare(b.name!));
  }

  public static getAllContracts() {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(undefined, dispatcher, getState).getAllContracts();
    };
  }
  async removeContract(id: number) {
    try {
      return await API.removeAitem({ id });
    } catch (e) {}
    return false;
  }

  public static removeContract(id: number) {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(undefined, dispatcher, getState).removeContract(
        id
      );
    };
  }
  async getAvailableRoles() {
    const roles = await API.getAvailableRoles();
    this.setRoles(roles);
  }

  public static getAvailableRoles() {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(
        undefined,
        dispatcher,
        getState
      ).getAvailableRoles();
    };
  }
  setRoles(roles: Model.APRole[]) {
    if (this._state) {
      this.roles = roles;
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_setRoles,
          payload: roles,
        });
      }
    }
  }

  public static setRoles(roles: Model.APRole[]) {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(undefined, dispatcher, getState).setRoles(roles);
    };
  }
  async getAvailableActions() {
    const actions = await API.getAllActions();
    this.setActions(actions);
  }

  public static getAvailableActions() {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(
        undefined,
        dispatcher,
        getState
      ).getAvailableActions();
    };
  }
  setActions(actions: schema.action[]) {
    if (this._state) {
      this.actions = actions;
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_setActions,
          payload: actions,
        });
      }
    }
  }

  public static setActions(actions: schema.action[]) {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(undefined, dispatcher, getState).setActions(
        actions
      );
    };
  }
  async getChildContracts(forID: number) {
    const children = await API.getChildContractNodes(forID);
    this.addChildContracts({ parentId: forID, children });
    this.view = 'contracts';
  }

  public static getChildContracts(forID: number) {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(undefined, dispatcher, getState).getChildContracts(
        forID
      );
    };
  }
  async refreshContracts() {
    this.rootContracts = await API.getRootContractNodes();
    this.contractNodes = this.rootContracts
      .sort((a, b) => a.id - b.id)
      .map((item) => {
        return {
          id: item.id,
          name: item.name as string,
          children: [],
          permissions: [],
        };
      });
    this.view = 'contracts';
  }

  public static refreshContracts() {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(
        undefined,
        dispatcher,
        getState
      ).refreshContracts();
    };
  }
  async getRootContractNodes() {
    if (this.rootContracts && this.rootContracts.length === 0) {
      this.rootContracts = await API.getRootContractNodes();
      this.contractNodes = this.rootContracts
        .sort((a, b) => a.id - b.id)
        .map((item) => {
          return {
            id: item.id,
            name: item.name as string,
            children: [],
            permissions: [],
          };
        });
    }
    this.view = 'contracts';
  }

  public static getRootContractNodes() {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(
        undefined,
        dispatcher,
        getState
      ).getRootContractNodes();
    };
  }
  async uploadAdminDocumentCollection(files: File[]) {
    if (!files) {
      return;
    }
    const formData = new FormData();
    formData.append('file', files[0]);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    this.latestUpload = (
      await axios.post('/docs/v1/upload/documentcollection', formData, config)
    ).data as model.AdminDocumentUpload;
  }

  public static uploadAdminDocumentCollection(files: File[]) {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(
        undefined,
        dispatcher,
        getState
      ).uploadAdminDocumentCollection(files);
    };
  }
  async uploadNewDocuments(files: File[]) {
    if (!files) {
      return;
    }
    const formData = new FormData();
    formData.append('file', files[0]);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    this.latestUpload = (
      await axios.post('/docs/v1/upload/adminexcel', formData, config)
    ).data as model.AdminDocumentUpload;
  }

  public static uploadNewDocuments(files: File[]) {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(
        undefined,
        dispatcher,
        getState
      ).uploadNewDocuments(files);
    };
  }
  async loadDocumentCategories() {
    try {
      if (!this.isLoadingCategories) {
        this.isLoadingCategories = true;
        this.documentCategories = await API.getDocumentTypes();
        this.isLoadingCategories = false;
      }
    } catch (e) {
      this.isLoadingCategories = false;
    }
  }

  public static loadDocumentCategories() {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(
        undefined,
        dispatcher,
        getState
      ).loadDocumentCategories();
    };
  }
  async getAllBuildings() {
    try {
      const buildings = await API.getAllBuildings();
      this.setAllBuildings(buildings);
    } catch (e) {
      console.log(e);
    }
  }

  public static getAllBuildings() {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(undefined, dispatcher, getState).getAllBuildings();
    };
  }
  setAllBuildings(buildings: schema.pitem[]) {
    if (this._state) {
      this.allBuildings = buildings;
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: AdminStateEnums.AdminState_setAllBuildings,
          payload: buildings,
        });
      }
    }
  }

  public static setAllBuildings(buildings: schema.pitem[]) {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(undefined, dispatcher, getState).setAllBuildings(
        buildings
      );
    };
  }
  async createNewPhase(params: { phaseName: string; longName: string }) {
    const action = this.adminstatus.createNewPhase;
    this.setStatus({ action });
    try {
      await API.createNewPhase(params);
      this.setSuccess({
        action,
        translation: { id: 'adminTools.newPhaseSuccess' },
      });
    } catch (e) {
      this.setError({ action, message: e.message });
    }
  }

  public static createNewPhase(params: {
    phaseName: string;
    longName: string;
  }) {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(undefined, dispatcher, getState).createNewPhase(
        params
      );
    };
  }
  async createNewContract(params: {
    phaseId: number;
    contractName: string;
    longName: string;
  }) {
    const action = this.adminstatus.createNewContract;
    this.setStatus({ action });
    try {
      await API.createNewContract(params);
      this.setSuccess({
        action,
        translation: { id: 'adminTools.newContractSuccess' },
      });
    } catch (e) {
      this.setError({ action, message: e.message });
    }
  }

  public static createNewContract(params: {
    phaseId: number;
    contractName: string;
    longName: string;
  }) {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(undefined, dispatcher, getState).createNewContract(
        params
      );
    };
  }
  async createNewSystem(params: {
    parent_system_id: number | undefined;
    system_name: string;
    description: string;
  }) {
    const action = this.adminstatus.createNewSystem;
    this.setStatus({ action });
    try {
      await DOCAPI.createNewSystem(params);
      this.setSuccess({
        action,
        translation: { id: 'adminTools.newSystemSuccess' },
      });
    } catch (e) {
      this.setError({ action, message: e.message });
    }
  }

  public static createNewSystem(params: {
    parent_system_id: number | undefined;
    system_name: string;
    description: string;
  }) {
    return (dispatcher: any, getState: any) => {
      return new RAdminState(undefined, dispatcher, getState).createNewSystem(
        params
      );
    };
  }
}

export const AdminStateEnums = {
  AdminState_isInitRun: 'AdminState_isInitRun',
  AdminState_networkError: 'AdminState_networkError',
  AdminState_errors: 'AdminState_errors',
  AdminState_adminstatus: 'AdminState_adminstatus',
  AdminState_view: 'AdminState_view',
  AdminState_users: 'AdminState_users',
  AdminState_ldapUsers: 'AdminState_ldapUsers',
  AdminState_allUsers: 'AdminState_allUsers',
  AdminState_allBuildings: 'AdminState_allBuildings',
  AdminState_items: 'AdminState_items',
  AdminState_contracts: 'AdminState_contracts',
  AdminState_roles: 'AdminState_roles',
  AdminState_actions: 'AdminState_actions',
  AdminState_rootContracts: 'AdminState_rootContracts',
  AdminState_contractNodes: 'AdminState_contractNodes',
  AdminState_activeItemID: 'AdminState_activeItemID',
  AdminState_activeContract: 'AdminState_activeContract',
  AdminState_activeFixtureTable: 'AdminState_activeFixtureTable',
  AdminState_dbFixture: 'AdminState_dbFixture',
  AdminState_initMessage: 'AdminState_initMessage',
  AdminState_selectedIdList: 'AdminState_selectedIdList',
  AdminState_systemModel: 'AdminState_systemModel',
  AdminState_systemView: 'AdminState_systemView',
  AdminState_loading_aitems: 'AdminState_loading_aitems',
  AdminState_aitemCache: 'AdminState_aitemCache',
  AdminState_loading_pitems: 'AdminState_loading_pitems',
  AdminState_pitemCache: 'AdminState_pitemCache',
  AdminState_uploadedExcel: 'AdminState_uploadedExcel',
  AdminState_latestUpload: 'AdminState_latestUpload',
  AdminState_adminSearch: 'AdminState_adminSearch',
  AdminState_isLoadingResults: 'AdminState_isLoadingResults',
  AdminState_isRunningTests: 'AdminState_isRunningTests',
  AdminState_testResults: 'AdminState_testResults',
  AdminState_activeTestSet: 'AdminState_activeTestSet',
  AdminState_isLoadingCategories: 'AdminState_isLoadingCategories',
  AdminState_documentCategories: 'AdminState_documentCategories',
  AdminState_isResettingDatabase: 'AdminState_isResettingDatabase',
  AdminState_didResetDatabase: 'AdminState_didResetDatabase',
  AdminState_setStatus: 'AdminState_setStatus',
  AdminState_setSuccess: 'AdminState_setSuccess',
  AdminState_setError: 'AdminState_setError',
  AdminState_resetAdminSnacks: 'AdminState_resetAdminSnacks',
  AdminState_setTestResults: 'AdminState_setTestResults',
  AdminState_setSelectedTestResult: 'AdminState_setSelectedTestResult',
  AdminState_setFreeText: 'AdminState_setFreeText',
  AdminState_setContractFilter: 'AdminState_setContractFilter',
  AdminState_setBuildingText: 'AdminState_setBuildingText',
  AdminState_setRoomText: 'AdminState_setRoomText',
  AdminState_setFloorText: 'AdminState_setFloorText',
  AdminState_setContractText: 'AdminState_setContractText',
  AdminState_setItemText: 'AdminState_setItemText',
  AdminState_saveDocument: 'AdminState_saveDocument',
  AdminState_setActiveDocument: 'AdminState_setActiveDocument',
  AdminState_setSearchResults: 'AdminState_setSearchResults',
  AdminState_initSearch: 'AdminState_initSearch',
  AdminState_setSystemView: 'AdminState_setSystemView',
  AdminState_setActiveRole: 'AdminState_setActiveRole',
  AdminState_setUserRolePrivileges: 'AdminState_setUserRolePrivileges',
  AdminState_setRolePrivileges: 'AdminState_setRolePrivileges',
  AdminState_setActiveUserPrivileges: 'AdminState_setActiveUserPrivileges',
  AdminState_setActiveUser: 'AdminState_setActiveUser',
  AdminState_fillAItemCache: 'AdminState_fillAItemCache',
  AdminState_removePrivilege: 'AdminState_removePrivilege',
  AdminState_fillPItemCache: 'AdminState_fillPItemCache',
  AdminState_setActivePrivilege: 'AdminState_setActivePrivilege',
  AdminState_setSelectedItem: 'AdminState_setSelectedItem',
  AdminState_unsetSelectedItem: 'AdminState_unsetSelectedItem',
  AdminState_setActiveItem: 'AdminState_setActiveItem',
  AdminState_selectFictureTable: 'AdminState_selectFictureTable',
  AdminState_setActiveContract: 'AdminState_setActiveContract',
  AdminState_updateLocalFixture: 'AdminState_updateLocalFixture',
  AdminState_setView: 'AdminState_setView',
  AdminState_setAllUsers: 'AdminState_setAllUsers',
  AdminState_findContractNode: 'AdminState_findContractNode',
  AdminState_addChildContracts: 'AdminState_addChildContracts',
  AdminState_setRoles: 'AdminState_setRoles',
  AdminState_setActions: 'AdminState_setActions',
  AdminState_setAllBuildings: 'AdminState_setAllBuildings',
};

export const AdminStateReducer = (
  state: IAdminState = initAdminState(),
  action: any
) => {
  return immer.produce(state, (draft: IAdminState) => {
    switch (action.type) {
      case AdminStateEnums.AdminState_isInitRun:
        new RAdminState(draft).isInitRun = action.payload;
        break;
      case AdminStateEnums.AdminState_networkError:
        new RAdminState(draft).networkError = action.payload;
        break;
      case AdminStateEnums.AdminState_errors:
        new RAdminState(draft).errors = action.payload;
        break;
      case AdminStateEnums.AdminState_adminstatus:
        new RAdminState(draft).adminstatus = action.payload;
        break;
      case AdminStateEnums.AdminState_view:
        new RAdminState(draft).view = action.payload;
        break;
      case AdminStateEnums.AdminState_users:
        new RAdminState(draft).users = action.payload;
        break;
      case AdminStateEnums.AdminState_ldapUsers:
        new RAdminState(draft).ldapUsers = action.payload;
        break;
      case AdminStateEnums.AdminState_allUsers:
        new RAdminState(draft).allUsers = action.payload;
        break;
      case AdminStateEnums.AdminState_allBuildings:
        new RAdminState(draft).allBuildings = action.payload;
        break;
      case AdminStateEnums.AdminState_items:
        new RAdminState(draft).items = action.payload;
        break;
      case AdminStateEnums.AdminState_contracts:
        new RAdminState(draft).contracts = action.payload;
        break;
      case AdminStateEnums.AdminState_roles:
        new RAdminState(draft).roles = action.payload;
        break;
      case AdminStateEnums.AdminState_actions:
        new RAdminState(draft).actions = action.payload;
        break;
      case AdminStateEnums.AdminState_rootContracts:
        new RAdminState(draft).rootContracts = action.payload;
        break;
      case AdminStateEnums.AdminState_contractNodes:
        new RAdminState(draft).contractNodes = action.payload;
        break;
      case AdminStateEnums.AdminState_activeItemID:
        new RAdminState(draft).activeItemID = action.payload;
        break;
      case AdminStateEnums.AdminState_activeContract:
        new RAdminState(draft).activeContract = action.payload;
        break;
      case AdminStateEnums.AdminState_activeFixtureTable:
        new RAdminState(draft).activeFixtureTable = action.payload;
        break;
      case AdminStateEnums.AdminState_dbFixture:
        new RAdminState(draft).dbFixture = action.payload;
        break;
      case AdminStateEnums.AdminState_initMessage:
        new RAdminState(draft).initMessage = action.payload;
        break;
      case AdminStateEnums.AdminState_selectedIdList:
        new RAdminState(draft).selectedIdList = action.payload;
        break;
      case AdminStateEnums.AdminState_systemModel:
        new RAdminState(draft).systemModel = action.payload;
        break;
      case AdminStateEnums.AdminState_systemView:
        new RAdminState(draft).systemView = action.payload;
        break;
      case AdminStateEnums.AdminState_loading_aitems:
        new RAdminState(draft).loading_aitems = action.payload;
        break;
      case AdminStateEnums.AdminState_aitemCache:
        new RAdminState(draft).aitemCache = action.payload;
        break;
      case AdminStateEnums.AdminState_loading_pitems:
        new RAdminState(draft).loading_pitems = action.payload;
        break;
      case AdminStateEnums.AdminState_pitemCache:
        new RAdminState(draft).pitemCache = action.payload;
        break;
      case AdminStateEnums.AdminState_uploadedExcel:
        new RAdminState(draft).uploadedExcel = action.payload;
        break;
      case AdminStateEnums.AdminState_latestUpload:
        new RAdminState(draft).latestUpload = action.payload;
        break;
      case AdminStateEnums.AdminState_adminSearch:
        new RAdminState(draft).adminSearch = action.payload;
        break;
      case AdminStateEnums.AdminState_isLoadingResults:
        new RAdminState(draft).isLoadingResults = action.payload;
        break;
      case AdminStateEnums.AdminState_isRunningTests:
        new RAdminState(draft).isRunningTests = action.payload;
        break;
      case AdminStateEnums.AdminState_testResults:
        new RAdminState(draft).testResults = action.payload;
        break;
      case AdminStateEnums.AdminState_activeTestSet:
        new RAdminState(draft).activeTestSet = action.payload;
        break;
      case AdminStateEnums.AdminState_isLoadingCategories:
        new RAdminState(draft).isLoadingCategories = action.payload;
        break;
      case AdminStateEnums.AdminState_documentCategories:
        new RAdminState(draft).documentCategories = action.payload;
        break;
      case AdminStateEnums.AdminState_isResettingDatabase:
        new RAdminState(draft).isResettingDatabase = action.payload;
        break;
      case AdminStateEnums.AdminState_didResetDatabase:
        new RAdminState(draft).didResetDatabase = action.payload;
        break;
      case AdminStateEnums.AdminState_setStatus:
        new RAdminState(draft).setStatus(action.payload);
        break;
      case AdminStateEnums.AdminState_setSuccess:
        new RAdminState(draft).setSuccess(action.payload);
        break;
      case AdminStateEnums.AdminState_setError:
        new RAdminState(draft).setError(action.payload);
        break;
      case AdminStateEnums.AdminState_resetAdminSnacks:
        new RAdminState(draft).resetAdminSnacks();
        break;
      case AdminStateEnums.AdminState_setTestResults:
        new RAdminState(draft).setTestResults(action.payload);
        break;
      case AdminStateEnums.AdminState_setSelectedTestResult:
        new RAdminState(draft).setSelectedTestResult(action.payload);
        break;
      case AdminStateEnums.AdminState_setFreeText:
        new RAdminState(draft).setFreeText(action.payload);
        break;
      case AdminStateEnums.AdminState_setContractFilter:
        new RAdminState(draft).setContractFilter(action.payload);
        break;
      case AdminStateEnums.AdminState_setBuildingText:
        new RAdminState(draft).setBuildingText(action.payload);
        break;
      case AdminStateEnums.AdminState_setRoomText:
        new RAdminState(draft).setRoomText(action.payload);
        break;
      case AdminStateEnums.AdminState_setFloorText:
        new RAdminState(draft).setFloorText(action.payload);
        break;
      case AdminStateEnums.AdminState_setContractText:
        new RAdminState(draft).setContractText(action.payload);
        break;
      case AdminStateEnums.AdminState_setItemText:
        new RAdminState(draft).setItemText(action.payload);
        break;
      case AdminStateEnums.AdminState_saveDocument:
        new RAdminState(draft).saveDocument(action.payload);
        break;
      case AdminStateEnums.AdminState_setActiveDocument:
        new RAdminState(draft).setActiveDocument(action.payload);
        break;
      case AdminStateEnums.AdminState_setSearchResults:
        new RAdminState(draft).setSearchResults(action.payload);
        break;
      case AdminStateEnums.AdminState_initSearch:
        new RAdminState(draft).initSearch(action.payload);
        break;
      case AdminStateEnums.AdminState_setSystemView:
        new RAdminState(draft).setSystemView(action.payload);
        break;
      case AdminStateEnums.AdminState_setActiveRole:
        new RAdminState(draft).setActiveRole(action.payload);
        break;
      case AdminStateEnums.AdminState_setUserRolePrivileges:
        new RAdminState(draft).setUserRolePrivileges(action.payload);
        break;
      case AdminStateEnums.AdminState_setRolePrivileges:
        new RAdminState(draft).setRolePrivileges(action.payload);
        break;
      case AdminStateEnums.AdminState_setActiveUserPrivileges:
        new RAdminState(draft).setActiveUserPrivileges(action.payload);
        break;
      case AdminStateEnums.AdminState_setActiveUser:
        new RAdminState(draft).setActiveUser(action.payload);
        break;
      case AdminStateEnums.AdminState_fillAItemCache:
        new RAdminState(draft).fillAItemCache(action.payload);
        break;
      case AdminStateEnums.AdminState_removePrivilege:
        new RAdminState(draft).removePrivilege(action.payload);
        break;
      case AdminStateEnums.AdminState_fillPItemCache:
        new RAdminState(draft).fillPItemCache(action.payload);
        break;
      case AdminStateEnums.AdminState_setActivePrivilege:
        new RAdminState(draft).setActivePrivilege(action.payload);
        break;
      case AdminStateEnums.AdminState_setSelectedItem:
        new RAdminState(draft).setSelectedItem(action.payload);
        break;
      case AdminStateEnums.AdminState_unsetSelectedItem:
        new RAdminState(draft).unsetSelectedItem(action.payload);
        break;
      case AdminStateEnums.AdminState_setActiveItem:
        new RAdminState(draft).setActiveItem(action.payload);
        break;
      case AdminStateEnums.AdminState_selectFictureTable:
        new RAdminState(draft).selectFictureTable(action.payload);
        break;
      case AdminStateEnums.AdminState_setActiveContract:
        new RAdminState(draft).setActiveContract(action.payload);
        break;
      case AdminStateEnums.AdminState_updateLocalFixture:
        new RAdminState(draft).updateLocalFixture(action.payload);
        break;
      case AdminStateEnums.AdminState_setView:
        new RAdminState(draft).setView(action.payload);
        break;
      case AdminStateEnums.AdminState_setAllUsers:
        new RAdminState(draft).setAllUsers(action.payload);
        break;
      case AdminStateEnums.AdminState_addChildContracts:
        new RAdminState(draft).addChildContracts(action.payload);
        break;
      case AdminStateEnums.AdminState_setRoles:
        new RAdminState(draft).setRoles(action.payload);
        break;
      case AdminStateEnums.AdminState_setActions:
        new RAdminState(draft).setActions(action.payload);
        break;
      case AdminStateEnums.AdminState_setAllBuildings:
        new RAdminState(draft).setAllBuildings(action.payload);
        break;
    }
  });
};
/********************************
 * React Context API component   *
 ********************************/
export const AdminStateContext = React.createContext<IProps>(
  initWithMethodsAdminState()
);
export const AdminStateConsumer = AdminStateContext.Consumer;
let instanceCnt = 1;
export class AdminStateProvider extends React.Component {
  public state: IAdminState = initAdminState();
  public lastSetState: IAdminState;
  private __devTools: any = null;
  private __selectorlistedUsers: any = null;
  constructor(props: any) {
    super(props);
    this.lastSetState = this.state;
    this.AdminStateDispatcher = this.AdminStateDispatcher.bind(this);
    this.setStatus = this.setStatus.bind(this);
    this.setSuccess = this.setSuccess.bind(this);
    this.setError = this.setError.bind(this);
    this.resetAdminSnacks = this.resetAdminSnacks.bind(this);
    this.setTestResults = this.setTestResults.bind(this);
    this.setSelectedTestResult = this.setSelectedTestResult.bind(this);
    this.getTestResults = this.getTestResults.bind(this);
    this.runOnlyTestset = this.runOnlyTestset.bind(this);
    this.runTestResults = this.runTestResults.bind(this);
    this.setFreeText = this.setFreeText.bind(this);
    this.setContractFilter = this.setContractFilter.bind(this);
    this.setBuildingText = this.setBuildingText.bind(this);
    this.setRoomText = this.setRoomText.bind(this);
    this.setFloorText = this.setFloorText.bind(this);
    this.setContractText = this.setContractText.bind(this);
    this.setItemText = this.setItemText.bind(this);
    this.getMetadata = this.getMetadata.bind(this);
    this.saveDocument = this.saveDocument.bind(this);
    this.setActiveDocument = this.setActiveDocument.bind(this);
    this.fetchDocument = this.fetchDocument.bind(this);
    this.setSearchResults = this.setSearchResults.bind(this);
    this.documentSearch = this.documentSearch.bind(this);
    this.initSearch = this.initSearch.bind(this);
    this.getUserSearches = this.getUserSearches.bind(this);
    this.fileUpload = this.fileUpload.bind(this);
    this.setSystemView = this.setSystemView.bind(this);
    this.setActiveRole = this.setActiveRole.bind(this);
    this.setUserRolePrivileges = this.setUserRolePrivileges.bind(this);
    this.setRolePrivileges = this.setRolePrivileges.bind(this);
    this.setActiveUserPrivileges = this.setActiveUserPrivileges.bind(this);
    this.setActiveUser = this.setActiveUser.bind(this);
    this.fillAItemCache = this.fillAItemCache.bind(this);
    this.getAItems = this.getAItems.bind(this);
    this.removePrivilege = this.removePrivilege.bind(this);
    this.removeUserPrivilege = this.removeUserPrivilege.bind(this);
    this.addPrivilegeToUser = this.addPrivilegeToUser.bind(this);
    this.fillPItemCache = this.fillPItemCache.bind(this);
    this.getPItems = this.getPItems.bind(this);
    this.selectUser = this.selectUser.bind(this);
    this.newUser = this.newUser.bind(this);
    this.createNewUser = this.createNewUser.bind(this);
    this.createNewUserFromLdap = this.createNewUserFromLdap.bind(this);
    this.sendPasswordResetLinkAdmin = this.sendPasswordResetLinkAdmin.bind(
      this
    );
    this.loadRolePrivileges = this.loadRolePrivileges.bind(this);
    this.setActivePrivilege = this.setActivePrivilege.bind(this);
    this.setSelectedItem = this.setSelectedItem.bind(this);
    this.unsetSelectedItem = this.unsetSelectedItem.bind(this);
    this.setActiveItem = this.setActiveItem.bind(this);
    this.selectFictureTable = this.selectFictureTable.bind(this);
    this.setActiveContract = this.setActiveContract.bind(this);
    this.updateLocalFixture = this.updateLocalFixture.bind(this);
    this.loadSettings = this.loadSettings.bind(this);
    this.loadFixture = this.loadFixture.bind(this);
    this.initDBWithFixture = this.initDBWithFixture.bind(this);
    this.setView = this.setView.bind(this);
    this.initializeDB = this.initializeDB.bind(this);
    this.getUsers = this.getUsers.bind(this);
    this.getAllUsers = this.getAllUsers.bind(this);
    this.setAllUsers = this.setAllUsers.bind(this);
    this.getItems = this.getItems.bind(this);
    this.addChildContracts = this.addChildContracts.bind(this);
    this.getUserPrivileges = this.getUserPrivileges.bind(this);
    this.getContracts = this.getContracts.bind(this);
    this.getAllContracts = this.getAllContracts.bind(this);
    this.removeContract = this.removeContract.bind(this);
    this.getAvailableRoles = this.getAvailableRoles.bind(this);
    this.setRoles = this.setRoles.bind(this);
    this.getAvailableActions = this.getAvailableActions.bind(this);
    this.setActions = this.setActions.bind(this);
    this.getChildContracts = this.getChildContracts.bind(this);
    this.refreshContracts = this.refreshContracts.bind(this);
    this.getRootContractNodes = this.getRootContractNodes.bind(this);
    this.uploadAdminDocumentCollection = this.uploadAdminDocumentCollection.bind(
      this
    );
    this.uploadNewDocuments = this.uploadNewDocuments.bind(this);
    this.loadDocumentCategories = this.loadDocumentCategories.bind(this);
    this.getAllBuildings = this.getAllBuildings.bind(this);
    this.setAllBuildings = this.setAllBuildings.bind(this);
    this.createNewPhase = this.createNewPhase.bind(this);
    this.createNewContract = this.createNewContract.bind(this);
    this.createNewSystem = this.createNewSystem.bind(this);
    this.__selectorlistedUsers = listedUsersSelectorFnCreator();
    const devs = window['__REDUX_DEVTOOLS_EXTENSION__']
      ? window['__REDUX_DEVTOOLS_EXTENSION__']
      : null;
    if (devs) {
      this.__devTools = devs.connect({ name: 'AdminState' + instanceCnt++ });
      this.__devTools.init(this.state);
      this.__devTools.subscribe((msg: any) => {
        if (msg.type === 'DISPATCH' && msg.state) {
          this.setState(JSON.parse(msg.state));
        }
      });
    }
  }
  public componentWillUnmount() {
    if (this.__devTools) {
      this.__devTools.unsubscribe();
    }
  }
  public setStateSync(state: IAdminState) {
    this.lastSetState = state;
    this.setState(state);
  }
  /**
   * @dispatch true
   */
  async AdminStateDispatcher(action: any) {
    return new RAdminState(
      undefined,
      (action: any) => {
        const nextState = AdminStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ AdminState: this.lastSetState })
    ).AdminStateDispatcher(action);
  }
  setStatus(params: {
    action: ActionStatusDetail;
    snack?: SnackDetails;
    finished?: boolean;
  }) {
    const nextState = immer.produce(this.state, (draft: IAdminState) =>
      new RAdminState(draft).setStatus(params)
    );
    if (this.__devTools) {
      this.__devTools.send('setStatus', nextState);
    }
    this.setStateSync(nextState);
  }
  setSuccess(params: {
    action: ActionStatusDetail;
    translation?: TranslationDetails;
  }) {
    const nextState = immer.produce(this.state, (draft: IAdminState) =>
      new RAdminState(draft).setSuccess(params)
    );
    if (this.__devTools) {
      this.__devTools.send('setSuccess', nextState);
    }
    this.setStateSync(nextState);
  }
  setError(params: {
    action: ActionStatusDetail;
    message?: string;
    translation?: TranslationDetails;
  }) {
    const nextState = immer.produce(this.state, (draft: IAdminState) =>
      new RAdminState(draft).setError(params)
    );
    if (this.__devTools) {
      this.__devTools.send('setError', nextState);
    }
    this.setStateSync(nextState);
  }
  resetAdminSnacks() {
    const nextState = immer.produce(this.state, (draft: IAdminState) =>
      new RAdminState(draft).resetAdminSnacks()
    );
    if (this.__devTools) {
      this.__devTools.send('resetAdminSnacks', nextState);
    }
    this.setStateSync(nextState);
  }
  setTestResults(rows: model.TestResultRow[]) {
    const nextState = immer.produce(this.state, (draft: IAdminState) =>
      new RAdminState(draft).setTestResults(rows)
    );
    if (this.__devTools) {
      this.__devTools.send('setTestResults', nextState);
    }
    this.setStateSync(nextState);
  }
  setSelectedTestResult(name: string) {
    const nextState = immer.produce(this.state, (draft: IAdminState) =>
      new RAdminState(draft).setSelectedTestResult(name)
    );
    if (this.__devTools) {
      this.__devTools.send('setSelectedTestResult', nextState);
    }
    this.setStateSync(nextState);
  }
  async getTestResults() {
    return new RAdminState(
      undefined,
      (action: any) => {
        const nextState = AdminStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ AdminState: this.lastSetState })
    ).getTestResults();
  }
  async runOnlyTestset(name: string) {
    return new RAdminState(
      undefined,
      (action: any) => {
        const nextState = AdminStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ AdminState: this.lastSetState })
    ).runOnlyTestset(name);
  }
  async runTestResults() {
    return new RAdminState(
      undefined,
      (action: any) => {
        const nextState = AdminStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ AdminState: this.lastSetState })
    ).runTestResults();
  }
  setFreeText(value: string) {
    const nextState = immer.produce(this.state, (draft: IAdminState) =>
      new RAdminState(draft).setFreeText(value)
    );
    if (this.__devTools) {
      this.__devTools.send('setFreeText', nextState);
    }
    this.setStateSync(nextState);
  }
  setContractFilter(value: string) {
    const nextState = immer.produce(this.state, (draft: IAdminState) =>
      new RAdminState(draft).setContractFilter(value)
    );
    if (this.__devTools) {
      this.__devTools.send('setContractFilter', nextState);
    }
    this.setStateSync(nextState);
  }
  setBuildingText(value: string) {
    const nextState = immer.produce(this.state, (draft: IAdminState) =>
      new RAdminState(draft).setBuildingText(value)
    );
    if (this.__devTools) {
      this.__devTools.send('setBuildingText', nextState);
    }
    this.setStateSync(nextState);
  }
  setRoomText(value: string) {
    const nextState = immer.produce(this.state, (draft: IAdminState) =>
      new RAdminState(draft).setRoomText(value)
    );
    if (this.__devTools) {
      this.__devTools.send('setRoomText', nextState);
    }
    this.setStateSync(nextState);
  }
  setFloorText(value: string) {
    const nextState = immer.produce(this.state, (draft: IAdminState) =>
      new RAdminState(draft).setFloorText(value)
    );
    if (this.__devTools) {
      this.__devTools.send('setFloorText', nextState);
    }
    this.setStateSync(nextState);
  }
  setContractText(value: string) {
    const nextState = immer.produce(this.state, (draft: IAdminState) =>
      new RAdminState(draft).setContractText(value)
    );
    if (this.__devTools) {
      this.__devTools.send('setContractText', nextState);
    }
    this.setStateSync(nextState);
  }
  setItemText(value: string) {
    const nextState = immer.produce(this.state, (draft: IAdminState) =>
      new RAdminState(draft).setItemText(value)
    );
    if (this.__devTools) {
      this.__devTools.send('setItemText', nextState);
    }
    this.setStateSync(nextState);
  }
  async getMetadata() {
    return new RAdminState(
      undefined,
      (action: any) => {
        const nextState = AdminStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ AdminState: this.lastSetState })
    ).getMetadata();
  }
  saveDocument(doc: Model.UIDocument) {
    const nextState = immer.produce(this.state, (draft: IAdminState) =>
      new RAdminState(draft).saveDocument(doc)
    );
    if (this.__devTools) {
      this.__devTools.send('saveDocument', nextState);
    }
    this.setStateSync(nextState);
  }
  setActiveDocument(doc: Model.UIDocument) {
    const nextState = immer.produce(this.state, (draft: IAdminState) =>
      new RAdminState(draft).setActiveDocument(doc)
    );
    if (this.__devTools) {
      this.__devTools.send('setActiveDocument', nextState);
    }
    this.setStateSync(nextState);
  }
  async fetchDocument(id: number) {
    return new RAdminState(
      undefined,
      (action: any) => {
        const nextState = AdminStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ AdminState: this.lastSetState })
    ).fetchDocument(id);
  }
  setSearchResults(list: model.UIDocument[]) {
    const nextState = immer.produce(this.state, (draft: IAdminState) =>
      new RAdminState(draft).setSearchResults(list)
    );
    if (this.__devTools) {
      this.__devTools.send('setSearchResults', nextState);
    }
    this.setStateSync(nextState);
  }
  async documentSearch(params: Model.SearchRequest) {
    return new RAdminState(
      undefined,
      (action: any) => {
        const nextState = AdminStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ AdminState: this.lastSetState })
    ).documentSearch(params);
  }
  initSearch(searches: Model.UserSearches) {
    const nextState = immer.produce(this.state, (draft: IAdminState) =>
      new RAdminState(draft).initSearch(searches)
    );
    if (this.__devTools) {
      this.__devTools.send('initSearch', nextState);
    }
    this.setStateSync(nextState);
  }
  async getUserSearches() {
    return new RAdminState(
      undefined,
      (action: any) => {
        const nextState = AdminStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ AdminState: this.lastSetState })
    ).getUserSearches();
  }
  async fileUpload(options: { file: any; url: string }) {
    return new RAdminState(
      undefined,
      (action: any) => {
        const nextState = AdminStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ AdminState: this.lastSetState })
    ).fileUpload(options);
  }
  setSystemView(name: string) {
    const nextState = immer.produce(this.state, (draft: IAdminState) =>
      new RAdminState(draft).setSystemView(name)
    );
    if (this.__devTools) {
      this.__devTools.send('setSystemView', nextState);
    }
    this.setStateSync(nextState);
  }
  setActiveRole(role: schema.role) {
    const nextState = immer.produce(this.state, (draft: IAdminState) =>
      new RAdminState(draft).setActiveRole(role)
    );
    if (this.__devTools) {
      this.__devTools.send('setActiveRole', nextState);
    }
    this.setStateSync(nextState);
  }
  setUserRolePrivileges(privs: schema.user_role_privilege[]) {
    const nextState = immer.produce(this.state, (draft: IAdminState) =>
      new RAdminState(draft).setUserRolePrivileges(privs)
    );
    if (this.__devTools) {
      this.__devTools.send('setUserRolePrivileges', nextState);
    }
    this.setStateSync(nextState);
  }
  setRolePrivileges(privs: secmodel.SpecificPrivilege[]) {
    const nextState = immer.produce(this.state, (draft: IAdminState) =>
      new RAdminState(draft).setRolePrivileges(privs)
    );
    if (this.__devTools) {
      this.__devTools.send('setRolePrivileges', nextState);
    }
    this.setStateSync(nextState);
  }
  setActiveUserPrivileges(list: schema.role_privilege[]) {
    const nextState = immer.produce(this.state, (draft: IAdminState) =>
      new RAdminState(draft).setActiveUserPrivileges(list)
    );
    if (this.__devTools) {
      this.__devTools.send('setActiveUserPrivileges', nextState);
    }
    this.setStateSync(nextState);
  }
  setActiveUser(user: schema.user | undefined) {
    const nextState = immer.produce(this.state, (draft: IAdminState) =>
      new RAdminState(draft).setActiveUser(user)
    );
    if (this.__devTools) {
      this.__devTools.send('setActiveUser', nextState);
    }
    this.setStateSync(nextState);
  }
  fillAItemCache(items: schema.aitem[]) {
    const nextState = immer.produce(this.state, (draft: IAdminState) =>
      new RAdminState(draft).fillAItemCache(items)
    );
    if (this.__devTools) {
      this.__devTools.send('fillAItemCache', nextState);
    }
    this.setStateSync(nextState);
  }
  async getAItems(list: number[]) {
    return new RAdminState(
      undefined,
      (action: any) => {
        const nextState = AdminStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ AdminState: this.lastSetState })
    ).getAItems(list);
  }
  removePrivilege(priv: schema.user_role_privilege) {
    const nextState = immer.produce(this.state, (draft: IAdminState) =>
      new RAdminState(draft).removePrivilege(priv)
    );
    if (this.__devTools) {
      this.__devTools.send('removePrivilege', nextState);
    }
    this.setStateSync(nextState);
  }
  async removeUserPrivilege(priv: schema.user_role_privilege) {
    return new RAdminState(
      undefined,
      (action: any) => {
        const nextState = AdminStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ AdminState: this.lastSetState })
    ).removeUserPrivilege(priv);
  }
  async addPrivilegeToUser(priv: secmodel.GrantPrivilegeToUser) {
    return new RAdminState(
      undefined,
      (action: any) => {
        const nextState = AdminStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ AdminState: this.lastSetState })
    ).addPrivilegeToUser(priv);
  }
  fillPItemCache(items: schema.pitem[]) {
    const nextState = immer.produce(this.state, (draft: IAdminState) =>
      new RAdminState(draft).fillPItemCache(items)
    );
    if (this.__devTools) {
      this.__devTools.send('fillPItemCache', nextState);
    }
    this.setStateSync(nextState);
  }
  async getPItems(list: number[]) {
    return new RAdminState(
      undefined,
      (action: any) => {
        const nextState = AdminStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ AdminState: this.lastSetState })
    ).getPItems(list);
  }
  async selectUser(user: schema.user) {
    return new RAdminState(
      undefined,
      (action: any) => {
        const nextState = AdminStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ AdminState: this.lastSetState })
    ).selectUser(user);
  }
  async newUser() {
    return new RAdminState(
      undefined,
      (action: any) => {
        const nextState = AdminStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ AdminState: this.lastSetState })
    ).newUser();
  }
  async createNewUser(user: model.NewUserFields) {
    return new RAdminState(
      undefined,
      (action: any) => {
        const nextState = AdminStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ AdminState: this.lastSetState })
    ).createNewUser(user);
  }
  async createNewUserFromLdap(user: model.LDAPUser) {
    return new RAdminState(
      undefined,
      (action: any) => {
        const nextState = AdminStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ AdminState: this.lastSetState })
    ).createNewUserFromLdap(user);
  }
  async sendPasswordResetLinkAdmin(params: PasswordResetParams) {
    return new RAdminState(
      undefined,
      (action: any) => {
        const nextState = AdminStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ AdminState: this.lastSetState })
    ).sendPasswordResetLinkAdmin(params);
  }
  async loadRolePrivileges(id: number) {
    return new RAdminState(
      undefined,
      (action: any) => {
        const nextState = AdminStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ AdminState: this.lastSetState })
    ).loadRolePrivileges(id);
  }
  setActivePrivilege(privilege: schema.privilege) {
    const nextState = immer.produce(this.state, (draft: IAdminState) =>
      new RAdminState(draft).setActivePrivilege(privilege)
    );
    if (this.__devTools) {
      this.__devTools.send('setActivePrivilege', nextState);
    }
    this.setStateSync(nextState);
  }
  setSelectedItem(id: number) {
    const nextState = immer.produce(this.state, (draft: IAdminState) =>
      new RAdminState(draft).setSelectedItem(id)
    );
    if (this.__devTools) {
      this.__devTools.send('setSelectedItem', nextState);
    }
    this.setStateSync(nextState);
  }
  unsetSelectedItem(id: number) {
    const nextState = immer.produce(this.state, (draft: IAdminState) =>
      new RAdminState(draft).unsetSelectedItem(id)
    );
    if (this.__devTools) {
      this.__devTools.send('unsetSelectedItem', nextState);
    }
    this.setStateSync(nextState);
  }
  setActiveItem(id: number) {
    const nextState = immer.produce(this.state, (draft: IAdminState) =>
      new RAdminState(draft).setActiveItem(id)
    );
    if (this.__devTools) {
      this.__devTools.send('setActiveItem', nextState);
    }
    this.setStateSync(nextState);
  }
  selectFictureTable(name: FixtureKeys) {
    const nextState = immer.produce(this.state, (draft: IAdminState) =>
      new RAdminState(draft).selectFictureTable(name)
    );
    if (this.__devTools) {
      this.__devTools.send('selectFictureTable', nextState);
    }
    this.setStateSync(nextState);
  }
  setActiveContract(id: number) {
    const nextState = immer.produce(this.state, (draft: IAdminState) =>
      new RAdminState(draft).setActiveContract(id)
    );
    if (this.__devTools) {
      this.__devTools.send('setActiveContract', nextState);
    }
    this.setStateSync(nextState);
  }
  updateLocalFixture(value: Fixture | undefined) {
    const nextState = immer.produce(this.state, (draft: IAdminState) =>
      new RAdminState(draft).updateLocalFixture(value)
    );
    if (this.__devTools) {
      this.__devTools.send('updateLocalFixture', nextState);
    }
    this.setStateSync(nextState);
  }
  async loadSettings() {
    return new RAdminState(
      undefined,
      (action: any) => {
        const nextState = AdminStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ AdminState: this.lastSetState })
    ).loadSettings();
  }
  async loadFixture() {
    return new RAdminState(
      undefined,
      (action: any) => {
        const nextState = AdminStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ AdminState: this.lastSetState })
    ).loadFixture();
  }
  async initDBWithFixture() {
    return new RAdminState(
      undefined,
      (action: any) => {
        const nextState = AdminStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ AdminState: this.lastSetState })
    ).initDBWithFixture();
  }
  setView(name: string) {
    const nextState = immer.produce(this.state, (draft: IAdminState) =>
      new RAdminState(draft).setView(name)
    );
    if (this.__devTools) {
      this.__devTools.send('setView', nextState);
    }
    this.setStateSync(nextState);
  }
  async initializeDB() {
    return new RAdminState(
      undefined,
      (action: any) => {
        const nextState = AdminStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ AdminState: this.lastSetState })
    ).initializeDB();
  }
  async getUsers() {
    return new RAdminState(
      undefined,
      (action: any) => {
        const nextState = AdminStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ AdminState: this.lastSetState })
    ).getUsers();
  }
  async getAllUsers(onlyDBUsers?: boolean) {
    return new RAdminState(
      undefined,
      (action: any) => {
        const nextState = AdminStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ AdminState: this.lastSetState })
    ).getAllUsers(onlyDBUsers);
  }
  setAllUsers(users: model.UsersTableRow[]) {
    const nextState = immer.produce(this.state, (draft: IAdminState) =>
      new RAdminState(draft).setAllUsers(users)
    );
    if (this.__devTools) {
      this.__devTools.send('setAllUsers', nextState);
    }
    this.setStateSync(nextState);
  }
  async getItems() {
    return new RAdminState(
      undefined,
      (action: any) => {
        const nextState = AdminStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ AdminState: this.lastSetState })
    ).getItems();
  }
  addChildContracts(params: { parentId: number; children: schema.aitem[] }) {
    const nextState = immer.produce(this.state, (draft: IAdminState) =>
      new RAdminState(draft).addChildContracts(params)
    );
    if (this.__devTools) {
      this.__devTools.send('addChildContracts', nextState);
    }
    this.setStateSync(nextState);
  }
  async getUserPrivileges(forID: number) {
    return new RAdminState(
      undefined,
      (action: any) => {
        const nextState = AdminStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ AdminState: this.lastSetState })
    ).getUserPrivileges(forID);
  }
  async getContracts() {
    return new RAdminState(
      undefined,
      (action: any) => {
        const nextState = AdminStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ AdminState: this.lastSetState })
    ).getContracts();
  }
  async getAllContracts() {
    return new RAdminState(
      undefined,
      (action: any) => {
        const nextState = AdminStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ AdminState: this.lastSetState })
    ).getAllContracts();
  }
  async removeContract(id: number) {
    return new RAdminState(
      undefined,
      (action: any) => {
        const nextState = AdminStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ AdminState: this.lastSetState })
    ).removeContract(id);
  }
  async getAvailableRoles() {
    return new RAdminState(
      undefined,
      (action: any) => {
        const nextState = AdminStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ AdminState: this.lastSetState })
    ).getAvailableRoles();
  }
  setRoles(roles: Model.APRole[]) {
    const nextState = immer.produce(this.state, (draft: IAdminState) =>
      new RAdminState(draft).setRoles(roles)
    );
    if (this.__devTools) {
      this.__devTools.send('setRoles', nextState);
    }
    this.setStateSync(nextState);
  }
  async getAvailableActions() {
    return new RAdminState(
      undefined,
      (action: any) => {
        const nextState = AdminStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ AdminState: this.lastSetState })
    ).getAvailableActions();
  }
  setActions(actions: schema.action[]) {
    const nextState = immer.produce(this.state, (draft: IAdminState) =>
      new RAdminState(draft).setActions(actions)
    );
    if (this.__devTools) {
      this.__devTools.send('setActions', nextState);
    }
    this.setStateSync(nextState);
  }
  async getChildContracts(forID: number) {
    return new RAdminState(
      undefined,
      (action: any) => {
        const nextState = AdminStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ AdminState: this.lastSetState })
    ).getChildContracts(forID);
  }
  async refreshContracts() {
    return new RAdminState(
      undefined,
      (action: any) => {
        const nextState = AdminStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ AdminState: this.lastSetState })
    ).refreshContracts();
  }
  async getRootContractNodes() {
    return new RAdminState(
      undefined,
      (action: any) => {
        const nextState = AdminStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ AdminState: this.lastSetState })
    ).getRootContractNodes();
  }
  async uploadAdminDocumentCollection(files: File[]) {
    return new RAdminState(
      undefined,
      (action: any) => {
        const nextState = AdminStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ AdminState: this.lastSetState })
    ).uploadAdminDocumentCollection(files);
  }
  async uploadNewDocuments(files: File[]) {
    return new RAdminState(
      undefined,
      (action: any) => {
        const nextState = AdminStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ AdminState: this.lastSetState })
    ).uploadNewDocuments(files);
  }
  async loadDocumentCategories() {
    return new RAdminState(
      undefined,
      (action: any) => {
        const nextState = AdminStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ AdminState: this.lastSetState })
    ).loadDocumentCategories();
  }
  async getAllBuildings() {
    return new RAdminState(
      undefined,
      (action: any) => {
        const nextState = AdminStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ AdminState: this.lastSetState })
    ).getAllBuildings();
  }
  setAllBuildings(buildings: schema.pitem[]) {
    const nextState = immer.produce(this.state, (draft: IAdminState) =>
      new RAdminState(draft).setAllBuildings(buildings)
    );
    if (this.__devTools) {
      this.__devTools.send('setAllBuildings', nextState);
    }
    this.setStateSync(nextState);
  }
  async createNewPhase(params: { phaseName: string; longName: string }) {
    return new RAdminState(
      undefined,
      (action: any) => {
        const nextState = AdminStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ AdminState: this.lastSetState })
    ).createNewPhase(params);
  }
  async createNewContract(params: {
    phaseId: number;
    contractName: string;
    longName: string;
  }) {
    return new RAdminState(
      undefined,
      (action: any) => {
        const nextState = AdminStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ AdminState: this.lastSetState })
    ).createNewContract(params);
  }
  async createNewSystem(params: {
    parent_system_id: number | undefined;
    system_name: string;
    description: string;
  }) {
    return new RAdminState(
      undefined,
      (action: any) => {
        const nextState = AdminStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ AdminState: this.lastSetState })
    ).createNewSystem(params);
  }
  public render() {
    return (
      <AdminStateContext.Provider
        value={{
          ...this.state,
          AdminStateDispatcher: this.AdminStateDispatcher,
          setStatus: this.setStatus,
          setSuccess: this.setSuccess,
          setError: this.setError,
          resetAdminSnacks: this.resetAdminSnacks,
          setTestResults: this.setTestResults,
          setSelectedTestResult: this.setSelectedTestResult,
          getTestResults: this.getTestResults,
          runOnlyTestset: this.runOnlyTestset,
          runTestResults: this.runTestResults,
          setFreeText: this.setFreeText,
          setContractFilter: this.setContractFilter,
          setBuildingText: this.setBuildingText,
          setRoomText: this.setRoomText,
          setFloorText: this.setFloorText,
          setContractText: this.setContractText,
          setItemText: this.setItemText,
          getMetadata: this.getMetadata,
          saveDocument: this.saveDocument,
          setActiveDocument: this.setActiveDocument,
          fetchDocument: this.fetchDocument,
          setSearchResults: this.setSearchResults,
          documentSearch: this.documentSearch,
          initSearch: this.initSearch,
          getUserSearches: this.getUserSearches,
          fileUpload: this.fileUpload,
          setSystemView: this.setSystemView,
          setActiveRole: this.setActiveRole,
          setUserRolePrivileges: this.setUserRolePrivileges,
          setRolePrivileges: this.setRolePrivileges,
          setActiveUserPrivileges: this.setActiveUserPrivileges,
          setActiveUser: this.setActiveUser,
          fillAItemCache: this.fillAItemCache,
          getAItems: this.getAItems,
          removePrivilege: this.removePrivilege,
          removeUserPrivilege: this.removeUserPrivilege,
          addPrivilegeToUser: this.addPrivilegeToUser,
          fillPItemCache: this.fillPItemCache,
          getPItems: this.getPItems,
          selectUser: this.selectUser,
          newUser: this.newUser,
          createNewUser: this.createNewUser,
          createNewUserFromLdap: this.createNewUserFromLdap,
          sendPasswordResetLinkAdmin: this.sendPasswordResetLinkAdmin,
          loadRolePrivileges: this.loadRolePrivileges,
          setActivePrivilege: this.setActivePrivilege,
          setSelectedItem: this.setSelectedItem,
          unsetSelectedItem: this.unsetSelectedItem,
          setActiveItem: this.setActiveItem,
          selectFictureTable: this.selectFictureTable,
          setActiveContract: this.setActiveContract,
          updateLocalFixture: this.updateLocalFixture,
          loadSettings: this.loadSettings,
          loadFixture: this.loadFixture,
          initDBWithFixture: this.initDBWithFixture,
          setView: this.setView,
          initializeDB: this.initializeDB,
          getUsers: this.getUsers,
          getAllUsers: this.getAllUsers,
          setAllUsers: this.setAllUsers,
          getItems: this.getItems,
          addChildContracts: this.addChildContracts,
          getUserPrivileges: this.getUserPrivileges,
          getContracts: this.getContracts,
          getAllContracts: this.getAllContracts,
          removeContract: this.removeContract,
          getAvailableRoles: this.getAvailableRoles,
          setRoles: this.setRoles,
          getAvailableActions: this.getAvailableActions,
          setActions: this.setActions,
          getChildContracts: this.getChildContracts,
          refreshContracts: this.refreshContracts,
          getRootContractNodes: this.getRootContractNodes,
          uploadAdminDocumentCollection: this.uploadAdminDocumentCollection,
          uploadNewDocuments: this.uploadNewDocuments,
          loadDocumentCategories: this.loadDocumentCategories,
          getAllBuildings: this.getAllBuildings,
          setAllBuildings: this.setAllBuildings,
          createNewPhase: this.createNewPhase,
          createNewContract: this.createNewContract,
          createNewSystem: this.createNewSystem,
          listedUsers: this.__selectorlistedUsers(this.state),
        }}
      >
        {' '}
        {this.props.children}
      </AdminStateContext.Provider>
    );
  }
}
