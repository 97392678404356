import * as React from 'react';
import Collapse from '@material-ui/core/Collapse';
import Checkbox from '@material-ui/core/Checkbox';
import { Fragment } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

export interface Keyable<T> {
  id?: number;
  children?: T[];
}
export interface Action {
  name: string;
  fn: (id: number) => void;
}

export interface GenericTableProps<T extends Keyable<T>, K extends keyof T> {
  list: T[];
  keys: K[];
  title: string;
  activate?: (id: number) => void;
  loadChildren?: (id: number) => void;
  checkItem?: (id: number) => void;
  actions?: Action[];
}
export class GenericTable<
  T extends Keyable<T>,
  K extends keyof T
> extends React.PureComponent<GenericTableProps<T, K>> {
  render() {
    // subheader={<ListSubheader component="div">Items</ListSubheader>}
    return (
      <List component="nav">
        {this.props.list.map(item => {
          const children = item.children ? item.children : [];
          return (
            <Fragment key={item.id}>
              <ListItem
                key={item.id}
                onClick={_ => {
                  if (item.id) {
                    if (this.props.activate) {
                      this.props.activate(item.id);
                    }
                    if (this.props.loadChildren) {
                      this.props.loadChildren(item.id);
                    }
                  }
                }}
              >
                <Checkbox
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    console.log(e.target.checked);
                  }}
                />
                <ListItemText inset={true} primary={item[this.props.title]} />
              </ListItem>
              <Collapse
                in={children.length > 0}
                timeout="auto"
                unmountOnExit={true}
              >
                <div style={{ marginLeft: 20 }}>
                  <GenericTable
                    list={children}
                    title={this.props.title}
                    keys={this.props.keys}
                    activate={this.props.activate}
                    loadChildren={this.props.loadChildren}
                    actions={this.props.actions}
                  />
                </div>
              </Collapse>
            </Fragment>
          );
        })}
      </List>
    );
  }
}
