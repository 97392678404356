import * as React from 'react';
import { FormattedMessage, injectIntl, InjectedIntlProps } from 'react-intl';
import axios from 'axios';
import Button from '../Common/Button';
import { Item } from '../Common/AutosuggestChipSelection';
import ReactSelect from 'react-select';
import { Async } from '../Common/Async';
import { DocumentService } from '../../services/document/src/frontend/api';
import * as Model from '../../../../shared/src/model/index';

import * as container from '../../state/reducers/DocumentState';
import * as appstate from '../../state/reducers/ApplicationState';
import { LinearProgress } from '@material-ui/core';
import { ValueType } from 'react-select/lib/types';

const DOC = DocumentService(axios);

interface IPickDWGPropsProps {
  contractId?: number;
  onCancel?: () => void;
  onSelect?: (name: string) => void;
}

interface IPickDWGPropsState {
  selectedDwgs: Item[];
}

type IMyProps = IPickDWGPropsProps &
  InjectedIntlProps &
  container.IProps &
  appstate.IProps;
export class PickDWG extends React.Component<IMyProps, IPickDWGPropsState> {
  constructor(props: IMyProps) {
    super(props);
    this.state = {
      selectedDwgs: [],
    };
  }

  translate = (id: string) => {
    return this.props.intl.formatMessage({ id });
  };

  render() {
    if (this.props.contractId === 0) {
      return <div />;
    }

    return (
      <div className="pickDWG">
        <Async
          placeholder={
            <div>
              <LinearProgress />
            </div>
          }
          loader={() =>
            DOC.documentSearchRequest({
              contractFilter: [this.props.contractId || 0].filter((n) => n),
              fileTypes: ['dwg'],
            })
          }
          render={(data) => {
            if (!data) {
              return <div />;
            }
            return (
              <>
                <ReactSelect
                  isMulti={false}
                  placeholder={'Valitse DWG tiedosto'}
                  options={data.rows.map((item) => {
                    return {
                      label: item.fileName,
                      value: item.id,
                    };
                  })}
                  onChange={(values: ValueType<Model.Item>) => {
                    if (values && !Array.isArray(values)) {
                      this.setState({
                        selectedDwgs: [values as Model.Item],
                      });
                    }
                    // this.onSelectDocumentCategory(values as Model.Item[]);
                  }}
                />
                <div className="editActions">
                  <Button
                    onClick={() => {
                      if (this.props.onCancel) this.props.onCancel();
                    }}
                  >
                    <FormattedMessage id="buttons.cancel" />
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    onClick={(e) => {
                      if (this.props.onSelect)
                        this.props.onSelect(
                          this.state.selectedDwgs
                            .map((item) => item.label)
                            .join('') || ''
                        );
                    }}
                  >
                    <FormattedMessage id="buttons.save" />
                  </Button>
                </div>
              </>
            );
          }}
        />
      </div>
    );
  }
}

const PickDWGContainer = appstate.StateConnector(
  container.StateConnector(PickDWG)
);

export default injectIntl(PickDWGContainer);
