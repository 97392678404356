import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
} from '@material-ui/core';
import axios from 'axios';
import * as React from 'react';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { Redirect } from 'react-router-dom';
import ReactSelect from 'react-select';
import { ContractRole, UIDocument } from '../../../../shared/src/model/index';
import * as schema from '../../../../shared/src/model/projectSchema';
import MainLayout from '../../layout/MainLayout';
import { APIService } from '../../services/api/src/frontend/api';
import { DocumentService } from '../../services/document/src/frontend/api';
import * as applicationState from '../../state/reducers/ApplicationState';
import * as documentState from '../../state/reducers/DocumentState';
import { setBackgroundImage } from '../../utils';
import {
  createNewUIDocument,
  getIdForCategoryName as categoryId,
} from '../../utils/metadata';
import { Item } from '../Common/AutosuggestChipSelection';
import Button from '../Common/Button';
import Help from '../Common/Help';
import { errorMessage, successMessage } from '../Common/Notifier';
import Filters, { FilterRoles } from './filters';
import './inspectionDocuments.scss';
import TargetAreas from './targetAreas';
import { push } from 'connected-react-router';
import { ValueType } from 'react-select/lib/types';

const API = APIService(axios);
const DOC = DocumentService(axios);

interface BuildingPermit {
  aitem_id: number;
  id: number;
  name: string;
}

interface IInspectionDocumentsProps
  extends applicationState.IProps,
    documentState.IProps {
  match: {
    params: {
      contract: number;
      inspectionDocument: number;
    };
  };
}

interface IInspectionDocumentsState {
  selectedPhase?: Item;
  selectedContract?: Item;
  contractRoles?: ContractRole;
  contractBuildingPermits?: BuildingPermit[];
  inspectionDocuments: UIDocument[];
  targetAreas: UIDocument[];
  targets: UIDocument[];
  activeFilters: string[];
  showFavourites: boolean;
  filterTerm: string;
  redirectToAdmin: boolean;
  isViewingBuildingPermits: boolean;
  favourites: number[];
  favouritesVersion: number;
  workflows: schema.workflow[];
  isLoading: boolean;

  isAdmin?: boolean;
  isContractor?: boolean;
  isDesigner?: boolean;
  isInspector?: boolean;
  isAuthority?: boolean;
}

type InspectionDocumentsProps = IInspectionDocumentsProps & InjectedIntlProps;

class AbstractInspectionDocuments extends React.Component<
  InspectionDocumentsProps,
  IInspectionDocumentsState
> {
  constructor(props: InspectionDocumentsProps) {
    super(props);

    this.state = {
      inspectionDocuments: [],
      targetAreas: [],
      targets: [],
      activeFilters: [],
      filterTerm: '',
      redirectToAdmin: false,
      isViewingBuildingPermits: false,
      favourites: [],
      favouritesVersion: 0,
      showFavourites: false,
      workflows: [],
      isLoading: false,
    };
  }

  componentDidMount = async () => {
    setBackgroundImage();

    try {
      const favourites = await API.getUserFavorites();

      if (!favourites.favorites) {
        favourites.favorites = [];
      }

      this.setState({
        favourites: favourites.favorites,
        favouritesVersion: favourites.version,
      });
    } catch (e) {
      errorMessage(e);
    }

    try {
      const workflows = await API.getWorkflows();
      this.setState({ workflows });
    } catch (e) {
      errorMessage(e);
    }

    const { contract, inspectionDocument } = this.props.match.params;

    if (contract) {
      this.setRolesForContract(Number(contract));
      this.getInspectionDocuments(Number(contract));
      this.getBuildingPermits(Number(contract));
      this.setState({
        selectedContract: {
          value: contract,
          label: '',
        },
      });
    }

    if (inspectionDocument) {
      this.getInspectionDocument(Number(inspectionDocument));
    }
  };

  onChangePhase(selection: Item) {
    this.setState({ selectedPhase: selection });
  }

  async setRolesForContract(contractId: number) {
    const contractRoles = await API.getUserRoles([contractId]);

    const roles = contractRoles[0];
    const isInRole = (name: string) =>
      roles.roles.filter((role) => role.role_name === name).length > 0;
    this.setState({
      contractRoles: roles,
      isAdmin: isInRole('TA_ADMIN'),
      isContractor: isInRole('TA_URAKOITSIJA'),
      isDesigner: isInRole('TA_SUUNNITTELIJA'),
      isInspector: isInRole('TA_TARKASTAJA'),
      isAuthority: isInRole('TA_KATSELIJA'),
    });
  }

  setContract(contractId: number) {
    this.props.ApplicationStateDispatcher(
      push(`/inspection-documents/contracts/${contractId}`)
    );
  }

  setInspectionDocument(contractId: number, inspectionDocumentId: number) {
    this.props.ApplicationStateDispatcher(
      push(
        `/inspection-documents/contracts/${contractId}/${inspectionDocumentId}`
      )
    );
  }

  async getBuildingPermits(selectedContractId: number) {
    try {
      const contractBuildingPermits = await DOC.getBuildingPermits(
        selectedContractId
      );

      this.setState({
        contractBuildingPermits,
      });
    } catch (e) {
      errorMessage(e);
    }
  }

  async getInspectionDocuments(selectedContractId: number) {
    try {
      const unsortedInspectionDocuments = await DOC.documentSearchRequest({
        documentCategories: [await categoryId('TARKASTUSASIAKIRJA')],
        contractFilter: [selectedContractId],
      });

      // localSearchQuery returns documents in order of last update
      const inspectionDocuments = unsortedInspectionDocuments.rows.sort(
        (a, b) => a.name.localeCompare(b.name, 'fi')
      );

      this.setState({
        inspectionDocuments,
        targetAreas: [],
      });
    } catch (e) {
      errorMessage(e);
    }
  }

  onChangeContract = async (selectedContract: Item) => {
    this.setState({ selectedContract });

    this.setRolesForContract(selectedContract.value);
    this.getInspectionDocuments(selectedContract.value);
    this.getBuildingPermits(selectedContract.value);
    this.setContract(selectedContract.value);
  };

  // Helper function for building usable hierarchy from target areas and targets
  buildTargetAreaHierarchy = async (areasAndTargets: UIDocument[]) => {
    const areaId = await categoryId('TARKASTUSASIAKIRJA_ALUE');
    const targetId = await categoryId('TARKASTUSASIAKIRJA_KOHDE');

    const targetAreas = areasAndTargets
      .filter((targetArea: UIDocument) => {
        return targetArea.categories
          ? targetArea.categories.indexOf(areaId) > -1
          : false;
      })
      .sort((a, b) => {
        if (!a.ordinal || !b.ordinal) {
          return 0;
        }
        return a.ordinal - b.ordinal;
      });

    const targets = areasAndTargets.filter((targetArea: UIDocument) => {
      return targetArea.categories
        ? targetArea.categories.indexOf(targetId) > -1
        : false;
    });

    return targetAreas.map((area) => {
      area.children = targets
        .filter((target) => target.parentId === area.id)
        .sort((a, b) => {
          if (!a.ordinal || !b.ordinal) {
            return 0;
          }
          return a.ordinal - b.ordinal;
        });
      return area;
    });
  };

  checkForRole = (roleToBeChecked: string) => {
    const { contractRoles } = this.state;

    return (
      contractRoles &&
      contractRoles.roles.some((role) => role.role_name === roleToBeChecked)
    );
  };

  /*
    id|role_name                        |
    --|---------------------------------|
    11|TA_SUUNNITTELIJA                 |
    12|TA_TARKASTAJA                    |
    13|TA_URAKOITSIJA                   |
    14|TA_ADMIN                         |
    15|TA_KATSELIJA                     |
  */
  /*
    id|name          |description                       |
    --|--------------|----------------------------------|
    1 |INIT          |Asiakirja on luotu ilman liitteitä|
    2 |HAS_DOC       |Liitteet on lisätty               |
    3 |SUBMITTED     |Odottaa tarkastusta               |
    4 |INITIAL_ACCEPT|Odottaa hyväksyntää               |
    5 |ACCEPTED      |Hyväksytty                        |
    6 |REJECTED      |Hylätty                           |
    7 |ALL           |ALL                               |
    8 |READY         |READY                             |
  */

  matchStatusToColor = (target: UIDocument) => {
    const testForState = (state: number) =>
      target.childStates &&
      target.childStates.some((childState) => childState === state);
    const hasUreadComments =
      target.comments &&
      target.comments.some((comment) => comment.seen_at === null);

    const {
      isAdmin,
      isContractor,
      isDesigner,
      isInspector,
      isAuthority,
    } = this.state;

    if (isInspector || isDesigner || isAdmin) {
      if (target.childStates && target.childStates.length === 0) {
        return 'blue';
      }

      if (hasUreadComments) {
        return 'blue';
      }

      if (target.hasMissingAttachments) {
        return 'blue';
      }

      switch (true) {
        case testForState(2):
        case testForState(3):
        case testForState(4):
          return 'yellow';
        case testForState(1):
        case testForState(6):
          return 'blue';
        case testForState(5):
          return 'green';
        default:
          return '';
      }
    }

    if (isContractor) {
      if (hasUreadComments) {
        return 'red';
      }

      if (target.hasMissingAttachments) {
        return 'red';
      }

      if (target.childStates && target.childStates.length === 0) {
        return 'yellow';
      }

      switch (true) {
        case testForState(2):
        case testForState(3):
        case testForState(4):
          return 'yellow';
        case testForState(1):
          return 'blue';
        case testForState(5):
          return 'green';
        default:
          return '';
      }
    }

    if (isAuthority) {
      return 'blue';
    }

    return '';
  };

  // Get the most significant one from the child state colors
  getTargetAreaColor = (targets: UIDocument[]) => {
    const targetColors = targets.map((target) => {
      return target.uiMeta ? target.uiMeta.stateColor : '';
    });

    switch (true) {
      case targetColors.indexOf('red') !== -1:
        return 'red';
      case targetColors.indexOf('yellow') !== -1:
        return 'yellow';
      case targetColors.indexOf('blue') !== -1:
        return 'blue';
      case targetColors.indexOf('green') !== -1:
        return 'green';

      default:
        return '';
    }
  };

  setStateColors = (targetAreas: UIDocument[]) => {
    const isContractor = this.checkForRole('TA_URAKOITSIJA');

    // Loop targetAreas
    return targetAreas.map((targetArea: UIDocument) => {
      // Loop targets and set the state color
      if (targetArea.children && targetArea.children.length > 0) {
        targetArea.children.map((target) => {
          target.uiMeta = {
            stateColor: this.matchStatusToColor(target),
          };
          return target;
        });

        // Get and set targetArea color
        targetArea.uiMeta = {
          stateColor: this.getTargetAreaColor(targetArea.children),
        };
      } else {
        // If there's no children
        if (isContractor) {
          targetArea.uiMeta = {
            stateColor: 'yellow',
          };
        } else {
          targetArea.uiMeta = {
            stateColor: 'blue',
          };
        }
      }

      return targetArea;
    });
  };

  async getInspectionDocument(selectedInpectionDocumentId: number) {
    try {
      this.setState({ isLoading: true });
      const areasAndTargets = await DOC.documentSearchRequest({
        parentDocumentIds: [selectedInpectionDocumentId],
        documentCategories: [
          await categoryId('TARKASTUSASIAKIRJA_ALUE'),
          await categoryId('TARKASTUSASIAKIRJA_KOHDE'),
        ],
        additionalInfo: {
          childStates: true,
          hasMissingAttachments: true,
          // comments: true
        },
        cursor: {
          offset: 0,
          limit: 10000, // arbitary max limit for this request
        },
      });

      const targetAreas = await this.buildTargetAreaHierarchy(
        areasAndTargets.rows
      );
      const targetAreas2 = await this.setStateColors(targetAreas);

      this.setState({
        targetAreas: targetAreas2,
        isLoading: false,
      });
    } catch (e) {
      errorMessage(e);
      this.setState({ isLoading: false });
    }
  }

  onChangeInspectionDocument = async (selectedInpectionDocument: Item) => {
    this.getInspectionDocument(selectedInpectionDocument.value);
    this.setInspectionDocument(
      this.props.match.params.contract,
      selectedInpectionDocument.value
    );
  };

  onFilterChange = (activeFilters: string[]) => {
    this.setState({ activeFilters });
  };

  onFilterTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ filterTerm: e.currentTarget.value.toLowerCase().trim() });
  };

  setRedirectToAdmin = () => {
    this.setState({
      redirectToAdmin: true,
    });
  };

  onTargetAreaNameChange = async (
    targetAreaName: string,
    targetArea: UIDocument
  ) => {
    const updatedTargetArea = JSON.parse(JSON.stringify(targetArea));
    updatedTargetArea.name = targetAreaName;

    try {
      await DOC.updateDocument(updatedTargetArea);
    } catch (e) {
      errorMessage(e);
    }
  };

  onTargetNameChange = async (targetName: string, target: UIDocument) => {
    try {
      const targetAreas = [...this.state.targetAreas];
      const targetArea = targetAreas.filter(
        (area) => area.id === target.parentId
      )[0];
      const targets = targetArea.children || [];

      target.name = targetName;
      const updatedTarget: UIDocument = await DOC.updateDocument(target);

      targets.map((oldTarget) => {
        return target.id !== updatedTarget.id ? oldTarget : updatedTarget;
      });

      this.setState({ targetAreas });
    } catch (e) {
      errorMessage(e);
    }
  };

  onOrdinalChange = async (docIds: number[]) => {
    try {
      await DOC.reorderDocuments(docIds);
    } catch (error) {
      errorMessage(error.message);
    }
  };

  newTargetArea = async (): Promise<UIDocument> => {
    const t = (id: string) => this.props.intl.formatMessage({ id });
    const { selectedContract, targetAreas } = this.state;
    const contracts = selectedContract
      ? [
          {
            id: selectedContract.value,
            name: selectedContract.label,
          },
        ]
      : [];

    return createNewUIDocument({
      contracts,
      categoryIds: [await categoryId('TARKASTUSASIAKIRJA_ALUE')],
      parentId: targetAreas[0].parentId,
      name: t('inspectionDocuments.targetArea'),
    });
  };

  onAddNewTargetArea = async (index: number) => {
    const targetAreas = [...this.state.targetAreas];
    const newTargetArea: UIDocument = await this.newTargetArea();

    try {
      const area: UIDocument[] = await DOC.createAnyDocument(newTargetArea);
      // Insert new target area to target area array
      targetAreas.splice(index + 1, 0, area[0]);

      this.setState({ targetAreas });
    } catch (e) {
      errorMessage(e);
    }
  };

  onDeleteTargetArea = async (targetArea: UIDocument) => {
    const t = (id: string) => this.props.intl.formatMessage({ id });

    if (targetArea.children && targetArea.children.length > 0) {
      errorMessage(t('errorMessage.deleteInspectionAreaWithChildren'));
      return;
    }

    try {
      await DOC.deleteDocument(targetArea.id);

      successMessage(t('inspectionDocuments.targetAreaDeleted'));

      const oldTargetAreas = [...this.state.targetAreas];
      const targetAreas = oldTargetAreas.filter(
        (area) => area.id !== targetArea.id
      );
      this.setState({ targetAreas });
    } catch (e) {
      errorMessage(e);
    }
  };

  newTarget = async (parentId: number): Promise<UIDocument> => {
    const t = (id: string) => this.props.intl.formatMessage({ id });
    const { selectedContract } = this.state;
    const contracts = selectedContract
      ? [
          {
            id: selectedContract.value,
            name: selectedContract.label,
          },
        ]
      : [];

    return createNewUIDocument({
      contracts,
      parentId,
      categoryIds: [await categoryId('TARKASTUSASIAKIRJA_KOHDE')],
      name: t('inspectionDocuments.target'),
    });
  };

  onAddNewTarget = async (index: number, parentId: number) => {
    const targetAreas = [...this.state.targetAreas];
    const targetArea = targetAreas.filter((area) => area.id === parentId)[0];
    const newTarget: UIDocument = await this.newTarget(parentId);

    try {
      const target: UIDocument[] = await DOC.createAnyDocument(newTarget);

      // Insert new target to target array
      targetArea.children
        ? targetArea.children.splice(index + 1, 0, target[0])
        : (targetArea.children = [target[0]]);

      this.setState({ targetAreas });
    } catch (e) {
      errorMessage(e);
    }
  };

  onDeleteTarget = async (targetToBeDeleted: UIDocument) => {
    const t = (id: string) => this.props.intl.formatMessage({ id });

    if (
      targetToBeDeleted.childStates &&
      targetToBeDeleted.childStates.length > 0
    ) {
      errorMessage(t('errorMessage.deleteInspectionTargetWithChildren'));
      return;
    }

    try {
      await DOC.deleteDocument(targetToBeDeleted.id);

      successMessage(t('inspectionDocuments.targetDeleted'));

      const oldTargetAreas = [...this.state.targetAreas];
      const targetAreas = oldTargetAreas.map((area) => {
        if (area.children) {
          area.children = area.children.filter(
            (target) => target.id !== targetToBeDeleted.id
          );
        }
        return area;
      });

      this.setState({ targetAreas });
    } catch (e) {
      errorMessage(e);
    }
  };

  targetFilter(target: UIDocument) {
    const {
      activeFilters,
      favourites,
      showFavourites,
      filterTerm,
    } = this.state;
    return [target]
      .filter((target) => {
        // Check if the current targets state matches with selected filters
        const color = target.uiMeta ? target.uiMeta.stateColor : '';

        return activeFilters.length
          ? activeFilters.indexOf(color) !== -1
          : true;
      })
      .filter((target) => {
        // Show only targets that have been marked as favourites
        return showFavourites ? favourites.indexOf(target.id) !== -1 : true;
      })
      .filter((target) => {
        // NOTE: We are only filtering by the target name here. Expand this functionality
        //  When we have more stuff to filter by
        return target.name.toLowerCase().indexOf(filterTerm) !== -1;
      })
      .pop();
  }

  filteredTargets = (targets: UIDocument[]) => {
    const {
      activeFilters,
      favourites,
      showFavourites,
      filterTerm,
    } = this.state;

    return targets
      .filter((target) => {
        // Check if the current targets state matches with selected filters
        const color = target.uiMeta ? target.uiMeta.stateColor : '';

        return activeFilters.length
          ? activeFilters.indexOf(color) !== -1
          : true;
      })
      .filter((target) => {
        // Show only targets that have been marked as favourites
        return showFavourites ? favourites.indexOf(target.id) !== -1 : true;
      })
      .filter((target) => {
        // NOTE: We are only filtering by the target name here. Expand this functionality
        //  When we have more stuff to filter by
        return target.name.toLowerCase().indexOf(filterTerm) !== -1;
      });
  };

  onDownloadAsSpreadsheet = () => {
    let targets: UIDocument[] = [];
    const hasFilters =
      this.state.activeFilters.length > 0 ||
      this.state.showFavourites ||
      this.state.filterTerm;

    const targetAreas = this.state.targetAreas || [];

    targetAreas.map((targetArea) => {
      if (hasFilters) {
        if (targetArea.children) {
          const includedChildren = targetArea.children.filter((child) => {
            return this.targetFilter(child);
          });
          if (includedChildren.length > 0) {
            targets = targets.concat([targetArea], includedChildren);
          }
        }
      } else {
        if (targetArea.children) {
          targets = targets.concat([targetArea], targetArea.children);
        } else {
          targets.push(targetArea);
        }
      }
    });

    const filteredTargets = targets;

    const query = encodeURIComponent(
      JSON.stringify(filteredTargets.map((target) => target.id))
    );
    if (document && document.location) {
      document.location.href = `/docs/v1/downloadTargetsAsSpreadsheet/?targets=${query}`;
    }
  };

  onSetFavourites = async (id: number) => {
    const favourites = [...this.state.favourites];

    if (favourites.indexOf(id) === -1) {
      favourites.push(id);
    } else {
      favourites.splice(favourites.indexOf(id), 1);
    }

    try {
      await API.saveUserFavorites({
        favorites: favourites,
        version: this.state.favouritesVersion,
      });
      this.setState({ favourites });
    } catch (e) {
      errorMessage(e);
    }
  };

  onShowFavouritesUpdate = (state: boolean) => {
    this.setState({ showFavourites: state });
  };

  public render() {
    const t = (id: string) => this.props.intl.formatMessage({ id });
    const { user } = this.props;
    const { targetAreas, contractBuildingPermits } = this.state;
    const isFiltering =
      !!this.state.filterTerm.trim() ||
      this.state.activeFilters.length > 0 ||
      this.state.showFavourites;

    // const isAdmin = this.checkForRole('TA_ADMIN');
    const isContractor = this.checkForRole('TA_URAKOITSIJA');
    // const isDesigner = this.checkForRole('TA_SUUNNITTELIJA');
    // const isInspector = this.checkForRole('TA_TARKASTAJA');
    // const isAuthority = this.checkForRole('TA_KATSELIJA');

    const phaseOptionsMock = [
      { label: 'Kaikki', value: 0 },
      { label: 'Ensimmäinen', value: 1 },
      { label: 'Toinen', value: 2 },
    ];

    if (this.state.redirectToAdmin) {
      return (
        <Redirect
          to={{ pathname: `/inspection-documents/admin` }}
          push={true}
        />
      );
    }

    const selectedContract = Number(this.props.match.params.contract);
    const selectedInspectionDocument =
      Number(this.props.match.params.inspectionDocument) || null;

    const userContracts = (this.props.contractPermissions
      ? this.props.contractPermissions.readPermissions || []
      : []
    )
      .filter((p) => {
        return p.document_category_name === 'TARKASTUSASIAKIRJA';
      })
      .map((v) => ({
        label: v.aitem_name,
        value: v.aitem_id,
      }));

    const inspectionDocuments = this.state.inspectionDocuments.map(
      (inspectionDocument) => ({
        label: inspectionDocument.name,
        value: inspectionDocument.id,
      })
    );

    return (
      <MainLayout>
        <h1>
          <FormattedMessage id="headers.inspectionDocument" />
        </h1>

        <div className="grid gridCols3">
          <div className="filterBar inspectionDocumentPicker">
            <ReactSelect
              isDisabled={true}
              className="selectComponent"
              isMulti={false}
              placeholder={t('documentMeta.projectPhase')}
              defaultValue={phaseOptionsMock[0]}
              options={phaseOptionsMock}
              value={this.state.selectedPhase}
              onChange={(value: ValueType<Item>) =>
                this.onChangePhase(value as Item)
              }
            />
            <ReactSelect
              className="selectComponent"
              isMulti={false}
              placeholder={t('documentMeta.contract')}
              value={userContracts
                .filter((contract) => {
                  return contract.value === selectedContract;
                })
                .pop()}
              options={userContracts}
              onChange={(value: ValueType<Item>) =>
                this.onChangeContract(value as Item)
              }
            />

            <ReactSelect
              className="selectComponent"
              isMulti={false}
              placeholder={t('headers.inspectionDocument')}
              value={
                inspectionDocuments
                  .filter((inspectionDocument) => {
                    return (
                      inspectionDocument.value === selectedInspectionDocument
                    );
                  })
                  .pop() || null
              }
              noOptionsMessage={() =>
                t('inspectionDocuments.noIspectionDocuments')
              }
              options={inspectionDocuments}
              onChange={(value: ValueType<Item>) =>
                this.onChangeInspectionDocument(value as Item)
              }
            />
          </div>

          <div className="otherActions">
            <Button
              variant="text"
              disabled={
                !contractBuildingPermits || contractBuildingPermits.length === 0
              }
              onClick={() => this.setState({ isViewingBuildingPermits: true })}
            >
              <FormattedMessage id="inspectionDocuments.viewBuildingPermits" />
            </Button>

            {user && user.is_superuser && (
              <Button variant="text" onClick={this.setRedirectToAdmin}>
                <FormattedMessage id="inspectionDocuments.adminView" />
              </Button>
            )}
          </div>
          {this.state.isLoading && <LinearProgress />}
          {targetAreas.length > 0 ? (
            <>
              <Filters
                role={
                  this.state.isContractor
                    ? FilterRoles.Contractor
                    : this.state.isAdmin ||
                      this.state.isDesigner ||
                      this.state.isInspector
                    ? FilterRoles.Inspector
                    : FilterRoles.Authority
                }
                onFilterChange={this.onFilterChange}
                onFilterTermChange={this.onFilterTermChange}
                onShowFavouritesUpdate={this.onShowFavouritesUpdate}
              />
              <TargetAreas
                isContractor={isContractor || false}
                targetAreas={targetAreas}
                activeFilters={this.state.activeFilters}
                filterTerm={this.state.filterTerm}
                isFiltering={isFiltering}
                favourites={this.state.favourites}
                showFavourites={this.state.showFavourites}
                onTargetAreaNameChange={this.onTargetAreaNameChange}
                onTargetNameChange={this.onTargetNameChange}
                onOrdinalChange={this.onOrdinalChange}
                onAddNewTargetArea={this.onAddNewTargetArea}
                onAddNewTarget={this.onAddNewTarget}
                onDeleteTargetArea={this.onDeleteTargetArea}
                onDeleteTarget={this.onDeleteTarget}
                onDownloadAsSpreadsheet={this.onDownloadAsSpreadsheet}
                onSetFavourites={this.onSetFavourites}
                filteredTargets={this.filteredTargets}
                workflows={this.state.workflows}
              />
            </>
          ) : (
            <Help
              purpose="inspectionDocumentTabulaRasa"
              content={'inspectionDocuments.tabulaRasa'}
            />
          )}
        </div>

        {this.state.isViewingBuildingPermits && this.buildingPermitsDialog()}
      </MainLayout>
    );
  }

  buildingPermitsDialog() {
    const { isViewingBuildingPermits, contractBuildingPermits } = this.state;

    return (
      <Dialog
        open={isViewingBuildingPermits}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
      >
        <DialogTitle>
          <FormattedMessage id="inspectionDocuments.buildingPermitsDialogHeader" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText component={'div'}>
            <ul>
              {contractBuildingPermits &&
                contractBuildingPermits.map((permit) => (
                  <li key={permit.id}>{permit.name}</li>
                ))}
            </ul>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div>
            <Button
              onClick={() => this.setState({ isViewingBuildingPermits: false })}
            >
              <FormattedMessage id="buttons.close" />
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    );
  }
}

const InspectionDocuments = applicationState.StateConnector(
  documentState.StateConnector(AbstractInspectionDocuments)
);

export default injectIntl(InspectionDocuments);
