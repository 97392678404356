import * as React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import * as applicationState from '../../state/reducers/ApplicationState';
import { setBackgroundImage } from '../../utils/index';
import AdminTools from './index';
import './adminTools.scss';
import { Async } from '../Common/Async';
import axios from 'axios';
import { DocumentService } from '../../services/document/src/frontend/api';
import { DataTable } from '../Common/DataTable';
import Moment from 'react-moment';
const API = DocumentService(axios);

interface ISyncsProps extends applicationState.IProps {}

interface ISyncsState {}

type SyncsProps = ISyncsProps & InjectedIntlProps;

class AbstractSyncs extends React.Component<SyncsProps, ISyncsState> {
  constructor(props: SyncsProps) {
    super(props);

    this.state = {};
  }

  componentDidMount = async () => {
    setBackgroundImage();
  };

  render() {
    return (
      <AdminTools>
        <Async
          loader={() => API.getSyncErrors()}
          render={data => {
            if (!data) {
              return <div />;
            }
            return (
              <DataTable
                rows={data.reverse()}
                keys={['created_at', 'sequence_id', 'table_name', 'error_info']}
                titles={{
                  sequence_id: 'Sequence ID',
                  table_name: 'Table',
                  error_info: 'Info',
                }}
                render={{
                  created_at: row =>
                    row.created_at ? (
                      <Moment date={row.created_at} format="DD.MM.YYYY HH:mm" />
                    ) : (
                      <span>-</span>
                    ),
                  sequence_id: row => <span>{String(row.sequence_id)}</span>,
                }}
              />
            );
          }}
        />
      </AdminTools>
    );
  }
}

const Syncs = applicationState.StateConnector(AbstractSyncs);

export default injectIntl(Syncs);
