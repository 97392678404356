import * as Model from '../../../../shared/src/model';
import * as React from 'react';

import Button from '../Common/Button';
import { DataTable } from 'components/Common/DataTable';
import { DocumentService } from '../../services/document/src/frontend/api';
import { Link } from 'react-router-dom';
import Loading from '../Common/Loading';
import ReactSelect from 'react-select';
import TextField from '../Common/TextField';
import { ValueType } from 'react-select/lib/types';
import axios from 'axios';
import { useAppContext } from 'utils/AppContextProvider';
import { useUnsafeFormatMessage } from 'utils/translateHook';

// import { debounce } from '../../utils';

const DOC = DocumentService(axios);

interface MainCategory {
  label: string;
  value: number;
  tag: string;
}

const URAK_DOC = 'URAK_DOC';
const TIL_DOC = 'TIL_DOC';

const plainSearchRequest: Model.SearchRequest = {
  name: '',
  cursor: {
    limit: 100,
    offset: 0,
  },
  searchFromFileContents: false,
  freeText: '',
  areaIds: [],
  contractFilter: [],
  itemIds: [],
  documentCategories: [],
  documentTypes: [],
  systems: [],
  startPole: undefined,
  endPole: undefined,
  documentStates: [],
  fileTypes: [],
  pitemTypes: [],
  cols: ['name'],
  searchMainCategory: 0,
  isInspectionDocument: false,
  additionalInfo: {},
};

const ReplacementDocument: React.FC<any> = ({
  activeDocId,
  activeDocCategories,
  replacement,
  setReplacement,
  errorMessage,
}) => {
  const appCtx = useAppContext();
  const t = useUnsafeFormatMessage();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [documents, setDocuments] = React.useState<Model.UIDocument[]>();
  const [selected, setSelected] = React.useState<number>();
  const [mainCategory, setMainCategory] = React.useState<MainCategory>();
  const [freeText, setFreeText] = React.useState<string>('');
  const [docCategories, setDocCategories] = React.useState<Model.Item[]>([]);
  const [contractDocCategories, setContractDocCategories] = React.useState<
    Model.Item[]
  >([]);

  const mainCategories: MainCategory[] = appCtx.appDocumentCategories
    .filter((cat) => {
      if (appCtx.contractPermissions) {
        let hasPermission = false;
        for (const rp of appCtx.contractPermissions.readPermissions) {
          if (rp.document_category_name.localeCompare(cat.name) === 0) {
            hasPermission = true;
          }
        }
        if (!hasPermission) {
          return false;
        }
      }

      return cat.name === URAK_DOC || cat.name === TIL_DOC;
    })
    .map((item) => ({
      label: t(item.name),
      value: item.id,
      tag: item.name,
    }));

  const activeDocMainCategory = mainCategories.find((cat) =>
    activeDocCategories.find((catid) => cat.value === catid)
  );

  const onSearch = async (e) => {
    e.preventDefault();
    if (!mainCategory || loading) return;

    const additionals =
      mainCategory.tag === URAK_DOC
        ? {
            contractorDocumentTypes: contractDocCategories.map(
              (item) => item.value
            ),
          }
        : mainCategory.tag === TIL_DOC
        ? { documentCategories: docCategories.map((item) => item.value) }
        : {};

    const searchRequest = {
      ...plainSearchRequest,
      searchMainCategory: mainCategory.value,
      freeText,
      ...additionals,
    };

    setLoading(true);

    const result = await DOC.documentSearchRequest(searchRequest);

    setLoading(false);
    setDocuments(result.rows.filter((doc) => doc.id !== activeDocId));
  };

  const clear = () => {
    setReplacement(-1, undefined);
  };

  const onChoose = async () => {
    if (selected) {
      if (selected === activeDocId) {
        errorMessage(t('errorMessage.canNotReplaceByItself'));
        return;
      }
      const searchRequest = {
        ...plainSearchRequest,
        documentIds: [selected],
      };
      const result = await DOC.documentSearchRequest(searchRequest);
      if (result.rows.length === 1) {
        setReplacement(selected, result.rows[0].name);
      } else {
        errorMessage(t('errorMessage.canNotAccessDocument'));
      }
    }
  };

  React.useEffect(() => {
    if (!mainCategory && mainCategories.length > 0)
      setMainCategory(
        activeDocMainCategory ? activeDocMainCategory : mainCategories[0]
      );
  }, [mainCategory, mainCategories]);

  const allDocCategories = appCtx.docCategories.map((v) => ({
    label: v.name,
    value: v.id,
  }));
  const allContractorDocTypes = appCtx.getContractorDocumentypes.map((v) => ({
    label: v.name,
    value: v.id,
  }));

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onChoose();
        }}
      >
        <p>{t('replacementDocument.setById')}</p>
        <TextField
          label="ID"
          defaultValue={selected}
          onChange={(e) => {
            const nbr = Number(e.currentTarget.value);
            if (!isNaN(nbr)) {
              setSelected(
                Number(e.currentTarget.value) === activeDocId ? undefined : nbr
              );
            }
          }}
          type="number"
        />
      </form>
      <form onSubmit={(e) => onSearch(e)}>
        <p>{t('replacementDocument.setBySearch')}</p>
        <ReactSelect
          className="control"
          value={mainCategory}
          options={mainCategories}
          onChange={(value) => {
            if (value) {
              setMainCategory(value as MainCategory);
            }
          }}
        />
        <TextField
          label={t('search.freeTextSearch')}
          defaultValue={freeText}
          onChange={(e) => setFreeText(e.currentTarget.value.trim())}
        />

        {mainCategory ? (
          mainCategory.tag === TIL_DOC ? (
            <ReactSelect
              className="control"
              isMulti={true}
              placeholder={t('documentMeta.documentCategory')}
              options={allDocCategories}
              value={docCategories}
              onChange={(listOfValues: ValueType<Model.Item>) => {
                setDocCategories(listOfValues as Model.Item[]);
              }}
              maxMenuHeight={200}
            />
          ) : (
            <ReactSelect
              className="control"
              isMulti={true}
              placeholder={t('documentMeta.contractorDocumentType')}
              options={allContractorDocTypes}
              value={contractDocCategories}
              onChange={(listOfValues: ValueType<Model.Item>) => {
                setContractDocCategories(listOfValues as Model.Item[]);
              }}
              maxMenuHeight={200}
            />
          )
        ) : null}

        {loading ? (
          <Loading loading={true} />
        ) : documents ? (
          <DataTable
            defaultRowsPerPage={appCtx.userSettings.defaultRowCount}
            onUserUpdateDefaultRowCount={(count) => {
              appCtx.saveUserSettings({
                defaultRowCount: count,
              });
            }}
            rows={documents}
            keys={['id', 'name']}
            titles={{ id: 'ID', name: t('documentMeta.name') }}
            render={{
              id: (row) => (
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to={`/documents/${row.id}`}
                >
                  {row.id}
                </Link>
              ),
            }}
            onClick={(row) => {
              setSelected(row.id);
            }}
            rowStyles={(row) =>
              row.id === selected ? { background: 'rgb(225, 225, 225)' } : {}
            }
          />
        ) : null}
        <div>
          <Button className="button" type="submit">
            {t('buttons.search')}
          </Button>
          {selected ? (
            <Button className="button" onClick={() => onChoose()}>
              {t('buttons.setReplacement')}
            </Button>
          ) : null}
          {replacement && replacement !== -1 ? (
            <Button className="button" onClick={() => clear()}>
              {t('buttons.clearReplacement')}
            </Button>
          ) : null}
        </div>
      </form>
    </>
  );
};

export default ReplacementDocument;
