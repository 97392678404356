import { useContext, useState } from 'react';
import * as React from 'react';
import { createPitemType } from 'state/api';
import { useAppContext } from 'utils/AppContextProvider';
import { ItemListing } from '../../../../../shared/src/model/index';
import { CompareOldAndNewData } from './CompareOldAndNewData';
import { ItemExcelImport } from './ItemExcelImport';
import MissingPitemType from './MissingPitemType';
import { ItemDataCheckResults } from './PitemState';
import { PitemStateContext } from './reducers/PitemState';

interface ItemRegistryProps {}

export function ItemImport(props: ItemRegistryProps) {
  const ctx = useContext(PitemStateContext);

  const appCtx = useAppContext();
  const [excelData, setExcelData] = useState<ItemListing | undefined>(
    undefined
  );
  const [itemData, setItemData] = useState<ItemListing | undefined>(undefined);
  const [missingPitemType, setMissingPitemType] = useState<string | undefined>(
    ''
  );

  const refreshData = async inputData => {
    const data = (await ctx.checkItemData(inputData)) as ItemDataCheckResults;
    if (!data.success) {
      setExcelData(inputData);
      ctx.clearState();
      setMissingPitemType(data.missingPitemType);
      return;
    }

    setMissingPitemType(undefined);
    setItemData(data.data);
  };

  const importWithSpreadsheet = (
    <div>
      <ItemExcelImport onData={refreshData} />

      <div>
        <MissingPitemType
          name={missingPitemType}
          askNewType={async name => {
            if (missingPitemType) {
              await createPitemType(name);
              await appCtx.getDocumentCategories();
              refreshData(excelData);
            }
          }}
        />

        {itemData && ctx.buildings.length > 0 && (
          <CompareOldAndNewData
            newItems={itemData}
            oldItems={ctx.remoteItems}
            foundRooms={ctx.rooms}
            allBuildings={ctx.buildings}
            askRefreshData={async () => {
              refreshData(itemData);
            }}
          />
        )}
      </div>
    </div>
  );

  return importWithSpreadsheet;
}
