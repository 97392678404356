import * as React from 'react';
import * as _ from 'lodash';
import * as applicationState from '../../state/reducers/ApplicationState';
import * as documentState from '../../state/reducers/DocumentState';

import {
  DBPitem,
  UIDocument,
  UIDocumentType,
  SearchColumn,
  SearchField,
} from '../../../../shared/src/model/index';

import DocumentTable, { TableRowType } from '../Common/DocumentTable';
import DocumentToolbar from '../Common/DocumentToolbar';
import Moment from 'react-moment';
import { getTypesUnderRootTypeSync } from '../../utils/metadata';
import { useUnsafeFormatMessage } from '../../utils/translateHook';

// key: SearchColumn, value: SearchField
const orderByMapping: { [key: string]: string } = {
  name: 'DocumentName',
  revision: 'Revision',
  description: 'Description',
  dwgName: 'DWG',
  startPole: 'StartPole',
  endPole: 'EndPole',
  aItems: 'ContractName',
  pItems: 'BuildingName',
  workflowStateName: 'Status',
  contractorWorktype: 'WorkType',
  location: 'RoomName',
  locationLongname: 'RoomLongName',
  pitemTypeName: 'PItemTypeName',
  contractorDocumentType: 'ContractorDocumentType',
  designerDocumentType: 'DocumentType',
  designDiscipline: 'DesignPrinciple',
  area: 'AreaName',
  createdByUser: 'CreatedBy',
  modifiedByUser: 'ModifiedBy',
  inspectionDocTarget: 'InspectionDocTarget',
  created: 'DateCreated',
  modified: 'DateEdited',
  versionNumber: 'VersionNumber',
  targetcode: 'TargetCode',
  roomId: 'RoomName', // for pitem search
  room: 'RoomLongName', // for pitem search
  building: 'BuildingName', // for pitem search
};

// Columns which should used for pitem search result sorting
const pItemSortColumns = [
  'DocumentName', // This same as name (pitem)
  'TargetCode', // This same as name (pitem)
  'StartPole',
  'EndPole',
  'AreaName',
  'BuildingName',
  'RoomName',
  'RoomLongName',
  'PItemTypeName'
];

const getOrderByFieldname = (key: keyof UIDocument, type: string | undefined): string | undefined => {
  const field = _.get(orderByMapping, key, undefined);
  if (field !== undefined && type === 'pitem' && pItemSortColumns.indexOf(field) === -1) {
      return undefined;
  }
  return field;
}

interface SearchResultsTableProps
  extends documentState.IProps,
    applicationState.IProps {
  resultsLoading?: boolean;
  resultType?: UIDocumentType;
  searchMainCategoryName?: string;
  onSearchResultsPageChange?: (page: number) => void;
  currentPage?: number;
  defaultRowCount?: number;
  reload: () => void;
  onAskMoreData?: () => void;
  contents: UIDocument[];
  title?: string | Element | JSX.Element;
  handleRedirectToDocument: (
    document: UIDocument,
    type: 'documents' | 'items'
  ) => void;
  cols?: SearchColumn[];
  searchWithOrder: (field: SearchField) => void;
  onUserUpdateDefaultRowCount?: (rowCount: number) => void;
  estimatedCnt?: number;
}

const AbstractSearchResultsTable: React.FC<SearchResultsTableProps> = (
  props
) => {
  // Extract props
  const {
    resultsLoading,
    resultType,
    searchMainCategoryName,
    onSearchResultsPageChange,
    currentPage,
    defaultRowCount,
    reload,
    contents,
    searchWithOrder,
    title,
    handleRedirectToDocument,
    cols,
    onUserUpdateDefaultRowCount,
  } = props;

  // Props from appstate
  const isAdmin: boolean = _.get(props.user!, 'is_superuser', false) || false;
  const {
    appDocumentCategories,
    userContracts,
    togglePitemTrackedStatus,
  } = props;

  // Hooks
  const t = useUnsafeFormatMessage();
  const [selectedDocuments, setSelectedDocuments] = React.useState<
    UIDocument[]
  >([]);

  React.useEffect(() => {
    const updatedSelDocs = _.compact(
      selectedDocuments.map((doc) => contents.find(({ id }) => doc.id === id))
    );
    setSelectedDocuments(updatedSelDocs);
  }, [contents]);

  const renderTrackedDot = (doc: UIDocument) => {
    return isAdmin ? (
      <div
        onClick={(e) => {
          e.stopPropagation();
          togglePitemTrackedStatus(doc);
        }}
      >
        <span className={`dot ${doc.isTracked ? 'red' : 'notSet'}`} />
      </div>
    ) : (
      <div>
        <span className={`dot ${doc.isTracked ? 'red' : 'transparent'}`} />
      </div>
    );
  };

  const renderRows = (): TableRowType[] =>
    contents.map((row: UIDocument) => ({
      ...row,
      aItems: <span>{row.aItems.map((item) => item.name).join(', ')}</span>,
      workAitems: (
        <span>
          {userContracts
            .filter(
              (contract) =>
                row &&
                row.workAitemIds &&
                row.workAitemIds.indexOf(contract.aitem_id) > -1
            )
            .map((aitem) => aitem.aitem_name)
            .join(', ')}
        </span>
      ),
      pItems: <span>{row.pItems.map((item) => item.name).join(', ')}</span>,
      pitemTree: (
        <span>
          {row.pitemTree
            ? Object.keys(row.pitemTree)
                .map((key) =>
                  (row.pitemTree ? row.pitemTree[key] : [])
                    .map((item: DBPitem) => item.name)
                    .join(', ')
                )
                .join('')
            : null}
        </span>
      ),
      created: (
        <span>
          <Moment date={row.created} format="DD.MM.YYYY HH:mm" />
        </span>
      ),
      modified: (
        <span>
          <Moment date={row.modified} format="DD.MM.YYYY HH:mm" />
        </span>
      ),
      area:
        (row.areas && row.areas.map((area) => area.area_name).join(', ')) || '',
      // Sijainti
      location: row.room
        ? row.room.name
        : row.polerange
        ? row.polerange.name
        : row.pole
        ? row.pole.name
        : row.startPole
        ? `${row.startPole}${row.endPole ? ' - ' + row.endPole : ''}`
        : '',
      // Tilan nimi
      locationLongname: row.room
        ? row.room.name_long
        : row.polerange
        ? row.polerange.name_long
        : row.pole
        ? row.pole.name_long
        : row.startPole
        ? `${row.startPole}${row.endPole ? ' - ' + row.endPole : ''}`
        : '',
      designDiscipline: getTypesUnderRootTypeSync(
        'Suunnitteluala',
        row,
        appDocumentCategories
      )
        .map((item) => item.name)
        .join(' / '),
      contractorWorktype: getTypesUnderRootTypeSync(
        'Työlaji',
        row,
        appDocumentCategories
      )
        .map((item) => item.name)
        .join(' / '),
      contractorDocumentType: getTypesUnderRootTypeSync(
        'Dokumentin tyyppi',
        row,
        appDocumentCategories
      )
        .map((item) => item.name)
        .join(' / '),
      designerDocumentType: getTypesUnderRootTypeSync(
        'Dokumenttityyppi',
        row,
        appDocumentCategories
      )
        .map((item) => item.name)
        .join(' / '),
      room: row.room ? row.room.name_long : '',
      roomId: row.room ? row.room.name : '',
      pole: row.pole ? row.pole.name : '',
      polerange: row.polerange ? row.polerange.name : '',
      floor: row.floor ? row.floor.name : '',
      building: row.building ? row.building.name : '',
      target: row.target ? row.target.name_long : '',
      targetcode: row.target
        ? row.target.name
        : row.type === 'pitem'
        ? row.name
        : '',
      pitemTypeName: row.pitemTypeName || '',
      isTracked: renderTrackedDot(row),
      categories: (
        <span>{row.categoryNames ? row.categoryNames.join(', ') : null}</span>
      ),
      inspectionDocTarget: row.inspectionDocTarget,
    }));

  return (
    <DocumentTable
      align={{ isTracked: 'center' }}
      loading={resultsLoading}
      type={resultType}
      searchMainCategoryName={searchMainCategoryName}
      onPageChange={onSearchResultsPageChange}
      page={currentPage}
      rowsPerPage={defaultRowCount}
      onUserUpdateDefaultRowCount={onUserUpdateDefaultRowCount}
      actionComponents={
        resultType === 'document'
          ? [
              <DocumentToolbar
                key={'toolbar1'}
                selectedDocuments={selectedDocuments}
                didUpdateDocuments={() => reload()}
              />,
            ]
          : []
      }
      onAskMoreData={props.onAskMoreData}
      selectedDocuments={selectedDocuments}
      setSelected={(ids) =>
        setSelectedDocuments(contents.filter(({ id }) => ids.indexOf(id) >= 0))
      }
      headerClick={(value: keyof UIDocument) => {
        const fieldName = getOrderByFieldname(value, resultType);
        if (fieldName) {
          searchWithOrder(fieldName as SearchField);
        }
      }}
      hasSelect={resultType === 'document'}
      title={title}
      estimatedCnt={props.estimatedCnt}
      rows={renderRows()}
      keys={(cols || []).reduce((prev, curr) => {
        return {
          ...prev,
          [curr]: t('documentMeta.' + curr),
        };
      }, {})}
      rowClick={(row) =>
        handleRedirectToDocument(
          row as UIDocument,
          row.type === 'pitem' ? 'items' : 'documents'
        )
      }
    />
  );
};

const SearchResultsTable = applicationState.StateConnector(
  documentState.StateConnector(AbstractSearchResultsTable)
);

export default SearchResultsTable;
