import * as React from 'react';
import * as _ from 'lodash';
import { Paper, Tab, Tabs } from '@material-ui/core';
import { useFormatMessage } from '../../../utils/translateHook';
import { AreaImport } from './AreaImport';
import { AreaMFiles } from './AreaMFiles';
import { Route } from 'react-router';
import useRouter from 'utils/routerHook';
import { Areas } from './Areas';

export enum PItemPages {
  TargetImport = 0,
  PhaseContractBuilding,
}

export default function MainView() {
  const t = useFormatMessage();
  const router = useRouter();

  const tabs = [
    { label: t('adminTools.areas'), value: '/admin-tools/areas' },
    { label: t('adminTools.importArea'), value: '/admin-tools/areas/import' },
    { label: t('adminTools.areaMFiles'), value: '/admin-tools/areas/mfiles' },
  ];

  const location = router.location.pathname;
  return (
    <Paper square={true}>
      <Tabs
        value={location}
        indicatorColor="primary"
        textColor="primary"
        onChange={(e, newValue) => {
          router.history.push(newValue);
        }}
      >
        {tabs.map(tab => (
          <Tab key={tab.label} {...tab} />
        ))}
      </Tabs>
      <Route path="/admin-tools/areas/" exact={true} component={Areas} />
      <Route path="/admin-tools/areas/import" component={AreaImport} />
      <Route path="/admin-tools/areas/mfiles" component={AreaMFiles} />
    </Paper>
  );
}
