import { DocumentService } from '../services/document/src/frontend/api';
import axios from 'axios';
import * as _ from 'lodash';
import { PropertyCollection } from '../../../shared/src/model';
import { ClassFields } from '../../../shared/src/model/QueryTypes';
const DOC = DocumentService(axios);

export type ClassNames = keyof ClassFields;
export type ClassKeys<T extends ClassNames> = ClassFields[T] | '_';
export type CollectionFetchParamType<T extends ClassNames> =
  | ClassKeys<T>
  | [ClassKeys<T>, CollectionFetchParamType<ClassNames>]
  | Array<CollectionFetchParamType<ClassNames>>;
export type CollectionFetchParams<T extends ClassNames> = Array<
  CollectionFetchParamType<T>
>;

interface FetchParams<T extends ClassNames> {
  id: number;
  className: string;
  fields?: CollectionFetchParams<T>;
  resolver?: (collection: PropertyCollection<any>) => void;
  reject?: (reason: any) => void;
}

// batch ID requests
let latestRequests: Array<FetchParams<ClassNames>> = [];
const memoized: {
  promises: { [key: string]: Promise<PropertyCollection<any>> };
  props: { [key: string]: { [key: string]: boolean } };
} = {
  promises: {},
  props: {},
};

// Seems to be about right to avoid "PayloadTooLargeError: request entity too large",
// though the error depends on the actual payload size
const maxQueriesPerRequest = 1000;

// compress any query JSON to more compact format
function compress(listData: any) {
  const keyHash = {};
  const valueHash = {};
  let keyIndex = 1;
  let valueIndex = 1;
  let reversedKeys = {};
  let reversedValues = {};
  const addKey = (key) => {
    if (keyHash[key]) {
      return keyHash[key];
    }
    reversedKeys[keyIndex] = key;
    keyHash[key] = keyIndex;
    keyIndex++;
    return keyHash[key];
  };
  const addValue = (value) => {
    const key = JSON.stringify(value);
    if (valueHash[key]) {
      return valueHash[key];
    }
    reversedValues[valueIndex] = value;
    valueHash[key] = valueIndex++;
    return valueHash[key];
  };
  const res = {
    data: listData.map((obj) => {
      const o = {};
      Object.keys(obj).forEach((key) => {
        o[addKey(key)] = addValue(obj[key]);
      });
      return o;
    }),
    k: reversedKeys,
    v: reversedValues,
  };
  return res;
}

setInterval(async () => {
  const fetchParams = [...latestRequests.slice(0, maxQueriesPerRequest)];
  // const props = memoized.props;
  latestRequests = latestRequests.slice(maxQueriesPerRequest);
  memoized.promises = {};
  memoized.props = {};
  if (fetchParams.length === 0) {
    return;
  }
  try {
    // simplified
    (
      await DOC.fetchProps(
        compress(
          fetchParams.map(({ id, className, fields }) => ({
            id,
            className,
            fields: fields as string[],
          }))
        )
      )
    ).forEach((collection, i) => {
      const resolve = fetchParams[i].resolver;
      if (resolve) {
        resolve(collection);
        delete fetchParams[i].resolver;
      }
    });
    fetchParams.forEach((p) => {
      if (p.resolver) {
        p.resolver({});
      }
    });
  } catch (e) {
    fetchParams.forEach((p) => {
      if (p.reject) p.reject(e);
    });
  }
}, 100);

export const fetchPropertyCollection = async <T extends ClassNames>(props: {
  id: number;
  className: T;
  fields?: CollectionFetchParams<T>;
}): Promise<PropertyCollection<any>> => {
  const fieldParams = props.fields || ['_'];
  const key = `${props.className}:${props.id}:${JSON.stringify(fieldParams)}`;
  if (memoized.promises[key]) {
    // if same collecttion is fetched, return the same promise
    return memoized.promises[key];
  }
  const p = new Promise<PropertyCollection<any>>((resolve, reject) => {
    latestRequests.push({
      id: props.id,
      className: props.className,
      fields: fieldParams,
      resolver: resolve,
      reject,
    });
  });
  return (memoized.promises[key] = p);
};

export const updateProperties = async (
  props: PropertyCollection<any> | Array<PropertyCollection<any>>
) => {
  if (props instanceof Array) {
    return DOC.updateProps(props);
  } else {
    return DOC.updateProps([props]);
  }
};
