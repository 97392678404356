import * as React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import * as applicationState from '../../state/reducers/ApplicationState';
import * as adminState from '../../state/reducers/AdminState';

import './adminTools.scss';
// import Loading from '../Common/Loading';
// import { Async } from '../Common/Async';
import * as Model from '../../../../shared/src/model/index';
// import * as schema from '../../../../shared/src/model/projectSchema';
import { APIService } from '../../services/api/src/frontend/api';
// import { Notifier } from '../Common/Notifier';

// import { errorMessage, successMessage } from '../Common/Notifier';
import axios from 'axios';
import Loading from '../Common/Loading';
import { Async } from '../Common/Async';
import { Notifier } from '../Common/Notifier';
import PrivilegeCard from './PrivilegeCard';
// import PermissionPanel from './PermissionPanel';
// import { Divider, Button } from '@material-ui/core';
// import ReactSelect from 'react-select';

const API = APIService(axios);
// const DOC = DocumentService(axios);

interface IUsersProps extends applicationState.IProps, adminState.IProps {
  // roleId: number;
  role?: Model.APRole;
}

interface IUsersState {
  expandedPrivilegeIndex: number | undefined;
}

type UsersProps = IUsersProps & InjectedIntlProps;

class AbstractUsers extends React.Component<UsersProps, IUsersState> {
  constructor(props: UsersProps) {
    super(props);

    this.state = {
      expandedPrivilegeIndex: undefined,
    };
  }

  componentDidMount() {
    this.props.getMetadata();
    this.props.getContracts();
    this.props.getAvailableActions();
    this.props.loadSettings();
  }

  handleRemoveRoleFromUser = async (
    privilege: Model.UIUserRolePrivilege,
    roleId: number
  ) => {
    await API.removeRoleFromUser({
      userId: privilege.userId,
      roleId,
      aitemId: privilege.aitem ? privilege.aitem.id : undefined,
      pitemId: privilege.pitem ? privilege.pitem.id : undefined,
    });
  };

  render() {
    const t = (id: string) => this.props.intl.formatMessage({ id });
    const { role } = this.props;
    if (!role) {
      return <Loading loading={true} />;
    } else {
      return (
        <div className="detail">
          <div className="component">
            <h2>{role.role_name}</h2>
            <div className="content">
              <Async
                placeholder={<Loading loading={true} />}
                uri={role.id}
                loader={async (): Promise<Model.UIRolePrivilege[]> => {
                  return await API.getPrivilegesForRole(role.id);
                }}
                render={(privileges, reload) => {
                  if (!privileges) {
                    return (
                      <Notifier
                        snack={{ message: t('error.title'), variant: 'error' }}
                      />
                    );
                  }

                  const roleWithPrivilege: Model.UIRole = {
                    id: role.id,
                    name: role.role_name,
                    privileges,
                  };

                  return (
                    <PrivilegeCard
                      role={roleWithPrivilege}
                      metadata={this.props.adminSearch.metadata}
                      actions={this.props.actions}
                      privileges={
                        this.props.systemModel &&
                        this.props.systemModel.privileges
                      }
                      key={role.id}
                      onAddPrivilege={async (
                        privileges: Model.UIRolePrivilege[]
                      ) => {
                        await API.addPrivilegesToRole({
                          roleId: role.id,
                          privileges,
                        });
                        reload();
                      }}
                    />
                  );
                }}
              />
            </div>
          </div>
        </div>
      );
    }
  }
}

const Users = adminState.StateConnector(
  applicationState.StateConnector(AbstractUsers)
);

export default injectIntl(Users);
