import * as React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import MainLayout from '../../layout/MainLayout';
import * as applicationState from '../../state/reducers/ApplicationState';
import { setBackgroundImage } from '../../utils';
import './adminTools.scss';
import { Tabs, Tab } from '@material-ui/core';
// import { errorMessage, successMessage } from '../Common/Notifier';
import { push } from 'connected-react-router';

// const API = APIService(axios);
// const DOC = DocumentService(axios);

interface IAdminToolsProps extends applicationState.IProps {}

interface IAdminToolsState {
  selectedTab: number;
}

type AdminToolsProps = IAdminToolsProps & InjectedIntlProps;

class AbstractAdminTools extends React.Component<
  AdminToolsProps,
  IAdminToolsState
> {
  constructor(props: AdminToolsProps) {
    super(props);

    this.state = {
      selectedTab: 0,
    };
  }

  componentDidMount = async () => {
    setBackgroundImage();
  };

  onChangeTab = (event: React.ChangeEvent<{}>, value: any) => {
    this.props.ApplicationStateDispatcher(push(value));
  };

  render() {
    const t = (id: string) => this.props.intl.formatMessage({ id });

    const tabs = [
      { label: t('adminTools.users'), value: '/admin-tools/users' },
      { label: t('adminTools.roles'), value: '/admin-tools/roles' },
      {
        label: t('adminTools.systems'),
        value: '/admin-tools/systems',
      },
      {
        label: t('adminTools.categories'),
        value: '/admin-tools/categories',
      },
      {
        label: t('documentMeta.pitemTypes'),
        value: '/admin-tools/pitemtypes',
      },
      {
        label: t('adminTools.approvalCycles'),
        value: '/admin-tools/approval-cycles',
      },
      {
        label: t('adminTools.itemRegistry'),
        value: '/admin-tools/item-registry',
      },
      {
        label: t('adminTools.docs'),
        value: '/admin-tools/docs',
      },
      {
        label: t('adminTools.phases'),
        value: '/admin-tools/phases',
      },
      {
        label: t('adminTools.contracts'),
        value: '/admin-tools/contracts',
      },
      {
        label: t('adminTools.areas'),
        value: '/admin-tools/areas',
      },
      { label: t('adminTools.sentEmails'), value: '/admin-tools/mail-tasks' },
      {
        label: t('adminTools.printOrders'),
        value: '/admin-tools/print-orders',
      },
      {
        label: t('adminTools.serviceMessage'),
        value: '/admin-tools/service-message',
      },
      {
        label: t('adminTools.sync'),
        value: '/admin-tools/sync',
      },
    ];

    // Add the misc tab if in development mode
    if (process.env.NODE_ENV === 'development') {
      tabs.push({ label: t('adminTools.misc'), value: '/admin-tools/misc' });
    }

    const getLocation = () => {
      const location = window.location.pathname;
      // Special case for selected ids
      const splitLocation = location
        .split('/')
        .splice(0, 3)
        .join('/');
      if (tabs.filter((t) => t.value === splitLocation).length > 0) {
        return splitLocation;
      } else {
        return false;
      }
    };

    return (
      <MainLayout view="adminTools">
        <Tabs
          className="adminNavigation"
          value={getLocation()}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          onChange={this.onChangeTab}
          scrollButtons="on"
        >
          {tabs.map((tab) => (
            <Tab key={tab.label} {...tab} />
          ))}
        </Tabs>

        {this.props.children}
      </MainLayout>
    );
  }
}

const AdminTools = applicationState.StateConnector(AbstractAdminTools);

export default injectIntl(AdminTools);
