import { addLocaleData, IntlProvider } from 'react-intl';
import * as en from 'react-intl/locale-data/en';
import * as fi from 'react-intl/locale-data/fi';
import { connect } from 'react-redux';
import translations_en from './translations/en';
import translations_fi from './translations/fi';

addLocaleData([...en, ...fi]);

const translations = {
  en: translations_en,
  fi: translations_fi,
};

const supportedLanguages = ['en', 'fi'];

// This function will map the current redux state to the props for the component that it is "connected" to.
// When the state of the redux store changes, this function will be called, if the props that come out of
// this function are different, then the component that is wrapped is re-rendered.
function mapStateToProps(state) {
  const userLanguage = state.ApplicationState.userSettings.userLanguage;
  const browserLanguage = navigator.language.split(/[-_]/)[0];
  const browserLanguageIsSupported =
    supportedLanguages.indexOf(browserLanguage) >= 0;

  let locale = '';

  // Get user language from user settings
  if (userLanguage) {
    locale = userLanguage;
    // Fallback to browser language
  } else if (browserLanguageIsSupported) {
    locale = browserLanguage;
    // Fallback to default laguage for the service
  } else {
    locale = 'fi';
  }

  const messages = translations[locale];
  return { locale, messages };
}

export default connect(mapStateToProps)(IntlProvider);
