import './document.scss';

import * as Model from '../../../../shared/src/model';
import * as React from 'react';
import * as _ from 'lodash';
import * as container from '../../state/reducers/ApplicationState';
import * as documentState from '../../state/reducers/DocumentState';

import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { InjectedNotistackProps, withSnackbar } from 'notistack';
import {
  Notifier,
  errorMessage,
  successMessage,
  warningMessage,
} from '../Common/Notifier';
import {
  categoriesHaveBeenLoaded,
  collectDocumentMetadata,
  getTypesUnderRootType,
} from '../../utils/metadata';

import { APIService } from '../../services/api/src/frontend/api';
import { Async } from '../Common/Async';
import Breadcrumbs from '../Common/Breadcrumbs';
import Button from '../Common/Button';
import DateRow from '../Common/DateRow';
import DocToolbar from './DocToolbar';
import DocumentPackage from './DocumentPackage';
import { DocumentService } from '../../services/document/src/frontend/api';
import DocumentTextRow from '../Common/DocumentTextRow';
import FileInput from '../Common/FileInput';
import { Item } from '../Common/AutosuggestChipSelection';
import { Link } from 'react-router-dom';
import Loading from '../Common/Loading';
import MainLayout from '../../layout/MainLayout';
import Moment from 'react-moment';
import Preview from '../Common/Preview/Preview';
import ReactSelect from 'react-select';
import ReplacementDocument from './ReplacementDocument';
import StatusHistory from './StatusHistory';
import TextField from '../Common/TextField';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { ValueType } from 'react-select/lib/types';
import VersionHistory from './VersionHistory';
import axios from 'axios';
import { push } from 'connected-react-router';
import { DataTable } from 'components/Common/DataTable';

const API = APIService(axios);
const DOC = DocumentService(axios);

interface IDocumentProps
  extends container.IProps,
  documentState.IProps,
  InjectedNotistackProps {
  savedSearchName: string;
  match: {
    params: {
      documentId: number;
      mode?: string;
    };
  };
  location?: any;
}

interface IDocumentState {
  isEditing: boolean;
  isUploading: boolean;
  isRejecting: boolean;
  contracts: Item[];
  documentCategory: string;
  rejectReason: string;
  file: File | undefined;

  isLoaded: boolean;
  error: any;
  doc?: Model.UIDocument;
  docPackage?: Model.UIDocument;
  statusHistory?: Model.DocStateHistoryRow[];
  versionHistory?: Model.UIDocument[];
  successMessage?: string;
  selectedDocuments: Model.UIDocument[];
  openPanels: { [key: string]: boolean };

  documentSnapshotForUndo?: Model.UIDocument;
  currentDocumentCategory?: Item;
  currentDiscipline?: Item;

  isReplacing: boolean;
  isFindSimilar: boolean;
  similarDocuments: Model.UIDocument[];
}

type DocumentProps = IDocumentProps & InjectedIntlProps;

class AbstractDocument extends React.Component<DocumentProps, IDocumentState> {
  docPackage?: Model.UIDocument;

  constructor(props: DocumentProps) {
    super(props);

    this.state = {
      isEditing: false,
      isUploading: false,
      contracts: [],
      documentCategory: '',
      isRejecting: false,
      rejectReason: '',
      file: undefined,
      isLoaded: false,
      error: null,
      selectedDocuments: [],
      openPanels: {},
      isReplacing: false,
      isFindSimilar: false,
      similarDocuments: [],
    };

    this.props.getUserContracts();
  }

  setReplacement = (replacement: number, name: string | undefined | null) => {
    if (this.state.doc) {
      this.setState({
        doc: {
          ...this.state.doc,
          replacedByDocumentId: replacement,
          replacedByDocumentName: name,
        },
      });
    }
  };

  setIsReplacing = (replacing: boolean = true, save: boolean = false) => {
    if (save && this.state.doc && this.state.doc.replacedByDocumentId == null)
      return;
    this.setState({ isReplacing: replacing });
    if (save) {
      this.saveActiveDocument();
    }
  };

  setIsFindSimilar = (value: boolean = true) => {
    this.setState({ isFindSimilar: value });
  };

  async refreshDocument() {
    try {
      if (this.state.error) {
        return;
      }
      // Wait for app categories to be loaded before proceeding
      await categoriesHaveBeenLoaded();
      const doc = await DOC.fetchDocument(this.props.match.params.documentId);
      this.saveDocumentSnapshotData(doc);
      const versionHistory = (
        await DOC.getVersionHistory(this.props.match.params.documentId)
      ).sort((a, b) => {
        // Latest first
        if (b.versionNumber < a.versionNumber) return -1;
        if (b.versionNumber > a.versionNumber) return 1;
        return 0;
      });
      const statusHistory = await DOC.fetchDocumentHistory(
        this.props.match.params.documentId
      );

      this.setState({ isLoaded: true, doc, versionHistory, statusHistory });
      this.props.updateActiveDocument(doc);
    } catch (e) {
      this.setState({ isLoaded: true, error: e });
      errorMessage(e);
    }
  }

  async componentDidMount() {
    this.refreshDocument();
  }

  downloadDocument = (activeDocument: Model.UIDocument) => {
    if (document && document.location) {
      document.location.href = `/docs/v1${activeDocument.downloadUrl}`;
    }
  };

  onChangeNewDocument = (files: File[]) => {
    if (files) {
      this.setState({ file: files[0] });
    }
  };

  toState = async (
    activeDocument: Model.UIDocument,
    stateId: number,
    reason: string
  ) => {
    if (this.state.doc) {
      try {
        this.setState({
          isLoaded: false,
        });
        await DOC.changeDocState({
          docid: this.state.doc.id,
          newStateId: stateId,
          reasonForRejection: reason,
        });
        // Then fetch the history
        const versionHistory = (
          await DOC.getVersionHistory(this.state.doc.id)
        ).sort((a, b) => {
          // Latest first
          if (b.versionNumber < a.versionNumber) return -1;
          if (b.versionNumber > a.versionNumber) return 1;
          return 0;
        });
        const statusHistory = await DOC.fetchDocumentHistory(this.state.doc.id);
        this.refreshDocument();
        this.setState({
          versionHistory,
          statusHistory,
          isLoaded: true,
        });
      } catch (e) {
        errorMessage(e);
        this.setState({
          isLoaded: true,
          error: e,
        });
      }
    }
  };

  handleEditMode = () => {
    this.setState({ isEditing: !this.state.isEditing }, () => {
      if (!this.state.isEditing) {
        this.undoDocumentChanges();
      } else {
        this.saveDocumentSnapshot();
      }
    });
  };

  handleFindSimilar = async () => {
    if (this.state.doc) {
      const had = this.state.isFindSimilar;
      this.setState({ isFindSimilar: !this.state.isFindSimilar });

      if (!had) {
        this.setState({
          similarDocuments: await DOC.findSimilarDocuments(this.state.doc!.id),
        });
      }
    }
  };

  handleReplacingMode = () => {
    this.setState({ isReplacing: !this.state.isReplacing }, () => {
      if (!this.state.isReplacing) {
        this.undoDocumentChanges();
      } else {
        this.saveDocumentSnapshot();
      }
    });
  };

  handleUploadNewDocumentMode = () => {
    this.setState({
      isUploading: !this.state.isUploading,
      isEditing: !this.state.isEditing,
    });
  };

  handleCancelEdit = () => {
    if (this.props.activeDocument) {
      this.props.revertDocument(this.props.activeDocument);
    }
    this.setState({ isEditing: false, isUploading: false, isReplacing: false });

    this.undoDocumentChanges();
  };

  saveDocumentSnapshotData = (doc: Model.UIDocument) => {
    const documentCategories = this.props.docCategories;
    const currentDocumentCategory = documentCategories
      .filter((cat) => (doc.categories || []).includes(cat.id))
      .map((item) => ({
        value: item.id,
        label: item.name,
      }))
      .pop();

    const documentDisciplines = this.props.designDisciplines;
    const currentDiscipline = documentDisciplines
      .filter((cat) => (doc.categories || []).includes(cat.id))
      .map((item) => ({
        value: item.id,
        label: item.name,
      }))
      .pop();

    this.setState({
      documentSnapshotForUndo: doc,
      currentDocumentCategory,
      currentDiscipline,
    });
  };

  saveDocumentSnapshot = () => {
    if (this.state.doc) {
      this.saveDocumentSnapshotData(this.state.doc);
    }
  };

  undoDocumentChanges = () => {
    this.setState({
      doc: this.state.documentSnapshotForUndo,
    });
  };

  onChangeDocumentCategoryId = (cat: Item) => {
    if (this.state.doc) {
      const activeDoc = this.state.doc;
      const preserve = this.props.designDisciplines
        .filter((item) =>
          activeDoc.categories
            ? activeDoc.categories.indexOf(item.id) >= 0
            : false
        )
        .map((item) => item.id);
      if (cat) {
        this.setKey('categories', [...preserve, cat.value]);
        this.setState({ currentDocumentCategory: cat });
      }
    }
  };
  onChangeDesignDisciplineId = (cat: Item) => {
    if (this.state.doc) {
      const activeDoc = this.state.doc;
      const preserve = this.props.docCategories
        .filter((item) =>
          activeDoc.categories
            ? activeDoc.categories.indexOf(item.id) >= 0
            : false
        )
        .map((item) => item.id);
      if (cat) {
        if (cat.value) {
          this.setKey('categories', [...preserve, cat.value]);
        } else {
          // if not value, category is removed from the list
          this.setKey('categories', [...preserve]);
        }
        this.setState({ currentDiscipline: cat });
      }
    }
  };

  async uploadNewDocumentRevision(file: File) {
    if (!file) return;
    this.setState({
      isLoaded: false,
    });

    const formData = new FormData();
    formData.append('info', JSON.stringify(this.state.doc));
    formData.append('file', file);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };

    try {
      const result = (
        await axios.post('/docs/v1/upload/nextrevision', formData, config)
      ).data as Model.UIDocument[];
      const newDoc = result[0];
      const history = await DOC.fetchDocumentHistory(newDoc.id);
      this.props.DocumentStateDispatcher(push('/documents/' + newDoc.id));
      this.setState({ doc: newDoc, isLoaded: true, statusHistory: history });
      this.props.updateActiveDocument(newDoc);
      successMessage('Dokumentti päivitetty onnistuneesti');
      this.setState(
        {
          isEditing: false,
          isUploading: false,
        },
        () => {
          // Update the version history
          this.refreshDocument();
        }
      );
      this.props.noOp();
    } catch (e) {
      errorMessage(e);
      this.setState({
        error: e,
        isLoaded: true,
      });
      this.props.noOp();
    }
  }

  async saveActiveDocument() {
    try {
      if (this.state.doc) {
        this.setState({
          isLoaded: false,
        });
        const nextDoc = await DOC.updateDocument(this.state.doc);
        this.props.DocumentStateDispatcher(push('/documents/' + nextDoc.id));
        const history = await DOC.fetchDocumentHistory(this.state.doc.id);
        this.setState({
          isLoaded: true,
          doc: nextDoc,
          statusHistory: history,
        });
        successMessage('Dokumentti tallennettu onnistuneesti');
        this.props.updateActiveDocument(this.state.doc);
        this.setState({ isEditing: false });
        // TODO: It's not mandatory to reload document meta information every time when user
        // has edit the document. Only when it's in state 'Hyväksytty' or 'Hylätty'
        this.refreshDocument();
      }
    } catch (e) {
      this.refreshDocument();
      this.setState({
        error: e,
        isLoaded: true,
      });
      if (
        e.response &&
        e.response.data &&
        e.response.data.substring &&
        e.response.data.substring(0, 3) === '###'
      ) {
        errorMessage(
          this.props.intl.formatMessage({ id: e.response.data.substring(3) })
        );
      } else if (e.message) {
        errorMessage(e.message);
      } else {
        errorMessage(String(e));
      }
    }
  }

  handleSave = () => {
    if (this.state.isUploading) {
      if (this.state.file) {
        this.uploadNewDocumentRevision(this.state.file);
      } else {
        warningMessage('Valitse tiedosto!');
      }
    } else {
      this.saveActiveDocument();
    }
  };

  onRejectReasonChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ rejectReason: e.currentTarget.value });
  };

  setKey<K extends keyof Model.UIDocument>(key: K, value: Model.UIDocument[K]) {
    if (this.state.doc) {
      this.setState({
        doc: {
          ...this.state.doc,
          [key]: value,
        },
      });
    }
  }

  onChangeDocumentBuildingElement = (item: {
    label: string;
    value: number;
  }) => {
    if (this.state.doc) {
      const buildings = this.props.buildingItemTree
        .filter((b) => {
          return b.id === item.value;
        })
        .map((item) => {
          return {
            id: item.id,
            name: item.name!,
            longName: item.name_long!,
          };
        });
      this.setKey('pItems', buildings);
    }
  };

  onChangeDocumentContract = (itemAny: any) => {
    const items: Array<{ label: string; value: number }> = itemAny;
    if (this.state.doc) {
      const cat = _.uniqBy(
        this.props.userContracts.filter((cat) => {
          return (
            items.filter((selItem) => {
              if (cat.aitem_id === selItem.value) {
                return true;
              }
              return false;
            }).length > 0
          );
        }),
        (item: any) => item.aitem_id
      );
      if (cat && cat.length > 0) {
        this.setKey(
          'aItems',
          cat.map((aitem) => ({ id: aitem.aitem_id, name: aitem.aitem_name }))
        );
        this.setKey(
          'contractStr',
          cat.map((aitem) => aitem.aitem_name).join(' ')
        );
      } else {
        this.setKey('aItems', []);
        this.setKey('contractStr', '');
      }
    }
  };

  onDocTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setKey('name', e.currentTarget.value);
  };

  onStarPoleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setKey('startPole', parseInt(e.currentTarget.value, 10));
  };

  onEndPoleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setKey('endPole', parseInt(e.currentTarget.value, 10));
  };

  onDocRevisionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setKey('revision', e.currentTarget.value);
  };

  onDocDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setKey('description', e.currentTarget.value);
  };

  onChangeDocumentSystem = (value: number) => {
    this.setKey('systemId', value);
  };

  componentDidUpdate() {
    if (this.state.doc && this.state.isLoaded) {
      if (
        parseInt(String(this.props.match.params.documentId), 10) !==
        this.state.doc.id
      ) {
        this.setState(
          {
            isLoaded: false,
          },
          () => {
            this.refreshDocument();
          }
        );
      }
      //
    }
  }

  getSystemNameById(systemId: number) {
    return this.props.systems
      .filter(s => s.id === systemId)
      .map(s => s.system_name)
      .join('');
  }

  render() {
    const t = (id: string) => this.props.intl.formatMessage({ id });
    const { isEditing, isUploading, isReplacing } = this.state;
    const activeDocument = this.state.doc;
    const loading = !this.state.isLoaded;
    // const loaded = this.state.isLoaded;
    const hasReplacementDoc =
      activeDocument &&
      activeDocument.replacedByDocumentId &&
      activeDocument.replacedByDocumentId !== -1;

    if (!activeDocument) {
      return <MainLayout />;
    }

    const isOldVersion =
      this.state.versionHistory &&
      this.state.versionHistory.length > 0 &&
      this.state.versionHistory[0].id !== activeDocument.id;

    const kohdeTyyppi = this.props.pitemTypes
      .filter((t) => t.name === 'Kohde')
      .pop();

    const getPitemTypename = (id: number, useKohde = true): string => {
      const possibleType = this.props.pitemTypes
        .filter((t) => t.id === id)
        .pop();
      if (!possibleType) {
        return '';
      }
      if (
        useKohde &&
        kohdeTyyppi &&
        possibleType.upper_type_id === kohdeTyyppi.id
      ) {
        return 'Kohde';
      }
      return possibleType.name || '';
    };

    let name = '';

    const isDocumentPackage =
      _.intersection(
        activeDocument.categories || [],
        this.props.getContractorDocumentPackageId
      ).length > 0;

    const rejcetedState = this.props.appDocumentStates
      .filter((s) => s.name === 'REJECTED')
      .pop();
    const rejectedStateId = rejcetedState ? rejcetedState.id : 6;

    return (
      <MainLayout>
        <Breadcrumbs>
          <Link to="/">
            <FormattedMessage id="headers.home" />
          </Link>
          {this.props.searchResults.length > 0 ? (
            <Link to="/results">
              <FormattedMessage id="search.resultsHeader" />
            </Link>
          ) : (
            <FormattedMessage id="headers.documents" />
          )}

          {activeDocument && (
            <Link to={`/documents/${activeDocument.id}`}>
              {activeDocument.name}
            </Link>
          )}
        </Breadcrumbs>

        {isDocumentPackage ? (
          <h1>
            <FormattedMessage id="headers.documentPackage" />
          </h1>
        ) : (
          <h1>
            <FormattedMessage id="headers.document" />
          </h1>
        )}

        {isOldVersion && (
          <div className="isOldVersion">
            <FormattedMessage id="documentMeta.documentHasNewVersion" />
          </div>
        )}

        <Async
          uri={activeDocument.id + '' + activeDocument.modified}
          loader={async () => {
            return await collectDocumentMetadata(activeDocument, {
              worktypes: this.props.getContractorWorkTypes,
            });
          }}
          render={(meta, askForReload) => {
            if (!meta) {
              this.props.getDocumentCategories();
              return <div />;
            }
            if (isDocumentPackage && activeDocument.target) {
              name = activeDocument.target.name;
            } else {
              name = activeDocument.name;
            }
            return (
              <div>
                {this.state.successMessage ? (
                  <Notifier
                    snack={{
                      variant: 'success',
                      message: this.state.successMessage,
                    }}
                  />
                ) : null}
                <div
                  className={
                    meta.isContractorDoc ? 'grid gridCols3' : 'grid gridCols2'
                  }
                >
                  {activeDocument && (
                    <div className="documentMetaContainer">
                      <div className="documentMeta component">
                        <h2>
                          <span>{name}</span>
                        </h2>

                        <DocToolbar
                          doc={activeDocument}
                          meta={meta}
                          isUploading={isUploading}
                          isEditing={isEditing}
                          onAddToBasket={() => {
                            this.props.addToBasket([activeDocument]);
                          }}
                          isDocumentPackage={isDocumentPackage}
                          onNewRevision={this.handleUploadNewDocumentMode}
                          onEdit={this.handleEditMode}
                          askForReload={() => {
                            this.refreshDocument();
                          }}
                          onDownload={() => {
                            this.downloadDocument(activeDocument);
                          }}
                          onAction={(field) => {
                            if (
                              field.action_name === 'REJECT' ||
                              field.action_name === 'REJECT2'
                            ) {
                              this.setState({ isRejecting: true });
                            } else {
                              this.toState(
                                activeDocument,
                                field.to_state_id!,
                                ''
                              );
                            }
                          }}
                          onReplace={this.handleReplacingMode}
                          onFindSimilar={this.handleFindSimilar}
                          isReplacing={isReplacing}
                        />

                        <div className="content">
                          {loading || !this.state.isLoaded ? (
                            <Loading loading={true} />
                          ) : null}

                          {this.state.isRejecting ? (
                            <div className="isRejecting">
                              <h3>
                                <FormattedMessage id="documentMeta.rejectReason" />
                              </h3>
                              <div>
                                <TextField
                                  multiline={true}
                                  value={this.state.rejectReason}
                                  onChange={this.onRejectReasonChange}
                                />
                              </div>
                              <div className="editActions">
                                <Button
                                  color="primary"
                                  type="submit"
                                  // Disable save button when no reject reason is given
                                  disabled={!this.state.rejectReason.trim()}
                                  onClick={() => {
                                    this.setState({ isRejecting: false });
                                    this.toState(
                                      activeDocument,
                                      6,
                                      this.state.rejectReason
                                    );
                                  }}
                                >
                                  <FormattedMessage id="buttons.save" />
                                </Button>
                                <Button
                                  color="primary"
                                  onClick={(e) =>
                                    this.setState({ isRejecting: false })
                                  }
                                >
                                  <FormattedMessage id="buttons.cancel" />
                                </Button>
                              </div>
                            </div>
                          ) : null}

                          {this.state.isFindSimilar ? (
                            this.state.similarDocuments.length > 0 ? (
                              <div className="replacement-document">
                                { this.state.similarDocuments.length > 1 ? (
                                  <DataTable
                                    rows={this.state.similarDocuments.filter(doc => !!activeDocument && doc.id !== activeDocument.id)}
                                    keys={['pitemName', 'name']}
                                    titles={{
                                      pitemName: t('generics.pitem.target'),
                                      name: t('documentMeta.name'),
                                    }}
                                    render={{
                                      pitemName: (row) => {
                                        const showInTAGui = row.categoryNames && row.categoryNames.includes('TARKASTUSASIAKIRJA_DOC') && !!row.parentId;
                                        const linkName = row.pitemName || (row.systemId && this.getSystemNameById(row.systemId)) || row.id!;
                                        return (
                                          <span>
                                            { showInTAGui && (
                                              <Link to={'/inspection-documents/target/' + row.parentId! + '/attachment/' + row.id!}>{ linkName }</Link>
                                            )}
                                            { !showInTAGui && (
                                              <Link to={'/documents/' + row.id!}>{ linkName }</Link>
                                            )}
                                          </span>
                                        );
                                      },
                                      name: (row) => {
                                        return <span>{row.name}</span>;
                                      },
                                    }}
                                  />
                                ) : (
                                  <p><FormattedMessage id="documentMeta.similarDocumentsNotFound" /></p>
                                )}
                              </div>
                            ) : (
                              <Loading loading={true} />
                            )
                          ) : null}

                          {isReplacing ? (
                            <div className="replacement-document">
                              <ReplacementDocument
                                activeDocId={activeDocument.id}
                                activeDocCategories={activeDocument.categories}
                                replacement={
                                  activeDocument.replacedByDocumentId
                                }
                                setReplacement={this.setReplacement}
                                errorMessage={errorMessage}
                              />
                              <div className="editActions">
                                <Button
                                  color="primary"
                                  onClick={this.handleCancelEdit}
                                >
                                  <FormattedMessage id="buttons.cancel" />
                                </Button>

                                <Button
                                  color="primary"
                                  onClick={() =>
                                    this.setIsReplacing(false, true)
                                  }
                                  style={{ float: 'right' }}
                                >
                                  <FormattedMessage id="buttons.save" />
                                </Button>
                              </div>
                            </div>
                          ) : null}

                          <ValidatorForm onSubmit={this.handleSave}>
                            {meta.isRejected && (
                              <div className="isMissingDocuments">
                                <h2>
                                  <FormattedMessage id="documentMeta.REJECTED" />
                                </h2>
                                <Async
                                  uri={activeDocument.modified}
                                  loader={() =>
                                    DOC.fetchDocumentHistory(activeDocument.id)
                                  }
                                  render={(data) => {
                                    if (data) {
                                      const statusHistory =
                                        (this.state &&
                                          this.state.statusHistory) ||
                                        [];
                                      const lastReject = statusHistory.filter(
                                        (value, index) =>
                                          index === 0 &&
                                          value.newStateId === rejectedStateId
                                      );

                                      // If we are looking document package and the last status
                                      // history entry is not 'rejected', show message about
                                      // automatic rejection
                                      if (
                                        meta.isDocumentPackage &&
                                        lastReject.length === 0
                                      ) {
                                        return (
                                          <pre>
                                            <FormattedMessage id="documentMeta.documentPackageAutomaticRejection" />
                                          </pre>
                                        );
                                      }
                                      return (
                                        <>
                                          {lastReject.map((value, idx) => {
                                            return (
                                              <React.Fragment key={idx}>
                                                <pre>
                                                  {value.reasonForRejection}
                                                </pre>
                                                <div className="meta">
                                                  <p>
                                                    <Moment
                                                      date={value.dateStr}
                                                      format="DD.MM.YYYY HH:mm"
                                                    />
                                                  </p>
                                                  <p>{value.userName}</p>
                                                </div>
                                              </React.Fragment>
                                            );
                                          })}
                                        </>
                                      );
                                    } else {
                                      return <div />;
                                    }
                                  }}
                                />
                              </div>
                            )}
                            {isUploading && isEditing && (
                              <FileInput
                                style={{ height: '80px' }}
                                id="updateDocument"
                                single={true}
                                label={t('upload.dropzoneInfoSingle')}
                                onChange={this.onChangeNewDocument}
                              />
                            )}
                            <dl>
                              {hasReplacementDoc || isReplacing ? (
                                <>
                                  <dt>
                                    <FormattedMessage id="documentMeta.replacementDocument" />
                                  </dt>
                                  {hasReplacementDoc ? (
                                    <dd>
                                      <Link
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        to={`/documents/${activeDocument.replacedByDocumentId}`}
                                      >
                                        {activeDocument.replacedByDocumentName}
                                      </Link>
                                    </dd>
                                  ) : (
                                    <dd />
                                  )}
                                </>
                              ) : null}
                              <dt>
                                <FormattedMessage id="documentMeta.name" />
                              </dt>
                              <dd>
                                {isEditing ? (
                                  <TextField
                                    value={activeDocument.name}
                                    onChange={this.onDocTitleChange}
                                  />
                                ) : (
                                  activeDocument.name
                                )}
                              </dd>
                              {meta.isContractorDoc && !isEditing ? (
                                <>
                                  {activeDocument.pitemId ? (
                                    <div
                                      key={activeDocument.pitemId}
                                      className="documentMetaTargetCode"
                                    >
                                      <dt>
                                        <FormattedMessage id="documentMeta.targetcode" />
                                      </dt>
                                      <dd>
                                        <Link
                                          to={
                                            '/items/' + activeDocument.pitemId
                                          }
                                        >
                                          {activeDocument.pitemName}
                                        </Link>
                                      </dd>
                                    </div>
                                  ) : null}
                                  {meta.contractorDocumentTypes.length > 0 ? (
                                    <>
                                      <dt>
                                        <FormattedMessage id="documentMeta.contractorDocumentType" />
                                      </dt>
                                      <dd>
                                        {meta.contractorDocumentTypes
                                          .map((t) => t.name)
                                          .join(' / ')}
                                      </dd>
                                    </>
                                  ) : null}
                                  <dt>
                                    <FormattedMessage id="documentMeta.contractorWorkType" />
                                  </dt>
                                  <dd>
                                    {meta.contractorWorkTypes.length > 0
                                      ? meta.contractorWorkTypes.map(
                                        (t) => t.name
                                      )
                                      : '-'}
                                  </dd>

                                  {activeDocument && activeDocument.room ? (
                                    <div key={activeDocument.room.name}>
                                      <dt>
                                        <FormattedMessage id="documentMeta.room" />
                                      </dt>
                                      <dd>
                                        {activeDocument.room
                                          ? activeDocument.room.name
                                          : ''}
                                      </dd>
                                    </div>
                                  ) : null}
                                  {activeDocument.floor ? (
                                    <div key={activeDocument.floor.name}>
                                      <dt>
                                        <FormattedMessage id="documentMeta.floor" />
                                      </dt>
                                      <dd>{activeDocument.floor.name}</dd>
                                    </div>
                                  ) : null}
                                  {activeDocument.building ? (
                                    <div key={activeDocument.building.name}>
                                      <dt>
                                        <FormattedMessage id="documentMeta.building" />
                                      </dt>
                                      <dd>{activeDocument.building.name}</dd>
                                    </div>
                                  ) : null}
                                  {meta.pitemTree.length > 0 ? (
                                    <>
                                      <dt>
                                        <FormattedMessage id="documentMeta.pitemType" />
                                      </dt>
                                      <dd>
                                        {getPitemTypename(
                                          meta.primaryTypeId,
                                          false
                                        )}
                                      </dd>
                                    </>
                                  ) : null}
                                </>
                              ) : null}
                              {meta.isContractorDoc ? null : (
                                <>
                                  <dt>
                                    <FormattedMessage id="documentMeta.documentCategory" />
                                  </dt>
                                  <dd>
                                    {isEditing ? (
                                      <ReactSelect
                                        value={
                                          this.state.currentDocumentCategory
                                        }
                                        options={this.props.docCategories.map(
                                          (item) => ({
                                            value: item.id,
                                            label: item.name,
                                          })
                                        )}
                                        onChange={(
                                          value: ValueType<Model.Item>
                                        ) => {
                                          if (value) {
                                            if (!Array.isArray(value)) {
                                              this.onChangeDocumentCategoryId(
                                                value as Model.Item
                                              );
                                            }
                                          }
                                        }}
                                      />
                                    ) : (
                                      ((): string => {
                                        try {
                                          return this.props.docCategories.filter(
                                            (cat) =>
                                              activeDocument.categories
                                                ? activeDocument.categories.indexOf(
                                                  cat.id
                                                ) >= 0
                                                : false
                                          )[0].name;
                                        } catch (e) {
                                          return '-';
                                        }
                                      })()
                                    )}
                                  </dd>

                                  <dt>
                                    <FormattedMessage id="documentMeta.designDiscipline" />
                                  </dt>
                                  <dd>
                                    {/* ========= */}
                                    {isEditing ? (
                                      <ReactSelect
                                        value={this.state.currentDiscipline}
                                        options={[
                                          { value: null, label: '-' },
                                          ...this.props.designDisciplines.map(
                                            (item) => ({
                                              value: item.id,
                                              label: item.name,
                                            })
                                          ),
                                        ]}
                                        onChange={(
                                          value: ValueType<Model.Item>
                                        ) => {
                                          if (value) {
                                            if (!Array.isArray(value)) {
                                              this.onChangeDesignDisciplineId(
                                                value as Model.Item
                                              );
                                            }
                                          }
                                        }}
                                      />
                                    ) : (
                                      ((): string => {
                                        try {
                                          return this.props.designDisciplines.filter(
                                            (cat) =>
                                              activeDocument.categories
                                                ? activeDocument.categories.indexOf(
                                                  cat.id
                                                ) >= 0
                                                : false
                                          )[0].name;
                                        } catch (e) {
                                          return '-';
                                        }
                                      })()
                                    )}
                                  </dd>
                                </>
                              )}

                              <DocumentTextRow
                                id={'documentMeta.areas'}
                                value={
                                  activeDocument.areas &&
                                  activeDocument.areas.map((area) => {
                                    const label = area.description ? (
                                      <span>
                                        <b>{area.area_name}</b> –
                                        {area.description}
                                      </span>
                                    ) : (
                                      <b>{area.area_name}</b>
                                    );
                                    return label;
                                  })
                                }
                              />
                              {isEditing ? (
                                <>
                                  <dt>
                                    <FormattedMessage id="documentMeta.system" />
                                  </dt>
                                  <dd>
                                    <ReactSelect
                                      value={{
                                        value: activeDocument.systemId || 0,
                                        label: activeDocument.systemId ? this.getSystemNameById(activeDocument.systemId) : ''
                                      }}
                                      options={[
                                        { value: 0, label: '-' },
                                        ...this.props.systems.map((item) => ({
                                          value: item.id,
                                          label: item.system_name,
                                        })),
                                      ]}
                                      onChange={(
                                        value: ValueType<Model.Item>
                                      ) => {
                                        if (value) {
                                          if (!Array.isArray(value)) {
                                            this.onChangeDocumentSystem(
                                              (value as Model.Item).value
                                            );
                                          }
                                        }
                                      }}
                                    />
                                  </dd>
                                </>
                              ) : (
                                <DocumentTextRow
                                  id={'documentMeta.system'}
                                  value={
                                    activeDocument.systemsPath
                                      ? activeDocument.systemsPath.map(
                                        (systemId) => {
                                          const systems = this.props.systems.filter(
                                            (s) => s.id === systemId
                                          );
                                          if (!systems) {
                                            return <span></span>;
                                          }

                                          return (
                                            <span>
                                              {systems
                                                .map((s) => s.description)
                                                .join(' / ')}
                                            </span>
                                          );
                                        }
                                      )
                                      : '-'
                                  }
                                />
                              )}
                              <DocumentTextRow
                                id={'documentMeta.contract'}
                                value={activeDocument.aItems
                                  .map((item) => item.name)
                                  .join(' ')}
                              />

                              <dt>
                                {isEditing && !meta.isContractorDoc ? (
                                  <FormattedMessage id="documentMeta.poleInterval" />
                                ) : activeDocument.startPole &&
                                  activeDocument.endPole ? (
                                  <FormattedMessage id="documentMeta.poleInterval" />
                                ) : activeDocument.startPole &&
                                  !activeDocument.endPole ? (
                                  <FormattedMessage id="documentMeta.pole" />
                                ) : null}
                              </dt>

                              <dd>
                                {isEditing && !meta.isContractorDoc ? (
                                  <>
                                    <TextField
                                      label={t('documentMeta.startPole')}
                                      defaultValue={activeDocument.startPole}
                                      onChange={this.onStarPoleChange}
                                    />
                                    <TextField
                                      label={t('documentMeta.endPole')}
                                      defaultValue={activeDocument.endPole}
                                      onChange={this.onEndPoleChange}
                                    />
                                  </>
                                ) : (
                                  <span>
                                    {activeDocument.startPole &&
                                      activeDocument.endPole
                                      ? `${activeDocument.startPole} - ${activeDocument.endPole}`
                                      : null}
                                    {activeDocument.startPole &&
                                      !activeDocument.endPole
                                      ? `${activeDocument.startPole}`
                                      : null}
                                  </span>
                                )}
                              </dd>

                              {isEditing && !meta.isContractorDoc ? (
                                <>
                                  <dt>
                                    <FormattedMessage id="documentMeta.buildingElement" />
                                  </dt>
                                  <dd>
                                    <Async
                                      loader={async (): Promise<
                                        Array<{ label: string; value: number }>
                                      > => {
                                        const items = await API.getBuildingsForContracts(
                                          activeDocument.aItems.map(
                                            (item) => item.id
                                          )
                                        );
                                        this.props.assignBuildingElementTree(
                                          items
                                        );
                                        return items.map((item) => ({
                                          value: item.id,
                                          label: item.name_long!,
                                        }));
                                      }}
                                      placeholder={
                                        <div>
                                          <Loading loading={true} />
                                        </div>
                                      }
                                      render={(
                                        value?: Array<{
                                          label: string;
                                          value: number;
                                        }>
                                      ) => (
                                        <ReactSelect
                                          value={activeDocument.pItems.map(
                                            (building) => ({
                                              label: building.longName,
                                              value: building.id,
                                            })
                                          )}
                                          options={value}
                                          onChange={(
                                            value: ValueType<Model.Item>
                                          ) => {
                                            if (value) {
                                              if (!Array.isArray(value)) {
                                                this.onChangeDocumentBuildingElement(
                                                  value as Model.Item
                                                );
                                              }
                                            }
                                          }}
                                        />
                                      )}
                                    />
                                  </dd>
                                </>
                              ) : !meta.isContractorDoc ? (
                                <DocumentTextRow
                                  id="documentMeta.buildingElement"
                                  value={activeDocument.pItems
                                    .map((item) => item.longName)
                                    .join(', ')}
                                />
                              ) : null}

                              <DocumentTextRow
                                id={'documentMeta.version'}
                                value={String(activeDocument.versionNumber)}
                              />
                              <DocumentTextRow
                                id={'documentMeta.dwgName'}
                                value={activeDocument.dwgName}
                              />
                              <dt>
                                <FormattedMessage id="documentMeta.revision" />
                              </dt>
                              <dd>
                                {isEditing ? (
                                  <TextField
                                    value={activeDocument.revision}
                                    onChange={this.onDocRevisionChange}
                                  />
                                ) : (
                                  activeDocument.revision || '-'
                                )}
                              </dd>

                              <dt>
                                <FormattedMessage id="documentMeta.description" />
                              </dt>
                              <dd>
                                {isEditing ? (
                                  <TextField
                                    multiline={true}
                                    value={activeDocument.description || ''}
                                    onChange={this.onDocDescriptionChange}
                                  />
                                ) : (
                                  activeDocument.description || '-'
                                )}
                              </dd>
                              <dt>
                                <FormattedMessage id="documentMeta.workflowState" />
                              </dt>
                              <dd>
                                {t(
                                  `documentMeta.${activeDocument.workflowStateName}`
                                )}
                              </dd>
                              <dt>
                                <FormattedMessage id="documentMeta.createdByUser" />
                              </dt>
                              <dd>{activeDocument.createdByUser || '-'}</dd>
                              {/* <dd>{document.createdByUserId}</dd> */}

                              <DateRow
                                id="documentMeta.created"
                                value={activeDocument.created}
                              />

                              <dt>
                                <FormattedMessage id="documentMeta.modifiedByUser" />
                              </dt>
                              <dd>{activeDocument.modifiedByUser || '-'}</dd>

                              <DateRow
                                id="documentMeta.modified"
                                value={activeDocument.modified}
                              />
                            </dl>

                            {isEditing ? (
                              <div className="editActions">
                                <Button
                                  color="primary"
                                  onClick={this.handleCancelEdit}
                                >
                                  <FormattedMessage id="buttons.cancel" />
                                </Button>

                                <Button color="primary" type="submit">
                                  <FormattedMessage id="buttons.save" />
                                </Button>
                              </div>
                            ) : null}
                          </ValidatorForm>
                        </div>
                      </div>
                      {!isDocumentPackage && (
                        <>
                          <div className="spacer">
                            <StatusHistory
                              rows={this.state.statusHistory || []}
                            />
                          </div>

                          <div className="spacer">
                            <VersionHistory
                              rows={this.state.versionHistory || []}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  )}

                  {!meta.isContractorDoc || !activeDocument ? null : (
                    <Async
                      placeholder={<div>{t('messages.loading')}</div>}
                      uri={`${activeDocument.id}  ${activeDocument.modified}`}
                      loader={() =>
                        getTypesUnderRootType(
                          'Työlaji',
                          activeDocument,
                          this.props.getContractorWorkTypes
                        )
                      }
                      render={(workTypes) => {
                        const pItem =
                          activeDocument.pItems &&
                            activeDocument.pItems.length > 0
                            ? activeDocument.pItems[0]
                            : undefined;
                        // it is possible there are not work types
                        if (!pItem || !workTypes || workTypes.length === 0) {
                          return <div />;
                        }

                        return (
                          <DocumentPackage
                            itemIds={activeDocument.pitemIds || []}
                            openByDefault={isDocumentPackage}
                            document={activeDocument}
                            workTypeId={workTypes ? workTypes[0].id : undefined}
                            contractId={activeDocument.aItems[0].id}
                            itemName={pItem.name}
                            onWorflowChange={() => {
                              this.refreshDocument();
                            }}
                            onDocuments={(rows) => {
                              rows.forEach((uidoc) => {
                                if (
                                  uidoc.categoryNames &&
                                  uidoc.categoryNames.indexOf('URAK_DP') >= 0
                                ) {
                                  this.docPackage = uidoc;
                                  if (
                                    this.docPackage &&
                                    uidoc.id !== this.docPackage.id
                                  ) {
                                    this.setState({});
                                  }
                                }
                              });
                            }}
                          />
                        );
                      }}
                    />
                  )}

                  {activeDocument && !isDocumentPackage && (
                    <Preview doc={activeDocument} />
                  )}
                </div>
              </div>
            );
          }}
        />
      </MainLayout>
    );
  }
}

export default documentState.StateConnector(
  container.StateConnector(injectIntl(withSnackbar(AbstractDocument)))
);
