import '../index.scss';

import * as React from 'react';
import * as _ from 'lodash';

import MainLayout from '../../../layout/MainLayout';

import { Button, Paper, TableCell } from '@material-ui/core';
import { ClassNames } from 'properties/batch';
import Checkbox from '@material-ui/core/Checkbox';

import ImmutableDataTable from 'properties/ImmutableDataTable';

interface IPitemProps {
  match: {
    params: {
      classType?: ClassNames;
    };
  };
  history: any;
}

const SearchResultsDemo: React.FC<IPitemProps> = ({
  match: {
    params: { classType },
  },
  history,
}) => {
  return (
    <MainLayout>
      <div className="grid gridCol1">
        <Paper>
          <ImmutableDataTable
            onData={(newData, oldData) => {
              if (newData !== oldData) {
                console.log('... data is changed');
                newData.rows.forEach((r, i) => {
                  if (r !== oldData.rows[i])
                    console.log('Changed row', newData);
                });
              }
            }}
            rows={[
              { isSelected: false, id: 1, name: 'one', cnt: 0 },
              { isSelected: false, name: 'two', id: 2, cnt: 0 },
              { isSelected: false, name: 'three', id: 3, cnt: 0 },
            ]}
            renderers={[
              {
                id: 'selected',
                title: '',
                cell: (params) => (
                  <TableCell>
                    <Checkbox
                      onClick={(e) => {
                        params.modifyData((draft) => {
                          draft.rows[params.rowIndex].isSelected = !draft.rows[
                            params.rowIndex
                          ].isSelected;
                        });
                      }}
                      checked={params.row.isSelected}
                    />
                  </TableCell>
                ),
              },
              {
                id: 'row',
                title: 'row',
                cell: (params) => <TableCell>{params.rowIndex}</TableCell>,
              },
              {
                id: 'name',
                title: 'Name',
                cell: ({ row }) => <TableCell>{row.name}</TableCell>,
              },
              {
                id: 'clicks',
                title: 'Click Cnt',
                cell: ({ row }) => <TableCell>{row.cnt}</TableCell>,
              },
              {
                id: 'addCnt',
                title: () => <span />,
                cell: (params) => (
                  <TableCell>
                    <Button
                      onClick={() => {
                        params.modifyData((draft) => {
                          draft.rows[params.rowIndex].cnt++;
                        });
                      }}
                    >
                      +
                    </Button>
                  </TableCell>
                ),
              },
            ]}
            settings={{}}
          />
        </Paper>
      </div>
    </MainLayout>
  );
};

export default SearchResultsDemo;
