import axios from 'axios';
import * as React from 'react';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { APIService } from '../../services/api/src/frontend/api';
import * as applicationState from '../../state/reducers/ApplicationState';
import { setBackgroundImage } from '../../utils';
import AdminTools from '../AdminTools';
// import { adminState } from '../../state/reducers/AdminState';
import Button from '../Common/Button';
import './adminTools.scss';
import { errorMessage } from '../Common/Notifier';
// import { errorMessage, successMessage } from '../Common/Notifier';

const API = APIService(axios);
// const DOC = DocumentService(axios);

interface IMiscProps extends applicationState.IProps {}

interface IMiscState {
  isResettingDB: boolean;
  DBresetted: boolean;
}

type aAprovalCyclesProps = IMiscProps & InjectedIntlProps;

class AbstractMisc extends React.Component<aAprovalCyclesProps, IMiscState> {
  constructor(props: aAprovalCyclesProps) {
    super(props);

    this.state = {
      isResettingDB: false,
      DBresetted: false,
    };
  }

  componentDidMount = async () => {
    setBackgroundImage();
  };

  initializeDB = async () => {
    this.setState({ isResettingDB: true });
    try {
      await API.initializeDB();
      this.setState({ isResettingDB: false, DBresetted: true });
    } catch (e) {
      this.setState({ isResettingDB: false });
      errorMessage(e);
    }
  };

  render() {
    // const t = (id: string) => this.props.intl.formatMessage({ id });

    return (
      <AdminTools>
        <h1>Tämä sivu on näkyvillä vain kehitysympäristössä</h1>

        <div className="grid gridCols3">
          <div className="component master">
            <h2>
              <FormattedMessage id="headers.misc" />
            </h2>
            <div className="content">
              <ul className="miscActions">
                <li>
                  <h3>Alusta tietokanta</h3>
                  <Button onClick={this.initializeDB}>Alusta</Button>
                  <div className="description">
                    {this.state.isResettingDB ? (
                      <p>Alustetaan kantaa...</p>
                    ) : this.state.DBresetted ? (
                      <p>Alustus valmis</p>
                    ) : (
                      <p>Alusta kanta fikstuuridatalla</p>
                    )}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </AdminTools>
    );
  }
}

const Misc = applicationState.StateConnector(AbstractMisc);

export default injectIntl(Misc);
