import * as React from 'react';
import * as io from 'socket.io-client';

import * as container from '../../state/reducers/ApplicationState';
interface ComponentProps extends container.IProps {}
interface ComponentState {
  message: string;
}
const socket = io.connect('/', {
  path: '/health',
});
/**
 * This component is just an example of the health status listener on the UI side.
 *
 */

class Health extends React.Component<ComponentProps, ComponentState> {
  state: ComponentState = { message: '' };

  constructor(props: ComponentProps) {
    super(props);
    this.state = { message: '' };
    this.onData = this.onData.bind(this);
  }

  onData(data: any) {
    if (data.import) {
      console.log(data.import);
    }
    if (data.downmsg) {
      this.setState({ message: data.downmsg });
    }
    if (data.document_categories) {
      // getDocumentCategories
      this.props.getDocumentCategories();
    }
    if (data.update) {
      this.setState({ message: data.update });
    }
  }
  componentDidMount() {
    socket.on('pulse', this.onData);
    socket.emit('latest');
  }
  componentWillUnmount() {
    socket.removeListener('pulse', this.onData);
  }
  render() {
    return this.state.message.length > 2 ? (
      <div className="serviceMessage">{this.state.message}</div>
    ) : null;
  }
}

export default container.StateConnector(Health);
