import * as React from 'react';
import * as container from '../../state/reducers/ApplicationState';
import * as documentState from '../../state/reducers/DocumentState';
import { FormattedMessage } from 'react-intl';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import MainLayout from '../../layout/MainLayout';
import Button from '../Common/Button';
import TextField from '../Common/TextField';
import { ValidatorForm } from 'react-material-ui-form-validator';
import * as Model from '../../../../shared/src/model';
import Edit from '@material-ui/icons/Edit';
import CloudDownload from '@material-ui/icons/CloudDownload';
// import AssignmentTurnedIn from '@material-ui/icons/AssignmentTurnedIn';
import AssignmentLate from '@material-ui/icons/AssignmentLate';
import { Item } from '../Common/AutosuggestChipSelection';
import NoteAdd from '@material-ui/icons/NoteAdd';
import BuildIcon from '@material-ui/icons/Build';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import './document.scss';
import * as _ from 'lodash';
import Loading from '../Common/Loading';
import DateRow from '../Common/DateRow';
import DocumentTextRow from '../Common/DocumentTextRow';

import axios from 'axios';
import { APIService } from '../../services/api/src/frontend/api';
import { DocumentService } from '../../services/document/src/frontend/api';

import ToggleArea from '../Common/ToggleArea';
import FileInput from '../Common/FileInput';
import { push } from 'connected-react-router';

import { withSnackbar, InjectedNotistackProps } from 'notistack';
import { Notifier } from '../Common/Notifier';

import ReactSelect from 'react-select';
import { Async } from '../Common/Async';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {
  successMessage,
  errorMessage,
  warningMessage,
} from '../Common/Notifier';
import DocumentTable from '../Common/DocumentTable';
import DocumentToolbar from '../Common/DocumentToolbar';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core';
import Pdf from '../Common/Preview/Preview';
import Breadcrumbs from '../Common/Breadcrumbs';
import { ValueType } from 'react-select/lib/types';

const API = APIService(axios);
const DOC = DocumentService(axios);

interface IDocumentProps
  extends container.IProps,
    documentState.IProps,
    InjectedNotistackProps {
  savedSearchName: string;
  match: {
    params: {
      documentId: number;
      mode?: string;
    };
  };
  location?: any;
}

interface IDocumentState {
  isEditing: boolean;
  isUploading: boolean;
  isRejecting: boolean;
  contracts: Item[];
  documentCategory: string;
  rejectReason: string;
  file: File | undefined;

  isLoaded: boolean;
  error: any;
  doc?: Model.UIDocument;
  statusHistory?: Model.DocStateHistoryRow[];
  versionHistory?: Model.UIDocument[];
  successMessage?: string;
  selectedDocuments: Model.UIDocument[];
  openPanels: { [key: string]: boolean };
}

type DocumentProps = IDocumentProps & InjectedIntlProps;

class AbstractDocument extends React.Component<DocumentProps, IDocumentState> {
  constructor(props: DocumentProps) {
    super(props);

    this.state = {
      isEditing: false,
      isUploading: false,
      contracts: [],
      documentCategory: '',
      isRejecting: false,
      rejectReason: '',
      file: undefined,
      isLoaded: false,
      error: null,
      selectedDocuments: [],
      openPanels: {},
    };

    this.props.getUserContracts();
  }

  async refreshDocument() {
    try {
      const doc = await DOC.fetchDocument(this.props.match.params.documentId);
      const versionHistory = (
        await DOC.getVersionHistory(this.props.match.params.documentId)
      ).sort((a, b) => {
        // Latest first
        if (b.versionNumber < a.versionNumber) return -1;
        if (b.versionNumber > a.versionNumber) return 1;
        return 0;
      });
      const statusHistory = await DOC.fetchDocumentHistory(
        this.props.match.params.documentId
      );

      this.setState({ isLoaded: true, doc, versionHistory, statusHistory });
      this.props.updateActiveDocument(doc);
    } catch (e) {
      this.setState({ isLoaded: true, error: e });
    }
  }

  async componentDidMount() {
    this.refreshDocument();
  }

  downloadDocument = (activeDocument: Model.UIDocument) => {
    if (document && document.location) {
      document.location.href = `/docs/v1${activeDocument.downloadUrl}`;
    }
  };

  onChangeNewDocument = (files: File[]) => {
    if (files) {
      this.setState({ file: files[0] });
    }
  };

  toState = async (
    activeDocument: Model.UIDocument,
    stateId: number,
    reason: string
  ) => {
    if (this.state.doc) {
      try {
        this.setState({
          isLoaded: false,
        });
        await DOC.changeDocState({
          docid: this.state.doc.id,
          newStateId: stateId,
          reasonForRejection: reason,
        });
        // Then fetch the history
        const versionHistory = (
          await DOC.getVersionHistory(this.state.doc.id)
        ).sort((a, b) => {
          // Latest first
          if (b.versionNumber < a.versionNumber) return -1;
          if (b.versionNumber > a.versionNumber) return 1;
          return 0;
        });
        const statusHistory = await DOC.fetchDocumentHistory(this.state.doc.id);
        this.refreshDocument();
        this.setState({
          versionHistory,
          statusHistory,
          isLoaded: true,
        });
      } catch (e) {
        errorMessage(e);
        this.setState({
          isLoaded: true,
          error: e,
        });
      }
    }
  };

  handleEditMode = () => {
    this.setState({ isEditing: !this.state.isEditing });
  };

  handleUploadNewDocumentMode = () => {
    this.setState({
      isUploading: !this.state.isUploading,
      isEditing: !this.state.isEditing,
    });
  };

  handleCancelEdit = () => {
    if (this.props.activeDocument) {
      this.props.revertDocument(this.props.activeDocument);
    }
    this.setState({ isEditing: false, isUploading: false });
  };

  onChangeDocumentCategoryId = (cat: Item) => {
    if (this.state.doc) {
      const activeDoc = this.state.doc;
      const preserve = this.props.designDisciplines
        .filter((item) =>
          activeDoc.categories
            ? activeDoc.categories.indexOf(item.id) >= 0
            : false
        )
        .map((item) => item.id);
      if (cat) {
        // this.props.activeDocument.documentCategoryId
        this.setKey('categories', [...preserve, cat.value]);
      }
    }
  };
  onChangeDesignDisciplineId = (cat: Item) => {
    if (this.state.doc) {
      const activeDoc = this.state.doc;
      const preserve = this.props.docCategories
        .filter((item) =>
          activeDoc.categories
            ? activeDoc.categories.indexOf(item.id) >= 0
            : false
        )
        .map((item) => item.id);
      if (cat) {
        this.setKey('categories', [...preserve, cat.value]);
      }
    }
  };

  async uploadNewDocumentRevision(file: File) {
    if (!file) return;
    this.setState({
      isLoaded: false,
    });

    const formData = new FormData();
    formData.append('info', JSON.stringify(this.state.doc));
    formData.append('file', file);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };

    try {
      const result = (
        await axios.post('/docs/v1/upload/nextrevision', formData, config)
      ).data as Model.UIDocument[];
      const newDoc = result[0];
      const history = await DOC.fetchDocumentHistory(newDoc.id);
      this.props.DocumentStateDispatcher(push('/documents/' + newDoc.id));
      this.setState({ doc: newDoc, isLoaded: true, statusHistory: history });
      this.props.updateActiveDocument(newDoc);
      successMessage('Dokumentti päivitetty onnistuneesti');
      this.setState({
        isEditing: false,
        isUploading: false,
      });
      this.props.noOp();
    } catch (e) {
      // console.log(e);
      // console.error(e.response);
      // errorMessage('Dokumentti ei ollut päivittynyt onnistuneesti');
      errorMessage(e);
      this.setState({
        error: e,
        isLoaded: true,
      });
      this.props.noOp();
    }
  }

  async saveActiveDocument() {
    try {
      if (this.state.doc) {
        this.setState({
          isLoaded: false,
        });
        const nextDoc = await DOC.updateDocument(this.state.doc);
        this.props.DocumentStateDispatcher(push('/documents/' + nextDoc.id));
        const history = await DOC.fetchDocumentHistory(this.state.doc.id);
        this.setState({
          isLoaded: true,
          doc: nextDoc,
          statusHistory: history,
        });
        successMessage('Dokumentti tallennettu onnistuneesti');
        this.props.updateActiveDocument(this.state.doc);
        this.setState({ isEditing: false });
      }
    } catch (e) {
      this.setState({
        error: e,
        isLoaded: true,
      });
      if (e.message) {
        errorMessage(e.message);
      } else {
        errorMessage(String(e));
      }
    }
  }

  handleSave = () => {
    if (this.state.isUploading) {
      if (this.state.file) {
        this.uploadNewDocumentRevision(this.state.file);
      } else {
        warningMessage('Valitse tiedosto!');
      }
    } else {
      this.saveActiveDocument();
    }
  };

  onRejectReasonChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ rejectReason: e.currentTarget.value });
  };

  setKey<K extends keyof Model.UIDocument>(key: K, value: Model.UIDocument[K]) {
    if (this.state.doc) {
      this.setState({
        doc: {
          ...this.state.doc,
          [key]: value,
        },
      });
    }
  }

  onChangeDocumentBuildingElement = (item: {
    label: string;
    value: number;
  }) => {
    if (this.state.doc) {
      const buildings = this.props.buildingItemTree
        .filter((b) => {
          return b.id === item.value;
        })
        .map((item) => {
          return {
            id: item.id,
            name: item.name!,
            longName: item.name_long!,
          };
        });
      this.setKey('pItems', buildings);
    }
  };

  onChangeDocumentContract = (itemAny: any) => {
    const items: Array<{ label: string; value: number }> = itemAny;
    if (this.state.doc) {
      const cat = _.uniqBy(
        this.props.userContracts.filter((cat) => {
          return (
            items.filter((selItem) => {
              if (cat.aitem_id === selItem.value) {
                return true;
              }
              return false;
            }).length > 0
          );
        }),
        (item: any) => item.aitem_id
      );
      console.log('cat', cat);
      if (cat && cat.length > 0) {
        this.setKey(
          'aItems',
          cat.map((aitem) => ({ id: aitem.aitem_id, name: aitem.aitem_name }))
        );
        this.setKey(
          'contractStr',
          cat.map((aitem) => aitem.aitem_name).join(' ')
        );
      } else {
        this.setKey('aItems', []);
        this.setKey('contractStr', '');
      }
    }
  };

  onDocTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setKey('name', e.currentTarget.value);
  };

  onStarPoleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setKey('startPole', parseInt(e.currentTarget.value, 10));
  };

  onEndPoleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setKey('endPole', parseInt(e.currentTarget.value, 10));
  };

  onDocRevisionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setKey('revision', e.currentTarget.value);
  };

  onDocDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setKey('description', e.currentTarget.value);
  };

  componentDidUpdate() {
    if (this.state.doc && this.state.isLoaded) {
      if (
        parseInt(String(this.props.match.params.documentId), 10) !==
        this.state.doc.id
      ) {
        this.setState(
          {
            isLoaded: false,
          },
          () => {
            this.refreshDocument();
          }
        );
      }
      //
    }
  }

  render() {
    const t = (id: string) => this.props.intl.formatMessage({ id });
    const tMeta = (id: string | undefined | null) =>
      id ? t(`documentMeta.${id}`) : '';
    const { isEditing, isUploading } = this.state;
    const activeDocument = this.state.doc;
    const loading = !this.state.isLoaded;
    const loaded = this.state.isLoaded;

    /*
    const contracts: Item[] = this.props.userContracts.map(contract => {
      return {
        value: contract.aitem_id as number,
        label: contract.aitem_name as string,
      };
    });
    */
    // TODO: This is a wrong privilege to filter by
    const selectedDisciplines = this.props.designDisciplines.filter((cat) => {
      if (this.state.doc && this.state.doc.categories) {
        return this.state.doc.categories.indexOf(cat.id) >= 0;
      }
      return false;
    });

    const selectedCategories = this.props.docCategories.filter((cat) => {
      if (this.state.doc && this.state.doc.categories) {
        return this.state.doc.categories.indexOf(cat.id) >= 0;
      }
      return false;
    });

    const contractorDocumentTypes = this.props.getContractorDocumentypes.filter(
      (cat) => {
        if (this.state.doc && this.state.doc.categories) {
          return this.state.doc.categories.indexOf(cat.id) >= 0;
        }
        return false;
      }
    );

    const contractorWorkTypes = this.props.getContractorWorkTypes.filter(
      (cat) => {
        if (this.state.doc && this.state.doc.categories) {
          return this.state.doc.categories.indexOf(cat.id) >= 0;
        }
        return false;
      }
    );

    const isContractorDoc = contractorWorkTypes.length > 0;
    /*
    const selectedContracts = this.props.appDocumentCategories.filter(cat => {
      if (this.props && this.props.activeDocument) {
        return cat.id === this.props.activeDocument.documentCategoryId;
      }
      return false;
    });
*/

    const notFound = !activeDocument && !loading && !loaded;
    const currentState = this.props.appDocumentStates.filter((s) => {
      return this.state.doc ? this.state.doc.workflowStateId === s.id : false;
    });

    const isAcceptedOrRejected =
      currentState.length > 0 &&
      (currentState[0].name === 'ACCEPTED' ||
        currentState[0].name === 'REJECTED');

    const canEdit =
      activeDocument &&
      activeDocument.privileges &&
      activeDocument.privileges.canUpdate;

    const kohdeTyyppi = this.props.pitemTypes
      .filter((t) => t.name === 'Kohde')
      .pop();

    const docPackageType = this.props.appDocumentCategories
      .filter((t) => t.name === 'URAK_DP')
      .pop();

    const getPitemTypename = (id: number, useKohde = true): string => {
      const possibleType = this.props.pitemTypes
        .filter((t) => t.id === id)
        .pop();
      if (!possibleType) {
        return '';
      }
      if (
        useKohde &&
        kohdeTyyppi &&
        possibleType.upper_type_id === kohdeTyyppi.id
      ) {
        return 'Kohde';
      }
      return possibleType.name || '';
    };

    let pitemTree: Model.DBPitem[] = [];
    let primaryTypeId = 0;
    let isDocPackage = false;
    if (activeDocument && activeDocument.pitemTree) {
      const keys = Object.keys(activeDocument.pitemTree);
      if (keys.length > 0) {
        pitemTree = activeDocument.pitemTree[keys[0]];
        if (pitemTree.length > 0) {
          const typeIdValues = pitemTree[pitemTree.length - 1].pitemTypeIds;
          if (typeIdValues && typeIdValues.length > 0) {
            primaryTypeId = typeIdValues[0];
          }
        }
      }
      if (activeDocument.categories && docPackageType) {
        isDocPackage =
          activeDocument.categories.indexOf(docPackageType.id) >= 0;
      }
    }

    const createDocumentTable = (
      data: Model.UIDocument[],
      reload: () => void
    ) => {
      return (
        <DocumentTable
          hasSelect={true}
          keys={{
            name: 'Nimi',
            fileName: 'Tiedosto',
            categories: 'Kategoriat',
            workflowStateName: 'Tila',
          }}
          actionComponents={[
            <DocumentToolbar
              didUpdateDocuments={() => {
                reload();
              }}
              key={'toolbar1'}
              selectedDocuments={this.state.selectedDocuments}
            />,
          ]}
          setSelected={(ids) => {
            this.setState({
              selectedDocuments: data
                ? data.filter((doc) => {
                    return ids.indexOf(doc.id) >= 0;
                  })
                : [],
            });
          }}
          rowClick={(doc) => {
            const selectedDoc = (data || []).filter((row) => row.id === doc.id);
            if (selectedDoc.length === 0) {
              return;
            }
            this.setState({
              openPanels: {},
            });
            this.props.DocumentStateDispatcher(
              push('/documents/' + selectedDoc[0].id)
            );
          }}
          rows={(data || [])
            .filter((doc) => {
              if (doc.categories && docPackageType) {
                return !(doc.categories.indexOf(docPackageType.id) >= 0);
              }
              return true;
            })
            .map((doc) => {
              return {
                ...doc,
                categories: (
                  <span>
                    {doc.categories
                      ? doc.categories.map((c) => {
                          const cType = this.props.getContractorDocumentypes.filter(
                            (t) => t.id === c
                          );
                          if (cType.length === 0) return '';
                          return cType[0].name;
                        })
                      : null}
                  </span>
                ),
              };
            })}
        />
      );
    };

    let name = '';
    const isDownloadable =
      !isDocPackage && activeDocument && activeDocument.canBeDownloaded;

    if (activeDocument) {
      if (isDocPackage && activeDocument.target) {
        name = activeDocument.target.name;
      } else {
        name = activeDocument.name;
      }
    }
    return (
      <MainLayout>
        <Breadcrumbs>
          <Link to="/">
            <FormattedMessage id="headers.home" />
          </Link>
          <FormattedMessage id="headers.documents" />
          {activeDocument && (
            <Link to={`/documents/${activeDocument.id}`}>{name}</Link>
          )}
        </Breadcrumbs>

        {this.state.successMessage ? (
          <Notifier
            snack={{
              variant: 'success',
              message: this.state.successMessage,
            }}
          />
        ) : null}
        <div className={isContractorDoc ? 'grid gridCols3' : 'grid gridCols2'}>
          {notFound && <FormattedMessage id="warning.documentNotFound" />}
          {loading ? <Loading loading={true} /> : null}

          {activeDocument && (
            <div className="documentMetaContainer">
              <div className="documentMeta component">
                <h2>
                  <span>{name}</span>
                </h2>

                <div className="actions">
                  {canEdit && (
                    <>
                      {!isAcceptedOrRejected ? (
                        <Button
                          disabled={isUploading}
                          variant="text"
                          color="primary"
                          onClick={this.handleEditMode}
                        >
                          <Edit
                            style={{ marginTop: '-3px', marginRight: '8px' }}
                          />
                          <FormattedMessage id="buttons.edit" />
                        </Button>
                      ) : null}
                      <Button
                        disabled={isEditing && !isUploading}
                        variant="text"
                        color="primary"
                        onClick={this.handleUploadNewDocumentMode}
                      >
                        <NoteAdd
                          style={{ marginTop: '-3px', marginRight: '8px' }}
                        />
                        <FormattedMessage id="documentMeta.updateRevision" />
                      </Button>
                    </>
                  )}

                  {isDownloadable ? (
                    <Button
                      variant="text"
                      color="primary"
                      onClick={() => this.downloadDocument(activeDocument)}
                    >
                      <CloudDownload
                        style={{ marginTop: '-3px', marginRight: '8px' }}
                      />
                      <FormattedMessage id="documentMeta.downloadLink" />
                    </Button>
                  ) : null}

                  {this.props.appDocumentActions.map((field) => {
                    if (activeDocument.allowedActions.indexOf(field.id) < 0) {
                      return null;
                    }
                    return (
                      <Button
                        key={field.action_name!}
                        variant="text"
                        color="primary"
                        onClick={() => {
                          if (field.id === 6) {
                            this.setState({ isRejecting: true });
                          } else {
                            this.toState(
                              activeDocument,
                              field.to_state_id!,
                              ''
                            );
                          }
                        }}
                      >
                        <AssignmentLate
                          style={{ marginTop: '-3px', marginRight: '8px' }}
                        />
                        {t(field.action_name!)}
                      </Button>
                    );
                  })}
                </div>

                <div className="content">
                  {this.state.isRejecting ? (
                    <div className="isRejecting">
                      <h3>
                        <FormattedMessage id="documentMeta.rejectReason" />
                      </h3>
                      <div>
                        <TextField
                          multiline={true}
                          value={this.state.rejectReason}
                          onChange={this.onRejectReasonChange}
                        />
                      </div>
                      <div className="editActions">
                        <Button
                          color="primary"
                          type="submit"
                          // Disable save button when no reject reason is given
                          disabled={!this.state.rejectReason.trim()}
                          onClick={() => {
                            this.setState({ isRejecting: false });
                            this.toState(
                              activeDocument,
                              6,
                              this.state.rejectReason
                            );
                          }}
                        >
                          <FormattedMessage id="buttons.save" />
                        </Button>
                        <Button
                          color="primary"
                          onClick={(e) => this.setState({ isRejecting: false })}
                        >
                          <FormattedMessage id="buttons.cancel" />
                        </Button>
                      </div>
                    </div>
                  ) : null}

                  <ValidatorForm onSubmit={this.handleSave}>
                    {isUploading && isEditing && (
                      <FileInput
                        style={{ height: '80px' }}
                        id="updateDocument"
                        single={true}
                        label={t('upload.dropzoneInfoSingle')}
                        onChange={this.onChangeNewDocument}
                      />
                    )}

                    <dl>
                      <dt>
                        <FormattedMessage id="documentMeta.name" />
                      </dt>
                      <dd>
                        {isEditing ? (
                          <TextField
                            value={activeDocument.name}
                            onChange={this.onDocTitleChange}
                          />
                        ) : (
                          activeDocument.name
                        )}
                      </dd>
                      {isContractorDoc ? (
                        <>
                          {contractorDocumentTypes.length > 0 ? (
                            <>
                              <dt>
                                <FormattedMessage id="documentMeta.contractorDocumentType" />
                              </dt>
                              <dd>
                                {contractorDocumentTypes.map((t) => t.name)}
                              </dd>
                            </>
                          ) : null}
                          <dt>
                            <FormattedMessage id="documentMeta.contractorWorkType" />
                          </dt>
                          <dd>
                            {contractorWorkTypes.length > 0
                              ? contractorWorkTypes.map((t) => t.name)
                              : '-'}
                          </dd>
                          {pitemTree.map((pItem) => {
                            return (
                              <div key={pItem.name}>
                                <dt>
                                  {pItem.pitemTypeIds &&
                                  pItem.pitemTypeIds.length > 0
                                    ? getPitemTypename(pItem.pitemTypeIds[0])
                                    : 'Kohde'}
                                </dt>
                                <dd>{pItem.name_long}</dd>
                              </div>
                            );
                          })}
                          {pitemTree.length > 0 ? (
                            <>
                              <dt>
                                <FormattedMessage id="documentMeta.pitemType" />
                              </dt>
                              <dd>{getPitemTypename(primaryTypeId, false)}</dd>
                            </>
                          ) : null}
                        </>
                      ) : null}
                      {isContractorDoc ? null : (
                        <>
                          <dt>
                            <FormattedMessage id="documentMeta.documentCategory" />
                          </dt>
                          <dd>
                            {isEditing ? (
                              <ReactSelect
                                value={selectedCategories
                                  .map((item) => ({
                                    value: item.id,
                                    label: item.name,
                                  }))
                                  .pop()}
                                options={this.props.docCategories.map(
                                  (item) => ({
                                    value: item.id,
                                    label: item.name,
                                  })
                                )}
                                onChange={(value: ValueType<Model.Item>) => {
                                  if (value) {
                                    if (!Array.isArray(value)) {
                                      this.onChangeDocumentCategoryId(
                                        value as Model.Item
                                      );
                                    }
                                  }
                                }}
                              />
                            ) : (
                              ((): string => {
                                try {
                                  return this.props.docCategories.filter(
                                    (cat) =>
                                      activeDocument.categories
                                        ? activeDocument.categories.indexOf(
                                            cat.id
                                          ) >= 0
                                        : false
                                  )[0].name;
                                } catch (e) {
                                  return '-';
                                }
                              })()
                            )}
                          </dd>

                          <dt>
                            <FormattedMessage id="documentMeta.designDiscipline" />
                          </dt>
                          <dd>
                            {isEditing ? (
                              <ReactSelect
                                value={selectedDisciplines
                                  .map((item) => ({
                                    value: item.id,
                                    label: item.name,
                                  }))
                                  .pop()}
                                options={this.props.designDisciplines.map(
                                  (item) => ({
                                    value: item.id,
                                    label: item.name,
                                  })
                                )}
                                onChange={(value: ValueType<Model.Item>) => {
                                  if (value) {
                                    if (!Array.isArray(value)) {
                                      this.onChangeDesignDisciplineId(
                                        value as Model.Item
                                      );
                                    }
                                  }
                                }}
                              />
                            ) : (
                              ((): string => {
                                try {
                                  return this.props.designDisciplines.filter(
                                    (cat) =>
                                      activeDocument.categories
                                        ? activeDocument.categories.indexOf(
                                            cat.id
                                          ) >= 0
                                        : false
                                  )[0].name;
                                } catch (e) {
                                  return '-';
                                }
                              })()
                            )}
                          </dd>
                        </>
                      )}
                      <dt>
                        <FormattedMessage id="documentMeta.workflowState" />
                      </dt>
                      <dd>{tMeta(activeDocument.workflowStateName)}</dd>
                      <dt>
                        <FormattedMessage id="documentMeta.createdByUser" />
                      </dt>
                      <dd>{activeDocument.createdByUser || '-'}</dd>
                      {/* <dd>{document.createdByUserId}</dd> */}

                      <DateRow
                        id="documentMeta.created"
                        value={activeDocument.created}
                      />
                      <DateRow
                        id="documentMeta.modified"
                        value={activeDocument.modified}
                      />

                      <dt>
                        <FormattedMessage id="documentMeta.contract" />
                      </dt>
                      <dd>
                        {activeDocument.aItems
                          .map((item) => item.name)
                          .join(' ') || '-'}
                      </dd>

                      <dt>
                        <FormattedMessage id="documentMeta.poleInterval" />
                      </dt>
                      <dd>
                        {isEditing ? (
                          <>
                            <TextField
                              label={t('documentMeta.startPole')}
                              defaultValue={activeDocument.startPole}
                              onChange={this.onStarPoleChange}
                            />
                            <TextField
                              label={t('documentMeta.endPole')}
                              defaultValue={activeDocument.endPole}
                              onChange={this.onEndPoleChange}
                            />
                          </>
                        ) : (
                          <span>
                            {/* Stop cap fix for demo */}
                            {activeDocument.startPole
                              ? `${activeDocument.startPole} - ${activeDocument.endPole}`
                              : '-'}
                          </span>
                        )}
                      </dd>

                      {isEditing && !isContractorDoc ? (
                        <>
                          <dt>
                            <FormattedMessage id="documentMeta.buildingElement" />
                          </dt>
                          <dd>
                            <Async
                              loader={async (): Promise<
                                Array<{ label: string; value: number }>
                              > => {
                                const items = await API.getBuildingsForContracts(
                                  activeDocument.aItems.map((item) => item.id)
                                );
                                this.props.assignBuildingElementTree(items);
                                return items.map((item) => ({
                                  value: item.id,
                                  label: item.name_long!,
                                }));
                              }}
                              placeholder={<Loading loading={true} />}
                              render={(
                                value?: Array<{ label: string; value: number }>
                              ) => (
                                <ReactSelect
                                  value={activeDocument.pItems.map(
                                    (building) => ({
                                      label: building.longName,
                                      value: building.id,
                                    })
                                  )}
                                  options={value}
                                  onChange={(value: ValueType<Model.Item>) => {
                                    if (value) {
                                      if (!Array.isArray(value)) {
                                        this.onChangeDocumentBuildingElement(
                                          value as Model.Item
                                        );
                                      }
                                    }
                                  }}
                                />
                              )}
                            />
                          </dd>
                        </>
                      ) : !isContractorDoc ? (
                        <DocumentTextRow
                          id="documentMeta.buildingElement"
                          value={activeDocument.pItems
                            .map((item) => item.longName)
                            .join(', ')}
                        />
                      ) : null}

                      <DocumentTextRow
                        id={'documentMeta.version'}
                        value={String(activeDocument.versionNumber)}
                      />

                      <dt>
                        <FormattedMessage id="documentMeta.revision" />
                      </dt>
                      <dd>
                        {isEditing ? (
                          <TextField
                            value={activeDocument.revision}
                            onChange={this.onDocRevisionChange}
                          />
                        ) : (
                          activeDocument.revision || '-'
                        )}
                      </dd>

                      <dt>
                        <FormattedMessage id="documentMeta.description" />
                      </dt>
                      <dd>
                        {isEditing ? (
                          <TextField
                            multiline={true}
                            value={activeDocument.description || ''}
                            onChange={this.onDocDescriptionChange}
                          />
                        ) : (
                          activeDocument.description || '-'
                        )}
                      </dd>
                    </dl>

                    {isEditing && (
                      <div className="editActions">
                        <Button color="primary" onClick={this.handleCancelEdit}>
                          <FormattedMessage id="buttons.cancel" />
                        </Button>

                        <Button color="primary" type="submit">
                          <FormattedMessage id="buttons.save" />
                        </Button>
                      </div>
                    )}
                  </ValidatorForm>
                </div>

                <ToggleArea title={t('documentMeta.statusHistory')}>
                  <ol>
                    {this.state.statusHistory &&
                      this.state.statusHistory.map((historyItem) => (
                        <li key={historyItem.id}>
                          <div className="metadata">
                            <span className="date">
                              <Moment
                                date={historyItem.dateStr}
                                format="DD.MM.YYYY"
                              />
                            </span>

                            <span title={tMeta(historyItem.newStateName)}>
                              {tMeta(historyItem.newStateName)}
                            </span>

                            <span title={historyItem.userName}>
                              {historyItem.userName}
                            </span>
                          </div>

                          {historyItem.reasonForRejection ? (
                            <div className="reasonForRejection">
                              <h3>
                                <FormattedMessage id="documentMeta.reasonForRejection" />
                              </h3>
                              <p>{historyItem.reasonForRejection}</p>
                            </div>
                          ) : null}
                        </li>
                      ))}
                  </ol>
                </ToggleArea>

                {this.state.doc && (
                  <ToggleArea title={t('documentMeta.versionHistory')}>
                    <ol>
                      {this.state.versionHistory &&
                        this.state.versionHistory.map((historyItem, index) => (
                          <li key={index}>
                            <div className="metadata">
                              <span>{historyItem.versionNumber}</span>
                              <span className="date">
                                <Moment
                                  date={historyItem.created}
                                  format="DD.MM.YYYY HH:mm"
                                />
                              </span>

                              <span title={historyItem.fileName}>
                                <Link to={`/documents/${historyItem.id}`}>
                                  {historyItem.fileName}
                                </Link>
                              </span>

                              <span title={historyItem.createdByUser}>
                                {historyItem.createdByUser}
                              </span>
                            </div>
                          </li>
                        ))}
                    </ol>
                  </ToggleArea>
                )}
              </div>
            </div>
          )}

          {!isContractorDoc || !activeDocument ? null : (
            <div className="documentPackageMetadata component">
              <Async
                loader={async () => {
                  return DOC.documentSearchRequest({
                    itemIds: activeDocument.pItems.map((item) => item.id),
                    // contractorWorkTypes: contractorWorkTypes.map(t => t.id),
                    contractFilter: activeDocument.aItems.map((a) => a.id),
                  });
                }}
                render={(data, reload) => {
                  if (!data) {
                    return <div />;
                  }
                  const groups = _.groupBy(data ? data.rows : [], (value) => {
                    if (typeof value === 'object' && value) {
                      const typeList = this.props.getContractorWorkTypes.filter(
                        (t) => {
                          if (!value.categories) {
                            return false;
                          }
                          return value.categories.indexOf(t.id) >= 0;
                        }
                      );
                      if (typeList.length === 1) {
                        return typeList[0].name;
                      }
                    }
                    return '';
                  });

                  const keys = Object.keys(groups);

                  return (
                    <>
                      {keys.map((key) => {
                        const docs = groups[key];
                        const sampleIds =
                          docs.length > 0 ? docs[0].categories || [] : [];

                        const isExpanded =
                          this.state.openPanels[key] ||
                          (contractorWorkTypes &&
                            _.intersection(
                              sampleIds,
                              contractorWorkTypes.map((t) => t.id)
                            ).length > 0)
                            ? true
                            : false;

                        if (!isExpanded) {
                          return null;
                        }
                        return (
                          <ExpansionPanel key={key} expanded={isExpanded}>
                            <ExpansionPanelSummary
                              expandIcon={<ExpandMoreIcon />}
                              onClick={() => {
                                this.setState({
                                  openPanels: {
                                    ...this.state.openPanels,
                                    [key]: !this.state.openPanels[key],
                                  },
                                });
                              }}
                            >
                              <span>
                                <BuildIcon
                                  style={{
                                    height: '0.9em',
                                    marginRight: '10px',
                                  }}
                                />
                                {key}
                              </span>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                              {createDocumentTable(docs || [], reload)}
                            </ExpansionPanelDetails>
                          </ExpansionPanel>
                        );
                      })}
                    </>
                  );
                }}
              />
              {/*
                                <ExpansionPanel>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    {getAItemName(first.aitem_id) +
                      ' ' +
                      getPItemName(first.pitem_id)}
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails> */}
            </div>
          )}

          {activeDocument && <Pdf doc={activeDocument} />}
        </div>
      </MainLayout>
    );
  }
}

export default documentState.StateConnector(
  container.StateConnector(injectIntl(withSnackbar(AbstractDocument)))
);
