import {
  APLoginCredidentials,
  APLoginStatus,
  NewUserFields,
  PasswordToken,
  Passwords,
  UserPw,
  LDAPUser,
  PasswordResetFieldsAdmin,
  TokenValidityResponse,
} from '../../../../../shared/src/model';
import * as schema from '../../../../../shared/src/model/projectSchema';

/**
 * @client auth
 */
export function AuthService(axios: any) {
  return new class AssetPointAPI {
    // client for endpoint login
    async login(loginData:APLoginCredidentials) : Promise<string> {
      return (await axios.post(`/auth/v1/login/`,loginData)).data;
    }
    // client for endpoint signout
    async signout() : Promise<void> {
      return (await axios.get(`/auth/v1/signout/`,{params:{}})).data;
    }
    // client for endpoint loginStatus
    async loginStatus() : Promise<APLoginStatus> {
      return (await axios.get(`/auth/v1/loginStatus/`,{params:{}})).data;
    }
    // client for endpoint getUserDetails
    async getUserDetails() : Promise<schema.user & {
    requiredAgreements?: schema.user_agreements[];
    email?: string;
    }> {
      return (await axios.get(`/auth/v1/getUserDetails/`,{params:{}})).data;
    }
    // client for endpoint agreedAgreements
    async agreedAgreements(ids:number[])  {
      return (await axios.post(`/auth/v1/agreedAgreements/`,ids)).data;
    }
    // client for endpoint getAgreedAgreements
    async getAgreedAgreements() : Promise<Array<schema.user_agreements & {
    agreed_at: Date;
    }>> {
      return (await axios.get(`/auth/v1/getAgreedAgreements/`,{params:{}})).data;
    }
    // client for endpoint changePassword
    async changePassword(passwords:Passwords)  {
      return (await axios.post(`/auth/v1/changePassword/`,passwords)).data;
    }
    // client for endpoint changePasswordWithToken
    async changePasswordWithToken(data:PasswordToken)  {
      return (await axios.post(`/auth/v1/changePasswordWithToken/`,data)).data;
    }
    // client for endpoint getAllUsers
    async getAllUsers() : Promise<LDAPUser[]> {
      return (await axios.get(`/auth/v1/getAllUsers/`,{params:{}})).data;
    }
    // client for endpoint requestPasswordChangeLink
    async requestPasswordChangeLink(email:string) : Promise<void> {
      return (await axios.post(`/auth/v1/requestPasswordChangeLink/${email}/`,)).data;
    }
    // client for endpoint requestPasswordChangeLinkAdmin
    async requestPasswordChangeLinkAdmin(params:PasswordResetFieldsAdmin) : Promise<void> {
      return (await axios.post(`/auth/v1/requestPasswordChangeLinkAdmin/`,params)).data;
    }
    // client for endpoint createNewUser
    async createNewUser(user:NewUserFields) : Promise<{}> {
      return (await axios.post(`/auth/v1/createNewUser/`,user)).data;
    }
    // client for endpoint checkTokenValidity
    async checkTokenValidity(token:string) : Promise<TokenValidityResponse> {
      return (await axios.get(`/auth/v1/checkTokenValidity/${token}/`,{params:{}})).data;
    }
    // client for endpoint createNewUserFromLdap
    async createNewUserFromLdap(user:LDAPUser) : Promise<schema.user> {
      return (await axios.post(`/auth/v1/createNewUserFromLdap/`,user)).data;
    }
    // client for endpoint getServiceDown
    async getServiceDown()  {
      return (await axios.get(`/auth/v1/getServiceDown/`,{params:{}})).data;
    }
    // client for endpoint setServiceDown
    async setServiceDown(param:{
    down: boolean;
    })  {
      return (await axios.post(`/auth/v1/setServiceDown/`,param)).data;
    }
    // client for endpoint runTests
    async runTests() : Promise<boolean> {
      return (await axios.get(`/auth/v1/runTests/`,{params:{}})).data;
    }
    // client for endpoint createTestUserPool
    async createTestUserPool(pwForAll:UserPw) : Promise<any> {
      return (await axios.post(`/auth/v1/createTestUserPool/`,pwForAll)).data;
    }
    // client for endpoint ping
    async ping(message:string)  {
      return (await axios.get(`/auth/v1/ping/${message}/`,{params:{}})).data;
    }
  }
}
