import * as React from 'react';
import * as container from '../../state/reducers/ApplicationState';
import { FormattedMessage, injectIntl, InjectedIntlProps } from 'react-intl';

import './login.scss';

import PublicLayout from '../../layout/PublicLayout';
import TextField from '../Common/TextField';
import PasswordField from '../Common/PasswordField';
import Button from '../Common/Button';
import { Redirect, Link } from 'react-router-dom';
import { ValidatorForm } from 'react-material-ui-form-validator';

interface ILoginProps extends container.IProps {}

interface ILoginState {
  username: string;
  password: string;
  showPassword: boolean;
}

type LoginProps = ILoginProps & InjectedIntlProps;

class AbstractLogin extends React.Component<LoginProps, ILoginState> {
  constructor(props: LoginProps) {
    super(props);

    this.state = { username: '', password: '', showPassword: false };
  }

  componentDidMount() {
    this.props.getSystemState();
  }

  onUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ username: e.currentTarget.value });
  };

  onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ password: e.currentTarget.value });
  };

  handleSubmit = (e: any) => {
    this.props.login({
      username: this.state.username,
      password: this.state.password,
    });
  };

  public render() {
    const t = this.props.intl;

    const LoginError = () => {
      return (
        <div className="loginError">
          <FormattedMessage id="login.loginError" />
        </div>
      );
    };

    if (this.props.loginStatus === container.LoginStatus.Logged) {
      return <Redirect to={'/'} />;
    }

    const { systemDown } = this.props;

    return (
      <PublicLayout>
        <div className="grid gridCols3">
          <div className="loginBanner">
            <img src="/images/login_banner.svg" />
          </div>
          <div className="login component">
            <h2>
              <FormattedMessage id="login.header" />
            </h2>
            <div className="content">
              {systemDown && (
                <div className="loginError">
                  <FormattedMessage id="login.systemDown" />
                </div>
              )}
              <ValidatorForm onSubmit={this.handleSubmit}>
                <TextField
                  label={t.formatMessage({
                    id: 'login.username',
                  })}
                  defaultValue={this.state.username}
                  onChange={this.onUsernameChange}
                />
                <PasswordField
                  name="password"
                  label={t.formatMessage({
                    id: 'login.password',
                  })}
                  defaultValue={this.state.password}
                  onChange={this.onPasswordChange}
                />
                <Button color="secondary" type="submit">
                  <FormattedMessage id="login.login" />
                </Button>
              </ValidatorForm>

              <Link to="password-reset" className="footerLink">
                <FormattedMessage id="login.forgotPassword" />
              </Link>
            </div>
            {this.props.loginStatus === container.LoginStatus.Failed && (
              <LoginError />
            )}
          </div>
          <Link className="GDPRLink" to="/gdpr">
            <FormattedMessage id="login.GDPRLink" />
          </Link>
        </div>
      </PublicLayout>
    );
  }
}

const Login = container.StateConnector(AbstractLogin);

export default injectIntl(Login);
