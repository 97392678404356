import * as React from 'react';
interface Props<T> {
  placeholder?: JSX.Element;
  loader: () => Promise<T>;
  render: (
    value: T | undefined,
    reload: (newLoader?: () => Promise<T>) => void
  ) => JSX.Element;
  ms?: number;
  uri?: any;
}

interface State<T> {
  newLoader?: () => Promise<T>;
  isLoaded: boolean;
  isLoading: boolean;
  hadError: boolean;
  value?: Promise<T>;
  unwrappedValue?: T;
}

export class Async<T> extends React.Component<Props<T>, State<T>> {
  state: State<T> = { isLoaded: false, hadError: false, isLoading: false };

  constructor(props: Props<T>) {
    super(props);
  }

  reloadData() {
    if (this.state) {
      if (this.state.isLoading || this.state.hadError) {
        return;
      }
    }

    const value = (this.state.newLoader || this.props.loader)();

    this.setState({
      value,
      isLoading: true,
    });

    let isReady = true;
    let setStateTo: any = null;
    if (this.props.ms) {
      isReady = false;
      setTimeout(() => {
        isReady = true;
        if (setStateTo) {
          this.setState(setStateTo);
        }
      }, this.props.ms);
    }
    value
      .then(realValue => {
        if (isReady) {
          this.setState({
            isLoaded: true,
            isLoading: false,
            unwrappedValue: realValue,
          });
        } else {
          setStateTo = {
            isLoaded: true,
            isLoading: false,
            unwrappedValue: realValue,
          };
        }
      })
      .catch(e => {
        console.error(e);
        this.setState({
          hadError: true,
        });
      });
  }

  componentDidMount() {
    this.reloadData();
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.uri !== prevProps.uri) {
      this.reloadData();
    }
  }

  render() {
    if (!this.state.value) {
      return null;
    }
    return (
      <>
        {!this.state.isLoaded
          ? this.props.placeholder || null
          : this.props.render(
              this.state.unwrappedValue,
              (newLoader?: () => Promise<T>) => {
                if (!newLoader) {
                  this.reloadData();
                  return;
                }
                this.setState(
                  {
                    newLoader,
                  },
                  () => {
                    this.reloadData();
                  }
                );
              }
            )}
      </>
    );
  }
}
