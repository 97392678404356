import './index.scss';

import * as React from 'react';
import * as _ from 'lodash';
import * as applicationState from '../../state/reducers/ApplicationState';
import * as documentState from '../../state/reducers/DocumentState';

import { UIDocument, SearchField } from '../../../../shared/src/model/index';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { Link, Redirect } from 'react-router-dom';

import Breadcrumbs from '../Common/Breadcrumbs';
import Button from '../Common/Button';
import Loading from '../Common/Loading';
import MainLayout from '../../layout/MainLayout';
import SearchResultsTable from './SearchResultsTable';
import { errorMessage } from 'components/Common/Notifier';
import { downloadFromURL } from '../../utils';

import axios, { CancelTokenSource } from 'axios';

// import AssignmentLate from '@material-ui/icons/AssignmentLate';

interface ISearchResultsProps
  extends documentState.IProps,
    applicationState.IProps {
  savedSearchName: string;
  type?: 'items' | 'documents';
}

interface ISearchResultsState {
  results: UIDocument[];
  navigateToDocument: boolean;
  selectedDocument?: UIDocument;
  selectedDocuments: UIDocument[];
  lastOrder: 'ASC' | 'DESC';
  type: 'items' | 'documents';
  trackedItems: number[];
  downloadingExcel: boolean;
  cancelToken: CancelTokenSource;
}

type SearchResultsProps = ISearchResultsProps & InjectedIntlProps;

class AbstractSearchResults extends React.Component<
  SearchResultsProps,
  ISearchResultsState
> {
  constructor(props: SearchResultsProps) {
    super(props);

    this.state = {
      results: [],
      navigateToDocument: false,
      selectedDocument: undefined,
      selectedDocuments: [],
      lastOrder: 'ASC',
      type: this.props.type || 'documents',
      trackedItems: [],
      downloadingExcel: false,
      cancelToken: axios.CancelToken.source(),
    };
  }

  componentDidUpdate(prevProps: SearchResultsProps) {
    const oldResults = prevProps.searchResults;
    const results = this.props.searchResults;

    if (results !== oldResults && results.length > 0) {
      if (this.state.downloadingExcel) {
        this.state.cancelToken.cancel();
        this.setState({
          downloadingExcel: false,
          cancelToken: axios.CancelToken.source(),
        });
      }

      this.setState({
        trackedItems: results
          .filter((pitem) => pitem.isTracked)
          .map((trackedPitem) => trackedPitem.id),
      });
    }
  }
  componentWillUnmount() {
    if (this.state.downloadingExcel) this.state.cancelToken.cancel();
  }

  handleRedirectToDocument = (
    document: UIDocument,
    type: 'documents' | 'items'
  ) => {
    this.setState({
      selectedDocument: document,
      type,
      navigateToDocument: true,
    });
  };

  onDownloadAsSpreadsheet = _.throttle(async (tooManyResultsError: string) => {
    this.setState({
      downloadingExcel: true,
    });
    const searchWithoutLimit = {
      ...this.props.activeSearch,
      cursor: { offset: 0, limit: 1000 * 1000 },
    };
    const query = encodeURIComponent(JSON.stringify(searchWithoutLimit));
    if (document && document.location) {
      try {
        await downloadFromURL(
          `/docs/v1/downloadSearchAsExcel/?searchRequest=${query}`,
          'METTI_search_export.xlsx',
          this.state.cancelToken.token
        );
      } catch (e) {
        if (!axios.isCancel(e)) {
          errorMessage(tooManyResultsError);
        }
      }
    }
    this.setState({
      downloadingExcel: false,
    });
  }, 1000);

  onDownloadAsZip = _.throttle(() => {
    const query = encodeURIComponent(JSON.stringify(this.props.activeSearch));
    if (document && document.location) {
      window.open(
        `/docs/v1/downloadSearchAsZip/?searchRequest=${query}`,
        '_tab'
      );
    }
  }, 1000);

  onSearchResultsPageChange = (currentPage: number) => {
    this.props.updateSearchState({ currentPage });
  };

  renderTrackedDot(doc: UIDocument, isAdmin: boolean) {
    if (isAdmin) {
      return (
        <div
          onClick={(e) => {
            e.stopPropagation();
            this.props.togglePitemTrackedStatus(doc);
          }}
        >
          <span className={`dot ${doc.isTracked ? 'red' : 'notSet'}`} />
        </div>
      );
    } else {
      return (
        <div>
          <span className={`dot ${doc.isTracked ? 'red' : 'transparent'}`} />
        </div>
      );
    }
  }

  flipOrder = (): 'ASC' | 'DESC' => {
    const flipped = this.state.lastOrder === 'ASC' ? 'DESC' : 'ASC';
    this.setState({ lastOrder: flipped });
    return flipped;
  };

  render() {
    const t = (id: string) => this.props.intl.formatMessage({ id });
    const { defaultRowCount } = this.props.userSettings;
    const { loading } = this.props.docstatus.searchRequest;

    const resultsWithType = this.props.searchResults.filter((doc) => doc.type);
    const resultType =
      resultsWithType.length > 0 ? resultsWithType[0].type! : 'document';
    const savedSearchName = this.props.activeSearch
      ? this.props.activeSearch.name
      : '';

    if (this.state.navigateToDocument && this.state.selectedDocument) {
      const { selectedDocument } = this.state;
      let pathname = `/documents/${selectedDocument.id}`;
      if (
        selectedDocument.categoryNames &&
        selectedDocument.categoryNames.indexOf('TARKASTUSASIAKIRJA_DOC') !== -1
      ) {
        pathname = `/inspection-documents/target/${selectedDocument.parentId}/attachment/${selectedDocument.id}`;
      }
      if (this.state.type === 'items') {
        pathname = `/items/${selectedDocument.id}`;
      }

      return (
        <Redirect
          to={{
            pathname,
            state: {
              document: this.props.searchResults
                .filter((doc) => doc.id === selectedDocument.id)
                .pop(),
            },
          }}
          push={true}
        />
      );
    }

    return (
      <MainLayout>
        <Breadcrumbs>
          <Link to="/">
            <FormattedMessage id="headers.home" />
          </Link>
          <FormattedMessage id="search.resultsHeader" />
        </Breadcrumbs>

        <h1>
          <FormattedMessage id="search.resultsHeader" />
        </h1>

        <div className="grid gridCol1">
          <div>
            {loading ? (
              <div>
                <Loading loading={true} />
              </div>
            ) : this.props.searchResults.length === 0 ? (
              <p>
                <FormattedMessage id="search.noSearchResults" />
              </p>
            ) : null}
          </div>

          {this.props.searchResults.length > 0 &&
          !this.props.docstatus.searchRequest.loading ? (
            <>
              <div className="fileListActions">
                {/*
                // Enable this if customer needs it...
                <Button onClick={this.onDownloadAsZip}>
                  <FormattedMessage id="search.downloadAsZip" />
                </Button>
                */}
                <Button
                  onClick={() => {
                    if (!this.state.downloadingExcel)
                      this.onDownloadAsSpreadsheet(
                        t('errorMessage.searchTooLarge')
                      );
                  }}
                >
                  {this.state.downloadingExcel ? (
                    <FormattedMessage id="messages.loading" />
                  ) : (
                    <FormattedMessage id="search.downloadAsSpreadsheet" />
                  )}
                </Button>
              </div>
              <SearchResultsTable
                resultsLoading={
                  this.props.docstatus.togglePitemTrackedStatus.loading
                }
                resultType={resultType}
                searchMainCategoryName={
                  this.props.activeSearch.searchMainCategoryName
                }
                onSearchResultsPageChange={this.onSearchResultsPageChange}
                currentPage={this.props.searchState.currentPage}
                defaultRowCount={defaultRowCount}
                reload={() => {
                  this.props.documentSearch(this.props.activeSearch);
                }}
                onAskMoreData={() => {
                  this.props.loadMoreDocuments(this.props.activeSearch);
                }}
                contents={this.props.searchResults}
                title={
                  <div>
                    {savedSearchName ? (
                      savedSearchName
                    ) : (
                      <FormattedMessage id="search.resultsHeader" />
                    )}
                  </div>
                }
                estimatedCnt={this.props.searchResultCnt}
                handleRedirectToDocument={this.handleRedirectToDocument}
                cols={this.props.activeSearch.cols}
                searchWithOrder={(field: SearchField) =>
                  this.props.repeatSearchWithOrder({
                    fieldName: field,
                    order: this.flipOrder(),
                  })
                }
                onUserUpdateDefaultRowCount={(rowCount) => {
                  this.props.saveUserSettings({
                    defaultRowCount: rowCount,
                  });
                }}
              />
            </>
          ) : null}
        </div>
      </MainLayout>
    );
  }
}

const SearchResults = applicationState.StateConnector(
  documentState.StateConnector(AbstractSearchResults)
);

export default injectIntl(SearchResults);
