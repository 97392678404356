import * as React from 'react';
import Button from '@material-ui/core/Button';
import { ButtonProps as MuiButtonProps } from '@material-ui/core/Button';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

const rootStyles = {
  lineHeight: '20px',
  fontSize: 15,
  border: 0,
  boxShadow: 'none',
};
const basicStyles = (theme: Theme) =>
  createStyles({
    root: {
      ...rootStyles,
      textTransform: 'none',
    },
  });

const styleWithMargin = (theme: Theme) =>
  createStyles({
    root: {
      ...rootStyles,
      textTransform: 'none',
      marginRight: '1em',
      transition: '0.5s',
    },
  });

export interface IButtonProps {
  classes?: any;
}

type ButtonProps = MuiButtonProps & IButtonProps;

class UiButton extends React.Component<ButtonProps> {
  render() {
    return (
      <Button
        variant="contained"
        {...this.props}
        className={`${this.props.classes.root} ${this.props.className}`}
      />
    );
  }
}

class ActionButton extends React.Component<ButtonProps> {
  render() {
    return (
      <Button
        variant="text"
        {...this.props}
        className={`${this.props.classes.root} ${this.props.className}`}
      />
    );
  }
}

export const ToolbarButton = withStyles(styleWithMargin)(ActionButton);

export default withStyles(basicStyles)(UiButton);
