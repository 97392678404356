import * as React from 'react';
import * as Model from '../../../../shared/src/model';
import * as schema from '../../../../shared/src/model/projectSchema';
import TextField from '@material-ui/core/TextField';
import { UIPItemModel } from '../../../../shared/src/model/index';
import { FormattedMessage, injectIntl, InjectedIntlProps } from 'react-intl';
import AutosuggestChipSelection, {
  Item,
} from '../Common/AutosuggestChipSelection';
import * as _ from 'lodash';

interface Actions {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

interface IContracts {
  contracts: Item[];
  suggestions: Model.ContractInfo[];
  onChange: (selected: Item[]) => void;
}

export const Contracts = (props: IContracts) => {
  const userContracts = props.suggestions
    ? _.uniqBy(
        props.suggestions.map(c => {
          return { label: c.aitem_name || '', value: c.aitem_id || 0 };
        }),
        e => {
          return e.value;
        }
      )
    : undefined;

  return (
    <AutosuggestChipSelection
      onChange={props.onChange}
      inputValue={''}
      selectedItem={props.contracts}
      suggestions={userContracts}
      label={<FormattedMessage id="upload.document.contract" />}
    />
  );
};

interface IBuildingElements {
  buildingItemTree: Item[];
  loadingElementTree: boolean;
  selectedBuildingItem: Item[];
  onChange: (selected: Item[]) => void;
}

export const BuildingElements = (props: IBuildingElements) => {
  return (
    <AutosuggestChipSelection
      multi={true}
      onChange={props.onChange}
      selectedItem={props.selectedBuildingItem}
      suggestions={props.buildingItemTree}
      loading={props.loadingElementTree}
      label={<FormattedMessage id="documentMeta.buildingElement" />}
    />
  );
};

interface IRooms {
  multi?: boolean;
  loading?: boolean;
  suggestions: UIPItemModel[];
  selectedItems: Item[];
  onChange: (selected: Item[]) => void;
}

export const Rooms = (props: IRooms) => {
  const buildingItems: Item[] = props.suggestions.map(item => {
    return { label: item.nameLong || '', value: item.id } as Item;
  });

  return (
    <AutosuggestChipSelection
      multi={true}
      onChange={props.onChange}
      selectedItem={props.selectedItems}
      suggestions={buildingItems}
      loading={props.loading}
      label={<FormattedMessage id="documentMeta.room" />}
    />
  );
};

interface IPItemTypes {
  multi: boolean;
  required: boolean;
  pitemTypes: schema.pitem_type[];
  selectedDocumentType: Item[];
  onChange: (selected: Item[]) => void;
}

export const PItemTypes = (props: IPItemTypes) => {
  const documentTypes: Item[] = props.pitemTypes.map(item => {
    return { label: item.name, value: item.id } as Item;
  });

  return (
    <AutosuggestChipSelection
      multi={props.multi}
      required={props.required}
      onChange={props.onChange}
      selectedItem={props.selectedDocumentType}
      suggestions={documentTypes}
      label={<FormattedMessage id="itemMeta.itemType" />}
    />
  );
};

interface IDocumentTypes {
  multi: boolean;
  required?: boolean;
  documentTypes: Model.DocumentCategory[];
  selectedDocumentType: Item[];
  onChange: (selected: Item[]) => void;
}

export const DocumentTypes = (props: IDocumentTypes) => {
  // TODO: Document types don't have value yet
  const documentTypes: Item[] = props.documentTypes.map(
    (doctype: Model.DocumentCategory, index: number) => {
      return { label: doctype.name, value: doctype.id } as Item;
    }
  );

  return (
    <AutosuggestChipSelection
      multi={props.multi}
      required={props.required}
      onChange={props.onChange}
      selectedItem={props.selectedDocumentType}
      suggestions={documentTypes}
      label={<FormattedMessage id="documentMeta.documentType" />}
    />
  );
};

interface IDocumentCategories {
  multi: boolean;
  required?: boolean;
  documentCategories: Model.DocumentCategory[];
  selectedDocumentCategories: Item[];
  onChange: (selected: Item[]) => void;
}

export const DocumentCategories = (props: IDocumentCategories) => {
  const documentCategories: Item[] = props.documentCategories.map(
    (docCategory: Model.DocumentCategory) => {
      return { label: docCategory.name, value: docCategory.id } as Item;
    }
  );

  return (
    <AutosuggestChipSelection
      multi={props.multi}
      required={props.required}
      onChange={props.onChange}
      selectedItem={props.selectedDocumentCategories}
      suggestions={documentCategories}
      label={<FormattedMessage id="documentMeta.documentCategory" />}
    />
  );
};

interface IDesignDisciplines {
  multi: boolean;
  required?: boolean;
  designDisciplines: Model.DocumentCategory[];
  selectedDesignDisciplines: Item[];
  onChange: (selected: Item[]) => void;
}

export const DesignDisciplines = (props: IDesignDisciplines) => {
  const designDisciplines: Item[] = props.designDisciplines.map(
    (docType: Model.DocumentCategory) => {
      return { label: docType.name, value: docType.id } as Item;
    }
  );

  return (
    <AutosuggestChipSelection
      multi={props.multi}
      required={props.required}
      onChange={props.onChange}
      selectedItem={props.selectedDesignDisciplines}
      suggestions={designDisciplines}
      label={<FormattedMessage id="documentMeta.designDiscipline" />}
    />
  );
};

interface IContractorWorkTypes {
  multi?: boolean;
  required?: boolean;
  suggestions: Model.DocumentCategory[];
  selectedItems: Item[];
  onChange: (selected: Item[]) => void;
}

export const ContractorWorkTypes = (props: IContractorWorkTypes) => {
  const suggestions: Item[] = props.suggestions.map(
    (workType: Model.DocumentCategory) => {
      return { label: workType.name, value: workType.id } as Item;
    }
  );

  return (
    <AutosuggestChipSelection
      multi={props.multi}
      required={props.required}
      onChange={props.onChange}
      selectedItem={props.selectedItems}
      suggestions={suggestions}
      label={<FormattedMessage id="documentMeta.contractorWorkType" />}
    />
  );
};

interface IFileTypes {
  multi: boolean;
  required?: boolean;
  fileTypes: string[];
  selectedFileTypes: Item[];
  onChange: (selected: Item[]) => void;
}

export const FileTypes = (props: IFileTypes) => {
  const fileTypes: Item[] = props.fileTypes.map(
    (fileType: string, index: number) => {
      return { label: fileType, value: index } as Item;
    }
  );
  return (
    <AutosuggestChipSelection
      multi={props.multi}
      required={props.required}
      onChange={props.onChange}
      selectedItem={props.selectedFileTypes}
      suggestions={fileTypes}
      label={<FormattedMessage id="documentMeta.fileType" />}
    />
  );
};

interface IWorkflowStates {
  multi: boolean;
  required?: boolean;
  workflowStates: schema.documentstate[];
  selectedWorkflowStates: Item[];
  onChange: (selected: Item[]) => void;
}

type WorkflowStatesProps = IWorkflowStates & InjectedIntlProps;

const AbstractWorkflowStates = (props: WorkflowStatesProps) => {
  const { intl } = props;
  const workflowStates: Item[] = props.workflowStates.map(
    (workflowState: schema.documentstate) => {
      const label = intl.formatMessage({
        id: `documentMeta.${workflowState.name}`,
      });

      return { label, value: workflowState.id } as Item;
    }
  );

  return (
    <AutosuggestChipSelection
      multi={props.multi}
      required={props.required}
      onChange={props.onChange}
      selectedItem={props.selectedWorkflowStates}
      suggestions={workflowStates}
      label={<FormattedMessage id="documentMeta.workflowState" />}
    />
  );
};

export const WorkflowStates = injectIntl(AbstractWorkflowStates);

interface IRevision extends Actions {
  value: string | undefined;
}

export const DocumentRevision = (props: IRevision) => (
  <TextField
    fullWidth={true}
    value={props.value}
    onChange={props.onChange}
    label={<FormattedMessage id="documentMeta.revision" />}
  />
);

interface IStartPole extends Actions {
  value: string | undefined;
}

export const StartPole = (props: IStartPole) => (
  <TextField
    type="Number"
    fullWidth={true}
    value={props.value}
    onChange={props.onChange}
    label={<FormattedMessage id="documentMeta.startPole" />}
  />
);

interface IEndPole extends Actions {
  value: string | undefined;
}

export const EndPole = (props: IEndPole) => (
  <TextField
    type="Number"
    fullWidth={true}
    value={props.value}
    onChange={props.onChange}
    label={<FormattedMessage id="documentMeta.endPole" />}
  />
);
