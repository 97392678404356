import * as model from '../../../../../shared/src/model';
import * as schema from '../../../../../shared/src/model/projectSchema';

/**
 * @client document
 */
export function DocumentService(axios: any) {
  return new class AssetPointAPI {
    // client for endpoint signout
    async signout() : Promise<void> {
      return (await axios.get(`/docs/v1/signout/`,{params:{}})).data;
    }
    // client for endpoint documentPrintOrder
    async documentPrintOrder(printOrder:model.PrintOrder)  {
      return (await axios.post(`/docs/v1/documentprintorder/`,printOrder)).data;
    }
    // client for endpoint downloadSearchAsZip
    async downloadSearchAsZip(searchRequest:string) : Promise<any> {
      return (await axios.get(`/docs/v1/downloadSearchAsZip/`,{params:{searchRequest}})).data;
    }
    // client for endpoint downloadDocuments
    async downloadDocuments(docids:string) : Promise<string> {
      return (await axios.get(`/docs/v1/documentzip/`,{params:{docids}})).data;
    }
    // client for endpoint downloadUserManual
    async downloadUserManual() : Promise<string> {
      return (await axios.get(`/docs/v1/usermanual/`,{params:{}})).data;
    }
    // client for endpoint downloadDocument
    async downloadDocument(docid:number) : Promise<string> {
      return (await axios.get(`/docs/v1/documentfile/`,{params:{docid}})).data;
    }
    // client for endpoint runTests
    async runTests() : Promise<boolean> {
      return (await axios.get(`/docs/v1/runTests/`,{params:{}})).data;
    }
    // client for endpoint fetchPitem
    async fetchPitem(id:number) : Promise<model.UIDocument> {
      return (await axios.get(`/docs/v1/fetchPitem/${id}/`,{params:{}})).data;
    }
    // client for endpoint updateProps
    async updateProps(props:Array<model.PropertyCollection<any>>) : Promise<Array<model.PropertyCollection<any>>> {
      return (await axios.post(`/docs/v1/updateProps/`,props)).data;
    }
    // client for endpoint getRows
    async getRows(params:{
    tableName: string;
    afterId: number;
    limit: number;
    }) : Promise<number[]> {
      return (await axios.post(`/docs/v1/getRows/`,params)).data;
    }
    // client for endpoint fetchProps
    async fetchProps(compressedProps:any) : Promise<Array<model.PropertyCollection<any>>> {
      return (await axios.post(`/docs/v1/fetchProps/`,compressedProps)).data;
    }
    // client for endpoint fetchPitemProps
    async fetchPitemProps(id:number) : Promise<{
    [key: string]: model.GenericProp<any>;
    }> {
      return (await axios.get(`/docs/v1/fetchPitemProps/${id}/`,{params:{}})).data;
    }
    // client for endpoint fetchDocument
    async fetchDocument(id:number) : Promise<model.UIDocument> {
      return (await axios.get(`/docs/v1/fetchDocument/${id}/`,{params:{}})).data;
    }
    // client for endpoint fetchDocumentHistory
    async fetchDocumentHistory(id:number) : Promise<model.DocStateHistoryRow[]> {
      return (await axios.get(`/docs/v1/fetchDocumentHistory/${id}/`,{params:{}})).data;
    }
    // client for endpoint documentSearchCnt
    async documentSearchCnt(params:model.SearchRequest) : Promise<number> {
      return (await axios.post(`/docs/v1/documentSearchCnt/`,params)).data;
    }
    // client for endpoint documentSearchRequest
    async documentSearchRequest(params:model.SearchRequest) : Promise<{
    rows: model.UIDocument[];
    estimatedCnt: number;
    }> {
      return (await axios.post(`/docs/v1/documentSearchRequest/`,params)).data;
    }
    // client for endpoint pureDocumentSearch
    async pureDocumentSearch(params:model.SearchRequest) : Promise<schema.document[]> {
      return (await axios.post(`/docs/v1/pureDocumentSearch/`,params)).data;
    }
    // client for endpoint fixAllDocPackages
    async fixAllDocPackages() : Promise<any> {
      return (await axios.get(`/docs/v1/fixAllDocPackages/`,{params:{}})).data;
    }
    // client for endpoint importXML
    async importXML(options:model.ImportSettings) : Promise<any> {
      return (await axios.post(`/docs/v1/importXML/`,options)).data;
    }
    // client for endpoint createImportXMLForContractID
    async createImportXMLForContractID(contractId:number) : Promise<any> {
      return (await axios.get(`/docs/v1/createImportXMLForContractID/${contractId}/`,{params:{}})).data;
    }
    // client for endpoint downloadSearchAsExcel
    async downloadSearchAsExcel(searchRequest:string) : Promise<any> {
      return (await axios.get(`/docs/v1/downloadSearchAsExcel/`,{params:{searchRequest}})).data;
    }
    // client for endpoint updateDocument
    async updateDocument(doc:model.UIDocument) : Promise<model.UIDocument> {
      return (await axios.post(`/docs/v1/updateDocument/`,doc)).data;
    }
    // client for endpoint markMissingDocs
    async markMissingDocs(param:{
    id: number;
    whatIsMissing: string;
    isMissingDocs: boolean;
    })  {
      return (await axios.post(`/docs/v1/markMissingDocs/`,param)).data;
    }
    // client for endpoint docActionEvent
    async docActionEvent(eventData:model.DocumentActionEvent) : Promise<model.UIDocument[]> {
      return (await axios.post(`/docs/v1/docActionEvent/`,eventData)).data;
    }
    // client for endpoint changeDocState
    async changeDocState(newState:model.DocStateChangeEvent)  {
      return (await axios.post(`/docs/v1/changeDocState/`,newState)).data;
    }
    // client for endpoint waitingForReview
    async waitingForReview() : Promise<model.UIDocument[]> {
      return (await axios.get(`/docs/v1/waitingForReview/`,{params:{}})).data;
    }
    // client for endpoint getLinkedDocs
    async getLinkedDocs(query:model.LinkedDocs) : Promise<model.UIDocument[]> {
      return (await axios.post(`/docs/v1/getLinkedDocs/`,query)).data;
    }
    // client for endpoint uploadAdminExcelFile
    async uploadAdminExcelFile() : Promise<model.AdminDocumentUpload> {
      return (await axios.post(`/docs/v1/upload/adminexcel/`,)).data;
    }
    // client for endpoint linkToDwgDocs
    async linkToDwgDocs(linked:model.UpdateDWG) : Promise<model.UIDocument[]> {
      return (await axios.post(`/docs/v1/linkToDwgDocs/`,linked)).data;
    }
    // client for endpoint getVersionHistory
    async getVersionHistory(docId:number) : Promise<model.UIDocument[]> {
      return (await axios.get(`/docs/v1/getVersionHistory/${docId}/`,{params:{}})).data;
    }
    // client for endpoint uploadNewDocRevision
    async uploadNewDocRevision() : Promise<model.UIDocument[]> {
      return (await axios.post(`/docs/v1/upload/nextrevision/`,)).data;
    }
    // client for endpoint uploadContractorCollectionFile
    async uploadContractorCollectionFile() : Promise<model.UIDocument[]> {
      return (await axios.post(`/docs/v1/upload/contractordoc/`,)).data;
    }
    // client for endpoint createAnyDocument
    async createAnyDocument(info:model.UIDocument) : Promise<model.UIDocument[]> {
      return (await axios.post(`/docs/v1/create/doc2/`,info)).data;
    }
    // client for endpoint uploadAnyDocument2
    async uploadAnyDocument2() : Promise<model.UIDocument[]> {
      return (await axios.post(`/docs/v1/upload/doc2/`,)).data;
    }
    // client for endpoint uploadAnyDocument
    async uploadAnyDocument() : Promise<model.UIDocument[]> {
      return (await axios.post(`/docs/v1/upload/doc/`,)).data;
    }
    // client for endpoint deleteDocuments
    async deleteDocuments(documentIds:number[]) : Promise<boolean> {
      return (await axios.post(`/docs/v1/deleteDocuments/`,documentIds)).data;
    }
    // client for endpoint deleteDocument
    async deleteDocument(documentId:number)  {
      return (await axios.delete(`/docs/v1/deleteDocument/${documentId}/`,{params:{}})).data;
    }
    // client for endpoint deleteInspectionDocument
    async deleteInspectionDocument(documentId:number)  {
      return (await axios.delete(`/docs/v1/deleteInspectionDocument/${documentId}/`,{params:{}})).data;
    }
    // client for endpoint hasMissingAttachments
    async hasMissingAttachments(documentId:number) : Promise<boolean> {
      return (await axios.get(`/docs/v1/missingDocuments/${documentId}/`,{params:{}})).data;
    }
    // client for endpoint getDocumentComments
    async getDocumentComments(documentId:number) : Promise<{
    [id: number]: model.UIDocumentComment[];
    }> {
      return (await axios.get(`/docs/v1/comments/${documentId}/`,{params:{}})).data;
    }
    // client for endpoint addDocumentComment
    async addDocumentComment(data:model.DocumentComment) : Promise<{}> {
      return (await axios.post(`/docs/v1/comments/add/`,data)).data;
    }
    // client for endpoint documentCommentSeen
    async documentCommentSeen(commentId:number) : Promise<{
    [id: number]: model.UIDocumentComment[];
    }> {
      return (await axios.post(`/docs/v1/comments/${commentId}/`,)).data;
    }
    // client for endpoint getClientSettings
    async getClientSettings() : Promise<model.ClientSettings> {
      return (await axios.get(`/docs/v1/getClientSettings/`,{params:{}})).data;
    }
    // client for endpoint createInspectionDocument
    async createInspectionDocument(request:model.CreateInspectionDocumentRequest)  {
      return (await axios.post(`/docs/v1/createInspectionDocument/`,request)).data;
    }
    // client for endpoint downloadTargetsAsSpreadsheet
    async downloadTargetsAsSpreadsheet() : Promise<any> {
      return (await axios.get(`/docs/v1/downloadTargetsAsSpreadsheet/`,{params:{}})).data;
    }
    // client for endpoint getBuildingPermits
    async getBuildingPermits(contractId:number)  {
      return (await axios.get(`/docs/v1/getBuildingPermits/${contractId}/`,{params:{}})).data;
    }
    // client for endpoint reorderDocuments
    async reorderDocuments(docIds:number[])  {
      return (await axios.post(`/docs/v1/reorderDocuments/`,docIds)).data;
    }
    // client for endpoint fetchSystemByName
    async fetchSystemByName(name:string)  {
      return (await axios.get(`/docs/v1/fetchSystemByName/${name}/`,{params:{}})).data;
    }
    // client for endpoint fetchSystemById
    async fetchSystemById(systemId:number)  {
      return (await axios.get(`/docs/v1/fetchSystemById/${systemId}/`,{params:{}})).data;
    }
    // client for endpoint fetchChildSystemsOf
    async fetchChildSystemsOf(parentSystemId:number)  {
      return (await axios.get(`/docs/v1/fetchChildSystemsOf/${parentSystemId}/`,{params:{}})).data;
    }
    // client for endpoint fetchAllParentSystems
    async fetchAllParentSystems()  {
      return (await axios.get(`/docs/v1/fetchAllParentSystems/`,{params:{}})).data;
    }
    // client for endpoint fetchAllSystems
    async fetchAllSystems()  {
      return (await axios.get(`/docs/v1/fetchAllSystems/`,{params:{}})).data;
    }
    // client for endpoint deleteSystem
    async deleteSystem(systemId:number)  {
      return (await axios.delete(`/docs/v1/deleteSystem/${systemId}/`,{params:{}})).data;
    }
    // client for endpoint testSendingMails
    async testSendingMails(params:{
    roleName: string;
    templateName: string;
    stateId: number;
    docCatId: number;
    })  {
      return (await axios.post(`/docs/v1/testSendingMails/`,params)).data;
    }
    // client for endpoint getDocumentCnt
    async getDocumentCnt(categoryId:number)  {
      return (await axios.get(`/docs/v1/getDocumentCnt/${categoryId}/`,{params:{}})).data;
    }
    // client for endpoint addOrUpdatePitemType
    async addOrUpdatePitemType(options:Partial<model.DocumentCategory>)  {
      return (await axios.post(`/docs/v1/addOrUpdatePitemType/`,options)).data;
    }
    // client for endpoint deletePItemType
    async deletePItemType(typeId:number)  {
      return (await axios.delete(`/docs/v1/deletePItemType/${typeId}/`,{params:{}})).data;
    }
    // client for endpoint getPitemTypePitemCnt
    async getPitemTypePitemCnt(typeId:number)  {
      return (await axios.get(`/docs/v1/getPitemTypePitemCnt/${typeId}/`,{params:{}})).data;
    }
    // client for endpoint findSimilarDocuments
    async findSimilarDocuments(docId:number)  {
      return (await axios.get(`/docs/v1/findSimilarDocuments/${docId}/`,{params:{}})).data;
    }
    // client for endpoint addOrUpdateCategory
    async addOrUpdateCategory(options:Partial<model.DocumentCategory>)  {
      return (await axios.post(`/docs/v1/addOrUpdateCategory/`,options)).data;
    }
    // client for endpoint deleteCategory
    async deleteCategory(categoryId:number)  {
      return (await axios.delete(`/docs/v1/deleteCategory/${categoryId}/`,{params:{}})).data;
    }
    // client for endpoint createNewSystem
    async createNewSystem(newSystem:{
    parent_system_id: number | undefined;
    system_name: string;
    description: string;
    }) : Promise<schema.system> {
      return (await axios.post(`/docs/v1/createNewSystem/`,newSystem)).data;
    }
    // client for endpoint linkPitemsToSystems
    async linkPitemsToSystems(list:model.PItemToSystem[]) : Promise<model.PItemToSystemResult[]> {
      return (await axios.post(`/docs/v1/linkPitemsToSystems/`,list)).data;
    }
    // client for endpoint linkDocumentsToSystems
    async linkDocumentsToSystems(list:model.DocumentToSystem[]) : Promise<model.DocumentToSystemResult[]> {
      return (await axios.post(`/docs/v1/linkDocumentsToSystems/`,list)).data;
    }
    // client for endpoint runMigration
    async runMigration(options:model.ImportSettings) : Promise<string> {
      return (await axios.post(`/docs/v1/runMigration/`,options)).data;
    }
    // client for endpoint finalizeMigration
    async finalizeMigration() : Promise<any> {
      return (await axios.get(`/docs/v1/finalizeMigration/`,{params:{}})).data;
    }
    // client for endpoint testMFilesConn
    async testMFilesConn() : Promise<any> {
      return (await axios.get(`/docs/v1/testMFilesConn/`,{params:{}})).data;
    }
    // client for endpoint ping
    async ping(message:string) : Promise<string> {
      return (await axios.get(`/docs/v1/ping/${message}/`,{params:{}})).data;
    }
    // client for endpoint fixDocPackage
    async fixDocPackage(id:number) : Promise<any> {
      return (await axios.get(`/docs/v1/fixDocPackage/${id}/`,{params:{}})).data;
    }
    // client for endpoint runTestSuite
    async runTestSuite() : Promise<boolean | string> {
      return (await axios.get(`/docs/v1/runTestSuite/`,{params:{}})).data;
    }
    // client for endpoint syncDocs
    async syncDocs(start:number, end:number, step:number) : Promise<string[]> {
      return (await axios.get(`/docs/v1/syncDocs/${start}/`,{params:{end, step}})).data;
    }
    // client for endpoint updateMFilesSyncDataForLocalDB
    async updateMFilesSyncDataForLocalDB()  {
      return (await axios.get(`/docs/v1/updateMFilesSyncDataForLocalDB/`,{params:{}})).data;
    }
    // client for endpoint getMissingDocuments
    async getMissingDocuments() : Promise<schema.document[]> {
      return (await axios.get(`/docs/v1/getMissingDocuments/`,{params:{}})).data;
    }
    // client for endpoint linkMissingDocsManually
    async linkMissingDocsManually()  {
      return (await axios.get(`/docs/v1/linkMissingDocsManually/`,{params:{}})).data;
    }
    // client for endpoint runFixes
    async runFixes()  {
      return (await axios.get(`/docs/v1/runFixes/`,{params:{}})).data;
    }
    // client for endpoint initializeEAMBuilding
    async initializeEAMBuilding(name:string)  {
      return (await axios.get(`/docs/v1/initializeEAMBuilding/${name}/`,{params:{}})).data;
    }
    // client for endpoint changeDocVisibility
    async changeDocVisibility(id:number, visible:string)  {
      return (await axios.get(`/docs/v1/changeDocVisibility/${id}/`,{params:{visible}})).data;
    }
    // client for endpoint getSyncHistory
    async getSyncHistory() : Promise<model.SyncModelRow[]> {
      return (await axios.get(`/docs/v1/getSyncHistory/`,{params:{}})).data;
    }
    // client for endpoint getSyncErrors
    async getSyncErrors() : Promise<schema.eam_process_error[]> {
      return (await axios.get(`/docs/v1/getSyncErrors/`,{params:{}})).data;
    }
    // client for endpoint serviceName
    async serviceName()  {
      return (await axios.get(`/docs/v1/serviceName/`,{params:{}})).data;
    }
  }
}
