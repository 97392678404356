import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import WarningIcon from '@material-ui/icons/WarningRounded';
import * as React from 'react';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { Redirect } from 'react-router-dom';
import { setBackgroundImage } from '../../utils';
import { UIDocument } from '../../../../shared/src/model/index';
import MainLayout from '../../layout/MainLayout';
import * as documentState from '../../state/reducers/DocumentState';
import { normalizeExtension } from '../../utils/filename';
import Button from '../Common/Button';
import { Link } from 'react-router-dom';
import './downloadBasket.scss';
import Breadcrumbs from '../Common/Breadcrumbs';

interface IDownloadBasketProps extends documentState.IProps {}

type DownloadProps = IDownloadBasketProps & InjectedIntlProps;

interface DownloadBasketState {
  page: number;
  rowsPerPage: number;
  orderPrintsClicked: boolean;
}

class DownloadBasket extends React.PureComponent<
  DownloadProps,
  DownloadBasketState
> {
  state: DownloadBasketState = {
    page: 0,
    rowsPerPage: 10,
    orderPrintsClicked: false,
  };

  constructor(props: DownloadProps) {
    super(props);
  }

  async componentDidMount() {
    setBackgroundImage();
  }

  onPageChange = (_: any, page: number) => {
    this.setState({ page });
  };

  onRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ rowsPerPage: parseInt(event.currentTarget.value, 10) });
  };

  isPrintable(doc: UIDocument) {
    return normalizeExtension(doc.fileExtension) === 'pdf';
  }

  public render() {
    if (this.state.orderPrintsClicked) {
      return <Redirect push={true} to="/printorder" />;
    }

    return (
      <MainLayout>
        <Breadcrumbs>
          <Link to="/">
            <FormattedMessage id="headers.home" />
          </Link>
          <FormattedMessage id="downloadBasket.title" />
        </Breadcrumbs>

        <h1>
          <FormattedMessage id="downloadBasket.title" />
        </h1>

        <div className="grid gridCols1">
          <Paper>
            <div id="downloadBasketContent">
              {this.props.downloadBasket.length === 0 && (
                <p>
                  <FormattedMessage id="downloadBasket.empty" />
                </p>
              )}
              {this.props.downloadBasket.length > 0 &&
                this.renderDocumentList()}
            </div>
          </Paper>
        </div>
      </MainLayout>
    );
  }

  renderDocumentList() {
    const basketDocuments = this.props.downloadBasket;
    const { page, rowsPerPage } = this.state;
    const firstIndex = page * rowsPerPage;

    const docCount = basketDocuments.length;
    const printableCount = basketDocuments.reduce(
      (count, doc) => (this.isPrintable(doc) ? count + 1 : count),
      0
    );

    const showNoticeColumn = printableCount < docCount;

    return (
      <div>
        <div className="downloadDocumentList">
          <Table padding="dense">
            <TableHead>
              <TableRow>
                {showNoticeColumn && <TableCell padding="checkbox" />}
                <TableCell>
                  <FormattedMessage id="downloadBasket.file" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="documentMeta.versionNumber" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="downloadBasket.fileType" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="documentMeta.createdByUser" />
                </TableCell>

                <TableCell padding="checkbox" />
              </TableRow>
            </TableHead>
            <TableBody>
              {basketDocuments
                .slice(firstIndex, firstIndex + rowsPerPage)
                .map((doc) => {
                  return (
                    <TableRow key={doc.id}>
                      {showNoticeColumn && (
                        <TableCell padding="checkbox">
                          {!this.isPrintable(doc) && (
                            <WarningIcon className={'nonPrintable'} />
                          )}
                        </TableCell>
                      )}
                      <TableCell title={doc.fileName}>
                        <div className="nameColumn">
                          <div style={{ wordBreak: 'break-all' }}>
                            <Link to={`/documents/${doc.id}`}>{doc.name}</Link>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>{doc.versionNumber}</TableCell>
                      <TableCell>{doc.fileExtension}</TableCell>
                      <TableCell>{doc.createdByUser || '-'}</TableCell>
                      <TableCell>{doc.fileHash}</TableCell>
                      <TableCell padding="checkbox">
                        <div className="rowActions">
                          <IconButton
                            color="primary"
                            onClick={(e) => this.props.removeFromBasket(doc)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={basketDocuments.length}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              native: true,
            }}
            rowsPerPageOptions={[10, 20, 30]}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onChangePage={this.onPageChange}
            onChangeRowsPerPage={this.onRowsPerPageChange}
          />
        </div>
        <div>
          {printableCount < docCount && (
            <div className="notice">
              <WarningIcon className={'nonPrintable'} />
              <FormattedMessage id={'downloadBasket.someNonPrintable'} />
            </div>
          )}
        </div>
        <div className="buttonRow">
          <Button
            color="primary"
            onClick={() => {
              this.props.clearBasket();
            }}
          >
            <FormattedMessage id="downloadBasket.clear" />
          </Button>
          <Button
            color="primary"
            onClick={() => {
              if (document && document.location) {
                document.location.href =
                  `/docs/v1/documentzip/?docids=` +
                  basketDocuments.map((doc) => doc.id).join(',');
              }
            }}
          >
            <FormattedMessage id="downloadBasket.downloadAll" />
          </Button>
          <Button
            color="primary"
            disabled={printableCount === 0}
            onClick={() => this.setState({ orderPrintsClicked: true })}
          >
            <FormattedMessage id="downloadBasket.toPrintOrder" />
          </Button>
        </div>
      </div>
    );
  }
}

export default injectIntl(documentState.StateConnector(DownloadBasket));
