import * as Model from '../../../../shared/src/model/index';
import * as React from 'react';
import * as _ from 'lodash';

import { InjectedIntlProps, injectIntl } from 'react-intl';
import {
  LinearProgress,
  TableFooter,
  TablePagination,
} from '@material-ui/core';

import Checkbox from '@material-ui/core/Checkbox';
import { OpenInNew } from '@material-ui/icons/';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Loading from './Loading';

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

interface TableRowSourceType extends Model.UIDocument {
  location: string;
}
export type TableRowType = {
  [key in keyof Omit<TableRowSourceType, 'id'>]?:
    | string
    | TableRowSourceType[key]
    | JSX.Element;
} & { id: number };

export type DocumentTableKeyType = keyof (TableRowSourceType & { url: string });

interface IDocumentTableProps {
  type?: string;
  keys: {
    [key in DocumentTableKeyType]?: string | Element;
  };
  searchMainCategoryName?: string;
  rows: TableRowType[];
  dataRows?: Model.UIDocument[];
  title?: string | Element | JSX.Element;
  hasSelect?: boolean;
  rowClick?: (row: TableRowType) => void;
  actionComponents?: React.ReactNode[];
  setSelected?: (sel: number[]) => void;
  headerClick?: (key: string) => void;
  onTitleClick?: () => void;
  onUserUpdateDefaultRowCount?: (rowCount: number) => void;
  page?: number;
  onPageChange?: (page: number) => void;
  onAskMoreData?: () => void;
  rowsPerPage?: number;
  selectedDocuments?: Model.UIDocument[];
  loading?: boolean;
  rowHeader?: (
    index: number,
    rowIndex: number,
    row?: Model.UIDocument
  ) => React.ReactNode;
  alignDefault?: 'left' | 'center' | 'right';
  align?: { [key in DocumentTableKeyType]?: 'left' | 'center' | 'right' };
  estimatedCnt?: number;
}

interface IDocumentTableState {
  documentDocuments?: Model.AdminDocumentUpload;
  page: number;
  rowsPerPage: number;
  selectedIds: number[];
  selectedRows: number[];
  didAskMoreData: boolean;
}

type DocumentTableProps = IDocumentTableProps & InjectedIntlProps;

const ROWS_PER_PAGE_DEFAULT = 20;

class DocumentTable extends React.Component<
  DocumentTableProps,
  IDocumentTableState
> {
  constructor(props: DocumentTableProps) {
    super(props);
    this.state = {
      page: props.page || 0,
      rowsPerPage: props.rowsPerPage || ROWS_PER_PAGE_DEFAULT,
      selectedIds: [],
      selectedRows: [],
      didAskMoreData: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    // Any time props.email changes, update state.
    if (nextProps.page !== this.props.page) {
      this.setState({
        page: nextProps.page,
      });
    }
  }

  setSelected(selected: number[]) {
    this.setState({
      selectedIds: selected,
    });
    if (this.props.setSelected) this.props.setSelected(selected);
  }

  handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked && this.state.selectedIds.length === 0) {
      const newSelecteds: number[] = this.props.rows.map((n) => n.id);
      this.setSelected(newSelecteds);
    } else {
      this.setSelected([]);
    }
  };

  handleClick = (
    event: React.MouseEvent<HTMLTableRowElement>,
    row: TableRowType
  ) => {
    if (this.props.rowClick) {
      this.props.rowClick(row);
    }
  };

  handleSelectedClick = (id: number) => {
    const newSelected = _.xor(this.state.selectedIds, [id]);
    this.setSelected(newSelected);
  };

  handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    this.setState({ page });

    const totalCnt = this.props.estimatedCnt || this.props.rows.length;
    const endOfPage =
      (page + 1) * (this.state.rowsPerPage || ROWS_PER_PAGE_DEFAULT);

    if (
      this.props.rows.length < totalCnt &&
      this.props.rows.length < endOfPage
    ) {
      if (this.props.onAskMoreData && !this.state.didAskMoreData) {
        this.setState({ didAskMoreData: true }, () => {
          if (this.props.onAskMoreData) this.props.onAskMoreData();
        });
      }
    }

    if (this.props.onPageChange) {
      this.props.onPageChange(page);
    }
  };

  handleChangeRowsPerPage = (event: any) => {
    const rowCount = parseInt(event.target.value, 10);

    this.setState({ rowsPerPage: rowCount }, () => {
      if (this.props.onUserUpdateDefaultRowCount) {
        this.props.onUserUpdateDefaultRowCount(rowCount);
      }
    });
  };

  componentDidUpdate(prevProps: DocumentTableProps) {
    // if selected documents is given as array parameter, which means the selection
    // state is maintained outside of this component, then we will first compare if the
    // selected documents array is still the same
    if (prevProps.selectedDocuments !== this.props.selectedDocuments) {
      if (this.props.selectedDocuments && prevProps.selectedDocuments) {
        // then we check if the values have been actually changed
        const idValues = this.props.selectedDocuments.map((d) => d.id);
        const prevIdValues = prevProps.selectedDocuments.map((d) => d.id);
        // intersection will give us the values which are same in both arrays
        if (
          _.intersection(idValues, prevIdValues).length !== prevIdValues.length
        ) {
          // if the number of unique values has been changed, reset the selected items list
          this.setSelected(this.props.selectedDocuments.map((d) => d.id));
        }
      }
    }
    if (prevProps.rows.length !== this.props.rows.length) {
      if (this.state.didAskMoreData) {
        this.setState({ didAskMoreData: false });
      } else {
        this.setState({ page: 0 });
      }
    }
  }

  renderPaginationRow() {
    const { keys, rows } = this.props;
    return (
      <TableRow>
        <TablePagination
          className="tablePagination"
          rowsPerPageOptions={[20, 50, 100, 200]}
          colSpan={Object.keys(keys).length + 1}
          count={this.props.estimatedCnt || rows.length}
          rowsPerPage={this.state.rowsPerPage}
          page={this.state.page}
          SelectProps={{
            native: true,
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          labelRowsPerPage={this.props.intl.formatMessage({
            id: 'documentTable.labelRowsPerPage',
          })}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} / ${count}`
          }
        />
      </TableRow>
    );
  }

  renderActions() {
    if (this.props.actionComponents) {
      return this.props.actionComponents.map((component) => component);
    }
    return null;
  }

  Compose(props: { children: React.ReactNode }) {
    return <>{props.children}</>;
  }

  public render() {
    const { keys, rows } = this.props;
    const { page, rowsPerPage } = this.state;
    const numSelected = this.state.selectedIds.length;
    const rowCount = this.props.rows.length;
    const t = (id: string) => this.props.intl.formatMessage({ id });

    const defaultAlign = this.props.alignDefault || 'left';
    const align = (key: DocumentTableKeyType) =>
      this.props.align && this.props.align[key]
        ? this.props.align[key]
        : defaultAlign;

    const defaultCell = (
      content: any,
      key: string,
      className: string,
      index: number
    ) => (
      <TableCell
        className={className}
        align={align(key as DocumentTableKeyType)}
        padding="none"
        style={{
          whiteSpace: 'normal',
          wordWrap: 'break-word',
          paddingRight: '5px',
          paddingLeft: '5px',
        }}
        key={'cell_' + index}
      >
        {content}
      </TableCell>
    );

    return (
      <div className="documentTable">
        {this.props.title ? (
          <div
            className="component"
            onClick={() => {
              if (this.props.onTitleClick) {
                this.props.onTitleClick();
              }
            }}
          >
            <h2>
              {this.props.title}{' '}
              {this.props.estimatedCnt ? `(${this.props.estimatedCnt})` : ''}
            </h2>
            {this.props.loading && <LinearProgress />}
          </div>
        ) : null}
        <div className={`toolbar ${numSelected > 0 && 'selected'}`}>
          <div
            className="doctable_actions"
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            {numSelected > 0 && this.renderActions()}
          </div>
        </div>

        <div className="tableWrapper">
          <Table className="mainTable" padding="dense">
            <TableHead>
              {rows && rows.length > 5 && this.renderPaginationRow()}
              {rows && rows.length > 0 ? (
                <TableRow>
                  {this.props.hasSelect ? (
                    <TableCell padding="checkbox">
                      <Checkbox
                        indeterminate={
                          numSelected > 0 && numSelected < rowCount
                        }
                        checked={numSelected === rowCount && rowCount !== 0}
                        onChange={this.handleSelectAllClick}
                      />
                    </TableCell>
                  ) : null}
                  {Object.keys(keys).map((key, index) => {
                    return (
                      <TableCell
                        className="headerRowTitle"
                        align={align(key as DocumentTableKeyType)}
                        style={{
                          whiteSpace: 'normal',
                          paddingRight: '5px',
                          paddingLeft: '5px',
                        }}
                        padding={'none'}
                        key={'cell2_' + index}
                        onClick={() => {
                          if (this.props.headerClick) {
                            this.props.headerClick(key);
                          }
                        }}
                      >
                        {keys[key]}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ) : null}
            </TableHead>
            <TableBody>
              {rows &&
                rows.length > 0 &&
                rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isSelected =
                      this.state.selectedIds.indexOf(row.id) !== -1;
                    return (
                      <this.Compose key={'tablerow_' + index}>
                        {this.props.rowHeader
                          ? this.props.rowHeader(
                              page * rowsPerPage + index,
                              index,
                              this.props.dataRows
                                ? this.props.dataRows[
                                    page * rowsPerPage + index
                                  ]
                                : undefined
                            )
                          : null}
                        <TableRow
                          key={'tablerow_' + index}
                          onClick={(event) => this.handleClick(event, row)}
                          hover={true}
                          role="checkbox"
                          tabIndex={-1}
                          selected={isSelected}
                          style={{ cursor: 'pointer' }}
                        >
                          {this.props.hasSelect ? (
                            <TableCell
                              padding="checkbox"
                              onClick={(e: React.MouseEvent<Element>) => {
                                e.stopPropagation();
                                this.handleSelectedClick(row.id);
                              }}
                            >
                              <Checkbox checked={isSelected} />
                            </TableCell>
                          ) : null}
                          {Object.keys(keys).map((key, index2) => {
                            const className =
                              key === 'workflowStateName'
                                ? 'workflow_' + row.workflowStateId
                                : '';

                            switch (this.props.searchMainCategoryName) {
                              case 'TARKASTUSASIAKIRJA_DOC':
                              case 'TIL_DOC':
                              case 'URAK_DOC':
                                if (key === 'name') {
                                  return (
                                    <TableCell
                                      className={`${className} linkCell`}
                                      align={align(key as DocumentTableKeyType)}
                                      padding="none"
                                      style={{
                                        whiteSpace: 'normal',
                                        wordWrap: 'break-word',
                                        paddingRight: '5px',
                                        paddingLeft: '5px',
                                      }}
                                      key={'cell_' + index2}
                                    >
                                      <div
                                        className={`${className} linkCellContainer`}
                                      >
                                        <div>
                                          <OpenInNew />
                                        </div>
                                        <div>
                                          <a
                                            href={
                                              row.categoryNames &&
                                              row.categoryNames instanceof
                                                Array &&
                                              (row.categoryNames as string[]).indexOf(
                                                'TARKASTUSASIAKIRJA_DOC'
                                              ) !== -1
                                                ? `/inspection-documents/target/${row.parentId}/attachment/${row.id}`
                                                : `documents/${row.id}`
                                            }
                                            onClick={(e) => {
                                              // If the user holds Ctrl/Cmd key down while they
                                              // click the link, prevent the event from propagating
                                              // TableRow level and triggering navigation to selected
                                              // document or item
                                              if (e.metaKey) {
                                                e.stopPropagation();
                                              } else {
                                                // ...Otherwise prevent the normal link behaviour so
                                                // we don't trigger page refresh
                                                e.preventDefault();
                                              }
                                            }}
                                          >
                                            {row[key]}
                                          </a>
                                        </div>
                                      </div>
                                    </TableCell>
                                  );
                                } else {
                                  return defaultCell(
                                    key === 'workflowStateName'
                                      ? t(`documentMeta.${row[key]}`)
                                      : row[key],
                                    key,
                                    className,
                                    index2
                                  );
                                }
                              case 'URAK_DP':
                              case 'PITEMS':
                                if (key === 'targetcode') {
                                  return (
                                    <TableCell
                                      className={`${className} linkCell`}
                                      align={align(key as DocumentTableKeyType)}
                                      padding="none"
                                      style={{
                                        whiteSpace: 'normal',
                                        wordWrap: 'break-word',
                                        paddingRight: '5px',
                                        paddingLeft: '5px',
                                      }}
                                      key={'cell_' + index2}
                                    >
                                      <div
                                        className={`${className} linkCellContainer`}
                                      >
                                        <div>
                                          <OpenInNew />
                                        </div>
                                        <div>
                                          <a
                                            href={
                                              this.props.type === 'document'
                                                ? `/documents/${row.id}`
                                                : `/items/${row.id}`
                                            }
                                            onClick={(e) => {
                                              // If the user holds Ctrl/Cmd key down while they
                                              // click the link, prevent the event from propagating
                                              // TableRow level and triggering navigation to selected
                                              // document or item
                                              if (e.metaKey) {
                                                e.stopPropagation();
                                              } else {
                                                // ...Otherwise prevent the normal link behaviour so
                                                // we don't trigger page refresh
                                                e.preventDefault();
                                              }
                                            }}
                                          >
                                            {row[key]}
                                          </a>
                                        </div>
                                      </div>
                                    </TableCell>
                                  );
                                } else {
                                  return defaultCell(
                                    key === 'workflowStateName'
                                      ? t(`documentMeta.${row[key]}`)
                                      : row[key],
                                    key,
                                    className,
                                    index2
                                  );
                                }

                              default:
                                return defaultCell(
                                  key === 'workflowStateName'
                                    ? t(`documentMeta.${row[key]}`)
                                    : row[key],
                                  key,
                                  className,
                                  index2
                                );
                            }
                          })}
                        </TableRow>
                      </this.Compose>
                    );
                  })}
            </TableBody>
            <TableFooter>
              {rows && rows.length > 5 && this.renderPaginationRow()}
            </TableFooter>
          </Table>
          {this.state.didAskMoreData ? <Loading loading={true} /> : ''}
        </div>
      </div>
    );
  }
}

export default injectIntl(DocumentTable);
