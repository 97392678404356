import * as React from 'react';
import { withStyles, Theme } from '@material-ui/core/styles';
import * as container from '../../../state/reducers/ApplicationState';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FormattedMessage, injectIntl, InjectedIntlProps } from 'react-intl';
import {
  Checkbox,
  FormControlLabel,
  CircularProgress,
} from '@material-ui/core';
import * as schema from '../../../../../shared/src/model/projectSchema';
import * as _ from 'lodash';
// tslint:disable-next-line:no-var-requires
const ReactMarkdown = require('react-markdown');

const styles = (theme: Theme) => ({
  progress: {
    margin: theme.spacing.unit * 2,
    color: 'rgb(39, 99, 158)',
  },
});

// type LangKeys = 'fi' | 'en';

// interface AgreementModel {
//   id: number;
//   agreement: {
//     readonly [K in LangKeys]: {
//       id: number;
//       title: string;
//       text: string;
//     }
//   };
// }

interface UserAgreementProps extends container.IProps, InjectedIntlProps {
  classes: Record<string, string>;
}

interface UserAgreementState {
  checked: boolean;
  index: number;
  open: boolean;
}

class UserAgreements extends React.Component<
  UserAgreementProps,
  UserAgreementState
> {
  constructor(props: UserAgreementProps) {
    super(props);
    this.state = { index: 0, checked: false, open: true };
  }

  render() {
    const agreements = _.get(
      this.props.user,
      'requiredAgreements'
    ) as schema.user_agreements[];
    if (!agreements || agreements.length < 1) {
      return null;
    }

    const acceptAgreement = (agreementId: number) => {
      // Send after everything is accepted
      if (this.state.index + 1 === agreements.length) {
        this.props.acceptingAgreements(agreements.map(a => a.id));
      } else {
        this.setState({
          index: this.state.index + 1,
          checked: false,
        });
      }
    };
    const agreement = _.get(agreements[this.state.index], 'agreement.fi');
    const t = this.props.intl;
    const { loaded, loading } = this.props.appstatus.acceptingAgreements;

    return (
      <div className="userAgreement">
        <div style={{ textAlign: 'center' }}>
          <Dialog
            open={!loaded}
            scroll={'paper'}
            aria-labelledby="scroll-dialog-title"
          >
            <DialogTitle id="scroll-dialog-title">
              <span>
                {agreement.title}
                {agreements.length > 1 && (
                  <div style={{ float: 'right' }}>
                    {this.state.index + 1} / {agreements.length}
                  </div>
                )}
              </span>
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                className="agreementsDialogContent"
                component={'div'}
              >
                <ReactMarkdown source={agreement.text} escapeHtml={false} />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <FormControlLabel
                style={{ flexGrow: 2, marginLeft: 6 }}
                label={t.formatMessage({ id: 'agreement.accept' })}
                control={
                  <Checkbox
                    color="primary"
                    checked={this.state.checked}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      this.setState({ checked: e.target.checked });
                    }}
                  />
                }
              />
              <div style={{ position: 'relative' }}>
                <Button
                  variant="contained"
                  onClick={() => {
                    acceptAgreement(agreement.id);
                  }}
                  disabled={!this.state.checked || loading}
                  color="primary"
                >
                  <FormattedMessage id={'agreement.continue'} />
                </Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: -12,
                      marginLeft: -12,
                    }}
                  />
                )}
              </div>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(
  container.StateConnector(injectIntl(UserAgreements))
);
