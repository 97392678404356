import * as React from 'react';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import { ToolbarButton } from './Button';
import { UIDocument } from '../../../../shared/src/model/index';
import * as doc from '../../state/reducers/DocumentState';
import * as appState from '../../state/reducers/ApplicationState';
import { FormattedMessage } from 'react-intl';
import { errorMessage, successMessage } from './Notifier';
import DocumentReject from './DocumentReject';

import * as schema from '../../../../shared/src/model/projectSchema';
import PickDWG from './PickDWG';

import axios from 'axios';
import { DocumentService } from '../../services/document/src/frontend/api';
const DOCAPI = DocumentService(axios);

interface IToolbarState {
  action: 'reject' | 'download' | 'dwg' | undefined;
  activeAction?: schema.action;
}
interface IToolbarProps extends doc.IProps, appState.IProps {
  selectedDocuments: UIDocument[];
  didUpdateDocuments?: () => void;
}
type ToolbarProps = IToolbarProps & InjectedIntlProps;

class APItemToolbar extends React.Component<ToolbarProps, IToolbarState> {
  constructor(props: ToolbarProps) {
    super(props);
    this.state = { action: undefined };
  }
  downloadDocuments = () => {
    if (document && document.location) {
      document.location.href =
        `/docs/v1/documentzip/?docids=` +
        this.props.selectedDocuments.map(doc => doc.id).join(',');
    }
  };
  public render() {
    return (
      <div className="toolbar">
        <ToolbarButton
          color="primary"
          onClick={() => {
            /* move docs into oitem targettings */
            this.props.addToPitemTargets(this.props.selectedDocuments);
          }}
        >
          Valitse
        </ToolbarButton>

        <ToolbarButton
          color="primary"
          onClick={() => {
            this.props.clearPItemTargets();
          }}
        >
          Tyhjennä
        </ToolbarButton>
      </div>
    );
  }
}

class DocumentToolbar extends React.Component<ToolbarProps, IToolbarState> {
  constructor(props: ToolbarProps) {
    super(props);
    this.state = { action: undefined };
  }
  downloadDocuments = () => {
    if (document && document.location) {
      document.location.href =
        `/docs/v1/documentzip/?docids=` +
        this.props.selectedDocuments.map(doc => doc.id).join(',');
    }
  };
  public render() {
    const t = (id: string) => this.props.intl.formatMessage({ id });

    const customerDocType = this.props.appDocumentCategories
      .filter(c => c.name === 'TIL_DOC')
      .pop();

    const canSetDwg = this.props.selectedDocuments.reduce((prev, curr) => {
      if (!prev) return false;
      if (curr.fileExtension.indexOf('dwg') >= 0) {
        return false;
      }
      if (!curr.categories || !customerDocType) {
        return false;
      }
      if (!(curr.categories.indexOf(customerDocType.id) >= 0)) {
        return false;
      }
      if (curr && curr.privileges && curr.privileges.canUpdate) {
        return true;
      }
      return false;
    }, true);

    const canDelete = this.props.selectedDocuments.reduce((prev, curr) => {
      if (!prev) return false;

      if (curr.privileges && curr.privileges.canDelete) {
        return true;
      }
      return false;
    }, true);

    // count number of actions available for each selected document
    const docActions: { [key: number]: number } = {};
    // let actionsDenied = false;
    this.props.selectedDocuments.forEach(doc => {
      // if (!doc.hasDownloaded) {
      //  actionsDenied = true;
      // }
      doc.allowedActions.forEach(actionId => {
        docActions[actionId] = docActions[actionId]
          ? docActions[actionId] + 1
          : 1;
      });
    });

    // filter out available actions for current set of documents
    const currentActions = this.props.appDocumentActions.filter(field => {
      // TT: chakged the rejection also to be allowed en masse
      // if (field.action_name.indexOf('REJECT') >= 0) return false;
      if (!docActions[field.id]) return false;
      if (docActions[field.id] === this.props.selectedDocuments.length)
        return true;
      return false;
    });

    return (
      <>
        {this.props.selectedDocuments.length > 0 ? (
          <div className="toolbar">
            <ToolbarButton
              key={'add_to_basket'}
              color="primary"
              onClick={() => {
                /* move docs into loading basket */
                this.props.addToBasket(this.props.selectedDocuments);
              }}
            >
              {t('downloadBasket.addToBasket')} (
              {this.props.selectedDocuments.length})
            </ToolbarButton>
            <ToolbarButton
              key={'download'}
              color="primary"
              onClick={() => {
                this.setState({ action: 'download' });
                this.downloadDocuments();
              }}
            >
              <FormattedMessage id="buttons.downloadFiles" />
            </ToolbarButton>
            {canDelete ? (
              <ToolbarButton
                key={'can_delete'}
                color="primary"
                onClick={async () => {
                  if (!window.confirm(t('confirm.delete'))) {
                    return;
                  }
                  /** DELETE */
                  try {
                    await DOCAPI.deleteDocuments(
                      this.props.selectedDocuments.map(d => d.id)
                    );
                    successMessage(t('documentMeta.removeSuccess'));
                    if (this.props.didUpdateDocuments) {
                      this.props.didUpdateDocuments();
                    }
                  } catch (e) {
                    errorMessage(e);
                  }
                }}
              >
                {t('documentTable.delete')}
              </ToolbarButton>
            ) : null}
            {canSetDwg ? (
              <ToolbarButton
                key={'can_set_dwg'}
                color="primary"
                onClick={() => {
                  this.setState({ action: 'dwg' });
                }}
              >
                {t('documentTable.selectDWG')}
              </ToolbarButton>
            ) : null}

            {currentActions.map(act => {
              return (
                <ToolbarButton
                  key={`action_${act.id}`}
                  color="primary"
                  onClick={async () => {
                    if (
                      act.action_name === 'REJECT' ||
                      act.action_name === 'REJECT2'
                    ) {
                      this.setState({ action: 'reject', activeAction: act });
                      return;
                    }

                    if (
                      act.action_name === 'INITIAL_ACCEPT' &&
                      !window.confirm(t('documentMeta.confirmInitialAccept'))
                    ) {
                      return;
                    }

                    if (
                      act.action_name === 'ACCEPT' &&
                      !window.confirm(t('documentMeta.confirmAccept'))
                    ) {
                      return;
                    }

                    try {
                      await DOCAPI.docActionEvent({
                        documentIds: this.props.selectedDocuments.map(
                          d => d.id
                        ),
                        actionId: act.id,
                        reasonForRejection: '',
                      });
                      if (this.props.didUpdateDocuments) {
                        this.props.didUpdateDocuments();
                      }
                      // TODO: different message for "tarkastus"
                      // TODO: translate the action messages
                      successMessage(t('documentMeta.ACCEPTED'));
                    } catch (e) {
                      errorMessage(e);
                    }

                    // }
                  }}
                >
                  {t(act.action_name!)}
                </ToolbarButton>
              );
            })}
            {this.state.action === 'reject' ? (
              <DocumentReject
                activeAction={this.state.activeAction}
                documentIds={this.props.selectedDocuments.map(d => d.id)}
                onUpdate={didCancel => {
                  if (!didCancel) {
                    if (this.props.didUpdateDocuments) {
                      this.props.didUpdateDocuments();
                    }
                  }
                  this.setState({
                    action: undefined,
                    activeAction: undefined,
                  });
                }}
              />
            ) : null}
            {this.state.action === 'dwg' ? (
              <div style={{ marginLeft: '20px' }}>
                <PickDWG
                  onCancel={() => {
                    this.setState({
                      action: undefined,
                      activeAction: undefined,
                    });
                  }}
                  onSelect={async value => {
                    if (value) {
                      try {
                        await DOCAPI.linkToDwgDocs({
                          docIds: this.props.selectedDocuments.map(d => d.id),
                          dwgName: value,
                        });
                        if (this.props.didUpdateDocuments) {
                          this.props.didUpdateDocuments();
                        }
                        successMessage('DWG tiedostot päivitetty');
                      } catch (e) {
                        errorMessage(e);
                      }
                    }
                  }}
                  contractId={
                    this.props.selectedDocuments
                      .map(doc =>
                        doc.aItems && doc.aItems[0]
                          ? doc.aItems[0].id
                          : undefined
                      )
                      .filter(value => value)
                      .pop() || 0
                  }
                />
              </div>
            ) : this.state.action === 'download' ? (
              <div />
            ) : null}
          </div>
        ) : null}
      </>
    );
  }
}

export const PItemToolbar = appState.StateConnector(
  doc.StateConnector(injectIntl(APItemToolbar))
);

export default appState.StateConnector(
  doc.StateConnector(injectIntl(DocumentToolbar))
);
