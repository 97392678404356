import * as React from 'react';
import { useFormatMessage } from 'utils/translateHook';
import { List, ListItem, ListItemText } from '@material-ui/core';
// import { PitemStateContext } from './reducers/PitemState';
import { RoomPosition } from '../../../../../shared/src/model/index';

interface ItemDataProps {
  rooms: string[];
}

export function MissingRoomsInfo(props: ItemDataProps) {
  const t = useFormatMessage();
  // const ctx = React.useContext(PitemStateContext);

  const [addRoom] = React.useState<RoomPosition | undefined>(undefined);
  if (props.rooms.length === 0) return null;
  // Huoneet puuttuvat vielä, huoneiden luominen on vielä aika tärkeä osa
  /*
export interface NewPitem {
  name: string;
  name_long: string;
  parentItemId: number;
  pitemTypeId: number;

  // if targetting based on some pole range
  singlePole?: number;
  poleRange?: number[];
}  
  */

  return (
    <div>
      <h2>{t('adminTools.missingRooms')}</h2>
      <List>
        {props.rooms.map(name => (
          <React.Fragment key={name}>
            <ListItem key={name}>
              {/*<Button
                onClick={() => {
                  if (addRoom && addRoom.roomId === name) {
                    setAddRoom(undefined);
                    return;
                  }
                  setAddRoom(
                    ctx.positions.filter(r => r.roomId === name).pop()
                  );
                  console.log(ctx.positions.filter(r => r.roomId === name));
                }}
              >
                Tiedot
              </Button>*/}
              <ListItemText>{name}</ListItemText>
            </ListItem>
            {addRoom && addRoom.roomId === name ? (
              <ListItem>
                <pre>{JSON.stringify(addRoom, null, 2)}</pre>
              </ListItem>
            ) : null}
          </React.Fragment>
        ))}
      </List>
    </div>
  );
}
