import * as React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
// tslint:disable-next-line:no-var-requires
const ReactMarkdown = require('react-markdown');

interface IHelpProps {
  content: string;
  purpose?: string;
  error?: Error;
  errorInfo?: React.ErrorInfo;
}

interface IHelpState {}

type HelpProps = IHelpProps & InjectedIntlProps;

class Help extends React.Component<HelpProps, IHelpState> {
  constructor(props: HelpProps) {
    super(props);

    this.state = {};
  }

  render() {
    const t = (id: string) => this.props.intl.formatMessage({ id });
    const markdown = t(this.props.content);

    const stackTrace =
      this.props.errorInfo && this.props.errorInfo.componentStack;

    return (
      <div className={`help ${this.props.purpose}`}>
        <div className="content">
          <ReactMarkdown source={markdown} />
          {this.props.error && (
            <div className="stacktrace">
              <h2>{this.props.error.message && this.props.error.message}</h2>
              <pre>{stackTrace && stackTrace}</pre>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default injectIntl(Help);
