import * as React from 'react';
import * as container from '../../state/reducers/ApplicationState';
import * as documentState from '../../state/reducers/DocumentState';
import { FormattedMessage } from 'react-intl';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import MainLayout from '../../layout/MainLayout';
import Button from '../Common/Button';
import TextField from '../Common/TextField';
import { ValidatorForm } from 'react-material-ui-form-validator';
import * as Model from '../../../../shared/src/model';
import Edit from '@material-ui/icons/Edit';
import CloudDownload from '@material-ui/icons/CloudDownload';
import AssignmentLate from '@material-ui/icons/AssignmentLate';
import { Item } from '../Common/AutosuggestChipSelection';
import NoteAdd from '@material-ui/icons/NoteAdd';
import { Link } from 'react-router-dom';
import './inspectionDocuments.scss';
import * as _ from 'lodash';
import Loading from '../Common/Loading';
import DateRow from '../Common/DateRow';
import DocumentTextRow from '../Common/DocumentTextRow';
import StatusHistory from '../Document/StatusHistory';
import VersionHistory from '../Document/VersionHistory';

import axios from 'axios';
import { DocumentService } from '../../services/document/src/frontend/api';

import FileInput from '../Common/FileInput';
import { push } from 'connected-react-router';

import { withSnackbar, InjectedNotistackProps } from 'notistack';
import { Notifier } from '../Common/Notifier';

import ReactSelect from 'react-select';

import {
  successMessage,
  errorMessage,
  warningMessage,
} from '../Common/Notifier';
import Preview from '../Common/Preview/Preview';
import Breadcrumbs from '../Common/Breadcrumbs';
import { ValueType } from 'react-select/lib/types';
import { ShoppingBasketOutlined } from '@material-ui/icons';

const DOC = DocumentService(axios);

interface IDocumentProps
  extends container.IProps,
    documentState.IProps,
    InjectedNotistackProps {
  savedSearchName: string;
  match: {
    params: {
      id: number;
      targetId: number;
      mode?: string;
    };
  };
  location?: any;
}

interface IDocumentState {
  isEditing: boolean;
  isUploading: boolean;
  isRejecting: boolean;
  contracts: Item[];
  documentCategory: string;
  rejectReason: string;
  file: File | undefined;

  isLoaded: boolean;
  error: any;
  document?: Model.UIDocument;
  parent?: Model.UIDocument;
  grandparent?: Model.UIDocument;
  statusHistory?: Model.DocStateHistoryRow[];
  versionHistory?: Model.UIDocument[];
  successMessage?: string;
}

type DocumentProps = IDocumentProps & InjectedIntlProps;

class AbstractDocument extends React.Component<DocumentProps, IDocumentState> {
  constructor(props: DocumentProps) {
    super(props);

    this.state = {
      isEditing: false,
      isUploading: false,
      contracts: [],
      documentCategory: '',
      isRejecting: false,
      rejectReason: '',
      file: undefined,
      isLoaded: false,
      error: null,
    };

    this.props.getUserContracts();
  }

  async refreshDocument() {
    try {
      const document = await DOC.fetchDocument(this.props.match.params.id);
      const versionHistory = (
        await DOC.getVersionHistory(this.props.match.params.id)
      ).sort((a, b) => {
        // Latest first
        if (b.versionNumber < a.versionNumber) return -1;
        if (b.versionNumber > a.versionNumber) return 1;
        return 0;
      });
      const statusHistory = await DOC.fetchDocumentHistory(
        this.props.match.params.id
      );

      const parent =
        (document.parentId && (await DOC.fetchDocument(document.parentId))) ||
        undefined;

      const grandparent =
        (parent &&
          parent.parentId &&
          (await DOC.fetchDocument(parent.parentId))) ||
        undefined;

      this.setState({
        isLoaded: true,
        document,
        parent,
        grandparent,
        versionHistory,
        statusHistory,
      });
    } catch (e) {
      this.setState({ isLoaded: true, error: e });
    }
  }

  async componentDidMount() {
    this.refreshDocument();
    this.props.getMetadata();
  }

  downloadDocument = (activeDocument: Model.UIDocument) => {
    if (document && document.location) {
      document.location.href = `/docs/v1${activeDocument.downloadUrl}`;
    }
  };

  onChangeNewDocument = (files: File[]) => {
    if (files) {
      this.setState({ file: files[0] });
    }
  };

  toState = async (
    activeDocument: Model.UIDocument,
    stateId: number,
    reason: string
  ) => {
    if (this.state.document) {
      try {
        this.setState({
          isLoaded: false,
        });
        await DOC.changeDocState({
          docid: this.state.document.id,
          newStateId: stateId,
          reasonForRejection: reason,
        });
        // Then fetch the history
        const versionHistory = (
          await DOC.getVersionHistory(this.state.document.id)
        ).sort((a, b) => {
          // Latest first
          if (b.versionNumber < a.versionNumber) return -1;
          if (b.versionNumber > a.versionNumber) return 1;
          return 0;
        });
        const statusHistory = await DOC.fetchDocumentHistory(
          this.state.document.id
        );
        this.refreshDocument();
        this.setState({
          versionHistory,
          statusHistory,
          isLoaded: true,
        });
      } catch (e) {
        errorMessage(e);
        this.setState({
          isLoaded: true,
          error: e,
        });
      }
    }
  };

  toggleEditMode = () => {
    this.setState({ isEditing: !this.state.isEditing });
  };

  toggleNewRevisionUpload = () => {
    this.setState({
      isUploading: !this.state.isUploading,
      isEditing: false,
    });
  };

  handleCancelEdit = () => {
    this.setState({ isEditing: false, isUploading: false });
    this.refreshDocument();
  };

  onChangeDocumentCategoryId = (cat: Item) => {
    if (this.state.document) {
      const activeDoc = this.state.document;
      const preserve = this.props.designDisciplines
        .filter((item) =>
          activeDoc.categories
            ? activeDoc.categories.indexOf(item.id) >= 0
            : false
        )
        .map((item) => item.id);
      if (cat) {
        this.setKey('categories', [...preserve, cat.value]);
      }
    }
  };

  async uploadNewDocumentRevision(file: File) {
    if (!file) return;
    this.setState({
      isLoaded: false,
    });
    const t = (id: string) => this.props.intl.formatMessage({ id });

    const { targetId } = this.props.match.params;
    const formData = new FormData();
    formData.append('info', JSON.stringify(this.state.document));
    formData.append('file', file);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };

    try {
      const result = (
        await axios.post('/docs/v1/upload/nextrevision', formData, config)
      ).data as Model.UIDocument[];
      const newDoc = result[0];
      const history = await DOC.fetchDocumentHistory(newDoc.id);
      this.props.DocumentStateDispatcher(
        push(
          '/inspection-documents/target/' +
            targetId +
            '/attachment/' +
            newDoc.id
        )
      );
      this.setState({
        document: newDoc,
        isLoaded: true,
        statusHistory: history,
      });
      this.props.updateActiveDocument(newDoc);
      successMessage(t('inspectionDocuments.updateDocumentSuccess'));
      this.setState({
        isEditing: false,
        isUploading: false,
      });
      this.props.noOp();
    } catch (e) {
      errorMessage(e);
      this.setState({
        error: e,
        isLoaded: true,
      });
      this.props.noOp();
    }
  }

  async saveTargetAttachment() {
    const t = (id: string) => this.props.intl.formatMessage({ id });

    try {
      if (this.state.document) {
        this.setState({
          isLoaded: false,
        });
        const nextDoc = await DOC.updateDocument(this.state.document);

        const { targetId } = this.props.match.params;
        this.props.DocumentStateDispatcher(
          push(
            '/inspection-documents/target/' +
              targetId +
              '/attachment/' +
              nextDoc.id
          )
        );
        const history = await DOC.fetchDocumentHistory(this.state.document.id);
        this.setState({
          isLoaded: true,
          document: nextDoc,
          statusHistory: history,
        });
        successMessage(t('inspectionDocuments.addNewAttachmentSuccess'));
        this.props.updateActiveDocument(this.state.document);
        this.setState({ isEditing: false });
      }
    } catch (e) {
      this.setState({
        error: e,
        isLoaded: true,
      });
      if (e.message) {
        errorMessage(e.message);
      } else {
        errorMessage(String(e));
      }
    }
  }

  handleSave = () => {
    const t = (id: string) => this.props.intl.formatMessage({ id });

    if (this.state.isUploading) {
      if (this.state.file) {
        this.uploadNewDocumentRevision(this.state.file);
      } else {
        warningMessage(t('inspectionDocuments.selectFile'));
      }
    } else {
      this.saveTargetAttachment();
    }
  };

  onRejectReasonChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ rejectReason: e.currentTarget.value });
  };

  setKey<K extends keyof Model.UIDocument>(key: K, value: Model.UIDocument[K]) {
    if (this.state.document) {
      this.setState({
        document: {
          ...this.state.document,
          [key]: value,
        },
      });
    }
  }

  onDocTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setKey('name', e.currentTarget.value);
  };

  onDocDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setKey('description', e.currentTarget.value);
  };

  componentDidUpdate() {
    if (this.state.document && this.state.isLoaded) {
      if (
        parseInt(String(this.props.match.params.id), 10) !==
        this.state.document.id
      ) {
        this.setState(
          {
            isLoaded: false,
          },
          () => {
            this.refreshDocument();
          }
        );
      }
      //
    }
  }

  render() {
    const t = (id: string) => this.props.intl.formatMessage({ id });

    const { isEditing, isUploading } = this.state;
    const activeDocument = this.state.document;
    const loading = !this.state.isLoaded;
    const loaded = this.state.isLoaded;

    const categories = this.props.inspectionDocCategories;

    const selectedCategories = categories.filter((cat) => {
      if (this.state.document && this.state.document.categories) {
        return this.state.document.categories.indexOf(cat.id) >= 0;
      }
      return false;
    });

    const notFound = !activeDocument && !loading && !loaded;
    const currentState = this.props.appDocumentStates.filter((s) => {
      return this.state.document
        ? this.state.document.workflowStateId === s.id
        : false;
    });

    const isAcceptedOrRejected =
      currentState.length > 0 &&
      (currentState[0].name === 'ACCEPTED' ||
        currentState[0].name === 'REJECTED');

    const canEdit =
      activeDocument &&
      activeDocument.privileges &&
      activeDocument.privileges.canUpdate;

    const { parent, grandparent } = this.state;
    const targetName =
      (parent && parent.name) || this.props.match.params.targetId;

    return (
      <MainLayout>
        <Breadcrumbs>
          <Link to="/inspection-documents">
            <FormattedMessage id="inspectionDocuments.inspectionDocuments" />
          </Link>
          <FormattedMessage id="inspectionDocuments.targets" />
          <Link
            to={`/inspection-documents/target/${this.props.match.params.targetId}`}
          >
            {targetName}
          </Link>
          {activeDocument && (
            <Link
              to={`/inspection-documents/target/${this.props.match.params.targetId}/attachment/${activeDocument.id}`}
            >
              {activeDocument.name}
            </Link>
          )}
        </Breadcrumbs>

        <h1>
          <FormattedMessage id="headers.document" />
        </h1>

        {this.state.successMessage ? (
          <Notifier
            snack={{
              variant: 'success',
              message: this.state.successMessage,
            }}
          />
        ) : null}
        <div className={'grid gridCols2'}>
          {notFound && <FormattedMessage id="warning.documentNotFound" />}
          {loading ? <Loading loading={true} /> : null}

          {activeDocument && (
            <div className="documentMetaContainer">
              <div className="documentMeta component">
                <h2>
                  <span>{activeDocument.name}</span>
                </h2>

                <div className="actions">
                  {canEdit && (
                    <>
                      {!isAcceptedOrRejected ? (
                        <Button
                          disabled={isUploading}
                          variant="text"
                          color="primary"
                          onClick={this.toggleEditMode}
                        >
                          <Edit
                            style={{ marginTop: '-3px', marginRight: '8px' }}
                          />
                          <FormattedMessage id="buttons.edit" />
                        </Button>
                      ) : null}
                      <Button
                        disabled={isEditing}
                        variant="text"
                        color="primary"
                        onClick={this.toggleNewRevisionUpload}
                      >
                        <NoteAdd
                          style={{ marginTop: '-3px', marginRight: '8px' }}
                        />
                        <FormattedMessage id="documentMeta.updateRevision" />
                      </Button>
                    </>
                  )}
                  <Button
                    variant="text"
                    color="primary"
                    onClick={() => {
                      this.props.addToBasket([activeDocument]);
                    }}
                  >
                    <ShoppingBasketOutlined
                      style={{ marginTop: '-3px', marginRight: '8px' }}
                    />{' '}
                    <FormattedMessage id="downloadBasket.addToBasket" />
                  </Button>
                  <Button
                    variant="text"
                    color="primary"
                    onClick={() => this.downloadDocument(activeDocument)}
                  >
                    <CloudDownload
                      style={{ marginTop: '-3px', marginRight: '8px' }}
                    />
                    <FormattedMessage id="documentMeta.downloadLink" />
                  </Button>

                  {this.props.appDocumentActions.map((field) => {
                    if (activeDocument.allowedActions.indexOf(field.id) < 0) {
                      return null;
                    }
                    return (
                      <Button
                        key={field.action_name!}
                        variant="text"
                        color="primary"
                        onClick={() => {
                          if (field.id === 6) {
                            this.setState({ isRejecting: true });
                          } else {
                            this.toState(
                              activeDocument,
                              field.to_state_id!,
                              ''
                            );
                          }
                        }}
                      >
                        <AssignmentLate
                          style={{ marginTop: '-3px', marginRight: '8px' }}
                        />
                        {t(field.action_name!)}
                      </Button>
                    );
                  })}
                </div>

                <div className="content">
                  {this.state.isRejecting ? (
                    <div className="isRejecting">
                      <h3>
                        <FormattedMessage id="documentMeta.rejectReason" />
                      </h3>
                      <div>
                        <TextField
                          multiline={true}
                          value={this.state.rejectReason}
                          onChange={this.onRejectReasonChange}
                        />
                      </div>
                      <div className="editActions">
                        <Button
                          color="primary"
                          type="submit"
                          // Disable save button when no reject reason is given
                          disabled={!this.state.rejectReason.trim()}
                          onClick={() => {
                            this.setState({ isRejecting: false });
                            this.toState(
                              activeDocument,
                              6,
                              this.state.rejectReason
                            );
                          }}
                        >
                          <FormattedMessage id="buttons.save" />
                        </Button>
                        <Button
                          color="primary"
                          onClick={(e) => this.setState({ isRejecting: false })}
                        >
                          <FormattedMessage id="buttons.cancel" />
                        </Button>
                      </div>
                    </div>
                  ) : null}

                  <ValidatorForm onSubmit={this.handleSave}>
                    {isUploading && (
                      <FileInput
                        style={{ height: '80px' }}
                        id="updateDocument"
                        single={true}
                        label={t('upload.dropzoneInfoSingle')}
                        onChange={this.onChangeNewDocument}
                      />
                    )}

                    <dl>
                      <dt>
                        <FormattedMessage id="documentMeta.name" />
                      </dt>
                      <dd>
                        {isEditing ? (
                          <TextField
                            value={activeDocument.name}
                            onChange={this.onDocTitleChange}
                          />
                        ) : (
                          activeDocument.name
                        )}
                      </dd>

                      <dt>
                        <FormattedMessage id="documentMeta.contract" />
                      </dt>
                      <dd>
                        {activeDocument.aItems
                          .map((item) => item.name)
                          .join(' ') || '-'}
                      </dd>

                      <dt>
                        <FormattedMessage id="inspectionDocuments.target" />
                      </dt>
                      <dd>
                        {(parent && (
                          <a href={`/inspection-documents/target/${parent.id}`}>
                            {parent.name}
                          </a>
                        )) ||
                          '-'}
                      </dd>

                      <dt>
                        <FormattedMessage id="inspectionDocuments.documentName" />
                      </dt>
                      <dd>{(grandparent && grandparent.name) || '-'}</dd>

                      <dt>
                        <FormattedMessage id="inspectionDocuments.inspectionContent" />
                      </dt>
                      <dd>{(grandparent && grandparent.description) || '-'}</dd>

                      <dt>
                        <FormattedMessage id="documentMeta.documentCategory" />
                      </dt>
                      <dd>
                        {isEditing ? (
                          <ReactSelect
                            value={selectedCategories
                              .map((item) => ({
                                value: item.id,
                                label: t(item.name),
                              }))
                              .pop()}
                            options={
                              categories &&
                              categories.map((type) => {
                                return {
                                  label: t(type.name),
                                  value: type.id,
                                };
                              })
                            }
                            onChange={(value: ValueType<Item>) => {
                              if (value) {
                                if (!Array.isArray(value)) {
                                  this.onChangeDocumentCategoryId(
                                    value as Item
                                  );
                                }
                              }
                            }}
                          />
                        ) : (
                          <>
                            {activeDocument.categoryNames
                              ? t(
                                  activeDocument.categoryNames[
                                    activeDocument.categoryNames.length - 1
                                  ]
                                )
                              : '-'}
                          </>
                        )}
                      </dd>

                      <dt>
                        <FormattedMessage id="documentMeta.workflowState" />
                      </dt>
                      <dd>
                        {t(`documentMeta.${activeDocument.workflowStateName}`)}
                      </dd>

                      <DateRow
                        id="documentMeta.created"
                        value={activeDocument.created}
                      />
                      <dt>
                        <FormattedMessage id="documentMeta.createdByUser" />
                      </dt>
                      <dd>{activeDocument.createdByUser || '-'}</dd>
                      <DateRow
                        id="documentMeta.modified"
                        value={activeDocument.modified}
                      />
                      <dt>
                        <FormattedMessage id="documentMeta.modifiedByUser" />
                      </dt>
                      <dd>{activeDocument.modifiedByUser || '-'}</dd>

                      <DocumentTextRow
                        id={'documentMeta.version'}
                        value={String(activeDocument.versionNumber)}
                      />

                      <dt>
                        <FormattedMessage id="documentMeta.description" />
                      </dt>
                      <dd>
                        {isEditing ? (
                          <TextField
                            multiline={true}
                            value={activeDocument.description || ''}
                            onChange={this.onDocDescriptionChange}
                          />
                        ) : (
                          activeDocument.description || '-'
                        )}
                      </dd>
                    </dl>

                    {(isEditing || isUploading) && (
                      <div className="editActions">
                        <Button color="primary" onClick={this.handleCancelEdit}>
                          <FormattedMessage id="buttons.cancel" />
                        </Button>

                        <Button color="primary" type="submit">
                          <FormattedMessage id="buttons.save" />
                        </Button>
                      </div>
                    )}
                  </ValidatorForm>
                </div>
              </div>
              <div className="spacer">
                <div className="spacer">
                  <StatusHistory rows={this.state.statusHistory || []} />
                </div>
              </div>
              {this.state.document && (
                <div className="spacer">
                  <VersionHistory
                    rows={this.state.versionHistory || []}
                    linkPrefix={`/inspection-documents/target/${this.props.match.params.targetId}/attachment/`}
                  />
                </div>
              )}
            </div>
          )}

          {activeDocument && <Preview doc={activeDocument} />}
        </div>
      </MainLayout>
    );
  }
}

export default documentState.StateConnector(
  container.StateConnector(injectIntl(withSnackbar(AbstractDocument)))
);
