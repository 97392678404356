/**********************************************************************************
 *                                                                                 *
 *   Redux Reducers and React Context API Provider/Consumer for state ErrorState   *
 *   Generated by ts2redux from Source file ../ErrorState.ts                       *
 *                                                                                 *
 **********************************************************************************/

import * as immer from 'immer';
import { connect } from 'react-redux';
import { IState } from './index';
import * as React from 'react';
import * as _ from 'lodash';

export enum NotificationMessageType {
  error = 'error',
  warning = 'warning',
  success = 'success',
  info = 'info',
}

export interface NotificationMessage {
  messageType: NotificationMessageType;
  message: string;
  timeout?: number;
  hideAfter?: number;
  id: number;
}

const MAX_NOTIFICATION_DURATION = 1000 * 3 * 60 * 12;

/**
 * @redux true
 */
export class ErrorState {
  cnt = 0;
  messages: NotificationMessage[] = [];

  get getMessages() {
    return _.uniqBy(this.messages, (item) => item.message + item.messageType);
  }

  filterOldMessages() {
    const timeNow = Date.now();
    const newMessages = this.messages.filter(
      (m) => (m.hideAfter || 0) > timeNow
    );
    if (newMessages.length !== this.messages.length) {
      this.messages = newMessages;
    }
  }

  addMessage(newMessage: Omit<NotificationMessage, 'id'>) {
    this.cnt++;
    const msg: NotificationMessage = {
      ...newMessage,
      id: this.cnt,
      hideAfter: newMessage.timeout
        ? Date.now() + newMessage.timeout * 1000
        : Date.now() + MAX_NOTIFICATION_DURATION,
    };
    this.messages.push(msg);
  }

  clearMessages() {
    this.messages = [];
  }
}

export interface IContainerPropsMethods {
  filterOldMessages: () => any;
  addMessage: (newMessage: Omit<NotificationMessage, 'id'>) => any;
  clearMessages: () => any;
}
export interface IErrorState {
  cnt: number;
  messages: NotificationMessage[];
}

export type IContainerPropsState = IErrorState;
export interface IProps extends IContainerPropsState, IContainerPropsMethods {}

function pick<T, K extends keyof T>(o: T, ...props: K[]) {
  return props.reduce((a, e) => ({ ...a, [e]: o[e] }), {}) as Pick<T, K>;
}
export function mapStateToPropsWithKeys<K extends keyof IContainerPropsState>(
  state: IState,
  keys: K[]
): Pick<IContainerPropsState, K> {
  return pick(state.ErrorState as IContainerPropsState, ...keys);
}

export const mapStateToProps = (state: IState): IContainerPropsState => {
  return {
    cnt: state.ErrorState.cnt,
    messages: state.ErrorState.messages,
  };
};

function mapDispatchToPropsWithKeys<K extends keyof IContainerPropsMethods>(
  dispatch: any,
  keys: K[]
): Pick<IContainerPropsMethods, K> {
  return pick(mapDispatchToProps(dispatch), ...keys);
}

export const mapDispatchToProps = (dispatch: any): IContainerPropsMethods => {
  return {
    filterOldMessages: () => {
      return dispatch(RErrorState.filterOldMessages());
    },
    addMessage: (newMessage: Omit<NotificationMessage, 'id'>) => {
      return dispatch(RErrorState.addMessage(newMessage));
    },
    clearMessages: () => {
      return dispatch(RErrorState.clearMessages());
    },
  };
};

export function ConnectKeys<
  K extends keyof IErrorState,
  J extends keyof IContainerPropsMethods
>(keys: K[], methods: J[]) {
  return connect(
    (state: IState) => mapStateToPropsWithKeys(state, keys),
    (dispatch: any) => mapDispatchToPropsWithKeys(dispatch, methods)
  );
}

export const StateConnector = connect(mapStateToProps, mapDispatchToProps);

const initErrorState = () => {
  const o = new ErrorState();
  return {
    cnt: o.cnt,
    messages: o.messages,
  };
};
const initWithMethodsErrorState = () => {
  const o = new ErrorState();
  return {
    cnt: o.cnt,
    messages: o.messages,
    filterOldMessages: o.filterOldMessages,
    addMessage: o.addMessage,
    clearMessages: o.clearMessages,
  };
};

/**
 * @generated true
 */
export class RErrorState {
  private _state?: IErrorState;
  private _dispatch?: <A extends {}, T extends {}>(action: A) => T;
  private _getState?: () => any;
  constructor(
    state?: IErrorState,
    dispatch?: (action: any) => any,
    getState?: () => any
  ) {
    this._state = state;
    this._dispatch = dispatch;
    this._getState = getState;
  }
  get cnt(): number {
    if (this._getState) {
      return this._getState().ErrorState.cnt;
    } else {
      if (this._state) {
        return this._state.cnt;
      }
    }
    throw new Error('Invalid State in ErrorState_cnt');
  }
  set cnt(value: number) {
    if (this._state && typeof value !== 'undefined') {
      this._state.cnt = value;
    } else {
      // dispatch change for item cnt
      if (this._dispatch) {
        this._dispatch({
          type: ErrorStateEnums.ErrorState_cnt,
          payload: value,
        });
      }
    }
  }
  get messages(): NotificationMessage[] {
    if (this._getState) {
      return this._getState().ErrorState.messages;
    } else {
      if (this._state) {
        return this._state.messages;
      }
    }
    throw new Error('Invalid State in ErrorState_messages');
  }
  set messages(value: NotificationMessage[]) {
    if (this._state && typeof value !== 'undefined') {
      this._state.messages = value;
    } else {
      // dispatch change for item messages
      if (this._dispatch) {
        this._dispatch({
          type: ErrorStateEnums.ErrorState_messages,
          payload: value,
        });
      }
    }
  }

  filterOldMessages() {
    if (this._state) {
      const timeNow = Date.now();
      const newMessages = this.messages.filter(
        (m) => (m.hideAfter || 0) > timeNow
      );
      if (newMessages.length !== this.messages.length) {
        this.messages = newMessages;
      }
    } else {
      if (this._dispatch) {
        this._dispatch({ type: ErrorStateEnums.ErrorState_filterOldMessages });
      }
    }
  }

  public static filterOldMessages() {
    return (dispatcher: any, getState: any) => {
      return new RErrorState(
        undefined,
        dispatcher,
        getState
      ).filterOldMessages();
    };
  }
  addMessage(newMessage: Omit<NotificationMessage, 'id'>) {
    if (this._state) {
      this.cnt++;
      const msg: NotificationMessage = {
        ...newMessage,
        id: this.cnt,
        hideAfter: newMessage.timeout
          ? Date.now() + newMessage.timeout * 1000
          : Date.now() + MAX_NOTIFICATION_DURATION,
      };
      this.messages.push(msg);
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: ErrorStateEnums.ErrorState_addMessage,
          payload: newMessage,
        });
      }
    }
  }

  public static addMessage(newMessage: Omit<NotificationMessage, 'id'>) {
    return (dispatcher: any, getState: any) => {
      return new RErrorState(undefined, dispatcher, getState).addMessage(
        newMessage
      );
    };
  }
  clearMessages() {
    if (this._state) {
      this.messages = [];
    } else {
      if (this._dispatch) {
        this._dispatch({ type: ErrorStateEnums.ErrorState_clearMessages });
      }
    }
  }

  public static clearMessages() {
    return (dispatcher: any, getState: any) => {
      return new RErrorState(undefined, dispatcher, getState).clearMessages();
    };
  }
}

export const ErrorStateEnums = {
  ErrorState_cnt: 'ErrorState_cnt',
  ErrorState_messages: 'ErrorState_messages',
  ErrorState_filterOldMessages: 'ErrorState_filterOldMessages',
  ErrorState_addMessage: 'ErrorState_addMessage',
  ErrorState_clearMessages: 'ErrorState_clearMessages',
};

export const ErrorStateReducer = (
  state: IErrorState = initErrorState(),
  action: any
) => {
  return immer.produce(state, (draft: IErrorState) => {
    switch (action.type) {
      case ErrorStateEnums.ErrorState_cnt:
        new RErrorState(draft).cnt = action.payload;
        break;
      case ErrorStateEnums.ErrorState_messages:
        new RErrorState(draft).messages = action.payload;
        break;
      case ErrorStateEnums.ErrorState_filterOldMessages:
        new RErrorState(draft).filterOldMessages();
        break;
      case ErrorStateEnums.ErrorState_addMessage:
        new RErrorState(draft).addMessage(action.payload);
        break;
      case ErrorStateEnums.ErrorState_clearMessages:
        new RErrorState(draft).clearMessages();
        break;
    }
  });
};
/********************************
 * React Context API component   *
 ********************************/
export const ErrorStateContext = React.createContext<IProps>(
  initWithMethodsErrorState()
);
export const ErrorStateConsumer = ErrorStateContext.Consumer;
let instanceCnt = 1;
export class ErrorStateProvider extends React.Component {
  public state: IErrorState = initErrorState();
  public lastSetState: IErrorState;
  private __devTools: any = null;
  constructor(props: any) {
    super(props);
    this.lastSetState = this.state;
    this.filterOldMessages = this.filterOldMessages.bind(this);
    this.addMessage = this.addMessage.bind(this);
    this.clearMessages = this.clearMessages.bind(this);
    const devs = window['__REDUX_DEVTOOLS_EXTENSION__']
      ? window['__REDUX_DEVTOOLS_EXTENSION__']
      : null;
    if (devs) {
      this.__devTools = devs.connect({ name: 'ErrorState' + instanceCnt++ });
      this.__devTools.init(this.state);
      this.__devTools.subscribe((msg: any) => {
        if (msg.type === 'DISPATCH' && msg.state) {
          this.setState(JSON.parse(msg.state));
        }
      });
    }
  }
  public componentWillUnmount() {
    if (this.__devTools) {
      this.__devTools.unsubscribe();
    }
  }
  public setStateSync(state: IErrorState) {
    this.lastSetState = state;
    this.setState(state);
  }
  filterOldMessages() {
    const nextState = immer.produce(this.state, (draft: IErrorState) =>
      new RErrorState(draft).filterOldMessages()
    );
    if (this.__devTools) {
      this.__devTools.send('filterOldMessages', nextState);
    }
    this.setStateSync(nextState);
  }
  addMessage(newMessage: Omit<NotificationMessage, 'id'>) {
    const nextState = immer.produce(this.state, (draft: IErrorState) =>
      new RErrorState(draft).addMessage(newMessage)
    );
    if (this.__devTools) {
      this.__devTools.send('addMessage', nextState);
    }
    this.setStateSync(nextState);
  }
  clearMessages() {
    const nextState = immer.produce(this.state, (draft: IErrorState) =>
      new RErrorState(draft).clearMessages()
    );
    if (this.__devTools) {
      this.__devTools.send('clearMessages', nextState);
    }
    this.setStateSync(nextState);
  }
  public render() {
    return (
      <ErrorStateContext.Provider
        value={{
          ...this.state,
          filterOldMessages: this.filterOldMessages,
          addMessage: this.addMessage,
          clearMessages: this.clearMessages,
        }}
      >
        {' '}
        {this.props.children}
      </ErrorStateContext.Provider>
    );
  }
}
