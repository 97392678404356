import * as React from 'react';
import MainLayout from '../../layout/MainLayout';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { DocumentService } from '../../services/document/src/frontend/api';

import * as Model from '../../../../shared/src/model';
import { collectDocumentMetadata } from '../../utils/metadata';
import './document.scss';
import DocumentPackage from './DocumentPackage';
import { Button, TextField } from '@material-ui/core';
import { PriorityHigh } from '@material-ui/icons';
import { Async } from '../Common/Async';
import './document.scss';
import Breadcrumbs from '../Common/Breadcrumbs';
import { errorMessage } from '../Common/Notifier';
import { APIService } from '../../services/api/src/frontend/api';
import { useFormatMessage } from 'utils/translateHook';
import Moment from 'react-moment';
import { useAppContext } from 'utils/AppContextProvider';
import ReactSelect from 'react-select';
import { Item } from '../../../../shared/src/model';
import { useImmer } from 'use-immer';
import { ValueType } from 'react-select/lib/types';
import * as schema from '../../../../shared/src/model/projectSchema';

const DOC = DocumentService(axios);
const API = APIService(axios);

interface IPitemProps {
  match: {
    params: {
      itemId: number;
      mode?: string;
    };
  };
}

/*
export interface DeviceListRow {
  // [Cells not read from spreadsheet]
  readMetaInfo?: boolean; // Ominaisuustiedot luetaan

  oldId?: string; // database old_id to be used if there are more than one targets
  newOldId?: string; // database old_id to be used if there are more than one targets

  targetcode: string; // Uusi kohdetunnus
  // [apu]
  oldTargetcode?: string; // [Kohdetunnus]
  pitemTypeName?: string; // Kohdetyyppi
  description?: string; // Kohteen kuvaus
  priority?: string; // Prioriteetti
  // [Ylijärjestelmä]
  // [Järjestelmän ID]
  system?: string; // Järjestelmän nimi
  upperEquipment?: string; // Päälaite
  // [Huonetunnus]
  buildingElement: string; // Rakennusosa
  location?: string; // Tilan nimi
  // [Huoltoalueen osa]
  maintenanceArea?: string; // Huoltoalue
  // [Dokumenttien lkm]
  documents?: string[]; // Dokumentit
  warrantyExpires?: Date; // Takuu päättyy
  secondaryHierarchy?: string;
  additionalInformation?: string; // Lisätieto1
  additionalInformation2?: string; // Lisätieto2

  roomPoleStart?: string; // informative start pole
  roomPoleEnd?: string; // informative end pole

  specifier?: string;
}
*/

export const PitemView: React.FC<IPitemProps> = (props) => {
  // Hooks
  const appState = useAppContext();
  const t = useFormatMessage();
  const [editMode, setEditMode] = React.useState(false);
  const [contracts, setContracts] = React.useState<schema.aitem[]>([]);

  // create immer state or reducer ?
  const [data, setData] = useImmer<Partial<Model.DeviceListRow>>({});

  const [resetWarranty, setResetWarranty] = React.useState<boolean | undefined>(
    undefined
  );
  const [doc, setDoc] = React.useState<Model.UIDocument>();
  const docKey = React.useMemo(() => JSON.stringify(doc), [doc]);
  const refreshDocument = async () => {
    try {
      const doc = await DOC.fetchPitem(props.match.params.itemId);
      setDoc(doc);
    } catch (e) {
      errorMessage(e);
    }
  };
  React.useEffect(() => {
    refreshDocument();
  }, []);

  const resetValues = React.useCallback(() => {
    if (doc) {
      setData((prod) => {
        prod.targetcode = doc.name;
        prod.specifier = doc.specifier ? doc.specifier : undefined;
        prod.priority = doc.criticality_class ? doc.criticality_class : '';
        prod.description = doc.description;
        console.log('warrantyExpires', doc.warranty_expires);
        prod.warrantyExpires = doc.warranty_expires
          ? new Date(doc.warranty_expires)
          : undefined;
        prod.pitemTypeName = appState.equipmentTypes
          .filter((t) => t.name === doc.pitemTypeName)
          .map((t) => t.name)
          .join('');
        prod.additionalInformation = doc.additionalInformation
          ? doc.additionalInformation
          : undefined;
        prod.additionalInformation2 = doc.additionalInformation2
          ? doc.additionalInformation2
          : undefined;
        prod.maintenanceArea =
          doc.areas && doc.areas.length > 0
            ? doc.areas[0].area_name || ''
            : undefined;
        prod.readMetaInfo = true;
        prod.system = appState.systems
          .filter((sys) => sys.id === doc.systemId)
          .map((sys) => sys.system_name)
          .join('');
        prod.roomPoleStart = doc.startPole ? String(doc.startPole) : undefined;
        prod.roomPoleEnd = doc.endPole ? String(doc.endPole) : undefined;
        prod.oldTargetcode = undefined;
        prod.location = '';

        prod.workAItems = doc.workAitemIds
          ? doc.workAitemIds.map((a_id) => {
              return contracts
                .filter((c) => c.id === a_id)
                .map((c) => {
                  return c.name;
                })
                .join('');
            })
          : [];
      });
      console.log(data);
    }
  }, [doc, appState.systems, contracts]);

  const saveData = React.useCallback(async () => {
    try {
      if (!data || !data.targetcode) {
        errorMessage(t('pitem.code'));
        return;
      }
      await API.updateDevices([data as Model.DeviceListRow]);
      refreshDocument();
      setEditMode(false);
    } catch (e) {
      errorMessage(e);
    }
  }, [data]);

  // derive data from the document
  React.useEffect(() => {
    resetValues();
  }, [doc, contracts]);

  const getContracts = async () => {
    if (contracts.length === 0) {
      setContracts(await API.getContracts());
    }
  };

  React.useEffect(() => {
    if (editMode) {
      getContracts();
    }
  }, [editMode]);

  React.useEffect(() => {
    if (appState.user && appState.user.is_superuser) {
      getContracts();
    }
  }, [appState.user]);

  // wait fo the contracts lsit if superuser
  if (
    !doc ||
    (appState.user && appState.user.is_superuser && contracts.length === 0)
  ) {
    return <MainLayout />;
  }

  const savePitem = async (newDoc: Model.UIDocument) => {
    try {
      if (newDoc) {
        API.setTrackedStatus({
          id: newDoc.id,
          isTracked: newDoc.isTracked ? true : false,
        });
      }
    } catch (e) {
      if (newDoc) {
        setDoc({
          ...newDoc,
          isTracked: !newDoc.isTracked,
        });
      }

      errorMessage(e.message ? e.message : String(e));
    }
  };

  const setTrackedStatus = () => {
    if (doc) {
      const newDoc = { ...doc, isTracked: !doc.isTracked };
      setDoc(newDoc);
      savePitem(newDoc);
    }
  };

  const kohdeTyyppi = appState.pitemTypes
    .filter((t) => t.name === 'Kohde')
    .pop();

  const getPitemTypename = (id: number, useKohde = true): string => {
    const possibleType = appState.pitemTypes.filter((t) => t.id === id).pop();
    if (!possibleType) {
      return '';
    }
    if (
      useKohde &&
      kohdeTyyppi &&
      possibleType.upper_type_id === kohdeTyyppi.id
    ) {
      return t('pitem.target');
    }
    return possibleType.name || '';
  };

  const isTracked = doc && doc.isTracked;

  // Get aitem names
  const workAitems =
    doc && doc.workAitemIds && doc.workAitemIds.length > 0
      ? appState.userContracts
          .filter(
            (contract) =>
              doc &&
              doc.workAitemIds &&
              doc.workAitemIds.indexOf(contract.aitem_id) > -1
          )
          .map((aitem) => aitem.aitem_name)
          .join(', ')
      : '-';

  return (
    <MainLayout>
      <Async
        key={docKey}
        loader={() => collectDocumentMetadata(doc)}
        render={(meta) => {
          if (!meta) {
            appState.getDocumentCategories();
            return <div />;
          }
          return (
            <>
              <Breadcrumbs>
                <Link to="/">{t('headers.home')}</Link>
                <span>{t('pitem.targets')}</span>
                <span>{doc.name}</span>
              </Breadcrumbs>

              <h1>{t('headers.item')}</h1>
              {isTracked && (
                <h2 className="isTracked">
                  <span className="dot red" />
                  {t('pitem.isTracked')}
                </h2>
              )}

              <div className="grid gridCols3">
                <div className="documentMetaContainer">
                  <div className="documentMeta component">
                    <h2>
                      <span>{doc.name}</span>
                    </h2>
                    <div className="actions">
                      {appState.user && appState.user.is_superuser && (
                        <>
                          <Button
                            onClick={() => {
                              if (editMode) {
                                resetValues();
                              }
                              setEditMode(!editMode);
                            }}
                          >
                            {t('buttons.edit')}
                          </Button>
                          {isTracked ? (
                            <Button
                              variant="text"
                              color="primary"
                              onClick={() => {
                                setTrackedStatus();
                              }}
                            >
                              <PriorityHigh
                                style={{
                                  marginTop: '-3px',
                                  marginRight: '8px',
                                }}
                              />
                              {t('buttons.unsetAsTracked')}
                            </Button>
                          ) : (
                            <Button
                              variant="text"
                              color="primary"
                              onClick={() => {
                                setTrackedStatus();
                              }}
                            >
                              <PriorityHigh
                                style={{
                                  marginTop: '-3px',
                                  marginRight: '8px',
                                }}
                              />
                              {t('buttons.setAsTracked')}
                            </Button>
                          )}
                        </>
                      )}
                    </div>
                    <div className="content">
                      {editMode ? (
                        <form>
                          <dl>
                            <dt>{t('pitem.code')}</dt>
                            <dd>
                              <TextField
                                value={data.targetcode}
                                fullWidth={true}
                                onChange={(e) => {
                                  const tv = e.target.value;
                                  setData((prod) => {
                                    if (tv === doc.name) {
                                      prod.oldTargetcode = undefined;
                                    } else {
                                      prod.oldTargetcode = doc.name;
                                    }
                                    prod.targetcode = tv;
                                    // prod. = e.target.value
                                  });
                                }}
                              />
                            </dd>

                            <dt>{t('documentMeta.name_long')}</dt>
                            <dd>
                              <TextField
                                value={data.description}
                                fullWidth={true}
                                onChange={(e) => {
                                  const tv = e.target.value;
                                  setData((prod) => {
                                    prod.description = tv;
                                  });
                                }}
                              />
                            </dd>

                            <dt>{t('documentMeta.system')}</dt>

                            <dd>
                              <ReactSelect
                                placeholder={t('documentMeta.system')}
                                value={
                                  appState.systems
                                    .filter((sys) => {
                                      return sys.system_name === data.system;
                                    })
                                    .map((sys) => ({
                                      label: sys.system_name || '',
                                      value: sys.id,
                                    }))[0]
                                }
                                options={
                                  appState.systems &&
                                  appState.systems.map((sys) => ({
                                    label: sys.system_name,
                                    value: sys.id,
                                  }))
                                }
                                onChange={(sys: ValueType<Item>) => {
                                  setData((prod) => {
                                    prod.system = (sys as Model.Item).label;
                                  });
                                }}
                              />
                            </dd>
                            <dt>{t('documentMeta.area_name')}</dt>
                            <dd>
                              <ReactSelect
                                placeholder={t('documentMeta.area_name')}
                                value={
                                  appState.areas
                                    .filter((area) => {
                                      return (
                                        area.area_name === data.maintenanceArea
                                      );
                                    })
                                    .map((area) => ({
                                      label: area.area_name || '',
                                      value: area.id,
                                    }))[0]
                                }
                                options={
                                  appState.areas &&
                                  appState.areas.map((area) => ({
                                    label: area.area_name || '',
                                    value: area.id,
                                  }))
                                }
                                onChange={(area: ValueType<Item>) => {
                                  setData((prod) => {
                                    prod.maintenanceArea = (area as Item).label;
                                  });
                                }}
                              />
                            </dd>
                            <dt>{t('pitem.workAitems')}</dt>
                            <dd>
                              <ReactSelect
                                placeholder={t('pitem.workAitems')}
                                isMulti={true}
                                value={contracts
                                  .filter((c) => {
                                    return data.workAItems
                                      ? data.workAItems.includes(c.name || '')
                                      : false;
                                  })
                                  .map((aitem) => ({
                                    label: aitem.name || '',
                                    value: aitem.id,
                                  }))}
                                options={
                                  contracts &&
                                  contracts.map((aitem) => ({
                                    label: aitem.name,
                                    value: aitem.id,
                                  }))
                                }
                                onChange={(aitems: ValueType<Item>) => {
                                  setData((prod) => {
                                    prod.workAItems = (aitems as Model.Item[]).map(
                                      (item) => item.label
                                    );
                                  });
                                }}
                              />
                            </dd>
                            <dt>{t('itemMeta.itemType')}</dt>
                            <dd>
                              {doc.target && doc.target.pitemTypeIds
                                ? doc.target.pitemTypeIds
                                    .map((id) => getPitemTypename(id))
                                    .join(', ')
                                : '-'}
                              <ReactSelect
                                className="dropdown"
                                isMulti={false}
                                placeholder={'Valitse kohdetyyppi'}
                                value={appState.equipmentTypes
                                  .filter((t) => t.name === data.pitemTypeName)
                                  .map((category) => ({
                                    label: category.name,
                                    value: category.id,
                                  }))}
                                options={[
                                  ...appState.equipmentTypes
                                    .filter((cat) => {
                                      return (
                                        cat.upper_type_id &&
                                        cat.upper_type_id > 0
                                      );
                                    })
                                    .map((category) => ({
                                      label: category.name,
                                      value: category.id,
                                    })),
                                ]}
                                onChange={(values: Item) => {
                                  const value = values.label;
                                  setData((prod) => {
                                    prod.pitemTypeName = value;
                                  });
                                }}
                              />
                            </dd>
                            {meta.pitemTree.map((pItem) => {
                              if (pItem.id === doc.id)
                                return <div key={pItem.name} />;
                              // docPackage
                              const ptypename =
                                pItem.pitemTypeIds &&
                                pItem.pitemTypeIds.length > 0
                                  ? pItem.pitemTypeIds
                                      .map((id) => getPitemTypename(id))
                                      .join(', ')
                                  : t('pitem.target');
                              return (
                                <div key={pItem.name}>
                                  <dt>{ptypename}</dt>
                                  <dd>
                                    {pItem.name} / {pItem.name_long}
                                  </dd>
                                </div>
                              );
                            })}

                            <dt>{t('documentMeta.location')}</dt>
                            <dd>
                              <TextField
                                value={data.location}
                                fullWidth={true}
                                onChange={(e) => {
                                  const tv = e.target.value;
                                  setData((prod) => {
                                    prod.location = tv;
                                  });
                                }}
                              />
                            </dd>

                            <dt>{t('documentMeta.specifier')}</dt>
                            <dd>
                              {' '}
                              <TextField
                                value={data.specifier || ''}
                                fullWidth={true}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setData((prod) => {
                                    prod.specifier = value;
                                  });
                                }}
                              />
                            </dd>
                            <dt>{t('documentMeta.criticality_class')}</dt>
                            <dd>
                              <ReactSelect
                                placeholder={t(
                                  'documentMeta.criticality_class'
                                )}
                                value={
                                  appState.criticalityClasses
                                    .filter((c) => {
                                      return (
                                        c.criticality_class_name ===
                                        data.priority
                                      );
                                    })
                                    .map((c) => ({
                                      label: c.criticality_class_name || '',
                                      value: c.id,
                                    }))[0]
                                }
                                options={
                                  appState.criticalityClasses && [
                                    {
                                      label: '-',
                                      value: 0,
                                    },
                                    ...appState.criticalityClasses.map((c) => ({
                                      label: c.criticality_class_name,
                                      value: c.id,
                                    })),
                                  ]
                                }
                                onChange={(sys: ValueType<Item>) => {
                                  setData((prod) => {
                                    prod.priority = (sys as Model.Item).label;
                                  });
                                }}
                              />
                            </dd>
                            <dt>{t('documentMeta.warranty_expires')}</dt>
                            <dd>
                              {resetWarranty ? (
                                <button
                                  onClick={() => {
                                    setResetWarranty(false);
                                  }}
                                >
                                  {t('buttons.edit')}
                                </button>
                              ) : (
                                <input
                                  type="date"
                                  value={
                                    !resetWarranty && data.warrantyExpires
                                      ? (data.warrantyExpires || new Date())
                                          .toISOString()
                                          .split('T')[0]
                                      : undefined
                                  }
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    setData((prod) => {
                                      prod.warrantyExpires = new Date(value);
                                    });
                                    setResetWarranty(false);
                                  }}
                                />
                              )}{' '}
                              {!resetWarranty && data.warrantyExpires ? (
                                <button
                                  onClick={() => {
                                    setResetWarranty(true);
                                    setData((prod) => {
                                      prod.warrantyExpires = undefined;
                                    });
                                  }}
                                >
                                  x
                                </button>
                              ) : null}
                            </dd>
                            <dt>{t('documentMeta.upper_equipment')}</dt>
                            <dd>
                              <TextField
                                value={data.upperEquipment || ''}
                                fullWidth={true}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setData((prod) => {
                                    prod.upperEquipment = value;
                                  });
                                }}
                              />
                            </dd>
                            <dt>{t('documentMeta.secondary_hierarchy')}</dt>
                            <dd>
                              <TextField
                                value={data.secondaryHierarchy || ''}
                                fullWidth={true}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setData((prod) => {
                                    prod.secondaryHierarchy = value;
                                  });
                                }}
                              />
                            </dd>
                            <dt>{t('documentMeta.additional_information')}</dt>
                            <dd>
                              <TextField
                                value={data.additionalInformation}
                                fullWidth={true}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setData((prod) => {
                                    prod.additionalInformation = value;
                                  });
                                }}
                              />
                            </dd>
                            <dt>{t('documentMeta.addtional_information2')}</dt>
                            <dd>
                              <TextField
                                value={data.additionalInformation2}
                                fullWidth={true}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setData((prod) => {
                                    prod.additionalInformation2 = value;
                                  });
                                }}
                              />
                            </dd>

                            <dt>{t('documentMeta.startPole')}</dt>
                            <dd>
                              {' '}
                              <TextField
                                value={data.poleStart}
                                fullWidth={true}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setData((prod) => {
                                    if (!isNaN(Number(value))) {
                                      prod.poleStart = value;
                                    }
                                  });
                                }}
                              />
                            </dd>
                            <dt>{t('documentMeta.endPole')}</dt>
                            <dd>
                              <TextField
                                value={data.poleEnd}
                                fullWidth={true}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setData((prod) => {
                                    if (!isNaN(Number(value))) {
                                      prod.poleEnd = value;
                                    }
                                  });
                                }}
                              />
                            </dd>
                            <dd>
                              <Button onClick={saveData}>
                                {t('buttons.save')}
                              </Button>{' '}
                            </dd>
                          </dl>
                        </form>
                      ) : (
                        <form>
                          <dl>
                            <dt>{t('pitem.code')}</dt>
                            <dd>{doc.name}</dd>

                            <dt>{t('documentMeta.name_long')}</dt>
                            <dd>{doc.description}</dd>

                            <dt>{t('documentMeta.system')}</dt>

                            <dd>
                              {doc.systemsPath
                                ? doc.systemsPath
                                    .map((systemId) => {
                                      const systems = appState.systems.filter(
                                        (s) => s.id === systemId
                                      );
                                      return systems
                                        ? systems
                                            .map((s) => s.description)
                                            .join('')
                                        : '';
                                    })
                                    .join(' / ')
                                : '-'}
                            </dd>
                            <dt>{t('documentMeta.area_name')}</dt>
                            <dd>
                              {doc.areas && doc.areas.length > 0
                                ? doc.areas
                                    .map(
                                      (area) =>
                                        `${area.area_name} / ${area.description}`
                                    )
                                    .join(', ')
                                : '-'}
                            </dd>
                            <dt>{t('pitem.workAitems')}</dt>
                            <dd>{workAitems}</dd>
                            <dt>{t('itemMeta.itemType')}</dt>
                            <dd>
                              {doc.target && doc.target.pitemTypeIds
                                ? doc.target.pitemTypeIds
                                    .map((id) => getPitemTypename(id))
                                    .join(', ')
                                : '-'}
                            </dd>
                            {meta.pitemTree.map((pItem) => {
                              if (pItem.id === doc.id)
                                return <div key={pItem.name} />;
                              // docPackage
                              const ptypename =
                                pItem.pitemTypeIds &&
                                pItem.pitemTypeIds.length > 0
                                  ? pItem.pitemTypeIds
                                      .map((id) => getPitemTypename(id))
                                      .join(', ')
                                  : t('pitem.target');
                              return (
                                <div key={pItem.name}>
                                  <dt>{ptypename}</dt>
                                  <dd>
                                    {pItem.name} / {pItem.name_long}
                                  </dd>
                                </div>
                              );
                            })}
                            <dt>{t('documentMeta.specifier')}</dt>
                            <dd>{doc.specifier || '-'}</dd>
                            <dt>{t('documentMeta.criticality_class')}</dt>
                            <dd>{doc.criticality_class || '-'}</dd>
                            <dt>{t('documentMeta.warranty_expires')}</dt>
                            <dd>
                              {doc.warranty_expires ? (
                                <Moment
                                  date={doc.warranty_expires}
                                  format="DD.MM.YYYY"
                                />
                              ) : (
                                '-'
                              )}
                            </dd>
                            <dt>{t('documentMeta.upper_equipment')}</dt>
                            <dd>
                              {doc.upper_equipment
                                ? `${doc.upper_equipment.name} / ${doc.upper_equipment.name_long}`
                                : '-'}
                            </dd>
                            <dt>{t('documentMeta.secondary_hierarchy')}</dt>
                            <dd>
                              {doc.secondary_hierarchy
                                ? `${doc.secondary_hierarchy.name} / ${doc.secondary_hierarchy.name_long}`
                                : '-'}
                            </dd>
                            <dt>{t('documentMeta.additional_information')}</dt>
                            <dd>{doc.additionalInformation || '-'}</dd>
                            <dt>{t('documentMeta.addtional_information2')}</dt>
                            <dd>{doc.additionalInformation2 || '-'}</dd>

                            <dt>{t('documentMeta.startPole')}</dt>
                            <dd>{doc.startPole || '-'}</dd>
                            <dt>{t('documentMeta.endPole')}</dt>
                            <dd>{doc.endPole || '-'}</dd>
                          </dl>
                        </form>
                      )}
                    </div>
                  </div>
                </div>
                <DocumentPackage
                  openByDefault={true}
                  itemIds={doc.pitemIds || []}
                  itemName={doc.name}
                />
              </div>
            </>
          );
        }}
      />
    </MainLayout>
  );
};

export default PitemView;
