import * as React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import * as applicationState from '../../state/reducers/ApplicationState';
import { setBackgroundImage } from '../../utils/index';
import AdminTools from './index';
import './adminTools.scss';
import { Async } from '../Common/Async';

import axios from 'axios';
import { APIService } from '../../services/api/src/frontend/api';
import { DataTable } from '../Common/DataTable';
import { Link } from 'react-router-dom';

const API = APIService(axios);

interface IMailTasksProps extends applicationState.IProps {
  match: {
    params: {
      id: number;
    };
  };
}

interface IMailTasksState {}

type MailTasksProps = IMailTasksProps & InjectedIntlProps;

class AbstractMailTasks extends React.Component<
  MailTasksProps,
  IMailTasksState
> {
  constructor(props: MailTasksProps) {
    super(props);

    this.state = {};
  }

  componentDidMount = async () => {
    setBackgroundImage();
  };

  render() {
    // getEmailInfo
    return (
      <AdminTools>
        <Async
          loader={() => API.getPrintOrder(this.props.match.params.id)}
          render={data => {
            if (!data || data.length === 0) {
              return <div />;
            }
            return (
              <div>
                <DataTable
                  rows={data}
                  keys={['document_id', 'amount', 'folding']}
                  render={{
                    document_id: row => (
                      <Link to={`/documents/${row.document_id}`}>
                        {row.document_id}
                      </Link>
                    ),
                  }}
                />
              </div>
            );
          }}
        />
      </AdminTools>
    );
  }
}

const MailTasks = applicationState.StateConnector(AbstractMailTasks);

export default injectIntl(MailTasks);
