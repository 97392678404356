import * as React from 'react';
import { useState } from 'react';
import { useAdminContext } from 'utils/AppContextProvider';
import TextField from 'components/Common/TextField';
import ReactSelect from 'react-select';
import Button from 'components/Common/Button';
import * as _ from 'lodash';
import { Async } from 'components/Common/Async';
import Loading from 'components/Common/Loading';
import { Notifier } from 'components/Common/Notifier';
import { ValueType } from 'react-select/lib/types';
import { Item } from '../../../../../shared/src/model/index';
import { useFormatMessage } from 'utils/translateHook';

interface FormProps {
  name?: string | null;
  pole_start_pos?: number | null;
  pole_end_pos?: number | null;
  pitem_id?: number | null;
}

interface Props {
  onSave?: (form: FormProps) => void;
  onCancel?: () => void;
  form: FormProps;
}

export function AreaSectionEditor(props: Props) {
  const adminCtx = useAdminContext();
  const t = useFormatMessage();

  const [form, setValues] = useState<FormProps>({
    ...props.form,
  });

  const updateField = e => {
    setValues({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const setPitem = (v: Item) => {
    setValues({
      ...form,
      pitem_id: v.value,
    });
  };

  const saveSection = () => {
    if (props.onSave) {
      props.onSave(form);
    }
  };

  const cancelSave = () => {
    if (props.onCancel) {
      props.onCancel();
    }
  };
  return (
    <div className="area_section_editor">
      <span>
        <TextField
          label={t('adminTools.section_name')}
          value={form.name || ''}
          name="name"
          onChange={updateField}
        />
        <TextField
          label={t('documentMeta.startPole')}
          type={'number'}
          value={form.pole_start_pos || ''}
          name="pole_start_pos"
          onChange={updateField}
        />
        <TextField
          label={t('documentMeta.endPole')}
          type={'number'}
          value={form.pole_end_pos || ''}
          name="pole_end_pos"
          onChange={updateField}
        />
        <Async
          placeholder={<Loading loading={true} />}
          loader={async () => {
            // Only for a failsafe. Buildings are loaded in <Areas /> component
            if (adminCtx.allBuildings.length === 0) {
              await adminCtx.getAllBuildings();
            }

            return adminCtx.allBuildings.map(pitem => ({
              value: pitem.id,
              label: pitem.name,
            }));
          }}
          render={values => {
            return values ? (
              <ReactSelect
                className="dropdown"
                isMulti={false}
                placeholder={t('pitem.target')}
                value={values.filter(v => v.value === form.pitem_id).pop()}
                options={values}
                onChange={(v: ValueType<Item>) => {
                  if (!Array.isArray(v)) {
                    setPitem(v as Item);
                  }
                }}
              />
            ) : (
              <Notifier snack={{ variant: 'error' }} />
            );
          }}
        />
        <Button
          color={'secondary'}
          onClick={e => {
            cancelSave();
          }}
        >
          {t('buttons.cancel')}
        </Button>
        &nbsp;
        <Button
          onClick={async e => {
            await saveSection();
          }}
        >
          {t('buttons.save')}
        </Button>
      </span>
    </div>
  );
}
