import * as React from 'react';
import { pitem } from '../../../../../shared/src/model/projectSchema';
import { ItemListing } from '../../../../../shared/src/model/index';
import * as _ from 'lodash';
import { CompareData } from './controller';
import {
  List,
  MenuItem,
  Paper,
  Grid,
  ListItemText,
  Button,
  LinearProgress,
} from '@material-ui/core';
import { ShowItemRows } from './ShowItemRows';
import { ShowOldRows } from './ShowOldRows';
import { MissingRoomsInfo } from './MissingRoomsInfo';
import { PitemStateContext } from './reducers/PitemState';
import { useFormatMessage } from 'utils/translateHook';
export interface ItemDataProps {
  allBuildings: pitem[];
  newItems: ItemListing;
  oldItems: pitem[];
  foundRooms: pitem[];
  askRefreshData: () => Promise<void>;
}

export function CompareOldAndNewData(props: ItemDataProps) {
  const ctx = React.useContext(PitemStateContext);
  const t = useFormatMessage();
  const comp = CompareData(props);

  const selectedSegment = comp
    .filter(seg => {
      if (!ctx.selectedSegment) return false;
      return seg.segment.name === ctx.selectedSegment.segment.name;
    })
    .pop();

  const foundRooms = React.useMemo(() => {
    return props.foundRooms.map(r => r.name);
  }, [props.foundRooms, selectedSegment]);

  const requiredRooms = React.useMemo(() => {
    return ctx.positions
      .filter(r =>
        selectedSegment ? r.segmentName === selectedSegment.segment.name : false
      )
      .map(r => r.roomId || '');
  }, [props.foundRooms, selectedSegment]);

  const missingRooms = React.useMemo(() => {
    return _.uniq(_.difference(requiredRooms, foundRooms));
  }, [props.foundRooms, selectedSegment]);

  const allPitems = selectedSegment
    ? selectedSegment.difference.newItems.concat(
        selectedSegment.difference.preservedItems
      )
    : [];

  return (
    <Paper>
      <Grid container={true} direction="row">
        <Grid item={true} xs={3}>
          <List>
            {comp.map(seg => {
              return (
                <MenuItem
                  key={seg.segment.name}
                  selected={
                    selectedSegment &&
                    seg.segment.name === selectedSegment.segment.name
                  }
                  button={true}
                  onClick={() => ctx.setSelectedSegment(seg)}
                >
                  <ListItemText
                    primary={seg.segment.name}
                    secondary={`${t('adminTools.newPitemsLegend')}:
                      ${seg.difference.newItems.length}, ${t(
                      'adminTools.preservedItemsLegend'
                    )}:
                      ${seg.difference.preservedItems.length}`}
                  />
                </MenuItem>
              );
            })}
          </List>
        </Grid>
        <Grid item={true} xs={9}>
          {selectedSegment ? (
            <>
              {ctx.isUploading ? (
                <div style={{ padding: '10px' }}>
                  {t('upload.saving')}
                  <LinearProgress />{' '}
                </div>
              ) : null}
              <Button
                variant="contained"
                color="primary"
                onClick={async () => {
                  ctx.setUploading(true);
                  await ctx.createNewPitems({
                    typeName: props.newItems.pitemType || '',
                    list: allPitems,
                  });
                  ctx.setUploading(false);
                  props.askRefreshData();
                }}
              >
                {t('adminTools.importPitems')}
              </Button>
              <MissingRoomsInfo
                rooms={missingRooms.filter(
                  name =>
                    name.indexOf('PL-') !== 0 && name.indexOf('PLV-') !== 0
                )}
              />
              {selectedSegment.difference.newItems.length ? (
                <ShowItemRows items={selectedSegment.difference.newItems} />
              ) : null}
              {selectedSegment.difference.preservedItems.length ? (
                <ShowOldRows
                  items={selectedSegment.difference.preservedItems}
                />
              ) : null}
            </>
          ) : (
            <div />
          )}
        </Grid>
      </Grid>
    </Paper>
  );
}
