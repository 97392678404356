import * as React from 'react';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import * as applicationState from '../../state/reducers/ApplicationState';
import * as adminState from '../../state/reducers/AdminState';
import AdminTools from '../AdminTools';
import './adminTools.scss';

// import * as Model from '../../../../shared/src/model/index';
// import * as schema from '../../../../shared/src/model/projectSchema';
import { push } from 'connected-react-router';
import UsersTable from '../Common/UsersTable';
import User from './user';
// import { errorMessage, successMessage } from '../Common/Notifier';
import * as _ from 'lodash';
// const API = APIService(axios);
// const DOC = DocumentService(axios);

interface IUsersProps extends applicationState.IProps, adminState.IProps {
  match: {
    params: {
      id: number;
    };
  };
}

interface IUsersState {
  showOnlyDBUsers: boolean;
}

type UsersProps = IUsersProps & InjectedIntlProps;

class AbstractUsers extends React.Component<UsersProps, IUsersState> {
  constructor(props: UsersProps) {
    super(props);

    this.state = {
      showOnlyDBUsers: false,
    };
  }

  componentDidMount = async () => {
    this.props.getMetadata();
    this.props.getContracts();
    this.props.getAvailableRoles();
    if (this.props.allUsers.length === 0) {
      this.props.getAllUsers();
    }
  };

  onOnlyDBUsersChange = () => {
    this.setState(
      {
        showOnlyDBUsers: !this.state.showOnlyDBUsers,
      },
      () => {
        this.props.getAllUsers(this.state.showOnlyDBUsers);
      }
    );
  };

  render() {
    const t = (id: string) => this.props.intl.formatMessage({ id });
    const users = this.props.allUsers;

    return (
      <AdminTools>
        <div className="grid gridCols3">
          <div className="component master">
            <h2>
              <FormattedMessage id="headers.users" />
            </h2>
            <div className="content">
              <UsersTable
                selectedId={this.props.match.params.id}
                onlyDBUsers={this.state.showOnlyDBUsers}
                onlyDBUsersChange={this.onOnlyDBUsersChange}
                rowClick={user => {
                  this.props.ApplicationStateDispatcher(
                    push('/admin-tools/users/' + user.id)
                  );
                }}
                keys={{
                  email: t('adminTools.email'),
                  name: t('adminTools.name'),
                }}
                rows={users ? users : []}
                loading={this.props.adminstatus.getAllUsers.loading}
              />
            </div>
          </div>
          {this.props.match.params.id && (
            <User userId={this.props.match.params.id} />
          )}
        </div>
      </AdminTools>
    );
  }
}

const Users = adminState.StateConnector(
  applicationState.StateConnector(AbstractUsers)
);

export default injectIntl(Users);
