import * as React from 'react';
import { Star } from '@material-ui/icons/';
import { InjectedIntlProps, injectIntl } from 'react-intl';

import ReactSelect from 'react-select';
import { Item } from '../Common/AutosuggestChipSelection';

export enum FilterRoles {
  Contractor = 1,
  Inspector = 2,
  Authority = 3,
}

interface IFiltersProps {
  role: FilterRoles;
  onFilterChange: (activeFilters: string[]) => void;
  onShowFavouritesUpdate: (showFavourites: boolean) => void;
  onFilterTermChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface IFiltersState {
  activeFilters: string[];
  selectedOptions: Item[];
  showFavourites: boolean;
  filters: {
    blue: boolean;
    green: boolean;
    yellow: boolean;
    red: boolean;
  };
}

type FiltersProps = IFiltersProps & InjectedIntlProps;

class Filters extends React.Component<FiltersProps, IFiltersState> {
  constructor(props: FiltersProps) {
    super(props);

    this.state = {
      selectedOptions: [],
      activeFilters: [],
      showFavourites: false,
      filters: {
        blue: false,
        green: false,
        yellow: false,
        red: false,
      },
    };
  }

  onFilterUpdate = (filter: string) => () => {
    const filters = { ...this.state.filters };

    filters[filter] = !this.state.filters[filter];

    // Update the filter state and then the list of active filters
    this.setState({ filters }, () => {
      const activeFilters = Object.keys(this.state.filters).filter(key => {
        return this.state.filters[key];
      });

      this.setState({ activeFilters });
      this.props.onFilterChange(activeFilters);
    });
  };

  onShowFavouritesUpdate = () => {
    this.setState({ showFavourites: !this.state.showFavourites }, () => {
      this.props.onShowFavouritesUpdate(this.state.showFavourites);
    });
  };

  pickColor = (value: number) => {
    switch (value) {
      case 1:
        return 'green';
      case 2:
        return 'yellow';
      case 3:
        return 'blue';
      case 4:
        return 'red';
    }
    return 'green';
  };

  setSelectedFilters = (values: any) => {
    if (values && Array.isArray(values)) {
      const activeFilters = values.map(v => this.pickColor(v.value));
      this.setState({ activeFilters, selectedOptions: values });
      this.props.onFilterChange(activeFilters);
    }
  };

  public render() {
    const t = (id: string) => this.props.intl.formatMessage({ id });

    // const { blue, green, yellow, red } = this.state.filters;
    const options =
      this.props.role === FilterRoles.Contractor
        ? [
            {
              label: t('inspectionDocuments.filterLabels.contractor.green'),
              value: 1,
            },
            {
              label: t('inspectionDocuments.filterLabels.contractor.yellow'),
              value: 2,
            },
            {
              label: t('inspectionDocuments.filterLabels.contractor.blue'),
              value: 3,
            },
            {
              label: t('inspectionDocuments.filterLabels.contractor.red'),
              value: 4,
            },
          ]
        : this.props.role === FilterRoles.Inspector
        ? [
            {
              label: t('inspectionDocuments.filterLabels.inspector.green'),
              value: 1,
            },
            {
              label: t('inspectionDocuments.filterLabels.inspector.yellow'),
              value: 2,
            },
            {
              label: t('inspectionDocuments.filterLabels.inspector.blue'),
              value: 3,
            },
          ]
        : [
            {
              label: t('inspectionDocuments.filterLabels.default.green'),
              value: 1,
            },
            {
              label: t('inspectionDocuments.filterLabels.default.yellow'),
              value: 2,
            },
            {
              label: t('inspectionDocuments.filterLabels.default.blue'),
              value: 3,
            },
          ];

    /*
    const selectedOptions = options.filter(v => {
      if (v.value === 1 && green) return true;
      if (v.value === 2 && yellow) return true;
      if (v.value === 3 && blue) return true;
      if (v.value === 4 && red) return true;
      return false;
    });
    */

    return (
      <div className="filterContainer">
        <div className="targetFilters">
          {/*
        <div
          className={`filter green ${green ? 'active' : ''}`}
          onClick={this.onFilterUpdate('green')}
        />
        <div
          className={`filter blue ${blue ? 'active' : ''}`}
          onClick={this.onFilterUpdate('blue')}
        />
        <div
          className={`filter yellow ${yellow ? 'active' : ''}`}
          onClick={this.onFilterUpdate('yellow')}
        />
        <div
          className={`filter red ${red ? 'active' : ''}`}
          onClick={this.onFilterUpdate('red')}
        />
        */}
          <div
            className={`filter star ${
              this.state.showFavourites ? 'active' : ''
            }`}
            onClick={this.onShowFavouritesUpdate}
          >
            <Star />
          </div>
        </div>

        <ReactSelect
          className="filterSelecter"
          isMulti={true}
          placeholder={'Valitse rajaus'}
          options={options}
          onChange={this.setSelectedFilters}
          value={this.state.selectedOptions}
          styles={{
            // Dropdown options
            option: (styles, { data }) => {
              return {
                ...styles,
                marginLeft: '25px',
                width: '90%',
                ':before': {
                  content: '" "',
                  position: 'absolute',
                  display: 'block',
                  height: '20',
                  width: '20',
                  backgroundColor: this.pickColor(data.value),
                  border: '2px solid rgba(0, 0, 0, .1)',
                  borderRadius: 4,
                  marginLeft: '-30px',
                },
              };
            },
            // Selected
            multiValue: (styles, { data }) => {
              return {
                ...styles,
                backgroundColor: 'white',
                marginLeft: '20px',
                ':before': {
                  content: '" "',
                  position: 'absolute',
                  display: 'block',
                  height: '20',
                  width: '20',
                  backgroundColor: this.pickColor(data.value),
                  border: '2px solid rgba(0, 0, 0, .1)',
                  borderRadius: 4,
                  marginLeft: '-22px',
                },
              };
            },
          }}
        />

        <div className="filterTerm">
          <input
            type="text"
            className="basic"
            placeholder={t('buttons.filter')}
            onChange={this.props.onFilterTermChange}
          />
        </div>
      </div>
    );
  }
}

export default injectIntl(Filters);
