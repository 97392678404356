import {
  Grid,
  LinearProgress,
  List,
  ListItemText,
  MenuItem,
} from '@material-ui/core';
import { Delete } from '@material-ui/icons/';
import WarningIcon from '@material-ui/icons/WarningRounded';
import Button from 'components/Common/Button';
import * as _ from 'lodash';
import * as React from 'react';
import { useEffect, useState } from 'react';
import Moment from 'react-moment';
import { useAppContext } from 'utils/AppContextProvider';
import { useFormatMessage } from 'utils/translateHook';
import { DeviceListRow } from '../../../../../shared/src/model/index';
import { parseDeviceList } from '../../../utils/excel';
import { DataTable } from '../../Common/DataTable';
import FileInput from '../../Common/FileInput';
import { errorMessage, successMessage } from '../../Common/Notifier';
import { PitemStateContext } from './reducers/PitemState';
import { notEmpty } from 'utils';

interface Props {}

export function DeviceLists(props: Props) {
  const t = useFormatMessage();
  const ctx = React.useContext(PitemStateContext);
  const appCtx = useAppContext();
  const [files, setFiles] = useState<File[]>([]);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [devices, setDevices] = useState<DeviceListRow[]>([]);
  const [selectedDevices, setSelectedDevices] = useState<DeviceListRow[]>([]);
  const [unknownSystems, setUnknownSystems] = useState<string[]>([]);
  const [selectedBuildingElement, setSelectedBuildingElement] = useState<
    string | undefined
  >();

  useEffect(() => {
    appCtx.getSystems();
  }, []);

  useEffect(() => {
    // Find systems not found from db
    setUnknownSystems(() => {
      const spreadsheetSystems = _.uniq(
        devices
          .filter(device => device && device.system && device.system!.trim())
          .map(device => device.system) as string[]
      );
      const databaseSystems = appCtx.systems
        .map(system => system.system_name)
        .filter(notEmpty);
      return _.difference(spreadsheetSystems, databaseSystems);
    });
  }, [devices]);

  const buildingElements = _.uniq(devices.map(device => device.buildingElement))
    .filter(buildingElement => buildingElement)
    .sort((a, b) => a.localeCompare(b, 'fi'));

  const deviceAmountForBuildingElement = (
    buildingElement: string | undefined
  ) =>
    devices.filter(device => device.buildingElement === buildingElement).length;

  const isNewSystem = (system: string) =>
    system &&
    appCtx.systems.map(system => system.system_name).indexOf(system) === -1;

  const isMissingTargetcodes = devices.some(device => !device.targetcode);

  const targetsWithoutMetaInfo = _.chain(devices)
    .groupBy('targetcode')
    .map((value, key) => ({
      targetcode: key,
      readMetaInfo: value
        .map(device => device.readMetaInfo)
        .some(readMetaInfo => readMetaInfo),
    }))
    .filter(item => !item.readMetaInfo)
    .value();

  // Check if there's multiple lines with `readMetaInfo: true` for a
  // single target code
  const targetsWithMultipleMetaInfo = devices.filter(
    _ => false
  ); /*_.chain(devices)
    .groupBy('targetcode')
    .map((value, key) => ({
      targetcode: key,
      readMetaInfo: value
        .map(device => device.readMetaInfo)
        .filter(metaInfo => metaInfo),
    }))
    .filter(device => device.targetcode !== 'POISTO')
    .value();*/

  const targetsWithoutBuildingElement = devices.filter(
    device => !device.buildingElement
  );

  const devicesToBeDeleted = devices.filter(
    device => device.targetcode && device.targetcode === 'POISTO'
  );

  const submitDevices = async () => {
    setIsUploading(true);

    const response = await ctx.updateDeviceMetadata({
      devices: selectedDevices,
    });

    setIsUploading(false);

    if (response instanceof Error) {
      errorMessage(response);
    } else {
      successMessage(t('adminTools.metadataUpdateSuccess'));
    }
  };

  return (
    <>
      <FileInput
        id="deviceLists"
        files={files}
        label={t('upload.dropzoneInfo')}
        fileListRenderer={() => <div />}
        onChange={async files => {
          setDevices([]);
          setSelectedDevices([]);
          setSelectedBuildingElement(undefined);

          const deviceList = await parseDeviceList(files[0]);

          setDevices(deviceList);

          // Reset the file input after the data from the file has been red
          setFiles([]);
        }}
      />

      {isUploading && <LinearProgress />}

      {isMissingTargetcodes && (
        <div className="inlineNotification error">
          <WarningIcon className="errorIcon" />
          {`${t('adminTools.isMissingTargetcodes')}`}
        </div>
      )}

      {unknownSystems.length > 0 && (
        <div className="inlineNotification warning">
          <WarningIcon className="warningIcon" />
          {`${t('adminTools.unknownSystems')}: ${unknownSystems.join(', ')}`}
        </div>
      )}

      {targetsWithoutMetaInfo.length > 0 && (
        <div className="inlineNotification warning">
          <WarningIcon className="warningIcon" />
          {`${t(
            'adminTools.targetsWithoutMetaInfo'
          )}: ${targetsWithoutMetaInfo
            .map(target => target.targetcode)
            .join(', ')}`}
        </div>
      )}

      {targetsWithMultipleMetaInfo.length > 0 && (
        <div className="inlineNotification warning">
          <WarningIcon className="warningIcon" />
          {`${t(
            'adminTools.targetsWithMultipleMetaInfo'
          )}: ${targetsWithMultipleMetaInfo
            .map(device => device.targetcode)
            .join(', ')}`}
        </div>
      )}

      {targetsWithoutBuildingElement.length > 0 && (
        <div className="inlineNotification error">
          <WarningIcon className="errorIcon" />
          {`${t(
            'adminTools.targetsWithoutBuildingElement'
          )}: ${targetsWithoutBuildingElement
            .map(target => target.targetcode)
            .join(', ')}`}
        </div>
      )}

      {devicesToBeDeleted.length > 0 && (
        <div className="inlineNotification warning">
          <WarningIcon className="warningIcon" />
          {`${t('adminTools.devicesToBeDeleted')}: ${devicesToBeDeleted
            .map(device => device.oldTargetcode)
            .join(', ')}`}
        </div>
      )}

      {buildingElements.length > 0 && (
        <Grid container={true} direction="row">
          <Grid item={true} xs={2}>
            <List>
              <MenuItem
                selected={selectedBuildingElement === '###ALL###'}
                button={true}
                onClick={() => {
                  setSelectedBuildingElement('###ALL###');
                  setSelectedDevices(devices);
                }}
              >
                <ListItemText
                  primary={t('adminTools.allDevices')}
                  secondary={`${t('adminTools.deviceAmount')}: ${
                    devices.length
                  }`}
                />
              </MenuItem>
              {buildingElements.map(buildingElement => (
                <MenuItem
                  key={buildingElement}
                  selected={buildingElement === selectedBuildingElement}
                  button={true}
                  onClick={() => {
                    setSelectedBuildingElement(buildingElement);
                    setSelectedDevices(
                      devices.filter(
                        device => device.buildingElement === buildingElement
                      )
                    );
                  }}
                >
                  <ListItemText
                    primary={buildingElement}
                    secondary={`${t(
                      'adminTools.deviceAmount'
                    )}: ${deviceAmountForBuildingElement(buildingElement)}`}
                  />
                </MenuItem>
              ))}
            </List>
          </Grid>

          <Grid item={true} xs={10}>
            {selectedBuildingElement && (
              <>
                <h2 style={{ margin: '20px 10px' }}>
                  {t('adminTools.deviceList')}
                  <Button
                    variant="contained"
                    style={{ margin: '0 20px' }}
                    disabled={
                      unknownSystems.length > 0 ||
                      isMissingTargetcodes ||
                      targetsWithoutMetaInfo.length > 0 ||
                      targetsWithMultipleMetaInfo.length > 0 ||
                      isUploading
                    }
                    onClick={() => submitDevices()}
                  >
                    {t('adminTools.updateDevices')}
                  </Button>
                </h2>

                <DataTable
                  rows={selectedDevices}
                  keys={[
                    'readMetaInfo',
                    'targetcode',
                    'oldTargetcode',
                    'pitemTypeName',
                    'description',
                    'priority',
                    'warrantyExpires',
                    'system',
                    'upperEquipment',
                    'buildingElement',
                    'location',
                    'maintenanceArea',
                    'documents',
                  ]}
                  align={{ readMetaInfo: 'center', targetcode: 'center' }}
                  render={{
                    readMetaInfo: row => (
                      <div>
                        <span
                          className={`${
                            row.readMetaInfo ? 'dot green' : 'dot yellow'
                          }`}
                        />
                      </div>
                    ),
                    targetcode: row => (
                      <span>
                        {row.targetcode === 'POISTO' ? (
                          <Delete className="redText" />
                        ) : (
                          row.targetcode
                        )}
                      </span>
                    ),
                    warrantyExpires: row => (
                      <>
                        {row.warrantyExpires && (
                          <Moment
                            date={row.warrantyExpires}
                            format="DD.MM.YYYY"
                          />
                        )}
                      </>
                    ),
                    system: row => (
                      <span
                        className={
                          row.system && isNewSystem(row.system) ? 'redText' : ''
                        }
                      >
                        {row.system}
                      </span>
                    ),
                    documents: row => (
                      <>
                        {row.documents &&
                          row.documents.map((document, index) => (
                            <div key={index}>{document}</div>
                          ))}
                      </>
                    ),
                  }}
                />
              </>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
}
