import * as React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';

interface IDataTable<T, K extends keyof T> {
  rows: T[];
  keys: K[];
}

export function DataTable<T, K extends keyof T>(props: IDataTable<T, K>) {
  return (
    <Table>
      <TableHead>
        <TableRow>
          {props.keys.map((key, index) => {
            return <TableCell key={'cell2_' + index}>{key}</TableCell>;
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {props.rows.map((row, index) => {
          return (
            <TableRow key={'table_' + index}>
              {props.keys.map((key, index2) => {
                return (
                  <TableCell key={'cell_' + index2}>
                    {row[key] ? (
                      row[key]
                    ) : (
                      <TextField
                        key={'celledit_' + index2}
                        error={true}
                        label={'Value missing'}
                      />
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}
