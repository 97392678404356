import * as React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import * as applicationState from '../../state/reducers/ApplicationState';
import { setBackgroundImage } from '../../utils/index';
import AdminTools from './index';
import './adminTools.scss';
import { Async } from '../Common/Async';

import axios from 'axios';
import { APIService } from '../../services/api/src/frontend/api';
import { DataTable } from '../Common/DataTable';
import Moment from 'react-moment';
import { push } from 'connected-react-router';
const API = APIService(axios);

interface IMailTasksProps extends applicationState.IProps {}

interface IMailTasksState {}

type MailTasksProps = IMailTasksProps & InjectedIntlProps;

class AbstractMailTasks extends React.Component<
  MailTasksProps,
  IMailTasksState
> {
  constructor(props: MailTasksProps) {
    super(props);

    this.state = {};
  }

  componentDidMount = async () => {
    setBackgroundImage();
  };

  render() {
    return (
      <AdminTools>
        <Async
          loader={() => API.getLatestEmails()}
          render={data => {
            if (!data) {
              return <div />;
            }
            return (
              <DataTable
                rows={data}
                titles={{
                  to_address: 'Kohdeosoite',
                  template: 'Postin tyyppi',
                  created_at: 'Luotu',
                  sent_at: 'Lähetetty',
                }}
                keys={['id', 'to_address', 'template', 'created_at', 'sent_at']}
                onClick={row => {
                  this.props.ApplicationStateDispatcher(
                    push(`/admin-tools/mail-tasks/${row.id}`)
                  );
                }}
                render={{
                  created_at: row => (
                    <Moment date={row.created_at!} format="DD.MM.YYYY HH:mm" />
                  ),
                  sent_at: row =>
                    row.sent_at ? (
                      <Moment date={row.sent_at} format="DD.MM.YYYY HH:mm" />
                    ) : (
                      <span>-</span>
                    ),
                }}
              />
            );
          }}
        />
      </AdminTools>
    );
  }
}

const MailTasks = applicationState.StateConnector(AbstractMailTasks);

export default injectIntl(MailTasks);
