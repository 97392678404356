import * as React from 'react';
import { injectIntl, InjectedIntlProps, FormattedMessage } from 'react-intl';
import Checkbox from '@material-ui/core/Checkbox';

import * as schema from '../../../../shared/src/model/projectSchema';
import { UsersTableRow } from '../../../../shared/src/model/index';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { HowToReg, AssignmentInd, Lock } from '@material-ui/icons/';

import {
  TablePagination,
  TableFooter,
  LinearProgress,
  TextField,
  Switch,
  FormGroup,
  FormControlLabel,
} from '@material-ui/core';
import * as _ from 'lodash';

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type TableRowType = {
  [key in keyof Omit<schema.user, 'id'>]?:
    | string
    | schema.user[key]
    | UsersTableRow
    | JSX.Element
} & { id: number; name?: string; disabled?: boolean };

interface IDocumentTableProps {
  keys: {
    [key in keyof (schema.user & { url: string; name: string })]?:
      | string
      | Element
  };
  rows: TableRowType[];
  dataRows?: schema.user[];
  title?: string | Element | JSX.Element;
  hasSelect?: boolean;
  rowClick?: (row: TableRowType) => void;
  actionComponents?: React.ReactNode[];
  setSelected?: (sel: number[]) => void;
  headerClick?: (key: string) => void;
  onTitleClick?: () => void;
  rowsPerPage?: number;
  selectedDocuments?: schema.user[];
  selectedId?: number;
  loading?: boolean;
  rowHeader?: (
    index: number,
    rowIndex: number,
    row?: schema.user
  ) => React.ReactNode;
  onlyDBUsers?: boolean;
  onlyDBUsersChange?: () => void;
}

interface IDocumentTableState {
  page: number;
  rowsPerPage: number;
  selectedIds: number[];
  selectedRows: number[];
  searchValue: string;
}

type DocumentTableProps = IDocumentTableProps & InjectedIntlProps;

class DocumentTable extends React.Component<
  DocumentTableProps,
  IDocumentTableState
> {
  constructor(props: DocumentTableProps) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: props.rowsPerPage || 10,
      selectedIds: [],
      selectedRows: [],
      searchValue: '',
    };
  }

  setSelected(selected: number[]) {
    this.setState({
      selectedIds: selected,
    });
    if (this.props.setSelected) this.props.setSelected(selected);
  }

  handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked && this.state.selectedIds.length === 0) {
      const newSelecteds: number[] = this.props.rows.map(n => n.id);
      this.setSelected(newSelecteds);
    } else {
      this.setSelected([]);
    }
  };

  handleClick = (
    event: React.MouseEvent<HTMLTableRowElement>,
    row: TableRowType
  ) => {
    if (this.props.rowClick) {
      this.props.rowClick(row);
    }
  };

  handleSelectedClick = (id: number) => {
    const newSelected = _.xor(this.state.selectedIds, [id]);
    this.setSelected(newSelected);
  };

  handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event: any) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
  };

  onSearchParamsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchValue: e.target.value, page: 0 });
  };

  componentDidUpdate(prevProps: DocumentTableProps) {
    // if selected documents is given as array parameter, which means the selection
    // state is maintained outside of this component, then we will first compare if the
    // selected documents array is still the same
    if (prevProps.selectedDocuments !== this.props.selectedDocuments) {
      if (this.props.selectedDocuments && prevProps.selectedDocuments) {
        // then we check if the values have been actually changed
        const idValues = this.props.selectedDocuments.map(d => d.id);
        const prevIdValues = prevProps.selectedDocuments.map(d => d.id);
        // intersection will give us the values which are same in both arrays
        if (
          _.intersection(idValues, prevIdValues).length !== prevIdValues.length
        ) {
          // if the number of unique values has been changed, reset the selected items list
          this.setSelected(this.props.selectedDocuments.map(d => d.id));
        }
      }
    }
  }

  renderPaginationRow() {
    const { keys, rows } = this.props;
    return (
      <TableRow>
        <TablePagination
          rowsPerPageOptions={[20, 50, 100, 200]}
          colSpan={Object.keys(keys).length + 1}
          count={(rows && rows.length) || 0}
          rowsPerPage={this.state.rowsPerPage}
          page={this.state.page}
          SelectProps={{
            native: true,
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          labelRowsPerPage={''}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} / ${count}`
          }
        />
      </TableRow>
    );
  }

  renderActions() {
    if (this.props.actionComponents) {
      return this.props.actionComponents.map(component => component);
    }
    return null;
  }

  renderSearch() {
    return (
      <div className="toolbar">
        <TextField
          value={this.state.searchValue}
          onChange={this.onSearchParamsChange}
          label={<FormattedMessage id="usersTable.searchUser" />}
        />
      </div>
    );
  }

  filterRows(rows: TableRowType[]) {
    if (this.state.searchValue) {
      return rows.filter(row => {
        const searchValue = _.lowerCase(this.state.searchValue);
        if (
          row.email &&
          typeof row.email === 'string' &&
          _.includes(_.lowerCase(row.email), searchValue)
        ) {
          return true;
        }
        if (row.name && _.includes(_.lowerCase(row.name), searchValue)) {
          return true;
        }

        return false;
      });
    } else {
      return rows;
    }
  }

  Compose(props: { children: React.ReactNode }) {
    return <>{props.children}</>;
  }

  public render() {
    const { keys, rows } = this.props;
    const { page, rowsPerPage } = this.state;
    const numSelected = this.state.selectedIds.length;
    const rowCount = this.props.rows.length;
    return (
      <div className="documentTable">
        {this.props.title ? (
          <div
            className="component"
            onClick={() => {
              if (this.props.onTitleClick) {
                this.props.onTitleClick();
              }
            }}
          >
            <h2>{this.props.title}</h2>
            {this.props.loading && <LinearProgress />}
          </div>
        ) : null}

        <div className={`toolbar ${numSelected > 0 && 'selected'}`}>
          {this.renderSearch()}
          <div
            className="doctable_actions"
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            {numSelected > 0 && this.renderActions()}
          </div>
          {this.props.onlyDBUsersChange && (
            <FormGroup>
              <FormControlLabel
                label={<FormattedMessage id="usersTable.showOnlyDbUsers" />}
                control={
                  <Switch
                    value={this.props.onlyDBUsers}
                    onChange={() => {
                      if (this.props.onlyDBUsersChange) {
                        this.props.onlyDBUsersChange();
                      }
                      this.setState({ page: 0 });
                    }}
                  />
                }
              />
            </FormGroup>
          )}
        </div>

        <div className="tableWrapper">
          {this.props.loading && <LinearProgress />}

          <Table className="mainTable" padding="dense">
            <TableHead>
              {/* {rows && rows.length > 5 && this.renderPaginationRow()} */}
              {rows && rows.length > 0 ? (
                <TableRow>
                  {this.props.hasSelect ? (
                    <TableCell padding="checkbox">
                      <Checkbox
                        indeterminate={
                          numSelected > 0 && numSelected < rowCount
                        }
                        checked={numSelected === rowCount && rowCount !== 0}
                        onChange={this.handleSelectAllClick}
                      />
                    </TableCell>
                  ) : null}
                  <TableCell padding="checkbox">
                    <AssignmentInd color="primary" />
                  </TableCell>
                  {Object.keys(keys).map((key, index) => {
                    return (
                      <TableCell
                        className="headerRowTitle"
                        align="left"
                        style={{
                          whiteSpace: 'normal',
                          paddingRight: '5px',
                          paddingLeft: '5px',
                        }}
                        padding={'none'}
                        key={'cell2_' + index}
                        onClick={() => {
                          if (this.props.headerClick) {
                            this.props.headerClick(key);
                          }
                        }}
                      >
                        {keys[key]}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ) : null}
            </TableHead>
            <TableBody>
              {!this.props.loading &&
                rows &&
                rows.length > 0 &&
                this.filterRows(rows)
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isSelected =
                      this.state.selectedIds.indexOf(row.id) !== -1;
                    const idSelected =
                      this.props.selectedId &&
                      String(row.id) === String(this.props.selectedId);
                    return (
                      <this.Compose key={'tablerow_' + index}>
                        {this.props.rowHeader
                          ? this.props.rowHeader(
                              page * rowsPerPage + index,
                              index,
                              this.props.dataRows
                                ? this.props.dataRows[
                                    page * rowsPerPage + index
                                  ]
                                : undefined
                            )
                          : null}
                        <TableRow
                          key={'tablerow_' + index}
                          onClick={event => this.handleClick(event, row)}
                          hover={true}
                          role="checkbox"
                          tabIndex={-1}
                          selected={isSelected}
                          style={{
                            cursor: 'pointer',
                            backgroundColor: `${idSelected ? '#EEE' : ''}`,
                          }}
                        >
                          {this.props.hasSelect ? (
                            <TableCell
                              padding="checkbox"
                              onClick={(e: React.MouseEvent<Element>) => {
                                e.stopPropagation();
                                this.handleSelectedClick(row.id);
                              }}
                            >
                              <Checkbox checked={isSelected} />
                            </TableCell>
                          ) : null}
                          <TableCell padding="checkbox">
                            {row.disabled ? (
                              <Lock color="secondary" />
                            ) : (
                              row.id > 0 && <HowToReg color="primary" />
                            )}
                          </TableCell>
                          {Object.keys(keys).map((key, index2) => {
                            return (
                              <TableCell
                                align="left"
                                padding="none"
                                style={{
                                  whiteSpace: 'normal',
                                  wordWrap: 'break-word',
                                  paddingRight: '5px',
                                  paddingLeft: '5px',
                                }}
                                key={'cell_' + index2}
                              >
                                {row[key]}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      </this.Compose>
                    );
                  })}
            </TableBody>
            <TableFooter>
              {rows && rows.length > 5 && this.renderPaginationRow()}
            </TableFooter>
          </Table>
        </div>
      </div>
    );
  }
}

export default injectIntl(DocumentTable);
