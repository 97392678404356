import { TextField } from '@material-ui/core/';
import * as _ from 'lodash';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import ReactSelect from 'react-select';
import { useAdminContext, useAppContext } from 'utils/AppContextProvider';
import { Item } from '../../../../shared/src/model';
import * as schema from '../../../../shared/src/model/projectSchema';
import { useFormatMessage } from '../../utils/translateHook';
import AdminTools from '../AdminTools';
import Button from '../Common/Button';

interface Props {}

export default function Systems(props: Props) {
  const appCtx = useAppContext();
  const adminCtx = useAdminContext();
  const t = useFormatMessage();
  const [selectedSystem, setSelectedSystem] = useState<schema.system>();
  const [selectedParentSystem, setSelectedParentSystem] = useState<Item | null>(
    null
  );
  const [systemName, setSystemName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (isDisabled) {
      setTimeout(() => {
        setIsDisabled(false);
      }, 2000);
    }
  }, [isDisabled]);

  useEffect(() => {
    appCtx.getSystems();
  }, []);

  // Collect sub systems and super systems to separate arrays
  const [subSystems, superSystems] = _.partition(
    appCtx.systems.sort((a, b) =>
      a.system_name!.localeCompare(b.system_name!, 'fi')
    ),
    system => system.parent_system_id
  );

  const saveNewSystem = async (): Promise<void> => {
    setIsDisabled(true);

    await adminCtx.createNewSystem({
      parent_system_id: selectedParentSystem
        ? selectedParentSystem.value
        : undefined,
      system_name: systemName.trim(),
      description: description.trim(),
    });

    setSystemName('');
    setDescription('');
    appCtx.getSystems();
  };

  const nameString = (system: schema.system): string => {
    return system.description
      ? `${system.system_name!} – ${system.description!}`
      : system.system_name!;
  };

  const parentSystemName = (parentSystemId: number): string => {
    const superSystem = superSystems
      .filter(system => system.id === parentSystemId)
      .pop();

    return superSystem ? nameString(superSystem) : '';
  };

  const newSystem = (
    <ValidatorForm className="newItem" onSubmit={saveNewSystem}>
      <h3>{t('adminTools.newSystem')}</h3>
      <div>
        <ReactSelect
          placeholder={t('adminTools.parentSystem')}
          value={selectedParentSystem}
          options={superSystems.map((superSystem: schema.system) => ({
            label: nameString(superSystem),
            value: superSystem.id,
          }))}
          onChange={(system: Item) => setSelectedParentSystem(system)}
        />
      </div>
      <div>
        <TextField
          style={{ width: '100%' }}
          label={t('adminTools.systemId')}
          value={systemName}
          onChange={e => setSystemName(e.target.value)}
        />
      </div>
      <div>
        <TextField
          style={{ width: '100%' }}
          label={t('adminTools.systemDescription')}
          value={description}
          onChange={e => setDescription(e.target.value)}
        />
      </div>
      <Button
        type="submit"
        style={{ margin: '10px 0 0' }}
        disabled={!systemName.trim() || !description.trim() || isDisabled}
      >
        {selectedParentSystem
          ? t('adminTools.saveNewSystem')
          : t('adminTools.saveNewParentSystem')}
      </Button>
    </ValidatorForm>
  );

  const getSubSystems = (superSystem: schema.system) => {
    return subSystems.filter(
      system => system.parent_system_id === superSystem.id
    );
  };

  return (
    <AdminTools>
      <div className="grid gridCols3">
        <div className="component master">
          <h2>{t('adminTools.systems')}</h2>
          <div className="content">
            {newSystem}
            <ul>
              {superSystems.map(superSystem => (
                <li
                  key={superSystem.id}
                  className="superSystem"
                  onClick={() => setSelectedSystem(superSystem)}
                >
                  <span>{nameString(superSystem)}</span>
                  <ul>
                    {getSubSystems(superSystem).map(system => (
                      <li
                        key={system.id}
                        className="system"
                        onClick={e => {
                          e.stopPropagation();
                          setSelectedSystem(system);
                        }}
                      >
                        <span>{nameString(system)}</span>
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {selectedSystem && (
          <div className="detail">
            <div className="component">
              <h2>{nameString(selectedSystem)}</h2>
              <div className="content">
                <dl>
                  <dt>{t('adminTools.systemId')}</dt>
                  <dd>{selectedSystem.system_name}</dd>
                  <dt>{t('adminTools.systemDescription')}</dt>
                  <dd>{selectedSystem.description}</dd>
                  {selectedSystem.parent_system_id && (
                    <>
                      <dt>{t('adminTools.parentSystem')}</dt>
                      <dd>
                        {parentSystemName(selectedSystem.parent_system_id)}
                      </dd>
                    </>
                  )}
                </dl>
              </div>
            </div>
          </div>
        )}
      </div>
    </AdminTools>
  );
}
