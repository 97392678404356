import * as React from 'react';
import { RoomListing } from '../../../../../shared/src/model/index';
import * as _ from 'lodash';
import { PitemStateContext } from './reducers/PitemState';
// import { useAppContext } from 'utils/AppContextProvider';
import { RoomExcelImport } from './RoomExcelImport';
import { DataTable } from 'components/Common/DataTable';
import { LinearProgress } from '@material-ui/core';
import Button from '../../Common/Button';
import { useFormatMessage } from 'utils/translateHook';

interface ItemRegistryProps {}

export function RoomImport(props: ItemRegistryProps) {
  const ctx = React.useContext(PitemStateContext);
  const t = useFormatMessage();
  const [excelData, setExcelData] = React.useState<RoomListing | undefined>(
    undefined
  );

  const [isAdding, setIsAdding] = React.useState(false);

  React.useEffect(() => {
    ctx.clearState();
  }, []);

  const refreshData = async (inputData: RoomListing) => {
    console.log(inputData);
    setExcelData(inputData);
    await ctx.updateRoomData(inputData);
  };

  // Entering the state.... clearState
  return (
    <>
      <div className="grid gridCols2">
        <div className="content">
          <RoomExcelImport onData={refreshData} />
        </div>
      </div>
      <div className="grid gridCols1">
        {ctx.areasMissingFromDatabase.length > 0 ? (
          <b>
            Seuraavat alueet puuttuvat tietokannasta:{' '}
            {ctx.areasMissingFromDatabase.join(', ')}
          </b>
        ) : null}
        {ctx.missingRooms.length > 0 ? (
          <div>
            <fieldset>
              <legend>
                <i>{t('adminTools.missingRooms')}</i>
              </legend>
              <div>
                <Button
                  disabled={isAdding}
                  onClick={async () => {
                    setIsAdding(true);
                    await ctx.createNewRooms();
                    setIsAdding(false);
                    if (excelData) {
                      await ctx.updateRoomData(excelData);
                    }
                  }}
                >
                  {t('adminTools.addMissingRooms')}
                </Button>
                {isAdding ? <LinearProgress /> : null}
              </div>
              <DataTable
                rows={ctx.missingRooms}
                keys={['Id', 'Segment', 'RoomName', 'AreaName']}
              />
            </fieldset>
          </div>
        ) : ctx.alreadyAddedRooms.length > 0 ? (
          <div>
            <i>{t('adminTools.noMissingRooms')}</i>
          </div>
        ) : null}
        {ctx.buildingChangedForRooms.length > 0 ? (
          <div>
            <fieldset>
              <legend>
                <i>{t('adminTools.buildingChanged')}</i>
              </legend>
              <div>
                <Button
                  disabled={isAdding}
                  onClick={async () => {
                    setIsAdding(true);
                    await ctx.updateChangedRooms();
                    setIsAdding(false);
                    if (excelData) {
                      await ctx.updateRoomData(excelData);
                    }
                  }}
                >
                  {t('adminTools.changeBuildings')}
                </Button>
              </div>
              <DataTable
                rows={ctx.buildingChangedForRooms}
                keys={['roomId', 'newBuildingName', 'newFloorName']}
              />
            </fieldset>
          </div>
        ) : null}
        {ctx.nameChangedForRooms.length > 0 ? (
          <div>
            <fieldset>
              <legend>
                <i>{t('adminTools.nameChanged')}</i>
              </legend>
              <div>
                <Button
                  disabled={isAdding}
                  onClick={async () => {
                    setIsAdding(true);
                    await ctx.updateRoomNames();
                    setIsAdding(false);
                    if (excelData) {
                      await ctx.updateRoomData(excelData);
                    }
                  }}
                >
                  {t('adminTools.changeRoomNames')}
                </Button>
              </div>
              <DataTable
                rows={ctx.nameChangedForRooms}
                keys={['roomId', 'oldName', 'newName']}
              />
            </fieldset>
          </div>
        ) : null}
        {ctx.missingAreas.length > 0 ? (
          <div>
            <fieldset>
              <legend>
                <i>{t('adminTools.roomsWithoutArea')}</i>
              </legend>
              <div>
                <Button
                  disabled={isAdding}
                  onClick={async () => {
                    setIsAdding(true);
                    await ctx.updateRoomAreas();
                    setIsAdding(false);
                    if (excelData) {
                      await ctx.updateRoomData(excelData);
                    }
                  }}
                >
                  {t('adminTools.addAreasToRooms')}
                </Button>
              </div>
              <DataTable
                rows={ctx.missingAreas}
                keys={['Id', 'Segment', 'RoomName', 'AreaName']}
              />
            </fieldset>
          </div>
        ) : null}
        {ctx.alreadyAddedRooms.length > 0 ? (
          <div>
            <fieldset>
              <legend>
                <i>{t('adminTools.alreadyAddedRooms')}</i>
              </legend>
              <DataTable
                rows={ctx.alreadyAddedRooms}
                keys={['Id', 'Segment', 'RoomName', 'AreaName']}
              />
            </fieldset>
          </div>
        ) : null}
      </div>
    </>
  );
}
