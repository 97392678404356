import './index.scss';

import * as Model from '../../../../shared/src/model/index';
import * as React from 'react';
import * as appstate from '../../state/reducers/ApplicationState';
import * as container from '../../state/reducers/DocumentState';
import * as schema from '../../../../shared/src/model/projectSchema';

import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import {
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import {
  errorMessage,
  successMessage,
  warningMessage,
} from '../Common/Notifier';

import { APIService } from '../../services/api/src/frontend/api';
import { Async } from '../Common/Async';
import Button from '../Common/Button';
import { Item } from '../Common/AutosuggestChipSelection';
import ReactSelect from 'react-select';
import { ValueType } from 'react-select/lib/types';
import axios from 'axios';
import { useFormatMessage } from 'utils/translateHook';
import { useAppContext } from 'utils/AppContextProvider';

const API = APIService(axios);

interface ICreatePitemProps {
  header: string;
  contractId?: number;
  onEnd?: () => void;
  onCancel?: () => void;
  onCreate?: (pitem: schema.pitem) => void;
}

enum TargettingType {
  NotSelected = 1,
  Huone,
  Paalu,
  Paaluvali,
}

interface ICreatePitemState {
  selectedBuildings: Item[];
  selectedRooms: Item[];
  targettingTypes: Item[];
  selectedTypes: Item[];
  selectedTargetType: TargettingType;
  addedItems: schema.pitem[];
  code: string;
  description: string;
  sameCodeError: string;
  startPole?: number;
  endPole?: number;
  isSaving: boolean;
}

type IMyProps = ICreatePitemProps &
  InjectedIntlProps &
  container.IProps &
  appstate.IProps;

const SavingProgress = (props: {
  isSaving: boolean;
  addedItems: schema.pitem[];
  updateAddedItems: (addedItems: schema.pitem[]) => void;
}) => {
  const t = useFormatMessage();
  const ctx = useAppContext();
  return (
    <>
      {props.isSaving ? <LinearProgress /> : null}
      {props.addedItems.length > 0 ? (
        <>
          <h2>{t('added.items')}</h2>
          <Table>
            <TableBody>
              {props.addedItems.map((item) => {
                return (
                  <TableRow key={item.id}>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.name_long}</TableCell>
                    <TableCell>
                      {item.pitemtypes
                        ? item.pitemtypes
                            .map((typeid) =>
                              ctx.pitemTypes
                                .filter((tt) => tt.id === typeid)
                                .map((tt) => tt.name)
                                .join('')
                            )
                            .join(', ')
                        : ''}
                    </TableCell>
                    <TableCell>
                      <Button
                        onClick={async () => {
                          try {
                            await API.removePItem(item.id);
                            props.updateAddedItems(
                              props.addedItems.filter((i) => i.id !== item.id)
                            );
                            successMessage(`${item.name} poistettu`);
                          } catch (e) {
                            errorMessage(e);
                          }
                        }}
                      >
                        {t('buttons.delete')}
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </>
      ) : null}
    </>
  );
};
const SelectDetails = (props: {
  onCreate: (obj: {
    code: string;
    description: string;
    selectedTypes: Model.Item[];
  }) => void;
  sameCodeError?: string;
}) => {
  const t = useFormatMessage();
  const [code, setCode] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [selectedTypes, setSelectedTypes] = React.useState<Model.Item[]>([]);
  const ctx = useAppContext();

  return (
    <div className="addPitemArea">
      <h4>{t('itemMeta.itemType')}</h4>
      <ReactSelect
        className="dropdown"
        isMulti={false}
        placeholder={'Valitse kohdetyyppi'}
        value={selectedTypes}
        options={
          [
            ...ctx.targetPitemTypes
              .filter((t) => t && t.upper_type_id)
              .map((category) => ({
                label: category.name,
                value: category.id,
              })),
          ] as Model.Item[]
        }
        onChange={(values: ValueType<Model.Item>) => {
          if (values && !Array.isArray(values)) {
            setSelectedTypes([values as Model.Item]);
          }
        }}
      />
      <div>{t('pitem.code')}</div>
      <div className="pitemExistsError">{props.sameCodeError}</div>
      <input
        value={code}
        onChange={(e) => {
          setCode(e.target.value);
        }}
      />
      <div>{t('pitem.description')}</div>
      <input
        value={description}
        onChange={(e) => {
          setDescription(e.target.value);
        }}
      />
      <div>
        <Button
          onClick={() => {
            props.onCreate({ code, description, selectedTypes });
          }}
        >
          {t('buttons.save')}
        </Button>
      </div>
    </div>
  );
};

export class CreateNewPitem extends React.Component<
  IMyProps,
  ICreatePitemState
> {
  constructor(props: IMyProps) {
    super(props);
    this.state = {
      selectedTargetType: TargettingType.NotSelected,
      selectedBuildings: [],
      selectedRooms: [],
      selectedTypes: [],
      addedItems: [],
      code: '',
      isSaving: false,
      sameCodeError: '',
      description: '',
      targettingTypes: [
        { label: 'Valitse kohdistus', value: TargettingType.NotSelected },
        { label: 'Huone', value: TargettingType.Huone },
        { label: 'Paalu', value: TargettingType.Paalu },
        { label: 'Paaluväli', value: TargettingType.Paaluvali },
      ],
    };
  }

  async createPitem() {
    const t = this.props.intl;
    const translate = (id: string) => t.formatMessage({ id });
    try {
      if (this.state.selectedTargetType === TargettingType.Paalu) {
        if (!this.state.startPole) {
          warningMessage(translate('please.give.pole'));
          return;
        }
      }
      if (this.state.selectedTargetType === TargettingType.Paaluvali) {
        if (!this.state.startPole) {
          warningMessage(translate('please.give.startpole'));
          return;
        }
        if (!this.state.endPole) {
          warningMessage(translate('please.give.endpole'));
          return;
        }
      }
      if (this.state.selectedTypes.length === 0) {
        warningMessage(translate('please.give.pitem.type'));
        return;
      }
      if (!this.state.code) {
        warningMessage(translate('please.give.code'));
        return;
      }
      if (!this.state.description) {
        warningMessage(translate('please.give.description'));
        return;
      }
      this.setState({
        isSaving: true,
      });

      const trimmedCode = this.state.code.trim().toLowerCase();

      // Check if the Pitem already does exist
      const isDuplicate = await API.checkPItemDuplicate({
        name: trimmedCode,
      });

      if (isDuplicate) {
        this.setState({
          sameCodeError: translate('pitem.already.exists'),
          isSaving: false,
        });
        warningMessage(translate('pitem.already.exists'));
        return;
      }

      const pitem = await API.createPItem({
        name: this.state.code,
        name_long: this.state.description,
        parentItemId:
          this.state.selectedTargetType === TargettingType.Huone
            ? this.state.selectedRooms[0].value
            : this.state.selectedBuildings[0].value,
        pitemTypeId: this.state.selectedTypes[0].value,
        singlePole:
          this.state.selectedTargetType === TargettingType.Paalu
            ? this.state.startPole || 0
            : undefined,
        poleRange:
          this.state.selectedTargetType === TargettingType.Paaluvali
            ? [this.state.startPole || 0, this.state.endPole || 0]
            : undefined,
      });
      this.setState({
        sameCodeError: '',
        isSaving: false,
        addedItems: [...this.state.addedItems, ...[pitem]],
      });
      if (this.props.onCreate) {
        this.props.onCreate(pitem);
      }
      successMessage(`${pitem.name} / ${pitem.name_long} lisätty`);
    } catch (e) {
      errorMessage(e);
      this.setState({
        isSaving: false,
      });
    }
  }

  number = (value: string): number | undefined => {
    const n = Number(value);
    if (!isNaN(n) && n >= 0) {
      return n;
    }
    return 0;
  };

  Pole = () => (
    <div>
      <div className="columnLabel">
        <FormattedMessage id="documentMeta.pole" />
      </div>
      <div className="poleRange">
        <input
          type="text"
          className="basic"
          value={this.state.startPole || undefined}
          onChange={(e) => {
            this.setState({
              startPole: this.number(e.target.value),
            });
          }}
        />
      </div>
    </div>
  );
  PoleRange = () => (
    <div>
      <div className="columnLabel">
        <FormattedMessage id="documentMeta.poleInterval" />
      </div>
      <div className="poleRange">
        <input
          type="text"
          className="basic"
          value={this.state.startPole || undefined}
          onChange={(e) => {
            this.setState({
              startPole: this.number(e.target.value),
            });
          }}
        />
        <div className="divider">-</div>
        <input
          type="text"
          className="basic"
          value={this.state.endPole || undefined}
          onChange={(e) => {
            this.setState({
              endPole: this.number(e.target.value),
            });
          }}
        />
      </div>
    </div>
  );

  translate = (id: string) => {
    return this.props.intl.formatMessage({ id });
  };

  render() {
    const t = this.props.intl;
    const translate = (id: string) => t.formatMessage({ id });

    if (this.props.contractId === 0) {
      return <div />;
    }

    return (
      <div className="addingNewPitem">
        <Button
          variant="text"
          className="cancelButton"
          onClick={() => {
            if (this.props.onCancel) {
              this.props.onCancel();
            }
          }}
        >
          {this.translate('buttons.close')}
        </Button>
        <div>
          <h2>{this.props.header}</h2>
        </div>
        <Async
          loader={() =>
            API.searchPItems({
              contractFilter: [this.props.contractId || 0].filter((n) => n),
              pitemTypes: this.props.pitemTypes
                .filter((n) => n.name === 'Rakennusosa')
                .map((t) => t.id),
            })
          }
          render={(data) => {
            if (!data) {
              return <div />;
            }
            return (
              <>
                <h4>{translate('documentMeta.buildingElement')}</h4>
                <ReactSelect
                  isMulti={false}
                  placeholder={'Valitse rakennusosa'}
                  options={data.rows.map((item) => {
                    return {
                      label:
                        item.name +
                        ' / ' +
                        (item.building ? item.building.name_long : ''),
                      value: item.id,
                    };
                  })}
                  onChange={(values: ValueType<Model.Item>) => {
                    if (values && !Array.isArray(values)) {
                      this.setState({
                        selectedBuildings: [values as Model.Item],
                        selectedRooms: [],
                        selectedTargetType: TargettingType.NotSelected,
                        code: '',
                        description: '',
                      });
                    }
                    // this.onSelectDocumentCategory(values as Model.Item[]);
                  }}
                />
                {this.state.selectedBuildings.length > 0 ? (
                  <div>
                    Huone tai paaluväli...
                    <ReactSelect
                      isMulti={false}
                      placeholder={'Kohdistustyyppi'}
                      options={this.state.targettingTypes}
                      value={this.state.targettingTypes
                        .filter(
                          (v) => v.value === this.state.selectedTargetType
                        )
                        .pop()}
                      onChange={(values: ValueType<Model.Item>) => {
                        if (values && !Array.isArray(values)) {
                          this.setState({
                            selectedTargetType: (values as Model.Item).value,
                          });
                        }
                        // this.onSelectDocumentCategory(values as Model.Item[]);
                      }}
                    />
                  </div>
                ) : null}
                {this.state.selectedTargetType === TargettingType.Paalu ? (
                  <div>
                    <this.Pole />
                    <SelectDetails
                      onCreate={(obj) => {
                        this.setState(
                          {
                            code: obj.code,
                            description: obj.description,
                            selectedTypes: obj.selectedTypes,
                          },
                          () => {
                            this.createPitem();
                          }
                        );
                      }}
                    />
                    <SavingProgress
                      isSaving={this.state.isSaving}
                      addedItems={this.state.addedItems}
                      updateAddedItems={(list) => {
                        this.setState({ addedItems: list });
                      }}
                    />
                  </div>
                ) : null}
                {this.state.selectedTargetType === TargettingType.Paaluvali ? (
                  <div>
                    <this.PoleRange />
                    <SelectDetails
                      onCreate={(obj) => {
                        this.setState(
                          {
                            code: obj.code,
                            description: obj.description,
                            selectedTypes: obj.selectedTypes,
                          },
                          () => {
                            this.createPitem();
                          }
                        );
                      }}
                    />
                    <SavingProgress
                      isSaving={this.state.isSaving}
                      addedItems={this.state.addedItems}
                      updateAddedItems={(list) => {
                        this.setState({ addedItems: list });
                      }}
                    />
                  </div>
                ) : null}
                {this.state.selectedTargetType === TargettingType.Huone ? (
                  <div>
                    <Async
                      uri={this.state.selectedBuildings
                        .map((item) => item.value)
                        .join('.')}
                      loader={() =>
                        API.searchPItems({
                          contractFilter: [this.props.contractId || 0].filter(
                            (n) => n
                          ),
                          buildingPartIds: this.state.selectedBuildings.map(
                            (item) => item.value
                          ),
                          pitemTypes: this.props.pitemTypes
                            .filter((n) => n.name === 'Huone')
                            .map((t) => t.id),
                        })
                      }
                      render={(data) => {
                        if (!data) {
                          return <div />;
                        }
                        return (
                          <>
                            <div>
                              {this.translate(
                                'contractorDocumentPackage.selectRoom'
                              )}
                            </div>
                            <ReactSelect
                              isMulti={false}
                              placeholder={this.translate(
                                'contractorDocumentPackage.selectRoom'
                              )}
                              value={this.state.selectedRooms[0]}
                              options={data.rows.map((item) => {
                                return {
                                  label:
                                    item.name +
                                    ' / ' +
                                    (item.room ? item.room.name_long : ''),
                                  value: item.id,
                                };
                              })}
                              onChange={(values: ValueType<Model.Item>) => {
                                if (values && !Array.isArray(values)) {
                                  this.setState({
                                    selectedRooms: [values as Model.Item],
                                  });
                                }
                                // this.onSelectDocumentCategory(values as Model.Item[]);
                              }}
                            />
                            {this.state.selectedRooms.length > 0 ? (
                              <>
                                <SelectDetails
                                  onCreate={(obj) => {
                                    this.setState(
                                      {
                                        code: obj.code,
                                        description: obj.description,
                                        selectedTypes: obj.selectedTypes,
                                      },
                                      () => {
                                        this.createPitem();
                                      }
                                    );
                                  }}
                                />
                                <SavingProgress
                                  isSaving={this.state.isSaving}
                                  addedItems={this.state.addedItems}
                                  updateAddedItems={(list) => {
                                    this.setState({ addedItems: list });
                                  }}
                                />
                              </>
                            ) : null}
                          </>
                        );
                      }}
                    />
                  </div>
                ) : null}
              </>
            );
          }}
        />
      </div>
    );
  }
}

const CreateNewPitemContainer = appstate.StateConnector(
  container.StateConnector(CreateNewPitem)
);

export default injectIntl(CreateNewPitemContainer);
