import { pitem } from '../../../../../shared/src/model/projectSchema';
import {
  ItemListing,
  ItemDefinitionRow,
} from '../../../../../shared/src/model/index';
import * as _ from 'lodash';
import { notEmpty } from '../../../utils';
import { createNewPItemIntoRoom } from '../../../state/api';

export interface ParentChange {
  oldParent: pitem;
  row: ItemDefinitionRow;
}

export interface TypeChange {
  oldTypeName: string;
  newTypeName: string;
}

export interface ItemDataProps {
  newItems: ItemDefinitionRow[];
  oldList: pitem[];
}
export interface Diff {
  newItems: ItemDefinitionRow[];
  preservedItems: ItemDefinitionRow[];
  parentChange?: ParentChange;
  typeChange?: TypeChange;
}

export interface SegmentDiff {
  segment: pitem;
  difference: Diff;
}
export function PItemListCompare(props: ItemDataProps): Diff {
  const newItems = _.differenceWith(props.newItems, props.oldList, (a, b) => {
    return a.Id === b.name;
  });
  const preservedItems = _.intersectionWith(
    props.newItems,
    props.oldList,
    (a, b) => {
      return a.Id === b.name;
    }
  );

  return {
    newItems,
    preservedItems,
  };
}

export interface SegmentItemDataProps {
  allBuildings: pitem[];
  newItems: ItemListing;
  oldItems: pitem[];
}

// Room ID can be in other building...
export function CompareData(props: SegmentItemDataProps) {
  const segments = _.uniq(props.newItems.rows.map(row => row.Segment))
    .map(name => {
      return _.find(props.allBuildings, { name });
    })
    .filter(notEmpty);

  // continue over here...
  return segments.map(seg => {
    const oldList = props.oldItems.filter(item =>
      (item.parents || []).includes(seg.id)
    );
    const newItems = props.newItems.rows.filter(
      row => row.Segment === seg.name
    );
    const comp = PItemListCompare({
      oldList,
      newItems,
    });
    return {
      segment: seg,
      difference: comp,
    };
  });
}

export async function createRoomBasedPitems(
  typeName: string,
  list: ItemDefinitionRow[]
) {
  try {
    for (const item of list) {
      await createNewPItemIntoRoom({
        code: item.Id,
        description: item.Type,
        roomName: item.RoomId,
        pitemTypeName: typeName,
      });
    }
  } catch (e) {
    console.error(e);
  }
}
