import * as React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import * as applicationState from '../../state/reducers/ApplicationState';
import AdminTools from '../AdminTools';
import WorkflowDiagram from './WorkflowDiagram';
import './adminTools.scss';
import { Async } from '../Common/Async';
import { push } from 'connected-react-router';
import DocumentCategorySelect from '../Common/DocumentCategorySelect';
// import { errorMessage, successMessage } from '../Common/Notifier';

// katso lappu 1380

import axios from 'axios';
import { APIService } from '../../services/api/src/frontend/api';
// import { DocumentService } from '../../services/document/src/frontend/api';

// const DOC = DocumentService(axios);
import * as schema from '../../../../shared/src/model/projectSchema';
import {
  List,
  ListItem,
  Select,
  MenuItem,
  FormControl,
  Input,
} from '@material-ui/core';

const API = APIService(axios);
interface IApprovalCyclesProps extends applicationState.IProps {
  match: {
    params: {
      id: number;
    };
  };
}

interface IApprovalCyclesState {
  workflows: schema.workflow[];

  activeAction?: schema.action;
  selectedRole?: schema.role;
  selectedCategories: number[];
}
// API.getWorkflows()
type ApprovalCyclesProps = IApprovalCyclesProps & InjectedIntlProps;

class AbstractApprovalCycles extends React.Component<
  ApprovalCyclesProps,
  IApprovalCyclesState
> {
  constructor(props: ApprovalCyclesProps) {
    super(props);
    this.state = { workflows: [], selectedCategories: [] };
  }

  componentDidMount = async () => {
    this.setState({ workflows: await API.getWorkflows() });
  };

  render() {
    const t = (id: string) => this.props.intl.formatMessage({ id });
    const wf = this.state.workflows
      .filter(wf => wf.id === Number(this.props.match.params.id))
      .pop();
    return (
      <AdminTools>
        <div className="grid gridCols3">
          <div className="component master">
            <h2>{t('adminTools.workflows')}</h2>
            <List>
              {this.state.workflows.map(item => {
                return (
                  <ListItem
                    onClick={() => {
                      this.setState({ activeAction: undefined });
                      this.props.ApplicationStateDispatcher(
                        push('/admin-tools/approval-cycles/' + item.id)
                      );
                    }}
                    key={item.id}
                  >
                    {item.description}
                  </ListItem>
                );
              })}
            </List>
          </div>
          <div className="detail">
            {wf && this.props.match.params.id > 0 ? (
              <div className="component">
                <div>
                  <h2>{wf.description}</h2>
                  <Async
                    uri={this.props.match.params.id}
                    loader={async () => {
                      const actions = await API.getActions(
                        this.props.match.params.id
                      );
                      return actions;
                    }}
                    render={actions => {
                      if (!actions) return <div>No actions</div>;
                      return (
                        <div>
                          <WorkflowDiagram
                            activeAction={this.state.activeAction}
                            workflow={wf}
                            actions={actions}
                            onSelectAction={action => {
                              // if user clicks edge on the diagram
                              console.log(action);
                              this.setState({ activeAction: action });
                            }}
                            onSelectUnusedAction={(from, to) => {
                              console.log('Not used action ', from, to);
                            }}
                          />

                          <h2>Actions</h2>

                          <List>
                            {actions.map(action => {
                              return (
                                <ListItem key={action.id}>
                                  {this.state.activeAction &&
                                  this.state.activeAction.id === action.id ? (
                                    <b>{action.action_name}</b>
                                  ) : (
                                    action.action_name
                                  )}
                                </ListItem>
                              );
                            })}
                          </List>

                          {this.state.activeAction ? (
                            <Async
                              uri={this.state.activeAction.id}
                              loader={async () =>
                                await API.getActionRoles(
                                  this.state.activeAction
                                    ? this.state.activeAction.id
                                    : 0
                                )
                              }
                              render={data => {
                                if (!data) {
                                  return <div />;
                                }
                                return (
                                  <>
                                    <h2>Roolit</h2>
                                    <List>
                                      {data.map(role => (
                                        <ListItem key={role.id}>
                                          {role.role_name}
                                        </ListItem>
                                      ))}
                                    </List>
                                    <Async
                                      loader={() => API.getSystemRoles()}
                                      render={data => {
                                        if (!data) return <div />;
                                        return (
                                          <div className="worfklowRoleEditArea">
                                            <div>
                                              <div>
                                                <b>Rooli</b>
                                              </div>
                                              <Select
                                                value={
                                                  this.state.selectedRole
                                                    ? this.state.selectedRole.id
                                                    : undefined
                                                }
                                                onChange={event => {
                                                  this.setState({
                                                    selectedRole: data
                                                      .filter(
                                                        r =>
                                                          r.id ===
                                                          parseInt(
                                                            event.target.value,
                                                            10
                                                          )
                                                      )
                                                      .pop(),
                                                  });
                                                }}
                                              >
                                                {data.map(role => {
                                                  return (
                                                    <MenuItem
                                                      value={role.id}
                                                      key={role.id}
                                                    >
                                                      {role.role_name}
                                                    </MenuItem>
                                                  );
                                                })}
                                              </Select>
                                            </div>
                                            <div>
                                              <div>
                                                <b>Kategoriat</b>
                                              </div>
                                              <FormControl>
                                                <Select
                                                  multiple={true}
                                                  displayEmpty={true}
                                                  value={
                                                    this.state
                                                      .selectedCategories
                                                  }
                                                  onChange={event => {
                                                    const list: unknown =
                                                      event.target.value;
                                                    if (Array.isArray(list)) {
                                                      this.setState({
                                                        selectedCategories: list,
                                                      });
                                                    }
                                                  }}
                                                  input={
                                                    <Input id="select-multiple-placeholder" />
                                                  }
                                                  renderValue={selected => {
                                                    if (
                                                      !Array.isArray(selected)
                                                    ) {
                                                      return (
                                                        <em>Placeholder</em>
                                                      );
                                                    }

                                                    return selected
                                                      .map(cid => {
                                                        return this.props.appDocumentCategories.filter(
                                                          c => c.id === cid
                                                        )[0];
                                                      })
                                                      .filter(value => value)
                                                      .map(value => value.name)
                                                      .join(', ');
                                                  }}
                                                >
                                                  <MenuItem
                                                    disabled={true}
                                                    value=""
                                                  >
                                                    <em>Placeholder</em>
                                                  </MenuItem>
                                                  {this.props.appDocumentCategories.map(
                                                    cat => (
                                                      <MenuItem
                                                        key={cat.id}
                                                        value={cat.id}
                                                      >
                                                        {cat.name}
                                                      </MenuItem>
                                                    )
                                                  )}
                                                </Select>
                                              </FormControl>
                                            </div>
                                          </div>
                                        );
                                      }}
                                    />
                                  </>
                                );
                              }}
                            />
                          ) : null}

                          <h2>
                            Työnkulku on voimassa seuraavilla
                            dokumenttikategorioilla
                          </h2>

                          <Async
                            uri={this.props.match.params.id}
                            loader={() => API.getWorkflowDocCategories(wf.id)}
                            render={data => {
                              if (!data) {
                                return <div />;
                              }
                              return (
                                <DocumentCategorySelect
                                  selectedCategories={data.map(c => c.id)}
                                />
                              );
                            }}
                          />
                        </div>
                      );
                    }}
                  />
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </AdminTools>
    );
  }
}

const AprovalCycles = applicationState.StateConnector(AbstractApprovalCycles);

export default injectIntl(AprovalCycles);
