export const ClassViewParams = {
  action: [
    "display.name",
    "action_name",
    ["from_state_id", "display.name"],
    ["to_state_id", "display.name"],
    ["workflow_id", "display.name"]
  ],
  admin_request: [
    "display.name",
    ["user_id", "display.name"],
    "topic",
    "request",
    "answered",
    "response"
  ],
  aitem: [
    "display.name",
    "name",
    "mfilesid",
    "route_id",
    "route_start_pos",
    "route_end_pos",
    ["status_id", "display.name"],
    "name_long",
    "contractor",
    "is_hidden"
  ],
  aitem_aitem_type_connection: [
    "display.name",
    ["aitem_id", "display.name"],
    ["aitem_type_id", "display.name"]
  ],
  aitem_connection: [
    "display.name",
    ["aitem_id", "display.name"],
    ["belongs_to_id", "display.name"]
  ],
  aitem_pitemtype: ["display.name", "aitem_id", "pitem_type_id"],
  aitem_status: ["display.name", "status_name"],
  aitem_type: ["display.name", "name"],
  area: ["display.name", "area_name", "description", "mfilesid"],
  area_section: [
    "display.name",
    ["area_id", "display.name"],
    ["pitem_id", "display.name"],
    "name",
    "pole_start_pos",
    "pole_end_pos",
    "mfilesid"
  ],
  building_permit: ["display.name", "name", ["aitem_id", "display.name"]],
  contract_phase: ["display.name", "aitem_id", "phase_id"],
  criticality_class: ["display.name", "criticality_class_name"],
  document: [
    "display.name",
    "name",
    "description",
    ["documentstate_id", "display.name"],
    "mfilesid",
    "mfilesversionid",
    "filename",
    "fileextension",
    "filehash",
    "owner_id",
    "belongs_to_id",
    "created_at",
    "updated_at",
    "completed_at",
    "source_id",
    "root_version_id",
    "version_number",
    "route_id",
    "route_start_pos",
    "route_end_pos",
    "revision",
    "dwg_name",
    "filesize",
    "print_color",
    ["aitems", "display.name"],
    ["pitems", "display.name"],
    ["categories", "display.name"],
    "owner_name",
    "aitem_name",
    "pitem_name",
    ["workflow_id", "display.name"],
    "ordinal",
    ["modified_by", "display.name"],
    "is_metadata_version",
    "updater_name",
    "is_missing_docs",
    "what_is_missing",
    ["pitemtypes", "display.name"],
    ["system_id", "display.name"],
    ["systems_path", "display.name"],
    ["areas", "display.name"],
    ["replaced_by_id", "display.name"],
    "owner_email",
    "updater_email",
    "area_name",
    "building_name",
    "document_type_name",
    "design_principle_name",
    "contractor_document_type",
    "pitem_type_name",
    "work_type",
    "room_name",
    "room_long_name",
    "inspection_doc_target"
  ],
  document_aitem: [
    "display.name",
    ["document_id", "display.name"],
    ["aitem_id", "display.name"]
  ],
  document_category: [
    "display.name",
    ["upper_category_id", "display.name"],
    "category_name",
    ["initial_state_id", "display.name"],
    ["initial_workflow_id", "display.name"],
    "allow_eam"
  ],
  document_comment: [
    "display.name",
    ["document_id", "display.name"],
    ["user_id", "display.name"],
    "comment",
    "created_at",
    ["document_comment_category_id", "display.name"]
  ],
  document_comment_category: ["display.name", "category_name", "description"],
  document_comment_seen: [
    "display.name",
    "document_comment_id",
    "user_id",
    "seen_at"
  ],
  document_document_category: [
    "display.name",
    "document_id",
    "document_category_id"
  ],
  documentgroup: ["display.name", "name"],
  document_metadata: ["display.name", ["document_id", "display.name"]],
  document_pitem: [
    "display.name",
    ["document_id", "display.name"],
    ["pitem_id", "display.name"]
  ],
  document_pitem_aitem: [
    "display.name",
    ["document_id", "display.name"],
    ["pitem_id", "display.name"],
    ["aitem_id", "display.name"]
  ],
  document_role: [
    "display.name",
    ["document_id", "display.name"],
    ["role_id", "display.name"]
  ],
  documentstate: ["display.name", "name", "description"],
  documenttype: [
    "display.name",
    "name",
    "description",
    "mfilesvaluelistid",
    "mfilesid",
    "categoryid"
  ],
  documentversion: [
    "display.name",
    ["document_id", "display.name"],
    "version_name"
  ],
  documentversion_documentstate: [
    "display.name",
    "document_id",
    ["documentstate_id", "display.name"],
    ["previousstate_id", "display.name"],
    ["activator_user_id", "display.name"],
    "activate_time",
    "reason"
  ],
  download_counter: [
    "display.name",
    ["user_id", "display.name"],
    ["document_id", "display.name"],
    "updated_at"
  ],
  eam_process_error: [
    "display.name",
    "created_at",
    "sequence_id",
    "row_id",
    "table_name",
    "error_info"
  ],
  event_log: ["display.name", ["user_id", "display.name"], "event_time"],
  language: ["display.name", "language_name", "language_code"],
  localization_settings: [
    "display.name",
    ["language_id", "display.name"],
    "setting_purpose_code",
    "setting_value_plaintext",
    "setting_value_html"
  ],
  mail: [
    "display.name",
    ["from_user_id", "display.name"],
    "template",
    ["language_id", "display.name"],
    "subject",
    "message",
    "created_at"
  ],
  mail_task: [
    "display.name",
    ["mail_id", "display.name"],
    "template",
    ["language_id", "display.name"],
    "to_address",
    "retrycount",
    "created_at",
    "sent_at",
    "failed_at",
    "rendered_html"
  ],
  mfiles_targetting: [
    "display.name",
    "target_mfilesid",
    "target_mfilesversion"
  ],
  pitem: [
    "display.name",
    "name",
    "name_long",
    "mfilesid",
    "route_id",
    "route_start_pos",
    "route_end_pos",
    "specifier",
    ["parents", "display.name"],
    ["pitemtypes", "display.name"],
    ["created_by_user", "display.name"],
    "is_tracked",
    ["work_aitems", "display.name"],
    ["system_id", "display.name"],
    ["systems_path", "display.name"],
    "warranty_expires",
    "additional_information",
    "additional_information2",
    ["upper_equipment_id", "display.name"],
    ["secondary_hierarchy_id", "display.name"],
    ["criticality_class_id", "display.name"],
    "room_pole_start",
    "room_pole_end",
    ["area_id", "display.name"]
  ],
  pitem_aitem_connection: [
    "display.name",
    ["pitem_id", "display.name"],
    ["aitem_id", "display.name"]
  ],
  pitem_connection: [
    "display.name",
    ["pitem_id", "display.name"],
    ["belongs_to_id", "display.name"]
  ],
  pitem_pitem_type_connection: [
    "display.name",
    ["pitem_id", "display.name"],
    ["pitem_type_id", "display.name"]
  ],
  pitem_target_matrix: [
    "display.name",
    ["segment_id", "display.name"],
    ["pitem_type_id", "display.name"],
    ["pitem_parent_type_id", "display.name"]
  ],
  pitem_type: ["display.name", "name", ["upper_type_id", "display.name"]],
  print_order: [
    "display.name",
    ["user_id", "display.name"],
    "orderer",
    "orderer_first_name",
    "orderer_last_name",
    "orderer_email",
    "orderer_phone_number",
    "project_name",
    "payer_company",
    "payer_address",
    "payer_postal_code",
    "payer_city",
    "additional_info",
    "created_at",
    "payer_country"
  ],
  print_order_row: [
    "display.name",
    ["print_order_id", "display.name"],
    "company",
    "address",
    "city",
    "postal_code",
    "delivery_method",
    "additional_info",
    "sent_at",
    "order_success",
    "send_attempts"
  ],
  print_order_row_document: [
    "display.name",
    ["print_order_row_id", "display.name"],
    ["document_id", "display.name"],
    "amount",
    "folding",
    "is_color"
  ],
  privilege: ["display.name", "privilege_name", "description", "is_template"],
  privilege_target_specifier: [
    "display.name",
    ["role_privilege_id", "display.name"],
    ["pitem_type_id", "display.name"],
    ["document_category_id", "display.name"],
    ["documentstate_id", "display.name"]
  ],
  refresh_external_db: [
    "display.name",
    "local_table",
    "local_table_id",
    "created_at"
  ],
  role: ["display.name", "role_name", "default_role"],
  role_privilege: [
    "display.name",
    ["role_id", "display.name"],
    ["privilege_id", "display.name"],
    ["action_id", "display.name"],
    "not_rule"
  ],
  route: ["display.name", "name", "lat", "long"],
  service_down: ["display.name", "down", "start_time", "end_time"],
  system: [
    "display.name",
    "system_name",
    ["parent_system_id", "display.name"],
    "description",
    ["area_id", "display.name"]
  ],
  table_sync_session: [
    "display.name",
    "archive_table_name",
    "has_been_initialized",
    "doc_sync_current_id",
    "doc_sync_max_id",
    "sync_start_archive_id",
    "sync_end_archive_id"
  ],
  test_run: [
    "display.name",
    "service_name",
    "updated_at",
    "success",
    "success_cnt",
    "failed_cnt",
    "coverage"
  ],
  ui_state: ["display.name", ["user_id", "display.name"], "tag", "state_name"],
  user: [
    "display.name",
    "firstname",
    "lastname",
    "deactivated",
    "ldapid",
    "is_superuser",
    ["language_id", "display.name"],
    "email",
    "contractor_mail_collected"
  ],
  user_agreements: ["display.name", "active", "updated_at"],
  user_email_settings: [
    "display.name",
    ["user_id", "display.name"],
    "is_enabled",
    "email_name",
    "sent_at"
  ],
  user_has_agreements: [
    "display.name",
    ["user_id", "display.name"],
    ["agreement_id", "display.name"],
    "agreed_at"
  ],
  user_has_email_summary: [
    "display.name",
    ["user_id", "display.name"],
    ["aitem_id", "display.name"]
  ],
  user_role_privilege: [
    "display.name",
    ["user_id", "display.name"],
    ["role_privilege_id", "display.name"],
    ["aitem_id", "display.name"],
    ["pitem_id", "display.name"]
  ],
  vfs_file: [
    "display.name",
    ["folderid", "display.name"],
    "file_name",
    "file_type",
    "contents"
  ],
  vfs_folder: ["display.name", "folder_name"],
  workflow: [
    "display.name",
    "workflow_name",
    "description",
    ["initial_state", "display.name"]
  ],
  worksheet_document: ["display.name", ["document_id", "display.name"]]
};
