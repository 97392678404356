import * as React from 'react';
import { TextField } from '@material-ui/core';
import AddCircle from '@material-ui/icons/AddCircle';
import RemoveCircle from '@material-ui/icons/RemoveCircle';

interface SmallNumberPickerProps {
  value: number | ''; // Can be set to '' by parent.
  onChange: (newValue: number) => void;
  showButtons?: boolean;
  minValue?: number;
  maxValue?: number;
  disableEdit?: boolean;
}

interface SmallNumberPickerState {
  // Save value here while editing, send to parent on blur.
  internalValue: number | '';
}

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100px',
  },
  mofidier: {
    cursor: 'pointer',
    margin: '0px 4px 0px 4px',
  },
};

const inputProps = {
  style: {
    textAlign: 'center',
    width: '30px',
    height: '20px',
    padding: '5px',
  } as React.CSSProperties,
};

/**
 * Number picker with buttons for decrement and increment. Negative numbers not supported.
 */
export default class SmallNumberPicker extends React.Component<
  SmallNumberPickerProps,
  SmallNumberPickerState
> {
  state = {
    internalValue: this.props.value,
  };

  constructor(props: SmallNumberPickerProps) {
    super(props);
    this.onBlur = this.onBlur.bind(this);
    this.onChange = this.onChange.bind(this);

    // Clamp initial value.
    if (this.state.internalValue !== '') {
      this.state.internalValue = this.clamp(this.state.internalValue);
    }
  }

  shouldComponentUpdate(
    nextProps: SmallNumberPickerProps,
    nextState: SmallNumberPickerState
  ) {
    return (
      nextState.internalValue !== this.state.internalValue ||
      nextProps.value !== this.state.internalValue
    );
  }

  componentDidUpdate(prevProps: SmallNumberPickerProps) {
    // React to programmatic value change by parent component.
    if (
      prevProps !== this.props &&
      this.props.value !== this.state.internalValue
    ) {
      this.setState({ internalValue: this.props.value });
    }
  }

  clamp(num: number) {
    const min = this.props.minValue;
    const max = this.props.maxValue;
    if (typeof min === 'number' && num < min) {
      return min;
    }
    if (typeof max === 'number' && num > max) {
      return max;
    }
    return num;
  }

  // Make sure input is of type number | ''
  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    if (value.length === 0) {
      this.setState({ internalValue: '' });
    } else {
      const parsed = parseInt(value, 10);
      if (!Number.isNaN(parsed)) {
        this.setState({ internalValue: parsed });
      }
    }
  };

  // Make sure input is in a consistent state after focus leaves.
  // Send updated value to parent component.
  onBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    if (value === '') {
      // If no input, revert back to previous value.
      this.setState({ internalValue: this.props.value });
    } else {
      const parsed = this.clamp(parseInt(value, 10));
      if (!Number.isNaN(parsed)) {
        this.setState({ internalValue: parsed });
        if (this.props.value !== parsed) {
          this.props.onChange(parsed);
        }
      }
    }
  };

  add(val: number) {
    return () => {
      this.setState(
        state => {
          const currentValue = state.internalValue;
          if (currentValue === '') {
            return null;
          }
          return { internalValue: this.clamp(currentValue + val) };
        },
        () => {
          if (
            this.state.internalValue !== '' &&
            this.props.value !== this.state.internalValue
          ) {
            this.props.onChange(this.state.internalValue);
          }
        }
      );
    };
  }

  increment = this.add(1).bind(this);
  decrement = this.add(-1).bind(this);

  public render() {
    return (
      <div style={styles.container}>
        {this.props.showButtons && (
          <RemoveCircle
            onClick={this.decrement}
            style={styles.mofidier}
            fontSize="small"
            color="primary"
          />
        )}
        <TextField
          value={this.state.internalValue}
          onChange={this.onChange}
          onBlur={this.onBlur}
          margin="none"
          disabled={this.props.disableEdit}
          variant="outlined"
          inputProps={inputProps}
        />
        {this.props.showButtons && (
          <AddCircle
            onClick={this.increment}
            style={styles.mofidier}
            fontSize="small"
            color="primary"
          />
        )}
      </div>
    );
  }
}
