import * as React from 'react';
import AdminTools from '../index';
import MainView from './MainView';
interface ItemRegistryProps {}

export default function ItemRegistry(props: ItemRegistryProps) {
  return (
    <AdminTools>
      <MainView />
    </AdminTools>
  );
}
