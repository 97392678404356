import * as React from 'react';
import * as container from '../../state/reducers/ApplicationState';
import { FormattedMessage, injectIntl, InjectedIntlProps } from 'react-intl';
import { ValidatorForm } from 'react-material-ui-form-validator';
import PasswordField from '../Common/PasswordField';

import './passwordReset.scss';

import PublicLayout from '../../layout/PublicLayout';
import TextField from '../Common/TextField';
import Button from '../Common/Button';
import { Link } from 'react-router-dom';
import Loading from '../Common/Loading';
import { Notifier } from '../Common/Notifier';

interface IPasswordResetProps extends container.IProps {
  something?: string;
  match: {
    params: {
      token: string;
    };
  };
}

interface IPasswordResetState {
  email: string;
  messageVisible: boolean;
  passwordNew: string;
  passwordNew2: string;
  passwordChanged: boolean;
  mailSent: boolean;
}

type PasswordResetProps = IPasswordResetProps & InjectedIntlProps;

class AbstractPasswordReset extends React.Component<
  PasswordResetProps,
  IPasswordResetState
> {
  constructor(props: PasswordResetProps) {
    super(props);

    this.state = {
      email: '',
      messageVisible: false,
      passwordNew: '',
      passwordNew2: '',
      passwordChanged: false,
      mailSent: false,
    };
  }

  componentDidMount() {
    const { token } = this.props.match.params;
    if (token) {
      this.props.getUserHasPermissions(token);
    }
  }

  componentDidUpdate(prevProps: PasswordResetProps) {
    if (prevProps.appstatus !== this.props.appstatus) {
      const {
        sendPasswordResetRequest,
        resetPasswordWithToken,
      } = this.props.appstatus;

      this.setState({
        passwordChanged: resetPasswordWithToken.loaded,
        mailSent: sendPasswordResetRequest.loaded,
      });
    }
  }

  onPasswordNewChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ passwordNew: e.currentTarget.value });
  };

  onPasswordNew2Change = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ passwordNew2: e.currentTarget.value });
  };

  onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ email: e.currentTarget.value });
  };

  onSendEmail = (e: any) => {
    this.props.sendPasswordResetRequest(this.state.email);
    // TODO: We don't want to tell the user if the actual email has been
    // sent but we wan't to show an error message if the backend answers 500
    this.setState({ messageVisible: true });
  };

  handleNewPassword = (password: string, token: string) => {
    // console.log('handleSubmit');
    this.props.resetPasswordWithToken({
      password,
      token,
    });
  };

  public render() {
    const { token } = this.props.match.params;
    const {
      sendPasswordResetRequest,
      resetPasswordWithToken,
    } = this.props.appstatus;

    const t = this.props.intl.formatMessage;

    ValidatorForm.addValidationRule('isPasswordMatch', (value: string) => {
      return value === this.state.passwordNew;
    });

    const validators = [
      'required',
      `matchRegexp:^\\S*$`,
      'matchRegexp:w*[A-ZÄÖÅ]',
      'matchRegexp:w*[a-zäöå]',
      'matchRegexp:w*[0-9]',
      'minStringLength:8',
    ];
    const errorMessages = [
      t({
        id: 'passwordReset.requirements.required',
      }),
      t({
        id: 'passwordReset.requirements.noSpaces',
      }),
      t({
        id: 'passwordReset.requirements.hasCapitalLetters',
      }),
      t({
        id: 'passwordReset.requirements.hasLowercaseLetters',
      }),
      t({
        id: 'passwordReset.requirements.hasNumbers',
      }),
      t({
        id: 'passwordReset.requirements.eightCharacters',
      }),
    ];

    const _wrapLayout = (children: React.ReactNode) => (
      <PublicLayout>
        {resetPasswordWithToken.snack && (
          <Notifier snack={resetPasswordWithToken.snack} />
        )}
        <div className="grid gridCols3">
          <div className="publicPasswordReset component">
            <h2>
              <FormattedMessage id="passwordReset.header" />
            </h2>
            <div className="publicPasswordReset content">{children}</div>
          </div>
        </div>
      </PublicLayout>
    );

    const _renderBackToLogin = () => (
      <Link to="/" className="footerLink">
        <FormattedMessage id="passwordReset.backToLogin" />
      </Link>
    );

    if (
      token &&
      this.props.tokenValidity &&
      !this.props.tokenValidity.hasPermissions
    ) {
      return _wrapLayout(
        <FormattedMessage id="passwordReset.userHasNoPermissions" />
      );
    }

    if (
      token &&
      this.props.tokenValidity &&
      this.props.tokenValidity.isExpired
    ) {
      return _wrapLayout(
        <>
          <FormattedMessage id="passwordReset.tokenExpired" />
          {_renderBackToLogin()}
        </>
      );
    }

    if (sendPasswordResetRequest.loading || resetPasswordWithToken.loading) {
      return _wrapLayout(<Loading loading={true} />);
    }

    if (this.state.mailSent) {
      return _wrapLayout(
        <div>
          <FormattedMessage id="passwordReset.messageSent" />
          {_renderBackToLogin()}
        </div>
      );
    }

    if (this.state.passwordChanged) {
      return _wrapLayout(
        <div>
          <FormattedMessage id="passwordReset.success" />
          {_renderBackToLogin()}
        </div>
      );
    }

    if (!token) {
      return _wrapLayout(
        <div className="content">
          <p>
            <FormattedMessage id="passwordReset.instructions" />
          </p>
          <TextField
            label={t({
              id: 'passwordReset.email',
            })}
            defaultValue={this.state.email}
            onChange={this.onEmailChange}
          />
          <Button color="primary" onClick={this.onSendEmail}>
            <FormattedMessage id="passwordReset.submitEmail" />
          </Button>
          {_renderBackToLogin()}
        </div>
      );
    } else {
      // Got token
      return _wrapLayout(
        <div className="content">
          <pre>
            <FormattedMessage id="passwordReset.requirements" />
          </pre>
          <ValidatorForm
            onSubmit={() => {
              this.handleNewPassword(this.state.passwordNew, token);
            }}
          >
            <PasswordField
              name="passwordNew"
              label={t({ id: 'passwordReset.passwordNew' })}
              value={this.state.passwordNew}
              validators={validators}
              errorMessages={errorMessages}
              onChange={this.onPasswordNewChange}
            />
            <PasswordField
              name="passwordNew2"
              label={t({ id: 'passwordReset.passwordNew2' })}
              value={this.state.passwordNew2}
              validators={['isPasswordMatch']}
              errorMessages={[
                t({ id: 'passwordReset.requirements.isPasswordMatch' }),
              ]}
              onChange={this.onPasswordNew2Change}
            />
            <Button color="primary" type="submit">
              <FormattedMessage id="passwordReset.submit" />
            </Button>
          </ValidatorForm>
        </div>
      );
    }
  }
}

const PasswordReset = container.StateConnector(AbstractPasswordReset);

export default injectIntl(PasswordReset);
