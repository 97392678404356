import * as React from 'react';
import * as container from '../../state/reducers/ApplicationState';
import { FormattedMessage, injectIntl, InjectedIntlProps } from 'react-intl';
import { setBackgroundImage } from '../../utils';

import MainLayout from '../../layout/MainLayout';
import { AdminUI } from './admin';

type SettingsProps = container.IProps & InjectedIntlProps;

class AbstractSettings extends React.Component<SettingsProps> {
  constructor(props: SettingsProps) {
    super(props);
  }

  componentDidMount() {
    setBackgroundImage('managementTools');
  }

  public render() {
    return (
      <MainLayout>
        <h1>
          <FormattedMessage id="headers.managementTools" />
        </h1>

        <div className="grid gridCols3">
          <AdminUI />
        </div>
      </MainLayout>
    );
  }
}

const Settings = container.StateConnector(AbstractSettings);

export default injectIntl(Settings);
