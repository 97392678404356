import * as React from 'react';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import * as applicationState from '../../state/reducers/ApplicationState';
import * as adminState from '../../state/reducers/AdminState';
import AdminTools from '../AdminTools';
import './adminTools.scss';
// import * as Model from '../../../../shared/src/model/index';
// import * as schema from '../../../../shared/src/model/projectSchema';
import { push } from 'connected-react-router';
import Role from './role';
import { List, ListItem } from '@material-ui/core';
// import { errorMessage, successMessage } from '../Common/Notifier';

// const API = APIService(axios);
// const DOC = DocumentService(axios);

interface IRolesProps extends applicationState.IProps, adminState.IProps {
  match: {
    params: {
      id: number;
    };
  };
}

interface IRolesState {}

type RolesProps = IRolesProps & InjectedIntlProps;

class AbstractRoles extends React.Component<RolesProps, IRolesState> {
  constructor(props: RolesProps) {
    super(props);

    this.state = {};
  }

  componentDidMount = () => {
    this.props.getAvailableRoles();
  };

  render() {
    // const t = (id: string) => this.props.intl.formatMessage({ id });
    const selectedRole = this.props.roles
      .filter(r => Number(r.id) === Number(this.props.match.params.id))
      .pop();
    return (
      <AdminTools>
        <div className="grid gridCols3">
          <div className="component master">
            <h2>
              <FormattedMessage id="headers.roles" />
            </h2>
            <div className="content">
              <List>
                {this.props.roles.map(role => {
                  return (
                    <ListItem
                      key={role.id}
                      onClick={() => {
                        this.props.ApplicationStateDispatcher(
                          push('/admin-tools/roles/' + role.id)
                        );
                      }}
                    >
                      {role.role_name}
                    </ListItem>
                  );
                })}
              </List>
            </div>
          </div>
          {selectedRole && <Role role={selectedRole} />}
        </div>
      </AdminTools>
    );
  }
}

const Roles = adminState.StateConnector(
  applicationState.StateConnector(AbstractRoles)
);

export default injectIntl(Roles);
