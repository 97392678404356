import * as React from 'react';
import * as container from '../../state/reducers/DocumentState';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import * as Model from '../../../../shared/src/model';

import MainLayout from '../../layout/MainLayout';

interface IUploadProps extends container.IProps {}

interface IUploadState {
  email: string;
  password: string;
}

type UploadProps = IUploadProps & InjectedIntlProps;

class AbstractUpload extends React.Component<UploadProps, IUploadState> {
  constructor(props: UploadProps) {
    super(props);

    this.onSelect = this.onSelect.bind(this);
  }

  onSelect(savedSearch: Model.SearchRequest) {
    this.props.setActiveSearch(savedSearch);
    this.props.documentSearch(savedSearch);
  }

  public render() {
    // const t = this.props.intl;

    return (
      <MainLayout>
        <p>Tämä on upload</p>
      </MainLayout>
    );
  }
}

const Upload = container.StateConnector(AbstractUpload);

export default injectIntl(Upload);
