import { Create, PlayArrow, DragIndicator } from '@material-ui/icons/';
import * as _ from 'lodash';
import * as React from 'react';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { ValidatorForm } from 'react-material-ui-form-validator';
import {
  UIDocument,
  AreaDocumentState,
} from '../../../../shared/src/model/index';
import * as schema from '../../../../shared/src/model/projectSchema';
import Button from '../Common/Button';
import { Comment } from '../Common/Icons';
import Target from './targetRow';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
interface ITargetAreaProps {
  isContractor: boolean;
  area: UIDocument;
  key: string;
  index: number;
  isFiltering: boolean;
  isEditing?: boolean;
  activeFilters: string[];
  filterTerm: string;
  favourites: number[];
  showFavourites: boolean;
  workflows: schema.workflow[];
  targetDocs: AreaDocumentState[];
  onTargetAreaNameChange: (
    targetAreaName: string,
    targetArea: UIDocument
  ) => void;
  onTargetNameChange: (targetName: string, target: UIDocument) => void;
  onOrdinalChange: (docIds: number[]) => void;
  onAddNewTargetArea: (index: number) => void;
  onDeleteTargetArea: (target: UIDocument) => void;
  onAddNewTarget: (index: number, parentId: number) => void;
  onDeleteTarget: (target: UIDocument) => void;
  onSetFavourites: (id: number) => void;
  filteredTargets: (targets: UIDocument[]) => UIDocument[];
  onEditTargetArea: (targetAreaId: number | undefined) => void;
}

interface ITargetAreaState {
  isOpen: boolean;
  isEditing: boolean;
  isConfirmingDeletion: boolean;
  targetAreaName: string;
  filteredTargets: UIDocument[];
  editingTargetId?: number;
}

type TargetAreaProps = ITargetAreaProps & InjectedIntlProps;

class TargetArea extends React.Component<TargetAreaProps, ITargetAreaState> {
  constructor(props: TargetAreaProps) {
    super(props);

    this.state = {
      isOpen: false,
      isEditing: props.isEditing || false,
      isConfirmingDeletion: false,
      targetAreaName: props.area.name,
      filteredTargets: props.filteredTargets(props.area.children || []),
    };
  }

  onToggleVisibility = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  onToggleEditMode = () => {
    this.props.onEditTargetArea(
      this.state.isEditing ? undefined : this.props.area.id
    );
  };

  onUpdateName = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ targetAreaName: event.target.value });
  };

  onConfirmTargetAreaDeletion = () => {
    this.setState({ isConfirmingDeletion: true });
  };

  onCancelTargetAreaDeletion = () => {
    this.setState({ isConfirmingDeletion: false });
  };

  onTargetAreaDeletion = (targetArea: UIDocument) => {
    this.setState({ isConfirmingDeletion: false });
    this.props.onDeleteTargetArea(targetArea);
  };

  onCancelEdit = () => {
    this.setState({
      targetAreaName: this.props.area.name,
      isEditing: false,
    });
  };

  onAddNewTargetArea = (index: number) => {
    this.props.onAddNewTargetArea(index);
    this.setState({ isEditing: false });
  };

  onAddNewTarget = (index: number, parentId: number) => {
    this.props.onAddNewTarget(index, parentId);
    this.setState({ isEditing: false });
  };

  array_move = (arr: UIDocument[], old_index: number, new_index: number) => {
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
  };

  onSortEnd = (params: { oldIndex: number; newIndex: number }) => {
    const { oldIndex, newIndex } = params;
    this.setState({
      filteredTargets: this.array_move(
        this.state.filteredTargets,
        oldIndex,
        newIndex
      ),
    });
  };

  onEditTarget = (targetId: number | undefined) => {
    this.setState({
      editingTargetId: targetId,
    });
  };

  onCancelTargetEdit = () => {
    this.setState({
      filteredTargets: this.props.filteredTargets(
        this.props.area.children || []
      ),
    });
  };

  onTargetNameChange = (targetName: string, target: UIDocument) => {
    if (target.name !== targetName) {
      this.props.onTargetNameChange(targetName, target);
    }

    const stateOrder = this.state.filteredTargets.map((t) => t.id);
    const originalOrder = this.props
      .filteredTargets(this.props.area.children || [])
      .map((t) => t.id);

    // Check if order has changed
    if (!_.isEqual(stateOrder, originalOrder)) {
      this.props.onOrdinalChange(stateOrder);
    }
  };

  render() {
    const {
      isContractor,
      area,
      index,
      isFiltering,
      showFavourites,
    } = this.props;
    const { isEditing, isConfirmingDeletion } = this.state;
    const isOpen =
      this.state.isOpen ||
      this.state.isEditing ||
      isFiltering ||
      showFavourites;
    // const targets = area.children || [];
    const stateColor = area.uiMeta ? area.uiMeta.stateColor : '';

    // const filteredTargets = this.props.filteredTargets(targets);
    const { filteredTargets } = this.state;

    const comments =
      area.children &&
      _.flatten(
        area.children.map((target) => (target.comments ? target.comments : []))
      );

    const hasUnreadComments =
      comments && comments.some((comment) => comment.seen_at === null);

    // Hide the area if theres no targets to show
    if (filteredTargets.length === 0 && isFiltering) {
      return '';
    }

    const SortableItem = SortableElement(
      (props: { value: UIDocument; index: number }): any => {
        const editingTarget = this.state.editingTargetId === props.value.id;
        return (
          <Target
            isContractor={this.props.isContractor}
            target={props.value}
            targetDocs={this.props.targetDocs.filter(
              (t) => t.target_id === props.value.id
            )}
            key={props.value.id}
            index={index || 0}
            favourites={this.props.favourites}
            onTargetNameChange={this.onTargetNameChange}
            onDeleteTarget={this.props.onDeleteTarget}
            onSetFavourites={this.props.onSetFavourites}
            workflows={this.props.workflows}
            isEditing={editingTarget}
            onEditTarget={this.onEditTarget}
            onCancelEdit={this.onCancelTargetEdit}
          />
        );
      }
    );

    const SortableList = SortableContainer((props: { items: UIDocument[] }) => {
      return (
        <ul className="targets">
          {props.items.map((value: UIDocument, index: number) => (
            <SortableItem key={`item-${index}`} index={index} value={value} />
          ))}
        </ul>
      );
    });

    const SortableDragHandle = SortableHandle(() => (
      <DragIndicator className="dragHandle" />
    ));

    return (
      <li className={`targetArea`}>
        {isEditing ? (
          <ValidatorForm
            className="content editMode"
            onSubmit={() => {
              this.onToggleEditMode();

              this.props.onTargetAreaNameChange(
                this.state.targetAreaName,
                area
              );
            }}
          >
            <div className="fields">
              <input
                type="text"
                className="basic"
                value={this.state.targetAreaName}
                onChange={this.onUpdateName}
              />
            </div>
            <div className="actions">
              <Button
                size="small"
                disabled={isConfirmingDeletion}
                onClick={this.onCancelEdit}
              >
                <FormattedMessage id="buttons.cancel" />
              </Button>
              <Button
                size="small"
                disabled={
                  !(area.privileges && area.privileges.canDelete) ||
                  isConfirmingDeletion
                }
                onClick={this.onConfirmTargetAreaDeletion}
              >
                <FormattedMessage id="buttons.delete" />
              </Button>
              <Button
                size="small"
                disabled={isConfirmingDeletion}
                onClick={(event) => this.onAddNewTargetArea(index)}
              >
                <FormattedMessage id="inspectionDocuments.addNewTargetArea" />
              </Button>
              <Button
                size="small"
                disabled={isConfirmingDeletion}
                onClick={(event) => this.onAddNewTarget(index, area.id)}
              >
                <FormattedMessage id="inspectionDocuments.addNewTarget" />
              </Button>
              <Button
                size="small"
                disabled={isConfirmingDeletion}
                type="submit"
              >
                <FormattedMessage id="buttons.save" />
              </Button>
            </div>
            <SortableDragHandle />
          </ValidatorForm>
        ) : (
          <div
            className={`content status-${stateColor}`}
            onClick={this.onToggleVisibility}
          >
            <div className="meta">{this.state.targetAreaName}</div>
            <div className="filler">&nbsp; </div>

            {comments && comments.length > 0 && (
              <div
                className={`comments button ${
                  hasUnreadComments && 'unreadComments'
                } ${isContractor && 'contractor'}`}
              >
                <Comment />
              </div>
            )}

            {area.privileges && area.privileges.canUpdate && (
              <div
                className="edit button"
                onClick={(e) => {
                  e.stopPropagation();
                  this.onToggleEditMode();
                }}
              >
                <Create />
              </div>
            )}
            <div className={`toggle ${isOpen ? 'open' : ''} button`}>
              <PlayArrow />
            </div>
          </div>
        )}

        {isConfirmingDeletion && (
          <div className="confirm">
            <p>
              <FormattedMessage id="inspectionDocuments.confirmTargetAreaDeletion" />
            </p>
            <div className="actions">
              <Button size="small" onClick={this.onCancelTargetAreaDeletion}>
                <FormattedMessage id="buttons.cancel" />
              </Button>
              <Button
                size="small"
                onClick={() => this.onTargetAreaDeletion(area)}
              >
                <FormattedMessage id="buttons.delete" />
              </Button>
            </div>
          </div>
        )}

        {isOpen && (
          <>
            <div className="headers">
              <div className="wrapper">
                <div className="header">
                  <FormattedMessage id="inspectionDocuments.target" />
                </div>
                <div className="header">
                  <FormattedMessage id="inspectionDocuments.content" />
                </div>
                <div className="header">
                  <FormattedMessage id="inspectionDocuments.workflowStateName" />
                </div>
                <div className="header">
                  <FormattedMessage id="inspectionDocuments.workflow" />
                </div>
              </div>
            </div>
            {filteredTargets && (
              <SortableList
                onSortEnd={this.onSortEnd}
                lockToContainerEdges={true}
                lockAxis={'y'}
                useDragHandle={true}
                items={filteredTargets}
              />

              // <List
              //   values={filteredTargets}
              //   onChange={({ oldIndex, newIndex }) => {
              //     console.log(
              //       'Moving: ',
              //       JSON.stringify({ oldIndex, newIndex })
              //     );
              //     // this.setState({
              //     //   filteredTargets: this.state.filteredTargets.splice(
              //     //     newIndex,
              //     //     0,
              //     //     this.state.filteredTargets.splice(oldIndex, 1)[0]
              //     //   ),
              //     // });
              //   }}
              //   //   this.setState((prevState: { filteredTargets: UIDocument[] }) => {
              //   //     return ({
              //   //       items: arrayMove(prevState.filteredTargets, oldIndex, newIndex),
              //   //     });
              //   //   })
              //   // }
              //   renderList={({ children, props, isDragged }) => (
              //     <ul
              //       className="targets"
              //       {...props}
              //       style={{
              //         padding: '0em 0em 1em 0em',
              //         cursor: isDragged ? 'grabbing' : 'inherit',
              //       }}
              //     >
              //       {children}
              //     </ul>
              //   )}
              //   renderItem={({
              //     value,
              //     props,
              //     index,
              //     isDragged,
              //     isSelected,
              //   }) => {
              //     const target = value;
              //     console.log('INDEX: ', index);
              //     return (
              //       <React.Fragment key={'litem_' + target.id}>
              //         <li
              //           {...props}
              //           style={{
              //             ...props.style,
              //             listStyleType: 'none',
              //             cursor: isDragged ? 'grabbing' : 'inherit',
              //             backgroundColor:
              //               isDragged || isSelected ? '#EEE' : '#FFF',
              //           }}
              //         >
              //           <Target
              //             isContractor={this.props.isContractor}
              //             target={target}
              //             key={target.id}
              //             index={index || 0}
              //             favourites={this.props.favourites}
              //             onTargetNameChange={this.props.onTargetNameChange}
              //             onDeleteTarget={this.props.onDeleteTarget}
              //             onSetFavourites={this.props.onSetFavourites}
              //             workflows={this.props.workflows}
              //           />
              //         </li>
              //       </React.Fragment>
              //     );
              //   }}
              // />
            )}
          </>
        )}
        {/* <div
          style={{ width: '0px', height: '0px' }}
          data-movable-handle={true}
        /> */}
      </li>
    );
  }
}
{
  /* <Target
                    isContractor={this.props.isContractor}
                    target={target}
                    key={target.id}
                    index={index}
                    favourites={this.props.favourites}
                    onTargetNameChange={this.props.onTargetNameChange}
                    onDeleteTarget={this.props.onDeleteTarget}
                    onSetFavourites={this.props.onSetFavourites}
                    workflows={this.props.workflows}
                  /> */
}

export default injectIntl(TargetArea);
